import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftSvg } from 'assets/svg/arrow-left.svg';
import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { getUserTypes, WHO_ARE_YOU_PAGE_TITLE, WHO_ARE_YOU_SIGN_IN_PAGE_TITLE } from 'constants/who-are-you';
import { AuthLayout, HeaderInfo, WhoAreYouChip } from 'page-components';
import { LinkButton } from 'shared-components';

import { ButtonVariants } from '../constants/shared/button';
import { updateUserType } from '../modules/current-user/action';
import { selectUser } from '../modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from '../modules/store';

const WhoAreYou = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const user = useAppSelector(selectUser);

  const isMultiSignIn = localStorage.getItem(LocalStorageKeys.IS_MULTI_SIGN_IN);

  const onClickRegister = (selectedUserType: UserTypes) => {
    if (!selectedUserType) return;
    localStorage.setItem(LocalStorageKeys.USER_TYPE, selectedUserType);

    if (isMultiSignIn) {
      dispatch(updateUserType({ type: selectedUserType }))
        .unwrap()
        .then(() => {
          localStorage.setItem(LocalStorageKeys.IS_MULTI_SIGN_IN, '0');
          navigate(ROUTES.dashboard);
        });
      return;
    }

    navigate(ROUTES.signUp);
  };

  useEffect(() => {
    if (isMultiSignIn === '0' && user) {
      navigate(ROUTES.dashboard);
    }
  }, [isMultiSignIn, user]);

  return (
    <AuthLayout childrenClassName='max-w-[576px]'>
      <HeaderInfo
        className='[&_h2]:mb-0'
        title={isMultiSignIn ? WHO_ARE_YOU_SIGN_IN_PAGE_TITLE : WHO_ARE_YOU_PAGE_TITLE}
      />

      <div className='mb-10'>
        {getUserTypes(!!isMultiSignIn).map(({ title, description, userType, icon }, index) => (
          <WhoAreYouChip
            onClick={onClickRegister}
            key={userType}
            className={index === 0 ? 'mb-20' : ''}
            title={title}
            description={description}
            userType={userType}
            icon={icon}
          />
        ))}
      </div>
      <LinkButton to={ROUTES.signIn} variant={ButtonVariants.SECONDARY}>
        <ArrowLeftSvg className='mr-2' /> Back
      </LinkButton>
    </AuthLayout>
  );
};
export default WhoAreYou;
