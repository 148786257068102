import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminFeesApi } from 'apis/fees.apis';
import { showServerError, successNotify } from 'helpers';
import { CreateFeeFormValues, GetListPayload } from 'interfaces';
import { FEES_PER_PAGE } from 'page-components/business-settings/fees-table/FeesTable';

export const FEES_SLICE_NAME = 'fees';

export const getAllFees = createAsyncThunk(
  `${FEES_SLICE_NAME}/getAllFees`,
  async (params: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await adminFeesApi.getAllFees(params);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createFee = createAsyncThunk(
  `${FEES_SLICE_NAME}/createFee`,
  async (payload: CreateFeeFormValues, { dispatch, rejectWithValue }) => {
    try {
      const response = await adminFeesApi.createFee(payload);

      dispatch(getAllFees({ page: 1, per_page: FEES_PER_PAGE }));
      successNotify('Fee was successfully created');

      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);
