import React, { FC } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { GRAPHS_RANGES_DROPDOWN } from 'constants/dashboard';
import { TypographyVariants } from 'constants/shared/typography';
import { GraphItem, SelectOption } from 'interfaces';
import Typography from 'shared-components/Typography';

import { MultiAreaChart } from '../chart';
import AreaChartComponent from '../chart/area-chart/AreaChart';
import TooltipV2 from '../chart/area-chart/TooltipV2';
import MetricsGrowth from '../metrics-growth/MetricsGrowth';
import Select from '../Select';

type Props = {
  onChangeRange: (option: SelectOption | null) => void;
  selectedRange: SelectOption;
  amount: number | null;
  chartData: GraphItem[];
  isMulti?: boolean;
  isLoading: boolean;
  className?: string;
  chartWrapperClassName?: string;
  wrapperClassName?: string;
  showTotalArea?: boolean;
  title?: string;
};

const MetricsGraphLayout: FC<Props> = ({
  className,
  wrapperClassName,
  chartWrapperClassName,
  selectedRange,
  isMulti,
  isLoading,
  chartData,
  onChangeRange,
  amount,
  title,
}) => {
  const validAmount = amount || 0;

  if (isLoading) {
    return (
      <div className={cn('flex w-full h-[220px]', className)}>
        <div className='skeleton-loader w-1/3 mr-4' />

        <div className='skeleton-loader w-2/3' />
      </div>
    );
  }

  return (
    <div className={cn('flex gap-2 w-full relative h-[220px]', className)}>
      <div className='w-[120px] absolute right-0 -top-[25px]'>
        <Select value={selectedRange} options={GRAPHS_RANGES_DROPDOWN} onChange={onChangeRange} />
      </div>

      <div className={twMerge('w-1/3 flex flex-col justify-between', wrapperClassName)}>
        {!isMulti && (
          <div>
            {title && (
              <Typography tag='h3' variant={TypographyVariants.LARGE}>
                {title}
              </Typography>
            )}
            <h3 className='text-4xl font-medium'>{validAmount}</h3>
          </div>
        )}

        <MetricsGrowth isMulti={isMulti} timePeriod={selectedRange.label} data={chartData} />
      </div>

      <div className={twMerge('flex items-end w-2/3', chartWrapperClassName)}>
        {!isMulti ? (
          <AreaChartComponent data={chartData} height={190} showXAxis={false} tooltip={TooltipV2} tooltipOffset={-65} />
        ) : (
          <MultiAreaChart data={chartData} height={190} showXAxis={false} tooltip={TooltipV2} tooltipOffset={-65} />
        )}
      </div>
    </div>
  );
};

export default MetricsGraphLayout;
