import { createSlice } from '@reduxjs/toolkit';

import { DueDiligenceSections } from 'constants/due-diligence';
import { DueDiligenceState } from 'interfaces';

import { DUE_DILIGENCE_SLICE_NAME } from './action';
import {
  deleteDueDiligenceDocumentReducer,
  getDueDiligenceDocumentsReducer,
  setDueDiligenceDocumentsFilterValueReducer,
  saveDueDiligenceLegalMattersReducer,
  setDueDiligenceReducer,
  addDueDiligenceCommentReducer,
  setDueDiligenceEditingSectionsReducer,
  removeDueDiligenceEditingSectionsReducer,
  getDueDiligenceFoundersForInviteReducer,
  dueDiligenceConfirmReducer,
  dueDiligenceRejectReducer,
  dueDiligenceReopenReducer,
  dueDiligenceHighlightUnsavedChangesReducer,
  dueDiligenceUpdateDocumentType,
  getDueDiligenceDataReducer,
} from './reducers';

export const initialState: DueDiligenceState = {
  businessDetails: null,
  significantControlPersons: [],
  beneficialOwners: [],
  documentTypes: [],
  legalMatters: null,
  children: [],
  companyRunway: null,
  fundraisingDetails: null,
  documents: {
    list: null,
    isLoading: false,
    totalPages: 0,
    total: 0,
    filterValue: '',
  },
  comments: {
    [DueDiligenceSections.DOCUMENTS]: [],
    [DueDiligenceSections.BENEFICIAL_OWNERS]: [],
    [DueDiligenceSections.BUSINESS_DETAILS]: [],
    [DueDiligenceSections.SIGNIFICANT_CONTROL_PERSONS]: [],
    [DueDiligenceSections.LEGAL_MATTERS]: [],
    [DueDiligenceSections.MONTHS_CASH_RUNWAY_BLOCK]: [],
    [DueDiligenceSections.MANAGEMENT_OWNERSHIP]: [],
    [DueDiligenceSections.FUNDRAISING_DETAILS]: [],
    [DueDiligenceSections.DEBT_PREFERENCE_RIGHTS]: [],
  },
  foundersForInvite: [],
  editingSections: [],
  isCalled: false,
  isEditable: false,
  highlightUnsavedChanges: false,
  companyId: null,
  lastCreatedAt: null,
  resubmitted: false,
  canViewInvestmentProposal: false,
};

const dueDiligenceSlice = createSlice({
  name: DUE_DILIGENCE_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setDueDiligenceReducer(builder);
    setDueDiligenceDocumentsFilterValueReducer(builder);
    getDueDiligenceDocumentsReducer(builder);
    deleteDueDiligenceDocumentReducer(builder);
    saveDueDiligenceLegalMattersReducer(builder);
    addDueDiligenceCommentReducer(builder);
    setDueDiligenceEditingSectionsReducer(builder);
    removeDueDiligenceEditingSectionsReducer(builder);
    getDueDiligenceFoundersForInviteReducer(builder);
    dueDiligenceConfirmReducer(builder);
    dueDiligenceRejectReducer(builder);
    dueDiligenceReopenReducer(builder);
    dueDiligenceHighlightUnsavedChangesReducer(builder);
    dueDiligenceUpdateDocumentType(builder);
    getDueDiligenceDataReducer(builder);
  },
});

export const dueDiligenceReducer = dueDiligenceSlice.reducer;
