import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { MY_BILLS_SEARCH_PLACEHOLDER, PayBillModalTypeByStatus, PayBillModalTypes } from 'constants/my-bills';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { Bill } from 'interfaces';
import { getBills } from 'modules/bills/action';
import { selectBillsData } from 'modules/bills/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';

import PayBillModalsContainer from '../pay-bill-modals/PayBillModalsContainer';
import BillsTable from './BillsTable';

const BILLS_PER_PAGE = 10;

const BillsContainer = () => {
  const dispatch = useAppDispatch();
  const { totalPages, total, ...billsData } = useAppSelector(selectBillsData);
  const location = useLocation();

  const { onOpen, onClose, ...modalProps } = useModal();
  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  const [modalType, setModalType] = useState<PayBillModalTypes | null>(null);
  const [modalBill, setModalBill] = useState<Bill | null>(null);
  const [selectedBill, setSelectedBill] = useState<Bill | null>(null);

  const showBillModal = useCallback(
    (modalType: PayBillModalTypes, bill?: Bill) => {
      setModalBill(bill || null);
      setModalType(modalType);
      onOpen();
    },
    [onOpen],
  );

  const closeBillModal = useCallback(() => {
    onClose();
    setModalType(null);
    setModalBill(null);
  }, [onClose]);

  useEffect(() => {
    dispatch(getBills({ page, per_page: BILLS_PER_PAGE, sort: sorting.column, order: sorting.order, query }));
  }, [dispatch, page, query, sorting.column, sorting.order]);

  useEffect(() => {
    const searchObject = new URLSearchParams(location.search);
    const payStatus = searchObject.get('pay') as 'success' | 'failed' | null;

    if (payStatus && payStatus && PayBillModalTypeByStatus[payStatus]) {
      showBillModal(PayBillModalTypeByStatus[payStatus] as unknown as PayBillModalTypes);
    }
  }, [location.search, showBillModal]);

  return (
    <>
      <PayBillModalsContainer
        setModalType={setModalType}
        bill={modalBill}
        modalType={modalType}
        onClose={closeBillModal}
        {...modalProps}
      />
      <div className='flex flex-col'>
        <div className='flex justify-between mb-10 flex-col sm:flex-row'>
          <SearchForm
            handleFilter={handleFilter}
            placeholder={MY_BILLS_SEARCH_PLACEHOLDER}
            className='flex-col xs:flex-row gap-4 xs:gap-0 xl:basis-8/12 2xl:basis-72'
          />
        </div>
        <BillsTable
          showBillModal={showBillModal}
          selectedBill={selectedBill}
          setSelectedBill={setSelectedBill}
          updateSorting={updateSorting}
          {...billsData}
        />
        <Pagination
          loading={billsData.isLoading}
          currentPage={page}
          totalPages={totalPages}
          total={total}
          updatePage={setPage}
        />
      </div>
    </>
  );
};

export default BillsContainer;
