import Hotjar from '@hotjar/browser';

import { LocalStorageKeys } from 'constants/global';
import { UserTypes } from 'constants/user';

export const initializeHotjar = () => {
  const siteId = window?._env_?.REACT_APP_HOTJAR_ID || process.env.REACT_APP_HOTJAR_ID;
  const hotjarVersion = window?._env_?.REACT_APP_HOTJAR_VERSION || process.env.REACT_APP_HOTJAR_VERSION;
  const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE);

  if (siteId && hotjarVersion && userType !== UserTypes.ADMIN && !window.location.pathname.startsWith('/admin')) {
    Hotjar.init(Number(siteId), Number(hotjarVersion));
  }
};
