import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  adminApis,
  adminCompaniesApis,
  adminCompaniesMetricsApis,
  adminCompanyDueDiligenceApis,
  companiesApis,
  companiesIacApis,
  externalCompaniesApis,
  fmUserApis,
  fmUserCompaniesIacApis,
  fmUsersCompaniesDueDiligenceApis,
  founderCompaniesApis,
  foundersApis,
  investorsCompaniesApis,
} from 'apis';
import { COMPANY_DATA_EXCLUDED_KEYS } from 'constants/companies';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { errorNotify, showServerError, successNotify } from 'helpers';
import {
  CompanyData,
  CompanyDetailsInput,
  GetArchivedDataPayload,
  GetListPayload,
  InviteCompanyInvestorPayload,
  InvitedCompanyInvestorPayload,
  SendInviteInvestorsEmailPayload,
  ServerError,
  ValidateCompanyInvestorsPayload,
} from 'interfaces';
import history from 'services/history';
import { excludeKeyFromObjectByArrayOfValues, getCompaniesOptionsRequestByUserType } from 'utils';

export const COMPANIES_SLICE_NAME = 'companies';

export const getCompanyById = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompanyById`,
  async ({ id, isAdmin }: { id: string; isAdmin?: boolean }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin ? adminCompaniesApis.getCompany(id) : companiesApis.getCompany(id));

      return response.data;
    } catch (err) {
      showServerError(err);

      if (!isAdmin && (err as AxiosError<ServerError>)?.response?.status === 403) {
        history.push(ROUTES.myCompanies);
      }

      return rejectWithValue(err);
    }
  },
);

export const getShortCompanyInfo = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getShortCompanyInfo`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await companiesApis.getShortCompanyInfo(id);

      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getMyCompanies = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getMyCompanies`,
  async (data: GetListPayload) => {
    const response = await founderCompaniesApis.getMyCompanies(data);

    return response.data;
  },
);

export const getAdminCompanies = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getAdminCompanies`,
  async ({ isAdmin, ...params }: GetListPayload & { isAdmin: boolean }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin ? adminCompaniesApis.getCompanies(params) : fmUserApis.getCompanies(params));
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getAdminCompanyExchangeData = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getAdminCompanyExchangeData`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await adminCompaniesApis.getAdminCompanyExchangeData(id);
      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getCompanyName = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompanyName`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await founderCompaniesApis.getCompanyName(id);

      return response.data.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getMyCompaniesOptions = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getMyCompaniesOptions`,
  async (userType: UserTypes) => {
    const { data } = await getCompaniesOptionsRequestByUserType(userType, { page: 1, per_page: 20 });

    return data.data.map(({ companyName, id }) => ({
      label: companyName,
      value: id.toString(),
    }));
  },
);

export const getCompanyProfileByNumber = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompanyProfileByNumber`,
  async (data: string) => {
    const response = await externalCompaniesApis.getCompanyProfile(data);

    return excludeKeyFromObjectByArrayOfValues(response.data, COMPANY_DATA_EXCLUDED_KEYS) as CompanyData;
  },
);

export const addCompany = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/addCompany`,
  async (
    { companyNumber, companyName, isAdmin, ...data }: CompanyDetailsInput & { isAdmin?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const convertedData = {
        ...data,
        companyNumber: companyNumber?.label,
        companyName: companyName?.label,
      } as CompanyData;

      const response = await (isAdmin ? adminApis.addCompany(convertedData) : foundersApis.addCompany(convertedData));

      successNotify('The company was successfully added.');

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteCompany = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/deleteCompany`,
  async (id: number, { rejectWithValue }) => {
    try {
      await adminCompaniesApis.deleteCompany(id);

      successNotify('The company was successfully deleted.');

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getAdminCompaniesMetrics = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getAdminCompaniesMetrics`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminCompaniesMetricsApis.getAdminCompaniesMetrics();

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const updateIsWhitelisted = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/updateIsWhitelisted`,
  async ({ id, isWhitelisted }: { id: number; isWhitelisted: boolean }, { rejectWithValue }) => {
    try {
      await adminCompaniesApis.setCompanyWhiteLis({ id, isWhitelisted });

      return isWhitelisted;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getInvitedCompanyInvestors = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getInvitedCompanyInvestors`,
  async ({ id, userType }: { id: string; userType?: UserTypes }, { rejectWithValue }) => {
    try {
      const response = await (userType === UserTypes.ADMIN
        ? adminCompaniesApis.getInvitedCompanyInvestors(id)
        : founderCompaniesApis.getInvitedCompanyInvestors(id));

      return response.data.data;
    } catch (e) {
      showServerError(e);

      if ((e as AxiosError<ServerError>)?.response?.status === 403) {
        history.push(ROUTES.myCompanies);
      }

      return rejectWithValue(e);
    }
  },
);

export const createCompanyInvestorForInvite = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/createCompanyInvestorForInvite`,
  async ({ id, investor, userType }: InviteCompanyInvestorPayload & { userType?: UserTypes }, { rejectWithValue }) => {
    try {
      const response = await (userType === UserTypes.ADMIN
        ? adminCompaniesApis.createCompanyInvestorForInvite({ id, investor })
        : founderCompaniesApis.createCompanyInvestorForInvite({ id, investor }));

      successNotify('The investor was successfully created');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteInvitedCompanyInvestor = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/deleteInvitedCompanyInvestor`,
  async (
    { id, investorId, userType }: InvitedCompanyInvestorPayload & { userType?: UserTypes },
    { rejectWithValue },
  ) => {
    try {
      await (userType === UserTypes.ADMIN
        ? adminApis.deleteAdminInvitedCompanyInvestor(investorId)
        : founderCompaniesApis.deleteInvitedCompanyInvestor({ id, investorId }));

      successNotify('The investor was successfully deleted');

      return investorId;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const validateCompanyInvestorsForInvite = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/validateCompanyInvestorsForInvite`,
  async (
    {
      id,
      investors,
      companyName,
      userType,
    }: ValidateCompanyInvestorsPayload & { companyName: string; userType?: UserTypes },
    { rejectWithValue },
  ) => {
    try {
      const response = await Promise.all(
        investors.map((investor) =>
          userType === UserTypes.ADMIN
            ? adminApis.validateAdminInvitedCompanyInvestor({
                investorId: investor.id,
                fullName: investor.fullName,
                email: investor.email,
              })
            : founderCompaniesApis.validateInvitedCompanyInvestor({ id, investorId: investor.id }),
        ),
      );

      const isNoLiveDealParameterExist = response?.some(({ data }) => data?.isDealParameterNotLive);

      if (isNoLiveDealParameterExist) {
        errorNotify(`There is no live Deal for the ${companyName}`);
      }

      return {
        investors: response
          ?.map(({ data }, index) => ({ ...data, ...investors[index] }))
          ?.filter(({ hasShareExchanges }) => hasShareExchanges),
        isNoLiveDealParameterExist,
      };
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const uploadCompanyInvestors = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/uploadCompanyInvestors`,
  async ({ id, file, userType }: { id: string; file: File; userType?: UserTypes }, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append('csvFile', file);
      const config = {
        headers: { 'Content-type': 'multipart/form-data' },
      };

      const {
        data: { data },
      } = await (userType === UserTypes.ADMIN
        ? adminCompaniesApis.uploadCompanyInvestors({ id, data: formData }, config)
        : founderCompaniesApis.uploadCompanyInvestors({ id, data: formData }, config));

      successNotify('The investors were successfully uploaded');

      return data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getCompaniesInviteEmailTemplate = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompaniesInviteEmailTemplate`,
  async (
    { companyId, shareholderId, userType }: { companyId: string; shareholderId?: number; userType?: UserTypes },
    { rejectWithValue },
  ) => {
    try {
      const response = await (userType === UserTypes.ADMIN && shareholderId
        ? adminApis.getAdminCompaniesInviteEmailTemplate(shareholderId)
        : founderCompaniesApis.getCompaniesInviteEmailTemplate(companyId));

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const sendInviteInvestorsEmail = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/sendInviteInvestorEmail`,
  async (
    { companyId, content, investors, userType }: SendInviteInvestorsEmailPayload & { userType?: UserTypes },
    { rejectWithValue },
  ) => {
    try {
      await Promise.all(
        investors.map(({ id }) =>
          userType === UserTypes.ADMIN
            ? adminApis.sendAdminInviteInvestorEmail({ id, content })
            : founderCompaniesApis.sendInviteInvestorEmail({ companyId, id, content }),
        ),
      );

      successNotify('The investors were successfully invited');

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getCompanyDataForInvestments = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompanyDataForInvestments`,
  async (companyNumber: string, { rejectWithValue }) => {
    try {
      const response = await investorsCompaniesApis.getCompanyDataForInvestments(companyNumber);

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getCompanyArchivedDueDiligence = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompanyArchivedDueDiligence`,
  async ({ id, userType }: GetArchivedDataPayload, { rejectWithValue }) => {
    try {
      const payload = { id, page: 1, per_page: 10 };

      const response = await (userType === UserTypes.ADMIN
        ? adminCompanyDueDiligenceApis.getArchivedDueDiligence(payload)
        : fmUsersCompaniesDueDiligenceApis.getArchivedDueDiligence(payload));

      return response.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getCompanyArchivedIac = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompanyArchivedIac`,
  async ({ id, userType }: GetArchivedDataPayload, { rejectWithValue }) => {
    try {
      const payload = { id, page: 1, per_page: 10 };

      const response = await (userType === UserTypes.ADMIN
        ? companiesIacApis.getArchivedCompanyIac(payload)
        : fmUserCompaniesIacApis.getArchivedCompanyIac(payload));

      return response.data.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const resetCompanyData = createAction('RESET_COMPANY_DATA');
