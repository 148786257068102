import React, { FC, useState } from 'react';

import {
  COMPANY_IAC_FM_USER_CONFIRM_MODAL_DESCRIPTION,
  COMPANY_IAC_FM_USER_CONFIRM_MODAL_TITLE,
} from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { confirmCompanyIac } from 'modules/company-iac/action';
import { selectCompanyIacCompanyName, selectCompanyIacId } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const CompanyIacFmUserConfirmModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const companyIacId = useAppSelector(selectCompanyIacId);
  const companyName = useAppSelector(selectCompanyIacCompanyName);

  const [isLoading, setIsLoading] = useState(false);

  const onFmUserConfirm = () => {
    setIsLoading(true);
    dispatch(confirmCompanyIac({ iacId: companyIacId, isAdmin: false }))
      .then(onClose)
      .finally(() => setIsLoading(false));
  };

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onFmUserConfirm}
      isLoading={isLoading}
      title={COMPANY_IAC_FM_USER_CONFIRM_MODAL_TITLE}
      description={`${COMPANY_IAC_FM_USER_CONFIRM_MODAL_DESCRIPTION} ${companyName}?`}
      descriptionClassName='mt-7'
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
      submitButtonText='Confirm'
    />
  );
};

export default CompanyIacFmUserConfirmModal;
