import { addDays, format, isSaturday, isSunday, subDays } from 'date-fns';
import qs from 'query-string';

import { FULL_TIME_FORMAT } from 'constants/dateFormats';
import { LocalStorageKeys, PLAND_LOCAL_URL } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import {
  DealStage,
  FormFieldPossibleValue,
  FormItem,
  MetaPayload,
  NumberOrNull,
  Option,
  StepProgressItem,
  StringOrNull,
} from 'interfaces';

export const convertObjectToOptions = (object: Record<string, string>, isReversed?: boolean): Option[] => {
  return Object.entries(object).map((e) => ({ value: e[isReversed ? 1 : 0], label: e[isReversed ? 0 : 1] }));
};

export const navigateByRole = (isAdmin: boolean, route: string): string => (isAdmin ? `/admin${route}` : route);

export const checkIsAdmin = (): boolean => {
  return localStorage.getItem(LocalStorageKeys.USER_TYPE) === UserTypes.ADMIN;
};

export const checkIsFmUser = (): boolean => {
  return localStorage.getItem(LocalStorageKeys.USER_TYPE) === UserTypes.FM_USER;
};

export const checkIsAdminOrFmUser = (): boolean => {
  const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE);
  return userType === UserTypes.ADMIN || userType === UserTypes.FM_USER;
};

export const checkIsFounder = (): boolean => {
  return localStorage.getItem(LocalStorageKeys.USER_TYPE) === UserTypes.FOUNDER;
};

export const checkIsInvestor = (): boolean => {
  return localStorage.getItem(LocalStorageKeys.USER_TYPE) === UserTypes.INVESTOR;
};

export const generateRandomColor = (): string =>
  '#' +
  Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padEnd(6, '0');

export const generateArrayOfColors = (length: number): string[] => {
  return Array(length).fill('').map(generateRandomColor);
};

export const getRemainderCountOfListByLimit = (list: unknown[], limit: number): number =>
  list?.length > limit ? list?.length - limit : 0;

export const calculatePercentage = (sum: number, value: number | string): number =>
  Math.round((Number(value) / sum) * 100);

export const calculatePercentageWithDecimals = (sum: number, value: number | string): number => {
  return Number(((Number(value) / sum) * 100).toFixed(1));
};

export const checkIsAdminRoute = (): boolean => window.location.pathname.includes(ROUTES.admin);

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatStepsProgressData = (
  stages: DealStage[],
  currentKey: string,
): { steps: StepProgressItem[]; currentStep: NumberOrNull } => {
  const currentStepIndex = stages.findIndex((i) => i.stage === currentKey);

  const formattedSteps = stages.map(({ stage: key, label: text, documentKey }, index) => {
    return {
      key,
      text,
      documentKey,
      filled: 'w-full',
      isCompleted: false,
      ...(currentStepIndex >= index && { isCompleted: true }),
      ...(currentStepIndex === index && { filled: 'w-1/2' }),
    };
  });

  if (currentStepIndex === -1)
    return {
      steps: [
        { key: 'Pending', filled: 'w-[0px]', isCompleted: false, documentKey: null, text: '', hidden: true },
        ...formattedSteps,
      ],
      currentStep: null,
    };

  return {
    steps: formattedSteps,
    currentStep: currentStepIndex,
  };
};

export const stringifyFilterParams = ({ page = 1, per_page = 10, sort, order, query, filter }: MetaPayload): string => {
  const filterQuery = filter ? `&${filter}` : '';

  return qs.stringify({ page, per_page, sort, order, q: query }) + filterQuery;
};

export const formatCamelCaseToTitleCase = (value: string): string => {
  const text = value.replace(/([A-Z])/g, ' $1');
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const excludeKeyFromObjectByArrayOfValues = (
  values: Record<string, any>,
  keysToExclude: string[],
): Record<string, any> => {
  return Object.keys(values)
    .filter((key) => !keysToExclude.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: values[key] });
    }, {});
};

export const openUrlInSeparateTab = (link: string) => window.open(link, '_blank', 'noopener,noreferrer');

export const whetherToShowMockData = () =>
  (window?._env_?.REACT_APP_SHOW_MOCK_DATA || process.env.REACT_APP_SHOW_MOCK_DATA) === 'true';

export const getWindowEnvBaseUrl = () => {
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  return apiBaseUrl?.includes(PLAND_LOCAL_URL) ? window?._env_?.REACT_APP_API_URL : apiBaseUrl;
};

export const getFiltersQuery = (filterType: string, filterValue: string) => `ftype=${filterType}&fvalue=${filterValue}`;

export const getUserFiltersQuery = (params: Record<string, unknown>) => {
  const paramsKeys = Object.keys(params);

  return paramsKeys.reduce((acc, paramsKey, index) => {
    if (!params[paramsKey]) return acc;

    const separator = index < paramsKeys.length - 1 ? '&' : '';

    return `${acc}filter[${paramsKey}]=${params[paramsKey]}${separator}`;
  }, '');
};

export const navigateWithFilter = (isAdmin: boolean, route: string, filterType: string, filterValue: string) =>
  `${navigateByRole(isAdmin, route)}?${getFiltersQuery(filterType, filterValue)}`;

export const getAbbreviateNumber = (value: number) => {
  return Math.abs(value) > 999
    ? Math.sign(value) * Number((Math.abs(value) / 1000).toFixed(1)) + 'k'
    : Math.sign(value) * Math.abs(value);
};

export const mapObjectsWithLabels = (object: Record<string, string | number>, label: Record<string, string>) =>
  Object.entries(object)
    .map((e) => ({ key: e[0], objValue: e[1] }))
    .reduce((acc, val) => ({ ...acc, [label[val.key]]: val.objValue }), {});

export const getSumOfObjectValues = (object: Record<string, number>): number => {
  return Object.values(object).reduce((prevValue, currentValue) => prevValue + currentValue, 0);
};

export const formatFormItemsNamesForAppend = (items: FormItem[]) => {
  return Object.values(items).reduce(
    (prev, { name }) => ({
      ...prev,
      [name.split('.').reverse()[0]]: '',
    }),
    {},
  );
};

export const convertsObjectToOptionsMap = (
  object: Record<string, StringOrNull | undefined>,
): Record<string, Option> => {
  return Object.entries(object).reduce(
    (prev, element) => ({
      ...prev,
      ...(element[1] && { [element[0]]: { label: element[1], value: element[1] } }),
    }),
    {},
  );
};

export const getKeyValue =
  <T extends object, U extends keyof T>(key: U) =>
  (obj: T) =>
    obj[key];

export const separateAndCapitalizeString = (value: string, separator?: string) =>
  value
    .split(separator || '_')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');

export const getCurrentTime = () => format(new Date(), FULL_TIME_FORMAT);

export const formatFloatNumbers = (value?: string | number) => {
  if (!value) return '';

  const numberValue = Number(value);

  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 10 }).format(numberValue);
};

export const getClosestWorkOrCurrentDate = (date: Date) => {
  if (isSaturday(date)) return subDays(date, 1);

  if (isSunday(date)) return addDays(date, 1);

  return date;
};

export const checkIfValuesTheSame = (value: FormFieldPossibleValue, secondValue: FormFieldPossibleValue) => {
  return typeof value === 'object'
    ? (value as Option)?.value === (secondValue as Option)?.value
    : value === secondValue;
};

export const checkIsJSONString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
