import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

const NoDocumentsAdded = () => {
  return (
    <TableRow>
      <TableData>No information is available as no Documents have been added</TableData>
    </TableRow>
  );
};

export default NoDocumentsAdded;
