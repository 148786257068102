import React, { useEffect } from 'react';

import {
  DASHBOARD_DEALS_SNIPPET_STAGES_LABELS,
  DASHBOARD_PIE_CHART_COLORS,
  FINISHED_SHARE_EXCHANGE_TITLE,
  STARTED_SHARE_EXCHANGE_TITLE,
} from 'constants/dashboard';
import useGraphRanges from 'hooks/use-grpah-ranges/useGraphRanges';
import { getDealsStaticMetrics, getDealsFinishedMetrics, getDealsStartedMetrics } from 'modules/deals/action';
import {
  selectIsLoadingMetrics,
  selectDealsTotalInStagesMetrics,
  selectDealsTotalFinishedMetrics,
  selectDealsTotalStartedMetrics,
} from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ChartInfoItemWithColoredRect, MetricsGraphLayout } from 'shared-components';
import { ChartMetricsLayout, PieChart, PieChartActiveShapeV2 } from 'shared-components/chart';
import { convertObjectToChartData, convertObjectToMultiAreaData, getSumOfObjectValues } from 'utils';

const AdminDealsSnippetContent = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoadingMetrics);
  const totalInStages = useAppSelector(selectDealsTotalInStagesMetrics);
  const totalStarted = useAppSelector(selectDealsTotalStartedMetrics);
  const totalFinished = useAppSelector(selectDealsTotalFinishedMetrics);

  const { selectedRange, onChangeRange } = useGraphRanges();

  useEffect(() => {
    dispatch(getDealsStaticMetrics(DASHBOARD_DEALS_SNIPPET_STAGES_LABELS));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDealsStartedMetrics(selectedRange.value));
    dispatch(getDealsFinishedMetrics(selectedRange.value));
  }, [dispatch, selectedRange.value]);

  return (
    <>
      <div className='col-span-full sm:col-span-2 flex flex-col gap-8'>
        <MetricsGraphLayout
          wrapperClassName='justify-end w-1/5'
          chartWrapperClassName='w-4/5'
          showTotalArea={false}
          selectedRange={selectedRange}
          amount={totalStarted.total}
          isLoading={totalStarted.isLoading}
          chartData={convertObjectToMultiAreaData(totalStarted.totalStarted, totalFinished.totalFinished)}
          isMulti
          onChangeRange={onChangeRange}
        />
        <ChartMetricsLayout isLoading={isLoading} className='flex justify-center items-center gap-2 flex-1'>
          <ChartInfoItemWithColoredRect
            title='Started'
            value={totalStarted.total}
            rectClassName='bg-[#558217]'
            showTooltip
            tooltipMessage={STARTED_SHARE_EXCHANGE_TITLE}
            tooltipId='started'
          />

          <ChartInfoItemWithColoredRect
            title='Completed'
            value={totalFinished.total}
            rectClassName='bg-[#ff7300]'
            showTooltip
            tooltipMessage={FINISHED_SHARE_EXCHANGE_TITLE}
            tooltipId='finished'
          />
        </ChartMetricsLayout>
      </div>

      <PieChart
        showTooltip
        className='justify-self-center self-center col-span-full sm:col-span-2'
        isLoading={isLoading}
        colors={DASHBOARD_PIE_CHART_COLORS}
        data={convertObjectToChartData({ ...totalInStages })}
        activeShape={<PieChartActiveShapeV2 title='SE in Stages' customValue={getSumOfObjectValues(totalInStages)} />}
      />
    </>
  );
};

export default AdminDealsSnippetContent;
