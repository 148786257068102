import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { TODO_TYPES_TEST } from 'constants/dashboard';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { ButtonDropdownActions, Todo, TodoActionsParamsType, TodoTypes } from 'interfaces';
import { Button } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getAdminCompanyIacLink, getDueDiligenceLink, getShareExchangeLink } from 'utils';

interface Props extends Todo {
  getActions?: (data: TodoActionsParamsType) => ButtonDropdownActions[];
}

const AdminTodoSnippetItem: FC<Props> = ({ id, company, action }) => {
  const navigate = useNavigate();

  const getReviewNavigationLink = () => {
    switch (action) {
      case TodoTypes.DUE_DILIGENCE:
        return getDueDiligenceLink(id, true);
      case TodoTypes.SHARE_EXCHANGE_REQUEST:
        return getShareExchangeLink(id, true);
      case TodoTypes.IAC:
        return getAdminCompanyIacLink(id);
      default:
        return ROUTES.home;
    }
  };

  const onReviewClick = () => {
    const navigationLink = getReviewNavigationLink();
    navigate(navigationLink);
  };

  return (
    <TableRow key={id}>
      <TableData>
        <div className='flex flex-col'>
          <span>{TODO_TYPES_TEST[action]}</span>
          <span className='whitespace-break-spaces'>{company}</span>
        </div>
      </TableData>
      <TableData className='pl-1'>
        <Button onClick={onReviewClick} variant={ButtonVariants.SECONDARY}>
          Review
        </Button>
      </TableData>
    </TableRow>
  );
};

export default AdminTodoSnippetItem;
