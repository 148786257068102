import React, { Fragment, useEffect } from 'react';

import { twMerge } from 'tailwind-merge';

import { DASHBOARD_CONTAINER_CLASSNAMES } from 'constants/dashboard';
import { ConstraintVariants } from 'constants/shared';
import { UserTypes } from 'constants/user';
import { selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import MyInvestmentsSnippet from 'page-components/dashboard/dashboard-snippets/my-investments/MyInvestmentsSnippet';
import { Constraint } from 'shared-components';

import { Company, DealProgressItem } from '../../interfaces';
import { getMyCompanies } from '../../modules/companies/action';
import { selectCompaniesData } from '../../modules/companies/selectors';
import { getDealsProgressList } from '../../modules/deals/action';
import { selectDealsProgressData, selectIsLoadingDeals } from '../../modules/deals/selectors';
import {
  AdminCompaniesSnippet,
  AdminDealsSnippet,
  AdminFoundersSnippet,
  AdminInvestorsSnippet,
  AdminInvoicesSnippet,
  FounderCompaniesSnippet,
  FounderShareExchangesSnippet,
  MyInvitesSnippet,
} from '../index';
import AdminTodosSnippet from './dashboard-snippets/admin-todos/AdminTodosSnippet';
import CompaniesIacSnippet from './dashboard-snippets/companies-iac-snippet/CompaniesIacSnippet';
import CompaniesSnippet from './dashboard-snippets/companies-snippet/CompaniesSnippet';
import FundDocumentsSnippet from './dashboard-snippets/fund-documents/FundDocumentsSnippet';
import InvestorsDoneShareExchangesSnippet from './dashboard-snippets/investors-done-share-exchanges/InvestorsDoneShareExchangesSnippet';
import InvestmentsStepsSnippet from './dashboard-snippets/my-investments-steps-snippet/InvestmentsStepsSnippet';

const COMPANIES_PER_PAGE = 10;

const DASHBOARD_SNIPPETS = (
  companies: Company[] | null,
  isCompaniesLoading: boolean,
  dealsProgress: DealProgressItem[],
  isLoadingDeals: boolean,
) => [
  {
    component: <InvestmentsStepsSnippet />,
    userTypes: [UserTypes.INVESTOR],
  },
  {
    component: <FundDocumentsSnippet />,
    userTypes: [UserTypes.INVESTOR],
  },
  {
    component: <MyInvestmentsSnippet />,
    userTypes: [UserTypes.INVESTOR],
  },
  {
    component: <AdminInvestorsSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <AdminInvoicesSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <AdminFoundersSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <AdminCompaniesSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <AdminDealsSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <InvestorsDoneShareExchangesSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <AdminTodosSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: <MyInvitesSnippet />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    component: (
      <FounderShareExchangesSnippet
        isCompaniesLoading={isCompaniesLoading}
        companies={companies}
        dealsProgress={dealsProgress}
        isLoadingDeals={isLoadingDeals}
      />
    ),
    userTypes: [UserTypes.FOUNDER],
  },
  {
    component: <FounderCompaniesSnippet isLoading={isCompaniesLoading} companies={companies} />,
    userTypes: [UserTypes.FOUNDER],
  },
  { component: <CompaniesIacSnippet />, userTypes: [UserTypes.FM_USER] },
  { component: <CompaniesSnippet />, userTypes: [UserTypes.FM_USER] },
];

const DashboardSnippets = () => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(selectUserType);

  const { list: companies, isLoading } = useAppSelector(selectCompaniesData);

  const dealsProgress = useAppSelector(selectDealsProgressData);
  const isLoadingDeals = useAppSelector(selectIsLoadingDeals);

  useEffect(() => {
    if (userType === UserTypes.FOUNDER) {
      dispatch(getMyCompanies({ page: 1, per_page: COMPANIES_PER_PAGE }));
      dispatch(getDealsProgressList({ userType: UserTypes.FOUNDER }));
    }
  }, [dispatch, userType]);

  if (!userType) return null;

  return (
    <Constraint
      variant={ConstraintVariants.FULL}
      className={twMerge(DASHBOARD_CONTAINER_CLASSNAMES[userType], 'grid grid-flow-row gap-[30px]')}
    >
      <>
        {DASHBOARD_SNIPPETS(companies, isLoading, dealsProgress, isLoadingDeals)
          .filter(({ userTypes }) => userTypes.includes(userType))
          .map(({ component }, index) => (
            <Fragment key={index}>{component}</Fragment>
          ))}
      </>
    </Constraint>
  );
};

export default DashboardSnippets;
