import React, { FC, ReactNode, useState } from 'react';

import { Tooltip } from 'react-tooltip';

import { ReactComponent as WarningSvg } from 'assets/svg/warning-outlined.svg';
import { UserData } from 'interfaces';

interface Props extends Pick<UserData, 'id' | 'isRegistrationFinished'> {
  tooltipContent: ReactNode;
}

const UsersTableItemRegisteredData: FC<Props> = ({ id, isRegistrationFinished, tooltipContent }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const tooltipAnchorId = `registered-${id}`;

  const handleOpenTooltip = () => setIsTooltipOpen(true);

  const handleCloseTooltip = () => setIsTooltipOpen(false);

  if (isRegistrationFinished) return <p>Yes</p>;

  return (
    <div
      id={tooltipAnchorId}
      className='flex items-center relative'
      onMouseEnter={handleOpenTooltip}
      onMouseLeave={handleCloseTooltip}
    >
      <p>No</p>
      <WarningSvg className='ml-2 [&_path]:fill-violet-600' />
      <Tooltip anchorId={tooltipAnchorId} isOpen={isTooltipOpen} offset={-10} clickable noArrow>
        {tooltipContent}
      </Tooltip>
    </div>
  );
};

export default UsersTableItemRegisteredData;
