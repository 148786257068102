import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { DeletePendingInviteUserFunctionType, InvitedUser } from 'interfaces';
import Typography from 'shared-components/Typography';

import MyInvitesSkeleton from './my-invites-skeleton/MyInvitesSkeleton';
import MyInvitesItem from './MyInvitesItem';

interface Props {
  listClassName?: string;
  isLoading?: boolean;
  invites: InvitedUser[];
  handleDeleteInvite: DeletePendingInviteUserFunctionType;
}

const MyInvites: FC<Props> = ({ invites, listClassName, isLoading, handleDeleteInvite }) => {
  if (isLoading) return <MyInvitesSkeleton />;

  return (
    <div>
      <Typography className='pb-6 border-b border-grey-500'>Invited Users:</Typography>
      {!invites?.length ? (
        <Typography className='mt-6'>No information is available as no invites have been added</Typography>
      ) : (
        <ul className={twMerge('overflow-y-auto pt-2', listClassName)}>
          {invites.map((person, index) => (
            <MyInvitesItem key={person.email + index} handleDeleteInvite={handleDeleteInvite} {...person} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default MyInvites;
