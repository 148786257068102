import React, { FC, SVGProps } from 'react';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';

import { Button } from './index';

export const ICON_BUTTON_WITH_TOOLTIP_TEST_ID = 'ICON_BUTTON_WITH_TOOLTIP_TEST_ID';

export interface Props {
  onClick: VoidFunction;
  icon: FC<SVGProps<SVGSVGElement>>;
  anchorId?: string;
  tooltipText?: string;
  className?: string;
  disabled?: boolean;
  disableDefaultIconStyles?: boolean;
}

const IconButtonWithTooltip: FC<Props> = ({
  icon: Icon,
  onClick,
  tooltipText,
  anchorId,
  className,
  disabled,
  disableDefaultIconStyles,
}) => {
  const { isOpen, onOpen, onClose } = useModal();

  return (
    <Button
      id={anchorId}
      disabled={disabled}
      variant={ButtonVariants.TRANSPARENT}
      onClick={onClick}
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      className={twMerge('p-4 mx-1', className)}
      type='button'
      data-testid={ICON_BUTTON_WITH_TOOLTIP_TEST_ID}
    >
      <div className='flex items-center icon-button-with-tooltip-svg'>
        <Icon
          className={cn('min-w-[24px] min-h-[24px]', {
            'fill-blue-800 [&_path]:fill-blue-800': !disableDefaultIconStyles,
          })}
        />
        {tooltipText && (
          <Tooltip
            anchorId={anchorId}
            isOpen={isOpen}
            positionStrategy='fixed'
            className='text-sm text-grey-500'
            clickable
            noArrow
          >
            {tooltipText}
          </Tooltip>
        )}
      </div>
    </Button>
  );
};
export default IconButtonWithTooltip;
