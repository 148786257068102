import React, { ReactNode } from 'react';

import cn from 'classnames';

interface Props {
  children: ReactNode;
  isSelected?: boolean;
}

const NavigationItem = ({ isSelected, ...props }: Props) => {
  return (
    <li
      className={cn(
        '[&>a]:py-4 [&>a]:px-6 list-none leading-6 [&>a]:text-white [&>a]:stroke-violet-600 [&>a]:hover:stroke-violet-600 hover:bg-violet-800 hover:[&>a]:stroke-violet-600 [&_svg]:min-w-[24px] [&_path]:fill-violet-600 transition-all duration-300',
        {
          'bg-violet-800 [&>a]:stroke-violet-600': isSelected,
        },
      )}
      {...props}
    />
  );
};

export default NavigationItem;
