import { useCallback, useEffect } from 'react';

import { getCompaniesIacList } from 'modules/companies-iac/action';
import { selectCompaniesIacData } from 'modules/companies-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import useFilterParameters from './use-filter-parameters/useFilterParameters';
import { usePaginationWithSearch } from './use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from './use-table-sorting/useTableSorting';

const useLoadCompaniesIac = () => {
  const dispatch = useAppDispatch();

  const { page, query, setPage, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { list, isLoading, ...companiesPaginationData } = useAppSelector(selectCompaniesIacData);

  const handleLoadCompanies = useCallback(() => {
    dispatch(
      getCompaniesIacList({
        page,
        per_page: 1000,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter,
      }),
    );
  }, [dispatch, filter, page, query, sorting.column, sorting.order]);

  useEffect(handleLoadCompanies, [handleLoadCompanies]);

  return {
    handleLoadCompanies,
    list,
    isLoading,
    updateSorting,
    handleFilter,
    paginationState: { updatePage: setPage, currentPage: page, ...companiesPaginationData },
  };
};

export default useLoadCompaniesIac;
