import React, { FC, useMemo } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { FileDocument } from 'interfaces';
import { downloadDocument } from 'modules/documents/action';
import { useAppDispatch } from 'modules/store';
import { ButtonDropdown } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { checkIsInvestor } from 'utils';
import { getPreviewDocumentUrlByFileName } from 'utils/documents';

import { DocumentFormattedNames, DocumentNames, FILE_EXTENSION_PATTERN } from '../../constants/documents';

type Props = {
  fileDocument: FileDocument;
  handleOpenPreviewDocumentsModal: ({ filePath }: { filePath: string }) => void;
};

const LocalDocumentsTableRow: FC<Props> = ({ fileDocument, handleOpenPreviewDocumentsModal }) => {
  const isInvestor = useMemo(() => checkIsInvestor(), []);

  const fileType = fileDocument.documentType as DocumentNames;
  const formattedFileName = fileDocument.name.replace(FILE_EXTENSION_PATTERN, '');

  const dispatch = useAppDispatch();

  const getDocumentActions = () => [
    {
      title: 'Download',
      id: 'download',
      action: () => {
        dispatch(downloadDocument({ filePath: fileDocument.internalFileName, filename: fileDocument.filename }));
      },
    },
    {
      title: 'View/Preview',
      id: 'preview',
      action: () =>
        handleOpenPreviewDocumentsModal({
          filePath: getPreviewDocumentUrlByFileName(fileDocument.internalFileName),
        }),
    },
  ];

  return (
    <TableRow key={fileDocument.id}>
      <TableData className='pl-1'>
        <ButtonDropdown className='w-40' actions={getDocumentActions()} />
      </TableData>
      {!isInvestor && <TableData>{fileDocument.investor}</TableData>}
      <TableData className='overflow-hidden'>{DocumentFormattedNames[fileType] ?? formattedFileName}</TableData>
      {!isInvestor && (
        <>
          <TableData>{fileDocument.location}</TableData>
        </>
      )}
      <TableData>{fileDocument.company}</TableData>
      <TableData>{format(parseISO(fileDocument.createdAt), DAY_FORMAT_WITH_SLASHES)}</TableData>
    </TableRow>
  );
};

export default LocalDocumentsTableRow;
