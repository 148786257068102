import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  GetListPayload,
  ShareExchangeForInvestorPayload,
  ShareExchangeRequestsResponse,
  SingleShareExchange,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class ShareExchangeApis {
  constructor(private url: string) {}

  getShareExchangeRequests(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<ShareExchangeRequestsResponse>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  getShareExchangeRequest(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: SingleShareExchange }>(`${this.url}/${id}`, config);
  }

  cancelShareExchange(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: SingleShareExchange }>(`${this.url}/${id}/cancel`, {}, config);
  }

  declineShareExchange({ id, reason }: { id: string; reason: string }, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: SingleShareExchange }>(`${this.url}/${id}/decline`, { reason }, config);
  }

  acceptShareExchange(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: SingleShareExchange }>(`${this.url}/${id}/accept`, {}, config);
  }

  createShareExchangeForInvestor(payload: ShareExchangeForInvestorPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: SingleShareExchange }>(`${this.url}s`, payload, config);
  }
}

export const adminShareExchangeRequestsApis = new ShareExchangeApis('/admin/share-exchange-requests');
export const adminShareExchangeApis = new ShareExchangeApis('/admin/share-exchange');
export const investorShareExchangeRequestsApi = new ShareExchangeApis('/investors/share-exchange-requests');
