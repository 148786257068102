import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import useModal from 'hooks/use-modal/useModal';
import { acceptShareExchange, declineShareExchange, getShareExchange } from 'modules/share-exchange/action';
import { selectShareExchangeReview } from 'modules/share-exchange/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  ShareExchangeConstraintHeader,
  ShareExchangeReviewQualificationAnswersModal,
  ShareExchangeReviewTableSection,
  ShareExchangeReviewStatusSection,
  ShareExchangeReviewLayout,
  AdminShareExchangeReviewAnswersSection,
  DeclineShareExchangeQualificationQuestionsModal,
  ShareExchangeQualificationQuestionsReviewModal,
} from 'page-components';
import { getDueDiligenceLink } from 'utils';

const ShareExchangeReview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, shareExchange } = useAppSelector(selectShareExchangeReview);

  const { id: shareExchangeId } = useParams();

  const {
    isOpen: isOpenSubmittedAnswers,
    onOpen: onOpenSubmittedAnswers,
    onClose: onCloseSubmittedAnswers,
  } = useModal();
  const { isOpen: isOpenReviewAnswers, onOpen: onOpenReviewAnswers, onClose: onCloseReviewAnswers } = useModal();
  const { isOpen: isOpenDeclineAnswers, onOpen: onOpenDeclineAnswers, onClose: onCloseDeclineAnswers } = useModal();

  const navigateToShareExchangeRequests = () => {
    navigate(-1);
  };

  const onAcceptAnswers = () => {
    if (!shareExchangeId) {
      return;
    }

    dispatch(acceptShareExchange(shareExchangeId))
      .unwrap()
      .then(() => {
        if (isOpenReviewAnswers) {
          onCloseReviewAnswers();
        }

        navigate(getDueDiligenceLink(Number(shareExchange?.companyId), true), {
          state: {
            companyName: shareExchange.companyName,
            shownInvitationProposal: true,
          },
        });
      });
  };

  const onDeclineAnswers = (reason: string) => {
    if (!reason || !shareExchangeId) {
      return;
    }

    dispatch(declineShareExchange({ id: shareExchangeId, reason }))
      .unwrap()
      .then(() => {
        onCloseDeclineAnswers();

        if (isOpenReviewAnswers) {
          onCloseReviewAnswers();
        }
      });
  };

  useEffect(() => {
    if (!shareExchangeId) {
      return;
    }

    dispatch(getShareExchange({ id: shareExchangeId, isAdmin: true }));
  }, [shareExchangeId, dispatch]);

  return (
    <>
      <ShareExchangeReviewQualificationAnswersModal
        isOpen={isOpenSubmittedAnswers}
        answers={shareExchange?.answers}
        onClose={onCloseSubmittedAnswers}
      />

      <DeclineShareExchangeQualificationQuestionsModal
        isOpen={isOpenDeclineAnswers}
        isLoading={isLoading}
        onClose={onCloseDeclineAnswers}
        onSubmitDeclineQualificationAnswers={onDeclineAnswers}
        companyName={shareExchange?.companyName}
        companyNumber={shareExchange?.companyNumber}
      />

      <ShareExchangeQualificationQuestionsReviewModal
        preventOutsideClick={isOpenReviewAnswers}
        onClickReject={onOpenDeclineAnswers}
        onClickAccept={onAcceptAnswers}
        isOpen={isOpenReviewAnswers}
        answers={shareExchange?.answers}
        onClose={onCloseReviewAnswers}
        isLoading={isLoading}
      />

      <ShareExchangeReviewLayout onClickBack={navigateToShareExchangeRequests} isLoading={isLoading}>
        <ShareExchangeConstraintHeader isAdmin shareExchange={shareExchange} />

        <ShareExchangeReviewStatusSection shareExchange={shareExchange} />

        <ShareExchangeReviewTableSection status={shareExchange?.status} investments={shareExchange?.investments} />

        <AdminShareExchangeReviewAnswersSection
          hasAnswers={!!shareExchange?.answers}
          status={shareExchange?.status}
          onClickViewAnswers={onOpenSubmittedAnswers}
          onClickReviewAnswers={onOpenReviewAnswers}
          onClickDecline={onOpenDeclineAnswers}
          onClickAccept={onAcceptAnswers}
        />
      </ShareExchangeReviewLayout>
    </>
  );
};

export default ShareExchangeReview;
