import React, { FC } from 'react';

import cn from 'classnames';

import { TypographyVariants } from 'constants/shared/typography';
import { SliderItem } from 'interfaces';
import Typography from 'shared-components/Typography';

const Slide: FC<SliderItem> = ({ id, title, description, imageSrc }) => {
  if (!title && !description && !imageSrc) return null;

  return (
    <div className='flex items-center justify-center pb-10'>
      <div className='max-w-[450px] text-center'>
        {imageSrc && (
          <img src={imageSrc} alt={`slide-${id}`} className='h-[380px] w-[400px] object-contain mx-auto max-h-96' />
        )}
        {title && (
          <Typography className='mt-16 mb-3.5' tag='h4' variant={TypographyVariants.LARGE}>
            {title}
          </Typography>
        )}
        {description && <Typography className={cn({ 'mt-16': !title })}>{description}</Typography>}
      </div>
    </div>
  );
};

export default Slide;
