import React, { useEffect, useMemo } from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import {
  getInvitedFormValues,
  SIGN_UP_INVITED_USER_EXCESS_FIELDS,
  SIGN_UP_PAGE_ADDITIONAL_LINK,
  SIGN_UP_PAGE_ADDITIONAL_TEXT,
  SIGN_UP_PAGE_TITLE,
} from 'constants/sign-up';
import { InvestorSubTypes, UserTypes } from 'constants/user';
import { SignUpInput } from 'interfaces';
import { selectInvitedUserSignUp, selectIsActionLoading, selectIsInvitationLoading } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo, SignUpForm } from 'page-components';
import { FullScreenLoader } from 'shared-components';

import { asyncGetInvitedUser, asyncInvitedUserSignUp } from '../modules/auth/action';

const SignUpInvitedUser = () => {
  const { hash } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isActionLoading = useAppSelector(selectIsActionLoading);
  const isInvitationLoading = useAppSelector(selectIsInvitationLoading);
  const invitedUserData = useAppSelector(selectInvitedUserSignUp);

  const isTypeEntity = invitedUserData?.type === (InvestorSubTypes.ENTITY as unknown as UserTypes);

  const signUpFormValues = useMemo(() => {
    if (!invitedUserData) {
      return {};
    }

    return {
      firstName: invitedUserData.firstName,
      lastName: invitedUserData.lastName,
      ...(isTypeEntity ? getInvitedFormValues(invitedUserData) : {}),
    };
  }, [invitedUserData]);

  const onSubmitForm: SubmitHandler<Partial<SignUpInput>> = (data: Partial<SignUpInput>) => {
    if (!hash || !invitedUserData) {
      return;
    }

    const userType = isTypeEntity ? UserTypes.INVESTOR : invitedUserData?.type;

    dispatch(
      asyncInvitedUserSignUp({
        ...data,
        hash,
        userType,
        companyId: invitedUserData.companyId,
        inviteType: invitedUserData.inviteType,
      }),
    );
  };

  useEffect(() => {
    if (!hash) {
      navigate(ROUTES.login);
      return;
    }

    dispatch(asyncGetInvitedUser(hash));
  }, [navigate, hash, dispatch]);

  if (isInvitationLoading) return <FullScreenLoader withoutSidebar hideOverlay />;

  return (
    <AuthLayout>
      <HeaderInfo title={SIGN_UP_PAGE_TITLE} linkText={SIGN_UP_PAGE_ADDITIONAL_LINK} linkPath={ROUTES.signIn}>
        {SIGN_UP_PAGE_ADDITIONAL_TEXT}
      </HeaderInfo>

      <SignUpForm
        isLoading={isActionLoading}
        onSubmit={onSubmitForm}
        excessFields={SIGN_UP_INVITED_USER_EXCESS_FIELDS}
        formValues={signUpFormValues}
      />
    </AuthLayout>
  );
};

export default SignUpInvitedUser;
