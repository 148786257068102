import React, { FC, useCallback, useState } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { NOTIFICATIONS_TABLE_COLUMNS } from 'constants/notifications';
import { ButtonVariants } from 'constants/shared/button';
import { NotificationStatus, UserNotification } from 'interfaces';
import { getUser } from 'modules/current-user/action';
import { updateNotification } from 'modules/notifications/action';
import { useAppDispatch } from 'modules/store';
import { Button } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  notification: UserNotification;
};

const NotificationsTableRow: FC<Props> = ({ notification }) => {
  const dispatch = useAppDispatch();

  const [isTextFullyShown, setIsTextFullyShown] = useState(false);
  const [isTextClamped, setIsTextClamped] = useState(false);

  const { message, companyName } = notification;

  const onRowClick = () => {
    setIsTextFullyShown(true);

    if (notification.status === NotificationStatus.UNREAD) {
      dispatch(updateNotification(notification.id))
        .unwrap()
        .then(() => dispatch(getUser()));
    }
  };

  const textRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    const resizeObserver = new ResizeObserver(() => {
      const isNodeTextClamped = node.scrollHeight > node.clientHeight;

      setIsTextClamped(isNodeTextClamped);
    });

    resizeObserver.observe(node);
  }, []);

  return (
    <>
      <TableRow
        className={cn('cursor-pointer', { 'bg-grey-100': notification.status === NotificationStatus.UNREAD })}
        onClick={onRowClick}
      >
        <TableData className={cn('pl-1', { italic: !companyName })}>{companyName || 'SYSTEM NOTIFICATION'}</TableData>
        <TableData>{format(parseISO(notification.createdAt), DAY_FORMAT_WITH_SLASHES)}</TableData>
        <TableData>
          {!isTextFullyShown && isTextClamped && (
            <Button variant={ButtonVariants.LINK} onClick={onRowClick}>
              See more
            </Button>
          )}
        </TableData>
      </TableRow>
      <TableRow className='border-b-0'>
        <TableData className='transition-all duration-500 py-4' colSpan={NOTIFICATIONS_TABLE_COLUMNS.length}>
          <div
            className={cn('grid [&>*]:overflow-hidden transition-all duration-500 grid-rows-[1fr]', {
              'grid-rows-[1fr]': isTextFullyShown,
            })}
          >
            <div
              ref={textRef}
              className={cn('col-span-full transition-all duration-500 px-2 ml-5', {
                'line-clamp-1': !isTextFullyShown,
              })}
            >
              {message}
            </div>
          </div>
        </TableData>
      </TableRow>
    </>
  );
};

export default NotificationsTableRow;
