import { SignificantControlPerson } from 'interfaces';

export const validateSignificantControlPersons = ({
  significantControlPersons,
  isParentDueDiligenceFinishedOrDeclined,
}: {
  significantControlPersons: SignificantControlPerson[];
  isParentDueDiligenceFinishedOrDeclined?: boolean;
}): boolean => {
  if (isParentDueDiligenceFinishedOrDeclined) {
    return false;
  }

  if (!significantControlPersons?.length) {
    return true;
  }

  return significantControlPersons?.some((significantControlPerson) =>
    validateSignificantControlPerson({ significantControlPerson }),
  );
};

export const validateSignificantControlPerson = ({
  significantControlPerson,
}: {
  significantControlPerson: SignificantControlPerson;
}) => {
  const { fullName, dateOfBirth, residentialAddresses } = significantControlPerson;

  if (!fullName || !dateOfBirth || !residentialAddresses?.length) return true;

  const isInvalidAddressFieldExists = residentialAddresses?.some(
    ({ address, from, to, tillNow }) => !address || !from || (!to && !tillNow),
  );

  return Boolean(isInvalidAddressFieldExists);
};
