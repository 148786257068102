import React from 'react';

import { NOTIFICATIONS_PAGE_TITLE } from 'constants/notifications';
import { DashboardLayout } from 'page-components';
import { TitleWithDescription } from 'shared-components';

import NotificationsTable from '../page-components/notifications/NotificationsTable';

const Notifications = () => {
  return (
    <DashboardLayout withoutLoader>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-6' title={NOTIFICATIONS_PAGE_TITLE} />
        <NotificationsTable />
      </div>
    </DashboardLayout>
  );
};

export default Notifications;
