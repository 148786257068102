import React, { FC, useEffect } from 'react';

import { SubmitHandler, useFieldArray, UseFieldArrayAppend, useForm, UseFormSetValue } from 'react-hook-form';

import { START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS } from 'constants/share-exchange';
import { TypographyVariants } from 'constants/shared/typography';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import {
  Option,
  StartShareExchangeWithCompanyFormValues,
  StartShareExchangesInvestmentsFormValues,
  StartShareExchangeWatchValues,
} from 'interfaces';
import { selectCompanyDealData } from 'modules/companies/selectors';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { getInvestors } from 'modules/investors/action';
import { createShareExchangeForInvestor } from 'modules/share-exchange/action';
import { selectWelcomeInvestorShareExchangeData } from 'modules/share-exchange/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal } from 'shared-components';

import ShareExchangeDetailsCommonFields from './ShareExchangeDetailsCommonFields';
import ShareExchangeInvestorFields from './ShareExchangeInvestorFields';

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  companyId?: number;
  isActionLoading?: boolean;
  title?: string;
}

const StartShareExchangesModalWithInvestor: FC<Props> = ({
  companyId,
  isActionLoading,
  title,
  onClose,
  ...modalProps
}) => {
  const dispatch = useAppDispatch();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);
  const { isLoading: isStartShareExchangeLoading } = useAppSelector(selectWelcomeInvestorShareExchangeData);

  const { data: companyDealPredefinedData, isLoading } = useAppSelector(selectCompanyDealData);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<StartShareExchangeWithCompanyFormValues>({
    defaultValues: {
      exchanges: [],
    },
  });

  const {
    fields: dealsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'exchanges',
  });

  const watchExchanges = watch('exchanges');

  const getFields = (
    watchValues: StartShareExchangeWatchValues,
    handleSetCompanyPrice: VoidFunction,
    classOfSharesOptions: Option[],
    closingDatesOptions: Option[],
    dealIndex: number,
  ) =>
    START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS(
      dealIndex,
      setValue as unknown as UseFormSetValue<StartShareExchangesInvestmentsFormValues>,
      watchValues,
      classOfSharesOptions,
      closingDatesOptions,
      handleSetCompanyPrice,
      isTypeEntity,
      entityName,
    );

  const onSubmit: SubmitHandler<StartShareExchangeWithCompanyFormValues> = ({
    exchanges,
    email,
    investorId,
    ...investor
  }) => {
    if (!companyId) return;

    const investorEmail = email.value;

    const formattedExchanges = exchanges.map(({ classOfShares, closingDate, ...rest }) => ({
      classOfShares: classOfShares?.value ?? '',
      closingDate: closingDate?.value ?? '',
      ...rest,
    }));
    dispatch(
      createShareExchangeForInvestor({
        exchanges: formattedExchanges,
        companyId,
        investorId: investorId ? investorId : null,
        investor: {
          ...investor,
          email: investorEmail,
        },
      }),
    )
      .unwrap()
      .then(() => {
        onClose();
        reset({});
      });
  };

  useEffect(() => {
    if (!companyDealPredefinedData?.companyName) return;

    const { companySharePrice, ...dealPredefinedData } = companyDealPredefinedData;

    const [{ companyPrice }] = companySharePrice || [];

    reset({
      exchanges: [
        {
          ...dealPredefinedData,
          companyPrice,
        },
      ],
    });
  }, [companyDealPredefinedData, reset]);

  useEffect(() => {
    dispatch(getInvestors({ page: 1, per_page: 20 }));
  }, [dispatch]);

  return (
    <AlertModal
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isActionLoading || isLoading || isStartShareExchangeLoading}
      title={title}
      submitButtonText='Submit Request'
      modalClassName='min-w-[70%] 2xl:min-w-[1048px]'
      titleVariant={TypographyVariants.LARGE}
      hideIcon
      onClose={onClose}
      {...modalProps}
    >
      {isLoading ? (
        <div className='skeleton-loader h-64 w-full' />
      ) : (
        <form className='w-full mt-8'>
          <ShareExchangeInvestorFields
            watch={watch}
            control={control}
            errors={errors}
            register={register}
            setValue={setValue}
          />
          <ShareExchangeDetailsCommonFields
            getFields={getFields}
            dealsFields={dealsFields}
            shareExchanges={watchExchanges}
            companyDealPredefinedData={companyDealPredefinedData}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            append={append as unknown as UseFieldArrayAppend<StartShareExchangesInvestmentsFormValues, 'exchanges'>}
            remove={remove}
          />
        </form>
      )}
    </AlertModal>
  );
};

export default StartShareExchangesModalWithInvestor;
