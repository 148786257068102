import React, { FC, useMemo } from 'react';

import { SIGNIFICANT_CONTROL_COMPANIES_TABLE_COLUMNS } from 'constants/due-diligence';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { selectChildrenDueDiligence, selectIsDueDiligenceFinishedOrDeclined } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import Table from 'shared-components/table';
import { checkIsAdmin, checkIsFmUser } from 'utils';

import SignificantControlCompaniesTableItem from './SignificantControlCompaniesTableItem';

type Props = {
  getUnfieldValue?: getUnfieldValueFunc;
  isConfirmationValidationHighlighted: boolean;
};

const SignificantControlCompaniesTable: FC<Props> = ({ getUnfieldValue }) => {
  const significantControlCompanies = useAppSelector(selectChildrenDueDiligence);
  const isDDFinishedOrDeclined = useAppSelector(selectIsDueDiligenceFinishedOrDeclined);

  const isAdmin = useMemo(() => checkIsAdmin(), []);
  const isFmUser = useMemo(() => checkIsFmUser(), []);

  if (!significantControlCompanies.length) {
    return null;
  }

  return (
    <Table
      columns={SIGNIFICANT_CONTROL_COMPANIES_TABLE_COLUMNS}
      className='table-simplified-tr table-simplified-th mb-6'
    >
      {significantControlCompanies.map((company, index) => (
        <SignificantControlCompaniesTableItem
          key={company.businessDetails?.id || index}
          isAdmin={isAdmin}
          isFmUser={isFmUser}
          company={company}
          getUnfieldValue={getUnfieldValue}
          isDDFinishedOrDeclined={isDDFinishedOrDeclined}
        />
      ))}
    </Table>
  );
};

export default SignificantControlCompaniesTable;
