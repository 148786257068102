import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

interface Props {
  rowNumber: number;
  children: ReactNode;
  className?: string;
  rowNumberClassName?: string;
  onDeleteRow?: (index: number) => () => void;
}

const DealParametersSharesFormRow: FC<Props> = ({
  rowNumber,
  children,
  className,
  rowNumberClassName,
  onDeleteRow,
}) => {
  return (
    <div className='flex gap-5 bg-grey-100 py-3 px-5 mb-10'>
      <div className={rowNumberClassName}>{rowNumber}.</div>
      <div className='flex gap-2 flex-1 flex-col sm:flex-row'>
        <div className={twMerge('flex flex-col sm:flex-row gap-5 flex-1', className)}>{children}</div>
        {onDeleteRow && (
          <Button
            className='self-center w-auto'
            type='button'
            variant={ButtonVariants.TRANSPARENT}
            onClick={onDeleteRow(rowNumber - 1)}
          >
            <DeleteSvg />
          </Button>
        )}
      </div>
    </div>
  );
};

export default DealParametersSharesFormRow;
