import React, { FC } from 'react';

import { InvestmentProposalBlockNames } from 'constants/company-iac';

import CompanyIacAddCustomFieldsListItem from './CompanyIacAddCustomFieldsListItem';

type Props = {
  fields?: number[];
  onDeleteField: (index: number) => void;
  blockName: InvestmentProposalBlockNames;
  disabled?: boolean;
};

const CompanyIacAddCustomFieldsList: FC<Props> = ({ fields, onDeleteField, blockName, disabled }) => {
  return (
    <ul>
      {fields?.map((field, index) => (
        <CompanyIacAddCustomFieldsListItem
          disabled={disabled}
          key={field}
          index={index}
          onDeleteField={onDeleteField}
          blockName={blockName}
        />
      ))}
    </ul>
  );
};

export default CompanyIacAddCustomFieldsList;
