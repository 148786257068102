import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { TodosState } from 'interfaces';

import { TODOS_SLICE_NAME } from './action';
import { getTodosReducer } from './reducers';

export const initialState: TodosState = {
  todosList: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
};

const todosSlice = createSlice({
  name: TODOS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getTodosReducer(builder);
  },
});

export const todosReducer = todosSlice.reducer;
