import React from 'react';

import {
  FOUNDER_INVITE_ADDITIONAL_TEXT,
  FOUNDER_INVITE_PAGE_FIELDS,
  FOUNDER_INVITE_PAGE_TITLE,
} from 'constants/invites';
import { ROUTES } from 'constants/routes';
import { InviteInput } from 'interfaces';
import { inviteFounder } from 'modules/auth/action';
import { selectFoundersList } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthHeader, AuthInviteForm, AuthLayout, HeaderInfo, InvitedList, SkipButtonsGroup } from 'page-components';

const FounderInvite = () => {
  const dispatch = useAppDispatch();
  const foundersList = useAppSelector(selectFoundersList);

  const onFounderInvite = (data: InviteInput) => dispatch(inviteFounder(data));

  return (
    <AuthLayout customHeader={<AuthHeader hideButtons />}>
      <div>
        <HeaderInfo title={FOUNDER_INVITE_PAGE_TITLE}>{FOUNDER_INVITE_ADDITIONAL_TEXT}</HeaderInfo>
        <AuthInviteForm
          onInvite={onFounderInvite}
          invitedCount={foundersList?.length}
          formFields={FOUNDER_INVITE_PAGE_FIELDS}
        />
        <InvitedList invitationList={foundersList} />
      </div>
      <SkipButtonsGroup linkPath={ROUTES.investorsInvite} />
    </AuthLayout>
  );
};

export default FounderInvite;
