import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';

import { LinkButton } from './index';

interface Props {
  className?: string;
}

const AddInvestmentButton: FC<Props> = ({ className }) => {
  return (
    <LinkButton variant={ButtonVariants.PRIMARY} className={twMerge('mx-4', className)} to={ROUTES.investments}>
      Add Investment
    </LinkButton>
  );
};

export default AddInvestmentButton;
