import { KeyboardEventHandler } from 'react';

import { COMPANY_NUMBER_FIELD_ALLOWED_KEYS, COMPANY_NUMBER_FIELD_CTRL_COMBINATIONS } from 'constants/entities';
import { LETTERS_PATTERN } from 'constants/global';

export const handleOnlyNumbersOnlySelect: KeyboardEventHandler<HTMLDivElement> = (event) => {
  const keyPressed = event.nativeEvent.key;

  const isCtrlCommand = event.ctrlKey || event.metaKey;

  if (
    !COMPANY_NUMBER_FIELD_ALLOWED_KEYS.includes(keyPressed) &&
    LETTERS_PATTERN.test(keyPressed) &&
    !(isCtrlCommand && COMPANY_NUMBER_FIELD_CTRL_COMBINATIONS.includes(keyPressed))
  ) {
    event.preventDefault();
  }
};
