import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { BillsState, GetBillsPayload, InvoicesMetrics } from 'interfaces';

import { getAdminInvoicesMetrics, getBills } from './action';

export function getBillsReducer(builder: ActionReducerMapBuilder<BillsState>) {
  builder.addCase(getBills.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getBills.fulfilled, (state, action: PayloadAction<GetBillsPayload>) => {
    const { data, meta } = action.payload;
    state.bills = data;
    state.totalPages = meta.last_page;
    state.total = meta.total;
    state.isLoading = false;
  });

  builder.addCase(getBills.rejected, (state) => {
    state.isLoading = false;
    state.bills = [];
  });
}

export function getAdminInvoicesMetricsReducer(builder: ActionReducerMapBuilder<BillsState>) {
  builder.addCase(getAdminInvoicesMetrics.pending, (state) => {
    state.invoicesMetrics.isLoading = true;
  });

  builder.addCase(getAdminInvoicesMetrics.fulfilled, (state, action: PayloadAction<InvoicesMetrics>) => {
    state.invoicesMetrics = {
      ...action.payload,
      isLoading: false,
    };
  });

  builder.addCase(getAdminInvoicesMetrics.rejected, (state) => {
    state.invoicesMetrics.isLoading = false;
  });
}
