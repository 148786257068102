import { useState } from 'react';

import { DUE_DILIGENCE_COMMENT_TYPES, DueDiligenceSections } from 'constants/due-diligence';
import { dueDiligenceAddComment } from 'modules/due-diligence/action';
import { selectDueDiligenceId } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

export type AddCommentFunction = (comment: string, reset: VoidFunction) => void;

export interface UseDueDiligenceCommentsReturnValue {
  handleAddComment: AddCommentFunction;
  isActionLoading: boolean;
}

const UseDueDiligenceComments = ({
  sectionType,
}: {
  sectionType: DueDiligenceSections;
}): UseDueDiligenceCommentsReturnValue => {
  const dispatch = useAppDispatch();
  const dueDiligenceId = useAppSelector(selectDueDiligenceId);

  const [isActionLoading, setIsActionLoading] = useState(false);

  const handleAddComment: AddCommentFunction = (comment, reset) => {
    if (dueDiligenceId) {
      setIsActionLoading(true);
      dispatch(
        dueDiligenceAddComment({
          dueDiligenceId,
          sectionType,
          text: comment,
          type: DUE_DILIGENCE_COMMENT_TYPES[sectionType],
        }),
      )
        .unwrap()
        .finally(() => {
          setIsActionLoading(false);
          reset();
        });
    }
  };

  return { handleAddComment, isActionLoading };
};

export default UseDueDiligenceComments;
