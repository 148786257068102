import React from 'react';

const CompaniesItemSkeleton = () => {
  return (
    <div className='flex items-center justify-between border border-grey-300 rounded-10 py-6 px-10 mt-6'>
      <div className='w-2/12'>
        <div className='skeleton-loader h-5 w-1/2' />
        <div className='skeleton-loader h-10 1/3 mt-2' />
      </div>
      <div className='skeleton-loader h-24 w-7/12' />
      <div className='skeleton-loader h-10 w-2/12 ml-4' />
    </div>
  );
};

export default CompaniesItemSkeleton;
