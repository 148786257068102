import React, { FC, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  COMPANY_IAC_DECLINE_MODAL_DESCRIPTION,
  COMPANY_IAC_DECLINE_MODAL_FIELDS,
  COMPANY_IAC_DECLINE_MODAL_TITLE,
} from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyIacDeclineFormState } from 'interfaces';
import { declineCompanyIac } from 'modules/company-iac/action';
import { selectCompanyIacId } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal, FormContent } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const CompanyIacDeclineModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const companyIacId = useAppSelector(selectCompanyIacId);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CompanyIacDeclineFormState>();

  const onSubmit: SubmitHandler<CompanyIacDeclineFormState> = (data) => {
    setIsLoading(true);

    dispatch(declineCompanyIac({ reason: data.reason, id: companyIacId }))
      .unwrap()
      .then(onClose)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      title={COMPANY_IAC_DECLINE_MODAL_TITLE}
      description={COMPANY_IAC_DECLINE_MODAL_DESCRIPTION}
      descriptionClassName='mt-7'
      submitButtonText='Confirm'
      backButtonText='Cancel'
      modalClassName='lg:w-8/12 xl:w-6/12'
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
    >
      <form className='w-full mt-8'>
        <FormContent fields={COMPANY_IAC_DECLINE_MODAL_FIELDS} register={register} errors={errors} />
      </form>
    </AlertModal>
  );
};

export default CompanyIacDeclineModal;
