import { createSlice } from '@reduxjs/toolkit';

import {
  DEALS_METRICS_DEFAULT_PIE_CHART_STATE,
  DEALS_METRICS_DEFAULT_TOTAL_STARTED_METRICS,
  DEALS_METRICS_DEFAULT_TOTAL_FINISHED_METRICS,
} from 'constants/deals';
import { META_INITIAL_STATE } from 'constants/global';
import { DealsState } from 'interfaces';

import { DEALS_SLICE_NAME } from './action';
import {
  getDashboardDealsReducer,
  getDealsReducer,
  getDealsOptionsReducer,
  getDealsStaticMetricsReducer,
  getFinishedDealsMetricsReducer,
  getStartedDealsMetricsReducer,
  getDealsParametersReducer,
  setDealParametersReducer,
  getCompanyDealPredefinedDataReducer,
  stopDealByIdReducer,
  stopDealsParametersByIdReducer,
  getDealStagesReducer,
  updateDealParametersByIdReducer,
  getDealsProgressListReducer,
  setDealDetailsReducer,
  getLastDraftDealPredefinedDataReducer,
  restartDealByIdReducer,
  updateDealFeeReducer,
} from './reducers';

export const initialState: DealsState = {
  isLoading: false,
  deals: null,
  dealsParameters: {
    list: [],
    ...META_INITIAL_STATE,
  },
  dealsOptions: [],
  dealDetails: null,
  dealStages: [],
  previewDeals: [],
  previewDealsTemplates: [],
  dashboardDeals: null,
  dealParameters: null,
  companyDealPredefinedData: null,
  totalDeals: 0,
  totalPages: 1,
  dealsMetrics: {
    isLoading: false,
    ...DEALS_METRICS_DEFAULT_PIE_CHART_STATE,
    totalStarted: { isLoading: false, ...DEALS_METRICS_DEFAULT_TOTAL_STARTED_METRICS },
    totalFinished: { isLoading: false, ...DEALS_METRICS_DEFAULT_TOTAL_FINISHED_METRICS },
  },
  dealsProgress: [],
};

const dealsSlice = createSlice({
  name: DEALS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getDealsReducer(builder);
    setDealDetailsReducer(builder);
    getDashboardDealsReducer(builder);
    getDealsOptionsReducer(builder);
    restartDealByIdReducer(builder);
    getDealsStaticMetricsReducer(builder);
    getFinishedDealsMetricsReducer(builder);
    getStartedDealsMetricsReducer(builder);
    getDealsParametersReducer(builder);
    setDealParametersReducer(builder);
    getCompanyDealPredefinedDataReducer(builder);
    stopDealByIdReducer(builder);
    stopDealsParametersByIdReducer(builder);
    getDealStagesReducer(builder);
    updateDealParametersByIdReducer(builder);
    getDealsProgressListReducer(builder);
    getLastDraftDealPredefinedDataReducer(builder);
    updateDealFeeReducer(builder);
  },
});

export const dealsReducer = dealsSlice.reducer;
