import React, { FC, useState } from 'react';

import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { TypographyStyles } from 'constants/shared/typography';
import { NumberOrNull } from 'interfaces';
import Typography from 'shared-components/Typography';

interface Props {
  rectClassName?: string;
  title: string;
  value: string | NumberOrNull;
  showTooltip?: boolean;
  tooltipMessage?: string;
  tooltipId?: string;
}

const ChartInfoItemWithColoredRect: FC<Props> = ({
  rectClassName,
  title,
  value,
  showTooltip,
  tooltipMessage,
  tooltipId,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <>
      <div className='flex flex-col'>
        <div
          className='flex items-center justify-start'
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          <h5 className={TypographyStyles.body_normal} id={tooltipId}>
            {title}
          </h5>
        </div>
        <div className='mt-1 flex items-center'>
          <div className={twMerge('mr-1 min-h-[16px] h-4 min-w-[16px] rounded-full bg-red-500', rectClassName)} />
          <Typography className='text-grey-800'>{value}</Typography>
        </div>
      </div>

      {showTooltip && (
        <Tooltip
          anchorId={tooltipId}
          isOpen={isTooltipOpen}
          positionStrategy='fixed'
          className='text-sm text-grey-500 z-10'
          offset={40}
          noArrow
        >
          {tooltipMessage}
        </Tooltip>
      )}
    </>
  );
};

export default ChartInfoItemWithColoredRect;
