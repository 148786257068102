import React from 'react';

import { INVESTMENTS_DOWNLOAD_TEMPLATE_PATH } from 'constants/investments';
import { getLinkWithOriginPrefix } from 'utils';

const ReviewUploadedInvestmentsHeaderDescription = () => {
  const investmentsTemplateDownloadPath = getLinkWithOriginPrefix(INVESTMENTS_DOWNLOAD_TEMPLATE_PATH);

  return (
    <div className='pt-2 pb-6'>
      <div>
        <span className='text-grey-500'>If you are having errors, you can download a new template</span>
        <a href={investmentsTemplateDownloadPath} download className='pl-0.5 text-blue-600 underline cursor-pointer'>
          Here
        </a>
      </div>
      <div className='text-grey-500'>*when using the template you must add Company Name to every row</div>
    </div>
  );
};

export default ReviewUploadedInvestmentsHeaderDescription;
