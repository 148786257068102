import React from 'react';

import { BUSINESS_PROFILE_PAGE_TITLE } from 'constants/business-profile';
import { ButtonVariants } from 'constants/shared/button';
import { Button, TitleWithDescription } from 'shared-components';

const BusinessProfileHeader = () => {
  return (
    <div className='flex justify-between items-center flex-col md:flex-row gap-4 md:gap-0 mb-9'>
      <TitleWithDescription title={BUSINESS_PROFILE_PAGE_TITLE} />
      <div className='flex justify-between gap-2 flex-col xs:flex-row w-full xs:w-auto'>
        <Button className='w-full xs:w-150' variant={ButtonVariants.SECONDARY} disabled>
          Action
        </Button>
        <Button className='w-full xs:w-150 md:ml-5 whitespace-nowrap' disabled>
          Edit information
        </Button>
      </div>
    </div>
  );
};

export default BusinessProfileHeader;
