import React, { FC } from 'react';

type Props = {
  rowsNumber: number;
  columnsNumber: number;
};

export const TABLE_SKELETON_ROW_TEST_ID = 'TABLE_SKELETON_ROW_TEST_ID';
export const TABLE_SKELETON_DATA_TEST_ID = 'TABLE_SKELETON_DATA_TEST_ID';

const TableSkeleton: FC<Props> = ({ rowsNumber, columnsNumber }) => (
  <>
    {[...Array(rowsNumber)].map((_, rIndex) => (
      <tr data-testid={TABLE_SKELETON_ROW_TEST_ID} key={rIndex}>
        {[...Array(columnsNumber)].map((_, cIndex) => (
          <td data-testid={TABLE_SKELETON_DATA_TEST_ID} key={cIndex} className='h-[70px]'>
            <div className='h-full skeleton-loader' />
          </td>
        ))}
      </tr>
    ))}
  </>
);

export default TableSkeleton;
