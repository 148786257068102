import React, { FC, useEffect, useMemo, useState } from 'react';

import { Control, SubmitHandler, useForm } from 'react-hook-form';

import { EMAIL_FIELD_VALIDATION, EMAIL_PATTERN } from 'constants/global';
import { ButtonVariants } from 'constants/shared/button';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { DealPreviewModalData, DealSendClosureOverviewFormInput } from 'interfaces';
import { sendDealClosureOverview } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';
import { Button, CreatableSelectFormInput, ModalWindow } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getDealEmailPreviewModalDescription, getDealEmailPreviewModalTitle } from 'utils';

interface Props extends Omit<UseModalReturnValues, 'onOpen' | 'modalProps'> {
  id: number | undefined;
  onClose: VoidFunction;
  previewData: DealPreviewModalData;
  selectedClosingDates: string[];
  deleteDates: VoidFunction;
  handleBack: VoidFunction;
}

const DealClosureOverviewEmailPreviewModal: FC<Props> = ({
  id,
  onClose,
  previewData,
  selectedClosingDates,
  deleteDates,
  handleBack,
  ...modalProps
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { handleSubmit, control, reset } = useForm<DealSendClosureOverviewFormInput>();

  const { templates, founders } = previewData;

  const isFoundersExists = founders?.length;

  const lastStepIndex = isFoundersExists ? templates?.length - 1 : templates?.length;

  const isAvailableToSend = currentStepIndex === lastStepIndex;

  const isShownEmailsInput = currentStepIndex === templates?.length && !isFoundersExists;

  const handlePreviousModal = () => {
    if (currentStepIndex === 0) handleBack();

    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const handleNextModal = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const onCloseModal = () => {
    onClose();
    deleteDates();
  };

  const onSendDealClosureOverview: SubmitHandler<DealSendClosureOverviewFormInput> = ({ email: emailOptions }) => {
    if (!id) return;

    if (currentStepIndex < templates?.length && !isFoundersExists) {
      handleNextModal();
      return;
    }
    setIsLoading(true);

    const emails = emailOptions?.map(({ value }) => value);

    dispatch(
      sendDealClosureOverview({
        id,
        closingDates: selectedClosingDates,
        ...(!isFoundersExists && emails?.length && { emails }),
      }),
    )
      .unwrap()
      .then(() => {
        deleteDates();
        onClose();
      })
      .finally(() => {
        setIsLoading(false);

        reset();
      });
  };

  const formattedTemplate = useMemo(() => {
    return previewData?.templates?.[currentStepIndex]?.template || '';
  }, [previewData, currentStepIndex]);

  const onCreateOption = (inputValue: string) => {
    return EMAIL_PATTERN.test(inputValue);
  };

  useEffect(() => {
    if (modalProps.isOpen) {
      setCurrentStepIndex(0);
    }
  }, [modalProps.isOpen]);

  return (
    <ModalWindow
      title={getDealEmailPreviewModalTitle(isAvailableToSend)}
      description={getDealEmailPreviewModalDescription(isAvailableToSend, isShownEmailsInput)}
      descriptionClassName='!text-blue-800 mt-1'
      className='min-w-[60%] xl:min-w-[52%]'
      wrapperClassName='lg:p-10'
      contentClassName='overflow-x-hidden'
      onClose={onCloseModal}
      {...modalProps}
    >
      <form onSubmit={handleSubmit(onSendDealClosureOverview)}>
        {isShownEmailsInput ? (
          <div className='h-24 mt-5'>
            <CreatableSelectFormInput
              className='w-[450px] mb-0 ml-6'
              label='Emails'
              isMulti
              control={control as unknown as Control}
              validation={EMAIL_FIELD_VALIDATION}
              name='email'
              onCreateOption={onCreateOption}
            />
          </div>
        ) : (
          templates?.[currentStepIndex] && (
            <>
              <Typography className='mt-5 mb-2'>Email Preview</Typography>

              <div
                dangerouslySetInnerHTML={{ __html: formattedTemplate }}
                className='p-2 [&_a]:text-violet-500 bg-grey-100 border border-grey-300 h-[300px] overflow-y-auto'
              />
            </>
          )
        )}
        <div className='flex justify-between mt-[60px]'>
          {currentStepIndex >= 0 && (
            <Button
              className='w-150'
              type='button'
              variant={ButtonVariants.SECONDARY}
              onClick={handlePreviousModal}
              disabled={isLoading}
            >
              {currentStepIndex > 0 ? 'Previous' : 'Back'}
            </Button>
          )}

          {currentStepIndex < lastStepIndex && (
            <Button className='w-150' type='button' onClick={handleNextModal} disabled={isLoading}>
              Next
            </Button>
          )}

          {isAvailableToSend && (
            <Button className='w-150' isLoading={isLoading}>
              Send
            </Button>
          )}
        </div>
      </form>
    </ModalWindow>
  );
};

export default DealClosureOverviewEmailPreviewModal;
