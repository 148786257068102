import React from 'react';

const UserPageSkeleton = () => (
  <div className='contentful-dashboard-page'>
    <div className='mb-10'>
      <div className='skeleton-loader h-[50px] w-2/6 mb-6' />

      <div className='skeleton-loader h-6 w-350 mb-2' />
      <div className='skeleton-loader h-6 w-350 mb-2' />
      <div className='skeleton-loader h-6 w-350 mb-2' />
    </div>
  </div>
);

export default UserPageSkeleton;
