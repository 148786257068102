import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ShareExchangeStatuses } from 'constants/share-exchange';
import { ShareExchangeRequestsResponse, ShareExchangeState, SingleShareExchange } from 'interfaces';

import {
  getShareExchange,
  getShareExchangeRequests,
  cancelShareExchange,
  acceptShareExchange,
  declineShareExchange,
  getWelcomeInvestorShareExchangeData,
  createShareExchangeForInvestor,
} from './action';

export function getShareExchangeRequestsReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(getShareExchangeRequests.pending, (state) => {
    state.requests.isLoading = true;
  });

  builder.addCase(getShareExchangeRequests.fulfilled, (state, action: PayloadAction<ShareExchangeRequestsResponse>) => {
    state.requests.isLoading = false;
    state.requests.list = action.payload.data;
    state.requests.totalPages = action.payload.meta.last_page;
    state.requests.total = action.payload.meta.total;
  });

  builder.addCase(getShareExchangeRequests.rejected, (state) => {
    state.requests.isLoading = false;
  });
}

export function getShareExchangeReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(getShareExchange.pending, (state) => {
    state.review.isLoading = true;
  });

  builder.addCase(getShareExchange.fulfilled, (state, action: PayloadAction<{ data: SingleShareExchange }>) => {
    state.review.isLoading = false;
    state.review.shareExchange = action.payload.data;
  });

  builder.addCase(getShareExchange.rejected, (state) => {
    state.review.isLoading = false;
    state.review.shareExchange = {} as SingleShareExchange;
  });
}

export function cancelShareExchangeReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(cancelShareExchange.pending, (state) => {
    state.review.isLoading = true;
  });

  builder.addCase(cancelShareExchange.fulfilled, (state) => {
    state.review.isLoading = false;
    state.review.shareExchange.status = ShareExchangeStatuses.CANCELED;
  });

  builder.addCase(cancelShareExchange.rejected, (state) => {
    state.review.isLoading = false;
  });
}

export function acceptShareExchangeReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(acceptShareExchange.pending, (state) => {
    state.review.isLoading = true;
  });

  builder.addCase(acceptShareExchange.fulfilled, (state) => {
    state.review.isLoading = false;
    state.review.shareExchange.status = ShareExchangeStatuses.IN_PROGRESS;
  });

  builder.addCase(acceptShareExchange.rejected, (state) => {
    state.review.isLoading = false;
  });
}

export function createShareExchangeForInvestorReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(createShareExchangeForInvestor.pending, (state) => {
    state.welcomeInvestorShareExchangeData.isLoading = true;
  });

  builder.addCase(createShareExchangeForInvestor.fulfilled, (state) => {
    state.welcomeInvestorShareExchangeData.isLoading = false;
  });

  builder.addCase(createShareExchangeForInvestor.rejected, (state) => {
    state.welcomeInvestorShareExchangeData.isLoading = false;
  });
}

export function declineShareExchangeReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(declineShareExchange.pending, (state) => {
    state.review.isLoading = true;
  });

  builder.addCase(declineShareExchange.fulfilled, (state, action) => {
    state.review.isLoading = false;
    state.review.shareExchange.status = ShareExchangeStatuses.DECLINED;
    state.review.shareExchange.reason = action.payload.reason;
  });

  builder.addCase(declineShareExchange.rejected, (state) => {
    state.review.isLoading = false;
  });
}

export function getWelcomeInvestorShareExchangeDataReducer(builder: ActionReducerMapBuilder<ShareExchangeState>) {
  builder.addCase(getWelcomeInvestorShareExchangeData.pending, (state) => {
    state.welcomeInvestorShareExchangeData.isLoading = true;
    state.welcomeInvestorShareExchangeData.list = null;
  });

  builder.addCase(getWelcomeInvestorShareExchangeData.fulfilled, (state, action) => {
    state.welcomeInvestorShareExchangeData.isLoading = false;
    state.welcomeInvestorShareExchangeData.list = action.payload;
  });

  builder.addCase(getWelcomeInvestorShareExchangeData.rejected, (state) => {
    state.welcomeInvestorShareExchangeData.isLoading = false;
    state.welcomeInvestorShareExchangeData.list = null;
  });
}
