import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectState = (x: RootState) => x.foundersReducer;

export const selectFoundersData = createSelector(selectState, ({ foundersList }) => foundersList);

export const selectFounderData = createSelector(selectState, ({ founder }) => founder);

export const selectFoundersMetrics = createSelector(selectState, ({ foundersMetrics }) => foundersMetrics);

export const selectFoundersCompanies = createSelector(selectState, ({ foundersCompanies }) => foundersCompanies.list);

export const selectFoundersCompaniesLoading = createSelector(
  selectState,
  ({ foundersCompanies }) => foundersCompanies.isLoading,
);
