import { UseFormReset } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { StartShareExchangesInvestmentItem, StartShareExchangesInvestmentsFormValues } from 'interfaces';
import { createCompaniesShareExchanges } from 'modules/investments/action';
import { useAppDispatch } from 'modules/store';
import { getDealDetailsLink } from 'utils';

import useModal from './use-modal/useModal';

const useStartInvestmentShareExchange = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    isOpen: isOpenStartShareExchange,
    onClose: onCloseStartShareExchange,
    onOpen: onOpenStartShareExchange,
  } = useModal();

  const onSuccessStartShareExchange = (
    shareExchanges: StartShareExchangesInvestmentItem[],
    companyId: number,
    loadingCallback: VoidFunction,
    reset: UseFormReset<StartShareExchangesInvestmentsFormValues>,
    investmentIds?: number[],
    draftDealId?: number,
  ) => {
    dispatch(createCompaniesShareExchanges({ companyId, shareExchanges, investmentIds, draftDealId }))
      .unwrap()
      .then((response) => {
        onCloseStartShareExchange();
        loadingCallback();
        reset();
        navigate(getDealDetailsLink(response.id));
      })
      .finally(loadingCallback);
  };

  return {
    onSuccessStartShareExchange,
    isOpenStartShareExchange,
    onCloseStartShareExchange,
    onOpenStartShareExchange,
  };
};

export default useStartInvestmentShareExchange;
