import React from 'react';

import { COMPANY_PROFILE_FINANCIAL_OVERVIEW } from 'constants/company-profile';
import { CompanyProfileFinancialItem } from 'page-components';
import { LinkButton, SnippetLayout } from 'shared-components';
import { AreaChart } from 'shared-components/chart';

const mockData = [
  {
    name: 'M',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'T',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'W',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'T',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'F',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'S',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'S',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const CompanyProfileFinancialOverviewSnippet = () => {
  return (
    <SnippetLayout className='mt-10' title={COMPANY_PROFILE_FINANCIAL_OVERVIEW}>
      <div className='h-[250px]'>
        <AreaChart data={mockData} height={140} />
      </div>
      <div className='mt-10 ml-5 flex flex-wrap gap-y-4'>
        <CompanyProfileFinancialItem />
        <CompanyProfileFinancialItem />
        <CompanyProfileFinancialItem />
        <CompanyProfileFinancialItem />
        <CompanyProfileFinancialItem />
        <CompanyProfileFinancialItem />
      </div>
      <LinkButton className='mt-6' to='/'>
        View All Financials
      </LinkButton>
    </SnippetLayout>
  );
};

export default CompanyProfileFinancialOverviewSnippet;
