import React, { FC } from 'react';

import CompanyInviteFounder from 'page-components/company-profile/company-profile-invite/CompanyInviteFounder';
import { CompanyProfileInvite } from 'page-components/index';

interface Props {
  companyId?: string | number;
}
const CompanyProfileInvites: FC<Props> = ({ companyId }) => {
  return (
    <>
      <CompanyProfileInvite
        companyId={companyId}
        hasActiveDeal
        className='flex-col sm:flex-row gap-4 sm:gap-0'
        buttonClassName='whitespace-nowrap w-full sm:w-150'
      />
      <CompanyInviteFounder companyId={companyId} />
    </>
  );
};
export default CompanyProfileInvites;
