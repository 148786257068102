import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { TableRowVariants } from 'constants/shared';
import { CompanyOfficer } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

const CompanyProfileOfficersItem: FC<CompanyOfficer> = ({ firstName, lastName, appointedOn }) => {
  return (
    <TableRow variant={TableRowVariants.SIMPLIFIED}>
      <TableData className='pl-2'>{firstName}</TableData>
      <TableData>{lastName}</TableData>
      <TableData>{format(parseISO(appointedOn), DAY_FORMAT_WITH_SLASHES)}</TableData>
    </TableRow>
  );
};

export default CompanyProfileOfficersItem;
