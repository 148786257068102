import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { AVAILABLE_MY_INVESTMENT_TABLE_ITEM_KEYS } from 'constants/my-investments';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyActiveShareExchange, investmentStatusesColoredLabel, InvestmentWithCompany } from 'interfaces';
import { ColoredLabel, LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getCompanyProfileLink, getShareExchangeLink } from 'utils';
import { formatNumberInputValue } from 'utils/shared';

interface Props {
  investment: InvestmentWithCompany;
  companyId: number;
  companyName: string;
  companyNumber: string;
  isFirstElement: boolean;
  companyActiveShareExchange: CompanyActiveShareExchange | null;
}

const MyCompaniesWithFinalisedInvestmentsTableItem: FC<Props> = ({
  investment,
  companyId,
  companyNumber,
  companyName,
  isFirstElement,
  companyActiveShareExchange,
}) => {
  const { originalInvestmentPrice, totalShares } = investment;

  return (
    <TableRow className='bg-grey-200'>
      <TableData className='pl-2'>
        <LinkButton
          variant={ButtonVariants.SECONDARY}
          className='w-120'
          to={getShareExchangeLink(companyActiveShareExchange?.id)}
        >
          View
        </LinkButton>
      </TableData>
      <TableData>{isFirstElement && <Link to={getCompanyProfileLink(companyId)}>{companyName}</Link>}</TableData>

      <TableData>{companyNumber}</TableData>
      {AVAILABLE_MY_INVESTMENT_TABLE_ITEM_KEYS.map(({ key, prefix = '' }) => (
        <TableData key={key}>{`${prefix} ${(investment as Record<string, any>)[key]}`}</TableData>
      ))}

      <TableData>{formatNumberInputValue(totalShares, 4)}</TableData>
      <TableData>{originalInvestmentPrice && `£${formatNumberInputValue(originalInvestmentPrice)}`}</TableData>
      <TableData>
        {investment.status && <ColoredLabel {...investmentStatusesColoredLabel[investment.status]} />}
      </TableData>
    </TableRow>
  );
};

export default MyCompaniesWithFinalisedInvestmentsTableItem;
