import React, { FC, useMemo } from 'react';

import { DealShareExchangeDetails } from 'interfaces';

import ShareExchangeInfoItem from '../../ShareExchangeInfoItem';

type Props = {
  isLoading: boolean;
  shareExchangeDetails?: DealShareExchangeDetails[];
};

const ShareExchangeDetailsTotals: FC<Props> = ({ isLoading, shareExchangeDetails }) => {
  const totalSharesForExchange = useMemo(() => {
    if (!shareExchangeDetails?.length) {
      return 0;
    }

    return shareExchangeDetails.reduce((acc, { noOfShares }) => acc + parseInt(noOfShares, 10), 0);
  }, [shareExchangeDetails]);

  const totalSharesWillBeRecieved = useMemo(() => {
    if (!shareExchangeDetails?.length) {
      return 0;
    }

    return shareExchangeDetails.reduce((acc, { noExchangedShares }) => acc + parseInt(noExchangedShares, 10), 0);
  }, [shareExchangeDetails]);

  if (isLoading) {
    return <div className='skeleton-loader w-5/12 2xl:w-4/12 h-150' />;
  }

  if (!shareExchangeDetails?.length) {
    return <div className='underline'>No Items Available</div>;
  }

  return (
    <ul className='w-5/12 2xl:w-4/12 py-4'>
      {shareExchangeDetails.map(({ id, classOfShares, noOfShares }) => (
        <ShareExchangeInfoItem key={id} label={classOfShares} value={noOfShares} />
      ))}

      <hr className='bg-grey-200 w-full my-2' />

      <ShareExchangeInfoItem label='Total Shares for Exchange' value={totalSharesForExchange} />
      <ShareExchangeInfoItem label='Total Shares will be recieved' value={totalSharesWillBeRecieved} />
    </ul>
  );
};

export default ShareExchangeDetailsTotals;
