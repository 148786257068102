import React, {
  PropsWithChildren,
  DragEvent,
  ChangeEvent,
  ElementType,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from 'react';

import { useForm, SubmitHandler, DeepPartial, UseFormReset, FieldValues } from 'react-hook-form';

import { ReactComponent as TrashCanIcon } from 'assets/svg/trash-can.svg';
import { UPLOAD_FILE_MAX_FILE_SIZE_ERROR, UPLOAD_FILE_WRONG_FILETYPE_ERROR } from 'constants/shared';
import { errorNotify } from 'helpers';
import { FileData, PromiseBooleanOrVoid } from 'interfaces';
import { Button } from 'shared-components';
import Typography from 'shared-components/Typography';

import DragUploadArea from './DragUploadArea';

type Props<T extends FieldValues> = {
  onSubmitUpload: (files: File[], data?: T, resetFormFunction?: UseFormReset<T>) => PromiseBooleanOrVoid;
  allowedFileTypes: string[];
  maxFileSize: number;
  allowedFileHelpText: string;
  dragAreaTitle?: string;
  onClose?: VoidFunction;
  multiple?: boolean;
  inputs?: ElementType;
  inputsProps?: Record<string, unknown>;
  isLoading?: boolean;
  submitButtonText?: string;
  initialFormValues?: DeepPartial<T>;
  disabled?: boolean;
  allowedFileTypeErrorMessage?: string;
  allowedFileSizeError?: ReactNode | string;
  watchFieldName?: string;
  onError?: (error: string | boolean) => void;
};

const UploadFileForm = <T extends FieldValues>({
  onClose,
  onSubmitUpload,
  multiple = false,
  allowedFileTypes,
  maxFileSize,
  allowedFileHelpText,
  dragAreaTitle,
  isLoading,
  submitButtonText,
  initialFormValues,
  inputs: Inputs,
  allowedFileTypeErrorMessage,
  allowedFileSizeError,
  onError,
  inputsProps = {},
}: PropsWithChildren<Props<T>>) => {
  const fileInput = useRef<null | HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileData, setFileData] = useState<FileData[]>([]);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<T>();
  const watchAllValues = watch();

  const isDisabledUploadButton = Boolean(Inputs)
    ? !!Object.keys(errors).length || !selectedFiles?.length
    : !selectedFiles?.length;

  const handleFile = (file: File) => {
    const { type: fileType, size: fileSize } = file;

    if (!allowedFileTypes.includes(fileType)) {
      errorNotify(allowedFileTypeErrorMessage || UPLOAD_FILE_WRONG_FILETYPE_ERROR);
      return;
    }

    if (fileSize > maxFileSize) {
      errorNotify(allowedFileSizeError || UPLOAD_FILE_MAX_FILE_SIZE_ERROR, {
        closeOnClick: false,
      });

      onError?.(true);
      return;
    }

    const fileSizeMB = (fileSize / 1000000).toPrecision(2);

    setFileData((files) => [...files, { name: file.name, size: fileSizeMB, id: Date.now() }]);
    setSelectedFiles((files) => [...files, file]);
  };

  const onDrop = (ev: DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    const { files } = ev.dataTransfer;

    if (files && files[0]) {
      handleFile(files[0]);
    }
  };

  const onClickBrowseFile = () => {
    if (isLoading) return;

    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files[0]) {
      handleFile(files[0]);
    }

    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  const handleDeleteFile = (removedFileIndex: number) => {
    setFileData((currentFileData) => currentFileData.filter((_, index) => index !== removedFileIndex));
    setSelectedFiles((currentFiles) => currentFiles.filter((_, index) => index !== removedFileIndex));
  };

  const removeAllFiles = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }

    setFileData([]);
    setSelectedFiles([]);
  };

  const onSubmit: SubmitHandler<T> = async (data) => {
    if (!selectedFiles.length) {
      return;
    }

    const shouldFilesBeSaved = await onSubmitUpload(selectedFiles, data, reset);
    if (!shouldFilesBeSaved) removeAllFiles();

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (initialFormValues) {
      reset({ ...initialFormValues });
    } else {
      reset();
    }
    removeAllFiles();
  }, [initialFormValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Inputs && (
        <Inputs control={control} register={register} errors={errors} values={watchAllValues} {...inputsProps} />
      )}

      {(!fileData.length || multiple) && (
        <DragUploadArea
          dragAreaTitle={dragAreaTitle}
          allowedFileHelpText={allowedFileHelpText}
          onDrop={onDrop}
          onClickBrowseFile={onClickBrowseFile}
          isLoading={isLoading}
        />
      )}

      {fileData.length > 0 && (
        <div className='upload-files'>
          {fileData.map((file, fileIndex) => (
            <div key={file.id} className='not:first:mt-2 flex'>
              <TrashCanIcon
                onClick={() => handleDeleteFile(fileIndex)}
                className='mr-2 cursor-pointer [&_path]:fill-blue-600'
              />
              <Typography className='text-grey-800' tag='span'>{`${file.name} - ${file.size}mb`}</Typography>
            </div>
          ))}
        </div>
      )}

      <input ref={fileInput} className='hidden' type='file' onChange={onSelectedFile} multiple={false} />

      <Button type='submit' className='mt-6' isLoading={isLoading} disabled={isDisabledUploadButton}>
        {submitButtonText || 'Next'}
      </Button>
    </form>
  );
};

export default UploadFileForm;
