import { CompanyIacCustomField } from 'interfaces';

import { companiesIacApis, fmUserCompaniesIacApis } from '../apis';

export const prepareCustomFieldsForUpload = (
  customFields: CompanyIacCustomField[],
  values: Record<string, string>,
  deletedFieldsId?: number[],
) => {
  if (!customFields) return [];

  if (!deletedFieldsId) {
    return customFields.map((field) => ({ ...field, value: values[String(field?.name)] || '' }));
  }

  return customFields
    ?.filter((field) => !deletedFieldsId.includes(field.id))
    .map((field) => ({ ...field, value: values[String(field?.name)] || '' }));
};

export const getCustomFieldsValues = (customFields: CompanyIacCustomField[]) => {
  return customFields.reduce((prev, current) => ({ ...prev, [current.name]: current.value }), {});
};

export const getCompanyIacRequestByConditions = (companyId: string, iacId?: string | undefined, isAdmin?: boolean) => {
  if (companyId && iacId) return companiesIacApis.getCompanyIacByIacId({ companyId, iacId });

  if (isAdmin) return companiesIacApis.getCompanyIac(companyId);

  return fmUserCompaniesIacApis.getCompanyIac(companyId);
};
