import React, { useEffect } from 'react';

import { MY_INVESTMENTS_SNIPPET_COLUMNS, MY_INVESTMENTS_SNIPPET_TITLE } from 'constants/dashboard';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { getCompaniesWithInvestments } from 'modules/investments/action';
import { selectCompaniesWithInvestments, selectIsLoading } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import AddInvestmentButton from 'shared-components/AddInvestmentButton';
import Table, { TableSkeleton } from 'shared-components/table';
import { getTitleWithReplacedYourWord } from 'utils';
import { getFormattedCompanyWithInvestmentsWithDaxia } from 'utils/investments';

import { SnippetLayout } from '../../../../shared-components';
import InvestmentSnippetItem from './InvestmentSnippetItem';
import InvestmentSnippetNoInfo from './InvestmentSnippetNoInfo';

const REQUEST_PARAMS = {
  page: 1,
  per_page: 10,
  filter: `filter[status][]=active,inExchange`,
};

const MyInvestmentsSnippet = () => {
  const dispatch = useAppDispatch();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);
  const companiesWithInvestment = useAppSelector(selectCompaniesWithInvestments);
  const isLoading = useAppSelector(selectIsLoading);

  const formattedCompaniesWithInvestments = getFormattedCompanyWithInvestmentsWithDaxia(companiesWithInvestment);

  useEffect(() => {
    dispatch(getCompaniesWithInvestments(REQUEST_PARAMS));
  }, [dispatch]);

  return (
    <SnippetLayout
      title={getTitleWithReplacedYourWord(MY_INVESTMENTS_SNIPPET_TITLE, entityName, isTypeEntity)}
      className='row-start-4 row-end-5 col-start-1 col-end-3 [&>div]:flex-col sm:[&>div]:flex-row [&_p]:mr-4'
      customHeader={
        formattedCompaniesWithInvestments?.length ? (
          <AddInvestmentButton className='mr-0 mt-6 ml-0 sm:ml-4 sm:mt-0' />
        ) : null
      }
    >
      <Table columns={MY_INVESTMENTS_SNIPPET_COLUMNS}>
        <>
          {!isLoading && formattedCompaniesWithInvestments?.length ? (
            formattedCompaniesWithInvestments.map(
              ({ id, companyName, userCompanySEStatus, investments, activeShareExchangeRequest }, index) =>
                investments.map((investment) => (
                  <InvestmentSnippetItem
                    key={`${investment?.id}-${index}`}
                    companyId={id}
                    userCompanySEStatus={userCompanySEStatus}
                    companyActiveShareExchangeId={activeShareExchangeRequest?.id}
                    id={investment.id}
                    amountInvested={investment.amountInvested}
                    companyName={investment.companyName || companyName}
                    originalInvestmentPrice={investment.originalInvestmentPrice}
                    classOfShares={investment.classOfShares}
                    investmentName={investment.investmentName}
                    index={index}
                  />
                )),
            )
          ) : isLoading ? (
            <TableSkeleton columnsNumber={5} rowsNumber={3} />
          ) : (
            <InvestmentSnippetNoInfo />
          )}
        </>
      </Table>
    </SnippetLayout>
  );
};

export default MyInvestmentsSnippet;
