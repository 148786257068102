import { useState } from 'react';

import { NumberOrNull } from 'interfaces';

const useSelectId = (): [NumberOrNull, (id: NumberOrNull) => void, VoidFunction] => {
  const [selectedId, setSelectedId] = useState<NumberOrNull>(null);

  const handleSelectId = (id: NumberOrNull) => {
    setSelectedId(id);
  };

  const handleClearSelectedId = () => setSelectedId(null);

  return [selectedId, handleSelectId, handleClearSelectedId];
};

export default useSelectId;
