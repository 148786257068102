import React from 'react';

import useLoadDeals from 'hooks/deals/useLoadDeals';
import { Pagination } from 'shared-components';

import DealsTable from './DealsTable';

const DealsTableTabContainer = () => {
  const { loading, paginationState, updateSorting, handleFilter, handleLoadDeals } = useLoadDeals({});

  return (
    <div>
      <DealsTable updateSorting={updateSorting} handleFilter={handleFilter} handleLoadDeals={handleLoadDeals}>
        <Pagination loading={loading} {...paginationState} />
      </DealsTable>
    </div>
  );
};

export default DealsTableTabContainer;
