import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  MetaPage,
  GetListPayload,
  Investor,
  CommonMetrics,
  InvestorPieChartMetrics,
  WelcomeInvestorShareExchangeList,
  InvestorResponse,
  InvestorShareExchangesResponse,
  InvestorCompaniesInvestmentsResponse,
  InvestorInvoicesResponse,
  InvestorActivityLogResponse,
  InvestorActivityLogsResponse,
  InvestorEntitiesResponse,
  InvestorsDoneShareExchanges,
  InvestorFundsSharesResponse,
  InvestorSigningPayload,
  CurrentUser,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class InvestorsApis {
  constructor(private url: string) {}

  getInvestors(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Investor[]; meta: MetaPage }>(
      `${this.url}?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getInvestor(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorResponse>(`${this.url}/${id}`, config);
  }

  getInvestorCompaniesInvestments({ id, ...params }: GetListPayload & { id: string }, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorCompaniesInvestmentsResponse>(
      `${this.url}/${id}/companies-investments?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getInvestorFundsShares({ id, ...params }: GetListPayload & { id: string }, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorFundsSharesResponse>(
      `${this.url}/${id}/funds-shares?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getInvestorInvoices({ id, ...params }: GetListPayload & { id: string }, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorInvoicesResponse>(
      `${this.url}/${id}/invoices?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getInvestorShareExchanges({ id, ...params }: GetListPayload & { id: string }, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorShareExchangesResponse>(
      `${this.url}/${id}/share-exchanges?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getMetrics(rangeFilter: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CommonMetrics>(`${this.url}/investor?filter=${rangeFilter}`, config);
  }

  getPieChart(config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorPieChartMetrics>(`${this.url}/investor-pie-chart`, config);
  }

  getInvestorsDoneShareExchanges(config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorsDoneShareExchanges>(`${this.url}/investor-done-ses`, config);
  }

  getWelcomeInvestorShareExchangeData(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: WelcomeInvestorShareExchangeList }>(`${this.url}/dashboard-buttons`, config);
  }

  getInvestorActivityLogs(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorActivityLogsResponse>(
      `${this.url}/activity-logs?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getInvestorActivityLog(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorActivityLogResponse>(`${this.url}/activity-logs/${id}`, config);
  }

  getInvestorEntities({ id, ...params }: GetListPayload & { id: number }, config?: AxiosRequestConfig) {
    return httpApiClient.get<InvestorEntitiesResponse>(
      `${this.url}/entities/${id}/investors?${stringifyFilterParams(params)}`,
      config,
    );
  }

  updateSigningAuthority({ id, ...body }: InvestorSigningPayload & { id: number }, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: CurrentUser }>(`${this.url}/${id}/signing-authority`, body, config);
  }
}

export const adminInvestorsApi = new InvestorsApis('/admin/investors');
export const adminInvestorsMetrics = new InvestorsApis('/admin/metrics');
export const investorApi = new InvestorsApis('/investors');
