import React, { FC } from 'react';

import { UseFormReset } from 'react-hook-form';

import { MY_INVESTMENT_SHARE_EXCHANGE_TITLE } from 'constants/my-investments';
import { StartShareExchangesInvestmentItem, StartShareExchangesInvestmentsFormValues } from 'interfaces';
import ShareExchangeModalTooltipMessage from 'page-components/share-exchange/share-exchange-requests-table/ShareExchangeModalTooltipMesage';
import { ModalWindow } from 'shared-components';

import StartInvestmentShareExchangeModalTable from './StartInvestmentShareExchangeModalTable';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSuccessStartShareExchange: (
    shareExchanges: StartShareExchangesInvestmentItem[],
    companyId: number,
    loadingCallback: VoidFunction,
    reset: UseFormReset<StartShareExchangesInvestmentsFormValues>,
  ) => void;
};

const SHARE_EXCHANGE_INVESTMENT_MODAL_INFO_ICON = 'share-exchange-investment-modal-info-icon';

const StartInvestmentShareExchangeModal: FC<Props> = ({ isOpen, onClose, onSuccessStartShareExchange }) => {
  return (
    <ModalWindow
      title={MY_INVESTMENT_SHARE_EXCHANGE_TITLE}
      className='lg:w-3/4 max-w-[1120px]'
      wrapperClassName='px-8 py-8 lg:px-8 lg:py-8 overflow-x-hidden'
      overlayClassName='bg-black opacity-80'
      descriptionClassName='max-w-[1100px]'
      headerClassName='[&>div]:items-center'
      isOpen={isOpen}
      onClose={onClose}
      tooltip
      tooltipText={<ShareExchangeModalTooltipMessage />}
      anchorId={SHARE_EXCHANGE_INVESTMENT_MODAL_INFO_ICON}
    >
      <StartInvestmentShareExchangeModalTable
        onClose={onClose}
        onSuccessStartShareExchange={onSuccessStartShareExchange}
      />
    </ModalWindow>
  );
};

export default StartInvestmentShareExchangeModal;
