import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { investmentsApi, investorCompaniesApis } from 'apis';
import { SHARE_EXCHANGE_CREATE_MESSAGE, SHARE_EXCHANGES_CREATE_MESSAGE } from 'constants/share-exchange';
import { showServerError, showServerMultipleErrors, successNotify, warningNotify } from 'helpers';
import {
  EditInvestmentPayload,
  Investment,
  InvestmentPayload,
  MetaPayload,
  ReviewInvestment,
  ServerError,
  StartShareExchangesInvestmentItem,
} from 'interfaces';
import { addUserIdToWelcomeModalUsersIds, formatInvestmentFormData } from 'utils/investments';

import { RootState } from '../store';

export const INVESTMENTS_SLICE_NAME = 'investments';

export const getInvestments = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/getInvestments`,
  async (data?: MetaPayload) => {
    const response = await investmentsApi.getInvestments(data || {});

    return response?.data;
  },
);

export const getLinkedInInvestments = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/getLinkedInInvestments`,
  async (data?: MetaPayload) => {
    const response = await investmentsApi.getLinkedInInvestments(data || {});

    return response?.data;
  },
);

export const getCompaniesWithInvestments = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/getCompaniesWithInvestments`,
  async (data?: MetaPayload) => {
    const response = await investmentsApi.getCompaniesWithInvestments(data || {});

    return response?.data?.data;
  },
);

export const addInvestment = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/addInvestment`,
  async (data: InvestmentPayload, { rejectWithValue }) => {
    try {
      const response = await investmentsApi.addInvestment(formatInvestmentFormData(data));

      successNotify('Investment Added Successfully');

      return response?.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const editInvestment = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/editInvestment`,
  async (data: EditInvestmentPayload, { rejectWithValue }) => {
    try {
      const { id, ...restData } = data;

      const response = await investmentsApi.editInvestment({
        ...formatInvestmentFormData(restData),
        id,
      });
      successNotify('The investment was successfully edited');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteInvestment = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/deleteInvestment`,
  async (id: string, { rejectWithValue }) => {
    try {
      await investmentsApi.deleteInvestment(id);
      successNotify('The investment was successfully deleted');

      return id;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const requestShareExchange = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/requestShareExchange`,
  async ({ id, isResubmit, selfId }: { id: number; isResubmit: boolean; selfId?: number }, { rejectWithValue }) => {
    try {
      const { data } = await (isResubmit
        ? investmentsApi.resubmitShareExchange(id)
        : investmentsApi.requestShareExchange(id));

      if (!isResubmit) addUserIdToWelcomeModalUsersIds(selfId);

      return data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getCompaniesGraph = createAsyncThunk(`${INVESTMENTS_SLICE_NAME}/getCompaniesGraph`, async () => {
  const response = await investmentsApi.getCompaniesGraph();
  return response.data.data;
});

export const getIndustriesGraph = createAsyncThunk(`${INVESTMENTS_SLICE_NAME}/getIndustriesGraph`, async () => {
  const response = await investmentsApi.getIndustriesGraph();
  return response.data.data;
});

export const uploadInvestments = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/uploadInvestments`,
  async (file: File, { rejectWithValue }) => {
    try {
      const formData = new FormData();

      formData.append('csvFile', file);

      const {
        data: { data },
      } = await investmentsApi.uploadInvestments(formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      });

      // BE doesnt return ID for parsed investment, so we create custom ID to manipulate investments
      return data.map((investment, index) => ({ ...investment, id: index.toString() }));
    } catch (err) {
      const error = err as AxiosError<ServerError & { errors: { csvFile: string[] } }>;
      const { csvFile: csvErrors = [] } = error?.response?.data?.errors || {};

      showServerError(err);
      showServerMultipleErrors(csvErrors);
      return rejectWithValue(err);
    }
  },
);

export const deleteReviewInvestment = createAction(
  `${INVESTMENTS_SLICE_NAME}/deleteReviewInvestment`,
  (investment: ReviewInvestment) => ({ payload: { investment } }),
);

export const updateReviewInvestment = createAction(
  `${INVESTMENTS_SLICE_NAME}/updateReviewInvestment`,
  (investment: ReviewInvestment) => ({ payload: { investment } }),
);

export const resetReviewInvestments = createAction(`${INVESTMENTS_SLICE_NAME}/resetReviewInvestments`);

export const saveReviewedInvestments = createAsyncThunk<{ data: Investment[] }, void, { state: RootState }>(
  `${INVESTMENTS_SLICE_NAME}/deleteReviewInvestment`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        investmentsReducer: { reviewInvestments },
      } = getState();

      const { data } = await investmentsApi.addReviewedInvestments(reviewInvestments);

      if (data?.leftToBeProcessed > 0) {
        warningNotify(
          `The first 10 investments have been added to the system. The remaining ${data?.leftToBeProcessed} are added in the background. This may take up to several minutes`,
        );
      }

      return data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getQualificationQuestions = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/getQualificationQuestions`,
  async (shareExchangeId: string, { rejectWithValue }) => {
    try {
      const { data } = await investmentsApi.getQualificationQuestions(shareExchangeId);

      return data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const cancelShareExchange = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/cancelShareExchange`,
  async (shareExchangeId: string, { rejectWithValue }) => {
    try {
      const { data } = await investmentsApi.cancelShareExchange(shareExchangeId);

      successNotify('Share exchange was canceled');
      return data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const submitShareExchangeAnswers = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/submitShareExchangeAnswers`,
  async (
    { answers, shareExchangeRequestId }: { answers: Record<string, string>; shareExchangeRequestId: string },
    { rejectWithValue },
  ) => {
    try {
      const formattedAnswers = Object.entries(answers).map(([questionId, answerKey]) => ({
        questionId: parseInt(questionId, 10),
        answerKey: parseInt(answerKey, 10),
      }));

      const { data } = await investmentsApi.submitShareExchangeAnswers({
        answers: formattedAnswers,
        shareExchangeRequestId,
      });

      return data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const createCompaniesShareExchanges = createAsyncThunk(
  `${INVESTMENTS_SLICE_NAME}/createCompaniesShareExchanges`,
  async (
    {
      companyId,
      shareExchanges,
      investmentIds,
      draftDealId,
    }: {
      shareExchanges: StartShareExchangesInvestmentItem[];
      companyId: number;
      investmentIds?: number[];
      draftDealId?: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const formattedShareExchanges = shareExchanges?.map(({ classOfShares, closingDate, ...shareExchange }) => ({
        ...shareExchange,
        classOfShares: classOfShares?.value,
        closingDate: closingDate?.value,
      }));

      const response = await investorCompaniesApis.createCompanyShareExchanges({
        id: companyId,
        data: formattedShareExchanges,
        investmentIds,
        draftDealId,
      });

      successNotify(shareExchanges?.length > 1 ? SHARE_EXCHANGES_CREATE_MESSAGE : SHARE_EXCHANGE_CREATE_MESSAGE);
      return response.data.data;
    } catch (e) {
      showServerError(e, '(and');
      return rejectWithValue(e);
    }
  },
);
