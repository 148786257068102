import React from 'react';

import { FOUNDERS_PAGE_TITLE } from 'constants/founders';
import { DashboardLayout, FoundersTable } from 'page-components';
import { TitleWithDescription } from 'shared-components';

const Founders = () => {
  return (
    <DashboardLayout>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-6' title={FOUNDERS_PAGE_TITLE} />

        <FoundersTable />
      </div>
    </DashboardLayout>
  );
};

export default Founders;
