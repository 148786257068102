import React, { FC } from 'react';

type Props = {
  companyName?: string;
  companyNumber?: string;
};

const DeclineShareExchangeQualificationQuestionModalDescription: FC<Props> = ({ companyNumber, companyName }) => {
  if (!companyNumber || !companyNumber) {
    return null;
  }

  return (
    <div className='text-grey-500 self-start mt-7'>
      <div>Are you sure you want to decline the share exchange request for</div>
      <div className='text-black font-bold'>{`${companyName}, ${companyNumber}?`}</div>
    </div>
  );
};

export default DeclineShareExchangeQualificationQuestionModalDescription;
