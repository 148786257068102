import React from 'react';

import BillsContainer from './my-bills/bills/BillsContainer';

const MyBillsTab = () => {
  return (
    <div>
      <BillsContainer />
    </div>
  );
};

export default MyBillsTab;
