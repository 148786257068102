import React, { ReactNode } from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import { SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

interface Props {
  title: string;
  description?: string;
  children: ReactNode;
}

const DueDiligenceSubBlock = ({ title, description, children }: Props) => {
  return (
    <SnippetLayout className='mt-6'>
      <Typography tag='h5' className='text-grey-800 font-bold'>
        {title}
      </Typography>
      {description && (
        <Typography className='text-sm text-grey-600' variant={TypographyVariants.BODY_SMALL}>
          {description}
        </Typography>
      )}
      <div className='mt-6'>{children}</div>
    </SnippetLayout>
  );
};

export default DueDiligenceSubBlock;
