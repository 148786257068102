import { createSelector } from '@reduxjs/toolkit';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { CompanyIac } from 'interfaces';
import { RootState } from 'modules/store';
import { formatFloatNumbers } from 'utils';

const selectState = (x: RootState) => x.companyIacReducer;

export const selectCompanyIac = (x: RootState) => x.companyIacReducer.companyIac;

const selectCompanyIacDeal = (x: RootState) => x.companyIacReducer.deal;

export const selectCustomFields = (blockName: InvestmentProposalBlockNames) =>
  createSelector(selectState, (state) => state.customFields[blockName]);

export const selectCompanyIacEditingSections = createSelector(selectState, ({ editingSections }) => editingSections);

export const selectIsCustomFieldsInCreateMode = (blockName: InvestmentProposalBlockNames) =>
  createSelector(selectState, ({ customFieldsInCreateMode }) => customFieldsInCreateMode[blockName]);

export const selectCompanyIacFundManager = createSelector(
  selectCompanyIac,
  (companyIac) => companyIac.fundManager || {},
);

export const selectCompanyIacResubmitted = createSelector(selectCompanyIac, (companyIac) => companyIac.isResubmitted);

export const selectCompanyIacOverviewData = createSelector(selectCompanyIac, (companyIac) => {
  const {
    id,
    companyName,
    companyNumber,
    businessSector,
    fullyDilutedShareCapital,
    proposedPrice,
    notes,
    incorporationDate,
    stageOfDevelopment,
    approvalForSecondarySale,
    timing,
    numberOfShareholders,
    sharesToPurchase,
    website,
    companySharePrices,
    closingDates,
  } = companyIac || {};

  return {
    id,
    companyName,
    companyNumber,
    businessSector,
    fullyDilutedShareCapital,
    proposedPrice,
    notes,
    incorporationDate,
    stageOfDevelopment,
    approvalForSecondarySale,
    timing,
    numberOfShareholders,
    website,
    sharesToPurchase,
    companySharePrices,
    closingDates,
  };
});

export const selectCompanyIAcNatureOfBusiness = createSelector(selectCompanyIac, (state) => state.natureOfBusiness);

export const selectCompanyIacFundName = createSelector(selectState, ({ companyIac }) => companyIac?.fundName);

export const selectCompanyIacId = createSelector(selectState, ({ companyIac }) => companyIac.id);

export const selectCompanyIacStatus = createSelector(selectState, ({ companyIac }) => companyIac.status);

export const selectLastCreatedAt = createSelector(selectState, ({ companyIac }) => companyIac.lastCreatedAt);

export const selectCompanyIacSummary = createSelector(selectCompanyIac, (companyIac) => ({
  companyOverview: companyIac?.companyOverview,
  backgroundInvestmentProposal: companyIac?.backgroundInvestmentProposal,
  finansialShareCapital: companyIac?.finansialShareCapital,
  valuationMetrics: companyIac?.valuationMetrics,
}));

export const selectCompanyIacBusinessReview = createSelector(selectCompanyIac, (companyIac = {} as CompanyIac) => {
  return {
    marketOpportunity: companyIac?.marketOpportunity,
    competition: companyIac?.competition,
    partnershipOpportunity: companyIac?.partnershipOpportunity,
    IPPosition: companyIac?.IPPosition,
    managementTeam: companyIac?.managementTeam,
    cashRunwayInNoOfMonth: companyIac?.cashRunwayInNoOfMonth,
    milestones: companyIac?.milestones,
    nextInvestmentPlans: companyIac?.nextInvestmentPlans,
    externalsUsedForDiligence: companyIac?.externalsUsedForDiligence,
    externalsUsedForDiligenceNotes: companyIac?.externalsUsedForDiligenceNotes,
    DDRedFlags: companyIac?.DDRedFlags,
    DDRedFlagsNotes: companyIac?.DDRedFlagsNotes,
    regulatoryChecks: companyIac?.regulatoryChecks,
    regulatoryChecksNotes: companyIac?.regulatoryChecksNotes,
    otherMatters: companyIac?.otherMatters,
    otherMattersNotes: companyIac?.otherMattersNotes,
    conflictsOfInterest: companyIac?.conflictsOfInterest,
    conflictsOfInterestNotes: companyIac?.conflictsOfInterestNotes,
    signature: companyIac?.signature === undefined ? '' : String(!!companyIac?.signature),
  };
});

export const selectCompanyIacCompanyName = createSelector(selectCompanyIac, (companyIac) => companyIac?.companyName);

export const selectCompanyIacCompanyNumber = createSelector(
  selectCompanyIac,
  (companyIac) => companyIac?.companyNumber,
);

export const selectCompanyIacHasDealParameter = createSelector(
  selectCompanyIac,
  ({ hasDealParameter }) => hasDealParameter,
);

export const selectCompanyIacDealParametersCompanyData = createSelector(
  selectCompanyIacDeal,
  ({ companyName, companyNumber }) => ({
    companyName,
    companyNumber,
  }),
);

export const selectCompanyIacDealParameters = createSelector(selectCompanyIacDeal, (deal) => ({
  feeRate: deal?.feeRate,
  daxiaSharePrice: formatFloatNumbers(deal?.daxiaSharePrice),
  feeMinimum: formatFloatNumbers(deal?.feeMinimum),
  companySharePrice: deal?.companySharePrice?.map(({ denomination, sharePrice, ...companySharePrice }) => ({
    ...companySharePrice,
    denomination: formatFloatNumbers(denomination),
    sharePrice: formatFloatNumbers(sharePrice),
  })),
}));
