export enum TypographyVariants {
  LARGE = 'large',
  SEMI_LARGE = 'semi_large',
  MEDIUM = 'medium',
  BODY_NORMAL = 'body_normal',
  BODY_SMALL = 'body_small',
}

export const TypographyStyles: { [key in TypographyVariants]: string } = {
  [TypographyVariants.LARGE]: 'text-[#14120F] font-medium leading-50 font-inter-tight text-[40px]',
  [TypographyVariants.SEMI_LARGE]: 'text-grey-800 font-medium leading-10 font-inter-tight text-[32px]',
  [TypographyVariants.MEDIUM]: 'text-grey-800 font-medium leading-8 font-inter-tight text-2xl',
  [TypographyVariants.BODY_NORMAL]: 'text-base font-normal leading-6 text-grey-600',
  [TypographyVariants.BODY_SMALL]: 'text-sm font-normal leading-[16.8px] text-grey-500',
};
