import React, { FC, useEffect } from 'react';

import cn from 'classnames';

import { DUE_DILIGENCE_DOCUMENTS_MODAL_TITLE } from 'constants/due-diligence';
import { TypographyStyles } from 'constants/shared/typography';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getDueDiligenceDocuments } from 'modules/due-diligence/action';
import { selectDueDiligenceDocuments } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DynamicPageTitle, ModalWindow, Pagination } from 'shared-components';
import DocumentsViewer from 'shared-components/documents-viewer/DocumentsViewer';
import { getPreviewDocumentsData } from 'utils/documents';

import { DueDiligenceDocumentsTable } from '../index';

const DUE_DILIGENCE_PER_PAGE = 10;

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: VoidFunction;
  filterValue: string;
  title?: string;
}

const DueDiligenceDocumentsModal: FC<Props> = ({ id, isOpen, onClose, filterValue, title }) => {
  const dispatch = useAppDispatch();

  const {
    isOpen: isPreviewModalOpen,
    onOpen: handleOpenPreviewModal,
    onClose: handleClosePreviewModal,
    modalProps: previewModalProps,
  } = useModal();

  const {
    list: documents,
    isLoading,
    ...dueDiligenceDocumentsPaginationData
  } = useAppSelector(selectDueDiligenceDocuments);

  const { sorting, updateSorting } = useTableSorting();
  const { page, setPage } = usePaginationWithSearch();

  const [previewDocuments, isDocumentsInvalid] = getPreviewDocumentsData(previewModalProps?.filePath);

  useEffect(() => {
    if (id && isOpen)
      dispatch(
        getDueDiligenceDocuments({
          id,
          page,
          per_page: DUE_DILIGENCE_PER_PAGE,
          order: sorting.order,
          sort: sorting.column,
          filter: filterValue ? `filter[type]=${filterValue}` : '',
        }),
      );
  }, [isOpen, dispatch, filterValue, id, page, sorting.column, sorting.order]);

  return (
    <>
      <DocumentsViewer
        isOpen={isPreviewModalOpen}
        onClose={handleClosePreviewModal}
        documents={previewDocuments}
        shownError={isDocumentsInvalid}
      />
      <ModalWindow
        className='min-w-[80%]'
        wrapperClassName='p-8'
        overlayClassName='bg-black opacity-80'
        isOpen={isOpen}
        onClose={onClose}
        preventOutsideClick={isPreviewModalOpen}
      >
        <DynamicPageTitle
          title={title || DUE_DILIGENCE_DOCUMENTS_MODAL_TITLE}
          dynamicTitle='Documents'
          className={cn('[&>span]:font-bold', TypographyStyles.large)}
        />
        <div className='flex flex-col'>
          <DueDiligenceDocumentsTable
            id={id}
            updateSorting={updateSorting}
            documents={documents || []}
            isLoading={isLoading}
            handleOpenPreviewDocumentsModal={handleOpenPreviewModal}
          />
          <Pagination
            currentPage={page}
            updatePage={setPage}
            loading={isLoading}
            {...dueDiligenceDocumentsPaginationData}
          />
        </div>
      </ModalWindow>
    </>
  );
};

export default DueDiligenceDocumentsModal;
