import React, { FC } from 'react';

import { Company } from 'interfaces';
import { CompaniesItem, CompaniesItemSkeleton } from 'page-components';
import { NoInformationText } from 'shared-components';

const MY_COMPANIES_PRELOADER_ITEMS = [1, 2, 3];

interface Props {
  companies: Company[] | null;
  isLoading: boolean;
}

const CompaniesList: FC<Props> = ({ companies, isLoading }) => {
  if (isLoading)
    return (
      <div className='mt-16'>
        {MY_COMPANIES_PRELOADER_ITEMS.map((i) => (
          <CompaniesItemSkeleton key={i} />
        ))}
      </div>
    );

  return (
    <div className='mt-16 space-y-6'>
      {companies?.length ? (
        companies?.map((company) => <CompaniesItem key={company.id} {...company} className='overflow-auto' />)
      ) : (
        <NoInformationText text='Companies have been added' />
      )}
    </div>
  );
};

export default CompaniesList;
