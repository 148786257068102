import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  getSettingsSecurityFields,
  SETTINGS_SECURITY_DESCRIPTION,
  SETTINGS_SECURITY_TITLE,
  SettingsSecurityFieldNames,
} from 'constants/settings';
import { SettingsSecurityInput } from 'interfaces';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { updateUserPassword } from 'modules/users/action';
import { selectProfileActionState } from 'modules/users/selectors';
import { FormContent, PasswordStrengthWidget, TitleWithDescription } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

import SettingsSecurityButtons from './SettingsSecurityButtons';

const SettingsSecurity = () => {
  const dispatch = useAppDispatch();
  const { isProfileSettingsActionLoading, isProfileSettingsActionCalled } = useAppSelector(selectProfileActionState);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<SettingsSecurityInput>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const watchNewPassword = watch(SettingsSecurityFieldNames.NEW_PASSWORD);

  const closeEditMode = () => {
    setIsEditMode(false);
    clearErrors();
    reset();
  };

  const onSubmit: SubmitHandler<SettingsSecurityInput> = (data) => {
    dispatch(updateUserPassword(data));
  };
  const onUpdate = () => (!isEditMode ? setIsEditMode(true) : handleSubmit(onSubmit)());

  useEffect(() => {
    if (isProfileSettingsActionCalled) reset();
  }, [isProfileSettingsActionCalled, reset]);

  return (
    <div>
      <div className='flex justify-between xs:items-center flex-col xs:flex-row gap-4 xs:gap-0'>
        <TitleWithDescription
          className='section-big-title'
          title={SETTINGS_SECURITY_TITLE}
          description={getTitleWithReplacedYourWord(SETTINGS_SECURITY_DESCRIPTION, entityName, isTypeEntity)}
        />
        <SettingsSecurityButtons
          isLoading={isProfileSettingsActionLoading}
          isShownCancel={isEditMode}
          onUpdate={onUpdate}
          onCancel={closeEditMode}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col text-grey-500'>
          <FormContent
            fields={getSettingsSecurityFields(watchNewPassword, isEditMode)}
            register={register}
            errors={errors}
          />
          <PasswordStrengthWidget
            password={watchNewPassword}
            className={cn('pt-0 !pb-6', { block: isEditMode, hidden: !isEditMode })}
          />
        </div>
      </form>
    </div>
  );
};

export default SettingsSecurity;
