import React from 'react';

import { INVESTOR_FUND_SHARES_COLUMNS } from 'constants/investments';
import { selectInvestorsFundsShares } from 'modules/investors/selectors';
import { useAppSelector } from 'modules/store';
import Table, { TableSkeleton } from 'shared-components/table';

import { TypographyVariants } from '../../constants/shared/typography';
import { TitleWithDescription } from '../../shared-components';
import InvestorFundsSharesTableRow from './InvestorFundsSharesTableRow';
import NoCompaniesInvestments from './NoCompaniesInvestments';

const InvestorNumberOfShares = () => {
  const { isLoading, list } = useAppSelector(selectInvestorsFundsShares);

  return (
    <div>
      <TitleWithDescription title='Fund Shares' titleVariant={TypographyVariants.MEDIUM} />
      <Table columns={INVESTOR_FUND_SHARES_COLUMNS}>
        {list?.length && !isLoading ? (
          <>
            {list.map((fundShare) => (
              <InvestorFundsSharesTableRow key={fundShare.id} fundShare={fundShare} />
            ))}
          </>
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={INVESTOR_FUND_SHARES_COLUMNS.length} />
        ) : (
          <NoCompaniesInvestments entityName='Fund Shares' />
        )}
      </Table>
    </div>
  );
};

export default InvestorNumberOfShares;
