import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { ButtonAction } from 'interfaces';

import IconButtonWithTooltip from './IconButtonWithTooltip';

export const BUTTONS_ACTIONS_TEST_ID = 'BUTTONS_ACTIONS_TEST_ID';

interface Props {
  anchorPrefix: string;
  actions: ButtonAction[];
  className?: string;
  disabled?: boolean;
}
const ButtonsActions: FC<Props> = ({ anchorPrefix, actions, className, disabled }) => {
  if (!actions?.length) return null;
  return (
    <div className={twMerge('flex justify-end', className)} data-testid={BUTTONS_ACTIONS_TEST_ID}>
      {actions.map(({ id, action, ...element }) => (
        <IconButtonWithTooltip
          className={twMerge('block p-0 pl-4 m-0', element.className)}
          anchorId={`buttons-actions-${anchorPrefix}`}
          key={id}
          onClick={action}
          disabled={disabled}
          {...element}
        />
      ))}
    </div>
  );
};
export default ButtonsActions;
