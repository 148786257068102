import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

const NoBillsAdded = () => {
  return (
    <TableRow>
      <TableData>No information is available as no Invoices have been founded</TableData>
    </TableRow>
  );
};

export default NoBillsAdded;
