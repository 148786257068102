import React from 'react';

import { ADMIN_DEALS_PAGE_TITLE } from 'constants/deals';
import useLoadDeals from 'hooks/deals/useLoadDeals';
import { DashboardLayout, DealsTable } from 'page-components';
import { Pagination, TitleWithDescription } from 'shared-components';

const AdminDeals = () => {
  const { loading, paginationState, updateSorting, handleFilter } = useLoadDeals({});

  return (
    <DashboardLayout>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-6' title={ADMIN_DEALS_PAGE_TITLE} />

        <DealsTable updateSorting={updateSorting} handleFilter={handleFilter}>
          <Pagination loading={loading} {...paginationState} />
        </DealsTable>
      </div>
    </DashboardLayout>
  );
};

export default AdminDeals;
