import React from 'react';

import useModal from 'hooks/use-modal/useModal';
import { AlertModal } from 'shared-components';

import { usePromptBeforeRouteChange } from '../../hooks/usePromptBeforeRouteChange';

interface Props {
  isSignatureNotSaved: boolean | null;
}

const SignatureNotSavedModal = ({ isSignatureNotSaved }: Props) => {
  const { onOpen, onClose, ...modalState } = useModal();

  const [confirm, cancel] = usePromptBeforeRouteChange(isSignatureNotSaved === true, onOpen);

  const onCancel = () => {
    cancel();
    onClose();
  };

  return (
    <AlertModal
      isLoading={false}
      title="Your signature wasn't saved. Are you sure you want to leave this page?"
      onSubmit={confirm}
      onClose={onCancel}
      submitButtonText='Confirm'
      {...modalState}
    />
  );
};

export default SignatureNotSavedModal;
