import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { useForm, FormProvider } from 'react-hook-form';

import { SIGNIFICANT_CONTROL_DIRECTORS_FORM_FIELDS } from 'constants/significant-control-company';
import useModal from 'hooks/use-modal/useModal';
import useSignificantControlCompanyEdit from 'hooks/useSignificantControlCompanyEdit';
import useSignificantControlPersons from 'hooks/useSignificantControlPersons';
import { SignificantControlCompanyDirectorFormState } from 'interfaces';
import {
  selectDueDiligenceCompanyId,
  selectParentDueDiligenceStatusFinishedOrDeclined,
  selectSignificantControlPersons,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';
import { validateSignificantControlPersons } from 'utils/significant-control-company';

import PersonsBlock from '../../due-diligence/persons-block/PersonsBlock';
import SignificantControlCompanyActionsButtons from '../SignificantControlCompanyActionsButtons';

interface Props {
  id?: string;
}

const SignificantControlCompanyDirectors: FC<Props> = ({ id }) => {
  const { isEditing, setEditingMode, unsetEditingMode } = useSignificantControlCompanyEdit();

  const [isShownRequiredFieldsError, setIsShownRequiredFieldsError] = useState<boolean>(false);

  const significantControlPersons = useAppSelector(selectSignificantControlPersons);
  const companyId = useAppSelector(selectDueDiligenceCompanyId);
  const isParentDueDiligenceFinishedOrDeclined = useAppSelector(selectParentDueDiligenceStatusFinishedOrDeclined);

  const methods = useForm<SignificantControlCompanyDirectorFormState>();

  const modalProps = useModal();

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  const {
    onSubmit,
    handleDiscardChanges,
    handleSetIsLoading,
    isLoading,
    setDeletedPersons,
    deletedPersons,
    deletedAddresses,
    setDeletedAddresses,
  } = useSignificantControlPersons({
    reset: reset,
    dirtyFields: dirtyFields,
  });

  const onClickDiscardChanges = () => {
    handleDiscardChanges();
    unsetEditingMode();
  };

  const handleValidatePersonsRequiredFields = () => {
    if (!companyId || companyId !== Number(id)) return;

    const isError = validateSignificantControlPersons({
      significantControlPersons,
      isParentDueDiligenceFinishedOrDeclined,
    });

    setIsShownRequiredFieldsError(isError);
  };

  useEffect(() => {
    handleValidatePersonsRequiredFields();
  }, [significantControlPersons]);

  return (
    <SnippetLayout className='mt-6' title='Directors Information'>
      <FormProvider {...methods}>
        <PersonsBlock
          isCalled
          footerClassName='pt-0'
          isOpen={modalProps.isOpen}
          onClose={modalProps.onClose}
          onOpen={modalProps.onOpen}
          modalProps={modalProps.modalProps}
          personsList={significantControlPersons}
          name={SIGNIFICANT_CONTROL_DIRECTORS_FORM_FIELDS.DIRECTORS}
          handleCancel={unsetEditingMode}
          isEditing={isEditing}
          handleSetIsLoading={handleSetIsLoading}
          deletedPersons={deletedPersons}
          setDeletedPersons={setDeletedPersons}
          deletedAddresses={deletedAddresses}
          setDeletedAddresses={setDeletedAddresses}
        />
      </FormProvider>
      {isShownRequiredFieldsError && (
        <Typography className={cn('text-red-500', { 'mt-10': !significantControlPersons?.length })}>
          Please check table, some information needs to be updated
        </Typography>
      )}
      <SignificantControlCompanyActionsButtons
        className='pt-0'
        isEditing={isEditing}
        onClickEdit={setEditingMode}
        isLoading={isLoading}
        onClickDiscardChanges={onClickDiscardChanges}
        onClickSave={handleSubmit(onSubmit({ onOpen: modalProps.onOpen, handleCancel: unsetEditingMode }))}
      />
    </SnippetLayout>
  );
};

export default SignificantControlCompanyDirectors;
