import React, { FC, SVGProps, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SortingArrow } from 'assets/svg/sorting-arrow.svg';
import { ReactComponent as SpinSvg } from 'assets/svg/spin-loader.svg';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyStyles, TypographyVariants } from 'constants/shared/typography';
import { ButtonDropdownActions } from 'interfaces';

import ButtonWithIcon from './button-with-icon/ButtonWithIcon';

export const BUTTONS_DROPDOWN_TEST_ID = 'BUTTONS_DROPDOWN_TEST_ID';
export const BUTTON_DROPDOWN_TEST_ID = 'BUTTON_DROPDOWN_TEST_ID';
export const BUTTON_DROPDOWN_LOADER_TEST_ID = 'BUTTON_DROPDOWN_LOADER_TEST_ID';

type Props = {
  actions: ButtonDropdownActions[];
  loading?: boolean | null;
  className?: string;
  menuClassName?: string;
  disabledPositionCalculation?: boolean;
  isButtonLoading?: boolean;
  disabledButton?: boolean;
  buttonText?: string;
  additionalLeftValue?: number;
  additionalTopValue?: number;
  icon?: FC<SVGProps<SVGSVGElement>>;
  variant?: ButtonVariants;
  shouldUseDisplay?: boolean;
};

// https://stackoverflow.com/questions/18135204/setting-overflow-y-causes-overflow-x-to-change-as-well
// Solution with dynamic top style attribute is done to prevent bug with overflow for table
const ButtonDropdown: FC<Props> = ({
  actions,
  loading,
  className,
  menuClassName,
  disabledPositionCalculation,
  isButtonLoading,
  disabledButton,
  icon,
  shouldUseDisplay,
  additionalLeftValue = 0,
  additionalTopValue = 0,
  buttonText = 'Actions',
  variant = ButtonVariants.SECONDARY,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [currentId, setCurrentId] = useState<string | null>(null);
  const [menuWidth, setMenuWidth] = useState(0);

  const toggleMenuVisibility = useCallback(() => {
    const rect = buttonRef.current?.getBoundingClientRect();
    const calculatedMenuTopPosition = Math.round((rect?.top || 0) + window.scrollY) + (additionalTopValue || 60);

    const leftMenuPosition = Math.round((rect?.left || 0) + window.scrollX);

    if (menuRef.current && !disabledPositionCalculation) {
      menuRef.current.style.top = `${calculatedMenuTopPosition}px`;
      menuRef.current.style.left = `${leftMenuPosition - additionalLeftValue}px`;
    }

    setIsMenuVisible((isVisible) => !isVisible);
  }, [additionalLeftValue, additionalTopValue, disabledPositionCalculation]);

  const onClickDropdownItem = ({ action, id, isOpenOnAction }: Omit<ButtonDropdownActions, 'title'>) => {
    setCurrentId(id);
    action();
    if (!isOpenOnAction) toggleMenuVisibility();
  };

  useEffect(() => {
    const buttonWidth = buttonRef?.current?.offsetWidth;

    if (buttonWidth && buttonWidth !== menuWidth) {
      setMenuWidth(buttonWidth);
    }
  }, [buttonRef?.current?.offsetWidth, menuWidth]);

  return (
    <>
      {isMenuVisible && <div onClick={toggleMenuVisibility} className='fixed w-full h-full left-0 top-0 z-10' />}

      <ButtonWithIcon
        isLoading={isButtonLoading}
        buttonRef={buttonRef}
        className={cn('relative w-[120px]', { 'z-30': isMenuVisible }, className)}
        iconClassName='w-3'
        text={buttonText}
        onClick={toggleMenuVisibility}
        icon={icon || SortingArrow}
        disabled={disabledButton}
        buttonVariant={variant}
      />

      <div
        ref={menuRef}
        style={{ width: menuWidth }}
        className={cn(
          'absolute z-30 bg-green-100 w-[120px] shadow rounded-2xl flex flex-col',
          shouldUseDisplay
            ? { flex: isMenuVisible, hidden: !isMenuVisible }
            : {
                visible: isMenuVisible,
                invisible: !isMenuVisible,
              },
          menuClassName,
        )}
        data-testid={BUTTONS_DROPDOWN_TEST_ID}
      >
        {actions.map(({ title, id, disabled, icon: Icon, ...action }, index) => (
          <button
            key={id}
            onClick={() => onClickDropdownItem({ ...action, id })}
            disabled={disabled}
            className={twMerge(
              cn(
                TypographyStyles[TypographyVariants.BODY_NORMAL],
                'p-2 pl-4 border-green-300 w-full text-center whitespace-nowrap hover:bg-green-300 fill-green-300 stroke-green-300 flex items-center text-grey-800 hover:first:rounded-t-2xl hover:last:rounded-b-2xl',
                {
                  'border-b': index !== actions.length - 1,
                  'bg-grey-100 cursor-not-allowed hover:bg-grey-300 !border-grey-300': disabled,
                },
              ),
            )}
            data-testid={BUTTON_DROPDOWN_TEST_ID}
          >
            {loading && currentId === id ? (
              <SpinSvg className='mx-auto animated-spin' data-testid={BUTTON_DROPDOWN_LOADER_TEST_ID} />
            ) : (
              <>
                {Icon && <Icon className='mr-2 [&>path]:fill-text-800' />}
                {title}
              </>
            )}
          </button>
        ))}
      </div>
    </>
  );
};

export default ButtonDropdown;
