import { ColoredLabelVariants } from '../shared';

export enum StepperStatuses {
  PENDING = 'pending',
  SUCCESS = 'success',
  CANCEL = 'cancel',
  DELETED = 'deleted',
}

export const stepperStatusesLabels: {
  [key in StepperStatuses]: {
    children: string;
    variant: ColoredLabelVariants;
  };
} = {
  [StepperStatuses.PENDING]: {
    children: 'Pending',
    variant: ColoredLabelVariants.PENDING,
  },
  [StepperStatuses.SUCCESS]: {
    children: 'Completed',
    variant: ColoredLabelVariants.SUCCESS,
  },
  [StepperStatuses.CANCEL]: {
    children: 'Stopped',
    variant: ColoredLabelVariants.ERROR,
  },
  [StepperStatuses.DELETED]: {
    children: 'Deleted',
    variant: ColoredLabelVariants.ERROR,
  },
};
