import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import { ReactComponent as Info } from 'assets/svg/info.svg';
import { HIGHLIGHTED_MESSAGE_COLORS } from 'constants/shared';
import { FromItemHigligtedColor } from 'interfaces';

interface Props {
  children: ReactNode;
  color: FromItemHigligtedColor;
  className?: string;
}

const HighlightedMessage: FC<Props> = ({ children, color, className }) => {
  return (
    <div className='flex'>
      {color === FromItemHigligtedColor.Red && <Info className='mt-1 w-4 h-4 [&>path]:fill-red-700' />}
      <p className={cn(`text-${HIGHLIGHTED_MESSAGE_COLORS[color]}`, className)}>{children}</p>
    </div>
  );
};

export default HighlightedMessage;
