import React from 'react';

const ShareExchangeSkeleton = ({ itemsCount }: { itemsCount: number }) => (
  <>
    {[...Array(itemsCount)].map((_, index) => (
      <div key={index} className='skeleton-loader col-start-1 col-end-3 h-[100px] my-2' />
    ))}
  </>
);

export default ShareExchangeSkeleton;
