import React from 'react';

import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import Typography from 'shared-components/Typography';

import { LINK_BUTTON_DATA_TEST_ID } from '../../shared-components/link-button/LinkButton';

const AlreadyExistEmailToast = () => {
  return (
    <Typography className='text-grey-800'>
      Looks like you already have an account click here to
      <Link to={ROUTES.signIn} data-testid={LINK_BUTTON_DATA_TEST_ID}>
        log in
      </Link>
    </Typography>
  );
};

export default AlreadyExistEmailToast;
