import React, { FC } from 'react';

import SelectComponent, { components, DropdownIndicatorProps, CSSObjectWithLabel, OptionProps } from 'react-select';

import { ReactComponent as ArrowSvg } from 'assets/svg/sorting-arrow.svg';
import { SelectOption } from 'interfaces';

type Props = {
  value: SelectOption;
  options: SelectOption[];
  onChange: (option: SelectOption | null) => void;
};

const styles = {
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    fontSize: '15px',
    height: '40px',
    border: '1px solid #DAD9D8',
    borderRadius: '8px',
    boxShadow: 'none',
    zIndex: '2',
    '&:hover': {
      borderColor: '#B4B3B1',
    },
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    marginTop: 0,
    backgroundColor: '#F3F3F4',
    border: 0,
    padding: 0,
    overflow: 'hidden',
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({ ...baseStyles, padding: 0 }),
  option: (baseStyles: CSSObjectWithLabel, state: OptionProps<SelectOption>) => ({
    ...baseStyles,
    fontSize: '16px',
    backgroundColor: state?.isSelected ? '#80A3ED' : '#FFFFFF',
    color: '#14120F',
  }),
  singleValue: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: '#14120F',
  }),
};

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowSvg />
    </components.DropdownIndicator>
  );
};

const Select: FC<Props> = ({ value, options, onChange }) => {
  return (
    <SelectComponent
      value={value}
      isSearchable={false}
      defaultValue={options[0]}
      styles={styles}
      components={{ IndicatorSeparator: null, DropdownIndicator }}
      options={options}
      isMulti={false}
      onChange={onChange}
    />
  );
};

export default Select;
