import React, { FC, useEffect, useMemo, useState } from 'react';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { setIsCustomFieldsInCreateMode } from 'modules/company-iac/action';
import { selectCompanyIacEditingSections } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button } from 'shared-components';

import CompanyIacAddCustomFieldsList from './CompanyIacAddCustomFieldsList';

interface Props {
  blockName: InvestmentProposalBlockNames;
  disabled?: boolean;
  hideAddNewFieldButton?: boolean;
  className?: string;
}

const CompanyIacAddCustomFields: FC<Props> = ({ blockName, disabled, hideAddNewFieldButton, className }) => {
  const dispatch = useAppDispatch();

  const companyIacEditingSections = useAppSelector(selectCompanyIacEditingSections);

  const [fields, setFields] = useState<number[]>([]);

  const isEditingCurrentBlock = useMemo(
    () => companyIacEditingSections?.includes(blockName),
    [companyIacEditingSections, blockName],
  );

  const appendField = () => {
    setFields((prev) => [...prev, new Date().getTime()]);
  };

  const handleDeleteField = (index: number) => {
    setFields((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    dispatch(setIsCustomFieldsInCreateMode({ blockName, isCreateMode: Boolean(fields?.length) }));
  }, [blockName, dispatch, fields?.length]);

  useEffect(() => {
    if (!isEditingCurrentBlock) {
      setFields([]);
    }
  }, [isEditingCurrentBlock]);

  return (
    <div className='w-full flex flex-col'>
      <CompanyIacAddCustomFieldsList
        disabled={disabled}
        fields={fields}
        onDeleteField={handleDeleteField}
        blockName={blockName}
      />

      {!hideAddNewFieldButton && (
        <Button
          variant={ButtonVariants.SECONDARY}
          disabled={disabled || fields?.length > 0}
          onClick={appendField}
          className='w-full sm:w-fit mt-2'
        >
          + Add New Field
        </Button>
      )}
    </div>
  );
};

export default CompanyIacAddCustomFields;
