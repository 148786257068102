import React from 'react';

import { useLocation, matchPath, Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/svg/logo-dark-large.svg';
import { ROUTES, SIGN_UP_ROUTES_WITHOUT_REGISTER_LINK } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { LinkButton } from 'shared-components';

interface Props {
  hideButtons?: boolean;
}

const AuthHeader = ({ hideButtons }: Props) => {
  const { pathname } = useLocation();

  const registerButtonVisible = !SIGN_UP_ROUTES_WITHOUT_REGISTER_LINK.some((path) => matchPath(path, pathname));

  return (
    <header className='flex justify-between pt-10'>
      <Link to={ROUTES.signIn}>
        <Logo />
      </Link>
      {!hideButtons && (
        <div className='flex flex-col gap-4'>
          {!pathname.includes(ROUTES.signIn) && (
            <LinkButton to={ROUTES.signIn} variant={ButtonVariants.SECONDARY} className='max-w-150 w-full mr-5'>
              Sign in
            </LinkButton>
          )}
          {registerButtonVisible && (
            <LinkButton to={ROUTES.whoAreYou} variant={ButtonVariants.SECONDARY} className='max-w-150 w-full'>
              Register
            </LinkButton>
          )}
        </div>
      )}
    </header>
  );
};

export default AuthHeader;
