import React, { ReactNode, useMemo } from 'react';

import cn from 'classnames';

import { ReactComponent as CancelCircleIcon } from 'assets/svg/cancel-circle.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/svg/check-mark-v3.svg';
import { StepperStatuses } from 'constants/shared/stepper';

interface Props {
  children?: ReactNode;
  className?: string;
  status?: StepperStatuses;
}

const STEPPER_ICONS: { [key in StepperStatuses]: ReactNode } = {
  [StepperStatuses.PENDING]: <CheckMarkIcon className='[&>path]:fill-grey-300 w-10 h-10' />,
  [StepperStatuses.SUCCESS]: <CheckMarkIcon className='[&>path]:fill-lightGreen-700 w-10 h-10' />,
  [StepperStatuses.CANCEL]: <CancelCircleIcon className='w-10 h-10' />,
  [StepperStatuses.DELETED]: <CancelCircleIcon className='w-10 h-10' />,
};

const StepperItemCircle = ({ children, className, status }: Props) => {
  const stepperItemCircleContent = useMemo(() => {
    if (!children && status) return STEPPER_ICONS[status];

    if (children) return children;

    return <CheckMarkIcon className='[&>path]:fill-grey-300' />;
  }, [children, status]);

  return (
    <span
      className={cn(
        'w-14 h-10 flex justify-start border-2 bg-white border-transparent rounded-full mx-auto text-sm text-white ml-0 relative',
        className,
      )}
    >
      {stepperItemCircleContent}
    </span>
  );
};

export default StepperItemCircle;
