import { TabItem } from 'interfaces';
import BusinessMetrics from 'page-components/business-profile/BusinessMetrics';

export const BUSINESS_PROFILE_PAGE_TITLE = 'Investments';

export const BUSINESS_PROFILE_PAGE_TABS: TabItem[] = [
  { id: 'overview', name: 'Overview', component: BusinessMetrics },
  { id: 'investments', name: 'Investments' },
  { id: 'deals', name: 'Deals' },
  { id: 'otherInvestments', name: 'Other Investments' },
  { id: 'metrics', name: 'Metrics' },
];
