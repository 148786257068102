import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { TypographyVariants } from 'constants/shared/typography';
import { TitleWithDescription } from 'shared-components/index';

export interface Props {
  children: ReactNode;
  title?: string;
  description?: string | ReactNode;
  link?: string;
  linkText?: string;
  className?: string;
  childrenClassName?: string;
  customHeader?: ReactNode;
}

const SnippetLayout: FC<Props> = ({
  children,
  className,
  link,
  linkText,
  title,
  description,
  childrenClassName,
  customHeader,
}) => {
  return (
    <div className={twMerge('bg-white border border-grey-200 rounded-2xl p-6', className)}>
      <div className='flex justify-between'>
        {title && (
          <TitleWithDescription
            titleVariant={TypographyVariants.MEDIUM}
            title={title}
            description={description}
            descriptionClassName='pt-2'
          />
        )}
        {link && linkText && (
          <Link className='text-violet-600 underline ml-1' to={link}>
            {linkText}
          </Link>
        )}
        {customHeader}
      </div>
      <div className={cn('w-full', { 'mt-6': title }, childrenClassName)}>{children}</div>
    </div>
  );
};

export default SnippetLayout;
