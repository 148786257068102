import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

type Props = {
  onCancel: VoidFunction;
  onSave: VoidFunction;
  isDisabledSave: boolean;
};

const ReviewUploadedInvestmentsModalButtons: FC<Props> = ({ onCancel, isDisabledSave, onSave }) => (
  <div className='w-full flex justify-between mt-10'>
    <Button variant={ButtonVariants.SECONDARY} className='w-150' onClick={onCancel}>
      Cancel
    </Button>

    <Button variant={ButtonVariants.PRIMARY} className='w-300' onClick={onSave} disabled={isDisabledSave}>
      Confirm & Add
    </Button>
  </div>
);

export default ReviewUploadedInvestmentsModalButtons;
