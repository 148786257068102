import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { CANCELABLE_SHARE_EXCHANGE_STATUSES } from 'constants/share-exchange';
import { ColoredLabelVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { SingleShareExchange } from 'interfaces';
import { ButtonDropdown, ColoredLabel, LinkButton } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getCompanyProfileLink } from 'utils';

type Props = {
  shareExchange: SingleShareExchange;
  onClickCancelShareExchange?: VoidFunction;
  isAdmin?: boolean;
};

const ShareExchangeConstraintHeader: FC<Props> = ({ onClickCancelShareExchange, shareExchange, isAdmin = false }) => {
  const getActions = () => [
    {
      title: 'Cancel',
      id: 'cancel',
      action: onClickCancelShareExchange ? onClickCancelShareExchange : () => '',
      disabled: !CANCELABLE_SHARE_EXCHANGE_STATUSES.includes(shareExchange?.status),
    },
  ];

  const initiatedDate = shareExchange.createdAt
    ? format(parseISO(shareExchange.createdAt), DAY_FORMAT_WITH_SLASHES)
    : '';

  return (
    <div className='flex justify-between items-start flex-col md:flex-row gap-4 md:gap-0'>
      <div>
        <div className='flex items-start flex-col sm:flex-row mb-4'>
          <Typography className='mr-6 break-all' tag='h4' variant={TypographyVariants.SEMI_LARGE}>
            {shareExchange.companyName}
          </Typography>
          <ColoredLabel className='mt-3' variant={ColoredLabelVariants.SUCCESS}>
            Share Exchange Request
          </ColoredLabel>
        </div>
        <div>{`Company Number: ${shareExchange.companyNumber}`}</div>
        <div>{`Initiated: ${initiatedDate}`}</div>
      </div>

      {isAdmin ? (
        <LinkButton
          className='min-w-full sm:min-w-[210px] md:ml-10'
          variant={ButtonVariants.SECONDARY}
          to={getCompanyProfileLink(shareExchange.companyId, true)}
        >
          View Company Profile
        </LinkButton>
      ) : (
        <ButtonDropdown menuClassName='w-250' className='w-250' actions={getActions()} />
      )}
    </div>
  );
};

export default ShareExchangeConstraintHeader;
