import React, { FC } from 'react';

type Props = {
  type: string;
  value?: string | Array<string>;
  isDueDiligenceLoaded?: boolean;
};

const SignificantControlCompanyHeaderSection: FC<Props> = ({ type, value, isDueDiligenceLoaded }) => {
  if (!isDueDiligenceLoaded || !value) {
    return (
      <div>
        <div className='text-grey-500 mb-1.5'>{type}</div>
        <div className='skeleton-loader w-150 h-6' />
      </div>
    );
  }

  return (
    <div>
      <div className='text-grey-500 mb-1.5'>{type}</div>
      {typeof value === 'string' ? (
        <div className='text-violet-600'>{value}</div>
      ) : (
        value.map((itemValue) => (
          <div key={itemValue} className='text-violet-600'>
            {itemValue}
          </div>
        ))
      )}
    </div>
  );
};

export default SignificantControlCompanyHeaderSection;
