import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
};

const SettingsEmailConfirmModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onConfirm}
      isLoading={false}
      title='Please be sure you use the same email address in your share exchange process'
      descriptionClassName='mt-7'
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
      submitButtonText='Confirm'
    />
  );
};

export default SettingsEmailConfirmModal;
