import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';

import { LocalStorageKeys } from 'constants/global';
import { FieldTypes } from 'constants/shared';
import {
  getSignUpFields,
  getSignUpInvestorEntityFields,
  SIGN_UP_BUTTON_TEST_IDS,
  SIGN_UP_FIELDS_TEST_IDS,
  SIGN_UP_PAGE_FORM_INITIAL,
  SIGN_UP_PAGE_TITLE,
  SIGN_UP_PASSWORD_VALIDATION,
  SignUpFieldNames,
} from 'constants/sign-up';
import { InvestorSubTypes, UserTypes } from 'constants/user';
import { SignUpInput } from 'interfaces';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { Button, FormContent, FormLabel, Input, PasswordStrengthWidget, ToggleSwitch } from 'shared-components';

import { useIsAlreadyRegistered } from '../../hooks/useIsAlreadyRegistered';
import EntityCompanyFields from '../entities/EntityCompanyFields';
import AlreadyRegisteredInfoSection from './AlreadyRegisteredInfoSection';

type Props = {
  isLoading: boolean;
  onSubmit: SubmitHandler<Partial<SignUpInput>>;
  excessFields?: Array<SignUpFieldNames | string>;
  formValues?: Partial<SignUpInput>;
  submitButtonText?: string;
  disabledEmailInput?: boolean;
};

const SignUpForm: FC<Props> = ({
  onSubmit,
  excessFields = [],
  isLoading,
  formValues,
  submitButtonText,
  disabledEmailInput,
}) => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const [isShownPasswordWidget, setIsShownPasswordWidget] = useState(false);

  const [investorSubType, setInvestorSubType] = useState(InvestorSubTypes.INDIVIDUAL);

  const {
    isAlreadyRegisteredData,
    isPartlyRegistered,
    handleCheckIsAlreadyRegistered,
    handleClearIsAlreadyRegisteredData,
  } = useIsAlreadyRegistered();

  const userType = (localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes) ?? UserTypes.INVESTOR;
  const isInvestor = userType === UserTypes.INVESTOR;

  const toggleIsShownPasswordWidget = () => setIsShownPasswordWidget((prev) => !prev);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    trigger,
    getValues,
    setValue,
  } = useForm<SignUpInput>({
    defaultValues: SIGN_UP_PAGE_FORM_INITIAL,
    reValidateMode: 'onChange',
  });

  const watchPassword = watch(SignUpFieldNames.PASSWORD);

  const isCorrectFounderFields = userType === UserTypes.FOUNDER && !isAlreadyRegisteredData?.isFounder;
  const isCorrectInvestorFields = userType === UserTypes.INVESTOR && !isAlreadyRegisteredData?.isInvestor;

  const isMulti = !!isAlreadyRegisteredData && (isCorrectFounderFields || isCorrectInvestorFields);

  const alreadyRegisteredExcessFields =
    isPartlyRegistered && (isCorrectFounderFields || isCorrectInvestorFields)
      ? [SignUpFieldNames.CONFIRM_PASSWORD]
      : [];

  const handleBlurEmail = async () => {
    const isEmailValid = await trigger(SignUpFieldNames.EMAIL);

    if (!isEmailValid) return;

    handleCheckIsAlreadyRegistered(getValues(SignUpFieldNames.EMAIL));
  };

  const handleToggleInvestorEntity = (active: boolean) => {
    setInvestorSubType(() => (active ? InvestorSubTypes.ENTITY : InvestorSubTypes.INDIVIDUAL));
    reset({ ...formValues });
    handleClearIsAlreadyRegisteredData();
  };

  useEffect(() => {
    if (formValues) {
      reset({ ...formValues });
    }

    if (formValues?.companyNumber) {
      setInvestorSubType(InvestorSubTypes.ENTITY);
    }
  }, [formValues, reset]);

  useEffect(() => {
    if (isMulti) {
      setValue('multi', isMulti);
    }
  }, [isMulti, setValue]);

  return (
    <form onSubmit={handleSubmit((values) => onSubmit({ ...values, type: investorSubType }))}>
      {isInvestor && !formValues?.companyNumber && (
        <div className='flex items-center space-x-2 mb-6'>
          <FormLabel className='mb-0'>Individual</FormLabel>
          <ToggleSwitch initial={false} onToggle={handleToggleInvestorEntity} />
          <FormLabel className='mb-0'>Entity</FormLabel>
        </div>
      )}

      {(isInvestor && investorSubType === InvestorSubTypes.INDIVIDUAL) || !isInvestor ? (
        <div className='grid grid-cols-2 gap-x-7 relative'>
          <FormContent<SignUpInput>
            fields={getSignUpFields(
              watchPassword,
              alreadyRegisteredExcessFields?.length ? alreadyRegisteredExcessFields : excessFields,
              disabledEmailInput,
              entityName,
              handleBlurEmail,
              isTypeEntity,
            )}
            register={register}
            errors={errors}
            control={control}
          />

          <AlreadyRegisteredInfoSection
            isAlreadyRegisteredData={isAlreadyRegisteredData}
            userType={userType}
            additionalQueryParams={`email=${getValues(SignUpFieldNames.EMAIL)}`}
            className='col-start-1 col-end-3 row-start-3 row-end-4'
          />

          <Input
            {...register(SignUpFieldNames.PASSWORD, SIGN_UP_PASSWORD_VALIDATION)}
            error={errors[SignUpFieldNames.PASSWORD]?.message}
            onFocus={toggleIsShownPasswordWidget}
            onBlur={toggleIsShownPasswordWidget}
            type={FieldTypes.PASSWORD}
            tabIndex={4}
            label='Password'
            className='row-start-4 row-end-5 col-start-1 col-end-3'
            dataTestId={SIGN_UP_FIELDS_TEST_IDS.PASSWORD_TEST_ID}
            required
          />
          <PasswordStrengthWidget
            password={watchPassword}
            className={cn(
              'col-start-1 col-end-3 row-start-6 row-end-7 2xl:row-start-3 2xl:absolute 2xl:-right-[85%] top-0',
              { block: isShownPasswordWidget },
              { hidden: !isShownPasswordWidget },
            )}
          />
        </div>
      ) : (
        <div className='grid grid-cols-4 gap-x-7 relative'>
          <EntityCompanyFields
            control={control}
            errors={errors}
            register={register}
            reset={reset}
            setValue={setValue}
            watch={watch}
          />
          <FormContent
            fields={getSignUpInvestorEntityFields(
              watchPassword,
              alreadyRegisteredExcessFields?.length ? alreadyRegisteredExcessFields : excessFields,
              disabledEmailInput,
              entityName,
              handleBlurEmail,
              isTypeEntity,
            )}
            register={register}
            control={control}
            errors={errors}
          />
          <AlreadyRegisteredInfoSection
            isAlreadyRegisteredData={isAlreadyRegisteredData}
            userType={userType}
            additionalQueryParams={`email=${getValues(SignUpFieldNames.EMAIL)}`}
            className='col-start-1 col-span-4 row-start-[9]'
          />
          <Input
            {...register(SignUpFieldNames.PASSWORD, SIGN_UP_PASSWORD_VALIDATION)}
            error={errors[SignUpFieldNames.PASSWORD]?.message}
            onFocus={toggleIsShownPasswordWidget}
            onBlur={toggleIsShownPasswordWidget}
            type={FieldTypes.PASSWORD}
            tabIndex={7}
            label='Password'
            dataTestId={SIGN_UP_FIELDS_TEST_IDS.PASSWORD_TEST_ID}
            className='col-start-1 col-span-4 row-start-[10]'
            required
          />
          <PasswordStrengthWidget
            password={watchPassword}
            className={cn(
              'col-start-1 col-span-4 row-start-[11] 2xl:row-start-[10] 2xl:absolute 2xl:-right-[85%] top-0',
              { block: isShownPasswordWidget },
              { hidden: !isShownPasswordWidget },
            )}
          />
        </div>
      )}
      <Button data-testid={SIGN_UP_BUTTON_TEST_IDS} className='my-2.5' isLoading={isLoading}>
        {submitButtonText || SIGN_UP_PAGE_TITLE}
      </Button>
    </form>
  );
};

export default SignUpForm;
