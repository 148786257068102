import React, { useCallback, useEffect, useState } from 'react';

import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getEntityActivityLog, getEntityActivityLogs } from 'modules/investors/action';
import { selectInvestorActivityLog, selectInvestorActivityLogs } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import AuditLogsCommonLayout from '../../audit-logs/AuditLogsCommonLayout';
import AuditLogsModal from '../audit-logs-modal/AuditLogsModal';

const AUDIT_LOG_PER_PAGE = 8;

const EntityAuditLogsTable = () => {
  const dispatch = useAppDispatch();

  const { list: auditLogs, isLoading, totalPages, total } = useAppSelector(selectInvestorActivityLogs);
  const { log, isLoading: isLogLoading } = useAppSelector(selectInvestorActivityLog);

  const { page, query, setPage, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { onOpen, ...modalState } = useModal();

  const [currentAuditLogsId, setCurrentAuditLogsId] = useState<number | null>(null);

  const handleOpenAuditInfoModal = (id: number) => {
    setCurrentAuditLogsId(id);
    onOpen();
  };

  const loadAuditLogs = useCallback(() => {
    dispatch(
      getEntityActivityLogs({
        page,
        per_page: AUDIT_LOG_PER_PAGE,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter,
      }),
    );
  }, [dispatch, filter, page, query, sorting.column, sorting.order]);

  useEffect(() => {
    loadAuditLogs();
  }, [loadAuditLogs]);

  useEffect(() => {
    if (currentAuditLogsId) dispatch(getEntityActivityLog(currentAuditLogsId));
  }, [dispatch, currentAuditLogsId]);

  return (
    <div className='flex flex-col'>
      <AuditLogsCommonLayout
        isLoading={isLoading}
        pagination={{ page, setPage, total, totalPages }}
        handleFilter={handleFilter}
        handleOpenAuditInfoModal={handleOpenAuditInfoModal}
        updateSorting={updateSorting}
        auditLogs={auditLogs}
      />
      <AuditLogsModal {...modalState} id={currentAuditLogsId} info={log} isLoading={isLogLoading} />
    </div>
  );
};

export default EntityAuditLogsTable;
