import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const useFilterParameters = () => {
  const { search } = useLocation();
  const { ftype, fvalue } = qs.parse(search);

  return {
    filter: ftype && fvalue ? `filter[${ftype}]=${fvalue}` : undefined,
    filterType: ftype,
    filterValue: fvalue,
  };
};

export default useFilterParameters;
