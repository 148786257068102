import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { MetaPage } from 'interfaces';
import { Fund, FundsState } from 'interfaces/funds.interfaces';

import { getFundsList } from './action';

export function getFundsListReducer(builder: ActionReducerMapBuilder<FundsState>) {
  builder.addCase(getFundsList.pending, (state) => ({
    ...state,
    ...META_INITIAL_STATE,
    isLoading: true,
  }));

  builder.addCase(getFundsList.fulfilled, (state, action: PayloadAction<{ data: Fund[]; meta: MetaPage }>) => {
    state.isLoading = false;
    state.list = action.payload.data;
    state.totalPages = action.payload.meta.last_page;
    state.total = action.payload.meta.total;
  });

  builder.addCase(getFundsList.rejected, (state) => ({
    ...state,
    ...META_INITIAL_STATE,
    isLoading: false,
  }));
}
