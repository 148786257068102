import React, { FC, useEffect, useState } from 'react';

import { UseFormReset } from 'react-hook-form';

import {
  UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES,
  UPLOAD_DOCUMENTS_MAX_FILE_SIZE,
  UPLOAD_DOCUMENTS_MODAL_TITLE,
  UPLOAD_DOCUMENTS_HELP_TEXT,
  UPLOAD_DOCUMENTS_DRAG_AREA_TITLE,
  UPLOAD_DOCUMENTS_SUBMIT_BUTTON_TEXT,
  DUE_DILIGENCE_DOCUMENTS_TYPES,
} from 'constants/due-diligence';
import { UPLOAD_FILE_WRONG_FILETYPE_ERROR, UPLOAD_FILE_WRONG_FILETYPE_PDF_PNG_ERROR } from 'constants/shared';
import { SIGNIFICANT_CONTROL_DOCUMENT_TYPES } from 'constants/significant-control-company';
import { DueDiligenceUploadDocumentsInput, Option } from 'interfaces';
import { uploadDueDiligenceDocument } from 'modules/due-diligence/action';
import { useAppDispatch } from 'modules/store';
import { UploadFileModal, UploadFileForm } from 'shared-components';

import UploadDueDiligenceDocumentsFileSizeError from './UploadDueDiligenceDocumentsFileSizeError';
import UploadDueDiligenceDocumentsModalInputs from './UploadDueDiligenceDocumentsModalInputs';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  documentLabels: Record<string, string>;
  documentTypesOptions?: Option[];
  dueDiligenceId?: string;
  modalProps?: { selectedDocumentType?: string; allowedFileTypes?: string[]; allowedFileHelpText?: string };
}

const UploadDueDiligenceDocumentsModal: FC<Props> = ({
  isOpen,
  onClose,
  documentLabels,
  dueDiligenceId,
  modalProps,
  documentTypesOptions,
}) => {
  const dispatch = useAppDispatch();

  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<Partial<DueDiligenceUploadDocumentsInput>>(
    {} as DueDiligenceUploadDocumentsInput,
  );

  const errorMessage =
    modalProps?.selectedDocumentType === SIGNIFICANT_CONTROL_DOCUMENT_TYPES.OTHER ||
    modalProps?.selectedDocumentType === SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT
      ? UPLOAD_FILE_WRONG_FILETYPE_PDF_PNG_ERROR
      : UPLOAD_FILE_WRONG_FILETYPE_ERROR;

  const handleCloseModal = () => {
    onClose();
    setIsUploadError(false);
  };

  const onSubmitUpload = async (
    [file]: File[],
    formData?: DueDiligenceUploadDocumentsInput,
    resetFormState?: UseFormReset<DueDiligenceUploadDocumentsInput>,
  ) => {
    if (!formData || !dueDiligenceId || !formData?.documentType) {
      return false;
    }

    setIsLoading(true);

    const {
      documentType: { value: documentType },
      officialDate,
      documentName,
    } = formData;
    try {
      await dispatch(
        uploadDueDiligenceDocument({
          file,
          dueDiligenceId,
          documentType,
          officialDate,
          ...(documentName && { documentName }),
        }),
      ).unwrap();

      if (resetFormState) {
        resetFormState({
          documentType: null,
          officialDate: '',
        });
      }

      handleCloseModal();
    } catch {
      return true;
    } finally {
      setIsLoading(false);
    }

    return false;
  };

  useEffect(() => {
    const selectedDocumentType = modalProps?.selectedDocumentType;

    if (!selectedDocumentType) {
      setInitialFormValues({} as DueDiligenceUploadDocumentsInput);
      return;
    }

    const documentLabel = documentLabels[selectedDocumentType] || '';

    setInitialFormValues({
      documentType: {
        label: documentLabel,
        value: selectedDocumentType,
      } as Option,
    });
  }, [documentLabels, modalProps?.selectedDocumentType]);

  return (
    <UploadFileModal
      title={UPLOAD_DOCUMENTS_MODAL_TITLE}
      isOpen={isOpen}
      onClose={handleCloseModal}
      preventOutsideClick={isUploadError}
    >
      <UploadFileForm<DueDiligenceUploadDocumentsInput>
        isLoading={isLoading}
        onSubmitUpload={onSubmitUpload}
        inputs={UploadDueDiligenceDocumentsModalInputs}
        inputsProps={{
          documentTypesOptions,
          disabled: modalProps?.selectedDocumentType,
          nameFieldDocumentType: DUE_DILIGENCE_DOCUMENTS_TYPES.OTHER,
        }}
        allowedFileHelpText={modalProps?.allowedFileHelpText || UPLOAD_DOCUMENTS_HELP_TEXT}
        allowedFileTypes={modalProps?.allowedFileTypes || UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES}
        maxFileSize={UPLOAD_DOCUMENTS_MAX_FILE_SIZE}
        dragAreaTitle={UPLOAD_DOCUMENTS_DRAG_AREA_TITLE}
        submitButtonText={UPLOAD_DOCUMENTS_SUBMIT_BUTTON_TEXT}
        initialFormValues={initialFormValues}
        allowedFileTypeErrorMessage={errorMessage}
        allowedFileSizeError={<UploadDueDiligenceDocumentsFileSizeError />}
        onError={(err) => setIsUploadError(Boolean(err))}
      />
    </UploadFileModal>
  );
};

export default UploadDueDiligenceDocumentsModal;
