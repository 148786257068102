import { useMemo, useState } from 'react';

import { Investment, InvestmentWithCompany } from 'interfaces';
import { deleteInvestment } from 'modules/investments/action';
import { useAppDispatch } from 'modules/store';
import { getDeleteInvestmentModalTitle } from 'utils';

export interface UseDeleteInvestmentReturnValues {
  isOpen: boolean;
  changeDeletingInvestment: (investment: Investment | InvestmentWithCompany | null) => void;
  resetDeletingInvestment: VoidFunction;
  onDeleteInvestment: VoidFunction;
  modalTitle: string;
  isLoading: boolean;
}

const useDeleteInvestment = (): UseDeleteInvestmentReturnValues => {
  const dispatch = useAppDispatch();

  const [currentInvestment, setCurrentInvestment] = useState<Investment | InvestmentWithCompany | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const modalTitle = useMemo(() => {
    return getDeleteInvestmentModalTitle(currentInvestment?.companyName || '', currentInvestment?.amountInvested || '');
  }, [currentInvestment?.amountInvested, currentInvestment?.companyName]);

  const changeDeletingInvestment = (investment: Investment | InvestmentWithCompany | null) => {
    setCurrentInvestment(investment);
  };

  const resetDeletingInvestment = () => setCurrentInvestment(null);

  const onDeleteInvestment = () => {
    if (currentInvestment?.id) {
      setIsLoading(true);

      dispatch(deleteInvestment(currentInvestment.id)).finally(() => {
        setIsLoading(false);
        resetDeletingInvestment();
      });
    }
  };

  return {
    isOpen: Boolean(currentInvestment),
    changeDeletingInvestment,
    resetDeletingInvestment,
    onDeleteInvestment,
    modalTitle,
    isLoading,
  };
};

export default useDeleteInvestment;
