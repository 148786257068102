import React from 'react';

import { ConstraintVariants } from 'constants/shared';
import { selectIsAdmin } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import { Constraint } from 'shared-components';

import DealsDetailsOutstandingTasksSkeleton from './deals-details-outstanding-tasks/DealsDetailsOutstandingTasksSkeleton';

const DealDetailsSkeleton = () => {
  const isAdmin = useAppSelector(selectIsAdmin);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <div className='w-full flex justify-between items-center'>
          <div className='w-full space-y-2'>
            <div className='skeleton-loader w-3/6 h-10' />
            <div className='skeleton-loader w-2/6 h-8' />
            <div className='skeleton-loader w-2/6 h-8' />
            <div className='skeleton-loader w-2/6 h-8' />
          </div>
          <div className='flex items-center space-x-3'>
            <div className='skeleton-loader w-200 h-14' />
            <div className='skeleton-loader w-150 h-14' />
          </div>
        </div>
        {isAdmin ? (
          <>
            <div className='skeleton-loader h-40 w-full mt-9' />
            <div className='skeleton-loader h-96 w-full mt-7' />
            <div className='skeleton-loader h-72 w-full mt-7' />
          </>
        ) : (
          <DealsDetailsOutstandingTasksSkeleton />
        )}
      </Constraint>
    </DashboardLayout>
  );
};
export default DealDetailsSkeleton;
