import React from 'react';

import { toast, ToastContent, ToastOptions } from 'react-toastify';

import { ReactComponent as BlockSvg } from 'assets/svg/block.svg';
import { ReactComponent as SuccessSvg } from 'assets/svg/filled-check-mark.svg';
import { ReactComponent as InfoSvg } from 'assets/svg/info.svg';
import { ReactComponent as WarningSvg } from 'assets/svg/warning-triangle.svg';

export const successNotify = (message: ToastContent, config?: ToastOptions) =>
  toast(message, {
    type: 'success',
    icon: () => <SuccessSvg />,
    className: '!bg-lightGreen-200',
    ...config,
  });

export const errorNotify = (message: ToastContent, config?: ToastOptions) =>
  toast(message, { type: 'error', icon: () => <BlockSvg />, className: '!bg-red-200', ...config });

export const warningNotify = (message: ToastContent, config?: ToastOptions) =>
  toast(message, { type: 'warning', icon: () => <WarningSvg />, className: '!bg-yellow-200', ...config });

export const infoNotify = (message: ToastContent, config?: ToastOptions) =>
  toast(message, {
    type: 'info',
    icon: () => <InfoSvg className='[&_path]:fill-violet-600' />,
    className: '!bg-lightBlue-100',
    ...config,
  });
