import { TableColumns } from 'interfaces';

export const COMPANY_PROFILE_PAGE_TITLE = 'Company Profile';

export const COMPANY_PROFILE_SHARES_SNIPPET_TITLE = 'Have shares in this company?';

export const COMPANY_PROFILE_SHARES_DESCRIPTION =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa pellentesque dictumst purus velit nisi, lectus consequat tortor. Ut sagittis, molestie ut tellus, tellus. Amet etiam interdum fames vulputate neque. Faucibus id in ut mauris integer non massa. Tristique eget senectus gravida gravida vitae in consequat malesuada. Suscipit ac odio mi velit pretium id ante sit.';

export const COMPANY_PROFILE_HOLDERS_TITLE = 'Persons with significant control';

export const COMPANY_PROFILE_NO_HOLDERS = '0 active persons with significant control';

export const COMPANY_PROFILE_OFFICERS_TITLE = 'Directors';

export const COMPANY_PROFILE_FINANCIAL_OVERVIEW = 'Financial Overview';

export const COMPANY_PROFILE_OFFICERS_COLUMNS: TableColumns[] = [
  { id: 'firstName', title: 'First Name', className: 'pl-2' },
  { id: 'lastName', title: 'Last Name' },
  { id: 'date', title: 'Date' },
];

export const COMPANY_PROFILE_HOLDERS_COLUMNS = [{ id: 'name', title: 'Shareholder Name', className: 'pl-2' }];
