import React, { FC } from 'react';

import { APPROVE_DUE_DILIGENCE_MODAL_TITLE, APPROVE_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT } from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import { selectBusinessName } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading: boolean;
  onApproveDueDiligence: VoidFunction;
};

const ApproveDueDiligenceModal: FC<Props> = ({ isOpen, onClose, isLoading, onApproveDueDiligence }) => {
  const businessName = useAppSelector(selectBusinessName);

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onApproveDueDiligence}
      isLoading={isLoading}
      title={`${APPROVE_DUE_DILIGENCE_MODAL_TITLE} ${businessName || ''}?`}
      submitButtonText={APPROVE_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT}
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
    />
  );
};

export default ApproveDueDiligenceModal;
