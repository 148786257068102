import { useState } from 'react';

import useModal from 'hooks/use-modal/useModal';
import { CustomHookWithActionsReturnValues } from 'interfaces';
import { restartDeal } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';

const useRestartDeal = ({ onSuccess }: { onSuccess?: VoidFunction }): CustomHookWithActionsReturnValues => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const modalProps = useModal();

  const handleRestartDeal = (id: number | number[]) => {
    if (!id || Array.isArray(id)) return;

    setIsLoading(true);
    dispatch(restartDeal({ id }))
      .unwrap()
      .then(onSuccess)
      .finally(() => {
        setIsLoading(false);
        modalProps.onClose();
      });
  };

  return [handleRestartDeal, modalProps, isLoading];
};

export default useRestartDeal;
