import React, { FC } from 'react';

import Typography from 'shared-components/Typography';

interface Props {
  title: string;
  value?: string;
}

const AuditLogsBasicInfoItem: FC<Props> = ({ title, value }) => {
  if (!value) return null;
  return (
    <div className='basis-1/2 shrink'>
      <Typography className='text-grey-800 font-bold'>{title}</Typography>
      <Typography className='mt-1'>{value}</Typography>
    </div>
  );
};

export default AuditLogsBasicInfoItem;
