import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

import cn from 'classnames';

import { Comment } from 'interfaces';

import CommentsItem from './CommentsItem';

export interface AddCommentInput {
  comment: string;
}

interface Props<T> {
  isOpen: boolean;
  commentsFooterContent?: ReactNode;
  comments: Comment<T>[];
}

const Comments = <T,>({
  isOpen,
  commentsFooterContent,
  comments,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const commentsTitle = `Comments(${comments?.length})`;

  if (!isOpen) return null;
  return (
    <div>
      {comments?.length ? (
        <>
          <h4>{commentsTitle}</h4>
          <ul className={cn('pt-2', { 'overflow-y-scroll h-250': comments?.length > 5 })}>
            {comments?.map((comment) => (
              <CommentsItem<T> key={comment.id} {...comment} />
            ))}
          </ul>
        </>
      ) : null}

      {commentsFooterContent}
    </div>
  );
};

export default Comments;
