import React from 'react';

import { ReactComponent as MyInvestmentsSvg } from 'assets/svg/my-investments.svg';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { LinkButton } from 'shared-components';
import SnippetNoInfoLayout from 'shared-components/SnippetNoInfoLayout';
import { TableData, TableRow } from 'shared-components/table';
import Typography from 'shared-components/Typography';

const InvestmentSnippetNoInfo = () => {
  return (
    <TableRow>
      <TableData className='pr-0' colSpan={5}>
        <SnippetNoInfoLayout icon={<MyInvestmentsSvg />}>
          <Typography className='flex text-grey-800'>
            <LinkButton
              className='text-inherit mr-1'
              variant={ButtonVariants.UNDERLINED_LINK}
              to={ROUTES.myInvestments}
            >
              Add Investments
            </LinkButton>
            now to start the share exchange process
          </Typography>
        </SnippetNoInfoLayout>
      </TableData>
    </TableRow>
  );
};

export default InvestmentSnippetNoInfo;
