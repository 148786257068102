import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { SHARE_EXCHANGE_STAGES } from '../constants/deals';
import { LocalStorageKeys } from '../constants/global';
import { selectIsAdmin } from '../modules/current-user/selectors';
import { useAppSelector } from '../modules/store';
import { addValueToLocalStorageArray, getParsedLocalStorageArray } from '../utils/local-storage';
import useModal, { UseModalReturnValues } from './use-modal/useModal';

type UseHideDealsFundInfoModalReturnValues = [
  Pick<UseModalReturnValues, 'isOpen' | 'onClose'>,
  (dealId: number) => void,
];

interface Props {
  stage?: SHARE_EXCHANGE_STAGES;
}

export const useOpenDealsFundInfoModal = ({ stage }: Props): UseHideDealsFundInfoModalReturnValues => {
  const { id: dealId } = useParams();

  const { onOpen, onClose, isOpen } = useModal();

  const isAdmin = useAppSelector(selectIsAdmin);

  const handleHideDealFundInfoModal = (dealId: number) => {
    addValueToLocalStorageArray(LocalStorageKeys.HIDE_DEALS_FUND_INFO_MODAL_IDS, dealId);
    onClose();
  };

  useEffect(() => {
    if (!stage || stage !== SHARE_EXCHANGE_STAGES.INTRODUCER_AGREEMENT || isAdmin) return;

    const dealsIds = getParsedLocalStorageArray(LocalStorageKeys.HIDE_DEALS_FUND_INFO_MODAL_IDS);

    const isModalShouldBeHidden = dealsIds?.includes(Number(dealId));

    if (!isModalShouldBeHidden) onOpen();
  }, [stage, onOpen, dealId, isAdmin]);

  return [{ isOpen, onClose }, handleHideDealFundInfoModal];
};
