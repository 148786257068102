import React, { useEffect, useMemo, useState } from 'react';

import { omit } from 'lodash';
import qs from 'query-string';
import { Control, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { UserTypes } from 'constants/user';
import { GET_INVESTORS_FILTER } from 'constants/users';
import { DocumentsFilterByCompanyInputs, Option } from 'interfaces';
import { selectUserType } from 'modules/current-user/selectors';
import { getCompaniesFilerOptions, getDocumentTypes } from 'modules/documents/action';
import { selectCompaniesFilterOptions, selectDocumentTypesOptions } from 'modules/documents/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { selectUsersOptions } from 'modules/users/selectors';
import history from 'services/history';
import { SelectFormInput } from 'shared-components';
import SelectUserInput from 'shared-components/SelectUserInput';

import SelectCompanySyncInput from './SelectCompanySyncInput';

const FILTER_TYPE = 'company_id';
const COMPANIES_WITH_DOCUMENTS_FILTER = 'filter[hasDocuments]=1';
const COMPANIES_PER_PAGE = 1000;

const DocumentsPageFilter = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  const companiesOptions = useAppSelector(selectCompaniesFilterOptions);
  const documentTypesOptions = useAppSelector(selectDocumentTypesOptions);
  const usersOptions = useAppSelector(selectUsersOptions);

  const userType = useAppSelector(selectUserType);

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const parsedSearchParameters = useMemo(() => qs.parse(search), [search]);

  const { control, watch } = useForm<DocumentsFilterByCompanyInputs>({
    defaultValues: {
      companyName: null,
      userId: null,
      documentType: null,
    },
  });

  const watchCompanyName = watch('companyName') as Option;
  const watchUserId = watch('userId') as Option;
  const watchDocumentType = watch('documentType') as Option;

  const sharedSearchParameters = useMemo(() => {
    const { fvalue, page } = parsedSearchParameters;

    return {
      ...parsedSearchParameters,
      page: watchCompanyName?.value === fvalue ? page : 1,
    };
  }, [parsedSearchParameters, watchCompanyName?.value]);

  const handleReplaceHistorySearch = (searchParams: Record<string, unknown>) => {
    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify({ ...sharedSearchParameters, ...searchParams }),
    });
  };

  const resetFilters = (resetKey?: string) => {
    const clearParameters = omit(parsedSearchParameters, resetKey ? [`${resetKey}`] : ['ftype', 'fvalue']);

    history.replace({
      pathname: window.location.pathname,
      search: qs.stringify({ ...clearParameters, page: 1 }),
    });
  };

  useEffect(() => {
    let searchParams: Record<string, string> = {};

    if (watchCompanyName) {
      searchParams = { ftype: FILTER_TYPE, fvalue: watchCompanyName.value };
    }

    if (watchDocumentType) searchParams.filterDocumentType = watchDocumentType?.value;

    if (watchUserId) searchParams.filterUserId = watchUserId?.value;

    handleReplaceHistorySearch(searchParams);
  }, [watchCompanyName, watchDocumentType, watchUserId]);

  useEffect(() => {
    if (!userType) return;

    setIsLoadingOptions(true);

    if (userType === UserTypes.ADMIN) {
      dispatch(getDocumentTypes());
      dispatch(getUsers({ filter: GET_INVESTORS_FILTER, per_page: 20 }));
    }
    dispatch(
      getCompaniesFilerOptions({ userType, per_page: COMPANIES_PER_PAGE, filter: COMPANIES_WITH_DOCUMENTS_FILTER }),
    )
      .unwrap()
      .finally(() => setIsLoadingOptions(false));
  }, [dispatch, userType]);

  return (
    <form className='mb-12 grid grid-cols-2 lg:grid-cols-[1fr_1fr_1fr] gap-4'>
      <SelectCompanySyncInput<DocumentsFilterByCompanyInputs>
        className='col-span-full sm:col-span-1'
        label='Filter by Company Name'
        name='companyName'
        control={control}
        isClearable
        onClearCallback={resetFilters}
        isLoadingOptions={isLoadingOptions}
        options={companiesOptions}
      />
      {userType === UserTypes.ADMIN && (
        <>
          <SelectUserInput
            className='col-span-full sm:col-span-1'
            name='userId'
            defaultOptions={usersOptions}
            onClearCallback={() => resetFilters('filterUserId')}
            label='Filter by Investor'
            control={control}
          />
          <SelectFormInput
            className='col-span-full sm:col-span-1 mb-0'
            label='Filter by Document Type'
            placeholder='Document Type'
            control={control as unknown as Control}
            name='documentType'
            options={documentTypesOptions}
            onClearCallback={() => resetFilters('filterDocumentType')}
            isClearable
          />
        </>
      )}
    </form>
  );
};

export default DocumentsPageFilter;
