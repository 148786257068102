import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminFoundersApis, adminFoundersMetricsApis } from 'apis';
import { showServerError, successNotify } from 'helpers';
import { GetListPayload, FounderModifiedCompany } from 'interfaces';

export const FOUNDERS_SLICE_NAME = 'founders';

export const getFounders = createAsyncThunk(
  `${FOUNDERS_SLICE_NAME}/getFounders`,
  async (params: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await adminFoundersApis.getFounders(params);

      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getFounder = createAsyncThunk(
  `${FOUNDERS_SLICE_NAME}/getFounder`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminFoundersApis.getFounder(id);

      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getFoundersMetrics = createAsyncThunk(
  `${FOUNDERS_SLICE_NAME}/getAdminFoundersMetrics`,
  async (rangeFilter: string) => {
    const response = await adminFoundersMetricsApis.getMetrics(rangeFilter);
    return response.data;
  },
);

export const getFounderCompanies = createAsyncThunk(
  `${FOUNDERS_SLICE_NAME}/getFounderCompanies `,
  async (params: string, { rejectWithValue }) => {
    try {
      const response = await adminFoundersApis.getFounderCompanies(params);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const updateFoundersCompanies = createAsyncThunk(
  `${FOUNDERS_SLICE_NAME}/updateFoundersCompanies`,
  async (
    { founderId, modifiedCompanies }: { founderId: string; modifiedCompanies: Array<FounderModifiedCompany> },
    { rejectWithValue },
  ) => {
    try {
      const promises = modifiedCompanies.map(({ companyId, confirmed }) =>
        adminFoundersApis.setFoundersConfirmedCompanies(founderId, companyId, confirmed),
      );

      await Promise.all(promises);

      successNotify(
        modifiedCompanies.length > 1 ? 'Companies relations are confirmed' : 'Company relation is confirmed',
      );

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
