import React, { FC } from 'react';

import { PRIVACY_POLICY_LABEL, PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LABEL } from 'constants/privacy-policy';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { LinkButton } from 'shared-components';

export const PRIVACY_POLICY_LABEL_TEST_ID = 'privacy-policy-label';

interface Props {
  className?: string;
}

const PrivacyPolicyLabel: FC<Props> = () => {
  return (
    <div data-testid={PRIVACY_POLICY_LABEL_TEST_ID}>
      By signing up you agree to the{' '}
      <LinkButton
        className='w-fit inline'
        variant={ButtonVariants.UNDERLINED_LINK}
        to={PRIVACY_POLICY_LINK}
        target='_blank'
        rel='noreferrer'
      >
        {PRIVACY_POLICY_LABEL}
      </LinkButton>
      {` and `}
      <LinkButton
        className='w-fit inline'
        variant={ButtonVariants.UNDERLINED_LINK}
        to={ROUTES.termsAndConditions}
        target='_blank'
        rel='noreferrer'
      >
        {TERMS_AND_CONDITIONS_LABEL}
      </LinkButton>
    </div>
  );
};

export default PrivacyPolicyLabel;
