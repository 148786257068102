import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  children?: ReactNode;
}

const ListItemWrapper: FC<Props> = ({ className, children }) => {
  return (
    <div className={twMerge('flex items-center justify-between p-6 border border-grey-200 rounded-2xl', className)}>
      {children}
    </div>
  );
};

export default ListItemWrapper;
