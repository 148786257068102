import React, { FC } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import { CompanyProfileRelatedItem } from 'interfaces';
import { Button, LinkButton, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

const SHARED_BUTTON_CLASSNAME = 'flex justify-center items-center';

interface Props extends CompanyProfileRelatedItem {
  className?: string;
}

const AdminCompanyProfileRelatedItem: FC<Props> = ({
  title,
  description,
  buttonText,
  linkPath,
  buttonVariant,
  locationState,
  action,
  isLoading,
  node,
  children,
  className,
}) => {
  return (
    <SnippetLayout className={twMerge('mb-6', className)} childrenClassName='flex flex-col gap-6'>
      <div className='flex justify-between items-center flex-col !2xl:flex-row gap-4 !2xl:gap-0'>
        <div className='mr-4 text-center !2xl:text-start'>
          <p className='text-xl font-bold'>{title}</p>
          {description && <Typography className='mt-2.5'>{description}</Typography>}
        </div>
        {node || (
          <>
            {buttonVariant === ButtonVariants.LINK ? (
              <LinkButton
                variant={ButtonVariants.SECONDARY}
                className={cn('w-full !2xl:w-[160px]', SHARED_BUTTON_CLASSNAME)}
                to={linkPath || ''}
                state={locationState}
              >
                {buttonText || 'View'}
              </LinkButton>
            ) : (
              <Button
                isLoading={isLoading}
                className={cn('w-full !2xl:w-[160px]', SHARED_BUTTON_CLASSNAME, { 'w-fit': isLoading })}
                variant={buttonVariant}
                onClick={action}
              >
                {buttonText}
              </Button>
            )}
          </>
        )}
      </div>
      {children}
    </SnippetLayout>
  );
};

export default AdminCompanyProfileRelatedItem;
