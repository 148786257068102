import React, { FC, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  DUE_DILIGENCE_SEND_REQUEST_FORM_FIELDS,
  DUE_DILIGENCE_SEND_REQUEST_MODAL_TITLE,
  GET_FOUNDERS_FORM_FIELDS,
} from 'constants/due-diligence';
import { UserTypes, InviteUserType } from 'constants/user';
import { MyInvitesFounderInput } from 'interfaces';
import { inviteUser } from 'modules/current-user/action';
import {
  getDueDiligenceFoundersForInvite,
  requestDueDiligenceFounderInfo,
  requestFounderIdByEmail,
} from 'modules/due-diligence/action';
import { selectDueDiligenceFoundersForInvite, selectDueDiligenceId } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, FormContent, ModalWindow } from 'shared-components';
import { checkIsAdmin } from 'utils';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

const DEFAULT_FORM_VALUES = {
  founders: null,
  email: '',
  firstName: '',
  lastName: '',
};

const RequestFounderModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const dueDiligenceId = useAppSelector(selectDueDiligenceId);
  const foundersForInviteList = useAppSelector(selectDueDiligenceFoundersForInvite);

  const { id: companyId } = useParams();

  const {
    handleSubmit,
    register,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<MyInvitesFounderInput>({
    defaultValues: { ...DEFAULT_FORM_VALUES },
  });

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const foundersValue = watch('founders');

  const showAdditionalFields = foundersValue?.value === 'notInTheList';

  const successInvite = () => {
    setIsLoading(false);
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<MyInvitesFounderInput> = ({ email, firstName, lastName, founders }) => {
    if (founders?.value === 'notInTheList') {
      if (!email || !firstName || !lastName) {
        return;
      }

      const inviteUserPayload = {
        type: UserTypes.FOUNDER,
        inviteType: InviteUserType.DUE_DILIGENCE,
        companyId: Number(companyId),
        email,
        firstName,
        lastName,
      };

      setIsLoading(true);

      dispatch(
        requestFounderIdByEmail({
          email,
        }),
      )
        .unwrap()
        .then(({ founderId }) => {
          if (founderId) {
            dispatch(
              requestDueDiligenceFounderInfo({
                id: Number(dueDiligenceId),
                founderId,
                isAdmin,
              }),
            ).finally(successInvite);

            return;
          }

          dispatch(inviteUser(inviteUserPayload)).finally(successInvite);
        })
        .catch(() => {
          dispatch(inviteUser(inviteUserPayload)).finally(successInvite);
        });

      return;
    }

    const findUser = foundersForInviteList?.find((founder) => {
      return founders?.value === founder.email;
    });

    if (!findUser) {
      return;
    }

    setIsLoading(true);

    dispatch(requestDueDiligenceFounderInfo({ id: Number(dueDiligenceId), founderId: findUser.id, isAdmin })).finally(
      () => {
        successInvite();
      },
    );
  };

  useEffect(() => {
    if (!companyId || !isOpen || !dueDiligenceId) {
      return;
    }

    dispatch(getDueDiligenceFoundersForInvite({ id: dueDiligenceId, page: 1, per_page: 100, isAdmin }));
  }, [companyId, dispatch, dueDiligenceId, isAdmin, isOpen]);

  return (
    <ModalWindow
      title={DUE_DILIGENCE_SEND_REQUEST_MODAL_TITLE}
      className={'lg:w-8/12 xl:w-6/12'}
      wrapperClassName='pt-14'
      onClose={onClose}
      isOpen={isOpen}
    >
      <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
        <FormContent
          fields={
            showAdditionalFields
              ? [...GET_FOUNDERS_FORM_FIELDS(foundersForInviteList), ...DUE_DILIGENCE_SEND_REQUEST_FORM_FIELDS]
              : GET_FOUNDERS_FORM_FIELDS(foundersForInviteList)
          }
          register={register}
          errors={errors}
          control={control}
        />

        <Button className={cn({ 'mt-6': !showAdditionalFields })} isLoading={isLoading}>
          {showAdditionalFields ? 'Invite' : 'Send Request'}
        </Button>
      </form>
    </ModalWindow>
  );
};

export default RequestFounderModal;
