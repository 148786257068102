import React, { FC } from 'react';

import cn from 'classnames';

import { ReactComponent as CheckmarkSvg } from 'assets/svg/check-mark-v2.svg';
import Typography from 'shared-components/Typography';

export const CHECKMARK_WITH_TITLE_SVG_TEST_ID = 'checkmark-with-title-svg';
export const CHECKMARK_WITH_TITLE_TEST_ID = 'CHECKMARK_WITH_TITLE_TEST_ID';

export interface Props {
  title: string;
  filled?: boolean;
  className?: string;
}

const CheckmarkWithTitle: FC<Props> = ({ title, filled = true, className }) => {
  return (
    <div className='flex items-center' data-testid={CHECKMARK_WITH_TITLE_TEST_ID}>
      <CheckmarkSvg
        className={cn('mr-3.5 min-w-[16px]', { 'fill-green-600': filled, 'fill-grey-300': !filled }, className)}
        data-testid={CHECKMARK_WITH_TITLE_SVG_TEST_ID}
      />
      <Typography>{title}</Typography>
    </div>
  );
};

export default CheckmarkWithTitle;
