import React, { ChangeEvent, RefObject, useRef } from 'react';

import SignatureCanvas from 'react-signature-canvas';

import { Button, Input } from 'shared-components';

import { ButtonVariants } from '../../constants/shared/button';
import { SIGNATURE_MAX_FILE_SIZE, SIGNATURE_MAX_FILE_SIZE_ERROR } from '../../constants/signature-settings';
import { errorNotify, uploadImageToCanvasWithAspectRatio } from '../../helpers';

interface Props {
  sigCanvas: RefObject<SignatureCanvas> | null;
}

const SignatureUpload = ({ sigCanvas }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if ((file?.size ?? 0) > SIGNATURE_MAX_FILE_SIZE) {
      errorNotify(SIGNATURE_MAX_FILE_SIZE_ERROR);
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        const canvas = sigCanvas?.current?.getCanvas();

        image.onload = () => {
          if (!canvas) return;
          sigCanvas?.current?.clear();

          uploadImageToCanvasWithAspectRatio(canvas, image);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!inputRef || !inputRef.current) return;

    inputRef.current.click();
  };

  return (
    <form>
      <Button variant={ButtonVariants.SECONDARY} onClick={handleButtonClick}>
        Upload Image
      </Button>
      <Input
        className='hidden'
        ref={inputRef}
        accept='image/png,image/svg+xml'
        type='file'
        onChange={handleFileUpload}
      />
    </form>
  );
};

export default SignatureUpload;
