import React from 'react';

import { DUE_DILIGENCE_PREFERENCE_RIGHTS_FIELDS, DueDiligenceSections } from 'constants/due-diligence';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { UsePreferenceRightsReturnValues } from 'hooks/usePreferenceRights';
import { FormContent } from 'shared-components';
import { highlightDDFields } from 'utils/due-diligence';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';

interface Props extends UsePreferenceRightsReturnValues {
  getUnfieldValue: getUnfieldValueFunc;
}

const PreferenceRights = ({
  register,
  control,
  errors,
  isLoading,
  handleDiscardChanges,
  handleSaveField,
  getUnfieldValue,
  isEditing,
}: Props) => {
  return (
    <DueDiligenceBlock
      blockNumber='7'
      descriptionClassName='text-grey-500'
      sectionType={DueDiligenceSections.DEBT_PREFERENCE_RIGHTS}
      title='Debt, Preference Rights, Warrants & Options'
      onClickDiscardChanges={handleDiscardChanges}
      isLoading={isLoading}
    >
      <FormContent
        fields={highlightDDFields(DUE_DILIGENCE_PREFERENCE_RIGHTS_FIELDS, getUnfieldValue)}
        register={register}
        control={control}
        errors={errors}
        onBlur={handleSaveField}
        isDisabled={!isEditing}
      />
    </DueDiligenceBlock>
  );
};

export default PreferenceRights;
