import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';
import { formatUsersToOptions } from 'utils';

const selectState = (x: RootState) => x.usersReducer;

export const selectUsers = createSelector(selectState, (state) => state.users);

export const selectUsersOptions = createSelector(selectState, (state) => formatUsersToOptions(state?.users || []));

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

export const selectUsersPaginationData = createSelector(selectState, ({ totalPages, total }) => ({
  totalPages,
  total,
}));

export const selectUserData = createSelector(selectState, (state) => state.userData);

export const selectUser = (userId: string | undefined) =>
  createSelector(selectState, (state) => (userId ? state.users?.find(({ id }) => id == parseInt(userId, 10)) : null));

export const selectUserId = createSelector(selectState, (state) => state.userData?.id);

export const selectUserPermissions = createSelector(selectState, ({ permissions, enabledPermissions }) => ({
  permissions,
  enabledPermissions,
}));

export const selectIsPermissionsLoading = createSelector(selectState, (state) => state.isPermissionsLoading);

export const selectProfileActionState = createSelector(
  selectState,
  ({ isProfileSettingsActionLoading, isProfileSettingsActionCalled }) => ({
    isProfileSettingsActionCalled,
    isProfileSettingsActionLoading,
  }),
);

export const selectProfileLinkedInIntegrations = createSelector(selectState, (state) => state.userData?.companies);
