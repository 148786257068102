import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';

import { adminDocumentsApis, documentsApis, investorDocumentsApis } from 'apis';
import { UserTypes } from 'constants/user';
import { showServerError, successNotify } from 'helpers';
import { DownloadDocumentPayload, GetFileDocumentsPayload, GetListPayload, UploadDocumentPayload } from 'interfaces';
import { getCompaniesOptionsRequestByUserType } from 'utils';
import { getDocumentsRequestByUserType } from 'utils/documents';

export const DOCUMENTS_SLICE_NAME = 'documents';

export const getDocuments = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/getDocuments`,
  async ({ userType, ...data }: GetFileDocumentsPayload & { userType?: UserTypes }) => {
    const response = await getDocumentsRequestByUserType(data, userType);

    return response.data;
  },
);

export const getFundDocuments = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/getFundDocuments`,
  async (data?: GetFileDocumentsPayload) => {
    const response = await investorDocumentsApis.getFundDocuments(data);
    return response.data;
  },
);

export const getCarouselDocuments = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/getCarouselDocuments`,
  async (data: GetFileDocumentsPayload) => {
    const response = await documentsApis.getDocuments(data);

    return response.data;
  },
);

export const getTableDocuments = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/getTableDocuments`,
  async ({ isAdmin, ...data }: GetFileDocumentsPayload & { isAdmin?: boolean }) => {
    const response = await (isAdmin ? adminDocumentsApis.getDocuments(data) : documentsApis.getDocuments(data));

    return response.data;
  },
);

export const uploadDocument = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/uploadDocuments`,
  async ({ isAdmin, notifyClassName, ...data }: UploadDocumentPayload, { rejectWithValue }) => {
    try {
      const response = await (isAdmin ? adminDocumentsApis.uploadDocument(data) : documentsApis.uploadDocument(data));

      successNotify('Document added successfully', {
        className: `right-10 ${notifyClassName}`,
      });
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const downloadDocument = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/downloadDocument`,
  async ({ filename, ...downloadPayload }: DownloadDocumentPayload) => {
    try {
      const response = await documentsApis.downloadDocument(downloadPayload);

      saveAs(response.data, filename);
    } catch (err) {
      showServerError(err);
    }
  },
);

export const getCompaniesFilerOptions = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/getCompaniesFilerOptions`,
  async ({ userType, ...parameters }: Partial<GetListPayload> & { userType: UserTypes; per_page: number }) => {
    const { data } = await getCompaniesOptionsRequestByUserType(userType, { ...parameters, page: 1 });

    return data.data.map(({ companyName, id }) => ({
      label: companyName,
      value: id.toString(),
    }));
  },
);

export const getCompaniesDocumentsUploadOptions = createAsyncThunk(
  `${DOCUMENTS_SLICE_NAME}/getCompaniesUploadOptions`,
  async ({ userType, ...parameters }: Partial<GetListPayload> & { userType: UserTypes; per_page: number }) => {
    const { data } = await getCompaniesOptionsRequestByUserType(userType, { ...parameters, page: 1 });

    return data.data.map(({ companyName, id }) => ({
      label: companyName,
      value: id.toString(),
    }));
  },
);

export const getDocumentTypes = createAsyncThunk(`${DOCUMENTS_SLICE_NAME}/getDocumentTypes`, async () => {
  const response = await documentsApis.getDocumentTypes();

  return response.data.data;
});
