import React, { useEffect, useState } from 'react';

import { unwrapResult } from '@reduxjs/toolkit';

import { TermsAndConditionsData } from 'interfaces';
import { asyncGetTermsAndConditions } from 'modules/auth/action';
import { useAppDispatch } from 'modules/store';
import { SpinLoader } from 'shared-components';

const H2_TERMS_AND_CONDITIONS = 'pt-14 text-[42px] font-bold font-inter text-[#080E36]';
const LI_TERMS_AND_CONDITIONS = 'text-lg text-justify';
const TABLE_TERMS_AND_CONDITIONS = 'border-separate my-[-15px] border-spacing-y-[30px] ml-4';
const TABLE_TD_TERMS_AND_CONDITIONS = 'w-[200px] font-bold align-top pr-[20px]';
const LI_BOLD_TERMS_AND_CONDITIONS =
  'font-bold font-inter text-32 text-[#080E36] leading-10 [&>*]:font-normal [&>*]:text-lg [&>*]:font-roboto [&>*]:text-black';
const OL_TERMS_AND_CONDITIONS = 'list-none [counter-reset:li]';
const LI_TABLE_CELL_TERMS_AND_CONDITIONS = 'table before:table-cell before:pr-[20px] mb-[34px]';
const LI_TOP_CLASSNAME = 'm-0';
const CONTAINER_CLASSNAME = 'max-w-[1360px] mx-auto px-[30px]';

const TermsAndConditions = () => {
  const dispatch = useAppDispatch();

  const [conditions, setConditions] = useState<TermsAndConditionsData | null>(null);

  useEffect(() => {
    dispatch(asyncGetTermsAndConditions())
      .then(unwrapResult)
      .then((res) => setConditions(res));
  }, [dispatch]);

  if (!conditions) return <SpinLoader />;

  return (
    <div className='bg-[#F1F1F3] pb-20'>
      <div className="relative bg-[url('https://pland.co/wp-content/uploads/2024/02/buildings1.jpg')] h-[254px] bg-no-repeat bg-cover bg-center flex items-center">
        <div className={`${CONTAINER_CLASSNAME} w-full`}>
          <h2 className={H2_TERMS_AND_CONDITIONS}>Terms And Conditions of Service</h2>
        </div>
      </div>
      <div className={`${CONTAINER_CLASSNAME} my-10`}>
        <div className='li-terms-and-conditions ol-second-level ol-third-level qwe ol-second-level-before ol-third-level-before [&>ol>li>ol]:first:mt-[44px] [&>ol>li]:my-[44px]'>
          <ol className={OL_TERMS_AND_CONDITIONS}>
            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS} ${LI_TOP_CLASSNAME}`}>
              Provision of the Services
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  The Services are provided by Getpland Limited (“we”, “our”, “us”, ”planD”). We are registered in
                  England and Wales under company 12901503 and have our registered office at 27 Ceylon Road, London,
                  England, W14 0PY.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>“You” are the customer of these Services.</li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  The Services are split into two distinct services, regulated and unregulated. The first are Regulated
                  Services, the second are Unregulated Services. Regulated Services shall be subject to a further
                  Engagement Letter in accordance with the Regulations. The Regulated Services cannot be provided
                  without Your agreement to the Engagement Letter. These Terms are in addition to the terms included in
                  the Engagement Letter and together these Terms and the Engagement Letter form a binding agreement
                  between You and us. The Unregulated Services are provided on these terms only and any other terms that
                  You may seek to reference or include are excluded.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Unregulated Services - No Advice
                  <ol className={`${OL_TERMS_AND_CONDITIONS}`}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      The Unregulated Services connect users for the purposes of facilitating transactions. We do not
                      provide legal, tax, accounting, or financial advice in relation to the Unregulated Services.
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      It is your responsibility to connect with Advisors to obtain their professional services and to
                      take the appropriately qualified advice to make an informed decision. The professional services
                      are provided by the Advisors and not us. If the professional services are regulated, then any
                      regulatory obligations lie solely with the Advisor and not us.
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE SERVICES accessed via the planD
                  website and platform as they represent a binding legal agreement and you will be bound by them.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  BY CONTINUING TO USE THE SERVICES YOU ARE INDICATING YOUR ACCEPTANCE OF THE TERMS OF SERVICE. YOUR
                  ATTENTION IS PARTICULARLY DRAWN TO CLAUSE 4, relating to price and payment and that you are bound to
                  pay us commission based on the scope and scale of a commercial opportunity that arises as a
                  consequence of an introduction facilitated by GetplanD Limited. This is a condition of this Agreement
                  and goes to the heart of the Agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  use of the Services includes accessing it, perusing it, linking to it, copying any of the content on
                  it or using any of the functionality offered via it. If you do not agree to these terms and conditions
                  of service, you must not use the Services.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You are also responsible for ensuring that all persons who access our Services through your internet
                  connection are aware of these Terms and Conditions of Service and other applicable terms and
                  conditions, and that they comply with them. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A
                  COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY TO THESE
                  TERMS AND CONDITIONS, IN WHICH CASE THE TERMS `YOU` OR `YOUR` SHALL REFER TO SUCH ENTITY. IF YOU DO
                  NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS OF SERVICE, YOU MUST
                  NOT PROCEED AND MAY NOT USE THE SERVICE
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  The following additional terms also apply to your use of the Services:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      Our Website Terms of use, which sets out the terms on which you may access our website including
                      the acceptable use policy (where amongst other things, you will find the portal to access the
                      Services.)
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      Our Privacy Policy, which sets out the terms on which we process any personal data we collect from
                      you, or that you provide to us.
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      Our Cookie Policy, which sets out information about the cookies on the Website.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Appointment
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  In order to access the Services, you must create an account. When registering for an Account, we may
                  request certain information from you as part of our due diligence. We reserve the right to reject a
                  registration which fails to provide the requested information or does not otherwise pass our due
                  diligence checks.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You are solely responsible for all activity that occurs under your account. If you believe your
                  account is being used without permission, you should contact us immediately at support@pland.co. We
                  will not be liable for any unauthorised use of your account.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  By creating an account, you are appointing us on a non-exclusive basis to provide the Services and to
                  enable introductions to Potential Counterparties. We do not provide services exclusively for you and
                  nothing in this agreement excludes us from entering into agreements with anyone else.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Where we enable an introduction to a Potential Counterparty that enters into any arrangement of
                  commercial value with you, you will promptly notify us in accordance with clause 4.4.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  If a Potential Counterparty, then introduces you to a third party who then enters into any arrangement
                  of commercial value with you, by virtue of the initial Introduction, we shall be deemed to have
                  Introduced the third party to you as well and the third party shall be a Potential Counterparty.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Our Obligations
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We agree to supply the Services to you pursuant to the terms and conditions of this Agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We agree to share our commission with you on the below basis where you enable an introduction to a
                  Potential Counterparty either pursuant to clause 2.5 or otherwise. You shall be deemed to have enabled
                  an introduction to a Potential Counterparty where:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      You provide the contact details of the Potential Counterparty and;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      The Potential Counterparty does not have an account with the planD platform and;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      The Potential Counterparty enters into any arrangement of commercial value with a planD account
                      holder and;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      The planD account holder pays Commission to us as specified at the time of the transaction
                      (“Shared Commission”)
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Our obligation to pay Shared Commission is on a “pay when paid” basis and we shall not be liable to
                  pay Shared Commission where we have not received a Commission payment. We shall be under no liability
                  or obligation whatsoever to pursue payment of a Commission payment from any third party.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall have no authority, and shall not hold ourselves out, or permit any person to hold itself out,
                  or otherwise create the impression that it is authorised to bind you in any way.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Price And Payment
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You undertake to pay the applicable Fee for the Regulated Services as set out in the Engagement
                  Letter. You shall pay invoices within 30 days of issue.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Where we enable an introduction to a Potential Counterparty and that Potential Counterparty agrees or
                  enters into an arrangement for commercial value (whether for money or money’s worth) within 30 days of
                  that introduction (each a “<b>Transaction Event</b>”), we shall be entitled to receive a commission (“
                  <b>Commission</b>”).
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall be entitled to Commission in respect of any Transaction Event 30 days after Introduction of a
                  Potential Customer to the Company.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall be entitled to invoice Commission in relation to Transaction Events on the date of the
                  Potential Counterparty pays an instalment to you and on each occasion that the Potential Counterparty
                  pays an instalment.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You shall promptly notify us in writing of the following:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>A Potential Customer enters into a Transaction Event</li>
                    <li className={LI_TERMS_AND_CONDITIONS}>The date of the Transaction Event</li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      The amount payable for the assets or services procured in the Transaction Event
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      the dates on which payments are due for the Transaction Event
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      the dates on which payments are made for the Transaction Event
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall invoice the eligible Commission at the end of each calendar month, together with any
                  applicable VAT (or other applicable sales tax) which shall be added to the sum in question. The
                  Commission shall be due to us, and the Commission shall be paid within 30 days of the date of the
                  invoice.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  If any sum payable under this Agreement is not paid on or before the due date of the relevant invoice
                  then, without prejudice to any other rights it may have, We shall be entitled to charge the Company
                  interest on that sum at the rate of five per cent (5%) per annum above the base lending rate from time
                  to time of Barclays Bank PLC commencing from the due date of that invoice through to the date that
                  full payment is made.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  If any dispute arises as to the amount of Commission payable by you to us, the same shall be referred
                  to an independent auditor in accordance with clause 5 for settlement and their decision, save in the
                  case of manifest error, shall be final and binding on both parties.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Termination of this Agreement, howsoever arising, shall not affect the continuation in force of this
                  Clause 4 and your obligation to pay Commission to us in accordance with it.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Audit Rights
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You shall:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      provide us (and our auditors and other advisers) with all reasonable co- operation, access and
                      assistance in us establishing from your books and records whether and how much Commission is owed
                      to us;
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  The rights under this clause 5 shall survive termination and shall remain in full force after
                  termination of this Agreement howsoever arising.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Your Obligations
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You undertake that you shall not, directly, or indirectly seek to circumvent us or the payment of
                  Commission by:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      Pursuing the Potential Counterparty independently of our Services.
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      induce, solicit, procure, or otherwise encourage any third party to pursue the Potential
                      Counterparty; or
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      seek, encourage, or respond to any approach from any third party to pursue the Potential
                      Counterparty
                    </li>
                  </ol>
                  for the duration of the Agreement and for a further period of 12 months following the termination of
                  the Agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You will use your best endeavours to inform us of any Commissions arising under Transaction Events
                  whether payable by you or others.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You represent, warrant and undertake that
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      you will use the Services only for lawful purposes and in accordance with this Agreement;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      you will act in accordance with our Acceptable use Policy and you will not misrepresent yourself
                      or your business or the terms of any Transaction Event to any Potential Counterparty or other
                      third party;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      you will at all times comply with Data Protection Legislation;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      any Personal Data will only be provided to us in accordance with Data Protection Legislation;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      you will be solely responsible for the accuracy and submission of Your Business Data when using
                      the Services and we will not be liable for failing to ensure the accuracy of any Your Business
                      Data; and
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      any software, data, equipment, or other materials provided by you to us or employed by you in your
                      use of or receipt of the Services shall not infringe any Intellectual Property Rights, privacy or
                      Personal Data interests of any third party and shall not be obscene or defamatory of any person
                      and shall not violate the laws or regulations of any state which may have jurisdiction over such
                      activity; and
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      You have full capacity and authority to enter into and perform your obligations under this
                      Agreement;
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  In the event of any breach of any of the foregoing representations or warranties, in addition to any
                  other remedies available at law or in equity, we will have the right to suspend immediately any
                  related Services if deemed reasonably necessary by us to protect our proper interests or our other
                  customers. If practicable and depending on the nature of the breach, we may (in its absolute
                  discretion) give you an opportunity to cure such breach. In such case once you have cured the breach,
                  we will promptly restore the Services.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Disclaimer
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We make no representations that a Potential Counterparty is a suitable counterparty or that any
                  representations made by the counterparty are true, accurate, reliable or relevant to you;
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Any information, statements, forecasts, financial data or other data provided by any Potential
                  Counterparty should be independently verified by you and it is your sole responsibility to conduct
                  your own investigations and due diligence before entering into any agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We make no representations that we or any Potential Counterpart will enter into a commercial
                  arrangement with you.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Warranties
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We warrant to and undertake with you that:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      we will use our reasonable efforts to provide the Services and to exercise reasonable care and
                      skill when providing the Services in accordance with the terms of this Agreement;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      we have full right power and authority to provide the Services to you in accordance with the terms
                      of this Agreement;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      we will perform our obligations pursuant to this Agreement in accordance with all applicable law;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      we will at all times comply with the Data Protection Legislation.
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  For the avoidance of doubt, we make no warranty that use of the Service will guarantee or enable
                  compliance with any particular laws, regulations or codes of conduct. Your compliance with any legal
                  requirements to which you may be subject is entirely your own responsibility and we shall have no
                  liability or responsibility in respect of the same.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We make no representation or warranty that any information provided by or contained within the
                  Services shall be accurate, complete or up-to-date and you shall be responsible for verifying any such
                  information on which you may choose to rely.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall follow our archiving procedures for Your Business Data. In the event of any loss or damage to
                  Your Business Data, your sole and exclusive remedy shall be for us to use reasonable commercial
                  endeavours to restore Your Business Data from the latest back- up maintained by us in accordance with
                  our archiving procedure. We shall not be responsible for any loss, destruction, alteration or
                  disclosure of Your Business Data caused by any third party (except those third parties sub-contracted
                  by us to perform services related to the maintenance) and back-up of Your Business Data for which we
                  shall remain fully liable.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You acknowledge that the Services may enable or assist you to access the content of, correspond with,
                  and procure products and services from, third parties via third-party websites (whether Potential
                  Counterparties or not) and that you do so solely at its own risk. We make no representation, warranty
                  or commitment and shall have no liability or obligation whatsoever in relation to the content or use
                  of, or correspondence with, any such third-party website, or any transactions completed, and any
                  contract entered into by you, with any such third party. Any contract entered into and any transaction
                  completed via any third-party website is between you and the relevant third party.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Except for the express warranties set forth in this Clause 8, the Services are provided on an “as is”
                  basis, and your use of the Services is at your own risk. We do not make, and hereby disclaim, any and
                  all other express and/or implied warranties, statutory or otherwise, including, but not limited to,
                  warranties of merchantability, fitness for a particular purpose and any warranties arising from a
                  course of dealing, usage, or trade practice. We do not warrant that the Services will be
                  uninterrupted, error-free, or completely secure.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We do not and cannot control the rate of flow of data to or from our network and other portions of the
                  Internet. Such flow depends in large part on the performance of Internet services provided or
                  controlled by third parties. At times, actions or omissions of such third parties can impair or
                  disrupt connections to the Internet (or portions thereof). Although we will use commercially
                  reasonable efforts to take actions we deem appropriate to remedy and avoid such events, we cannot
                  guarantee that such events will not occur. Accordingly, we disclaim any and all liability resulting
                  from or related to such events.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Intellectual Property
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Without prejudice to your rights in your own materials and Your Business Data, we jointly agree that
                  you shall not acquire any Intellectual Property Rights whatsoever in respect of the webportal,
                  documentation and other materials used by us in connection with or related to the provision of the
                  Services hereunder.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We warrant that we have all necessary right, title and interest to enable you to benefit from the
                  Services in accordance with this Agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You hereby grant to us:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      A non-exclusive, royalty-free, world-wide licence during the term of the Agreement to use, copy,
                      reproduce, and manipulate Your Business Data provided by you or resulting from the Services for
                      the provision of the Services to you; and
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      A non-exclusive, perpetual, royalty free, world-wide licence to use, copy, reproduce, process and
                      manipulate the Shared Data provided by you or resulting from the Services for the provision of the
                      Services by us to our customers; and
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      A non-exclusive, royalty-free, world-wide licence during the term of the Agreement to use,
                      reproduce and display your trade marks for the purposes of using the trademarks for the provision
                      of the Services and for our marketing purposes.
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We acknowledge that we obtain no Intellectual Property Rights whatsoever that are owned by you.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Confidential Information
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Each party shall maintain secret and confidential all Confidential Information obtained from the other
                  in the course of or pursuant to this Agreement or prior to and in contemplation of it. The party
                  receiving such Confidential Information shall respect the other’s proprietary rights in such
                  Confidential Information and shall use the same exclusively for the purposes of this Agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  The party receiving such Confidential Information shall not disclose the same to any third party save:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      to those of its employees and contractors to whom and to the extent that such disclosure is
                      essential for the purposes of this Agreement and only where those employees or contractors are
                      bound to obligations of confidentiality no less onerous than those set out herein;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>as it may be required to do so by law.</li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  This Clause 11 shall not apply to any information which was already lawfully in the possession of the
                  party receiving it and any information in the public domain (otherwise than by the fault of the party
                  receiving it).
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Limitarion of Liability
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall have no liability to You for any indirect or consequential loss, nor for any:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>loss of profit, sales, revenue or business;</li>
                    <li className={LI_TERMS_AND_CONDITIONS}>loss of anticipated savings;</li>
                    <li className={LI_TERMS_AND_CONDITIONS}>loss of or damages to goodwill;</li>
                    <li className={LI_TERMS_AND_CONDITIONS}>loss of agreements or contracts;</li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      loss of use or corruption to software, data or information; or
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      any loss arising out of lawful termination of this Agreement or any decision not to renew its
                      term.
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  No matter how many claims are made and whatever the basis of such claims, our maximum aggregate
                  liability under or in connection with this Agreement and its subject matter (to the extent that such
                  loss is not excluded by any other provision of this Agreement) whether such claim arises in contract,
                  tort or otherwise, shall not exceed a sum equal to the total value of the Fees and Commission paid by
                  You in the twelve (12) months prior to the first event that gave rise to a cause of action against us.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  None of the clauses above shall apply so as to restrict liability for death or personal injury
                  resulting from the negligence of any party, nor shall they serve to limit either party’s liability for
                  fraud or fraudulent misrepresentation or other liability that cannot be so excluded, by law.
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Termination
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  We shall have the right at any time to terminate this Agreement including any rights granted hereunder
                  (without prejudice to any pre-existing rights or liabilities of the parties) by giving You no less
                  than 1 months’ notice in writing.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  You shall have the right to terminate this Agreement if we
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      {' '}
                      commit a material breach of a term of this agreement which breach is irremediable
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      breach any term of this Agreement, and such breach being remediable, we do not remedy that breach
                      within thirty (30) days of being notified in writing you to do so.
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>become insolvent.</li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>Relationship</li>
            <p className='mb-4 text-lg'>
              Nothing contained in this Agreement shall be deemed to constitute the relationship of principal and agent
              nor partnership between us and we shall not hold ourself out and shall procure that our employees, agents
              and representatives do not hold themselves as your agent.
            </p>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              Data Protection and Information Security
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  In this Agreement, Controller, Processor, Data Subject, Personal Data and Processing shall have the
                  meaning given to them in the Data Protection Legislation.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  In the event that Personal Data is passed between us <b>(Shared Personal Data)</b> during the
                  performance of our respective obligations, each party undertakes to the other that it will take all
                  necessary steps to ensure that it operates at all times in accordance with applicable Data Protection
                  Law.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Without limiting clause 15.2, each party shall be independent and separate Controllers of Personal
                  Data that is transferred pursuant to this Agreement.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Each party shall comply with all the obligations imposed on a Controller under the Data Protection
                  Law, and any material breach of the Data Protection Law by one party shall, if not remedied within 30
                  days of written notice from the other party, give grounds to the other party to terminate this
                  agreement with immediate effect.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Each party shall:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      ensure that it has all necessary notices and consents and lawful bases in place to enable lawful
                      transfer of the Shared Personal Data to the other party;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      give full information to any Data Subject whose Personal Data may be Processed under this
                      agreement of the nature of such Processing. This includes giving notice that, on the termination
                      of this Agreement, Personal Data relating to them may be retained by or, as the case may be,
                      transferred to one or more of the parties, their successors and assignees;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      ensure that it has in place appropriate technical and organisational measures, to protect against
                      unauthorised or unlawful processing of Personal Data and against accidental loss or destruction
                      of, or damage to, Personal Data.
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      not transfer any Personal Data outside the UK unless the transferor ensures that (i) the transfer
                      is to a country approved under the applicable Data Protection Law as providing adequate
                      protection; or (ii) there are appropriate safeguards or binding corporate rules in place pursuant
                      to the applicable Data Protection Law; or (iii) the transferor otherwise complies with its
                      obligations under the applicable Data Protection Law by providing an adequate level of protection
                      to any Personal Data that is transferred; or (iv) one of the derogations for specific situations
                      in the applicable Data Protection Law applies to the transfer.
                    </li>
                  </ol>
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  Each party shall assist the other in complying with all applicable requirements of the Data Protection
                  Law. In particular, each party shall:
                  <ol className={OL_TERMS_AND_CONDITIONS}>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      consult with the other party about any notices given to Data Subjects in relation to the Shared
                      Personal Data;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      promptly inform the other party about the receipt of any Data Subject rights request;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      provide the other party with reasonable assistance in complying with any Data Subject rights
                      request;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      not disclose, release, amend, delete or block any Shared Personal Data in response to a data
                      subject rights request without first consulting the other party wherever possible;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      assist the other party, at the cost of the other party, in responding to any request from a Data
                      Subject and in ensuring compliance with its obligations under the Data Protection Law with respect
                      to security, Personal Data breach notifications, data protection impact assessments and
                      consultations with the Information Commissioner or other regulators;
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      notify the other party without undue delay on becoming aware of any breach of the Data Protection
                      Law; and
                    </li>
                    <li className={LI_TERMS_AND_CONDITIONS}>
                      maintain complete and accurate records and information to demonstrate its compliance with this
                      clause 14.6.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              GENERAL
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  APPLICABLE LAW You and your agents and subcontractors shall at your own expense comply with all laws
                  and regulations. In particular, you shall comply with all applicable laws, regulations, mandatory
                  codes and sanctions relating to anti-bribery and anti- corruption including the Bribery Act 2010.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  WAIVER: No failure or delay by a party to exercise any right or remedy provided under the terms of
                  this Agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it
                  preclude or restrict the further exercise of that or any other right or remedy. No single or partial
                  exercise of such right or remedy shall preclude or restrict the further exercise of that or any other
                  right or remedy.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  SEVERANCE: If any court or administrative body finds that any provision of this Agreement (or part of
                  any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the
                  extent required, be deemed to be deleted, and the validity and enforceability of the other provisions
                  of this Agreement shall not be affected. If any invalid, unenforceable or illegal provision of this
                  Agreement would be valid, enforceable and legal if amended, the parties shall negotiate in good faith
                  to amend such provision such that, as amended, it is legal, valid and enforceable, and, to the
                  greatest extent possible, achieves the parties` original commercial intention.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  ENTIRE AGREEMENT: This Agreement constitutes the entire agreement between the parties and supersedes
                  all previous discussions, correspondence, negotiations, arrangements, understandings and agreements
                  between them relating to its subject matter.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  ASSIGNMENT AND SUBCONTRACTING: You are not entitled to assign or subcontract all or any of your rights
                  and obligations under this Agreement to any person without our prior written consent.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  THIRD-PARTY RIGHTS: This Agreement is made for the benefit of the parties to it and (where applicable)
                  their successors and permitted assigns and is not enforceable by virtue of the Contracts (Rights of
                  Third Parties) Act 1999 or otherwise by any person who is not a party to it.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  NOTICES: Any notice or other communication required to be given to a party under or in connection with
                  this contract shall be in writing and shall be delivered by hand or sent by pre-paid first-class post
                  or other next working day delivery service, at its registered office (if a company) or (in any other
                  case) its principal place of business, or sent by fax to the other party`s main fax number. Any notice
                  or communication shall be deemed to have been received, if delivered by hand, on signature of a
                  delivery receipt or at the time the notice is left at the proper address, or if sent by fax, at 9.00
                  am on the next business day after transmission, or otherwise at 9.00 am on the second business day
                  after posting or at the time recorded by the delivery service.
                </li>
                <li className={LI_TERMS_AND_CONDITIONS}>
                  GOVERNING LAW AND JURSIDICTION: This Agreement and any dispute or claim arising out of or in
                  connection with it or its subject matter or formation (including non- contractual disputes or claims)
                  shall be governed by and construed in accordance with the law of England and Wales. The parties
                  irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any
                  dispute or claim that arises out of or in connection with this Agreement or its subject matter or
                  formation (including non- contractual disputes or claims).
                </li>
              </ol>
            </li>

            <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_BOLD_TERMS_AND_CONDITIONS}`}>
              DEFINITION ANDS INTERPRETATION
              <ol className={OL_TERMS_AND_CONDITIONS}>
                <li className={LI_TERMS_AND_CONDITIONS}>In this Agreement</li>
                <table className={TABLE_TERMS_AND_CONDITIONS}>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Advisor</td>
                    <td>
                      any business entity from time to time offering professional advice in a field of expertise who is
                      appointed by you
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Affiliate</td>
                    <td>
                      any business entity from time to time controlling, controlled by, or under common control with
                      either partyy
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Agreement</td>
                    <td>means this agreement between the parties;</td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Commission</td>
                    <td>has the meaning given to it in clause 4.2;</td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Confidential Information</td>
                    <td>
                      means information in whatever form (including without limitation, in written, oral, visual or
                      electronic form or on any magnetic or optical disk or memory and wherever located), whether or not
                      such material is marked as confidential: (i) relating to the business, customers, products,
                      affairs, finances, operations, processes, product information, know-how, designs, trade secrets or
                      software of each party (for the time being confidential to the party) or any of its suppliers,
                      customers, agents, distributors, shareholders, management or business contacts; and (ii) including
                      that Referrer or Individual creates, develops, receives or obtains in connection with the
                      Agreement.
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Data Protection Law</td>
                    <td>
                      means, for such time they are in force in England and Wales, the Data Protection Act 2018, the
                      Privacy and Electronic Communications Regulations 2003, the retained EU law version of the General
                      Data Protection Regulation 2016/679 and all related legislation which may supplement, amend,
                      implement or replace them and which relates to the protection of individual’s rights in their
                      personal data and the protection of their privacy;
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Force Majeure</td>
                    <td>
                      means, without limitation, any event of, flood, drought, earthquake or other natural disaster, any
                      epidemic or pandemic, any terrorist attack, civil war, civil commotion or riots, war, threat of or
                      preparation for war, armed conflict, imposition of sanctions, embargo, or breaking off of
                      diplomatic relations, nuclear, chemical or biological contamination or sonic boom, any law or any
                      action taken by a government or public authority, including without limitation imposing an export
                      or import restriction, quota or prohibition, any collapse of buildings, fire, explosion or
                      accident, any labour or trade dispute, strikes, industrial action or lockouts, or any interruption
                      or failure of any utility service;
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Intellectual Property Rights</td>
                    <td>
                      means all patents, utility models, rights to inventions, copyright and neighbouring and related
                      rights, trade marks and service marks, business names and domain names, rights in get-up and trade
                      dress, goodwill and the right to sue for passing off or unfair competition, rights in designs,
                      database rights, rights to use, and protect the confidentiality of, confidential information
                      (including know-how and trade secrets), and all other intellectual property rights, in each case
                      whether registered or unregistered and including all applications and rights to apply for and be
                      granted, renewals or extensions of, and rights to claim priority from, such rights and all similar
                      or equivalent rights or forms of protection which subsist or will subsist now or in the future in
                      any part of the world.
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Personal Data</td>
                    <td>
                      shall have the meaning set out in the relevant Data Protection Laws in force in England and Wales
                      from time to time;
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Potential Counterparty</td>
                    <td>
                      means an individual or organisation to whom you or your Affiliates have not at any time
                      previously;
                    </td>
                  </tr>
                </table>
                <ol className={`${OL_TERMS_AND_CONDITIONS} list-roman-terms-and-condifions ml-[250px]`}>
                  <li className={`${LI_TERMS_AND_CONDITIONS} li-list-roman-terms-and-condifions`}>
                    had a commercial relationship; or
                  </li>
                  <li className={`${LI_TERMS_AND_CONDITIONS} li-list-roman-terms-and-condifions`}>
                    have been involved in discussions, negotiations, or similar interactions with a view to the sale of
                    goods, services by you or your Affiliates; or
                  </li>
                  <li className={`${LI_TERMS_AND_CONDITIONS} li-list-roman-terms-and-condifions`}>
                    been in discussions regarding a partnership or collaboration of any kind in any way with you or your
                    Affiliates; or
                  </li>
                  <li className={`${LI_TERMS_AND_CONDITIONS} li-list-roman-terms-and-condifions`}>
                    been in discussions about any arrangement of commercial value with you or your Affiliates
                  </li>
                </ol>
                <table className={TABLE_TERMS_AND_CONDITIONS}>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Regulated Services</td>
                    <td>
                      Services that we provide that are regulated by the Financial Conduct Authority in accordance with
                      their rules and regulations. These include advising on financial investment proposals including
                      divestments and share exchanges.
                    </td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Services</td>
                    <td>means the Regulated Services and the Unregulated Services</td>
                  </tr>
                  <tr>
                    <td className={TABLE_TD_TERMS_AND_CONDITIONS}>Unregulated Services</td>
                    <td>
                      Services that we provide that are not regulated by the Financial Conduct Authority. These include
                      the making of commercial introductions and enabling commercial relationships between parties
                      through a digital networking platform.
                    </td>
                  </tr>
                </table>
              </ol>
              <div className='ml-4'>
                <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_TABLE_CELL_TERMS_AND_CONDITIONS}`}>
                  A reference to writing or written includes e-mail.
                </li>
                <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_TABLE_CELL_TERMS_AND_CONDITIONS}`}>
                  The headings in this Agreement are inserted for convenience only and shall not affect its
                  construction.
                </li>
                <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_TABLE_CELL_TERMS_AND_CONDITIONS}`}>
                  A reference to a particular law is a reference to it as it is in force for the time being taking
                  account of any amendment, extension, or re-enactment and includes any subordinate legislation for the
                  time being in force made under it.
                </li>
                <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_TABLE_CELL_TERMS_AND_CONDITIONS}`}>
                  Unless the context otherwise requires, words in the singular shall include the plural and, in the
                  plural, shall include the singular.
                </li>
                <li className={`${LI_TERMS_AND_CONDITIONS} ${LI_TABLE_CELL_TERMS_AND_CONDITIONS}`}>
                  Any words following the terms <b>including, include, in particular, for example</b> or any similar
                  expression shall be construed as illustrative and shall not limit the sense of the words, description,
                  definition, phrase or term preceding those terms.
                </li>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
