import { ReactComponent as PoundSvg } from 'assets/svg/pound.svg';
import { FormItem, TableColumns } from 'interfaces';
import { Input } from 'shared-components';

import { EMAIL_PATTERN, PHONE_PATTERN } from './global';
import { FieldTypes } from './shared';

export enum CreateUserFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL_ADDRESS = 'email',
  COMPANY_NUMBER = 'companyNumber',
  PHONE_NUMBER = 'phone',
  AMOUNT_INVESTED = 'amountInvested',
}

export const USERS_PAGE_TITLE = 'Users';

export const USERS_PAGE_DESCRIPTION = 'View all users on the PlanD platform.';

export const USERS_CREATE_MODAL_TITLE = 'Add a new user to planD';

export const USERS_CREATE_MODAL_DESCRIPTION = 'Please input the details below to create & invite a new user in planD';

export const USERS_CREATE_FIELDS: FormItem[] = [
  {
    name: CreateUserFieldNames.FIRST_NAME,
    label: 'First Name',
    placeholder: 'First Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: 'Required information missing please input First name to continue' },
    className: 'col-start-1 col-end-2 row-start-1 row-end-2',
  },
  {
    name: CreateUserFieldNames.LAST_NAME,
    label: 'Last Name',
    placeholder: 'Last Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: 'Required information missing please input Last name to continue' },
    className: 'col-start-2 col-end-3 row-start-1 row-end-2',
  },
  {
    name: CreateUserFieldNames.EMAIL_ADDRESS,
    label: 'Email Address',
    placeholder: 'Email',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: 'Required information missing please input Email address to continue',
      pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
    },
    className: 'col-start-1 col-end-3 row-start-2 row-end-3',
  },
  {
    name: CreateUserFieldNames.PHONE_NUMBER,
    label: 'Phone number',
    placeholder: 'Phone number',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: 'Required information missing please input Phone number to continue',
      pattern: {
        message: 'Incorrect phone number',
        value: PHONE_PATTERN,
      },
    },
  },
  {
    name: CreateUserFieldNames.AMOUNT_INVESTED,
    label: 'Amount Invested',
    placeholder: 'Amount invested',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: 'Required information missing please input Amount invested to continue',
      pattern: {
        message: 'Invalid amount',
        value: /^\d+(\.\d{1,2})?$/,
      },
    },
    icon: PoundSvg,
  },
];

export const USERS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions' },
  { id: 'name', title: 'User Name', sortable: true },
  { id: 'email', title: 'Email Address' },
  { id: 'userble_type', title: 'User Type', sortable: true },
  { id: 'phone_number', title: 'Phone No' },
  { id: 'created_at', title: 'Date Created', sortable: true },
  { id: 'registered', title: 'Registered' },
  { id: 'status', title: 'Status' },
];

export const GET_INVESTORS_FILTER = 'filter[userble_type]=investor';
