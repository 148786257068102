export const splitActiveShapeContentWithLines = (string: string) => {
  let start = 0;
  const results = [];

  while (start < string.length) {
    const maxChars = 16 + results.length * 2;
    let endLine: number = start + maxChars;

    // We check that the line is not cut in the middle of a word
    if (endLine < string.length && string[endLine] !== ' ' && string[endLine - 1] !== ' ') {
      while (endLine > start && string[endLine] !== ' ') {
        endLine--; // We need to find the last space
      }
    }

    results.push(string.slice(start, endLine).trim());
    start = endLine;

    if (results.length === 3 && start < string.length) {
      results[2] = `${results[2]}...`;
      break;
    }
  }

  return results;
};
