import { COMPANY_IAC_STATUSES, TableColumns } from 'interfaces';

import { DUE_DILIGENCE_STATUSES } from './due-diligence';

export const COMPANIES_IAC_SNIPPET_COLUMNS: TableColumns[] = [
  { id: 'actions', title: '', width: 'w-1/12' },
  { id: 'companyName', title: 'Company Name', width: 'w-5/12' },
  { id: 'companyNumber', title: 'Company Number', width: 'w-2/12' },
  { id: 'fundName', title: 'Fund Name', width: 'w-2/12' },
  { id: 'status', title: 'Status', width: 'w-2/12' },
];

export const COMPANY_IAC_STATUS_OPTIONS = [
  { value: COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS, label: 'Approved by fm users' },
  { value: COMPANY_IAC_STATUSES.ARCHIVED, label: 'Archived' },
  { value: COMPANY_IAC_STATUSES.CONFIRMED_BY_ADMIN, label: 'Confirmed by admin' },
  { value: COMPANY_IAC_STATUSES.DECLINED_BY_FMUSERS, label: 'Declined by fm users' },
  { value: COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS, label: 'Reviewing by fm users' },
  { value: COMPANY_IAC_STATUSES.STARTED, label: 'Started' },
  { value: COMPANY_IAC_STATUSES.NOT_STARTED, label: 'Not started' },
  { value: COMPANY_IAC_STATUSES.STOPPED, label: 'Stopped' },
];

export const COMPANY_DUE_DILIGENCE_STATUS_OPTIONS = [
  { value: DUE_DILIGENCE_STATUSES.ARCHIVED, label: 'Archived' },
  { value: DUE_DILIGENCE_STATUSES.DECLINED, label: 'Declined' },
  { value: DUE_DILIGENCE_STATUSES.FINISHED, label: 'Finished' },
  { value: DUE_DILIGENCE_STATUSES.IN_PROGRESS, label: 'In progress' },
  { value: DUE_DILIGENCE_STATUSES.NOT_STARTED, label: 'Not started' },
];
