import React, { FC, useMemo } from 'react';

import cn from 'classnames';

import { CompanyShareExchangeSharedProps } from 'interfaces';
import { SnippetLayout } from 'shared-components';
import { ChartInfoList, PieChart, PieChartActiveShapeV1 } from 'shared-components/chart';
import { generateArrayOfColors } from 'utils';

const AdminCompanyProfileShareExchangeBreakdownSection: FC<CompanyShareExchangeSharedProps> = ({
  amountShareExchangesCompleted,
  amountShareExchangesInProgress,
  amountShareExchangesStopped,
  noOfSharesExchanged,
  noOfShares,
  isLoading,
}) => {
  const randomColorsForNoExchange = generateArrayOfColors(3);

  const randomColorsForShareExchanged = generateArrayOfColors(2);

  const isShownShareExchangedInfo = noOfShares || noOfSharesExchanged || isLoading;

  const noExchangePieData = useMemo(
    () => [
      {
        name: 'No. Exchange In Progress',
        value: amountShareExchangesInProgress,
      },
      { name: 'No. Exchange In Stopped', value: amountShareExchangesStopped },
      { name: 'No. Exchange In Complete', value: amountShareExchangesCompleted },
    ],
    [amountShareExchangesCompleted, amountShareExchangesInProgress, amountShareExchangesStopped],
  );

  const shareExchangedPieData = useMemo(
    () => [
      {
        name: 'Total Share Exchanged',
        value: noOfSharesExchanged,
      },
      {
        name: 'Allowed for share exchange',
        value: noOfShares && noOfSharesExchanged ? noOfShares - noOfSharesExchanged : noOfShares || 0,
      },
    ],
    [noOfShares, noOfSharesExchanged],
  );

  return (
    <SnippetLayout className='mt-6'>
      <h4 className='text-lg text-blue-800'>Share Exchange Breakdown</h4>
      <div className='flex flex-col 1xl:flex-row mt-10'>
        <div
          className={cn('w-full flex flex-col items-center justify-center w-full mr-6', {
            '1xl:w-1/2': isShownShareExchangedInfo,
          })}
        >
          <PieChart
            data={noExchangePieData}
            colors={randomColorsForNoExchange}
            activeShape={<PieChartActiveShapeV1 />}
            isLoading={isLoading}
          />
          <ChartInfoList
            className='ml-6 mt-6 flex-col grid-cols-[1fr_1fr_1fr]'
            colors={randomColorsForNoExchange}
            infoList={noExchangePieData}
          />
        </div>
        {isShownShareExchangedInfo ? (
          <div className='w-full 1xl:w-1/2 flex flex-col items-center justify-center mt-6 1xl:mt-0'>
            <PieChart
              data={shareExchangedPieData}
              colors={randomColorsForShareExchanged}
              activeShape={<PieChartActiveShapeV1 />}
              isLoading={isLoading}
            />
            <ChartInfoList
              className='ml-6 mt-6 flex-col grid-cols-2'
              colors={randomColorsForShareExchanged}
              infoList={shareExchangedPieData}
            />
          </div>
        ) : null}
      </div>
    </SnippetLayout>
  );
};

export default AdminCompanyProfileShareExchangeBreakdownSection;
