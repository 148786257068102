import React, { useEffect } from 'react';

import { getCompanyArchivedIac } from 'modules/companies/action';
import { selectArchivedCompanyIac } from 'modules/companies/selectors';
import { selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ListSkeleton } from 'shared-components';
import Typography from 'shared-components/Typography';

import ArchivedCompanyIacListItem from './ArchivedCompanyIacListItem';

interface Props {
  id?: string;
}

const ArchivedCompanyIacList = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(selectUserType);

  const { isLoading, list } = useAppSelector(selectArchivedCompanyIac);

  useEffect(() => {
    if (!id || !userType) return;

    dispatch(getCompanyArchivedIac({ id, userType }));
  }, [dispatch, id, userType]);

  if (isLoading) return <ListSkeleton />;

  if (!isLoading && !list?.length) return <Typography>No Archived Companies Iac Found</Typography>;

  return (
    <div className='space-y-6'>
      {list?.length
        ? list.map((companyIac) => (
            <ArchivedCompanyIacListItem key={companyIac?.id} data={companyIac} companyId={id || ''} />
          ))
        : null}
    </div>
  );
};

export default ArchivedCompanyIacList;
