import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { FoundersResponse, FoundersState, CommonMetrics, Founder } from 'interfaces';

import { getFounders, getFounderCompanies, getFoundersMetrics, updateFoundersCompanies, getFounder } from './action';

export function getFoundersReducer(builder: ActionReducerMapBuilder<FoundersState>) {
  builder.addCase(getFounders.pending, (state) => {
    state.foundersList.isLoading = true;
  });

  builder.addCase(getFounders.fulfilled, (state, action: PayloadAction<FoundersResponse>) => {
    state.foundersList.isLoading = false;
    state.foundersList.list = action.payload.data;
    state.foundersList.totalPages = action.payload.meta.last_page;
    state.foundersList.total = action.payload.meta.total;
  });

  builder.addCase(getFounders.rejected, (state) => {
    state.foundersList.isLoading = false;
  });
}

export function getFounderReducer(builder: ActionReducerMapBuilder<FoundersState>) {
  builder.addCase(getFounder.pending, (state) => {
    state.founder.isLoading = true;
  });

  builder.addCase(getFounder.fulfilled, (state, action: PayloadAction<Founder>) => {
    state.founder.isLoading = false;
    state.founder.data = action.payload;
  });

  builder.addCase(getFounder.rejected, (state) => {
    state.founder.isLoading = false;
  });
}

export function getFoundersMetricsReducer(builder: ActionReducerMapBuilder<FoundersState>) {
  builder.addCase(getFoundersMetrics.pending, (state) => {
    state.foundersMetrics.isLoading = true;
  });

  builder.addCase(getFoundersMetrics.fulfilled, (state, action: PayloadAction<CommonMetrics>) => {
    state.foundersMetrics = { ...action.payload, isLoading: false };
  });

  builder.addCase(getFoundersMetrics.rejected, (state) => {
    state.foundersMetrics.isLoading = false;
  });
}

export function getFoundersCompanyReducer(builder: ActionReducerMapBuilder<FoundersState>) {
  builder.addCase(getFounderCompanies.pending, (state) => {
    state.foundersCompanies.isLoading = true;
  });

  builder.addCase(getFounderCompanies.fulfilled, (state, action) => {
    state.foundersCompanies.list = action.payload.data;
    state.foundersCompanies.isLoading = false;
  });

  builder.addCase(getFounderCompanies.rejected, (state) => {
    state.foundersCompanies.isLoading = false;
  });
}

export function updateFoundersCompaniesReducer(builder: ActionReducerMapBuilder<FoundersState>) {
  builder.addCase(updateFoundersCompanies.pending, (state) => {
    state.foundersCompanies.isLoading = true;
  });

  builder.addCase(updateFoundersCompanies.fulfilled, (state) => {
    state.foundersCompanies.isLoading = false;
  });

  builder.addCase(updateFoundersCompanies.rejected, (state) => {
    state.foundersCompanies.isLoading = false;
  });
}
