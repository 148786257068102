import React from 'react';

import { DEALS_PAGE_TITLE, DEALS_TABS_IDS } from 'constants/deals';
import useInitialTab from 'hooks/use-initial-tab/useInitialTab';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import DealsTableTabContainer from 'page-components/deals/deals-table/DealsTableTabContainer';
import { TitleWithDescription, Tabs } from 'shared-components';
import { getTitleWithoutMyWord } from 'utils';

export const DEALS_PAGE_TABS = [
  { id: DEALS_TABS_IDS.SHARE_EXCHANGES, name: 'Share Exchanges', component: DealsTableTabContainer },
];

const Deals = () => {
  const { isTypeEntity } = useAppSelector(selectUserEntityData);

  const initialTabId = useInitialTab();

  return (
    <DashboardLayout>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-6' title={getTitleWithoutMyWord(DEALS_PAGE_TITLE, isTypeEntity)} />

        <Tabs tabs={DEALS_PAGE_TABS} initialTabId={initialTabId} />
      </div>
    </DashboardLayout>
  );
};

export default Deals;
