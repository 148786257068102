import React, { FC } from 'react';

export const NO_INFORMATION_TEXT_TEST_ID = 'NO_INFORMATION_TEXT_TEST_ID';

interface Props {
  text: string;
}

const NoInformationText: FC<Props> = ({ text }) => {
  return <p data-testid={NO_INFORMATION_TEXT_TEST_ID}>No information is available as no {text}</p>;
};

export default NoInformationText;
