import { useEffect, useRef, useCallback } from 'react';

const INACTIVITY_TIME = 1000 * 60 * 3;

const useUserActivity = (onInactive: () => void, isLocked: boolean) => {
  const inactivityTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
  };

  const setInactivityTimer = useCallback(() => {
    inactivityTimer.current = setTimeout(() => {
      if (!isLocked) {
        onInactive();
      }
    }, INACTIVITY_TIME);
  }, [isLocked, onInactive]);

  const handleActivity = useCallback(() => {
    clearInactivityTimer();
    setInactivityTimer();
  }, [setInactivityTimer]);

  useEffect(() => {
    if (isLocked) {
      return;
    }

    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('scroll', handleActivity);
    document.addEventListener('touchstart', handleActivity);
    setInactivityTimer();

    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      document.removeEventListener('touchstart', handleActivity);
      clearInactivityTimer();
    };
  }, [handleActivity, isLocked, setInactivityTimer]);
};

export default useUserActivity;
