import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

const NoAuditLogsAdded = () => {
  return (
    <TableRow>
      <TableData>No Audit Logs Added</TableData>
    </TableRow>
  );
};

export default NoAuditLogsAdded;
