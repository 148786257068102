import React from 'react';

import Typography from 'shared-components/Typography';

import TableData from './TableData';
import TableRow from './TableRow';

const DEFAULT_NO_DATA_TEXT = 'No Data Found';

interface Props {
  text?: string;
  skipColumns?: number;
}

const TableNoData = ({ text = DEFAULT_NO_DATA_TEXT, skipColumns }: Props) => {
  return (
    <TableRow>
      {[...Array(skipColumns)].map((_, index) => (
        <TableData key={index} />
      ))}
      <TableData>
        <Typography>{text}</Typography>
      </TableData>
    </TableRow>
  );
};

export default TableNoData;
