import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { StepperStatuses, stepperStatusesLabels } from 'constants/shared/stepper';
import { ColoredLabel } from 'shared-components';
import Stepper from 'shared-components/stepper-v1/Stepper';
import StepperItem from 'shared-components/stepper-v1/StepperItem';
import StepperItemCircle from 'shared-components/stepper-v1/StepperItemCircle';
import StepperItemDetails from 'shared-components/stepper-v1/StepperItemDetails';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';

const InvestmentsStepsNoInfo = () => {
  const navigate = useNavigate();

  const steps: { step: number; title: string; description?: string; link?: string; isActive?: boolean }[] = useMemo(
    () => [
      {
        step: 1,
        title: 'Add Investments',
        description: 'Getting Started',
        link: ROUTES.investments,
        isActive: false,
      },
      {
        step: 2,
        title: 'Request & Exchange',
        description: 'Start Exchange',
        isActive: false,
      },
      {
        step: 3,
        title: 'Continue Exchange',
        description: 'Progress',
        isActive: false,
      },
    ],
    [],
  );

  return (
    <div>
      <Stepper
        steps={steps}
        isLoading={false}
        renderContent={({ isActive, link, ...stepDetails }) => (
          <StepperItem
            key={stepDetails.step}
            onClick={link ? () => navigate(link) : undefined}
            isActive={isActive}
            renderStepContent={({ isActive }) => (
              <StepperItemCircle status={isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING} />
            )}
          >
            <StepperItemDetails {...stepDetails}>
              <ColoredLabel
                className='mt-4'
                {...stepperStatusesLabels[isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING]}
              />
            </StepperItemDetails>
          </StepperItem>
        )}
        renderSkeleton={() => <StepperSkeleton count={3} withDetails />}
      />
    </div>
  );
};

export default InvestmentsStepsNoInfo;
