import { format, parseISO } from 'date-fns';

import { MONTH_FORMAT_WITH_SLASHES } from 'constants/dateFormats';

export const formatToMonthFormatWithSlashes = (date: string) => {
  if (!date) return '';
  const parsedDate = parseISO(`${date}-01`);

  return date ? format(parsedDate, MONTH_FORMAT_WITH_SLASHES) : '';
};
