import React, { forwardRef, InputHTMLAttributes, ReactElement, FC } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { RadioOption, StringOrNull } from 'interfaces';

import ErrorMessage from '../error-message/ErrorMessage';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  radioOptions: RadioOption[];
  radioLegend: string | FC;
  fieldSetClassName?: string;
  legendClassName?: string;
  anchorPrefix?: string;
  control?: unknown;
  error?: string;
  currentValue?: StringOrNull;
}

const RadioInputSet = forwardRef<HTMLInputElement, Props>(
  (
    {
      control,
      anchorPrefix,
      radioLegend,
      fieldSetClassName,
      legendClassName,
      radioOptions,
      error,
      className,
      disabled,
      currentValue,
      ...inputProps
    },
    ref,
  ) => {
    const renderLegend = (): ReactElement | null => {
      if (!radioLegend) {
        return null;
      }

      if (typeof radioLegend === 'function') {
        const Component = radioLegend;
        return <Component />;
      }

      return <legend className={twMerge('mb-5', legendClassName)}>{radioLegend}</legend>;
    };

    return (
      <fieldset className={twMerge('mb-6', fieldSetClassName)}>
        {renderLegend()}

        {radioOptions.map(({ value, label, id }) => (
          <div className='inline-flex items-center mr-12' key={id}>
            <input
              id={`${inputProps.name}-${id}`}
              ref={ref}
              className={cn(
                'radio-input-unset-dimensions w-4 h-4',
                {
                  'cursor-not-allowed': disabled,
                  'cursor-pointer': !disabled,
                  'radio-input-highlighted': !!error && (currentValue ? currentValue !== value : true),
                },
                className,
              )}
              type='radio'
              value={value}
              disabled={disabled}
              {...inputProps}
            />

            {label && (
              <label
                htmlFor={`${inputProps.name}-${id}`}
                className={cn(
                  {
                    'text-grey-400': disabled,
                  },
                  twMerge('pl-2'),
                )}
              >
                {label}
              </label>
            )}
          </div>
        ))}

        <ErrorMessage error={error} />
      </fieldset>
    );
  },
);

export default RadioInputSet;

RadioInputSet.displayName = 'RadioInputSet';
