import React from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from '../../constants/dateFormats';
import { InvestorProfileInfo } from '../../constants/investors';
import { TypographyVariants } from '../../constants/shared/typography';
import { Investor } from '../../interfaces';
import Typography from '../../shared-components/Typography';
import { UserInfo } from '../index';

interface Props {
  investor?: Investor | null;
  daxiaFundShares?: number | null;
}

const InvestorInfo = ({ investor, daxiaFundShares }: Props) => {
  return (
    <div>
      <Typography tag='h2' variant={TypographyVariants.LARGE}>
        Investor Profile: {`${investor?.firstName} ${investor?.lastName}`}
      </Typography>

      <div className='mt-6'>
        <UserInfo title={InvestorProfileInfo.EMAIL_ADDRESS} value={investor?.email} />
        <UserInfo title={InvestorProfileInfo.PHONE_NUMBER} value={investor?.phone} />
        {investor?.created_at && (
          <UserInfo
            title={InvestorProfileInfo.CREATED_AT}
            value={format(parseISO(investor?.created_at), DAY_FORMAT_WITH_SLASHES)}
          />
        )}
        {investor?.IAQApprovedAt && (
          <UserInfo
            title={InvestorProfileInfo.IAQ_APPROVED}
            value={format(parseISO(investor?.IAQApprovedAt), DAY_FORMAT_WITH_SLASHES)}
          />
        )}
        {daxiaFundShares && <UserInfo title={InvestorProfileInfo.DAXIA_INVESTMENTS} value={`${daxiaFundShares}`} />}
      </div>
    </div>
  );
};

export default InvestorInfo;
