import React, { FC, useMemo } from 'react';

import cn from 'classnames';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CheckMarkIcon } from 'assets/svg/check-mark.svg';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { DUE_DILIGENCE_DOCUMENTS_TYPES } from 'constants/due-diligence';
import { TableRowVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { DueDiligenceSelectedDocumentTypeFunction, FromItemHigligtedColor } from 'interfaces';
import { selectDueDiligenceDocumentTypes } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { Button } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  documentType: string;
  documentTypeKey: DUE_DILIGENCE_DOCUMENTS_TYPES;
  handleFilter: (filterValue: string) => void;
  disabledAction: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
  onOpenUploadDocumentModal: DueDiligenceSelectedDocumentTypeFunction;
  isAttached?: boolean;
  officialDate?: string;
  className?: string;
};

const CorporateDocumentsAndFinancialsTableRow: FC<Props> = ({
  disabledAction,
  documentType,
  documentTypeKey,
  handleFilter,
  getUnfieldValue,
  onOpenUploadDocumentModal,
  isAttached,
  officialDate,
  className,
}) => {
  const dueDiligenceDocumentTypes = useAppSelector(selectDueDiligenceDocumentTypes);

  const {
    isDocumentAttached = false,
    documentDate = '',
    documents = [],
  } = isAttached && officialDate
    ? {}
    : dueDiligenceDocumentTypes.find(
        ({ documentType: currentDocumentType }) => documentTypeKey === currentDocumentType,
      ) || {};

  const determinedDocumentDate = officialDate ? officialDate : documentDate;

  const hasMissedInformation = useMemo(() => !isDocumentAttached || !documentDate, [isDocumentAttached, documentDate]);

  const highlightError = useMemo(() => {
    if (hasMissedInformation && getUnfieldValue) {
      const unfieldValue = getUnfieldValue(`documentTypes.${documentTypeKey}`);
      if (unfieldValue) {
        return unfieldValue.asError ? FromItemHigligtedColor.Red : FromItemHigligtedColor.Blue;
      }
    }
    return null;
  }, [hasMissedInformation, getUnfieldValue]);

  const onClickDocumentType = () => handleFilter(documentTypeKey);

  return (
    <>
      <TableRow
        className={twMerge(
          highlightError
            ? cn('!bg-opacity-20', {
                '!bg-lightBlue-600': highlightError === FromItemHigligtedColor.Blue,
                '!bg-red-500': highlightError === FromItemHigligtedColor.Red,
              })
            : '',
          className,
        )}
        variant={TableRowVariants.SIMPLIFIED}
      >
        <TableData className='pl-6'>
          <Button
            variant={ButtonVariants.LINK}
            className='py-0 text-primary justify-start'
            onClick={onClickDocumentType}
          >
            {documentType}
          </Button>
        </TableData>

        <TableData>
          {determinedDocumentDate ? format(new Date(determinedDocumentDate), DAY_FORMAT_WITH_SLASHES) : '__/__/____'}
        </TableData>

        <TableData>
          <div className='w-full h-full flex items-center justify-center'>
            {isDocumentAttached || isAttached ? (
              <CheckMarkIcon className='fill-lightGreen-700 h-6 w-6' />
            ) : (
              <Button
                variant={ButtonVariants.LINK}
                className='underline'
                disabled={disabledAction}
                onClick={() => onOpenUploadDocumentModal({ selectedDocumentType: documentTypeKey })}
              >
                Submit Document
              </Button>
            )}
          </div>
        </TableData>
      </TableRow>
      {documents?.length
        ? documents?.map(({ id, documentType, officialDate }) => (
            <CorporateDocumentsAndFinancialsTableRow
              className='first:[&>td]:pl-8'
              key={id}
              documentType={documentType}
              documentTypeKey={documentTypeKey}
              handleFilter={handleFilter}
              disabledAction={disabledAction}
              onOpenUploadDocumentModal={onOpenUploadDocumentModal}
              officialDate={officialDate}
              isAttached
            />
          ))
        : null}
    </>
  );
};

export default CorporateDocumentsAndFinancialsTableRow;
