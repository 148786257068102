import React, { FC, ReactNode, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import { ButtonAction, FielsIacActionsParamsType } from 'interfaces';
import { Button, ButtonsActions } from 'shared-components';

import DeleteIacFieldModal from '../company-iac-delete-field/DeleteIacFieldModal';

interface Props {
  actions: ButtonAction[];
  children: ReactNode;
  addNewShare: VoidFunction;
  isAvailableToAddNew?: boolean;
  index: number;
  callback?: VoidFunction;
  label?: string;
  disabled?: boolean;
}

type DeleteIacField = {
  label: string | undefined;
};

const CompanyIacTableFieldsRow: FC<Props> = ({
  children,
  addNewShare,
  isAvailableToAddNew,
  index,
  callback,
  label,
  disabled,
}) => {
  const [deleteIacField, setDeleteIacField] = useState<DeleteIacField>();
  const { onOpen, ...modalState } = useModal();

  const deleteField = ({ label }: FielsIacActionsParamsType) => {
    onOpen();
    setDeleteIacField({ label: undefined });
  };

  const deleteActions: ButtonAction[] = [
    {
      id: 'delete-persons-block-item',
      icon: DeleteSvg,
      action: () => deleteField({ label: label || '' }),
      disabled,
    },
  ];

  return (
    <div className={cn('flex p-2', { 'bg-grey-100': index % 2 == 0 })}>
      <div className='flex w-5/6'>
        {children}

        {!disabled && (
          <ButtonsActions
            className='items-center mr-6 ml-4'
            anchorPrefix='add-significant-control-'
            actions={deleteActions}
          />
        )}
      </div>
      {isAvailableToAddNew && !disabled && (
        <Button className='w-1/6 justify-end text-sm' variant={ButtonVariants.LINK} onClick={addNewShare}>
          +Add New Share
        </Button>
      )}
      <DeleteIacFieldModal onSubmit={callback} {...modalState} {...deleteIacField} />
    </div>
  );
};

export default CompanyIacTableFieldsRow;
