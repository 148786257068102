import React, { FC, ComponentType } from 'react';

import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

import { GraphItem } from 'interfaces';

import TooltipV1 from './TooltipV1';

type Props = {
  height?: string | number;
  data: GraphItem[];
  showXAxis?: boolean;
  tooltip?: ComponentType;
  tooltipOffset?: number;
};

const AreaChartComponent: FC<Props> = ({ data, height, showXAxis = true, tooltip: TooltipContent, tooltipOffset }) => {
  return (
    <ResponsiveContainer className='pt-3' minWidth='150px' height={height}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id='gradientId' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#558217' stopOpacity={0.5} />
            <stop offset='95%' stopColor='#558217' stopOpacity={0} />
          </linearGradient>
        </defs>

        {showXAxis && (
          <XAxis
            tick={{ fontSize: '11px', stroke: '#B4B3B1', strokeWidth: '0.5' }}
            dataKey='name'
            interval='preserveStartEnd'
          />
        )}

        <Tooltip content={TooltipContent ? <TooltipContent /> : <TooltipV1 />} offset={tooltipOffset || -35} />

        <Area type='monotone' dataKey='uv' stroke='#558217' strokeWidth={2} fill={`url(#gradientId)`} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartComponent;
