import React, { FC } from 'react';

const BusinessReviewRadioConflictsOfInterestsLegend: FC = () => {
  return (
    <legend className='mb-5'>
      <div>Are there any conflicts of interest that may impact the IAC’s decision to invest in the Company?*:</div>
      <div>
        If yes, please explain, identify, and recuse any members that may be conflicted. | Examples of conflicts include
        committee members having a holding in the Company
      </div>
    </legend>
  );
};

export default BusinessReviewRadioConflictsOfInterestsLegend;
