import { FormItem } from 'interfaces';
import { Input } from 'shared-components';

import { REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export enum ConnectLinkedInFieldsNames {
  USER_LINK = 'user-link',
  COMPANY_LINK = 'company-link',
}

export const CONNECT_LINKEDIN_TITLE = 'Save time & Connect to LinkedIn';

export const CONNECT_LINKEDIN_DESCRIPTION = 'By connecting to linkedin you will save time in the long run ';

export const CONNECT_LINKEDIN_USER_PROFILE_LABEL = 'Connect User Profile';

export const CONNECT_LINKEDIN_COMPANY_PROFILE_LABEL = 'Connect Company Profile';

export const CONNECT_LINKEDIN_INPUT_PLACEHOLDER = 'Connect to LinkedIn';

export const CONNECT_LINKEDIN_FIELDS: FormItem[] = [
  {
    name: ConnectLinkedInFieldsNames.USER_LINK,
    label: CONNECT_LINKEDIN_USER_PROFILE_LABEL,
    placeholder: CONNECT_LINKEDIN_INPUT_PLACEHOLDER,
    type: FieldTypes.TEXT,
    required: true,
    validation: {
      required: 'Required field',
      pattern: {
        message: REQUIRED_MESSAGE,
        value: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
      },
    },
    component: Input,
    className: 'label-grey input-center',
  },
  {
    name: ConnectLinkedInFieldsNames.COMPANY_LINK,
    label: CONNECT_LINKEDIN_COMPANY_PROFILE_LABEL,
    placeholder: CONNECT_LINKEDIN_INPUT_PLACEHOLDER,
    type: FieldTypes.TEXT,
    required: true,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        message: 'Link must be a valid URL',
        value: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/company\/[A-z0-9_-]+\/?/,
      },
    },
    component: Input,
    className: 'label-grey input-center',
  },
];
