import React from 'react';

const AdminCompanyProfileHeaderPreloader = () => {
  return (
    <div className='flex'>
      <div className='skeleton-loader h-20 w-1/3 ml-10 mr-16' />
      {Array.from(Array(4).keys()).map((key) => (
        <div className='mr-6 w-1/5' key={key}>
          <div className='skeleton-loader h-5 w-full' />
          <div className='skeleton-loader h-10 w-full mt-5' />
        </div>
      ))}
    </div>
  );
};

export default AdminCompanyProfileHeaderPreloader;
