import React, { FC, useMemo, ReactNode } from 'react';

import { useLocation } from 'react-router-dom';

import { DUE_DILIGENCE_PAGE_TITLE } from 'constants/due-diligence';
import { shareExchangeStatusesColoredLabel } from 'constants/share-exchange';
import { ButtonVariants } from 'constants/shared/button';
import { Company } from 'interfaces';
import {
  selectBusinessName,
  selectDueDiligenceId,
  selectDueDiligenceStatus,
  selectDueDiligenceDeclineReason,
  selectIsDueDiligenceDeclined,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { Button, DetailsPageTitle, ColoredLabel, LabelWithValue, LinkButton } from 'shared-components';
import { getArchivedDueDiligenceLink } from 'utils';

import DueDiligenceHeaderLinks from './DueDiligenceHeaderLinks';

interface Props {
  handleOpenDocumentsModal: VoidFunction;
  isLocked: boolean;
  checkLockStatus: string;
  headerNode?: ReactNode;
  lastUpdated: string | null;
  companyId?: string;
  isFmUser?: boolean;
}

const DueDiligenceHeader: FC<Props> = ({
  handleOpenDocumentsModal,
  isLocked,
  checkLockStatus,
  headerNode,
  lastUpdated,
  companyId,
  isFmUser,
}) => {
  const location = useLocation();

  const businessName = useAppSelector(selectBusinessName);
  const dueDiligenceStatus = useAppSelector(selectDueDiligenceStatus);
  const dueDiligenceDeclineReason = useAppSelector(selectDueDiligenceDeclineReason);
  const dueDiligenceId = useAppSelector(selectDueDiligenceId);
  const isDueDiligenceDeclined = useAppSelector(selectIsDueDiligenceDeclined);

  const { companyName = '' } = (location?.state as Pick<Company, 'companyName'>) || {};

  const declineReasonLabelText = useMemo(() => {
    return (isDueDiligenceDeclined ? '' : 'Previous ') + 'Decline Reason';
  }, [isDueDiligenceDeclined, dueDiligenceDeclineReason]);

  return (
    <div className='pb-6 mb-6 border-b border-grey-300'>
      <div className='flex items-center justify-between flex-col md:flex-row gap-8 md:gap-0'>
        <DetailsPageTitle
          subtitle={DUE_DILIGENCE_PAGE_TITLE}
          title={businessName || companyName}
          isLocked={isLocked}
          checkLockStatus={checkLockStatus}
          subTitleNode={
            <div className='block 1xl:hidden'>
              <LabelWithValue className='w-fit mr-6' title='Last updated' content={lastUpdated} />
            </div>
          }
        >
          <>
            {dueDiligenceId && <b className='-ml-2 mr-4'>№{dueDiligenceId}</b>}
            {dueDiligenceStatus && <ColoredLabel {...shareExchangeStatusesColoredLabel[dueDiligenceStatus]} />}
            {dueDiligenceDeclineReason && (
              <div className='ml-2 text-sm leading-6'>
                <span className='text-red-500 font-bold'>{declineReasonLabelText}:</span>
                <span>{` ${dueDiligenceDeclineReason}`}</span>
              </div>
            )}
          </>
        </DetailsPageTitle>

        <div className='flex flex-col md:flex-row justify-end gap-2 gap-y-6 md:pl-6 w-full md:w-auto flex-1'>
          <div className='hidden 1xl:block w-full md:w-52'>
            <LabelWithValue className='w-fit pr-2' title='Last updated' content={lastUpdated} />
          </div>
          {headerNode}
          <div className='flex flex-col gap-2'>
            <Button
              onClick={handleOpenDocumentsModal}
              className='md:ml-6 self-end order-1 1xl:order-2 flex-1 whitespace-nowrap'
              variant={ButtonVariants.SECONDARY}
            >
              View Documents
            </Button>
            {isFmUser && (
              <LinkButton
                variant={ButtonVariants.SECONDARY}
                className='w-full md:w-auto'
                to={getArchivedDueDiligenceLink(companyId ? +companyId : 0, isFmUser)}
              >
                Archived Due Diligence
              </LinkButton>
            )}
          </div>
        </div>
      </div>
      <DueDiligenceHeaderLinks status={dueDiligenceStatus} isLoading={!dueDiligenceStatus} />
    </div>
  );
};

export default DueDiligenceHeader;
