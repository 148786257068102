import React, { FC, memo } from 'react';

import { format, parseISO } from 'date-fns';

import { ReactComponent as EyeSvg } from 'assets/svg/eye.svg';
import { AUDIT_LOGS_OPERATION_LABELS } from 'constants/audit-log';
import { DAY_FORMAT_WITH_HOURS } from 'constants/dateFormats';
import { ButtonVariants } from 'constants/shared/button';
import { AuditLog } from 'interfaces/audit-logs.interfaces';
import { Button } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  auditLog: AuditLog;
  handleOpenModal: (id: number) => void;
};

const AuditLogsTableItem: FC<Props> = memo(function AuditLogsTableItem({ auditLog, handleOpenModal }) {
  const { id, operation, entity, author, authorType, companyName, createdAt, entityId } = auditLog;

  return (
    <>
      <TableRow key={id}>
        <TableData>{id}</TableData>
        <TableData>{entityId}</TableData>
        <TableData>{AUDIT_LOGS_OPERATION_LABELS[operation]}</TableData>
        <TableData>{companyName}</TableData>
        <TableData>{entity}</TableData>
        <TableData>{author}</TableData>
        <TableData>{authorType}</TableData>
        <TableData>{format(parseISO(createdAt), DAY_FORMAT_WITH_HOURS)}</TableData>
        <TableData>
          <Button
            variant={ButtonVariants.TRANSPARENT}
            className='w-[50px] h-full float-right'
            onClick={() => handleOpenModal(id)}
          >
            <EyeSvg className='fill-blue-800' />
          </Button>
        </TableData>
      </TableRow>
    </>
  );
});

AuditLogsTableItem.displayName = 'AuditLogsTableItem';

export default AuditLogsTableItem;
