import React, { FC, SVGProps, Ref } from 'react';

import cn from 'classnames';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components/index';

export const BUTTON_WITH_ICON_TEST_ID = 'button-with-icon';

export interface Props {
  className?: string;
  iconClassName?: string;
  buttonContentClassName?: string;
  onClick: VoidFunction;
  icon: FC<SVGProps<SVGSVGElement>>;
  text: string;
  buttonRef?: Ref<HTMLButtonElement>;
  buttonVariant?: ButtonVariants;
  isLoading?: boolean;
  disabled?: boolean;
}

const ButtonWithIcon: FC<Props> = ({
  buttonRef,
  onClick,
  text,
  className,
  iconClassName,
  buttonContentClassName,
  isLoading,
  buttonVariant,
  icon: Icon,
  disabled,
}) => {
  return (
    <Button
      buttonRef={buttonRef}
      className={className}
      variant={buttonVariant || ButtonVariants.SECONDARY}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      data-testid={BUTTON_WITH_ICON_TEST_ID}
    >
      <div className={cn('flex items-center', buttonContentClassName)}>
        <div>{text}</div>
        <Icon className={cn('ml-2', iconClassName)} />
      </div>
    </Button>
  );
};

export default ButtonWithIcon;
