import { createSlice } from '@reduxjs/toolkit';

import { COMPANIES_STATE_DEFAULT_COMPANY_DATA, CURRENT_COMPANY_DEFAULT_STATE } from 'constants/companies';
import { META_INITIAL_STATE } from 'constants/global';
import { CompaniesState } from 'interfaces';

import { COMPANIES_SLICE_NAME } from './action';
import {
  addCompanyReducer,
  getCompanyByIdReducer,
  getCompanyProfileByNumberReducer,
  getMyCompaniesOptionsReducer,
  getMyCompaniesReducer,
  resetCompanyDataReducer,
  getAdminCompaniesReducer,
  getAdminCompaniesMetricsReducer,
  updateIsWhitelistedReducer,
  getInvitedCompanyInvestorsReducer,
  uploadCompanyInvestorsReducer,
  createCompanyInvestorForInviteReducer,
  deleteInvitedCompanyInvestorReducer,
  getCompanyArchivedDueDiligenceReducer,
  getCompanyArchivedIacReducer,
  deleteCompanyReducer,
  getAdminCompanyExchangeDataReducer,
} from './reducers';

export const initialState: CompaniesState = {
  currentCompany: CURRENT_COMPANY_DEFAULT_STATE,
  companies: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
  companyData: COMPANIES_STATE_DEFAULT_COMPANY_DATA,
  myCompaniesOptions: [],
  companiesMetrics: {
    amountAll: null,
    amountInvolvedInShareExchange: null,
    amountNotInvolvedInShareExchange: null,
    isLoading: false,
  },
  invitedCompanyInvestors: [],
  archived: {
    isLoading: false,
    archivedDueDiligence: null,
    archivedCompanyIac: null,
  },
  companyDealData: {
    data: null,
    isLoading: false,
  },
};

const companiesSlice = createSlice({
  name: COMPANIES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCompanyByIdReducer(builder);
    getMyCompaniesReducer(builder);
    getMyCompaniesOptionsReducer(builder);
    getAdminCompanyExchangeDataReducer(builder);
    getCompanyProfileByNumberReducer(builder);
    resetCompanyDataReducer(builder);
    addCompanyReducer(builder);
    deleteCompanyReducer(builder);
    getAdminCompaniesReducer(builder);
    getAdminCompaniesMetricsReducer(builder);
    updateIsWhitelistedReducer(builder);
    getInvitedCompanyInvestorsReducer(builder);
    uploadCompanyInvestorsReducer(builder);
    createCompanyInvestorForInviteReducer(builder);
    deleteInvitedCompanyInvestorReducer(builder);
    getCompanyArchivedDueDiligenceReducer(builder);
    getCompanyArchivedIacReducer(builder);
  },
});

export const companiesReducer = companiesSlice.reducer;
