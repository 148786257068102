import React, { FC, ReactNode, useEffect } from 'react';

import cn from 'classnames';

import { ReactComponent as TrashCanSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

type Props = {
  children: ReactNode;
  handleRemoveRow: VoidFunction;
  isLast?: boolean;
  isOnlyOne?: boolean;
  watchClassOfSharesValue: string | undefined;
  handleSetCompanyPrice: VoidFunction;
};

const StartInvestmentShareExchangeModalTableItem: FC<Props> = ({
  children,
  handleRemoveRow,
  watchClassOfSharesValue,
  handleSetCompanyPrice,
  isLast,
  isOnlyOne,
}) => {
  // eslint-disable-next-line
  useEffect(handleSetCompanyPrice, [watchClassOfSharesValue]);

  return (
    <div className='flex gap-2 border-b py-4'>
      <div className={cn('grid grid-cols-12 items-end gap-y-6 gap-x-2 1xl:gap-x-4 w-full', { 'border-0': isLast })}>
        {children}
      </div>
      {!isOnlyOne && (
        <Button className='w-auto pr-0 self-center' onClick={handleRemoveRow} variant={ButtonVariants.TRANSPARENT}>
          <TrashCanSvg />
        </Button>
      )}
    </div>
  );
};

export default StartInvestmentShareExchangeModalTableItem;
