import React, { FC, useMemo } from 'react';

import { ReactComponent as CheckMark } from 'assets/svg/check-mark-v3.svg';
import { ReactComponent as ResubmitSvg } from 'assets/svg/resubmit.svg';
import {
  COMPANY_IAC_RESEND_REQUEST_FOR_REVIEW_TEXT,
  COMPANY_IAC_RESEND_TO_REVIEW_TITLE,
  COMPANY_IAC_SEND_ONE_MORE_REQUEST_FOR_REVIEW_TEXT,
  COMPANY_IAC_SEND_REQUEST_FOR_REVIEW_TEXT,
  COMPANY_IAC_SEND_TO_REVIEW_TITLE,
  COMPANY_IAC_START_DEAL_TEXT,
  COMPANY_IAC_START_DEAL_TITLE,
  COMPANY_IAC_SUBMIT_DESCRIPTION,
  COMPANY_IAC_SUBMIT_TEXT,
  COMPANY_IAC_SUBMIT_TITLE,
  COMPANY_IAC_TAKE_ACTION_TITLE,
  FUND_MANAGER_COMPANY_IAC_DECLINE_TEXT,
  FUND_MANAGER_COMPANY_IAC_SUBMIT_TEXT,
  FUND_MANAGER_COMPANY_IAC_SUBMIT_TITLE,
} from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { COMPANY_IAC_STATUSES } from 'interfaces';
import {
  selectCompanyIacStatus,
  selectCompanyIacResubmitted,
  selectCompanyIacHasDealParameter,
  selectCompanyIacEditingSections,
} from 'modules/company-iac/selectors';
import { useAppSelector } from 'modules/store';
import { Button, ButtonWithIcon } from 'shared-components';
import Typography from 'shared-components/Typography';
import { checkIsAdmin } from 'utils';

interface Props {
  isLoading: boolean;
  onSubmitCompanyIac: VoidFunction;
  onDeclineCompanyIac: VoidFunction;
  onStopCompanyIac: VoidFunction;
  onStartDeal: VoidFunction;
}

const CompanyIacConfirm: FC<Props> = ({
  onStartDeal,
  onSubmitCompanyIac,
  onStopCompanyIac,
  onDeclineCompanyIac,
  isLoading,
}) => {
  const companyIacStatus = useAppSelector(selectCompanyIacStatus);
  const companyIacResubmitted = useAppSelector(selectCompanyIacResubmitted);
  const companyIacHasDealParameter = useAppSelector(selectCompanyIacHasDealParameter);
  const companyIacEditingSections = useAppSelector(selectCompanyIacEditingSections);

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const disabledConfirmStatuses = companyIacStatus === COMPANY_IAC_STATUSES.DECLINED_BY_FMUSERS;

  const disabledConfirm = !isAdmin && disabledConfirmStatuses;

  const disableSubmitDescription =
    disabledConfirmStatuses ||
    companyIacStatus === COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS ||
    companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS ||
    companyIacStatus === COMPANY_IAC_STATUSES.CONFIRMED_BY_ADMIN;

  const companyIacConfirmTitle = useMemo(() => {
    if (isAdmin) {
      if (companyIacStatus === COMPANY_IAC_STATUSES.DECLINED_BY_FMUSERS) return null;

      if (companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS) return COMPANY_IAC_START_DEAL_TITLE;

      if (companyIacResubmitted) return COMPANY_IAC_RESEND_TO_REVIEW_TITLE;

      if (
        companyIacStatus === COMPANY_IAC_STATUSES.CONFIRMED_BY_ADMIN ||
        companyIacStatus === COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS
      ) {
        return COMPANY_IAC_SEND_TO_REVIEW_TITLE;
      }

      return COMPANY_IAC_SUBMIT_TITLE;
    }

    if (disabledConfirm) return COMPANY_IAC_TAKE_ACTION_TITLE;

    return FUND_MANAGER_COMPANY_IAC_SUBMIT_TITLE;
  }, [disabledConfirm, isAdmin, companyIacResubmitted, companyIacStatus]);

  const confirmButtonText = useMemo(() => {
    if (isAdmin) {
      if (companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS) return COMPANY_IAC_START_DEAL_TEXT;

      if (companyIacResubmitted) return COMPANY_IAC_RESEND_REQUEST_FOR_REVIEW_TEXT;

      if (companyIacStatus === COMPANY_IAC_STATUSES.CONFIRMED_BY_ADMIN) return COMPANY_IAC_SEND_REQUEST_FOR_REVIEW_TEXT;

      if (companyIacStatus === COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS) {
        return COMPANY_IAC_SEND_ONE_MORE_REQUEST_FOR_REVIEW_TEXT;
      }

      return COMPANY_IAC_SUBMIT_TEXT;
    }

    return FUND_MANAGER_COMPANY_IAC_SUBMIT_TEXT;
  }, [companyIacStatus, isAdmin, companyIacResubmitted]);

  const confirmButtonVariant = useMemo(() => {
    if (companyIacStatus === COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS) {
      return ButtonVariants.SECONDARY;
    }

    return ButtonVariants.PRIMARY;
  }, [companyIacStatus]);

  const onClickConfirm = () => {
    if (companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS) {
      onStartDeal();
      return;
    }

    onSubmitCompanyIac();
  };

  if (
    disabledConfirm ||
    companyIacStatus === COMPANY_IAC_STATUSES.STOPPED ||
    companyIacStatus === COMPANY_IAC_STATUSES.ARCHIVED ||
    (companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS && companyIacHasDealParameter) ||
    (companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS && !isAdmin)
  )
    return null;

  return (
    <div className='max-w-[800px] mx-auto pt-8 flex flex-col items-center text-blue-800'>
      <h3 className='text-32 font-bold'>{companyIacConfirmTitle}</h3>
      {!disableSubmitDescription && (
        <Typography className='mt-4 mb-6 text-center'>{COMPANY_IAC_SUBMIT_DESCRIPTION}</Typography>
      )}

      {disabledConfirmStatuses ? (
        <div className='flex justify-center w-full space-x-4'>
          <ButtonWithIcon
            isLoading={isLoading}
            className='max-w-[280px]'
            text='Re-submit'
            icon={ResubmitSvg}
            onClick={onSubmitCompanyIac}
            buttonVariant={ButtonVariants.SECONDARY}
          />
          {disabledConfirmStatuses && isAdmin && (
            <ButtonWithIcon
              isLoading={isLoading}
              text='Stop Share Exchange Request'
              icon={CheckMark}
              onClick={onStopCompanyIac}
              buttonVariant={ButtonVariants.SECONDARY}
            />
          )}
        </div>
      ) : (
        <ButtonWithIcon
          isLoading={isLoading}
          className='max-w-[560px]'
          text={confirmButtonText}
          icon={CheckMark}
          onClick={onClickConfirm}
          buttonVariant={confirmButtonVariant}
          disabled={!!companyIacEditingSections?.length}
        />
      )}

      {!isAdmin && !disabledConfirm && (
        <Button
          isLoading={isLoading}
          className='w-fit mt-5'
          variant={ButtonVariants.SECONDARY}
          onClick={onDeclineCompanyIac}
        >
          {FUND_MANAGER_COMPANY_IAC_DECLINE_TEXT}
        </Button>
      )}
    </div>
  );
};

export default CompanyIacConfirm;
