import React from 'react';

import { COMPANY_PROFILE_OFFICERS_COLUMNS, COMPANY_PROFILE_OFFICERS_TITLE } from 'constants/company-profile';
import { selectCompanyOfficers } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import CompanyProfileOfficersItem from './CompanyProfileOfficersItem';

const CompanyProfileOfficers = () => {
  const officers = useAppSelector(selectCompanyOfficers);

  return (
    <SnippetLayout className='mt-6' title={COMPANY_PROFILE_OFFICERS_TITLE}>
      <Table className='table-simplified-tr table-simplified-th' columns={COMPANY_PROFILE_OFFICERS_COLUMNS}>
        {officers?.length ? (
          officers.map((item) => <CompanyProfileOfficersItem key={item.name} {...item} />)
        ) : (
          <TableSkeleton rowsNumber={4} columnsNumber={3} />
        )}
      </Table>
    </SnippetLayout>
  );
};

export default CompanyProfileOfficers;
