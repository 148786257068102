import React, { FC } from 'react';

import { UseCompanyIacOverviewTotalShares } from 'hooks/useCompanyIacOverview';
import Typography from 'shared-components/Typography';
import { formatNumberInputValue } from 'utils/shared';

const CompanyIacTotalSharesInfo: FC<UseCompanyIacOverviewTotalShares> = ({ totalShares, checkedTotalShares }) => {
  const calculatedTargetPercent = Math.round((checkedTotalShares / totalShares) * 100);

  if (!Boolean(totalShares)) return null;
  return (
    <div className='px-2'>
      <Typography>Total shares : {formatNumberInputValue(totalShares)}</Typography>
      <div className='mt-4x'>
        <b>
          Total % target:
          {calculatedTargetPercent}%
        </b>
        <Typography>
          ({formatNumberInputValue(checkedTotalShares)}/{formatNumberInputValue(totalShares)}*100%)
        </Typography>
      </div>
    </div>
  );
};

export default CompanyIacTotalSharesInfo;
