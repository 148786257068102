import React from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  value?: string;
  className?: string;
}

const ListItemTitle = ({ title, value, className }: Props) => {
  return (
    <p className={twMerge('flex flex-col text-grey-500 mr-6 basis-4/12', className)}>
      {title} <span className='pt-1.5 text-3xl text-blue-800 font-bold'>{value}</span>
    </p>
  );
};

export default ListItemTitle;
