import React, { FC, useState } from 'react';

import { ReactComponent as PencilSvg } from 'assets/svg/pencil.svg';
import { COMPANY_DATA_FIELDS } from 'constants/companies';
import { FUNDS_ITEM_FORM_FIELDS } from 'constants/funds';
import { SignUpFieldNames } from 'constants/sign-up';
import useModal from 'hooks/use-modal/useModal';
import { UsePaginationWithSearchReturnValues } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useCompanySelect from 'hooks/useCompanySelect';
import useFundForm from 'hooks/useFundForm';
import { FundFormInput } from 'interfaces/funds.interfaces';
import { selectCompanyData } from 'modules/companies/selectors';
import { createFund } from 'modules/funds/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  Button,
  ButtonsControl,
  CompanySelectField,
  CompanySelectValueContainerWithTitle,
  FormContent,
  ModalWindow,
} from 'shared-components';
import { getPreparedFundFormValuesForPayload } from 'utils/funds';
import { handleOnlyNumbersOnlySelect } from 'utils/shared/select';

import { ButtonVariants } from '../../constants/shared/button';

type Props = Partial<UsePaginationWithSearchReturnValues> & {
  loadFunds: VoidFunction;
};

const CreateFundModal: FC<Props> = ({ loadFunds, setPage, page }) => {
  const dispatch = useAppDispatch();

  const companyData = useAppSelector(selectCompanyData);

  const [isActionLoading, setIsActionLoading] = useState(false);

  const { isOpen, onOpen, onClose } = useModal();

  const {
    reset,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useFundForm({});

  const [isFormAvailable, setIsFormAvailable] = useState(false);

  const setAsAvailable = () => setIsFormAvailable(true);

  const [handleChangeCompanyName, handleChangeCompanyNumber, isCompanyNameCreated, isCompanyNumberCreated] =
    useCompanySelect(watch, setValue, reset, true, companyData ?? null, setAsAvailable, 'companyData');

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const handleCreateFund = (values: FundFormInput) => {
    setIsActionLoading(true);

    dispatch(createFund(getPreparedFundFormValuesForPayload(values)))
      .unwrap()
      .then(() => {
        if (page === 1) {
          loadFunds();
        }

        setPage?.(1);
        handleCloseModal();
      })
      .finally(() => setIsActionLoading(false));
  };

  return (
    <div>
      <ModalWindow
        title='Create Fund'
        className='min-w-[60%] xl:min-w-[52%]'
        wrapperClassName='pt-10'
        contentClassName='overflow-x-hidden'
        headerClassName='modal-big-title'
        isOpen={isOpen}
        onClose={handleCloseModal}
        preventOutsideClick
      >
        <form className='mt-10 p-1' onSubmit={handleSubmit(handleCreateFund)}>
          <FormContent register={register} control={control} errors={errors} fields={FUNDS_ITEM_FORM_FIELDS} />
          <div className='border-y-2 border-neutral-200 pt-6'>
            <div className='flex flex-col md:flex-row gap-6 mb-6'>
              <CompanySelectField
                className='w-full md:basis-[50%] mt-0'
                name={SignUpFieldNames.COMPANY_NUMBER}
                placeholder='Company Number'
                label='Company No.'
                control={control}
                onChange={handleChangeCompanyNumber}
                disableRequest={isCompanyNameCreated}
                description='Minimum of 5 characters required for search'
                components={{ ValueContainer: CompanySelectValueContainerWithTitle }}
                creatable
                isReversed
                isClearable
                onKeyDown={handleOnlyNumbersOnlySelect}
              />
              <CompanySelectField
                className='w-full md:basis-[50%] mt-0 ml-2.5'
                label='Legal Company Name'
                name={SignUpFieldNames.COMPANY_NAME}
                control={control}
                onChange={handleChangeCompanyName}
                disableRequest={isCompanyNumberCreated}
                creatable
                isClearable
              />
            </div>
            <Button type='button' variant={ButtonVariants.SECONDARY} className='w-fit mb-6' onClick={setAsAvailable}>
              Edit <PencilSvg className='ml-2' />
            </Button>
          </div>
          <div className='mt-6 grid grid-cols-4 gap-x-5'>
            <FormContent
              isDisabled={!isFormAvailable}
              register={register}
              control={control}
              errors={errors}
              fields={COMPANY_DATA_FIELDS}
            />
          </div>
        </form>
        <ButtonsControl
          submitButtonText='Create'
          backButtonText='Cancel'
          isLoading={isActionLoading}
          onSubmit={handleSubmit(handleCreateFund)}
          onBack={handleCloseModal}
        />
      </ModalWindow>

      <Button onClick={onOpen}>Create Fund</Button>
    </div>
  );
};

export default CreateFundModal;
