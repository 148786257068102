import React, { Dispatch, FC, SetStateAction, useEffect, useMemo } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  SIGNIFICANT_CONTROL_TABLE_COLUMNS,
  PERSON_EMPTY_ADDRESS_VALUE,
  DueDiligencePersonsFormNamesEnum,
} from 'constants/due-diligence';
import { successNotify } from 'helpers';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import {
  DueDiligencePersonInput,
  DueDiligencePersonsFormNames,
  DueDiligencePersonsFormState,
  DueDiligencePersonsProps,
  PersonsDeletedAddress,
  SignificantControlPerson,
  SignificantControlPersonAddressInput,
  TableColumns,
} from 'interfaces';
import {
  dueDiligenceAddNewAddresses,
  dueDiligenceAddPersons,
  dueDiligenceDeleteAddresses,
  dueDiligenceDeletePersons,
  dueDiligenceUpdateAddresses,
  dueDiligenceUpdatePersons,
  getDueDiligenceData,
} from 'modules/due-diligence/action';
import { selectDueDiligenceId } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import PersonsTable from './PersonsTable';
import SubmitResidentialAddressesModal from './submit-residential-addresses-modal/SubmitResidentialAddressesModal';

type Props = UseModalReturnValues &
  DueDiligencePersonsProps & {
    personsList: SignificantControlPerson[];
    name: DueDiligencePersonsFormNames;
    handleCancel: VoidFunction;
    isEditing: boolean;
    handleSetIsLoading: (value: boolean) => void;
    addPersonsText?: string;
    noPersonsAddedText?: string;
    columns?: TableColumns[];
    hideCheckbox?: boolean;
    deletedPersons: number[];
    setDeletedPersons: Dispatch<SetStateAction<number[]>>;
    deletedAddresses: PersonsDeletedAddress[];
    setDeletedAddresses: Dispatch<SetStateAction<PersonsDeletedAddress[]>>;
    onSuccessUpdatePersons?: VoidFunction;
    addAddressText?: string;
    bypassValidation?: boolean;
    formClassName?: string;
    successMessage?: string;
    footerClassName?: string;
  };

const PersonsBlock: FC<Props> = ({
  isCalled,
  modalProps,
  name,
  personsList,
  onClose,
  isOpen,
  isEditing,
  handleCancel,
  addPersonsText,
  handleSetIsLoading,
  noPersonsAddedText,
  columns,
  hideCheckbox,
  deletedPersons,
  setDeletedPersons,
  deletedAddresses,
  setDeletedAddresses,
  onSuccessUpdatePersons,
  addAddressText,
  bypassValidation,
  formClassName,
  successMessage,
  getUnfieldValue,
  footerClassName,
}) => {
  const dispatch = useAppDispatch();
  const id = useAppSelector(selectDueDiligenceId);

  const { id: companyId } = useParams();

  const {
    control,
    watch,
    reset,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<DueDiligencePersonsFormState>();

  const { fields, remove, append } = useFieldArray({
    control,
    name,
  });

  const hasNominee = useMemo(() => name === DueDiligencePersonsFormNamesEnum.BENEFICIAL_OWNERS, [name]);

  const personsData = useMemo(() => {
    return isCalled ? personsList : null;
  }, [isCalled, personsList]);

  const deletedPersonsList = useMemo(() => {
    if (!personsList?.length) return [];

    return personsList.filter(({ id }) => deletedPersons.includes(id));
  }, [personsList, deletedPersons]);

  const onClickSubmitUpdatedDirectors = () => {
    onClose();
    saveUpdatedDirectors();
  };

  const saveUpdatedDirectors = async () => {
    //  This logic divides the state of our form into 3 parts - addresses, newAddresses and persons-block.
    //  Comparison with dirtyFields is performed. Then requests are executed.
    if (!id || !companyId) return;

    const persons = watch(name);

    handleSetIsLoading(true);

    const personsList: Partial<DueDiligencePersonInput>[] = [];
    const newPersons: Partial<DueDiligencePersonInput>[] = [];
    const addresses: SignificantControlPersonAddressInput[] = [];
    const newAddresses: SignificantControlPersonAddressInput[] = [];
    const deletedAndEmptyAddresses = [...deletedAddresses];

    persons.forEach(({ residentialAddresses, isNew, ...person }, index) => {
      // If a person is new, then we push it to the newPersons array and handle it separately
      if (isNew) {
        newPersons.push({ ...person, residentialAddresses: [...residentialAddresses] });
        return;
      }
      const { residentialAddresses: dirtyResidentialAddresses, ...dirtyField } = dirtyFields?.[name]?.[index] || {};

      // We use this variable to assign exising address id to dirty address
      // Then we see if address id is empty we consider that this address is new or deleted
      // To determine if address is new we check isNew field
      const dirtyResidentialAddressesWithRealIds = Array.from(dirtyResidentialAddresses || []).map(
        (dirtyFieldsData, index) =>
          dirtyFieldsData
            ? { ...dirtyFieldsData, realAddressId: residentialAddresses[index]?.id }
            : { realAddressId: residentialAddresses[index]?.id },
      );

      // We need to filter out addresses that were deleted. In this case we consider
      // that if isNew is false and realAddressId is undefined then this address was deleted
      // Otherwise we consider that this address was updated/created
      // After that we do MAP for each address and check if address is empty
      // (dirty address can be empty if we update field that is in middle of values array)
      // But because we have array with realAddressIds we need to filter realAddressIds out and
      // Check if address is empty or not
      const updatedDirtyResidentialAddresses = dirtyResidentialAddressesWithRealIds
        .filter(({ isNew, realAddressId }) => {
          // To prevent skipping of new address when from BE response we get empty array of addresses
          // And manually create new address for a person LINE 248
          const currentAddress = residentialAddresses.find(({ id }) => id === realAddressId);
          return currentAddress?.isNew || isNew || realAddressId;
        })
        .map(({ realAddressId, ...restDirtyData }) =>
          Object.values(restDirtyData).length > 0 ? restDirtyData : undefined,
        );

      updatedDirtyResidentialAddresses?.forEach((address, index) => {
        if (!address || !Object.values(address).includes(true)) {
          return;
        }

        const currentAddress = residentialAddresses[index];

        // If address isnt new, and address data fields are empty then we consider that its empty address
        // and this address should be also deleted. We also have checks if IDs are present
        if (
          !address?.isNew &&
          person?.id &&
          currentAddress?.id &&
          !currentAddress?.from &&
          !currentAddress?.to &&
          !currentAddress?.tillNow &&
          !currentAddress?.address
        ) {
          deletedAndEmptyAddresses.push({ addressId: currentAddress.id, id: person.id, index });
          return;
        }

        address?.isNew || !currentAddress?.id
          ? newAddresses.push({ ...currentAddress, personId: person.id })
          : addresses.push(currentAddress);
      });

      if (Object.values(dirtyField).includes(true)) personsList.push(person);
    });
    try {
      await dispatch(dueDiligenceAddPersons({ dueDiligenceId: id, persons: newPersons, name })).unwrap();
      await dispatch(dueDiligenceDeletePersons({ id, significantIds: deletedPersons, name })).unwrap();
      await dispatch(dueDiligenceDeleteAddresses({ addresses: deletedAndEmptyAddresses, name })).unwrap();
      await dispatch(dueDiligenceAddNewAddresses({ addresses: newAddresses, name })).unwrap();
      await dispatch(dueDiligenceUpdatePersons({ id, persons: personsList, name })).unwrap();
      await dispatch(dueDiligenceUpdateAddresses({ id, addresses, name })).unwrap();
      await dispatch(getDueDiligenceData({ companyId })).unwrap();

      setDeletedAddresses([]);
      setDeletedPersons([]);
      handleSetIsLoading(false);
      handleCancel();

      if (successMessage) {
        successNotify(successMessage);
      }

      if (onSuccessUpdatePersons) {
        onSuccessUpdatePersons();
      }
    } catch (e) {
      handleSetIsLoading(false);
    }
  };

  const handleDeleteSignificantPerson = (index: number, id: number, isNew?: boolean) => {
    if (!isNew) {
      setDeletedPersons((prev) => [...prev, id]);
    }

    remove(index);
  };

  const onClickAddPersons = () => {
    append({
      id: new Date().getTime(),
      checked: false,
      dateOfBirth: null,
      fullName: '',
      residentialAddresses: [{ address: '', from: '', to: '', isNew: true, tillNow: false }],
      isNew: true,
    });
  };

  useEffect(() => {
    const defaultValues = personsData?.length
      ? personsData.map(({ residentialAddresses, ...person }) => {
          const mappedAddresses = residentialAddresses.map((address) => ({
            ...address,
            from: address.from,
            to: address.to,
            tillNow: address.tillNow,
          }));

          return {
            ...person,
            residentialAddresses: mappedAddresses.length ? mappedAddresses : [PERSON_EMPTY_ADDRESS_VALUE],
          };
        })
      : [];

    reset({ ...getValues(), [name]: defaultValues });
  }, [getValues, name, personsData, reset]);

  const hasError = useMemo(() => {
    if (getUnfieldValue) {
      const unfieldValue = getUnfieldValue(DueDiligencePersonsFormNamesEnum.DIRECTORS);
      return unfieldValue && unfieldValue.asError;
    }
    return false;
  }, [getUnfieldValue]);

  return (
    <>
      <SubmitResidentialAddressesModal
        isOpen={isOpen}
        onClose={onClose}
        onClickSubmitUpdatedDirectors={onClickSubmitUpdatedDirectors}
        directors={modalProps.directors}
        columns={columns || SIGNIFICANT_CONTROL_TABLE_COLUMNS}
        deletedDirectors={deletedPersonsList}
        hasNominee={hasNominee}
      />

      <form className={formClassName}>
        <PersonsTable
          footerClassName={footerClassName}
          columns={columns}
          fields={fields}
          personsData={personsData}
          name={name}
          isEditing={isEditing}
          setDeletedAddresses={setDeletedAddresses}
          handleDeleteSignificantPerson={handleDeleteSignificantPerson}
          hideCheckbox={hideCheckbox}
          addAddressText={addAddressText}
          hasNominee={hasNominee}
          noPersonsAddedText={noPersonsAddedText}
          bypassValidation={bypassValidation}
          getUnfieldValue={getUnfieldValue}
          haveAccessToDelete={fields?.length > 2}
          onClickAddPersons={onClickAddPersons}
          addPersonsText={addPersonsText}
        />
      </form>
    </>
  );
};

export default PersonsBlock;
