import React, { FC, ReactNode } from 'react';

import { CompanyWithInvestments } from 'interfaces';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';

import InvestmentsStepsNoInfo from './InvestmentsStepsNoInfo';

interface Props {
  children: ReactNode;
  companyWithInvestment: CompanyWithInvestments[];
  isLoading?: boolean;
}

const InvestmentsStepsGroup: FC<Props> = ({ children, companyWithInvestment, isLoading }) => {
  if (isLoading) return <StepperSkeleton count={3} withDetails />;

  if (!companyWithInvestment.length) return <InvestmentsStepsNoInfo />;

  return <>{children}</>;
};

export default InvestmentsStepsGroup;
