import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { BillsState } from 'interfaces';

import { BILLS_SLICE_NAME } from './action';
import { getAdminInvoicesMetricsReducer, getBillsReducer } from './reducers';

export const initialState: BillsState = {
  bills: [],
  isLoading: false,
  ...META_INITIAL_STATE,
  invoicesMetrics: {
    amountAll: null,
    amountPaymentRequested: null,
    amountPaid: null,
    isLoading: false,
  },
};

const billsSlice = createSlice({
  name: BILLS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getBillsReducer(builder);
    getAdminInvoicesMetricsReducer(builder);
  },
});

export const billsReducer = billsSlice.reducer;
