import React, { FC } from 'react';

import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';

import { COMPANY_IAC_SHARE_PRICE_FIELDS, COMPANY_IAC_SHARE_PRICE_TITLES } from 'constants/company-iac';
import { CompanyIacOverviewFormState, CompanyIacSharePrice } from 'interfaces';
import { FormLabel, FormContent } from 'shared-components';

import CompanyIacInputsTitles from '../CompanyIacInputsTitles';
import CompanyIacSharePriceDescription from './CompanyIacSharePriceDescription';
import CompanyIacSharePriceNoItems from './CompanyIacSharePriceNoItems';

type Props = {
  register: UseFormRegister<CompanyIacOverviewFormState>;
  errors: FieldErrors<CompanyIacOverviewFormState>;
  control: Control<CompanyIacOverviewFormState>;
  fields: CompanyIacSharePrice[];
  disabled: boolean;
};

const CompanyIacSharePriceFields: FC<Props> = ({ register, control, errors, fields, disabled }) => {
  return (
    <div className='mb-5 w-[600px] md:w-auto'>
      <FormLabel>Company Share Price*</FormLabel>

      <div className='bg-grey-100 p-2 mt-6'>
        <CompanyIacInputsTitles titles={COMPANY_IAC_SHARE_PRICE_TITLES} />

        {fields && !!fields?.length ? (
          <>
            <ul>
              {fields.map((field, index) => (
                <li key={`${field.classOfShares}_${index}`} className='flex mb-2'>
                  <FormContent
                    isDisabled={disabled}
                    fields={COMPANY_IAC_SHARE_PRICE_FIELDS(index)}
                    register={register}
                    errors={errors}
                    control={control}
                  />
                </li>
              ))}
            </ul>

            <CompanyIacSharePriceDescription />
          </>
        ) : (
          <CompanyIacSharePriceNoItems />
        )}
      </div>
    </div>
  );
};

export default CompanyIacSharePriceFields;
