import { ReactNode } from 'react';

import { CSSObjectWithLabel } from 'react-select';

import { FormItem, Option } from 'interfaces';
import { Input, SelectFormInput } from 'shared-components';
import NumberInput from 'shared-components/NumberInput';

import { DealParametersStatuses } from './deals';
import { DECIMAL_NUMBER_PATTERN, EMAIL_PATTERN, ENTER_DECIMAL_NUMBER_MESSAGE, REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export enum DealCommercialParametersFieldNames {
  SHARE_PRICE = 'daxiaSharePrice',
  FEE = 'feeRate',
  MINIMUM_FEE = 'feeMinimum',
  CLOSING_DATE = 'closingDate',
}

export enum DealParametersSharesFieldNames {
  CLASS_OF_SHARES = 'classOfShares',
  DENOMINATION = 'denomination',
  SHARE_PRICE = 'sharePrice',
}

export enum DealParametersNomineeShareHoldersFieldNames {
  NAME = 'name',
  PRIMARY_CONTACT = 'primaryContact',
  EMAIL = 'contactEmail',
  ADDRESS = 'address',
}

export enum DealParametersSettingsFieldNames {
  AUTO_NOTIFICATION = 'autoNotificationThatSEExpiresSoon',
}

export const DEAL_PARAMETERS_FINISH_MODAL_MESSAGE = 'Are you sure you want to finish Deal?';

export const DEAL_PARAMETERS_REACTIVATE_DEAL_MODAL_TITLE = 'Are you sure you want to re-activate Deal?';

export const DEAL_PARAMETERS_PREVIEW_AND_SEND_TOOLTIP = 'Preview and Send Deal Closure Overview Document(s)';

export const PREVIEW_AND_SEND_TOOLTIP_BUTTON_ID = 'preview-and-send-button-tooltip';

export const DEAL_PARAMETERS_EMAIL_PREVIEW_MODAL_TITLE = 'Email Preview';

export const DEAL_PARAMETERS_EMAIL_PREVIEW_MODAL_DESCRIPTION =
  'Please review the email below before it is sent out the to selected corrospondents.';

export const COMMERCIAL_PARAMETERS_INPUT_CLASSNAME =
  'flex items-start sm:items-center flex-col sm:flex-row [&>div]:basis-1/3 [&>div>div]:flex odd:bg-grey-100 even:bg-white py-3 px-5 mb-[0px]';

export const DEAL_FEE_INPUT_CLASSNAME = '[&>div>div]:flex mb-[0px]';

const DEAL_PARAMETERS_SHARES_INPUT_CLASSNAME = '[&>div>div]:flex mb-[0px]';

export const DEAL_PARAMETERS_FEE_FORM_FIELDS: FormItem[] = [
  {
    name: DealCommercialParametersFieldNames.FEE,
    label: 'Fee',
    placeholder: 'Fee',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    fractionLength: 50,
    symbol: '%',
    isAbsoluteError: true,
    className: DEAL_FEE_INPUT_CLASSNAME,
  },
  {
    name: DealCommercialParametersFieldNames.MINIMUM_FEE,
    label: 'Minimum Fee',
    placeholder: 'Minimum Fee',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    fractionLength: 50,
    symbol: '£',
    isAbsoluteError: true,
    className: DEAL_FEE_INPUT_CLASSNAME,
  },
];

export const DEAL_PARAMETERS_COMMERCIAL_PARAMETERS_FORM_FIELDS: FormItem[] = [
  {
    name: DealCommercialParametersFieldNames.SHARE_PRICE,
    label: 'Daxia share price',
    placeholder: 'Daxia share price',
    type: FieldTypes.NUMBER,
    component: NumberInput,
    symbol: '£',
    className: COMMERCIAL_PARAMETERS_INPUT_CLASSNAME,
    validation: {
      pattern: {
        value: DECIMAL_NUMBER_PATTERN,
        message: ENTER_DECIMAL_NUMBER_MESSAGE,
      },
    },
    fractionLength: 50,
  },
  {
    name: DealCommercialParametersFieldNames.FEE,
    label: 'Fee',
    placeholder: 'Fee',
    type: FieldTypes.TEXT,
    component: Input,
    // validation: { required: REQUIRED_MESSAGE }, // TODO: Waiting for clients decision
    symbol: '%',
    className: COMMERCIAL_PARAMETERS_INPUT_CLASSNAME,
  },
  {
    name: DealCommercialParametersFieldNames.MINIMUM_FEE,
    label: 'Minimum Fee',
    placeholder: 'Minimum Fee',
    type: FieldTypes.TEXT,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    symbol: '£',
    className: COMMERCIAL_PARAMETERS_INPUT_CLASSNAME,
  },
];

export const DEAL_PARAMETERS_SHARES_FORM_FIELDS = (index: number): FormItem[] => [
  {
    name: `companySharePrice.${index}.${DealParametersSharesFieldNames.CLASS_OF_SHARES}`,
    label: 'Class of shares',
    placeholder: 'Class of shares',
    type: FieldTypes.TEXT,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    description: '(e.g A Ordinary)',
    className: 'mb-[0px]',
  },
  {
    name: `companySharePrice.${index}.${DealParametersSharesFieldNames.DENOMINATION}`,
    label: 'Denomination',
    placeholder: 'Denomination',
    type: FieldTypes.TEXT,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    symbol: '£',
    description: '(e.g 0.01)',
    className: DEAL_PARAMETERS_SHARES_INPUT_CLASSNAME,
  },
  {
    name: `companySharePrice.${index}.${DealParametersSharesFieldNames.SHARE_PRICE}`,
    label: 'Share price',
    placeholder: 'Share price',
    type: FieldTypes.TEXT,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    symbol: '£',
    description: '(can be decimal)',
    className: DEAL_PARAMETERS_SHARES_INPUT_CLASSNAME,
  },
];

export const DEAL_PARAMETERS_NOMINEE_SHAREHOLDERS_FORM_FIELDS = (index: number): FormItem[] => [
  {
    name: `nominees.${index}.${DealParametersNomineeShareHoldersFieldNames.NAME}`,
    label: 'Name',
    placeholder: 'Name',
    type: FieldTypes.TEXT,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    className: 'mb-0',
  },
  {
    name: `nominees.${index}.${DealParametersNomineeShareHoldersFieldNames.PRIMARY_CONTACT}`,
    label: 'Primary contact',
    placeholder: 'Primary contact',
    required: true,
    type: FieldTypes.TEXT,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    className: 'mb-0',
  },
  {
    name: `nominees.${index}.${DealParametersNomineeShareHoldersFieldNames.EMAIL}`,
    label: 'Email Address',
    placeholder: 'Email Address',
    required: true,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
    },
    type: FieldTypes.TEXT,
    component: Input,
    className: 'mb-0',
  },
  {
    name: `nominees.${index}.${DealParametersNomineeShareHoldersFieldNames.ADDRESS}`,
    label: 'Address',
    placeholder: 'Address',
    required: true,
    validation: {
      required: REQUIRED_MESSAGE,
    },
    type: FieldTypes.TEXT,
    component: Input,
    className: 'mb-0',
  },
];

export const DEAL_PARAMETERS_AUTO_NOTIFICATION_OPTIONS: Option[] = [
  { label: 'Automatic', value: 'automatic' },
  { label: 'Manual', value: 'manual' },
];

export const DEAL_PARAMETERS_NOTIFICATIONS_FIELDS = ({ label }: { label: ReactNode }): FormItem[] => [
  {
    name: DealParametersSettingsFieldNames.AUTO_NOTIFICATION,
    label,
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    options: DEAL_PARAMETERS_AUTO_NOTIFICATION_OPTIONS,
    className: `${COMMERCIAL_PARAMETERS_INPUT_CLASSNAME} [&>label]:basis-2/3 justify-between [&>div]:w-[140px]`,
    validation: { required: REQUIRED_MESSAGE },
    customCompanySelectStyles: {
      option: (style: CSSObjectWithLabel) => ({ ...style, width: '140px' }),
      input: (style: CSSObjectWithLabel) => ({ ...style, height: '36px' }),
    },
    isSearchable: false,
  },
];

export const dealParametersStatusesLabel: { [key in DealParametersStatuses]: string } = {
  [DealParametersStatuses.LIVE]: 'Live',
  [DealParametersStatuses.COMPLETED]: 'Completed',
};
