import React, { FC, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

type Props = {
  onToggle?: (toggle: boolean) => void;
  initial: boolean;
  disabled?: boolean;
};

export const TOGGLE_SWITCH_TEST_ID = 'TOGGLE_SWITCH_TEST_ID';

const TOGGLE_CLASS = ' transform translate-x-[37px]';

const ToggleSwitch: FC<Props> = ({ onToggle, initial, disabled }) => {
  const [toggle, setToggle] = useState(initial || false);

  const handleClick = () => {
    if (disabled) return;

    if (onToggle) {
      onToggle(!toggle);
    }
    setToggle(!toggle);
  };

  return (
    <div
      className={twMerge(
        cn('w-[70px] h-[32px] flex items-center rounded-full p-1 cursor-pointer duration-300', {
          'bg-green-600': toggle,
          'bg-grey-300': !toggle,
          'cursor-not-allowed': disabled,
        }),
      )}
      onClick={handleClick}
      data-testid={TOGGLE_SWITCH_TEST_ID}
    >
      <div
        className={cn(
          'bg-white',
          { [TOGGLE_CLASS]: toggle },
          'relative h-[25px] w-[25px] rounded-full shadow-md transition-transform duration-300 ease-in-out',
        )}
      />
    </div>
  );
};

export default ToggleSwitch;
