import React from 'react';

const CompanyProfileInviteSkeleton = () => {
  return (
    <div className='flex w-full h-46'>
      <div className='skeleton-loader w-full h-full mr-4' />
      <div className='skeleton-loader w-150 h-full' />
    </div>
  );
};

export default CompanyProfileInviteSkeleton;
