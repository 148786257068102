import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ENTITY_INVITE_DIRECTOR_MODAL_DESCRIPTION, ENTITY_INVITE_DIRECTOR_MODAL_TITLE } from 'constants/entities';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import useModal from 'hooks/use-modal/useModal';
import { InvitePreviewModalParams } from 'interfaces';
import { getCompaniesInviteEmailTemplate } from 'modules/companies/action';
import { getEntityInvestors } from 'modules/current-user/action';
import {
  selectEntityInvestors,
  selectUser,
  selectUserEntityData,
  selectIsLoading,
} from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, ModalWindow, Pagination, TitleWithDescription } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

import { usePaginationWithSearch } from '../../hooks/use-pagination-with-search/usePaginationWithSearch';
import CompanyInvestorsContent from '../company-investors/CompanyInvestorsContent';
import { EntityInviteDirectorForm } from '../index';

const EntityInvestors = () => {
  const { id: companyId } = useParams();

  const {
    onOpen: onOpenInvitePreviewModal,
    onClose: onCloseInvitePreviewModal,
    ...invitePreviewModalState
  } = useModal();

  const [, setIsLoading] = useState(false);

  const { page, setPage } = usePaginationWithSearch();

  const dispatch = useAppDispatch();

  const {
    isLoading: isEntityInvestorsLoading,
    data: entityInvestors,
    ...paginationData
  } = useAppSelector(selectEntityInvestors);
  const user = useAppSelector(selectUser);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const handleSetIsLoading = (value: boolean) => setIsLoading(value);

  const handleOpenInvitePreviewModal = (params: InvitePreviewModalParams) => {
    dispatch(getCompaniesInviteEmailTemplate({ companyId: params?.companyId }))
      .unwrap()
      .then((response) => {
        onOpenInvitePreviewModal({ templates: response, ...params });
      });
  };

  useEffect(() => {
    if (user?.entityCompany) {
      dispatch(getEntityInvestors({ id: user.entityCompany.id, page, per_page: 10 }));
    }
  }, [dispatch, page]);

  return (
    <>
      <ModalWindow
        title={ENTITY_INVITE_DIRECTOR_MODAL_TITLE}
        description={ENTITY_INVITE_DIRECTOR_MODAL_DESCRIPTION}
        titleVariant={TypographyVariants.MEDIUM}
        headerClassName='text-blue-800'
        className='min-w-[70%] 2xl:min-w-[1048px]'
        contentClassName='overflow-x-hidden'
        onClose={onCloseInvitePreviewModal}
        {...invitePreviewModalState}
      >
        <EntityInviteDirectorForm onModalClose={onCloseInvitePreviewModal} />
      </ModalWindow>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-between flex-col sm:flex-row'>
          <TitleWithDescription
            className='flex-1'
            title='Investors'
            description={getTitleWithReplacedYourWord(
              'Upload all your Investors at once with Batch Upload',
              entityName,
              isTypeEntity,
            )}
          />
          <Button
            className='w-full sm:w-auto self-center'
            onClick={onOpenInvitePreviewModal}
            variant={ButtonVariants.SECONDARY}
          >
            Send Invite
          </Button>
        </div>

        <CompanyInvestorsContent
          isEntities
          investors={entityInvestors ?? []}
          isLoading={isEntityInvestorsLoading}
          handleSetIsLoading={handleSetIsLoading}
          companyId={companyId}
          handleOpenInvitePreviewModal={handleOpenInvitePreviewModal}
          isActionsHidden
        />
        <Pagination currentPage={page} updatePage={setPage} {...paginationData} />
      </div>
    </>
  );
};

export default EntityInvestors;
