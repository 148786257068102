import React from 'react';

const CorporateDocumentsAndFinancialsInformationTooltipMessage = () => {
  return (
    <p>
      Please upload the required mandatory documents. <br /> Documents must be the following format: PDF, DOCX, XLS.
      <br /> To upload documents please select &quot;Submit Documents&quot;. <br /> You are presented with a pop up,
      <br /> Please Select the correct Document for this field. Select Upload.
    </p>
  );
};

export default CorporateDocumentsAndFinancialsInformationTooltipMessage;
