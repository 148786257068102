import { useState, useEffect, useCallback } from 'react';

import { DUE_DILIGENCE_CHECK_LOCK_STATUSES } from 'constants/due-diligence';
import { getDueDiligenceCheckLock } from 'modules/due-diligence/action';
import { useAppDispatch } from 'modules/store';

const INTERVAL_DURATION = 1000 * 60;

const useDueDiligenceIntervalCheckLock = (id?: number, isLoadedDate?: boolean) => {
  const dispatch = useAppDispatch();
  const [isLocked, setIsLocked] = useState(true);
  const [status, setStatus] = useState(DUE_DILIGENCE_CHECK_LOCK_STATUSES.IDLE);

  const checkIsLocked = useCallback(() => {
    if (!id) {
      return;
    }

    setStatus(DUE_DILIGENCE_CHECK_LOCK_STATUSES.PENDING);

    dispatch(getDueDiligenceCheckLock(id.toString()))
      .unwrap()
      .then((res) => {
        setIsLocked(!!res.isLocked);
      })
      .finally(() => {
        setStatus(DUE_DILIGENCE_CHECK_LOCK_STATUSES.LOADED);
      });
  }, [dispatch, id]);

  useEffect(() => {
    if (!isLoadedDate) {
      return;
    }

    checkIsLocked();
  }, [checkIsLocked, isLoadedDate]);

  useEffect(() => {
    if (!isLoadedDate) {
      return;
    }

    const interval = setInterval(checkIsLocked, INTERVAL_DURATION);

    return () => clearInterval(interval);
  }, [checkIsLocked, dispatch, id, isLoadedDate]);

  return { isLocked, status };
};

export default useDueDiligenceIntervalCheckLock;
