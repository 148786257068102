import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.billsReducer;

export const selectBillsData = createSelector(selectState, ({ bills, isLoading, totalPages, total }) => ({
  bills,
  isLoading,
  totalPages,
  total,
}));

export const selectInvoicesMetrics = createSelector(selectState, ({ invoicesMetrics }) => invoicesMetrics);
