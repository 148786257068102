import React, { useEffect } from 'react';

import { INVOICES_ADMIN_PAGE_SEARCH_PLACEHOLDER, INVOICES_ADMIN_TABLE_TABS } from 'constants/my-bills';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getBills } from 'modules/bills/action';
import { selectBillsData } from 'modules/bills/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import InvoicesTableItem from './InvoicesTableItem';
import NoInvoicesAdded from './NoInvoicesAdded';

const INVOICES_PER_PAGE = 10;

const InvoicesTable = () => {
  const dispatch = useAppDispatch();

  const { handleFilter, page, query, setPage } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  const { bills, isLoading, ...billsPaginationData } = useAppSelector(selectBillsData);

  useEffect(() => {
    dispatch(
      getBills({
        page,
        per_page: INVOICES_PER_PAGE,
        query,
        order: sorting.order,
        sort: sorting.column,
        isAdmin: true,
      }),
    );
  }, [dispatch, page, query, sorting.column, sorting.order]);

  return (
    <div className='flex flex-col'>
      <SearchForm
        handleFilter={handleFilter}
        className='w-full md:w-7/12 mb-12 items-center flex-col xs:flex-row gap-4 xs:gap-0'
        placeholder={INVOICES_ADMIN_PAGE_SEARCH_PLACEHOLDER}
      />
      <Table columns={INVOICES_ADMIN_TABLE_TABS} onUpdateSorting={updateSorting}>
        {bills?.length && !isLoading ? (
          <>
            {bills.map((invoice) => (
              <InvoicesTableItem key={invoice.id} {...invoice} />
            ))}
          </>
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={10} />
        ) : (
          <NoInvoicesAdded />
        )}
      </Table>
      <Pagination currentPage={page} updatePage={setPage} {...billsPaginationData} />
    </div>
  );
};

export default InvoicesTable;
