import React, { useMemo, useState } from 'react';

import { SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { SHARE_EXCHANGE_NOT_AVAILABLE_FOR_EMAIL } from 'constants/share-exchange';
import { errorNotify } from 'helpers';
import useDeleteDeal from 'hooks/deals/useDeleteDeal';
import useLoadDeals from 'hooks/deals/useLoadDeals';
import useModal from 'hooks/use-modal/useModal';
import { Deal } from 'interfaces';
import { completeDealsParametersById, sendEmailNotificationsByDealId } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';
import { DealsTable } from 'page-components';
import { AlertModal, Pagination } from 'shared-components';
import { getDeleteDealsModalTitle } from 'utils';

import StopShareExchangesModal from './StopShareExchangesModal';

interface Props {
  companyId: number;
}

const ShareExchangesTab = ({ companyId }: Props) => {
  const dispatch = useAppDispatch();

  const { onOpen: handleOpenStopModal, ...stopModalState } = useModal();

  const [selectedDeals, setSelectedDeals] = useState<Deal[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { handleLoadDeals, loading, paginationState, updateSorting, handleFilter } = useLoadDeals({
    filterType: 'company_id',
    filterValue: companyId || null,
    onlyWithFilters: true,
  });

  const [handleDeleteDeal, { onOpen: handleOpenDeleteDealModal, ...deleteDealModalProps }, isDeleteDealLoading] =
    useDeleteDeal({ onSuccess: handleLoadDeals });

  const selectedDealsIds = useMemo(() => selectedDeals?.map((deal) => deal.id), [selectedDeals]);

  const handleCompleteDealsParameters = () => {
    const isChosenUnpaidShareExchange = selectedDeals.find((shareExchange) => !shareExchange?.paid);

    if (isChosenUnpaidShareExchange) {
      errorNotify('Exchanges in Ready to Complete stage can be completed');
      return;
    }

    setIsLoading(true);

    dispatch(completeDealsParametersById(selectedDealsIds))
      .unwrap()
      .then(() => setSelectedDeals([]))
      .finally(() => setIsLoading(false));
  };

  const resetSelectedDeals = () => setSelectedDeals([]);

  const handleSendEmail = () => {
    const isChosenIncorrectDealsForEmail = selectedDeals.some(
      ({ stage, paid }) => SHARE_EXCHANGE_NOT_AVAILABLE_FOR_EMAIL.includes(stage) || paid,
    );

    if (isChosenIncorrectDealsForEmail) {
      errorNotify('Exchanges with an invoice paid status is not eligible for email sending');
      return;
    }

    setIsLoading(true);

    dispatch(sendEmailNotificationsByDealId(selectedDealsIds))
      .unwrap()
      .then(() => setSelectedDeals([]))
      .finally(() => setIsLoading(false));
  };

  const shareExchangesActions = [
    {
      title: 'Complete',
      id: 'complete',
      action: handleCompleteDealsParameters,
    },
    {
      title: 'Stop',
      id: 'stop',
      action: handleOpenStopModal,
    },
    { title: 'Delete', id: 'delete', action: handleOpenDeleteDealModal },
    { title: 'Send Email', id: 'email', action: handleSendEmail },
  ];

  const getShareExchangesActions = () => {
    if (selectedDeals.some((deal) => deal.stage !== SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE)) {
      return shareExchangesActions.filter((action) => action.id !== 'complete');
    }

    return shareExchangesActions;
  };

  return (
    <>
      <AlertModal
        onSubmit={() => handleDeleteDeal(selectedDealsIds)}
        isLoading={isDeleteDealLoading}
        title={getDeleteDealsModalTitle(selectedDeals?.length)}
        submitButtonText='Confirm'
        modalClassName='lg:w-8/12 xl:w-6/12'
        contentClassName='lg:px-28 lg:py-12'
        wrapperModalClassName='lg:py-0 lg:px-0'
        {...deleteDealModalProps}
      />
      <StopShareExchangesModal
        selectedDeals={selectedDeals}
        selectedDealsIds={selectedDealsIds}
        resetSelectedDeals={resetSelectedDeals}
        {...stopModalState}
      />
      <DealsTable
        headerActions={getShareExchangesActions()}
        isLoading={isLoading}
        placeholder='Search by Investor Name'
        setSelectedDeals={setSelectedDeals}
        selectedDeals={selectedDeals}
        handleFilter={handleFilter}
        updateSorting={updateSorting}
      >
        <Pagination loading={loading} {...paginationState} />
      </DealsTable>
    </>
  );
};

export default ShareExchangesTab;
