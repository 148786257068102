import React, { PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import { ButtonVariants } from 'constants/shared/button';
import { ButtonAction } from 'interfaces';
import { Button, ButtonsActions, Checkbox } from 'shared-components';
import { TableData } from 'shared-components/table';

interface Props<T extends FieldValues> {
  actions?: ButtonAction[];
  isShownAddressButton?: boolean;
  isShownCheckbox?: boolean;
  directorIndex?: number;
  handleAddNewAddress?: VoidFunction;
  register?: UseFormRegister<T>;
  name: string;
  addAddressText?: string;
}

const PersonsAdditionalData = <T extends FieldValues>({
  actions = [],
  isShownAddressButton,
  isShownCheckbox = true,
  register,
  directorIndex,
  handleAddNewAddress,
  name,
  addAddressText,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const checkboxRegisterName = `${name}.${directorIndex}.checked` as any;
  const hasActions = actions.length > 0;

  return (
    <TableData>
      <div className='flex items-center justify-end'>
        {isShownAddressButton && (
          <Button
            className={cn('justify-end max-w-max', { 'mr-9': !hasActions, 'mr-2': hasActions })}
            type='button'
            variant={ButtonVariants.LINK}
            onClick={handleAddNewAddress}
          >
            {addAddressText || '+ Add another new address'}
          </Button>
        )}

        <ButtonsActions
          className={cn('items-center', { 'mr-8': !isShownCheckbox })}
          anchorPrefix='add-significant-control-'
          actions={actions}
        />

        {isShownCheckbox && register && <Checkbox className='mx-2 pb-1.5' {...register(checkboxRegisterName)} />}
      </div>
    </TableData>
  );
};

export default PersonsAdditionalData;
