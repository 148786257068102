import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { REQUIRED_MESSAGE } from 'constants/global';
import { FieldTypes } from 'constants/shared';
import { AddCommentFunction } from 'hooks/useDueDiligenceComments';
import { FormItem } from 'interfaces';
import { Button, FormContent, Input } from 'shared-components';

import { AddCommentInput } from './Comments';

export const ADD_COMMENT_FIELDS: FormItem[] = [
  {
    name: 'comment',
    label: 'Comment',
    placeholder: 'Add Comment',
    type: FieldTypes.TEXT,
    component: Input,
    isAbsoluteError: true,
    validation: {
      required: REQUIRED_MESSAGE,
      maxLength: { value: 250, message: 'The comment must not be greater than 250 characters.' },
    },
    className: 'mb-0',
  },
];

interface Props {
  className?: string;
  onAddComment: AddCommentFunction;
  isLoading: boolean;
  hidden?: boolean;
}

const AddComment: FC<Props> = ({ className, onAddComment, isLoading, hidden }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddCommentInput>({ defaultValues: { comment: '' } });

  const onSubmit: SubmitHandler<AddCommentInput> = (values) => onAddComment(values.comment, reset);

  if (hidden) return null;

  return (
    <form className={twMerge('flex items-end pb-6', className)} onSubmit={handleSubmit(onSubmit)}>
      <FormContent control={control} register={register} errors={errors} fields={ADD_COMMENT_FIELDS} />
      <Button className='w-250 ml-6' isLoading={isLoading}>
        Add Comment
      </Button>
    </form>
  );
};

export default AddComment;
