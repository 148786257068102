import React, { FC, useState } from 'react';

import { useAppDispatch } from 'modules/store';
import { deleteUser } from 'modules/users/action';
import { AlertModal } from 'shared-components';

import DeleteUserModalTitle from './DeleteUserModalTitle';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  id?: string;
  fullName?: string;
  callback?: VoidFunction;
};

const DeleteUserModal: FC<Props> = ({ isOpen, onClose, id, fullName, callback }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    if (id) {
      setIsLoading(true);

      dispatch(deleteUser(id))
        .then(() => {
          onClose();
          setIsLoading(false);

          if (callback) {
            callback();
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <AlertModal
      isLoading={isLoading}
      onSubmit={onSubmit}
      onClose={onClose}
      isOpen={isOpen}
      title={<DeleteUserModalTitle fullName={fullName} />}
      description='Please ensure all outstanding deals are completed before removing this user'
    />
  );
};

export default DeleteUserModal;
