import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { AuditLogsState } from 'interfaces/audit-logs.interfaces';

import { AUDIT_LOGS_SLICE_NAME } from './action';
import { getAdminAuditLogByIdReducer, getAdminAuditLogsReducer } from './reducers';

export const initialState: AuditLogsState = {
  data: [],
  isLoading: true,
  ...META_INITIAL_STATE,
  auditLogInfo: {
    info: null,
    isLoading: false,
  },
};

const auditLogsSlice = createSlice({
  name: AUDIT_LOGS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAdminAuditLogsReducer(builder);
    getAdminAuditLogByIdReducer(builder);
  },
});

export const auditLogsReducer = auditLogsSlice.reducer;
