import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  adminShareExchangeApis,
  adminShareExchangeRequestsApis,
  investorApi,
  investorShareExchangeRequestsApi,
} from 'apis';
import { MY_INVESTMENTS_PAGE_TABS_IDS } from 'constants/investments';
import { ROUTES } from 'constants/routes';
import { showServerError, successNotify } from 'helpers';
import { GetListPayload, ShareExchangeForInvestorPayload } from 'interfaces';
import history from 'services/history';

export const SHARE_EXCHANGE_SLICE_NAME = 'share-exchange';

export const getShareExchangeRequests = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/getShareExchangeRequests`,
  async (data: GetListPayload) => {
    const response = await adminShareExchangeRequestsApis.getShareExchangeRequests(data);
    return response.data;
  },
);

export const getShareExchange = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/getShareExchange`,
  async ({ id, isAdmin }: { id: string; isAdmin?: boolean }, { rejectWithValue }) => {
    try {
      const response = await (isAdmin
        ? adminShareExchangeRequestsApis.getShareExchangeRequest(id)
        : investorShareExchangeRequestsApi.getShareExchangeRequest(id));

      return response.data;
    } catch (e) {
      showServerError(e);
      history.push({ pathname: ROUTES.myInvestments, search: `?tab=${MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS}` });
      return rejectWithValue(e);
    }
  },
);

export const cancelShareExchange = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/cancelShareExcahnge`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await investorShareExchangeRequestsApi.cancelShareExchange(id);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const declineShareExchange = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/declineShareExchange`,
  async ({ id, reason }: { id: string; reason: string }, { rejectWithValue }) => {
    try {
      await adminShareExchangeRequestsApis.declineShareExchange({ id, reason });

      return { reason };
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const acceptShareExchange = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/acceptShareExchange`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await adminShareExchangeRequestsApis.acceptShareExchange(id);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const createShareExchangeForInvestor = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/createShareExchangeForInvestor`,
  async (payload: ShareExchangeForInvestorPayload, { rejectWithValue }) => {
    try {
      const response = await adminShareExchangeApis.createShareExchangeForInvestor(payload);
      successNotify('Share Exchange created successfully');
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getWelcomeInvestorShareExchangeData = createAsyncThunk(
  `${SHARE_EXCHANGE_SLICE_NAME}/getWelcomeInvestorShareExchangeData`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await investorApi.getWelcomeInvestorShareExchangeData();

      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
