import React, { FC } from 'react';

type Props = {
  titles: Array<{ label: string; className: string }>;
};

const CompanyIacInputsTitles: FC<Props> = ({ titles }) => {
  return (
    <div className='flex w-[600px] md:w-auto'>
      {titles.map(({ label, className }, index) => (
        <div key={index} className={className}>
          {label}
        </div>
      ))}
    </div>
  );
};

export default CompanyIacInputsTitles;
