import React, { FC, ReactNode } from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import ActionsButtons, { Props as ActionsButtonsProps } from 'page-components/due-diligence/ActionsButtons';
import { SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

interface Props extends ActionsButtonsProps {
  title: string;
  children: ReactNode;
}

const CompanyIacBlock: FC<Props> = ({ children, isHideActionButtons, title, ...actionButtonsProps }) => {
  return (
    <SnippetLayout>
      <Typography tag='h5' variant={TypographyVariants.MEDIUM}>
        {title}
      </Typography>
      <div className='mt-6 flex flex-col'>{children}</div>

      {!isHideActionButtons && <ActionsButtons hideComments {...actionButtonsProps} />}
    </SnippetLayout>
  );
};

export default CompanyIacBlock;
