import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Control,
  FieldErrors,
  useForm,
  useFieldArray,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  COMPANY_IAC_FILL_CUSTOM_FIELDS_MESSAGE,
  COMPANY_IAC_REQUIRED_CLOSING_DATES_MESSAGE,
  InvestmentProposalBlockNames,
} from 'constants/company-iac';
import { errorNotify } from 'helpers';
import { CompanyIacOverviewFormState, CompanyIacSharePrice, Option } from 'interfaces';
import { updateCompanyIac } from 'modules/company-iac/action';
import {
  selectCompanyIacOverviewData,
  selectCustomFields,
  selectIsCustomFieldsInCreateMode,
} from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { convertsObjectToOptionsMap } from 'utils';
import { getCustomFieldsValues, prepareCustomFieldsForUpload } from 'utils/companies-iac';

import useCompanyIacCustomFields, {
  UseCompanyIacCustomFieldsReturnValues,
} from './use-company-iac-custom-fields/useCompanyIacCustomFields';
import useCompanyIacEdit from './useCompanyIacEdit';

export interface UseCompanyIacOverviewTotalShares {
  totalShares: number;
  checkedTotalShares: number;
}

export interface UseCompanyIacOverviewReturnValues {
  isEditing: boolean;
  handleEdit: VoidFunction;
  handleDiscardChanges: VoidFunction;
  handleFormSubmit: VoidFunction;
  sharedFormProps: {
    register: UseFormRegister<CompanyIacOverviewFormState>;
    errors: FieldErrors<CompanyIacOverviewFormState>;
    control: Control<CompanyIacOverviewFormState>;
    checkDisabledField?: (index: number, fieldName: string) => boolean;
  };
  customFieldsData: Omit<
    UseCompanyIacCustomFieldsReturnValues,
    'handleSubmitCustomFieldsForm' | 'reset' | 'resetDeletedFields'
  >;
  setValue: UseFormSetValue<CompanyIacOverviewFormState>;
  isLoading: boolean;
  trigger: UseFormTrigger<CompanyIacOverviewFormState>;
  reset: UseFormReset<CompanyIacOverviewFormState>;
  id?: number;
  isAllowAdditionalValidation: boolean;
  setIsAllowAdditionalValidation: Dispatch<SetStateAction<boolean>>;
  companySharePriceFields: CompanyIacSharePrice[];
  isHideActionButtons: boolean;
}

const useCompanyIacOverview = (): UseCompanyIacOverviewReturnValues & UseCompanyIacOverviewTotalShares => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isAllowAdditionalValidation, setIsAllowAdditionalValidation] = useState(false);

  const companyIacOverviewData = useAppSelector(selectCompanyIacOverviewData);
  const isCustomFieldsInCreateMode = useAppSelector(
    selectIsCustomFieldsInCreateMode(InvestmentProposalBlockNames.OVERVIEW),
  );
  const overviewCustomFields = useAppSelector(selectCustomFields(InvestmentProposalBlockNames.OVERVIEW));

  const { isEditing, handleEdit, handleCancel, isHideActionButtons } = useCompanyIacEdit(
    InvestmentProposalBlockNames.OVERVIEW,
  );

  const {
    handleSubmitCustomFieldsForm,
    reset: resetCustomFields,
    resetDeletedFields: resetCustomDeletedFields,
    ...customFieldsData
  } = useCompanyIacCustomFields();

  const {
    handleSubmit: handleSubmitCoreForm,
    control,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<CompanyIacOverviewFormState>();

  const {
    fields: companySharePriceFields,
    append: appendCompanySharePriceField,
    remove: removeCompanySharePriceField,
  } = useFieldArray({ control, name: 'companySharePrices' });

  const watchFullyDilutedShareCapital = watch('fullyDilutedShareCapital');
  const purchaseAtCompletion = watch('sharesToPurchase');
  const watchClosingDates = watch('closingDates');

  const totalShares = watchFullyDilutedShareCapital?.reduce(
    (prev, curr) => prev + Number(curr.noOfSharesShareCapital),
    0,
  );

  const checkedTotalShares = watchFullyDilutedShareCapital?.reduce(
    (prev, { noOfSharesShareCapital, checked }) => (checked ? prev + Number(noOfSharesShareCapital) : prev),
    0,
  );

  const checkDisabledFullyDilutedShareCapitalField = (index: number, fieldName: string) => {
    if (fieldName !== 'classOfShares') {
      return false;
    }

    return !!watchFullyDilutedShareCapital?.[index]?.checked;
  };

  const sharedFormProps = {
    register,
    control,
    errors,
    checkDisabledField: checkDisabledFullyDilutedShareCapitalField,
  };

  const handleDiscardChanges = () => {
    reset();
    resetCustomDeletedFields();
    handleCancel();
    setIsAllowAdditionalValidation(false);
  };

  const onSubmit = (coreData: CompanyIacOverviewFormState, customFieldsValues: Record<string, string>) => {
    if (!id || !totalShares || !companySharePriceFields?.length) return;

    if (!watchClosingDates?.length) return errorNotify(COMPANY_IAC_REQUIRED_CLOSING_DATES_MESSAGE);

    if (isCustomFieldsInCreateMode) return errorNotify(COMPANY_IAC_FILL_CUSTOM_FIELDS_MESSAGE);

    setIsLoading(true);

    const { approvalForSecondarySale, stageOfDevelopment } = coreData;

    const payloadData = {
      ...coreData,
      approvalForSecondarySale: approvalForSecondarySale.value,
      stageOfDevelopment: stageOfDevelopment.value,
      overviewCustomInputs: prepareCustomFieldsForUpload(
        overviewCustomFields,
        customFieldsValues,
        customFieldsData.deletedFields,
      ),
    };

    // TODO: Delete shouldUpdateStore parameter for overview when autosave will be implemented
    dispatch(updateCompanyIac({ id: coreData.id, companyId: id, data: payloadData, shouldUpdateStore: true }))
      .unwrap()
      .then(() => {
        handleCancel();
      })
      .finally(() => {
        setIsLoading(false);
        setIsAllowAdditionalValidation(false);
      });
  };

  const handleFormSubmit = () => {
    setIsAllowAdditionalValidation(true);
    handleSubmitCoreForm((coreData) => {
      handleSubmitCustomFieldsForm((customFieldsData) => {
        onSubmit(coreData, customFieldsData);
      })();
    })();
  };

  useEffect(() => {
    const noOfSharesKey = 'sharesToPurchase.noOfSharesPurchaseAtCompletion';

    if (totalShares) {
      const calculatedPurchaseNoOfShares =
        Math.floor((Number(purchaseAtCompletion?.percent) * totalShares) / 100) || '0';

      setValue('sharesToPurchase.percent', (totalShares && purchaseAtCompletion?.percent) || '24.99', {
        shouldValidate: true,
      });
      setValue(noOfSharesKey, String(calculatedPurchaseNoOfShares), { shouldValidate: true });
    } else setValue(noOfSharesKey, '');
  }, [purchaseAtCompletion?.percent, setValue, totalShares]);

  useEffect(() => {
    if (!companyIacOverviewData || isEditing) {
      return;
    }

    const { stageOfDevelopment, approvalForSecondarySale } = companyIacOverviewData;

    const convertedOptionsValues = convertsObjectToOptionsMap({
      stageOfDevelopment,
      approvalForSecondarySale,
    }) as {
      stageOfDevelopment: Option;
      approvalForSecondarySale: Option;
    };

    reset({
      ...companyIacOverviewData,
      ...convertedOptionsValues,
      fullyDilutedShareCapital: companyIacOverviewData?.fullyDilutedShareCapital || [],
      companySharePrices: companyIacOverviewData?.companySharePrices || [],
      closingDates: companyIacOverviewData?.closingDates || [],
    });

    resetCustomFields(getCustomFieldsValues(overviewCustomFields));
  }, [companyIacOverviewData, overviewCustomFields, reset, resetCustomFields, isEditing]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!value || !name || !name.includes('fullyDilutedShareCapital')) {
        return;
      }

      const [index, property] = name.replace('fullyDilutedShareCapital.', '').split('.');
      const numberIndex = parseInt(index, 10);

      if (property !== 'checked' || Number.isNaN(numberIndex)) {
        return;
      }

      const { checked: changedCheckedValue, classOfShares } = value?.fullyDilutedShareCapital?.[numberIndex] || {};

      if (changedCheckedValue) {
        appendCompanySharePriceField({ classOfShares: classOfShares || '', sharePrice: '', denomination: '' });
      } else {
        removeCompanySharePriceField(
          companySharePriceFields.findIndex((field) => field.classOfShares === classOfShares),
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, removeCompanySharePriceField, appendCompanySharePriceField, companySharePriceFields]);

  useEffect(() => {
    if (!watchFullyDilutedShareCapital) {
      return;
    }

    const checkedFullyDilutedShareCapital = watchFullyDilutedShareCapital.filter(({ checked }) => checked);

    // We need to check if we deleted any fully diluted share capital fields
    // If we deleted and we have share price fields for that fully diluted share capital field
    // We need to remove share price fields
    const removedSharePriceIndexes = companySharePriceFields.reduce((acc: number[], { classOfShares }, index) => {
      const isSharePriceFieldExist = checkedFullyDilutedShareCapital.some(
        ({ classOfShares: checkedClassOfShares }) => checkedClassOfShares === classOfShares,
      );

      if (!isSharePriceFieldExist) {
        return [...acc, index];
      }

      return acc;
    }, []);

    removedSharePriceIndexes.forEach((index) => removeCompanySharePriceField(index));
  }, [watchFullyDilutedShareCapital, companySharePriceFields, removeCompanySharePriceField]);

  return {
    isEditing,
    isHideActionButtons,
    handleEdit,
    handleDiscardChanges,
    handleFormSubmit,
    sharedFormProps,
    totalShares,
    checkedTotalShares,
    customFieldsData,
    setValue,
    isLoading,
    id: companyIacOverviewData?.id,
    trigger,
    reset,
    isAllowAdditionalValidation,
    setIsAllowAdditionalValidation,
    companySharePriceFields,
  };
};

export default useCompanyIacOverview;
