import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { shareExchangeStatusesColoredLabel } from 'constants/share-exchange';
import { ButtonVariants } from 'constants/shared/button';
import { ShareExchangeInterfaces } from 'interfaces';
import { ColoredLabel, LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getShareExchangeLink } from 'utils';

const ShareExchangeRequestsTableItem: FC<ShareExchangeInterfaces> = ({
  id,
  companyName,
  status,
  createdAt,
  investor,
}) => {
  const { firstName, lastName, phone, email } = investor;

  return (
    <TableRow>
      <TableData>
        <LinkButton className='w-40' to={getShareExchangeLink(id, true)} variant={ButtonVariants.SECONDARY}>
          View
        </LinkButton>
      </TableData>
      <TableData>{companyName}</TableData>
      <TableData>
        <ColoredLabel {...shareExchangeStatusesColoredLabel[status]} />
      </TableData>
      <TableData>{format(parseISO(createdAt), DAY_FORMAT_WITH_SLASHES)}</TableData>
      <TableData>{`${firstName} ${lastName}`}</TableData>
      <TableData>{phone}</TableData>
      <TableData>{email}</TableData>
    </TableRow>
  );
};

export default ShareExchangeRequestsTableItem;
