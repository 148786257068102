import React from 'react';

import { useNavigate } from 'react-router-dom';

import { MY_INVESTMENTS_PAGE_TABS_IDS } from 'constants/investments';
import { ROUTES } from 'constants/routes';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { ButtonsControl, ModalWindow } from 'shared-components';
import { getLinkWithTabId } from 'utils';

const CONFIRM_START_NEW_SHARE_EXCHANGE_MODAL_TITLE = 'Do you want to start a new share exchange?';

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  companyId?: number;
}

const ConfirmStartNewShareExchangeModal = ({ companyId, ...modalProps }: Props) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (!companyId) return;

    navigate(getLinkWithTabId(ROUTES.myInvestments, MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS), {
      state: { companyId },
    });
  };

  const handleCancel = () => {
    navigate(getLinkWithTabId(ROUTES.myInvestments, MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS));
  };

  return (
    <ModalWindow
      title={CONFIRM_START_NEW_SHARE_EXCHANGE_MODAL_TITLE}
      wrapperClassName='!px-8'
      className='lg:w-8/12 xl:w-5/12'
      {...modalProps}
      onClose={handleCancel}
    >
      <ButtonsControl submitButtonText='Yes' backButtonText='No' onSubmit={handleConfirm} onBack={handleCancel} />
    </ModalWindow>
  );
};

export default ConfirmStartNewShareExchangeModal;
