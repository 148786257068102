import React, { FC, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { DUE_DILIGENCE_STATUSES, dueDiligenceStatusesColoredLabel } from 'constants/due-diligence';
import { ROUTES } from 'constants/routes';
import { Company } from 'interfaces';
import { ButtonDropdown, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import {
  checkIsFmUser,
  getAdminCompanyIacLink,
  getCompanyInvestorsLink,
  getCompanyProfileLink,
  getDueDiligenceLink,
  navigateWithFilter,
} from 'utils';

import { companyIacStatusesColoredLabel } from '../../constants/company-iac';

type Props = {
  company: Company;
  onClickCompanyIac?: (companyId: number, companyName: string) => void;
  onDeleteCompanyClick?: (companyId: number, hasLiveDealParameter: boolean) => void;
  onOpen?: VoidFunction;
};

const AdminCompaniesTableRow: FC<Props> = ({ onClickCompanyIac, company, onOpen, onDeleteCompanyClick }) => {
  const navigate = useNavigate();

  const isFmUser = useMemo(() => checkIsFmUser(), []);

  const viewDocumentsByCompanyId = () => {
    navigate(navigateWithFilter(true, ROUTES.documents, 'company_id', company.id.toString()), {
      state: {
        companyName: company.companyName,
      },
    });
  };

  const viewDueDiligenceByCompanyId = () => {
    navigate(getDueDiligenceLink(company.id, true), {
      state: {
        companyName: company.companyName,
        shownInvitationProposal: true,
      },
    });
  };

  const viewCompanyIacByCompanyId = () => {
    if (company.canViewInvestmentProposal || isFmUser) {
      navigate(getAdminCompanyIacLink(company.id));
      return;
    }

    onClickCompanyIac && onClickCompanyIac(company.id, company.companyName);
  };

  const setDeleteCompanyId = () => {
    if (!onDeleteCompanyClick) return;
    onDeleteCompanyClick(company.id, company.hasLiveDealParameter);
    onOpen && onOpen();
  };

  const getCompaniesActions = () => {
    const actions = [
      {
        title: 'Company Profile',
        id: 'profile',
        action: () => navigate(getCompanyProfileLink(company.id, true)),
      },
      {
        title: 'Documents',
        id: 'view_documents',
        action: viewDocumentsByCompanyId,
      },
      {
        title: 'Due Diligence',
        id: 'due_diligence',
        action: viewDueDiligenceByCompanyId,
      },
      {
        id: 'invite_investors',
        title: 'Invite Investors',
        action: () =>
          navigate(getCompanyInvestorsLink(company.id, true), { state: { companyName: company.companyName } }),
      },
      ...(!isFmUser
        ? [
            {
              title: 'Delete',
              id: 'delete',
              action: setDeleteCompanyId,
            },
          ]
        : []),
      ...(company.dueDiligence === DUE_DILIGENCE_STATUSES.FINISHED
        ? [
            {
              title: 'IAC',
              id: 'company_iac',
              action: viewCompanyIacByCompanyId,
            },
          ]
        : []),
    ];

    if (!company.canViewDueDiligence) {
      return actions.filter((action) => action.id !== 'due_diligence');
    }

    if (isFmUser) {
      return actions.filter((action) => action.id !== 'profile' && action.id !== 'invite_investors');
    }

    return actions;
  };

  return (
    <TableRow>
      <TableData>
        <ButtonDropdown className='w-40 ml-1' actions={getCompaniesActions()} />
      </TableData>
      <TableData>{company.companyName}</TableData>
      <TableData>{company.companyNumber}</TableData>
      <TableData>{company.iac && <ColoredLabel {...companyIacStatusesColoredLabel[company.iac]} />}</TableData>
      <TableData>
        <ColoredLabel {...dueDiligenceStatusesColoredLabel[company.dueDiligence]} />
      </TableData>
    </TableRow>
  );
};

export default AdminCompaniesTableRow;
