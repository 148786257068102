import { useCallback, useState } from 'react';

import { TableSorting, UpdateSortingFunctionType } from 'interfaces';

export type UseTableSortingValuesType = {
  sorting: TableSorting;
  updateSorting: UpdateSortingFunctionType;
};

const useTableSorting = (): UseTableSortingValuesType => {
  const [sorting, setSorting] = useState<TableSorting>({});

  const updateSorting: UpdateSortingFunctionType = useCallback((column, order) => {
    setSorting({ column, order });
  }, []);

  return { sorting, updateSorting };
};
export default useTableSorting;
