import React, { FC, useMemo } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { COMPANY_WITH_SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import { ROUTES } from 'constants/routes';
import { StepperStatuses, stepperStatusesLabels } from 'constants/shared/stepper';
import { TypographyVariants } from 'constants/shared/typography';
import { CompanyWithInvestmentsAndDeals, InvestmentStatuses } from 'interfaces';
import { ColoredLabel, SnippetLayout } from 'shared-components';
import Stepper from 'shared-components/stepper-v1/Stepper';
import StepperItem from 'shared-components/stepper-v1/StepperItem';
import StepperItemCircle from 'shared-components/stepper-v1/StepperItemCircle';
import StepperItemDetails from 'shared-components/stepper-v1/StepperItemDetails';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';
import Typography from 'shared-components/Typography';
import {
  getDealDetailsLink,
  getInvestmentsStartShareExchange,
  getInvestmentsStartShareExchangeRequest,
  getShareExchangeLink,
} from 'utils';

interface Props {
  companyWithInvestment: CompanyWithInvestmentsAndDeals;
  showCompany: boolean;
  isLast: boolean;
}

const InvestmentStepsSnippetItem: FC<Props> = ({ companyWithInvestment, showCompany, isLast }: Props) => {
  const navigate = useNavigate();

  const investment = companyWithInvestment.investments[0];
  const companyId = companyWithInvestment.id;

  const hasInvestmentAdded = useMemo(() => {
    return investment?.status === InvestmentStatuses.ACTIVE || InvestmentStatuses.INITIAL;
  }, [investment]);

  const hasShareExchangeAdded = useMemo(() => {
    const statusesActivatingStep2 = [
      COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE,
      COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER,
    ];
    return (
      statusesActivatingStep2.includes(companyWithInvestment.userCompanySEStatus) &&
      companyWithInvestment.isActiveExchange
    );
  }, [companyWithInvestment]);

  const shareExchangeActionLink = useMemo(() => {
    if (companyWithInvestment.userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER) {
      return getInvestmentsStartShareExchangeRequest(companyId.toString());
    }
    if (companyWithInvestment.userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE) {
      return getInvestmentsStartShareExchange(companyId.toString());
    }
    if (
      companyWithInvestment.activeShareExchangeRequest &&
      !companyWithInvestment.isActiveExchange &&
      companyWithInvestment.userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER
    ) {
      return getShareExchangeLink(companyWithInvestment.activeShareExchangeRequest.id.toString());
    }

    return null;
  }, [companyWithInvestment, companyId]);

  const { isCompletedContinueExchange: hasCompletedContinueExchange, continueExchangeLink } = useMemo(() => {
    if (!investment) {
      return {
        isCompletedContinueExchange: false,
        continueExchangeLink: '',
      };
    }

    const investmentData = investment;

    let continueExchangeLink = ROUTES.shareExchangeProcess;
    if (companyWithInvestment.deals && companyWithInvestment.deals.length > 0) {
      continueExchangeLink = getDealDetailsLink(companyWithInvestment.deals[0].id.toString());
    }

    return {
      isCompletedContinueExchange: investmentData.status === InvestmentStatuses.EXCHANGED,
      continueExchangeLink: continueExchangeLink,
    };
  }, [investment, companyWithInvestment.deals]);

  const steps: {
    step: number;
    title: string | JSX.Element;
    description?: string;
    link?: string | null;
    isActive?: boolean;
  }[] = useMemo(
    () => [
      {
        step: 1,
        title: 'Add Investments',
        description: 'Getting Started',
        isActive: Boolean(hasInvestmentAdded),
      },
      {
        step: 2,
        title: (
          <>
            Initiate Exchange
            {!showCompany && (
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(ROUTES.shareExchangeProcess);
                }}
                className='text-sm text-blue-500 ml-2 underline cursor-pointer'
              >
                view details <FontAwesomeIcon icon={faChevronDown} />
              </span>
            )}
          </>
        ),
        description: 'Start Exchange',
        link: hasInvestmentAdded ? shareExchangeActionLink : undefined,
        isActive: Boolean(hasShareExchangeAdded),
      },
      {
        step: 3,
        title: 'Continue Exchange',
        description: 'Progress',
        link: hasShareExchangeAdded ? continueExchangeLink : undefined,
        isActive: Boolean(hasCompletedContinueExchange),
      },
    ],
    [
      hasInvestmentAdded,
      hasShareExchangeAdded,
      shareExchangeActionLink,
      hasCompletedContinueExchange,
      continueExchangeLink,
      showCompany,
      navigate,
    ],
  );

  return (
    <SnippetLayout
      className={` overflow-auto ${isLast ? '' : 'mb-6'}`}
      childrenClassName='flex items-center justify-between'
    >
      {showCompany && (
        <Typography variant={TypographyVariants.BODY_SMALL} className='flex flex-col mr-6 basis-1/4'>
          Company Name
          <Typography tag='span' className='mt-1' variant={TypographyVariants.MEDIUM}>
            {companyWithInvestment.companyName}
          </Typography>
        </Typography>
      )}
      <div className={showCompany ? 'w-3/4' : 'w-full'}>
        <Stepper
          steps={steps}
          isLoading={false}
          renderContent={({ isActive, link, ...stepDetails }) => {
            return (
              <StepperItem
                key={stepDetails.step}
                onClick={link ? () => navigate(link) : undefined}
                isActive={isActive}
                renderStepContent={({ isActive }) => (
                  <StepperItemCircle status={isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING} />
                )}
              >
                <StepperItemDetails {...stepDetails}>
                  <ColoredLabel
                    className='mt-4'
                    {...stepperStatusesLabels[isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING]}
                  />
                </StepperItemDetails>
              </StepperItem>
            );
          }}
          renderSkeleton={() => <StepperSkeleton count={3} withDetails />}
        />
      </div>
    </SnippetLayout>
  );
};

export default InvestmentStepsSnippetItem;
