import { useState } from 'react';

import { FieldValues, UseFormReset } from 'react-hook-form';

import {
  DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE,
  DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE,
  DUE_DILIGENCE_DIRECTORS_MISSED_FULL_NAME_MESSAGE,
  DueDiligenceSections,
} from 'constants/due-diligence';
import { errorNotify } from 'helpers';
import {
  DueDiligencePersonInput,
  DueDiligencePersonsFormNames,
  DueDiligencePersonsSubmitFunctionType,
  PersonsDeletedAddress,
} from 'interfaces';
import { hasDirectorWithInvalidSubmittedDateRanges, hasDirectorWithOverlappingDates } from 'utils/due-diligence';

import useDueDiligenceEdit from './use-due-diligence-edit/useDueDiligenceEdit';

const useDueDiligencePersons = ({
  sectionType,
  reset,
  dirtyFields,
  name,
  validateAddresses = true,
}: {
  sectionType: DueDiligenceSections;
  reset: UseFormReset<FieldValues>;
  dirtyFields: any;
  name: DueDiligencePersonsFormNames;
  validateAddresses?: boolean;
}) => {
  const editData = useDueDiligenceEdit(sectionType);

  const [isLoading, setIsLoading] = useState(false);
  const [deletedPersons, setDeletedPersons] = useState<number[]>([]);
  const [deletedAddresses, setDeletedAddresses] = useState<PersonsDeletedAddress[]>([]);

  const handleSetIsLoading = (value: boolean) => setIsLoading(value);

  const handleDiscardChanges = () => {
    reset();
    editData.handleCancel();
  };

  const onSubmit: DueDiligencePersonsSubmitFunctionType =
    ({ onOpen, handleCancel }) =>
    (values) => {
      const persons = values[name] as DueDiligencePersonInput[];

      const modifiedPersons = persons?.reduce((acc: DueDiligencePersonInput[], director, index) => {
        if (!dirtyFields?.[name]?.[index]) {
          return acc;
        }

        return [...acc, director];
      }, []);

      if (!modifiedPersons?.length && !deletedPersons?.length) {
        handleCancel();
        return;
      }

      const directorWithOverlappingDates = hasDirectorWithOverlappingDates(persons);
      const directorWithInvalidDateRange = hasDirectorWithInvalidSubmittedDateRanges(persons);

      // If validation is bypassed for Significant Control Persons, then we should validate at least name
      if (!validateAddresses && modifiedPersons.some((person) => !person.fullName)) {
        errorNotify(DUE_DILIGENCE_DIRECTORS_MISSED_FULL_NAME_MESSAGE);
        return;
      }

      // If there is a director with overlapping dates, then we show an error and return
      if (validateAddresses && directorWithOverlappingDates) {
        errorNotify(`${DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE} ${directorWithOverlappingDates.fullName}`);
        return;
      }

      // If there is a director with invalid date range, then we show an error and return
      if (validateAddresses && directorWithInvalidDateRange) {
        errorNotify(`${DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE} ${directorWithInvalidDateRange.fullName}`);
        return;
      }

      onOpen({ directors: modifiedPersons });
    };

  return {
    onSubmit,
    handleDiscardChanges,
    isLoading,
    handleSetIsLoading,
    deletedPersons,
    setDeletedPersons,
    deletedAddresses,
    setDeletedAddresses,
    handleEdit: editData.handleEdit,
    handleCancel: editData.handleCancel,
    previousState: editData.previousState,
    isEditing: editData.isEditing,
  };
};

export default useDueDiligencePersons;
