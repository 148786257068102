import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { DueDiligenceSections } from 'constants/due-diligence';
import {
  DueDiligenceData,
  DueDiligenceDocument,
  DueDiligenceState,
  DueDiligenceLegalMatters,
  MetaPage,
  DueDiligenceAddCommentsReducerPayload,
  DueDiligenceDocumentTypes,
  TruncatedUser,
  DueDiligenceBusinessDetails,
} from 'interfaces';

import {
  deleteDueDiligenceDocument,
  getDueDiligenceDocuments,
  setDueDiligence,
  setDueDiligenceDocumentsFilterValue,
  saveLegalMatters,
  dueDiligenceAddComment,
  setDueDiligenceEditingSections,
  removeDueDiligenceEditingSections,
  getDueDiligenceFoundersForInvite,
  dueDiligenceConfirm,
  dueDiligenceDecline,
  dueDiligenceReopen,
  setDueDiligenceHighlightUnsavedChanges,
  updateDueDiligenceDocumentType,
  getDueDiligenceData,
} from './action';

export function setDueDiligenceReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(setDueDiligence, (state, action: PayloadAction<Partial<DueDiligenceData>>) => ({
    ...state,
    ...action.payload,
  }));
}

export function getDueDiligenceDataReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(getDueDiligenceData.pending, (state) => ({
    ...state,
    companyRunway: null,
    fundraisingDetails: null,
  }));
}

export function setDueDiligenceDocumentsFilterValueReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(setDueDiligenceDocumentsFilterValue, (state, action: PayloadAction<string>) => {
    state.documents.filterValue = action.payload;
  });
}

export function deleteDueDiligenceDocumentReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(deleteDueDiligenceDocument.pending, (state) => {
    state.documents.isLoading = true;
  });

  builder.addCase(deleteDueDiligenceDocument.fulfilled, (state, action: PayloadAction<number>) => {
    state.documents.list = state.documents?.list?.filter(({ id }) => id !== action.payload) || [];
    state.documents.isLoading = false;
  });

  builder.addCase(deleteDueDiligenceDocument.rejected, (state) => {
    state.documents.isLoading = false;
  });
}

export function getDueDiligenceDocumentsReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(getDueDiligenceDocuments.pending, (state) => {
    state.documents.isLoading = true;
    state.documents.list = null;
    state.documents.totalPages = 0;
  });

  builder.addCase(
    getDueDiligenceDocuments.fulfilled,
    (state, action: PayloadAction<{ data: DueDiligenceDocument[]; meta: MetaPage }>) => {
      state.documents.isLoading = false;
      state.documents.list = action.payload.data;
      state.documents.totalPages = action.payload.meta.last_page;
    },
  );

  builder.addCase(getDueDiligenceDocuments.rejected, (state) => {
    state.documents.isLoading = false;
    state.documents.list = null;
    state.documents.totalPages = 0;
  });
}

export function saveDueDiligenceLegalMattersReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(saveLegalMatters.fulfilled, (state, action: PayloadAction<DueDiligenceLegalMatters>) => {
    state.legalMatters = action.payload;
  });
}

export function getDueDiligenceFoundersForInviteReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(getDueDiligenceFoundersForInvite.fulfilled, (state, action: PayloadAction<TruncatedUser[]>) => {
    state.foundersForInvite = action.payload;
  });
}

export function addDueDiligenceCommentReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(
    dueDiligenceAddComment.fulfilled,
    (state, { payload }: PayloadAction<DueDiligenceAddCommentsReducerPayload>) => {
      state.comments[payload.type].unshift(payload.comment);
    },
  );
}

export function setDueDiligenceEditingSectionsReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(setDueDiligenceEditingSections, (state, action: PayloadAction<DueDiligenceSections>) => {
    state.editingSections.push(action.payload);
  });
}

export function removeDueDiligenceEditingSectionsReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(removeDueDiligenceEditingSections, (state, action: PayloadAction<DueDiligenceSections>) => {
    state.editingSections = state.editingSections.filter((section) => section !== action.payload);
  });
}

export function dueDiligenceConfirmReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(dueDiligenceConfirm.fulfilled, (state, action: PayloadAction<DueDiligenceBusinessDetails>) => {
    state.businessDetails = action.payload;
    state.isEditable = false;
  });
}

export function dueDiligenceRejectReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(dueDiligenceDecline.fulfilled, (state, action: PayloadAction<DueDiligenceBusinessDetails>) => {
    state.businessDetails = action.payload;
    state.isEditable = false;
  });
}

export function dueDiligenceReopenReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(dueDiligenceReopen.fulfilled, (state, action: PayloadAction<DueDiligenceBusinessDetails>) => {
    state.businessDetails = action.payload;
    state.isEditable = true;
  });
}

export function dueDiligenceHighlightUnsavedChangesReducer(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(setDueDiligenceHighlightUnsavedChanges, (state, action: PayloadAction<boolean>) => {
    state.highlightUnsavedChanges = action.payload;
  });
}

export function dueDiligenceUpdateDocumentType(builder: ActionReducerMapBuilder<DueDiligenceState>) {
  builder.addCase(updateDueDiligenceDocumentType, (state, action: PayloadAction<DueDiligenceDocumentTypes>) => {
    state.documentTypes = state.documentTypes.map((documentType) => {
      if (documentType.id === action.payload.id) {
        return { ...action.payload };
      }

      return documentType;
    });
  });
}
