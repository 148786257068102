import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { MetaPage, GetListPayload, Founder, CommonMetrics, FoundersCompanies } from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class FoundersApis {
  constructor(private url: string) {}

  getFounders(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Founder[]; meta: MetaPage }>(
      `${this.url}/founders?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getFounder(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Founder }>(`${this.url}/founders/${id}`, config);
  }

  getMetrics(rangeFilter: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CommonMetrics>(`${this.url}?filter=${rangeFilter}`, config);
  }

  getFounderCompanies(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: FoundersCompanies[] }>(`${this.url}/founders/${id}/companies`, config);
  }

  setFoundersConfirmedCompanies(founderId: string, companyId: string, confirmed: boolean, config?: AxiosRequestConfig) {
    return httpApiClient.patch<FoundersCompanies>(
      `${this.url}/founders/${founderId}/companies/${companyId}/confirm`,
      { confirmed },
      config,
    );
  }
}

export const adminFoundersApis = new FoundersApis('/admin');
export const adminFoundersMetricsApis = new FoundersApis('/admin/metrics/founder');
