import React from 'react';

import { COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME } from 'constants/company-iac';

const COMPANY_IAC_SHARE_PRICE_FIELDS_DESCRIPTION = 'e.g., 0.0001';

const CompanyIacSharePriceDescription = () => {
  return (
    <div className='flex'>
      <div className={COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.CLASS_OF_SHARES} />
      <div className={`${COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.DENOMINATION} text-grey`}>
        ({COMPANY_IAC_SHARE_PRICE_FIELDS_DESCRIPTION})
      </div>
      <div className={`${COMPANY_IAC_SHARE_PRICE_FIELDS_COMMON_CLASSNAME.SHARE_PRICE} text-grey`}>
        ({COMPANY_IAC_SHARE_PRICE_FIELDS_DESCRIPTION})
      </div>
    </div>
  );
};

export default CompanyIacSharePriceDescription;
