import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { NotificationsResponse, NotificationState, UserNotification } from 'interfaces';

import { getNotifications, updateNotification } from './action';

export function getNotificationsReducer(builder: ActionReducerMapBuilder<NotificationState>) {
  builder.addCase(getNotifications.pending, (state) => {
    state.notificationList.isLoading = true;
  });

  builder.addCase(getNotifications.fulfilled, (state, action: PayloadAction<NotificationsResponse>) => {
    state.notificationList.isLoading = false;
    state.notificationList.list = action.payload.data;
    state.notificationList.totalPages = action.payload.meta.last_page;
    state.notificationList.total = action.payload.meta.total;
  });

  builder.addCase(getNotifications.rejected, (state) => {
    state.notificationList.isLoading = false;
  });
}
export function updateNotificationReducer(builder: ActionReducerMapBuilder<NotificationState>) {
  builder.addCase(updateNotification.fulfilled, (state, { payload }: PayloadAction<UserNotification>) => {
    state.notificationList.list =
      state.notificationList?.list?.map((notification) => ({
        ...notification,
        ...(notification.id === payload.id ? payload : {}),
      })) ?? null;
  });
}
