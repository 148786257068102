import { useEffect, useState } from 'react';

import { Control, FieldErrors, useForm, UseFormHandleSubmit, UseFormRegister, UseFormTrigger } from 'react-hook-form';

import { DueDiligenceSections } from 'constants/due-diligence';
import { BusinessDetails, BusinessDetailsFieldForEdit, CompanyNatureOfBusiness } from 'interfaces';
import { editDueDiligenceBusinessDetails } from 'modules/due-diligence/action';
import { selectBusinessDetails } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { checkIfValuesTheSame } from 'utils';

import useDueDiligenceEdit from './use-due-diligence-edit/useDueDiligenceEdit';

const BUSINESS_DETAILS_DEFAULT_FORM_VALUES = {
  businessName: '',
  registeredNumber: '',
  businessSector: '',
  dateOfIncorporation: '',
  registeredOffice: '',
  phone: '+44',
  website: '',
  approvalForSecondarySale: null,
};

export interface UseDueDiligenceBusinessDetailsReturnValues {
  isLoading: boolean;
  isEditing: boolean;
  handleEdit: VoidFunction;
  handleSaveField: any;
  handleDiscardChanges: VoidFunction;
  handleSubmit: UseFormHandleSubmit<BusinessDetails>;
  register: UseFormRegister<BusinessDetails>;
  control: Control<BusinessDetails>;
  errors: FieldErrors<BusinessDetails>;
  trigger: UseFormTrigger<BusinessDetails>;
  natureOfBusiness: CompanyNatureOfBusiness[] | undefined;
}

const UseDueDiligenceBusinessDetails = ({
  isCalled,
  isReSubmitted,
}: {
  isCalled: boolean;
  isReSubmitted?: boolean;
}): UseDueDiligenceBusinessDetailsReturnValues => {
  const dispatch = useAppDispatch();
  const { natureOfBusiness, noOfMonthsCashRunway, ...formValues } = useAppSelector(selectBusinessDetails) || {};

  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isEditing, handleEdit, handleCancel } = useDueDiligenceEdit(DueDiligenceSections.BUSINESS_DETAILS);

  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<BusinessDetails>({
    defaultValues: BUSINESS_DETAILS_DEFAULT_FORM_VALUES,
  });

  const handleSaveField = async (name: keyof BusinessDetailsFieldForEdit) => {
    const isValidatedCorrectly = await trigger(name);
    const fieldValue = getValues(name);

    const currentValue = (formValues as BusinessDetailsFieldForEdit)[name];

    const isValueNotUpdated = typeof fieldValue === 'boolean' ? false : checkIfValuesTheSame(fieldValue, currentValue);

    if (!isValidatedCorrectly || isValueNotUpdated || (!fieldValue && typeof fieldValue !== 'boolean' && !currentValue))
      return;

    setIsLoading(true);

    dispatch(editDueDiligenceBusinessDetails({ id: formValues?.id, [name]: fieldValue }))
      .unwrap()
      .then(handleCancel)
      .finally(() => setIsLoading(false));
  };

  const handleDiscardChanges = () => {
    reset(formValues);
    handleCancel();
  };

  useEffect(() => {
    // Business name is defined during loading page, so we need to filter it out
    const hasFormValues = Object.keys(formValues).filter((key) => key !== 'businessName')?.length;

    if (!isComponentMounted && !isLoaded && hasFormValues) {
      return;
    }
    if (isCalled && !isLoaded && hasFormValues) {
      reset(formValues);
      setIsLoaded(true);
    }
  }, [isComponentMounted, reset, formValues, isLoaded, isCalled]);

  // Update form values after re-submit function
  useEffect(() => {
    if (!isReSubmitted) return;

    reset(formValues);
  }, [reset, isReSubmitted]);

  useEffect(() => {
    if (!isCalled && !isLoaded) {
      reset(BUSINESS_DETAILS_DEFAULT_FORM_VALUES);
    }
  }, [isCalled, isLoaded, reset]);

  useEffect(() => {
    setIsComponentMounted(true);
  }, [isComponentMounted]);

  return {
    isLoading,
    isEditing,
    handleEdit,
    handleSaveField,
    handleDiscardChanges,
    handleSubmit,
    register,
    control,
    errors,
    natureOfBusiness,
    trigger,
  };
};

export default UseDueDiligenceBusinessDetails;
