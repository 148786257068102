import { useEffect, useState } from 'react';

import { Control, FieldErrors, useForm, UseFormRegister } from 'react-hook-form';

import { DueDiligenceSections } from 'constants/due-diligence';
import { PreferenceRightsInput } from 'interfaces';
import { editDueDiligenceBusinessDetails } from 'modules/due-diligence/action';
import { selectDebtPreferenceRights } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import useDueDiligenceEdit from './use-due-diligence-edit/useDueDiligenceEdit';

const PREFERENCE_RIGHTS_DEFAULT_VALUES = {
  debtPreferenceRights: '',
};

export interface UsePreferenceRightsReturnValues {
  isEditing: boolean;
  isLoading: boolean;
  handleSaveField: (name: keyof PreferenceRightsInput) => void;
  handleDiscardChanges: VoidFunction;
  register: UseFormRegister<PreferenceRightsInput>;
  control: Control<PreferenceRightsInput>;
  errors: FieldErrors<PreferenceRightsInput>;
}

const usePreferenceRights = (): UsePreferenceRightsReturnValues => {
  const [isLoading, setIsLoading] = useState(false);
  const { isEditing, handleCancel } = useDueDiligenceEdit(DueDiligenceSections.DEBT_PREFERENCE_RIGHTS);

  const dispatch = useAppDispatch();
  const { id, debtPreferenceRights } = useAppSelector(selectDebtPreferenceRights);

  const {
    register,
    control,
    trigger,
    reset,
    getValues,
    formState: { errors },
  } = useForm<PreferenceRightsInput>({
    defaultValues: PREFERENCE_RIGHTS_DEFAULT_VALUES,
  });

  const handleSaveField = async (name: string) => {
    const isValidatedCorrectly = await trigger(name as keyof PreferenceRightsInput);

    const fieldValue = getValues(name as keyof PreferenceRightsInput);

    if (!isValidatedCorrectly || fieldValue === debtPreferenceRights) return;

    setIsLoading(true);
    dispatch(
      editDueDiligenceBusinessDetails({
        id,
        debtPreferenceRights: fieldValue,
      }),
    )
      .unwrap()
      .then(handleCancel)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDiscardChanges = () => {
    handleCancel();
    reset({ debtPreferenceRights: '' });
  };

  useEffect(() => {
    reset({ debtPreferenceRights: debtPreferenceRights || '' });
  }, [id, debtPreferenceRights, reset]);

  return {
    isEditing,
    isLoading,
    handleSaveField,
    handleDiscardChanges,
    register,
    control,
    errors,
  };
};

export default usePreferenceRights;
