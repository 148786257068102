import { useLayoutEffect } from 'react';

const useLockBodyScroll = (locked: boolean): void => {
  useLayoutEffect((): (() => void) => {
    const originalStyle: string = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = locked ? 'hidden' : 'visible';

    return () => (document.body.style.overflow = originalStyle);
  }, [locked]);
};
export default useLockBodyScroll;
