import { useState } from 'react';

import { CustomHookWithActionsReturnValues } from 'interfaces';
import { selectIsAdmin } from 'modules/current-user/selectors';
import { deleteDeal, deleteDeals } from 'modules/deals/action';
import { useAppDispatch, useAppSelector } from 'modules/store';

import useModal from '../use-modal/useModal';

const useDeleteDeal = ({ onSuccess }: { onSuccess?: VoidFunction }): CustomHookWithActionsReturnValues => {
  const dispatch = useAppDispatch();

  const modalProps = useModal();

  const isAdmin = useAppSelector(selectIsAdmin);

  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteDeal = (payload: number | number[]) => {
    setIsLoading(true);

    (Array.isArray(payload)
      ? dispatch(deleteDeals({ dealsIds: payload, isAdmin }))
      : dispatch(deleteDeal({ id: payload, isAdmin }))
    )
      .unwrap()
      .then(onSuccess)
      .finally(() => {
        setIsLoading(false);
        modalProps.onClose();
      });
  };
  return [handleDeleteDeal, modalProps, isLoading];
};

export default useDeleteDeal;
