import React, { FC } from 'react';

import { SHARE_EXCHANGE_REVIEW_SECTION_TITLES } from 'constants/share-exchange';
import { SnippetLayout } from 'shared-components';

type Props = {
  onClickViewAnswers: VoidFunction;
};

const ShareExchangeReviewAnswersSection: FC<Props> = ({ onClickViewAnswers }) => {
  return (
    <SnippetLayout className='mt-6' title={SHARE_EXCHANGE_REVIEW_SECTION_TITLES.VIEW_ANSWERS}>
      <button onClick={onClickViewAnswers} className='text-sm'>
        View Answers
      </button>
    </SnippetLayout>
  );
};

export default ShareExchangeReviewAnswersSection;
