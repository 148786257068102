import { useState } from 'react';

import { InvitePreviewModalParams } from 'interfaces';
import { getCompaniesInviteEmailTemplate } from 'modules/companies/action';
import { selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import { getMockedCompaniesInviteEmailTemplate } from '../../constants/company-invite-investors-pdf-template';
import useModal, { UseModalReturnValues } from '../use-modal/useModal';

export const useInviteInvestorsPreviewEmail = (): [
  Omit<UseModalReturnValues, 'onOpen'>,
  (params: InvitePreviewModalParams) => void,
  boolean,
  (value: boolean) => void,
] => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(selectUserType);

  const [isLoading, setIsLoading] = useState(false);

  const { onOpen: onOpenInvitePreviewModal, ...invitePreviewModalState } = useModal();

  const handleSetIsLoading = (value: boolean) => setIsLoading(value);

  const handleOpenInvitePreviewModal = ({ shouldBeMocked, companyName, ...params }: InvitePreviewModalParams) => {
    if (shouldBeMocked) {
      onOpenInvitePreviewModal({
        templates: getMockedCompaniesInviteEmailTemplate(companyName, params?.companyId),
        ...params,
      });
      return;
    }

    setIsLoading(true);
    dispatch(
      getCompaniesInviteEmailTemplate({
        companyId: params?.companyId,
        userType,
        shareholderId: params.selectedInvestors[0]?.id,
      }),
    )
      .unwrap()
      .then((response) => {
        onOpenInvitePreviewModal({ templates: response, ...params });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [invitePreviewModalState, handleOpenInvitePreviewModal, isLoading, handleSetIsLoading];
};
