import React from 'react';

import { AUDIT_LOG_TABLE_COLUMNS } from 'constants/audit-log';
import { MetaState, UpdateSortingFunctionType } from 'interfaces';
import { AuditLog } from 'interfaces/audit-logs.interfaces';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import AuditLogsTableItem from '../due-diligence/audit-logs-table/AuditLogsTableItem';
import NoAuditLogsAdded from '../due-diligence/audit-logs-table/NoAuditLogsAdded';

type Props = {
  isLoading?: boolean;
  pagination: MetaState & { page: number; setPage: (page: number) => void };
  handleFilter: (search: string) => void;
  handleOpenAuditInfoModal: (id: number) => void;
  updateSorting: UpdateSortingFunctionType;
  auditLogs: AuditLog[] | null;
};

const AuditLogsCommonLayout = ({
  pagination: { total, totalPages, page, setPage },
  isLoading,
  auditLogs,
  handleFilter,
  updateSorting,
  handleOpenAuditInfoModal,
}: Props) => {
  return (
    <>
      <SearchForm
        className='w-full md:w-7/12 mb-12 items-center flex-col xs:flex-row gap-4 xs:gap-0'
        placeholder='Search by Entity Id, Entity'
        handleFilter={handleFilter}
      />
      <Table onUpdateSorting={updateSorting} columns={AUDIT_LOG_TABLE_COLUMNS}>
        {!isLoading && auditLogs?.length ? (
          auditLogs.map((auditLog) => (
            <AuditLogsTableItem auditLog={auditLog} handleOpenModal={handleOpenAuditInfoModal} key={auditLog.id} />
          ))
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={AUDIT_LOG_TABLE_COLUMNS.length} />
        ) : (
          <NoAuditLogsAdded />
        )}
      </Table>
      <Pagination loading={isLoading} currentPage={page} totalPages={totalPages} total={total} updatePage={setPage} />
    </>
  );
};

export default AuditLogsCommonLayout;
