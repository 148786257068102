import React, { useEffect } from 'react';

import qs from 'query-string';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import {
  LINK_FORGET_PASSWORD_DATA_TEST_ID,
  LOGIN_BUTTON_DATA_TEST_ID,
  MULTI_SIGN_IN_FLOWS,
  SIGN_IN_FIELDS,
  SIGN_IN_FLOWS,
  SIGN_IN_PAGE_ADDITIONAL_TEXT,
  SIGN_IN_PAGE_LINK_TEXT,
  SIGN_IN_PAGE_TITLE,
  SignInFieldNames,
} from 'constants/sign-in';
import { UserTypes } from 'constants/user';
import { PreviousLocationState, SignInInput } from 'interfaces';
import { asyncSignIn } from 'modules/auth/action';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { getLastDraftDealPredefinedData } from 'modules/deals/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo } from 'page-components';
import { Button, FormContent, LinkButton } from 'shared-components';
import { getInvestmentsStartShareExchange } from 'utils';

import { useIsAlreadyRegistered } from '../hooks/useIsAlreadyRegistered';

const SignIn = () => {
  const dispatch = useAppDispatch();
  const isActionLoading = useAppSelector(selectIsActionLoading);
  const location = useLocation();
  const navigate = useNavigate();

  const { email, company_id: companyId } = qs.parse(location?.search);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<SignInInput>();

  const watchEmailField = watch(SignInFieldNames.EMAIL);

  const { isAlreadyRegisteredData, handleCheckIsAlreadyRegistered } = useIsAlreadyRegistered();

  const { currentEmail } = isAlreadyRegisteredData || {};

  const isDisabledSubmitButton = currentEmail ? watchEmailField !== currentEmail : false;

  const onSubmit: SubmitHandler<SignInInput> = async (data) => {
    const { isInvestor, isFounder } = (await handleCheckIsAlreadyRegistered(data?.email)) ?? {};

    dispatch(asyncSignIn({ ...data, companyId: companyId ? Number(companyId) : undefined }))
      .unwrap()
      .then(async (userType: UserTypes) => {
        const isMultiSignIn = isInvestor && isFounder;

        if (isMultiSignIn) {
          navigate(`${ROUTES.whoAreYou}?flow=${MULTI_SIGN_IN_FLOWS.SIGN_IN}`);
          return;
        }

        const previousLocation = (location.state as PreviousLocationState)?.from;
        const previousLocationSearch = (location.state as PreviousLocationState)?.search || '';

        if (previousLocation) {
          navigate(`${previousLocation}${previousLocationSearch}`);
          return;
        }

        const { flow, company_id: companyId } = qs.parse(location.search);

        if (userType === UserTypes.INVESTOR) {
          const draftDeal = await dispatch(getLastDraftDealPredefinedData()).unwrap();
          if (draftDeal) {
            navigate(`${ROUTES.myInvestments}?tab=investments&startShareExchange=true`);
            return;
          }
        }

        if (userType === UserTypes.INVESTOR && flow === SIGN_IN_FLOWS.CREATE_SHARE_EXCHANGE && companyId) {
          navigate(getInvestmentsStartShareExchange(companyId as string, false));
          return;
        }
      });
  };

  useEffect(() => {
    if (email) reset({ email: email as string });
  }, [email, reset]);

  return (
    <AuthLayout>
      <HeaderInfo title={SIGN_IN_PAGE_TITLE} linkPath={ROUTES.whoAreYou} linkText={SIGN_IN_PAGE_LINK_TEXT}>
        {SIGN_IN_PAGE_ADDITIONAL_TEXT}
      </HeaderInfo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative'>
          <FormContent fields={SIGN_IN_FIELDS} register={register} errors={errors} />

          <LinkButton
            data-testid={LINK_FORGET_PASSWORD_DATA_TEST_ID}
            className='absolute bottom-28 right-0 text-grey-800'
            variant={ButtonVariants.UNDERLINED_LINK}
            to={ROUTES.forgotPassword}
          >
            Forgot?
          </LinkButton>
        </div>
        <Button
          data-testid={LOGIN_BUTTON_DATA_TEST_ID}
          className='mt-14'
          disabled={isDisabledSubmitButton}
          isLoading={isActionLoading}
        >
          Login <ArrowRight className='ml-2' />
        </Button>
      </form>
    </AuthLayout>
  );
};

export default SignIn;
