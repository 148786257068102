import React from 'react';

const CompanyDetailsSkeleton = () => {
  return (
    <div>
      <div className='skeleton-loader h-8' />
      <div className='skeleton-loader h-11 mt-1.5' />
      <div className='bg-grey-200 w-full h-px mt-6 mb-9' />
      <div className='skeleton-loader h-[200px] mt-' />
    </div>
  );
};

export default CompanyDetailsSkeleton;
