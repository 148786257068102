import { useEffect, RefObject } from 'react';
type Event = MouseEvent | TouchEvent;

const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T> | null,
  handler: (event: Event) => void,
  isOpen: boolean,
  preventOutsideClick = false,
): void => {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;

      if (!isOpen || !el || el.contains(event?.target as Node)) {
        return;
      }

      if (!preventOutsideClick) {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, preventOutsideClick]);
};
export default useOutsideClick;
