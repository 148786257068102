import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectState = (x: RootState) => x.fundsReducer;

export const selectFundsListData = createSelector(selectState, ({ list, total, totalPages, isLoading }) => ({
  list,
  total,
  totalPages,
  isLoading,
}));
