import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  REOPEN_DUE_DILIGENCE_FORM_FIELDS,
  REOPEN_DUE_DILIGENCE_MODAL_TITLE,
  REOPEN_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT,
} from 'constants/due-diligence';
import { DueDiligenceReopenFieldNames, DueDiligenceReopenFormState } from 'interfaces';
import { selectBusinessName } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { AlertModal, FormContent } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading: boolean;
  onReopenDueDiligence: (reason: string) => void;
};

const ReopenDueDiligenceModal: FC<Props> = ({ isOpen, onClose, isLoading, onReopenDueDiligence }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm<DueDiligenceReopenFormState>();

  const businessName = useAppSelector(selectBusinessName);

  const onSubmit: SubmitHandler<DueDiligenceReopenFormState> = ({ clearReasonSelect }) => {
    if (!clearReasonSelect) {
      return;
    }

    const clearReason = clearReasonSelect.value;

    onReopenDueDiligence(clearReason);
  };

  const onCloseModal = () => {
    reset({ [DueDiligenceReopenFieldNames.CLEAR_REASON_SELECT]: null });
    onClose();
  };

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onCloseModal}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      title={`${REOPEN_DUE_DILIGENCE_MODAL_TITLE} ${businessName || ''}?`}
      submitButtonText={REOPEN_DUE_DILIGENCE_SUBMIT_BUTTON_TEXT}
      modalClassName='lg:w-8/12 xl:w-6/12'
      contentClassName='lg:px-28 lg:py-12'
      wrapperModalClassName='lg:py-0 lg:px-0'
    >
      <form className='w-full mt-6'>
        <FormContent fields={REOPEN_DUE_DILIGENCE_FORM_FIELDS} register={register} errors={errors} control={control} />
      </form>
    </AlertModal>
  );
};

export default ReopenDueDiligenceModal;
