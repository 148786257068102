import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

interface Props {
  entityName: string;
}

const NoCompaniesInvestments = ({ entityName }: Props) => {
  return (
    <TableRow>
      <TableData colSpan={9}>No {entityName} found</TableData>
    </TableRow>
  );
};

export default NoCompaniesInvestments;
