import React from 'react';

const AuditLogsModalPreloader = () => {
  return (
    <div>
      <div className='skeleton-loader h-8 w-full' />
      <div className='flex flex-col xl:flex-row mt-5'>
        <div className='skeleton-loader h-[300px] w-full lg:w-full xl:w-4/12 xl:mr-6' />
        <div className='skeleton-loader h-[300px] w-full lg:w-full xl:w-8/12 mt-6 xl:mt-0' />
      </div>
    </div>
  );
};

export default AuditLogsModalPreloader;
