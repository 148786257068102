import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { ShareExchangeSubmittedAnswers } from 'interfaces';
import { Button } from 'shared-components';

import ShareExchangeQualificationQuestionsList from '../ShareExchangeQualificationQuestionsList';

type Props = {
  answers: ShareExchangeSubmittedAnswers[];
  onClose: VoidFunction;
};

const ShareExchangeReviewQualificationAnswersModalContent: FC<Props> = ({ answers, onClose }) => {
  return (
    <div className='mt-10'>
      <ShareExchangeQualificationQuestionsList answers={answers} />

      <Button className='mt-20' variant={ButtonVariants.SECONDARY} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};

export default ShareExchangeReviewQualificationAnswersModalContent;
