import { ChangeEvent, useEffect, useState } from 'react';

import { Control, FieldErrors, useForm, UseFormRegister, UseFormTrigger, UseFormReset, Path } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { CompanyIac, CompanyIacCustomField, CompanyIacSummaryFormState } from 'interfaces';
import { updateCompanyIac } from 'modules/company-iac/action';
import { selectCompanyIacId, selectCompanyIacSummary, selectCustomFields } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { checkIfValuesTheSame } from 'utils';
import { getCustomFieldsValues } from 'utils/companies-iac';

import useCompanyIacCustomFields, {
  UseCompanyIacCustomFieldsReturnValues,
} from './use-company-iac-custom-fields/useCompanyIacCustomFields';
import useCompanyIacEdit, { UseCompanyIacEditReturnValues } from './useCompanyIacEdit';

export type UseCompanyIacSummaryReturnValues = {
  isLoading: boolean;
  register: UseFormRegister<CompanyIacSummaryFormState>;
  control: Control<CompanyIacSummaryFormState>;
  reset: UseFormReset<CompanyIacSummaryFormState>;
  errors: FieldErrors<CompanyIacSummaryFormState>;
  trigger: UseFormTrigger<CompanyIacSummaryFormState>;
  handleSaveEditionCustomField: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
  handleSaveEditionField: (name: Path<CompanyIacSummaryFormState>) => Promise<void>;
  isHideActionButtons: boolean;
  customFieldsData: Omit<
    UseCompanyIacCustomFieldsReturnValues,
    'handleSaveEditionCustomField' | 'reset' | 'resetDeletedFields'
  >;
} & Pick<UseCompanyIacEditReturnValues, 'isEditing'>;

const UseCompanyIacSummary = (): UseCompanyIacSummaryReturnValues => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { id: companyId } = useParams();

  const companyIacId = useAppSelector(selectCompanyIacId);
  const summaryFields = useAppSelector(selectCompanyIacSummary);
  const summaryCustomFields = useAppSelector(selectCustomFields(InvestmentProposalBlockNames.SUMMARY));

  const { isHideActionButtons, isEditing } = useCompanyIacEdit(InvestmentProposalBlockNames.SUMMARY);

  const {
    register,
    control,
    reset,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<CompanyIacSummaryFormState>();

  const updatedCustomSummaryFields = (payloadData: CompanyIacCustomField[]) => {
    if (!companyIacId || !companyId) {
      return;
    }

    dispatch(
      updateCompanyIac({
        companyId,
        id: companyIacId,
        data: { summaryCustomInputs: payloadData },
      }),
    )
      .unwrap()
      .finally(() => setIsLoading(false));
  };

  const updateSummaryField = (payloadData: Partial<CompanyIac>) => {
    if (!companyIacId || !companyId) {
      return;
    }

    dispatch(
      updateCompanyIac({
        companyId,
        id: companyIacId,
        data: payloadData,
      }),
    )
      .unwrap()
      .finally(() => setIsLoading(false));
  };

  const {
    handleSaveEditionCustomField,
    reset: resetCustomFields,
    ...customFieldsData
  } = useCompanyIacCustomFields(summaryCustomFields, updatedCustomSummaryFields);

  const handleSaveEditionField = async (name: Path<CompanyIacSummaryFormState>) => {
    const isValidatedCorrectly = await trigger(name);
    const fieldValue = getValues(name);
    const currentValue = (summaryFields as CompanyIacSummaryFormState)[name];

    const isValueNotUpdated = checkIfValuesTheSame(fieldValue, currentValue);

    if (!isValidatedCorrectly || isValueNotUpdated || (!fieldValue && !currentValue)) {
      return;
    }

    setIsLoading(true);

    updateSummaryField({ [name]: fieldValue });
  };

  useEffect(() => {
    if (summaryCustomFields) {
      resetCustomFields(getCustomFieldsValues(summaryCustomFields));
    }
  }, [resetCustomFields, summaryCustomFields]);

  useEffect(() => {
    if (!summaryFields) {
      return;
    }

    reset({ ...summaryFields });
  }, [summaryFields, reset]);

  return {
    isLoading,
    register,
    control,
    errors,
    trigger,
    handleSaveEditionCustomField,
    handleSaveEditionField,
    isHideActionButtons,
    isEditing,
    customFieldsData,
    reset,
  };
};

export default UseCompanyIacSummary;
