import React, { PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import Select from 'react-select';

import { Option } from 'interfaces';
import { FormLabel } from 'shared-components/index';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: string;
  label: string;
  className?: string;
  validationRules?: RegisterOptions;
  isClearable?: boolean;
  onClearCallback?: VoidFunction;
  isLoadingOptions?: boolean;
  options: Option[];
}

const SelectCompanySyncInput = <T extends FieldValues>({
  control,
  className,
  name,
  label,
  validationRules,
  isClearable,
  onClearCallback,
  isLoadingOptions,
  options,
}: PropsWithChildren<Props<T>>): ReactElement => {
  return (
    <Controller
      name={name as Path<T>}
      control={control}
      rules={validationRules}
      render={({ field: { ref, onChange, value, ...field }, fieldState: { error } }) => (
        <div className={className}>
          {label && <FormLabel>{label}</FormLabel>}

          <Select
            {...field}
            value={value as Option}
            onChange={(selectedOption, triggeredAction) => {
              if (triggeredAction.action === 'clear' && onClearCallback) {
                onClearCallback();
              }

              onChange(selectedOption);
            }}
            options={options}
            isClearable={isClearable}
            noOptionsMessage={() => (isLoadingOptions ? 'Loading...' : 'No Companies Found')}
            className={cn('select-input', { 'select-input-error': error?.message })}
            placeholder='Company Name'
          />
        </div>
      )}
    />
  );
};

export default SelectCompanySyncInput;
