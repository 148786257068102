import { FormItem } from 'interfaces';
import { PrivacyPolicyLabel } from 'page-components';
import { Input, Checkbox } from 'shared-components';

import { PASSWORD_PATTERN } from './global';
import { FieldTypes } from './shared';

export const FINISH_REGISTRATION_PAGE_TITLE = 'Finish Registration';

export const FINISH_REGISTRATION_PAGE_ADDITIONAL_TEXT = 'Set a secure password for your account';

export const FINISH_REGISTRATION_SUBMIT_BUTTON = 'Next';

export enum FinishRegistrationFieldNames {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'password_confirmation',
  POLICY = 'policy',
}

export enum FinishRegistrationFieldTestIds {
  PASSWORD = 'PASSWORD_TEST_ID',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD_TEST_ID',
}

export const FINISH_REGISTRATION_BUTTON_TEST_ID = 'FINISH_REGISTRATION_BUTTON_TEST_ID';

export const getFinishRegistrationFields = (currentPassword: string): FormItem[] => [
  {
    name: FinishRegistrationFieldNames.PASSWORD,
    label: 'Set Password',
    placeholder: 'Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input Password to continue',
      pattern: {
        message: 'Password should match all requirements',
        value: PASSWORD_PATTERN,
      },
    },
    className: 'col-span-2 sm:col-span-1',
    dataTestId: FinishRegistrationFieldTestIds.PASSWORD,
  },
  {
    name: FinishRegistrationFieldNames.CONFIRM_PASSWORD,
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    type: FieldTypes.PASSWORD,
    component: Input,
    validation: {
      required: 'Required information missing please input Confirmation Password to continue',
      validate: (confirmPassword: string) =>
        currentPassword === confirmPassword || 'Password should be equal to Confirm Password',
    },
    className: 'col-span-2 sm:col-span-1',
    dataTestId: FinishRegistrationFieldTestIds.CONFIRM_PASSWORD,
  },
  {
    name: FinishRegistrationFieldNames.POLICY,
    label: PrivacyPolicyLabel,
    type: FieldTypes.CHECKBOX,
    dataTestId: FINISH_REGISTRATION_BUTTON_TEST_ID,
    component: Checkbox,
    validation: {
      required: 'You should agree to the Policy and Terms & conditions',
    },
    className: 'col-start-1 col-end-3 mb-6',
  },
];

export const FINISH_REGISTRATION_AUTH_LAYOUT_CHILDREN_CLASSNAMES = 'relative h-[calc(100vh-74px)] max-w-630';
