import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

type Props = {
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  disabledConfirm: boolean;
  isLoading: boolean;
};

const StartInvestmentShareExchangeModalButtons: FC<Props> = ({ onConfirm, onCancel, disabledConfirm, isLoading }) => {
  return (
    <div className='w-full flex justify-between mt-10 gap-4 sm:gap-0'>
      <Button variant={ButtonVariants.SECONDARY} className='w-150' onClick={onCancel}>
        Cancel
      </Button>

      <Button
        isLoading={isLoading}
        variant={ButtonVariants.PRIMARY}
        className='w-300'
        onClick={onConfirm}
        disabled={disabledConfirm}
      >
        Submit Request
      </Button>
    </div>
  );
};

export default StartInvestmentShareExchangeModalButtons;
