import React, { useEffect, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { INVESTOR_PAGE_TABS_IDS, InvestorProfileInfo } from 'constants/investors';
import { ConstraintVariants } from 'constants/shared';
import { TypographyVariants } from 'constants/shared/typography';
import { TabItem } from 'interfaces';
import { getInvestor, getInvestorFundsShares } from 'modules/investors/action';
import { selectInvestor, selectInvestorsFundsShares } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout, InvestorInvoices, InvestorDeals, UserInfo, UserPageSkeleton } from 'page-components';
import { Constraint, Tabs } from 'shared-components';

import InvestorInfo from '../page-components/investors/InvestorInfo';
import InvestorInvestments from '../page-components/investors/InvestorInvestments';
import Typography from '../shared-components/Typography';

const INVESTOR_PAGE_TABS: TabItem[] = [
  { id: INVESTOR_PAGE_TABS_IDS.INVESTMENTS, name: 'Deals', component: InvestorDeals },
  { id: INVESTOR_PAGE_TABS_IDS.DEALS, name: 'Investments', component: InvestorInvestments },
  { id: INVESTOR_PAGE_TABS_IDS.INVOICES, name: 'Invoices', component: InvestorInvoices },
];

const Investor = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { data: investor, isLoading } = useAppSelector(selectInvestor);
  const { list: fundShares } = useAppSelector(selectInvestorsFundsShares);

  const [daxiaFundShares, setDaxiaFundShares] = useState<number | null>();

  useEffect(() => {
    if (id) {
      dispatch(getInvestor(id));
      dispatch(getInvestorFundsShares({ id, page: 1, per_page: 1000 }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (fundShares?.length) {
      setDaxiaFundShares(fundShares?.find(({ fundName }) => fundName === 'DAXIA LIMITED')?.totalShares);
    }
  }, [fundShares]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        {isLoading ? <UserPageSkeleton /> : <InvestorInfo investor={investor} daxiaFundShares={daxiaFundShares} />}
        <Tabs tabs={INVESTOR_PAGE_TABS} />
      </Constraint>
    </DashboardLayout>
  );
};

export default Investor;
