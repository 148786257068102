import React, { FC, useEffect, useState } from 'react';

import { SubmitHandler, useFieldArray, useForm, UseFormReset } from 'react-hook-form';

import { START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS } from 'constants/share-exchange';
import {
  Option,
  StartShareExchangesInvestmentItem,
  StartShareExchangesInvestmentsFormValues,
  StartShareExchangeWatchValues,
} from 'interfaces';
import { selectCompanyDealPredefinedData } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';

import { selectUserEntityData } from '../../../modules/current-user/selectors';
import ShareExchangeDetailsCommonFields from '../../deals/share-exchanges/ShareExchangeDetailsCommonFields';
import StartInvestmentShareExchangeModalButtons from './StartInvestmentShareExchangeModalButtons';

interface Props {
  onClose: VoidFunction;
  onSuccessStartShareExchange: (
    shareExchanges: StartShareExchangesInvestmentItem[],
    companyId: number,
    loadingCallback: VoidFunction,
    reset: UseFormReset<StartShareExchangesInvestmentsFormValues>,
    investmentIds?: number[],
    draftDealId?: number,
  ) => void;
}

const StartInvestmentShareExchangeModalTable: FC<Props> = ({ onClose, onSuccessStartShareExchange }) => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const companyDealPredefinedData = useAppSelector(selectCompanyDealPredefinedData);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm<StartShareExchangesInvestmentsFormValues>({
    defaultValues: {
      exchanges: [],
    },
  });

  const {
    fields: dealsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'exchanges',
  });

  const watchExchanges = watch('exchanges');

  const handleSuccessLoadingCallback = () => {
    setIsLoading(false);
  };

  const onConfirmExchanges: SubmitHandler<StartShareExchangesInvestmentsFormValues> = ({ exchanges }) => {
    if (!companyDealPredefinedData?.companyId) return;

    setIsLoading(true);

    onSuccessStartShareExchange(
      exchanges,
      companyDealPredefinedData?.companyId,
      handleSuccessLoadingCallback,
      reset,
      companyDealPredefinedData?.investmentIds,
      companyDealPredefinedData?.draftDealId,
    );
  };

  const getFields = (
    watchValues: StartShareExchangeWatchValues,
    handleSetCompanyPrice: VoidFunction,
    classOfSharesOptions: Option[],
    closingDatesOptions: Option[],
    dealIndex: number,
  ) =>
    START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS(
      dealIndex,
      setValue,
      watchValues,
      classOfSharesOptions,
      closingDatesOptions,
      handleSetCompanyPrice,
      isTypeEntity,
      entityName,
    );

  useEffect(() => {
    if (!companyDealPredefinedData?.companyName) return;

    const { companySharePrice, ...dealPredefinedData } = companyDealPredefinedData;

    const [{ companyPrice }] = companySharePrice || [];

    reset({
      exchanges: [
        {
          ...dealPredefinedData,
          companyPrice,
        },
      ],
    });
  }, [reset, companyDealPredefinedData]);

  if (!companyDealPredefinedData) return <div className='skeleton-loader h-64 w-full' />;

  return (
    <div className='flex flex-col' id='table-test'>
      <ShareExchangeDetailsCommonFields
        getFields={getFields}
        dealsFields={dealsFields}
        shareExchanges={watchExchanges}
        companyDealPredefinedData={companyDealPredefinedData}
        register={register}
        control={control}
        watch={watch}
        errors={errors}
        setValue={setValue}
        append={append}
        remove={remove}
      />

      <StartInvestmentShareExchangeModalButtons
        onCancel={onClose}
        onConfirm={handleSubmit(onConfirmExchanges)}
        disabledConfirm={isLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default StartInvestmentShareExchangeModalTable;
