import React from 'react';

import { INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS } from 'constants/investors';
import { TableData, TableRow } from 'shared-components/table';

const InvestorDoneShareExchangesSnippetNoInfo = () => {
  return (
    <TableRow>
      <TableData colSpan={INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS.length} className='text-center'>
        No Investors with done Share Exchanges found
      </TableData>
    </TableRow>
  );
};

export default InvestorDoneShareExchangesSnippetNoInfo;
