import React, { FC } from 'react';

import { TableRowVariants } from 'constants/shared';
import { CompanyHolder } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

const CompanyProfileHoldersItem: FC<CompanyHolder> = ({ name }) => {
  return (
    <TableRow variant={TableRowVariants.SIMPLIFIED}>
      <TableData className='pl-2'>{name}</TableData>
    </TableRow>
  );
};

export default CompanyProfileHoldersItem;
