import React, { FC } from 'react';

import { QUALIFICATION_QUESTIONS_MODAL_TITLE } from 'constants/my-investments';
import { ShareExchangeSubmittedAnswers } from 'interfaces';
import { ModalWindow } from 'shared-components';

import ShareExchangeReviewQualificationAnswersModalContent from './ShareExchangeReviewQualificationAnswersModalContent';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  answers?: ShareExchangeSubmittedAnswers[] | null;
};

const ShareExchangeReviewQualificationAnswersModal: FC<Props> = ({ isOpen, onClose, answers }) => {
  return (
    <ModalWindow
      className='lg:w-8/12 xl:w-6/12 lg:h-12/12'
      wrapperClassName='px-10 py-[30px] lg:px-10 lg:py-[30px]'
      title={QUALIFICATION_QUESTIONS_MODAL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
    >
      {!!answers && <ShareExchangeReviewQualificationAnswersModalContent onClose={onClose} answers={answers} />}
    </ModalWindow>
  );
};

export default ShareExchangeReviewQualificationAnswersModal;
