import axios, { AxiosError, AxiosInstance } from 'axios';

import { LocalStorageKeys } from 'constants/global';
import { getAccessToken, resetUserStorage } from 'utils';

import { unsetCurrentUser } from '../modules/current-user/action';
import { store } from '../modules/store';

const { REACT_APP_API_URL } = process.env;

export const API_URL = window?._env_?.REACT_APP_API_URL || REACT_APP_API_URL;

function createHttpClient(endPoint = ''): AxiosInstance {
  const instance = axios.create({
    baseURL: `${API_URL}${endPoint}`,
    timeout: 0,
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN) || getAccessToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '69420',
      };
    }
    return config;
  });

  instance.interceptors.response.use(undefined, async function axiosRetryInterceptor(err) {
    const originalRequest = err.config;

    const isSignInPage = window?.location?.pathname?.includes('sign-up');

    if (err.response.status === 401 && !originalRequest._retry && !isSignInPage) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);

        if (!localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) && !refreshToken) throw new AxiosError();

        const response = await axios.post(
          `${API_URL}/api/v1/refresh-token`,
          {},
          { headers: { Authorization: `Bearer ${refreshToken}` } },
        );

        const { accessToken } = response.data;
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken);

        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return axios(originalRequest);
      } catch (error) {
        store.dispatch(unsetCurrentUser());
        resetUserStorage();
      }
    }
    return Promise.reject(err);
  });

  return instance;
}

export const httpApiClient = createHttpClient('/api/v1');
