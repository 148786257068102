import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { ReactComponent as DefaultAvatarIcon } from 'assets/svg/avatar.svg';

export const AVATAR_DEFAULT_CLASSNAME =
  'min-w-[32px] min-h-[32px] rounded-full bg-grey-100 flex items-center justify-center text-grey-100';

export interface Props {
  alt: string;
  src?: string;
  className?: string;
}

const Avatar: FC<Props> = ({ className, alt, ...props }) => {
  if (!props.src) return <DefaultAvatarIcon className={twMerge(AVATAR_DEFAULT_CLASSNAME, className)} />;

  return <img className={twMerge(AVATAR_DEFAULT_CLASSNAME, className)} alt={alt} {...props} />;
};

export default Avatar;
