import React from 'react';

const SECTION_SKELETON_CLASSNAME = 'mt-10 skeleton-loader max-w-800 w-full 2xl:ml-48 mx-auto h-[200px]';

const DealParametersSkeleton = () => {
  return (
    <div className='flex flex-col'>
      <div className='flex justify-end'>
        <div className='skeleton-loader w-150 h-11' />
        <div className='skeleton-loader w-150 h-11 ml-6' />
      </div>
      <div className={SECTION_SKELETON_CLASSNAME} />
      <div className={SECTION_SKELETON_CLASSNAME} />
      <div className={SECTION_SKELETON_CLASSNAME} />
    </div>
  );
};

export default DealParametersSkeleton;
