import React, { ReactNode } from 'react';

interface Props {
  icon?: ReactNode;
  children?: ReactNode;
}

const SnippetNoInfoLayout = ({ children, icon }: Props) => {
  return (
    <div className='flex flex-col items-center justify-center h-full w-full bg-violet-100 rounded-lg px-6 py-16 [&>svg]:w-[48px] [&>svg]:h-[48px] [&_path]:fill-violet-600'>
      {icon}

      <div className='mt-4 [&>p]:mr-0'>{children}</div>
    </div>
  );
};

export default SnippetNoInfoLayout;
