import React, { FC } from 'react';

import {
  INVESTMENTS_PAGE_LINK_TEXT,
  UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPE,
  UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPES_TEXT,
  UPLOAD_INVESTMENTS_MAX_FILE_SIZE,
  UPLOAD_INVESTMENTS_MODAL_DESCRIPTION,
  UPLOAD_INVESTMENTS_MODAL_TITLE,
} from 'constants/investments';
import { PromiseBooleanOrVoid } from 'interfaces';
import { DescriptionWithLink, UploadFileForm, UploadFileModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmitUploadInvestment: ([investmentsFile]: File[]) => PromiseBooleanOrVoid;
  investmentsTemplateDownloadPath: string;
};

// TODO: Fix this file
// In this file may happen Uncaught ReferenceError: Cannot access 'SOME_CONSTANT' before initialization
// This happens because of circled imports. constants/investments file has import of components that has UploadInvestmentsModal usage
const UploadInvestmentsModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmitUploadInvestment,
  investmentsTemplateDownloadPath,
}) => {
  return (
    <UploadFileModal
      isOpen={isOpen}
      onClose={onClose}
      title={UPLOAD_INVESTMENTS_MODAL_TITLE}
      description={
        <DescriptionWithLink
          href={investmentsTemplateDownloadPath}
          text={UPLOAD_INVESTMENTS_MODAL_DESCRIPTION}
          linkText={INVESTMENTS_PAGE_LINK_TEXT}
          isDownload
          className='max-w-[650px]'
        />
      }
    >
      <UploadFileForm
        onClose={onClose}
        onSubmitUpload={onSubmitUploadInvestment}
        allowedFileTypes={[UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPE]}
        maxFileSize={UPLOAD_INVESTMENTS_MAX_FILE_SIZE}
        allowedFileHelpText={UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPES_TEXT}
      />
    </UploadFileModal>
  );
};

export default UploadInvestmentsModal;
