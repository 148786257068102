import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  createDueDiligence,
  getDueDiligenceCompanyNameById,
  getDueDiligenceData,
  setDueDiligence,
} from 'modules/due-diligence/action';
import { initialState } from 'modules/due-diligence/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';

import { selectRawBusinessDetails } from '../modules/due-diligence/selectors';
import { checkIsAdmin } from '../utils';

const useDueDiligenceData = () => {
  const { id: companyId, dueDiligenceId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [isDueDiligenceLoaded, setIsDueDiligenceLoaded] = useState(false);
  const [isDueDiligenceLoading, setIsDueDiligenceLoading] = useState(false);
  const [isDueDiligenceReSubmitted, setIsDueDiligenceReSubmitted] = useState(false);

  const businessDetails = useAppSelector(selectRawBusinessDetails);

  const onPopulateData = (isReSubmit?: boolean) => {
    if (!companyId) return;

    setIsDueDiligenceLoading(true);
    dispatch(createDueDiligence({ id: companyId, isAdmin: checkIsAdmin() }))
      .unwrap()
      .then(() => {
        setIsDueDiligenceLoaded(true);
        if (isReSubmit) {
          setIsDueDiligenceReSubmitted(true);
        }
      })
      .catch(() => {
        if (Number(dueDiligenceId) === businessDetails?.id || isReSubmit) return;

        dispatch(getDueDiligenceCompanyNameById({ id: companyId, isAdmin: checkIsAdmin() }));
      })
      .finally(() => setIsDueDiligenceLoading(false));
  };

  useEffect(() => {
    dispatch(setDueDiligence(initialState));

    if (!companyId) return;

    dispatch(getDueDiligenceData({ companyId, dueDiligenceId }))
      .unwrap()
      .then(() => setIsDueDiligenceLoaded(true))
      .catch(() => {
        if (dueDiligenceId) {
          navigate(-1);
          return;
        }
        onPopulateData();
      });
  }, [dispatch, companyId, dueDiligenceId]);

  return {
    isDueDiligenceLoading,
    isDueDiligenceLoaded,
    isDueDiligenceReSubmitted,
    onPopulateData,
  };
};

export default useDueDiligenceData;
