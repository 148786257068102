import React, { DetailedHTMLProps, FC, ReactNode, TdHTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

export interface Props extends DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const TABLE_DATA_TEST_ID = 'TABLE_DATA_TEST_ID';

const TableData: FC<Props> = ({ disabled, className, children, ...props }) => {
  return (
    <td
      data-testid={TABLE_DATA_TEST_ID}
      title={typeof children === 'string' ? children : ''}
      className={twMerge(
        'text-grey-800 text-base font-normal text-ellipsis pr-2 py-4',
        typeof children === 'string' ? 'overflow-hidden' : '',
        disabled ? 'pointer-events-none opacity-40' : '',
        className,
      )}
      {...props}
    >
      {children}
    </td>
  );
};

export default TableData;
