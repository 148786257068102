import React from 'react';

import useLoadCompaniesIac from 'hooks/useLoadCompaniesIac';
import { CompaniesIacShortInfoTable } from 'page-components';
import { SnippetLayout } from 'shared-components';

const CompaniesIacSnippet = () => {
  const { list: companiesIacList, isLoading } = useLoadCompaniesIac();

  return (
    <SnippetLayout title='Companies Pending IAC Review' className='overflow-x-auto'>
      <CompaniesIacShortInfoTable companiesIac={companiesIacList} isLoading={isLoading} />
    </SnippetLayout>
  );
};

export default CompaniesIacSnippet;
