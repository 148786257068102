import { useState } from 'react';

import { AlreadyRegisteredTypes, StringOrNull } from 'interfaces';
import { checkIsUserAlreadyRegistered } from 'modules/current-user/action';
import { useAppDispatch } from 'modules/store';

export interface IsRegisteredData extends AlreadyRegisteredTypes {
  currentEmail: StringOrNull;
}

export interface UseIsAlreadyRegisteredReturnValues {
  isAlreadyRegisteredData: IsRegisteredData | null;
  handleCheckIsAlreadyRegistered: (email: string) => Promise<IsRegisteredData | null>;
  handleClearIsAlreadyRegisteredData: VoidFunction;
  isFullyRegistered: boolean;
  isPartlyRegistered: boolean;
  isChecking?: boolean;
}

export const useIsAlreadyRegistered = (): UseIsAlreadyRegisteredReturnValues => {
  const dispatch = useAppDispatch();

  const [isRegisteredData, setIsRegisteredData] = useState<IsRegisteredData | null>(null);
  const [isChecking, setIsChecking] = useState(false);

  const { isFounder, isInvestor } = isRegisteredData || {};

  const isFullyRegistered = Boolean(isFounder && isInvestor);

  const isPartlyRegistered = Boolean(isFounder || isInvestor);

  const handleCheckIsAlreadyRegistered = async (email: string): Promise<IsRegisteredData | null> => {
    setIsChecking(true);

    try {
      const response = await dispatch(checkIsUserAlreadyRegistered(email)).unwrap();

      const valuesWithEmail = { ...response, currentEmail: email };

      setIsRegisteredData(valuesWithEmail);
      return valuesWithEmail;
    } catch (e) {
      setIsRegisteredData(null);
    } finally {
      setIsChecking(false);
    }

    return null;
  };

  const handleClearIsAlreadyRegisteredData = () => {
    setIsRegisteredData(null);
  };

  return {
    isChecking,
    isAlreadyRegisteredData: isRegisteredData,
    isFullyRegistered,
    isPartlyRegistered,
    handleCheckIsAlreadyRegistered,
    handleClearIsAlreadyRegisteredData,
  };
};
