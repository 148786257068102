import React, { FC, useMemo } from 'react';

import { DueDiligencePersonInput, TableColumns } from 'interfaces';
import Table from 'shared-components/table';

import SubmitResidentialAddressesTableRow from './SubmitResidentialAddressesTableRow';

type Props = {
  directors: DueDiligencePersonInput[];
  columns: TableColumns[];
  hasNominee?: boolean;
};

const SubmitResidentialAddressesTable: FC<Props> = ({ directors, columns, hasNominee }) => {
  const tableColumns = useMemo(() => columns.slice(1), [columns]);

  return (
    <Table wrapperClassName='w-full' columns={tableColumns} className='table-simplified-tr table-simplified-th'>
      {directors.map((director, index) => (
        <SubmitResidentialAddressesTableRow
          key={`${director.id}/${index}`}
          director={director}
          hasNominee={hasNominee}
        />
      ))}
    </Table>
  );
};

export default SubmitResidentialAddressesTable;
