import React, { FC } from 'react';

import cn from 'classnames';
import { addDays, format } from 'date-fns';
import { Control, FieldErrors, useFieldArray, UseFormRegister } from 'react-hook-form';

import { ReactComponent as TrashCan } from 'assets/svg/trash-can.svg';
import {
  COMPANY_IAC_CLOSING_DATES_TITLES,
  COMPANY_IAC_CLOSING_DATES_FIELDS,
  COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME,
  COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME,
} from 'constants/company-iac';
import { DAY_FORMAT_WITH_DASH } from 'constants/dateFormats';
import { ButtonVariants } from 'constants/shared/button';
import { UseCompanyIacOverviewReturnValues } from 'hooks/useCompanyIacOverview';
import { CompanyIacOverviewFormState } from 'interfaces';
import { Button, FormContent, FormLabel } from 'shared-components';

import CompanyIacInputsTitles from '../CompanyIacInputsTitles';
import CompanyIacNoClosingDates from './CompanyIacNoClosingDates';

type Props = {
  register: UseFormRegister<CompanyIacOverviewFormState>;
  errors: FieldErrors<CompanyIacOverviewFormState>;
  control: Control<CompanyIacOverviewFormState>;
  disabled: boolean;
  isAllowAdditionalValidation?: boolean;
} & Pick<UseCompanyIacOverviewReturnValues, 'setValue'>;

const CompanyIacClosingDatesFields: FC<Props> = ({
  control,
  register,
  errors,
  disabled,
  isAllowAdditionalValidation,
  setValue,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'closingDates',
  });

  const handleAddNewClosingDate = () => {
    append({ duration: '30', closingDate: format(addDays(new Date(), 30), DAY_FORMAT_WITH_DASH) });
  };

  const handleDeleteClosingDate = (index: number) => {
    remove(index);
  };

  return (
    <div
      className={cn('my-6', COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME, {
        'border border-red-500': isAllowAdditionalValidation && !fields?.length,
      })}
    >
      <FormLabel>Closing Dates*</FormLabel>

      <div className='bg-grey-100 p-2 mt-6 overflow-auto'>
        <CompanyIacInputsTitles titles={COMPANY_IAC_CLOSING_DATES_TITLES} />

        {fields && !!fields?.length && (
          <ul className='w-[600px] md:w-auto'>
            {fields.map((field, index) => (
              <li key={`${field.id}`} className='flex mb-2'>
                <div
                  className={`${COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.NUMBER} flex items-center justify-center h-[48px]`}
                >
                  {index + 1}.
                </div>

                <FormContent
                  isDisabled={disabled}
                  fields={COMPANY_IAC_CLOSING_DATES_FIELDS(index, setValue)}
                  register={register}
                  errors={errors}
                  control={control}
                />

                <button
                  className={`${COMPANY_IAC_CLOSING_DATES_COMMON_CLASSNAME.ACTIONS} h-[48px]`}
                  type='button'
                  onClick={() => handleDeleteClosingDate(index)}
                >
                  <TrashCan className='grey-500-svg-path m-auto' />
                </button>
              </li>
            ))}
          </ul>
        )}

        {disabled && !fields?.length && <CompanyIacNoClosingDates />}
      </div>

      {!disabled && (
        <Button
          disabled={disabled}
          className='w-full h-[40px] bg-grey-100 mt-1 pl-5 rounded-none justify-start'
          variant={ButtonVariants.LINK}
          onClick={handleAddNewClosingDate}
          type='button'
        >
          + Add New Row
        </Button>
      )}
    </div>
  );
};

export default CompanyIacClosingDatesFields;
