import React, { useState, useEffect } from 'react';

import useSignificantControlCompanyEdit from 'hooks/useSignificantControlCompanyEdit';
import { editDueDiligenceBusinessDetails } from 'modules/due-diligence/action';
import { selectBusinessDetails } from 'modules/due-diligence/selectors';
import { useAppSelector, useAppDispatch } from 'modules/store';
import { SnippetLayout, TextArea } from 'shared-components';

import SignificantControlCompanyActionsButtons from './SignificantControlCompanyActionsButtons';

const SignificantControlCompanyGroupStructure = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');

  const { isEditing, setEditingMode, unsetEditingMode } = useSignificantControlCompanyEdit();

  const businessDetails = useAppSelector(selectBusinessDetails);

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const onClickSave = () => {
    if (!businessDetails) {
      return;
    }

    setIsLoading(true);

    dispatch(
      editDueDiligenceBusinessDetails({
        ...businessDetails,
        groupStructure: value,
        notificationMessage: 'The group structure was successfully updated',
      }),
    )
      .unwrap()
      .then(() => {
        unsetEditingMode();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickDiscardChanges = () => {
    setValue(businessDetails?.groupStructure || '');
    unsetEditingMode();
  };

  useEffect(() => {
    if (businessDetails?.groupStructure) {
      setValue(businessDetails?.groupStructure);
    }
  }, [businessDetails?.groupStructure]);

  return (
    <SnippetLayout className='mt-6' title='Group Structure'>
      <TextArea
        disabled={!isEditing || isLoading}
        label='Please provide further detail below:'
        placeholder='Enter message'
        onChange={handleChangeTextArea}
        value={value}
        textAreaClassName='min-h-[90px]'
        maxLength={2000}
      />

      <SignificantControlCompanyActionsButtons
        onClickEdit={setEditingMode}
        isEditing={isEditing}
        onClickSave={onClickSave}
        onClickDiscardChanges={onClickDiscardChanges}
        isLoading={isLoading}
      />
    </SnippetLayout>
  );
};

export default SignificantControlCompanyGroupStructure;
