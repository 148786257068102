import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

export const BANNER_TEST_ID = 'BANNER_TEST_ID';
export const BANNER_LOADER_TEST_ID = 'BANNER_TEST_ID';

interface Props {
  text: string;
  children?: ReactNode;
  isShown?: boolean;
  loading?: boolean;
  className?: string;
}

const Banner: FC<Props> = ({ text, children, isShown = true, loading, className }) => {
  if (loading) return <div className='skeleton-loader h-10 w-full rounded-md' data-testid={BANNER_LOADER_TEST_ID} />;

  if (!isShown) return null;

  return (
    <div className='flex justify-center items-center p-1.5 bg-red-500 rounded-md' data-testid={BANNER_TEST_ID}>
      <p className={twMerge('text-grey-100 font-medium mr-10', className)}>{text}</p>
      {children}
    </div>
  );
};

export default Banner;
