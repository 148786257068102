import { PhoneNumberUtil } from 'google-libphonenumber';

import { INCORRECT_PHONE_NUMBER_MESSAGE } from './global';

const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneNumber = (phone: string): string | undefined => {
  if (!phone) return undefined;
  try {
    const isValidNumber = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));

    if (!isValidNumber) return INCORRECT_PHONE_NUMBER_MESSAGE;

    return undefined;
  } catch {
    return INCORRECT_PHONE_NUMBER_MESSAGE;
  }
};
