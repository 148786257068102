import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import {
  DUE_DILIGENCE_DOCUMENTS_TYPES,
  DueDiligenceSections,
  LEGAL_MATTERS_BLOCK_DESCRIPTION,
  LEGAL_MATTERS_BLOCK_TITLE,
  LEGAL_MATTERS_TEXT_AREA_MAX_LENGTH,
} from 'constants/due-diligence';
import useDueDiligenceEdit from 'hooks/use-due-diligence-edit/useDueDiligenceEdit';
import { DueDiligenceLegalMatters } from 'interfaces';
import { saveLegalMatters } from 'modules/due-diligence/action';
import { selectDueDiligenceId, selectLegalMatters } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { TextArea } from 'shared-components';
import { checkIsFounder } from 'utils';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
import LegalMattersActions from './LegalMattersActions';

type Props = {
  onOpenUploadDocumentModal: (props: Record<string, any>) => void;
  className?: string;
  isConfirmationValidationHighlighted: boolean;
  isEditing: boolean;
};

const LegalMattersBlock: FC<Props> = ({
  onOpenUploadDocumentModal,
  className,
  isConfirmationValidationHighlighted,
  isEditing,
}) => {
  const dispatch = useAppDispatch();

  const dueDiligenceId = useAppSelector(selectDueDiligenceId);
  const legalMatters = useAppSelector(selectLegalMatters);

  const { handleCancel } = useDueDiligenceEdit(DueDiligenceSections.LEGAL_MATTERS);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedLegalMattersState, setSelectedLegalMattersState] = useState<boolean | undefined>(undefined);
  const [isDocumentsAttached, setIsDocumentsAttached] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');

  const isFounder = useMemo(() => checkIsFounder(), []);

  const onClickDiscardChanges = () => {
    handleCancel();
  };

  const handleChangeVicariousLiability = (value: boolean) => {
    setSelectedLegalMattersState(value);
    handleSave({ hasVicariousLiability: value });
  };

  const onChangeDocumentsAttachedState = () => {
    setIsDocumentsAttached((prevState) => !prevState);
    handleSave({ isDocumentsAttached: !isDocumentsAttached });
  };

  const onChangeTextAreaValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  };

  const handleSaveTextAreaValue = () => {
    if (!textAreaValue || textAreaValue === legalMatters?.details) return;
    handleSave({ details: textAreaValue });
  };

  const handleSave = (values: Partial<DueDiligenceLegalMatters>) => {
    if (!dueDiligenceId) {
      return;
    }

    setIsLoading(true);

    dispatch(
      saveLegalMatters({
        dueDiligenceId,
        isDocumentsAttached,
        hasVicariousLiability: selectedLegalMattersState,
        details: textAreaValue,
        ...values,
      }),
    )
      .unwrap()
      .then(handleCancel)
      .finally(() => setIsLoading(false));
  };

  const onClickUploadDocument = () => {
    onOpenUploadDocumentModal({ selectedDocumentType: DUE_DILIGENCE_DOCUMENTS_TYPES.LEGAL_MATTERS });
  };

  useEffect(() => {
    setSelectedLegalMattersState(legalMatters?.hasVicariousLiability);
    setIsDocumentsAttached(!!legalMatters?.isDocumentsAttached);
    setTextAreaValue(legalMatters?.details || '');
  }, [legalMatters]);

  return (
    <DueDiligenceBlock
      blockNumber='3'
      blockClassName={className}
      sectionType={DueDiligenceSections.LEGAL_MATTERS}
      onClickDiscardChanges={onClickDiscardChanges}
      title={LEGAL_MATTERS_BLOCK_TITLE}
      description={LEGAL_MATTERS_BLOCK_DESCRIPTION}
      isLoading={isLoading}
    >
      <LegalMattersActions
        isEditing={isEditing && !isLoading}
        onCheckDocumentsAttached={onChangeDocumentsAttachedState}
        isDocumentsAttached={isDocumentsAttached}
        isDocumentsAttachedActualValue={legalMatters?.isDocumentsAttached}
        onClickUploadDocument={onClickUploadDocument}
        onChangeLegalMattersState={handleChangeVicariousLiability}
        selectedLegalMattersState={selectedLegalMattersState}
        isFounder={isFounder}
        isConfirmationValidationHighlighted={
          isConfirmationValidationHighlighted && selectedLegalMattersState === undefined
        }
      />

      <TextArea
        maxLength={LEGAL_MATTERS_TEXT_AREA_MAX_LENGTH}
        disabled={!isEditing || isLoading || Boolean(isFounder && legalMatters?.details)}
        className='pt-6 disabled-light-grey'
        textAreaClassName='min-h-[125px]'
        label='Please provide further detail below:'
        labelClassName='text-sm pl-1'
        placeholder='Enter message'
        value={textAreaValue}
        onChange={onChangeTextAreaValue}
        onBlur={handleSaveTextAreaValue}
      />
    </DueDiligenceBlock>
  );
};

export default LegalMattersBlock;
