import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  SET_PASSWORD_SUBMIT_BUTTON,
  getSetPasswordFields,
  SetPasswordFieldNames,
  SET_PASSWORD_BUTTON_TEST_ID,
} from 'constants/reset-password';
import { SetPasswordInput } from 'interfaces';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';
import { Button, FormContent, PasswordStrengthWidget } from 'shared-components';

interface Props {
  onSubmit: SubmitHandler<SetPasswordInput>;
}

const SetPasswordForm: FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SetPasswordInput>({
    reValidateMode: 'onChange',
  });

  const isActionLoading = useAppSelector(selectIsActionLoading);
  const watchPassword = watch(SetPasswordFieldNames.PASSWORD);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-2 gap-x-7 relative'>
        <FormContent<SetPasswordInput>
          fields={getSetPasswordFields(watchPassword)}
          register={register}
          errors={errors}
        />
        <PasswordStrengthWidget
          password={watchPassword}
          className='col-start-1 col-end-3 row-start-5 row-end-6 top-0'
        />
      </div>
      <Button className='my-2.5' isLoading={isActionLoading} data-testid={SET_PASSWORD_BUTTON_TEST_ID}>
        {SET_PASSWORD_SUBMIT_BUTTON}
      </Button>
    </form>
  );
};

export default SetPasswordForm;
