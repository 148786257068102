import { useCallback, useEffect, useState } from 'react';

import { parse } from 'date-fns';
import { isEmpty, toString } from 'lodash';

import { DAY_FORMAT_WITH_DASH } from 'constants/dateFormats';
import {
  DUE_DILIGENCE_BUSINESS_DETAILS_REQUIRED_FIELDS,
  DUE_DILIGENCE_MAIN_DOCUMENTS_TYPES_KEYS,
  DUE_DILIGENCE_PERSON_ADDRESSES_REQUIRED_FIELDS,
  DUE_DILIGENCE_REQUIRED_CONFIRMATION_DOCUMENTS_TYPES_KEYS,
  DUE_DILIGENCE_SIGNIFICANT_CONTROL_PERSONS_REQUIRED_FIELDS,
  DueDiligencePersonsFormNamesEnum,
  DUE_DILIGENCE_COMPANY_RUNWAY_REQUIRED_FIELDS,
  PreferenceRightsFieldNames,
  DUE_DILIGENCE_FUNDRAISING_DETAILS_YES_REQUIRED_FIELDS,
  FundraisingDetailsFieldNames,
} from 'constants/due-diligence';
import { SIGNIFICANT_CONTROL_DOCUMENT_TYPES } from 'constants/significant-control-company';
import {
  DueDiligenceBusinessDetails,
  DueDiligenceCompanyRunway,
  DueDiligenceDataWithoutChildren,
  DueDiligenceDocumentTypes,
  DueDiligenceFundraisingDetails,
  DueDiligenceLegalMatters,
  SignificantControlPerson,
  SignificantControlPersonAddress,
} from 'interfaces';
import {
  selectBeneficialOwners,
  selectChildrenDueDiligence,
  selectCompanyRunway,
  selectDueDiligenceDocumentTypes,
  selectFundraisingDetails,
  selectIsChildrenPersonsExists,
  selectLegalMatters,
  selectRawBusinessDetails,
  selectSignificantControlPersons,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';

import { validateSignificantControlPersons } from '../utils/significant-control-company';

const createUnfieldValue = (name: string, description?: string): unfieldValueI => {
  return {
    name: name,
    asError: false,
    description: description,
  };
};

const validateBusinessDetails = (businessDetails: DueDiligenceBusinessDetails): unfieldValueI[] => {
  const result: unfieldValueI[] = [];
  const requiredFields = [
    ...DUE_DILIGENCE_BUSINESS_DETAILS_REQUIRED_FIELDS,
    ...[PreferenceRightsFieldNames.DEBT_PREFERENCE_RIGHTS],
  ];
  requiredFields.map((requiredField) => {
    if (isEmpty(businessDetails[requiredField as keyof DueDiligenceBusinessDetails])) {
      result.push(createUnfieldValue(requiredField));
    }
  });
  return result;
};

const validatePersons = (
  section: DueDiligencePersonsFormNamesEnum.BENEFICIAL_OWNERS | DueDiligencePersonsFormNamesEnum.DIRECTORS,
  persons: SignificantControlPerson[],
): unfieldValueI[] => {
  const result: unfieldValueI[] = [];

  if (persons.length < 1 && section === DueDiligencePersonsFormNamesEnum.DIRECTORS) {
    result.push(createUnfieldValue(`${section}`));
  }
  persons.map((person) => {
    DUE_DILIGENCE_SIGNIFICANT_CONTROL_PERSONS_REQUIRED_FIELDS.map((requiredField) => {
      const value = person[requiredField as keyof SignificantControlPerson];
      if (
        !value ||
        (requiredField === 'dateOfBirth' && !parse(toString(value), DAY_FORMAT_WITH_DASH, new Date()).getTime())
      ) {
        result.push(createUnfieldValue(`${section}.${person.id}.${requiredField}`));
      }
    });

    if (isEmpty(person.residentialAddresses)) {
      result.push(createUnfieldValue(`${section}.${person.id}.residentialAddresses`));
    } else {
      person.residentialAddresses.map((address) => {
        DUE_DILIGENCE_PERSON_ADDRESSES_REQUIRED_FIELDS.map((requiredField) => {
          const value = address[requiredField as keyof SignificantControlPersonAddress];
          if (!value) {
            if (requiredField !== 'to' || !address.tillNow) {
              result.push(
                createUnfieldValue(`${section}.${person.id}.residentialAddresses.${address.id}.${requiredField}`),
              );
            }
          }
        });
      });
    }
  });
  return result;
};

const validateLegalMatters = (legalMatters: DueDiligenceLegalMatters | null): unfieldValueI[] => {
  const result: unfieldValueI[] = [];
  if (!legalMatters) {
    result.push(createUnfieldValue('legalMatters'));
  }
  return result;
};

const validateDocumentTypes = (documentTypes: DueDiligenceDocumentTypes[]): unfieldValueI[] => {
  const result: unfieldValueI[] = [];

  DUE_DILIGENCE_MAIN_DOCUMENTS_TYPES_KEYS.map((documentTypeKey) => {
    if (DUE_DILIGENCE_REQUIRED_CONFIRMATION_DOCUMENTS_TYPES_KEYS.includes(documentTypeKey)) {
      documentTypes.map((documentType) => {
        if (documentTypeKey === documentType.documentType && !documentType.isDocumentAttached) {
          result.push(createUnfieldValue(`documentTypes.${documentTypeKey}`));
        }
      });
    }
  });
  return result;
};

const validateSignificantControlCompany = (
  childrenDD: DueDiligenceDataWithoutChildren[],
  takeSCPsFromChild: boolean,
): unfieldValueI[] => {
  const result: unfieldValueI[] = [];

  if (childrenDD?.length) {
    childrenDD?.map(({ documentTypes, companyId, significantControlPersons }) => {
      const isDocumentsNotFilled = documentTypes?.some(
        (documentType) =>
          documentType.documentType === SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT && !documentType.isDocumentAttached,
      );

      const isSignificantPersonsNotFilled = validateSignificantControlPersons({ significantControlPersons });

      if (isDocumentsNotFilled || isSignificantPersonsNotFilled) {
        result.push(createUnfieldValue(`significantControlCompany.${companyId}`));
      }
    });
  }

  if (takeSCPsFromChild) {
    if (
      validatePersons(DueDiligencePersonsFormNamesEnum.DIRECTORS, childrenDD[0].significantControlPersons).length > 0
    ) {
      result.push(createUnfieldValue(`significantControlCompany`));
      result.push(createUnfieldValue(`significantControlCompany.directors`));
    }
  }

  return result;
};

const validateCompanyRunway = (companyRunway: DueDiligenceCompanyRunway): unfieldValueI[] => {
  const result: unfieldValueI[] = [];

  DUE_DILIGENCE_COMPANY_RUNWAY_REQUIRED_FIELDS.map((requiredField) => {
    const currentValue = companyRunway[requiredField as keyof DueDiligenceCompanyRunway];

    if (currentValue === undefined || currentValue === null || currentValue === '') {
      result.push(createUnfieldValue(requiredField));
    }
  });

  return result;
};

const validateFundraisingDetails = (fundraisingDetails: DueDiligenceFundraisingDetails | null): unfieldValueI[] => {
  const result: unfieldValueI[] = [];

  if (!fundraisingDetails?.isCurrentlyFundraising && fundraisingDetails?.isCurrentlyFundraising !== false) {
    result.push(createUnfieldValue(FundraisingDetailsFieldNames.IS_CURRENTLY_FUNDRAISING));
    return result;
  }

  if (fundraisingDetails?.isCurrentlyFundraising) {
    DUE_DILIGENCE_FUNDRAISING_DETAILS_YES_REQUIRED_FIELDS.map((requiredField) => {
      const currentValue = fundraisingDetails[requiredField as keyof DueDiligenceFundraisingDetails];

      if (currentValue === null || currentValue === undefined || isEmpty(String(currentValue))) {
        result.push(createUnfieldValue(requiredField));
      }
    });
  }
  return result;
};

export interface unfieldValueI {
  name: string;
  asError: boolean;
  description?: string;
}

export interface getUnfieldValueFunc {
  (name: string): unfieldValueI | null;
}

const useDueDiligenceValidation = (isConfirmationValidationHighlighted: boolean, isShouldBeReValidated?: boolean) => {
  const businessDetails = useAppSelector(selectRawBusinessDetails);
  const significantControlPersons = useAppSelector(selectSignificantControlPersons);
  const beneficialOwners = useAppSelector(selectBeneficialOwners);
  const legalMatters = useAppSelector(selectLegalMatters);
  const documentTypes = useAppSelector(selectDueDiligenceDocumentTypes);
  const childrenDD = useAppSelector(selectChildrenDueDiligence);
  const isChildrenPersonsExists = useAppSelector(selectIsChildrenPersonsExists);
  const { companyRunway } = useAppSelector(selectCompanyRunway);
  const { fundraisingDetails } = useAppSelector(selectFundraisingDetails);

  const [unfieldValues, setUnfieldValues] = useState<unfieldValueI[]>([]);

  useEffect(() => {
    // Revalidate when DD is updated
    validate();
  }, [businessDetails, isConfirmationValidationHighlighted, companyRunway, fundraisingDetails, isShouldBeReValidated]);

  const validate = () => {
    if (!businessDetails) {
      return;
    }
    const takeSCPsFromChild =
      !significantControlPersons?.length && isChildrenPersonsExists && !!businessDetails?.isSCPsSameAsInSCC;
    let values = [
      ...validateBusinessDetails(businessDetails),
      ...validatePersons(DueDiligencePersonsFormNamesEnum.BENEFICIAL_OWNERS, beneficialOwners),
      ...validateLegalMatters(legalMatters),
      ...validateDocumentTypes(documentTypes),
      ...validateSignificantControlCompany(childrenDD, takeSCPsFromChild),
      ...validateCompanyRunway(companyRunway as DueDiligenceCompanyRunway),
      ...validateFundraisingDetails(fundraisingDetails as DueDiligenceFundraisingDetails),
    ];
    if (!takeSCPsFromChild) {
      values = [...values, ...validatePersons(DueDiligencePersonsFormNamesEnum.DIRECTORS, significantControlPersons)];
    }

    values.map((value) => {
      value.asError = isConfirmationValidationHighlighted;
      value.description = isConfirmationValidationHighlighted ? 'Required data missing' : undefined;
    });
    setUnfieldValues(values);
  };
  const getUnfieldValue = useCallback<getUnfieldValueFunc>(
    (name) => {
      let result = null;
      unfieldValues.map((value) => {
        if (value.name === name) {
          result = value;
        }
      });

      return result;
    },
    [unfieldValues],
  );

  return {
    unfieldValues,
    getUnfieldValue,
  };
};

export default useDueDiligenceValidation;
