import React, { FC } from 'react';

import cn from 'classnames';

import {
  DUE_DILIGENCE_FUNDRAISING_DETAILS_FIELDS,
  DUE_DILIGENCE_FUNDRAISING_OTHERS_DETAILS_FIELD,
  DueDiligenceSections,
  FundraisingDetailsFieldNames,
} from 'constants/due-diligence';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { UseFundraisingDetailsReturnValues } from 'hooks/useFundraisingDetails';
import { FormContent, RadioInputWithLabel } from 'shared-components';
import { highlightDDFields } from 'utils/due-diligence';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';

interface Props extends UseFundraisingDetailsReturnValues {
  getUnfieldValue: getUnfieldValueFunc;
  isConfirmationValidationHighlighted?: boolean;
}

const FUNDRAISING_DETAILS_CHOICES = [
  { name: FundraisingDetailsFieldNames.IS_CURRENTLY_FUNDRAISING, label: 'Yes', value: true },
  { name: FundraisingDetailsFieldNames.IS_CURRENTLY_FUNDRAISING, label: 'No', value: false },
];

const FundraisingDetails: FC<Props> = ({
  register,
  control,
  errors,
  handleSaveField,
  setValue,
  isCurrentlyFundraisingSelected,
  getUnfieldValue,
  isConfirmationValidationHighlighted,
  isLoading,
  handleDiscardChanges,
  isEditing,
}) => {
  const isCurrentlyFundraisingEmpty = !isCurrentlyFundraisingSelected && isCurrentlyFundraisingSelected !== false;
  return (
    <DueDiligenceBlock
      blockNumber='6'
      descriptionClassName='text-grey-500'
      sectionType={DueDiligenceSections.FUNDRAISING_DETAILS}
      title='Fundraising Details'
      onClickDiscardChanges={handleDiscardChanges}
      isLoading={isLoading}
    >
      <form className='mt-5'>
        <div className='flex flex-col xs:flex-row items-center mb-6'>
          <label className='mr-2'>Are you currently fundraising?</label>

          {FUNDRAISING_DETAILS_CHOICES.map((choice, index) => (
            <RadioInputWithLabel
              key={`${choice.name}/${index}`}
              className={cn('ml-2', {
                'radio-input-info-highlighted': isCurrentlyFundraisingEmpty,
                'radio-input-highlighted': isConfirmationValidationHighlighted && isCurrentlyFundraisingEmpty,
              })}
              labelClassName='px-2'
              name={choice.name}
              label={choice.label}
              checked={choice.value === isCurrentlyFundraisingSelected}
              disabled={isLoading || !isEditing}
              onChange={() => {
                setValue(`${FundraisingDetailsFieldNames.IS_CURRENTLY_FUNDRAISING}`, choice.value);
                handleSaveField(FundraisingDetailsFieldNames.IS_CURRENTLY_FUNDRAISING);
              }}
            />
          ))}
        </div>
        <FormContent
          fields={highlightDDFields([DUE_DILIGENCE_FUNDRAISING_OTHERS_DETAILS_FIELD], getUnfieldValue)}
          register={register}
          control={control}
          errors={errors}
          onBlur={handleSaveField}
          isDisabled={isLoading || !isEditing}
        />

        {isCurrentlyFundraisingSelected && (
          <FormContent
            fields={highlightDDFields(DUE_DILIGENCE_FUNDRAISING_DETAILS_FIELDS, getUnfieldValue)}
            register={register}
            control={control}
            errors={errors}
            onBlur={handleSaveField}
            isDisabled={isLoading || !isEditing}
          />
        )}
      </form>
    </DueDiligenceBlock>
  );
};

export default FundraisingDetails;
