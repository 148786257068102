import React, { useEffect } from 'react';

import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { FounderProfileInfo } from 'constants/founders';
import { ConstraintVariants } from 'constants/shared';
import { TypographyVariants } from 'constants/shared/typography';
import { getFounder } from 'modules/founders/action';
import { selectFounderData } from 'modules/founders/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout, UserInfo, UserPageSkeleton } from 'page-components';
import { Constraint, Tabs } from 'shared-components';

import { UserTypes } from '../constants/user';
import { Company, DealProgressItem } from '../interfaces';
import { getDealsProgressList } from '../modules/deals/action';
import { selectDealsProgressData, selectIsLoadingDeals } from '../modules/deals/selectors';
import FounderCompaniesSnippet from '../page-components/dashboard/dashboard-snippets/founder-companies-snippet/FounderCompaniesSnippet';
import FounderShareExchangesSnippet from '../page-components/dashboard/dashboard-snippets/share-exchange/founder-share-exchanges-snippet/FounderShareExchangesSnippet';
import Typography from '../shared-components/Typography';

const FOUNDER_TABS = (
  isCompaniesLoading: boolean,
  isLoadingDeals: boolean,
  dealsProgress: DealProgressItem[],
  companies?: Company[] | null,
) => [
  {
    id: 'companies',
    name: 'Companies',
    component: () => <FounderCompaniesSnippet isLoading={isCompaniesLoading} companies={companies} />,
  },
  {
    id: 'share-exchanges',
    name: 'Share Exchanges',
    component: () => (
      <FounderShareExchangesSnippet
        isCompaniesLoading={isCompaniesLoading}
        companies={companies}
        dealsProgress={dealsProgress}
        isLoadingDeals={isLoadingDeals}
      />
    ),
  },
];

const Investor = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { data: founder, isLoading } = useAppSelector(selectFounderData);

  const dealsProgress = useAppSelector(selectDealsProgressData);
  const isLoadingDeals = useAppSelector(selectIsLoadingDeals);

  useEffect(() => {
    if (id) {
      dispatch(getFounder(id));
      dispatch(getDealsProgressList({ founderId: id, userType: UserTypes.ADMIN }));
    }
  }, [dispatch, id]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED} className='flex flex-col gap-4'>
        {isLoading ? (
          <UserPageSkeleton />
        ) : (
          <div>
            <Typography tag='h2' variant={TypographyVariants.LARGE}>
              Founder Profile: {`${founder?.firstName} ${founder?.lastName}`}
            </Typography>

            <div className='mt-6'>
              <UserInfo title={FounderProfileInfo.EMAIL_ADDRESS} value={founder?.email} />
              <UserInfo title={FounderProfileInfo.PHONE_NUMBER} value={founder?.phone} />
              {founder?.created_at && (
                <UserInfo
                  title={FounderProfileInfo.CREATED_AT}
                  value={format(parseISO(founder?.created_at), DAY_FORMAT_WITH_SLASHES)}
                />
              )}
            </div>
          </div>
        )}
        <Tabs tabs={FOUNDER_TABS(isLoading, isLoadingDeals, dealsProgress, founder?.companies)} />
      </Constraint>
    </DashboardLayout>
  );
};

export default Investor;
