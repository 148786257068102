import React, { FC, useEffect, useMemo, useRef } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import useInitialTab from 'hooks/use-initial-tab/useInitialTab';
import { TabItem } from 'interfaces';
import Typography from 'shared-components/Typography';

export type Props = {
  tabs: TabItem[];
  initialTabId?: string;
  componentProps?: any;
  locationState?: any;
};

export const TABS_SELECTOR_TEST_ID = 'TABS_SELECTOR_TEST_ID';
export const TABS_SELECTOR_ITEM_TEST_ID = 'TABS_SELECTOR_ITEM_TEST_ID';
export const TABS_COMPONENT_TEST_ID = 'TABS_COMPONENT_TEST_ID';
export const TABS_INDICATOR_TEST_ID = 'TABS_INDICATOR_TEST_ID';

const Tabs: FC<Props> = ({ tabs, componentProps = {}, locationState }) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const tabId = useInitialTab();
  const navigate = useNavigate();
  const { search } = useLocation();

  const activeTab = useMemo(() => {
    const activeTabIndex = tabs.findIndex(({ id }) => id === tabId);
    if (activeTabIndex === -1) return 0;
    return activeTabIndex;
  }, [tabId, tabs]);

  const currentSearch = useMemo(() => (!search.includes('tab') ? `${search.replace('?', '&')}` : ''), [search]);

  const handleMoveIndicator = (tabIndex: number) => {
    const { current } = wrapperRef;

    if (!current) return;

    const childElementByTabIndex = Array.from(current.children)[tabIndex];

    const elementWidth = childElementByTabIndex.clientWidth;
    const elementClientRect = childElementByTabIndex.getBoundingClientRect();
    const elementLeft = elementClientRect.left - current.getBoundingClientRect().left;

    if (indicatorRef.current) {
      indicatorRef.current.style.left = `${elementLeft}px`;
      indicatorRef.current.style.width = `${elementWidth}px`;
    }
  };

  const onSelectTab = (tabIndex: number, newTabId?: string) => {
    const { current } = wrapperRef;
    if (current && newTabId !== tabId) {
      navigate(
        {
          pathname: window.location.pathname,
          search: `?tab=${newTabId}${currentSearch}`,
        },
        { state: locationState },
      );
    }
  };

  useEffect(() => {
    handleMoveIndicator(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (tabId) {
      const tabIndex = tabs.findIndex(({ id }) => id === tabId);
      const tab = tabs.find(({ id }) => id === tabId);
      onSelectTab(tabIndex, tab?.id);
    }

    if (!tabId) navigate({ search: `?tab=${tabs[0].id}${currentSearch}` }, { replace: true, state: locationState });
  }, [locationState, tabId]);

  const TabContentComponent = tabId ? tabs[activeTab]?.component : null;

  return (
    <div>
      <div
        data-testid={TABS_SELECTOR_TEST_ID}
        ref={wrapperRef}
        className='w-full mb-6 flex relative bg-violet-100 rounded-2xl overflow-x-auto px-4'
      >
        {tabs.map(({ id, name }, index) => (
          <div
            data-testid={`${TABS_SELECTOR_ITEM_TEST_ID}_${id}`}
            className={cn('p-4 h-full cursor-pointer', { active: activeTab === index })}
            key={id}
            onClick={() => onSelectTab(index, id)}
          >
            <Typography className={cn('text-grey-400', { 'text-grey-800': activeTab === index })}>{name}</Typography>
          </div>
        ))}
        <div
          data-testid={TABS_INDICATOR_TEST_ID}
          ref={indicatorRef}
          className={cn(`transition ease-linear duration-150 absolute bottom-0 h-0.5 bg-violet-500 left-0`)}
        />
      </div>

      {TabContentComponent && <TabContentComponent data-testid={TABS_COMPONENT_TEST_ID} {...componentProps} />}
    </div>
  );
};

export default Tabs;
