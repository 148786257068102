import React, { useMemo } from 'react';

import { INVESTORS_INVITE_PAGE_TITLE, INVESTORS_INVITE_PAGE_DESCRIPTION } from 'constants/invites';
import { ROUTES } from 'constants/routes';
import { InviteInput } from 'interfaces';
import { inviteInvestor } from 'modules/auth/action';
import { selectInvestorsList } from 'modules/auth/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { HeaderInfo, AuthInviteForm, InvitedList, SkipButtonsGroup, AuthHeader, AuthLayout } from 'page-components';
import { checkIsFounder } from 'utils';

const InvestorsInvite = () => {
  const dispatch = useAppDispatch();
  const investorsList = useAppSelector(selectInvestorsList);

  const isFounder = useMemo(() => checkIsFounder(), []);

  const backPath = isFounder ? ROUTES.companyConfirm : ROUTES.founderInvite;

  const onInvestorInvite = (data: InviteInput) => dispatch(inviteInvestor(data));

  return (
    <AuthLayout customHeader={<AuthHeader hideButtons />}>
      <HeaderInfo backPath={backPath} title={INVESTORS_INVITE_PAGE_TITLE}>
        {INVESTORS_INVITE_PAGE_DESCRIPTION}
      </HeaderInfo>
      <AuthInviteForm invitedCount={investorsList?.length} onInvite={onInvestorInvite} />
      <InvitedList invitationList={investorsList} />
      <SkipButtonsGroup linkPath={ROUTES.welcome} />
    </AuthLayout>
  );
};

export default InvestorsInvite;
