import React, { useCallback, useMemo } from 'react';

import { format } from 'date-fns';

import { SHARE_EXCHANGE_SNIPPET_TITLE } from 'constants/dashboard';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { SHARE_EXCHANGE_STAGES, SHARE_EXCHANGE_STEPS_DESCRIPTIONS } from 'constants/deals';
import { StepperStatuses, stepperStatusesLabels } from 'constants/shared/stepper';
import { Deal } from 'interfaces';
import { selectInvestorDealStages } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { ColoredLabel, SnippetLayout } from 'shared-components';
import Stepper from 'shared-components/stepper-v1/Stepper';
import StepperItem from 'shared-components/stepper-v1/StepperItem';
import StepperItemCircle from 'shared-components/stepper-v1/StepperItemCircle';
import StepperItemDetails from 'shared-components/stepper-v1/StepperItemDetails';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';
import Typography from 'shared-components/Typography';
import { formatStepsProgressData, openUrlInSeparateTab } from 'utils';

interface Props {
  deal: Deal;
}

const DealProgressSnippet = ({ deal }: Props) => {
  const stages = useAppSelector(selectInvestorDealStages);

  // If the step is approval we need to show 'application_form' stage as current step
  const investorStage =
    deal.stage === SHARE_EXCHANGE_STAGES.APPROVAL ? SHARE_EXCHANGE_STAGES.APPLICATION_FORM : deal.stage;

  const passedStagesKeys = useMemo(() => {
    const currentStageIndex = stages.findIndex((i) => i.stage === investorStage);

    const sliceLastIndex = currentStageIndex === stages?.length - 1 ? stages?.length : currentStageIndex;

    return stages.slice(0, sliceLastIndex === -1 ? stages?.length : sliceLastIndex).map(({ stage }) => stage);
  }, [investorStage, stages]);

  const { steps: stepInformation, currentStep } = useMemo(() => {
    const { steps, currentStep } = formatStepsProgressData(stages, investorStage);
    // Decorate steps with descriptions
    const stepsWithDescription = steps.map((step) => ({
      ...step,
      description: step.key ? SHARE_EXCHANGE_STEPS_DESCRIPTIONS[step.key as SHARE_EXCHANGE_STAGES] : null,
    }));
    return { steps: stepsWithDescription, currentStep };
  }, [investorStage, stages]);

  const dealIsStarted = useMemo(() => {
    return passedStagesKeys.includes(stepInformation[0].key);
  }, [stepInformation, passedStagesKeys]);

  const dealIsPending = useMemo(() => {
    const shareExchangeStage = deal.stage;

    return shareExchangeStage === SHARE_EXCHANGE_STAGES.COMPLETED;
  }, [deal]);

  const dealIsDone = useMemo(() => {
    return deal.stage === SHARE_EXCHANGE_STAGES.COMPLETED;
  }, [deal]);

  const getMiddleStepData = useCallback(
    (currentStep: number | null) => {
      let middleStep = currentStep ?? 0;
      // Show 2nd step if current step id 1
      if (currentStep === null || currentStep === 0) {
        middleStep = 1;
      }
      // Show pre last step if current step is completed
      if (currentStep === stepInformation.length - 1) {
        middleStep = stepInformation.length - 2;
      }
      // Show current step if not first and last
      return {
        middleStepnumber: middleStep + 1,
        middleStepTitle: stepInformation[middleStep].text ?? '',
        middleStepDescription: stepInformation[middleStep].description ?? null,
      };
    },
    [stepInformation],
  );

  const steps: {
    step: number;
    title?: string | JSX.Element;
    description?: string;
    link?: string | null;
    isActive?: boolean;
  }[] = useMemo(() => {
    const { middleStepnumber, middleStepTitle, middleStepDescription } = getMiddleStepData(currentStep);
    return [
      {
        step: 1,
        title: stepInformation[0].text ? stepInformation[0].text : '',
        description: stepInformation[0].description ?? '',
        link: currentStep === 0 ? deal.documentsUrls.currentDoc : '',
        isActive: Boolean(dealIsStarted),
      },
      {
        step: middleStepnumber,
        title: middleStepTitle,
        description: middleStepDescription ?? '',
        link:
          currentStep && currentStep > 0 && currentStep < stepInformation.length - 1
            ? deal.documentsUrls.currentDoc
            : '',
        isActive: Boolean(dealIsPending),
      },
      {
        step: stepInformation.length,
        title: stepInformation[stepInformation.length - 1].text,
        description: stepInformation[stepInformation.length - 1].description ?? '',
        link: currentStep === stepInformation.length - 1 ? deal.documentsUrls.currentDoc : '',
        isActive: Boolean(dealIsDone),
      },
    ];
  }, [
    stepInformation,
    currentStep,
    deal.documentsUrls.currentDoc,
    dealIsStarted,
    dealIsPending,
    dealIsDone,
    getMiddleStepData,
  ]);

  if (stepInformation[0].key === 'Pending') {
    return null;
  }

  return (
    <SnippetLayout className='mt-6 overflow-auto'>
      <div className='flex justify-between'>
        <Typography className='text-grey-500'>
          {SHARE_EXCHANGE_SNIPPET_TITLE}: <b>{deal.percentageCompletion}% complete</b>
        </Typography>
        {deal.closingDate ? (
          <Typography className='text-grey-500'>
            Anticipated closing date:{' '}
            <Typography tag='span' className='text-grey-800'>
              {format(new Date(deal.closingDate), DAY_FORMAT_WITH_SLASHES)}
            </Typography>
          </Typography>
        ) : null}
      </div>
      <div className={'w-full mt-6'}>
        <Stepper
          steps={steps}
          isLoading={false}
          renderContent={({ isActive, link, ...stepDetails }: any) => {
            return (
              <StepperItem
                key={stepDetails.step}
                onClick={link ? () => openUrlInSeparateTab(link) : undefined}
                isActive={isActive}
                renderStepContent={({ isActive }) => (
                  <StepperItemCircle status={isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING} />
                )}
              >
                <StepperItemDetails step={stepDetails.step} {...stepDetails}>
                  <ColoredLabel
                    className='mt-4'
                    {...stepperStatusesLabels[isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING]}
                  />
                </StepperItemDetails>
              </StepperItem>
            );
          }}
          renderSkeleton={() => <StepperSkeleton count={3} withDetails />}
        />
      </div>
    </SnippetLayout>
  );
};

export default DealProgressSnippet;
