import React, { FC } from 'react';

import { DUE_DILIGENCE_INVITATION_PROPOSAL_MODAL_DESCRIPTION } from 'constants/due-diligence';
import { TypographyVariants } from 'constants/shared/typography';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { ButtonsControl, ModalWindow } from 'shared-components';

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  handleOpenInvitationModal: VoidFunction;
}

const InvitationProposalModal: FC<Props> = ({ handleOpenInvitationModal, ...modalProps }) => {
  const onSubmit = () => {
    modalProps.onClose();
    handleOpenInvitationModal();
  };

  return (
    <ModalWindow
      className='lg:w-8/12 xl:w-6/12 lg:h-12/12'
      wrapperClassName='pt-14'
      title={DUE_DILIGENCE_INVITATION_PROPOSAL_MODAL_DESCRIPTION}
      titleVariant={TypographyVariants.LARGE}
      {...modalProps}
    >
      <ButtonsControl
        className='flex-col xs:flex-row gap-4 xs:gap-0'
        backButtonText='Back'
        submitButtonText='Yes'
        onBack={modalProps.onClose}
        onSubmit={onSubmit}
      />
    </ModalWindow>
  );
};

export default InvitationProposalModal;
