import React from 'react';

import { INVOICES_ADMIN_PAGE_TITLE } from 'constants/my-bills';
import { ConstraintVariants } from 'constants/shared';
import { TabItem } from 'interfaces';
import { DashboardLayout, InvoicesTable } from 'page-components';
import { Constraint, Tabs, TitleWithDescription } from 'shared-components';

export const INVOICES_PAGE_TABS: TabItem[] = [
  {
    id: 'invoices',
    name: 'Invoices',
    component: InvoicesTable,
  },
];

const Invoices = () => {
  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription className='mb-6' title={INVOICES_ADMIN_PAGE_TITLE} />
        <Tabs tabs={INVOICES_PAGE_TABS} />
      </Constraint>
    </DashboardLayout>
  );
};

export default Invoices;
