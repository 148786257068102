import React, { useEffect } from 'react';

import { DASHBOARD_PIE_CHART_COLORS } from 'constants/dashboard';
import { getAdminInvoicesMetrics } from 'modules/bills/action';
import { selectInvoicesMetrics } from 'modules/bills/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ChartInfoItemWithColoredRect } from 'shared-components';
import { ChartMetricsLayout, PieChart, PieChartActiveShapeV2 } from 'shared-components/chart';
import { convertObjectToChartData } from 'utils';

const AdminInvoicesSnippetContent = () => {
  const dispatch = useAppDispatch();
  const { isLoading, amountAll, amountPaid, amountPaymentRequested } = useAppSelector(selectInvoicesMetrics);

  useEffect(() => {
    if (!amountAll) dispatch(getAdminInvoicesMetrics());
  }, [amountAll, dispatch]);

  return (
    <div className='w-full flex flex-col items-center'>
      <PieChart
        isLoading={isLoading}
        colors={DASHBOARD_PIE_CHART_COLORS}
        data={convertObjectToChartData({ amountPaid, amountPaymentRequested })}
        activeShape={<PieChartActiveShapeV2 title='No. Invoices' customValue={amountAll} />}
      />
      <ChartMetricsLayout className='mt-6' isLoading={isLoading}>
        <ChartInfoItemWithColoredRect title='Paid' value={amountPaid} rectClassName='bg-lightGreen-700' />
        <ChartInfoItemWithColoredRect title='Awaiting Payment' value={amountPaymentRequested} />
      </ChartMetricsLayout>
    </div>
  );
};

export default AdminInvoicesSnippetContent;
