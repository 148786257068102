import React from 'react';

const SKELETON_ITEMS_LENGTH = 6;

const DealsDetailsOutstandingTasksSkeleton = () => {
  return (
    <div className='w-full space-y-20 pt-12 pb-6 px-7'>
      <div className='skeleton-loader w-3/6 h-10 mb-7.5' />
      <div>
        {[...Array(SKELETON_ITEMS_LENGTH)].map((_, index) => (
          <div className='flex justify-between items-center pr-6 mb-20' key={index}>
            <div className='skeleton-loader w-2/6 h-6' />
            <div className='skeleton-loader w-150 h-11' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealsDetailsOutstandingTasksSkeleton;
