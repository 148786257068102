import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { GetBillsPayload, GetListPayload, GetCardlessPayUrlPayload, InvoicesMetrics } from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class BillsApis {
  constructor(private url: string) {}

  getBills(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<GetBillsPayload>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  getCardlessLink(invoiceId: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<GetCardlessPayUrlPayload>(`${this.url}/${invoiceId}/billing`, config);
  }

  downloadBill(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.get(`${this.url}/${id}/download`, { ...config, responseType: 'blob' });
  }

  getAdminInvoicesMetrics(config?: AxiosRequestConfig) {
    return httpApiClient.get<InvoicesMetrics>(`${this.url}/invoice`, config);
  }
}

export const invoicesApis = new BillsApis('/investors/invoices');
export const adminInvoicesApis = new BillsApis('/admin/invoices');
export const adminInvoicesMetricsApis = new BillsApis('/admin/metrics');
