import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { ConstraintVariants, ConstraintVariantsStyles } from 'constants/shared';

export const CONSTRAINT_TEST_ID = 'constraint';

export interface Props {
  children: ReactNode;
  variant: ConstraintVariants;
  className?: string;
}

const Constraint: FC<Props> = ({ children, className, variant }) => {
  return (
    <div
      className={twMerge('mx-auto p-4', ConstraintVariantsStyles[variant], className)}
      data-testid={CONSTRAINT_TEST_ID}
    >
      {children}
    </div>
  );
};

export default Constraint;
