import React, { forwardRef, TextareaHTMLAttributes, useRef, useEffect, ChangeEvent, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { TEXT_INPUT_CLASSNAMES } from 'constants/shared';
import { FromItemHigligtedColor } from 'interfaces';
import HighlightedMessage from 'shared-components/higlighted-message/HighlightedMessage';

import { ErrorMessage } from '../index';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textAreaClassName?: string;
  labelClassName?: string;
  anchorPrefix?: string;
  error?: string;
  isAbsoluteError?: boolean;
  placeholder?: string;
  numberOfCharacters?: number;
  value?: string;
  highlighted?: boolean;
  highlightedDescription?: string;
  highlightedColor?: FromItemHigligtedColor;
}

const TEXT_AREA_HEIGHT_OFFSET = 2;

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      labelClassName,
      textAreaClassName,
      className,
      error,
      isAbsoluteError,
      onChange,
      placeholder,
      numberOfCharacters,
      value,
      highlighted,
      highlightedDescription,
      highlightedColor = FromItemHigligtedColor.Blue,
      ...textAreaProps
    },
    ref,
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null);
    const [text, setText] = useState('');

    // We need to keep local ref to be able to set the height of the textarea
    const setRef = (node: HTMLTextAreaElement) => {
      if (node) {
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }

        localRef.current = node;
      }
    };

    const handleChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = ev.target;
      if (numberOfCharacters && value.length <= numberOfCharacters) {
        setText(value);
      }

      if (onChange) {
        onChange(ev);
      }

      if (localRef.current && localRef.current.style) {
        localRef.current.style.height = 'auto'; // When we set auto to height we handle case when we delete a row
        localRef.current.style.height = `${ev.target.scrollHeight + TEXT_AREA_HEIGHT_OFFSET}px`;
      }
    };

    // We need this to set the height of the textarea during initiating.
    // Also this effect fires for example when onBlur event happens
    useEffect(() => {
      const scrollHeight = localRef.current?.scrollHeight;

      // Set the height of the textarea to match the scroll height
      if (scrollHeight && localRef.current && localRef.current.style) {
        localRef.current.style.height = `${scrollHeight + TEXT_AREA_HEIGHT_OFFSET}px`;
      }
    }, [localRef.current?.scrollHeight]);

    useEffect(() => {
      if (localRef.current && localRef.current.style) {
        localRef.current.style.height = 'auto';

        const scrollHeight = localRef.current?.scrollHeight;
        localRef.current.style.height = `${scrollHeight + 2}px`;
      }
    }, [textAreaProps.disabled]);

    return (
      <div className={className}>
        {label && <div className={cn('text-blue-800 mb-1', labelClassName)}>{label}</div>}

        <div className='relative'>
          <textarea
            value={value}
            ref={setRef}
            onChange={handleChange}
            placeholder={placeholder}
            maxLength={numberOfCharacters}
            data-hj-allow
            className={twMerge(
              cn(
                'resize-none w-full px-2 py-3 border border-grey-300 rounded min-h-[75px] overflow-hidden rounded-lg data-hj-allow',
                {
                  'rounded border-red-500': error,
                  [TEXT_INPUT_CLASSNAMES.highlighted[highlightedColor]]: !error && highlighted,
                  [TEXT_INPUT_CLASSNAMES.disabled]: textAreaProps?.disabled,
                },
                textAreaClassName,
              ),
            )}
            {...textAreaProps}
          />
          {numberOfCharacters ? (
            <div className='absolute bottom-2 right-2 text-s text-grey-500'>
              {text.length}/{numberOfCharacters}
            </div>
          ) : null}
        </div>

        <ErrorMessage className='input-error-message' error={error} isAbsoluteError={isAbsoluteError} />

        {!error && highlightedDescription && (
          <HighlightedMessage className='input-error-message' color={highlightedColor}>
            {highlightedDescription}
          </HighlightedMessage>
        )}
      </div>
    );
  },
);

export default TextArea;

TextArea.displayName = 'TextArea';
