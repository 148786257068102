import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import { ReactComponent as InformationIcon } from 'assets/svg/info.svg';
import { TypographyVariants } from 'constants/shared/typography';
import IconWithTooltip from 'shared-components/IconWithTooltip';
import Typography from 'shared-components/Typography';

export const TITLE_TEST_ID = 'title-test';

export interface Props {
  title: string;
  description?: string | ReactNode;
  className?: string;
  descriptionClassName?: string;
  tooltip?: boolean;
  tooltipText?: string | ReactNode;
  anchorId?: string;
  titleVariant?: TypographyVariants;
}

const TitleWithDescription: FC<Props> = ({
  title,
  description,
  className,
  descriptionClassName,
  tooltip,
  tooltipText,
  anchorId,
  titleVariant,
}) => {
  return (
    <div className={className}>
      <div className='flex items-start'>
        <Typography tag='h4' variant={titleVariant || TypographyVariants.LARGE} data-testid={TITLE_TEST_ID}>
          {title}
        </Typography>
        {tooltip && anchorId && (
          <IconWithTooltip
            wrapperClassName='ml-2.5'
            tooltipContent={tooltipText}
            anchorId={anchorId}
            place='bottom-start'
            icon={<InformationIcon />}
          />
        )}
      </div>
      {typeof description === 'string' ? (
        <Typography className={cn('py-6', descriptionClassName)}>{description}</Typography>
      ) : (
        description
      )}
    </div>
  );
};

export default TitleWithDescription;
