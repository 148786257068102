import React from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ENTITY_INVITE_DIRECTOR_FORM_FIELDS } from 'constants/entities';
import { InvestorSubTypes, InviteUserType } from 'constants/user';
import { EntityInviteDirectorInput } from 'interfaces';
import { selectUser } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { sendDirectorEntityInvite } from 'modules/users/action';
import { selectIsLoading } from 'modules/users/selectors';
import { Button, FormContent } from 'shared-components';

import { getEntityInvestors } from '../../modules/current-user/action';

interface Props {
  onModalClose: VoidFunction;
}

const EntityInviteDirectorForm = ({ onModalClose }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const isLoading = useAppSelector(selectIsLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EntityInviteDirectorInput>();

  const onSubmit: SubmitHandler<EntityInviteDirectorInput> = (data) => {
    const type = InvestorSubTypes.ENTITY;
    const inviteType = InviteUserType.INVESTOR_TO_ENTITY_COMPANY;
    const companyId = user?.entityCompany?.id;

    if (companyId) {
      dispatch(sendDirectorEntityInvite({ type, inviteType, companyId: +companyId, ...data }))
        .unwrap()
        .then(() => {
          onModalClose();
          dispatch(getEntityInvestors({ id: companyId, page: 1, per_page: 1000 }));
          reset();
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContent
        fields={ENTITY_INVITE_DIRECTOR_FORM_FIELDS}
        register={register}
        errors={errors}
        isDisabled={isLoading}
      />
      <Button className='mt-6' isLoading={isLoading}>
        Send Invite
      </Button>
    </form>
  );
};

export default EntityInviteDirectorForm;
