import React, { FC } from 'react';

import { INVESTMENT_BATCH_UPLOAD_TOOLTIP } from 'constants/investments';
import { ButtonVariants } from 'constants/shared/button';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { Button, ButtonWithTooltip } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

type Props = {
  onBack: VoidFunction;
  openUploadModal: VoidFunction;
  isLoading?: boolean;
};

const TOOLTIP_BUTTON_ID = ' batch-upload-tooltip';

const InvestmentsButtonsControl: FC<Props> = ({ isLoading, onBack, openUploadModal }) => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  return (
    <div className='flex flex-col xs:flex-row gap-2 xs:gap-0 xs:mt-[75px]'>
      <Button variant={ButtonVariants.SECONDARY} className='w-full xs:w-150 xs:mr-4' onClick={onBack}>
        Back
      </Button>

      <div className='flex relative'>
        <ButtonWithTooltip
          tooltipContent={getTitleWithReplacedYourWord(INVESTMENT_BATCH_UPLOAD_TOOLTIP, entityName, isTypeEntity)}
          variant={ButtonVariants.SECONDARY}
          onClick={openUploadModal}
          isLoading={isLoading}
          wrapperClassName='w-full'
          className='w-full xs:w-[250px] xs:mr-5'
          anchorId={TOOLTIP_BUTTON_ID}
          offset={10}
        >
          Batch Upload
        </ButtonWithTooltip>
      </div>
    </div>
  );
};

export default InvestmentsButtonsControl;
