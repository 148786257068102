import { adminCompanyDueDiligenceApis, fmUsersCompaniesDueDiligenceApis, foundersCompanyDueDiligenceApis } from 'apis';
import {
  DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE,
  DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE,
} from 'constants/due-diligence';
import { UserTypes } from 'constants/user';
import { checkDateOverlap, checkStrictTimeline, getTotalYears } from 'helpers';
import {
  DueDiligencePersonInput,
  DueDiligencePersonsFormNames,
  FormItem,
  FromItemHigligtedColor,
  GetListPayload,
  SignificantControlPerson,
  SignificantControlPersonAddressInput,
} from 'interfaces';

import { getUnfieldValueFunc } from '../hooks/useDueDiligenceValidation';

export const getDueDiligenceRequestByUserType = (
  userType: UserTypes,
  companyId: string,
  dueDiligenceId?: string | undefined,
) => {
  if (userType === UserTypes.ADMIN && dueDiligenceId) {
    return adminCompanyDueDiligenceApis.getDueDiligenceByDDId({ companyId, dueDiligenceId });
  }

  switch (userType) {
    case UserTypes.ADMIN:
      return adminCompanyDueDiligenceApis.getDueDiligenceData(companyId);
    case UserTypes.FM_USER:
      return fmUsersCompaniesDueDiligenceApis.getDueDiligenceData(companyId);
    default:
      return foundersCompanyDueDiligenceApis.getDueDiligenceData(companyId);
  }
};

export const getDueDiligenceDocumentsRequestByUserType = (
  userType: UserTypes,
  payload: GetListPayload & { id: string },
) => {
  switch (userType) {
    case UserTypes.ADMIN:
      return adminCompanyDueDiligenceApis.getDueDiligenceDocuments(payload);
    case UserTypes.FM_USER:
      return fmUsersCompaniesDueDiligenceApis.getDueDiligenceDocuments(payload);
    default:
      return foundersCompanyDueDiligenceApis.getDueDiligenceDocuments(payload);
  }
};

export const getDueDiligencePersonsResourcePath = (name: DueDiligencePersonsFormNames) => {
  switch (name) {
    case 'beneficialOwners':
      return 'beneficial-owners';

    default:
      return 'significant-control-persons';
  }
};

const getDateRanges = (residentialAddresses: SignificantControlPersonAddressInput[]) =>
  residentialAddresses
    .filter(({ from, to, tillNow }) => from && (to || tillNow))
    .map(({ from, to, tillNow }) => ({ from, to, tillNow }));

export const hasDirectorWithInvalidSubmittedDateRanges = (persons: DueDiligencePersonInput[]) =>
  persons.find(({ residentialAddresses }) => {
    const dateRanges = getDateRanges(residentialAddresses);

    if (dateRanges.length === 0) {
      return false;
    }

    const totalYears = getTotalYears(dateRanges);
    const isStrictTimeline = checkStrictTimeline(dateRanges);

    return totalYears < 3 || !isStrictTimeline;
  });

export const hasDirectorWithOverlappingDates = (persons: DueDiligencePersonInput[]) =>
  persons.find(({ residentialAddresses }) => {
    const dateRanges = getDateRanges(residentialAddresses);

    return checkDateOverlap(dateRanges);
  });

export const validateDirectorsAddresses = (directors: SignificantControlPerson[]): string | false => {
  const directorWithOverlappingDates = hasDirectorWithOverlappingDates(directors);
  const directorWithInvalidDateRange = hasDirectorWithInvalidSubmittedDateRanges(directors);

  if (directorWithOverlappingDates) {
    return `${DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE} ${directorWithOverlappingDates.fullName}`;
  }

  if (directorWithInvalidDateRange) {
    return `${DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE} ${directorWithInvalidDateRange.fullName}`;
  }

  return false;
};

export const highlightDDFields = (
  fields: FormItem[],
  getUnfieldValue: getUnfieldValueFunc,
  requiredFields?: string[],
): FormItem[] => {
  return fields.map((field): FormItem => {
    const unfieldValue = getUnfieldValue(field.name);

    const isRequiredField = requiredFields?.length ? requiredFields.includes(field.name) : true;

    return {
      ...field,
      highlighted: !!unfieldValue && isRequiredField,
      highlightedDescription: unfieldValue?.description,
      highlightedColor: unfieldValue?.asError ? FromItemHigligtedColor.Red : undefined,
    };
  });
};
