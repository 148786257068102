import { UserTypes } from 'constants/user';

export interface InviteInput {
  email: string;
  firstName: string;
  lastName: string;
  type: UserTypes;
}

export interface InvitedItem extends InviteInput {
  id: number;
  created_at: string;
  updated_at: string;
}

export type InvitedResponse = {
  data: InvitedItem;
};

export enum DueDiligenceGenerateInviteLinkFieldNames {
  EMAIL = 'email',
}

export interface DueDiligenceGenerateInviteLinkInput {
  [DueDiligenceGenerateInviteLinkFieldNames.EMAIL]: string;
}

export interface GenerateDueDiligenceInviteFounderLinkPayload {
  companyId: number;
  email: string;
}
