import React from 'react';

import { BUSINESS_PROFILE_PAGE_TABS } from 'constants/business-profile';
import { ConstraintVariants } from 'constants/shared';
import { BusinessProfileHeader, DashboardLayout } from 'page-components';
import { Constraint, Tabs } from 'shared-components';

const BusinessProfile = () => {
  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <BusinessProfileHeader />
        <Tabs tabs={BUSINESS_PROFILE_PAGE_TABS} />
      </Constraint>
    </DashboardLayout>
  );
};

export default BusinessProfile;
