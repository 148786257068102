import React, { ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

interface Props {
  isActive?: boolean;
  className?: string;
  children?: ReactNode;
  renderStepContent: ({ isActive }: { isActive?: boolean }) => ReactNode;
  onClick?: VoidFunction;
  lineClassName?: string;
  isError?: boolean;
}

const StepperItem = ({ isActive, renderStepContent, onClick, children, className, lineClassName, isError }: Props) => {
  isError;
  return (
    <li
      className={cn(
        'flex w-full relative text-grey-600 relative mr-6 last:mr-0',
        { 'cursor-pointer': onClick },
        className,
      )}
      onClick={onClick}
    >
      <div className='block whitespace-nowrap z-10 w-full'>
        <div className='flex items-center'>
          {renderStepContent({ isActive })}
          <div
            className={twMerge(
              cn('w-full h-0.5 bg-grey-200 inline-block ml-4', {
                'bg-lightGreen-700': isActive,
                'bg-red-700': isError,
              }),
              lineClassName,
            )}
          />
        </div>
        <>{children}</>
      </div>
    </li>
  );
};

export default StepperItem;
