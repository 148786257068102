import React, { FC, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { QUALIFICATION_QUESTIONS_ERROR_MESSAGE } from 'constants/my-investments';
import { ButtonVariants } from 'constants/shared/button';
import { QualificationQuestion } from 'interfaces';
import { Button, ErrorMessage, RadioInputWithLabel } from 'shared-components';
import Typography from 'shared-components/Typography';

import ShareExchangeQualificationQuestionsSkeleton from './ShareExchangeQualificationQuestionsSkeleton';

interface Props {
  loading: boolean;
  onSubmitAnswers: (data: Record<string, string>) => void;
  onClickCancel: VoidFunction;
  questions: QualificationQuestion[];
}

const ShareExchangeQualificationQuestionsForm: FC<Props> = ({ loading, onSubmitAnswers, questions, onClickCancel }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<Record<string, string>>();

  const hasErrors = !!Object.values(errors).length;

  const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
    onSubmitAnswers(data);
  };

  useEffect(() => {
    const questionsDefaultValues = questions.reduce((questions, question) => ({ ...questions, [question.id]: '' }), {});
    reset(questionsDefaultValues);
  }, [questions, reset]);

  return (
    <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <ShareExchangeQualificationQuestionsSkeleton />
      ) : (
        <div>
          {questions?.map((question) => (
            <div key={question.id} className='flex justify-between mb-[50px]'>
              <Typography>{question.title}</Typography>
              <div className='pr-[50px]'>
                <div className='flex flex-col'>
                  {question?.answers?.length &&
                    question?.answers?.map((answer, answerIndex) => (
                      <RadioInputWithLabel
                        key={answer.title}
                        id={answer.title}
                        value={answerIndex}
                        label={answer.title}
                        {...register(question.id.toString(), { required: true })}
                      />
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <ErrorMessage className='mt-4 mb-[50px]' error={hasErrors ? QUALIFICATION_QUESTIONS_ERROR_MESSAGE : undefined} />

      <div className='flex justify-between'>
        <Button
          type='button'
          className='w-150'
          variant={ButtonVariants.SECONDARY}
          isLoading={loading}
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button disabled={hasErrors} type='submit' className='w-250' isLoading={loading}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default ShareExchangeQualificationQuestionsForm;
