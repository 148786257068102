import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { COMPANY_IAC_INVITING_FUND_ADD_NEW_FIELDS } from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { UserTypes } from 'constants/user';
import { AddNewFmUser, UserData } from 'interfaces';
import { Button, FormContent } from 'shared-components';

interface Props {
  handleCloseAddForm: VoidFunction;
  handleAddNewUser: (user: UserData) => void;
}

const InvitingFundModalAddForm: FC<Props> = ({ handleCloseAddForm, handleAddNewUser }) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<AddNewFmUser>({ defaultValues: { email: '', firstName: '', lastName: '', addAnother: false } });

  const onSubmit: SubmitHandler<AddNewFmUser> = ({ addAnother, ...values }) => {
    handleAddNewUser({
      ...values,
      id: new Date().getTime(),
      userType: UserTypes.FM_USER,
    } as UserData);

    if (!addAnother) handleCloseAddForm();

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContent
        fields={COMPANY_IAC_INVITING_FUND_ADD_NEW_FIELDS}
        register={register}
        control={control}
        errors={errors}
      />
      <div className='flex items-end space-x-4'>
        <Button className='w-300' type='button' onClick={handleCloseAddForm} variant={ButtonVariants.SECONDARY}>
          Back
        </Button>
        <Button className='mt-10'>Add User</Button>
      </div>
    </form>
  );
};

export default InvitingFundModalAddForm;
