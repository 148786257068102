import React, { FC } from 'react';

import { DELETE_REVIEW_INVESTMENT_MODAL_TITLE } from 'constants/investments';
import { ReviewInvestment } from 'interfaces';
import { deleteReviewInvestment } from 'modules/investments/action';
import { useAppDispatch } from 'modules/store';
import { AlertModal } from 'shared-components';

import DeleteInvestmentModalContent from './DeleteInvestmentModalContent';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  investment: ReviewInvestment | null;
};

const DeleteInvestmentModal: FC<Props> = ({ isOpen, onClose, investment }) => {
  const dispatch = useAppDispatch();

  const onSubmitDelete = () => {
    if (!investment) {
      return;
    }

    dispatch(deleteReviewInvestment(investment));
    onClose();
  };

  return (
    <AlertModal
      secondModal
      isLoading={false}
      onSubmit={onSubmitDelete}
      onClose={onClose}
      isOpen={isOpen}
      title={DELETE_REVIEW_INVESTMENT_MODAL_TITLE}
      description={<DeleteInvestmentModalContent investment={investment} />}
      backButtonText='No'
      submitButtonText='Yes'
      wrapperModalClassName='lg:px-4 2xl:px-14'
    />
  );
};

export default DeleteInvestmentModal;
