import React, { ChangeEvent, FC, InputHTMLAttributes } from 'react';

import cn from 'classnames';
import { Control, Controller } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SmallInformationIcon } from 'assets/svg/small-info.svg';
import { INTEGER_WITH_DOTS_PATTERN } from 'constants/global';
import { FieldTypes, TEXT_INPUT_CLASSNAMES } from 'constants/shared';
import useModal from 'hooks/use-modal/useModal';
import { FormItem, FromItemHigligtedColor, SharedHighlighted } from 'interfaces';
import { FormLabel, ErrorMessage } from 'shared-components/index';
import { formatNumberInputValue, removeComaFromLocaleString } from 'utils/shared';

import { FieldTooltipVariants } from '../constants/shared/input';
import HighlightedMessage from './higlighted-message/HighlightedMessage';
import IconWithTooltip from './IconWithTooltip';
import PrefixSign from './PrefixSign';

export const NUMBERED_INPUT_TEST_ID = 'NUMBERED_INPUT_TEST_ID';

type Props = InputHTMLAttributes<HTMLInputElement> &
  SharedHighlighted & {
    control: Control;
    className?: string;
    labelClassName?: string;
    inputClassName?: string;
    anchorPrefix?: string;
    tooltipVariant?: FieldTooltipVariants;
  } & Pick<
    FormItem,
    | 'type'
    | 'label'
    | 'name'
    | 'validation'
    | 'disabled'
    | 'required'
    | 'isAbsoluteError'
    | 'fractionLength'
    | 'tooltipContent'
    | 'symbol'
  >;

const NumberInput: FC<Props> = ({
  control,
  validation,
  name,
  label,
  fractionLength,
  className,
  labelClassName,
  required,
  isAbsoluteError,
  inputClassName,
  placeholder,
  disabled,
  anchorPrefix,
  tooltipContent,
  symbol,
  highlightedColor = FromItemHigligtedColor.Blue,
  tooltipVariant = FieldTooltipVariants.WHOLE_INPUT,
  highlightedDescription,
  highlighted,
}) => {
  const { isOpen: isTooltipOpen, onOpen: handleOpenTooltip, onClose: handleCloseTooltip } = useModal(false);

  const anchorId = `${anchorPrefix}-${name}`;

  const onChangeInput = (formOnChange: (event: any) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    formOnChange(removeComaFromLocaleString(event.target.value).replace(INTEGER_WITH_DOTS_PATTERN, ''));
  };

  return (
    <div
      className={twMerge('mb-6 w-full', className)}
      id={tooltipVariant === FieldTooltipVariants.WHOLE_INPUT ? anchorId : undefined}
      onMouseEnter={handleOpenTooltip}
      onMouseLeave={handleCloseTooltip}
    >
      <div className='flex gap-2'>
        {label && (
          <FormLabel className={labelClassName} htmlFor={name}>
            <>
              {label}
              {required && '*'}
            </>
          </FormLabel>
        )}
        {tooltipVariant === FieldTooltipVariants.WITH_ICON && typeof tooltipContent === 'string' && (
          <IconWithTooltip
            wrapperClassName='ml-[5px] cursor-pointer'
            tooltipClassName='font-normal'
            tooltipContent={tooltipContent}
            anchorId={anchorId}
            offset={1}
            place='top'
            icon={<SmallInformationIcon />}
          />
        )}
      </div>
      <Controller
        control={control}
        name={name}
        rules={validation}
        defaultValue=''
        render={({ field: { ref, value, onChange, ...field }, fieldState: { error } }) => (
          <>
            <div className='flex'>
              {symbol && <PrefixSign prefixSign={symbol} disabled={disabled} />}
              <input
                {...field}
                value={value ? formatNumberInputValue(value, fractionLength) : ''}
                type={FieldTypes.TEXT}
                onChange={onChangeInput(onChange)}
                placeholder={placeholder}
                disabled={disabled}
                data-hj-allow
                className={cn(
                  TEXT_INPUT_CLASSNAMES.default,
                  {
                    [TEXT_INPUT_CLASSNAMES.error]: error,
                    [TEXT_INPUT_CLASSNAMES.highlighted[highlightedColor]]: !error && highlighted,
                    [TEXT_INPUT_CLASSNAMES.disabled]: disabled,
                    'rounded-l-none': symbol,
                  },
                  inputClassName,
                )}
                data-testid={NUMBERED_INPUT_TEST_ID}
              />
            </div>
            <ErrorMessage error={error?.message} isAbsoluteError={isAbsoluteError} />

            {!error && highlightedDescription && (
              <HighlightedMessage className='input-error-message' color={highlightedColor}>
                {highlightedDescription}
              </HighlightedMessage>
            )}
          </>
        )}
      />
      {tooltipVariant === FieldTooltipVariants.WHOLE_INPUT && tooltipContent && isTooltipOpen && (
        <Tooltip
          anchorId={anchorId}
          positionStrategy='fixed'
          className='text-sm text-grey-500'
          isOpen
          clickable
          noArrow
        >
          {tooltipContent}
        </Tooltip>
      )}
    </div>
  );
};

export default NumberInput;
