import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import Typography from 'shared-components/Typography';

import { TypographyVariants } from '../../../constants/shared/typography';

type Props = {
  blockNumber?: string;
  title: string;
  className?: string;
  highlightedUnsavedChanges?: boolean;
  informationTooltip?: ReactNode;
};

const DueDiligenceBlockTitle: FC<Props> = ({
  className,
  blockNumber,
  title,
  highlightedUnsavedChanges,
  informationTooltip,
}) => {
  return (
    <div
      className={cn(
        'text-xl text-blue-800 flex',
        { 'text-blue-800': !highlightedUnsavedChanges, 'text-red-500': highlightedUnsavedChanges },
        className,
      )}
    >
      {blockNumber && (
        <Typography className='pr-2' tag='span' variant={TypographyVariants.MEDIUM}>
          {blockNumber}
        </Typography>
      )}
      <Typography tag='span' variant={TypographyVariants.MEDIUM}>
        {title}
      </Typography>
      {informationTooltip || null}
    </div>
  );
};

export default DueDiligenceBlockTitle;
