import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { TypographyStyles, TypographyVariants } from 'constants/shared/typography';

export interface Props {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'label';
  variant?: TypographyVariants;
  className?: string;
  children: ReactNode | string;
}

export const TYPOGRAPHY_TEST_ID = 'TYPOGRAPHY_TEST_ID';

const Typography = ({ tag = 'p', variant = TypographyVariants.BODY_NORMAL, className, children }: Props) => {
  const TypographyTag = tag;

  return (
    <TypographyTag data-testid={TYPOGRAPHY_TEST_ID} className={twMerge(TypographyStyles[variant], className)}>
      {children}
    </TypographyTag>
  );
};

export default Typography;
