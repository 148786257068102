import React, { FC } from 'react';

import cn from 'classnames';

import { LINK_SHARED_STYLES } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import Typography from 'shared-components/Typography';

interface Props {
  id: number;
  sendInvitation: (id: number) => void;
  disabledInvitation: boolean;
}

const UsersRegisteredTooltipContent: FC<Props> = ({ id, sendInvitation, disabledInvitation }) => {
  const onSendInvitationClick = () => sendInvitation(id);

  return (
    <div className='max-w-sm flex items-center justify-between'>
      <Typography variant={TypographyVariants.BODY_SMALL}>
        This user hasn’t registered yet. <br />
        Please send him a notice to do this
      </Typography>
      <button
        onClick={onSendInvitationClick}
        className={cn('p-0 ml-7', LINK_SHARED_STYLES, {
          'disabled:bg-transparent disabled:text-grey-500 disabled:hover:text-grey-500': disabledInvitation,
        })}
        disabled={disabledInvitation}
      >
        Send invitation
      </button>
    </div>
  );
};

export default UsersRegisteredTooltipContent;
