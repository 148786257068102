import { ChangeEvent, useRef } from 'react';

type EventType = ChangeEvent<HTMLInputElement>;

const useDebouncedInputChange = (onChange: (value: string) => void, timeout = 300): ((e: EventType) => void) => {
  const timeoutRef = useRef<any>(null);

  return (ev: EventType) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = setTimeout(() => {
      onChange(ev.target.value);
    }, timeout);
  };
};

export default useDebouncedInputChange;
