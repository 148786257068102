import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { CompanyNatureOfBusiness } from 'interfaces';

interface Props {
  data: CompanyNatureOfBusiness[];
  className?: string;
  isLoading?: boolean;
  title?: string;
}

const NatureOfBusiness: FC<Props> = ({ data, className, isLoading, title }) => {
  if (isLoading) return <div className='skeleton-loader h-24 w-full mb-6' />;

  return (
    <div className={twMerge('pt-10 w-full sm:w-1/2', className)}>
      <h4 className='text-blue-800 text-xl'>{title || 'Nature of Business (SIC)'}</h4>
      <ul className='my-6'>
        {data?.length
          ? data.map(({ code, name }) => (
              <li key={code} className='text-grey-500'>
                {code} - {name}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

export default NatureOfBusiness;
