import React, { FC, useState } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { BILL_STATUS_COLORED_LABEL_STYLE } from 'constants/my-bills';
import { Bill, ButtonDropdownActions } from 'interfaces';
import { downloadInvoice } from 'modules/bills/action';
import { useAppDispatch } from 'modules/store';
import { ButtonDropdown, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

const InvoicesTableItem: FC<Bill> = ({
  id,
  invoiceNumber,
  invoiceReference,
  dealId,
  companyName,
  totalAmount,
  investor,
  dateCreated,
  status,
}) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean | null>(null);

  const onDownloadInvoice = () => {
    setIsLoading(true);
    dispatch(downloadInvoice({ id, invoiceReference })).finally(() => setIsLoading(false));
  };

  const invoiceActions = [
    {
      title: 'Download invoice',
      id: 'download_invoice',
      action: onDownloadInvoice,
      isOpenOnAction: true,
    },
  ] as ButtonDropdownActions[];

  return (
    <TableRow>
      <TableData className='pl-1'>
        <ButtonDropdown className='w-40' actions={invoiceActions} loading={isLoading} />
      </TableData>
      <TableData>{invoiceNumber}</TableData>
      <TableData>{invoiceReference}</TableData>
      <TableData>{dealId}</TableData>
      <TableData>{companyName}</TableData>
      <TableData>{investor}</TableData>
      <TableData>£{totalAmount}</TableData>
      <TableData>{format(parseISO(dateCreated), DAY_FORMAT_WITH_SLASHES)}</TableData>
      <TableData>
        <ColoredLabel {...BILL_STATUS_COLORED_LABEL_STYLE[status]} />
      </TableData>
    </TableRow>
  );
};

export default InvoicesTableItem;
