import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { getMyBillsPageTable, PayBillModalTypes } from 'constants/my-bills';
import { Bill, UpdateSortingFunctionType } from 'interfaces';
import Table, { TableSkeleton } from 'shared-components/table';

import BillsTableItem from './BillsTableItem';
import NoBillsAdded from './NoBillsAdded';

interface Props {
  bills: Bill[];
  setSelectedBill: Dispatch<SetStateAction<Bill | null>>;
  selectedBill: Bill | null;
  isLoading: boolean;
  updateSorting: UpdateSortingFunctionType;
  showBillModal: (modalType: PayBillModalTypes, bill: Bill) => void;
}

const BillsTable: FC<Props> = ({ bills, isLoading, selectedBill, setSelectedBill, updateSorting, showBillModal }) => {
  const handleSelectBillById = useCallback(
    (bill: Bill): void => {
      setSelectedBill((prev) => (prev?.id === bill.id ? null : { ...bill }));
    },
    [setSelectedBill],
  );

  const additionalBillsTableColumn = {
    id: '',
    title: '',
    className: 'w-10',
  };

  return (
    <Table columns={getMyBillsPageTable(additionalBillsTableColumn)} onUpdateSorting={updateSorting}>
      {bills?.length && !isLoading ? (
        <>
          {bills.map((bill) => (
            <BillsTableItem
              key={bill.id}
              checked={selectedBill?.id === bill.id}
              handleSelectBill={handleSelectBillById}
              showBillModal={showBillModal}
              bill={bill}
            />
          ))}
        </>
      ) : isLoading ? (
        <TableSkeleton rowsNumber={3} columnsNumber={10} />
      ) : (
        <NoBillsAdded />
      )}
    </Table>
  );
};

export default BillsTable;
