import React, { ReactNode } from 'react';

import { StepperItem } from 'interfaces/shared.interfaces';

interface Props<T extends StepperItem> {
  steps: T[] | undefined | null;
  renderContent: (data: T, index: number) => ReactNode;
  renderSkeleton?: VoidFunction;
  isLoading?: boolean;
}

const Stepper = <T extends StepperItem>({ steps, renderContent, renderSkeleton, isLoading }: Props<T>) => {
  if (isLoading) return <>{renderSkeleton?.()}</>;
  return (
    <ol className='flex items-start w-full text-xs text-gray-900 font-medium sm:text-base overflow-x-auto'>
      {steps?.map(renderContent)}
    </ol>
  );
};

export default Stepper;
