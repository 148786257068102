import React, { FC } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as Info } from 'assets/svg/info.svg';
import { INPUT_ERROR_DATA_TEST_ID } from 'constants/shared';

interface Props {
  className?: string;
  warning?: string;
  error?: string;
  isAbsoluteError?: boolean;
}

const ErrorMessage: FC<Props> = ({ className, warning, error, isAbsoluteError }) => {
  if (!error && !warning) return null;

  return (
    <div data-testid={INPUT_ERROR_DATA_TEST_ID} className={twMerge('flex', className)}>
      <Info
        className={cn('mt-1 w-4 h-4', {
          '[&>path]:fill-red-700': error,
          '[&>path]:fill-yellow-700': warning,
        })}
      />
      <div
        className={cn('text-sm pl-1 pt-1.5 leading-14', {
          'absolute ml-4': isAbsoluteError,
          'text-red-700': error,
          'text-yellow-700': warning,
        })}
      >
        {error || warning}
      </div>
    </div>
  );
};

export default ErrorMessage;
