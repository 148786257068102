import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { INVESTOR_DEALS_TABLE_COLUMNS } from 'constants/deals';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import { getInvestorShareExchanges } from 'modules/investors/action';
import { selectInvestorShareExchanges } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import AdminDealsTableRow from '../deals/deals-table/AdminDealsTableRow';
import NoDealsAdded from '../deals/NoDealsAdded';

const InvestorDeals = () => {
  const { id } = useParams();
  const { page, setPage } = usePaginationWithSearch();

  const dispatch = useAppDispatch();

  const { isLoading, list: deals, ...paginationData } = useAppSelector(selectInvestorShareExchanges);

  const tableColumn = [{ id: 'view', title: '', width: 'w-44' }, ...INVESTOR_DEALS_TABLE_COLUMNS];

  useEffect(() => {
    if (id) {
      dispatch(getInvestorShareExchanges({ id, page, per_page: 5 }));
    }
  }, [dispatch, id, page]);

  return (
    <>
      <Table columns={tableColumn}>
        {isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={tableColumn.length} />
        ) : deals?.length && !isLoading ? (
          deals?.map((deal) => <AdminDealsTableRow key={deal.id} deal={deal} />)
        ) : (
          <NoDealsAdded />
        )}
      </Table>
      <Pagination currentPage={page} updatePage={setPage} loading={isLoading} {...paginationData} />
    </>
  );
};

export default InvestorDeals;
