import React, { FC, useRef } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { Button, ModalWindow } from 'shared-components';

const FAQ_VIDEO_URL = 'https://player.vimeo.com/video/711789041?h=5cc778df62';

const FaqModal: FC<Omit<UseModalReturnValues, 'onOpen'>> = ({ onClose, ...modalProps }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const handleCloseModal = () => {
    if (iframeRef?.current?.src) {
      iframeRef.current.src = FAQ_VIDEO_URL;
    }

    onClose();
  };

  return (
    <ModalWindow
      title='Explainer Video'
      description='This quick video briefly explains the share exchange opportunity that planD offers'
      className='lg:w-[600px] xl:w-[850px]'
      wrapperClassName='lg:px-10 lg:pb-6'
      preventOutsideClick
      onClose={handleCloseModal}
      {...modalProps}
    >
      <div className='relative p-0 pt-[56%] mt-2'>
        <iframe
          ref={iframeRef}
          src={FAQ_VIDEO_URL}
          className='absolute top-0 left-0 w-full h-full'
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
        />
      </div>

      <div className='flex justify-end pt-6 mt-6 border-t border-grey-300'>
        <Button className='w-150' variant={ButtonVariants.SECONDARY} onClick={handleCloseModal}>
          Close Video
        </Button>
      </div>
    </ModalWindow>
  );
};

export default FaqModal;
