import React from 'react';

import { VerticalStepperItem as VerticalStepperItemType } from 'interfaces';

import VerticalStepperItem from './VerticalStepperItem';

export const VERTICAL_STEPPER_TEST_ID = 'VERTICAL_STEPPER_TEST_ID';

interface Props {
  steps: VerticalStepperItemType[];
}

const VerticalStepper = ({ steps }: Props) => {
  if (!steps?.length) return null;

  return (
    <ol data-testid={VERTICAL_STEPPER_TEST_ID}>
      {steps?.map((step) => (
        <VerticalStepperItem key={step.step} {...step} />
      ))}
    </ol>
  );
};

export default VerticalStepper;
