import React, { forwardRef, InputHTMLAttributes } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  wrapperClassName?: string;
}

const RadioInputWithLabel = forwardRef<HTMLInputElement, Props>(
  ({ label, labelClassName, className, wrapperClassName, ...inputProps }, ref) => {
    return (
      <div className={wrapperClassName}>
        <input ref={ref} className={twMerge('radio-input', className)} type='radio' {...inputProps} />

        {label && <label className={twMerge('pl-[25px] text-grey-500', labelClassName)}>{label}</label>}
      </div>
    );
  },
);

export default RadioInputWithLabel;

RadioInputWithLabel.displayName = 'RadioInputWithLabel';
