import React, { useEffect, useCallback } from 'react';

import { FOUNDERS_TABLE_COLUMNS } from 'constants/founders';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getFounders } from 'modules/founders/action';
import { selectFoundersData } from 'modules/founders/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination, SearchForm } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import FoundersTableRow from './FoundersTableRow';

const FOUNDERS_PER_PAGE = 7;

const FoundersTable = () => {
  const dispatch = useAppDispatch();

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { list: founders, isLoading, ...foundersPaginationData } = useAppSelector(selectFoundersData);

  const loadFounders = useCallback(() => {
    dispatch(
      getFounders({ page, per_page: FOUNDERS_PER_PAGE, query, order: sorting.order, sort: sorting.column, filter }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column, filter]);

  useEffect(() => {
    loadFounders();
  }, [loadFounders]);

  return (
    <div className='flex flex-col'>
      <SearchForm
        className='w-full sm:w-7/12 mb-12 items-center flex-col xs:flex-row gap-4 xs:gap-0'
        handleFilter={handleFilter}
        placeholder='Search by First Name, Last Name or Email Address'
      />

      <Table onUpdateSorting={updateSorting} columns={FOUNDERS_TABLE_COLUMNS}>
        {isLoading ? (
          <TableSkeleton rowsNumber={FOUNDERS_PER_PAGE} columnsNumber={FOUNDERS_TABLE_COLUMNS.length} />
        ) : (
          founders &&
          founders.map((founder) => <FoundersTableRow key={founder.id} founder={founder} loadFounders={loadFounders} />)
        )}
      </Table>

      <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...foundersPaginationData} />
    </div>
  );
};

export default FoundersTable;
