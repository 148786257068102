import { useCallback, useState } from 'react';

export interface UseModalReturnValues {
  isOpen: boolean;
  onClose: VoidFunction;
  onOpen: (props?: Record<string, any>) => void;
  modalProps: Record<string, any>;
}

const useModal = (initialValue?: boolean): UseModalReturnValues => {
  const [isOpen, setIsOpen] = useState(initialValue || false);
  const [modalProps, setModalProps] = useState<Record<string, any>>({});

  const onOpen = useCallback((props?: Record<string, any>) => {
    if (props) {
      setModalProps({ ...props });
    }

    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      setModalProps({});
    }
  }, [isOpen]);

  return {
    isOpen,
    onOpen,
    onClose,
    modalProps,
  };
};

export default useModal;
