import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { GraphItem } from 'interfaces';

export interface Props {
  data: GraphItem[];
  className?: string;
  timePeriod: string;
  isMulti?: boolean;
}

const MetricsGrowth: FC<Props> = ({ data, timePeriod, className, isMulti }) => {
  const calculateAmountForSelectedPeriod = data.reduce((acc, item) => acc + (item?.uv ?? 0), 0);
  const calculateSecondAmountForSelectedPeriod = data.reduce((acc, item) => acc + (item?.secondChart ?? 0), 0);

  return (
    <div className='flex flex-col items-center text-sm whitespace-nowrap'>
      <p className={twMerge('pl-1 text-lightGreen-700', className)}>
        + {calculateAmountForSelectedPeriod} last {timePeriod.toLowerCase()}
      </p>
      {isMulti && (
        <p className={twMerge('pl-1 text-[#ff7300]', className)}>
          + {calculateSecondAmountForSelectedPeriod} last {timePeriod.toLowerCase()}
        </p>
      )}
    </div>
  );
};

export default MetricsGrowth;
