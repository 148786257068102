import React, { FC, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { getDeclineShareExchangeModalFields } from 'constants/share-exchange';
import { errorNotify } from 'helpers';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { Deal, DeclineShareExchangeInputs, FormItem } from 'interfaces';
import { stopDealsParametersById } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';
import { AlertModal, FormContent } from 'shared-components';
import { checkIsDealNotCompleted } from 'utils/deals';

import { TypographyVariants } from '../../../constants/shared/typography';

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  selectedDeals: Deal[];
  selectedDealsIds: number[];
  resetSelectedDeals?: VoidFunction;
  handleStopShareExchange?: SubmitHandler<DeclineShareExchangeInputs>;
  isActionLoading?: boolean;
  doubleConfirmation?: boolean;
  title?: string;
  fields?: FormItem[];
}

const StopShareExchangesModal: FC<Props> = ({
  selectedDeals,
  selectedDealsIds,
  resetSelectedDeals,
  handleStopShareExchange,
  isActionLoading,
  doubleConfirmation,
  title,
  fields,
  ...modalProps
}) => {
  const [isFirstStepConfirmed, setIsFirstStepConfirmed] = useState(false);

  const dispatch = useAppDispatch();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DeclineShareExchangeInputs>();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<DeclineShareExchangeInputs> = ({ reason }) => {
    const isCompletedExchangesExists = selectedDeals?.some(
      ({ stage, status }) => !checkIsDealNotCompleted(stage, status),
    );

    if (isCompletedExchangesExists) {
      errorNotify('Completed Exchanges cannot be stopped');
      return;
    }
    setIsLoading(true);

    dispatch(stopDealsParametersById({ dealIds: selectedDealsIds, reason }))
      .unwrap()
      .then(() => {
        resetSelectedDeals && resetSelectedDeals();
        reset();
      })
      .finally(() => {
        setIsLoading(false);
        modalProps.onClose();
      });
  };

  const submitButtonText = doubleConfirmation && !isFirstStepConfirmed ? 'Yes' : 'Confirm';

  const conditionalSubmitHandler =
    doubleConfirmation && !isFirstStepConfirmed
      ? () => setIsFirstStepConfirmed(true)
      : handleSubmit(handleStopShareExchange || onSubmit);

  return (
    <AlertModal
      onSubmit={conditionalSubmitHandler}
      isLoading={isActionLoading || isLoading}
      title={title || 'Please enter the reason for stopping'}
      submitButtonText={submitButtonText}
      modalClassName='min-w-[70%] 2xl:min-w-[1048px]'
      titleVariant={TypographyVariants.LARGE}
      hideIcon
      {...modalProps}
    >
      {((isFirstStepConfirmed && doubleConfirmation) || !doubleConfirmation) && (
        <form className='w-full mt-8'>
          <FormContent
            fields={fields || getDeclineShareExchangeModalFields()}
            register={register}
            errors={errors}
            control={control}
          />
        </form>
      )}
    </AlertModal>
  );
};

export default StopShareExchangesModal;
