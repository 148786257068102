import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as SuccessIcon } from 'assets/svg/check-mark-v3.svg';
import { DOCUMENTS_PAGE_TABS_IDS } from 'constants/documents';
import { PAY_BILL_MODAL_TITLE, payBillModalCommonStyles } from 'constants/my-bills';
import { ROUTES } from 'constants/routes';
import { TypographyVariants } from 'constants/shared/typography';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { ModalWindow, Button } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getTitleWithReplacedYourWord } from 'utils';

import PayBillModalsHeader from './PayBillModalsHeader';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const PayBillSuccessModal: FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const navigateToBills = () => {
    onClose();
    navigate({ pathname: ROUTES.documents, search: `?tab=${DOCUMENTS_PAGE_TABS_IDS.INVOICES}` });
  };

  return (
    <ModalWindow
      {...payBillModalCommonStyles}
      contentClassName='h-full flex flex-col'
      isOpen={isOpen}
      onClose={onClose}
    >
      <PayBillModalsHeader title={getTitleWithReplacedYourWord(PAY_BILL_MODAL_TITLE, entityName, isTypeEntity)} />

      <div className='flex flex-col items-center mt-16'>
        <SuccessIcon className='w-12 h-[48px] [&_path]:fill-lightGreen-700' />

        <Typography className='my-6' tag='h3' variant={TypographyVariants.SEMI_LARGE}>
          Payment made successfully!
        </Typography>
        <Typography className='text-grey-500'>You can now navigate away from this page</Typography>

        <Button onClick={navigateToBills} className='mt-16'>
          Return To Bills
        </Button>
      </div>
    </ModalWindow>
  );
};

export default PayBillSuccessModal;
