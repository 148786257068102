import React, { useEffect } from 'react';

import { FUND_DOCUMENTS_SNIPPET_TITLE } from 'constants/dashboard';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { getFundDocuments } from 'modules/documents/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

import LocalDocumentsTable from '../../../documents-page/LocalDocumentsTable';

const FundDocumentsSnippet = () => {
  const dispatch = useAppDispatch();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  useEffect(() => {
    dispatch(getFundDocuments());
  }, [dispatch]);

  return (
    <SnippetLayout
      title={getTitleWithReplacedYourWord(FUND_DOCUMENTS_SNIPPET_TITLE, entityName, isTypeEntity)}
      className='row-start-3 row-end-4 col-span-full'
    >
      <LocalDocumentsTable itemsPerPage={10} />
    </SnippetLayout>
  );
};

export default FundDocumentsSnippet;
