import { useMemo } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

const useInitialTab = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const { tab } = qs.parse(search);

    return tab as string | undefined;
  }, [search]);
};

export default useInitialTab;
