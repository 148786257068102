import React, { PropsWithChildren, ReactElement } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { Comment } from 'interfaces';
import Typography from 'shared-components/Typography';

const CommentsItem = <T,>({ text, author, createdAt }: PropsWithChildren<Comment<T>>): ReactElement => {
  return (
    <li className='mt-4'>
      <Typography>{author}</Typography>
      <Typography className='py-2 px-3 bg-grey-100 rounded text-grey-500 max-w-fit'>
        <span>{format(parseISO(createdAt), DAY_FORMAT_WITH_SLASHES)}:</span> {text}
      </Typography>
    </li>
  );
};

export default CommentsItem;
