import { FormItem, TableColumns } from 'interfaces';

import { Input } from '../shared-components';
import { EMAIL_FIELD_VALIDATION, REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export enum AddNewCompanyInvestorFieldNames {
  FULL_NAME = 'fullName',
  EMAIL = 'email',
}

export const COMPANY_INVESTORS_DESCRIPTION = 'Upload all your Investors at once with Batch Upload -';

export const COMPANY_INVESTORS_DOWNLOAD_TEMPLATE_PATH = 'csv/company-investors.csv';

export const COMPANY_INVESTORS_CHOOSE_TEXT = 'Please choose Investor(s) you want to invite';

export const COMPANY_INVESTORS_ALREADY_INVESTOR_TEXT = 'Investor is already invited';

export const UPLOAD_COMPANY_INVESTORS_MODAL_TITLE = 'Upload Investors';

export const UPLOAD_COMPANY_INVESTORS_MODAL_DESCRIPTION = `Please ensure the template structure has not been modified to ensure a successful upload. If you are having errors, you can download a new template`;

export const COMPANY_INVESTORS_TABLE_COLUMNS: TableColumns[] = [
  {
    id: 'fullName',
    title: 'Full Name',
    width: 'w-4/12',
  },
  {
    id: 'email',
    title: 'Email Address',
    className: 'w-3/12',
  },
  {
    id: 'invited',
    title: 'Invited',
    className: 'w-3/12',
  },
  {
    id: 'actions',
    title: '',
    className: 'w-16',
  },
];

export const COMPANY_INVESTORS_ADD_FORM_FIELDS: FormItem[] = [
  {
    name: AddNewCompanyInvestorFieldNames.FULL_NAME,
    label: 'Full Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: REQUIRED_MESSAGE,
    },
  },
  {
    name: AddNewCompanyInvestorFieldNames.EMAIL,
    label: 'Email Address',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: EMAIL_FIELD_VALIDATION,
  },
];
