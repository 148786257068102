import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  progress: number;
  isLoading?: boolean;
}

export const PROGRESS_BAR_LOADER_TEST_ID = 'PROGRESS_BAR_LOADER_TEST_ID';
export const PROGRESS_BAR_TEST_ID = 'PROGRESS_BAR_TEST_ID';

const ProgressBar: FC<Props> = ({ className, progress, isLoading }) => {
  if (isLoading)
    return <div className='skeleton-loader w-full h-5 rounded-full' data-testid={PROGRESS_BAR_LOADER_TEST_ID} />;

  return (
    <div className={twMerge('w-full h-5 bg-grey-300 rounded-full', className)} data-testid={PROGRESS_BAR_TEST_ID}>
      <div
        style={{ width: progress + '%' }}
        className='h-full text-center text-xs text-white bg-lightGreen-700 rounded-full'
      />
    </div>
  );
};

export default ProgressBar;
