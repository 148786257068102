import React, { FC, useEffect } from 'react';

import { DUE_DILIGENCE_ACTIVITY_MODAL_TITLE } from 'constants/due-diligence';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import history from 'services/history';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const INACTIVITY_TIMER = 1000 * 30;

const DueDiligenceActivityModal: FC<Props> = ({ isOpen, onClose }) => {
  const onCloseActivityModal = () => {
    onClose();
    history.push(ROUTES.dashboard);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const timeout = setTimeout(() => {
      onClose();
      history.push(ROUTES.dashboard);
    }, INACTIVITY_TIMER);

    return () => clearTimeout(timeout);
  }, [isOpen, onClose]);

  return (
    <AlertModal
      isOpen={isOpen}
      onClickBackButton={onCloseActivityModal}
      onClose={onClose}
      onSubmit={onClose}
      title={DUE_DILIGENCE_ACTIVITY_MODAL_TITLE}
      isLoading={false}
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
      backButtonText='No'
      submitButtonText='Yes'
    />
  );
};

export default DueDiligenceActivityModal;
