export enum SIGNIFICANT_CONTROL_DOCUMENT_TYPES {
  ARTICLES_OF_ASSOCIATION = 'articles_of_association',
  CERTIFICATE_OF_INCORPORATION = 'certificate_of_incorporation',
  PASSPORT = 'passport',
  OTHER = 'other',
}

export const SIGNIFICANT_CONTROL_DOCUMENT_TYPES_KEYS = [
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES.ARTICLES_OF_ASSOCIATION,
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION,
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT,
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES.OTHER,
];

export const SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS = {
  [SIGNIFICANT_CONTROL_DOCUMENT_TYPES.ARTICLES_OF_ASSOCIATION]: 'Articles of Association',
  [SIGNIFICANT_CONTROL_DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION]: 'Certificate of Incorporation',
  [SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT]: 'Passport',
  [SIGNIFICANT_CONTROL_DOCUMENT_TYPES.OTHER]: 'Other Documents',
};

export const SIGNIFICANT_CONTROL_DOCUMENT_TYPES_OPTIONS = SIGNIFICANT_CONTROL_DOCUMENT_TYPES_KEYS.map((key) => ({
  label: SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS[key],
  value: key,
}));

export const SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_COLUMNS = [
  [
    SIGNIFICANT_CONTROL_DOCUMENT_TYPES.ARTICLES_OF_ASSOCIATION,
    SIGNIFICANT_CONTROL_DOCUMENT_TYPES.CERTIFICATE_OF_INCORPORATION,
  ],
  [SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT, SIGNIFICANT_CONTROL_DOCUMENT_TYPES.OTHER],
];

export const SIGNIFICANT_CONTROL_LIST_DOCUMENT_REQUIRED_TYPES = [SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT];

export enum SIGNIFICANT_CONTROL_DIRECTORS_FORM_FIELDS {
  DIRECTORS = 'directors',
}

export const SIGNIFICANT_CONTROL_PASSPORT_FILE_TYPES = ['application/pdf', 'image/png'];

export const SIGNIFICANT_CONTROL_PASSPORT_FILE_HELP_TEXT = 'Accepted: PDF, PNG';

export const SIGNIFICANT_CONTROL_EXTENDED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'image/png',
];

export const SIGNIFICANT_CONTROL_EXTENDED_FILE_HELP_TEXT = 'Accepted: PDF, DOCX, XLS, PNG';
