import React, { FC, ReactNode, useMemo } from 'react';

import { ReactComponent as FolderSvg } from 'assets/svg/folder.svg';
import { ButtonVariants } from 'constants/shared/button';
import { CompaniesItemSkeleton } from 'page-components';
import { Button } from 'shared-components';
import SnippetNoInfoLayout from 'shared-components/SnippetNoInfoLayout';

import { checkIsAdmin } from '../../../../utils';

interface Props {
  children: ReactNode;
  companiesLength?: number;
  skeletonItems: number;
  isLoading?: boolean;
  handleOpenAddCompanyModal: VoidFunction;
}

const FounderCompaniesSnippetWrapper: FC<Props> = ({
  children,
  companiesLength,
  isLoading,
  skeletonItems,
  handleOpenAddCompanyModal,
}) => {
  const isAdmin = useMemo(() => checkIsAdmin(), []);

  if (isLoading)
    return (
      <>
        {[...Array(skeletonItems)].map((_, index) => (
          <CompaniesItemSkeleton key={index} />
        ))}
      </>
    );

  if (!companiesLength)
    return (
      <SnippetNoInfoLayout icon={<FolderSvg />}>
        {!isAdmin ? (
          <Button
            className='text-grey-800'
            variant={ButtonVariants.UNDERLINED_LINK}
            onClick={handleOpenAddCompanyModal}
          >
            Add Company
          </Button>
        ) : (
          'No companies'
        )}
      </SnippetNoInfoLayout>
    );

  return <>{children}</>;
};

export default FounderCompaniesSnippetWrapper;
