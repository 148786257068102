import React, { FC } from 'react';

import { TableData, TableRow } from 'shared-components/table';

type Props = {
  columnsLength: number;
};

const ShareExchangeDetailsNoItems: FC<Props> = ({ columnsLength }) => {
  return (
    <TableRow>
      <TableData colSpan={columnsLength}>No Share Exchanges found</TableData>
    </TableRow>
  );
};

export default ShareExchangeDetailsNoItems;
