import { format, isAfter, isBefore, parse, startOfMonth, isSameMonth } from 'date-fns';

import { MONTH_FORMAT_WITH_DASH, MONTH_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { INCORRECT_DATE_MESSAGE } from 'constants/due-diligence';
import { DateRange, SignificantControlPersonAddress } from 'interfaces';

import { getMidnightDate } from './dates.helpers';

const sortDateRanges = (dateRanges: DateRange[]) =>
  dateRanges
    .map((range) => ({
      from: new Date(range.from),
      to: range.to ? new Date(range.to) : startOfMonth(new Date()), // If to date then we can consider that tillNow is set to true
    }))
    .sort((a, b) => {
      // We can have date range where from === to, in this case we need to compare not only 'from' but also 'to'
      const fromDiff = a.from.getTime() - b.from.getTime();
      if (fromDiff !== 0) {
        return fromDiff;
      }

      return a.to.getTime() - b.to.getTime();
    });

export const checkStrictTimeline = (dateRanges: DateRange[]) => {
  if (!dateRanges.length) {
    return true;
  }

  const sortedRanges = sortDateRanges(dateRanges);

  // We store last TO date from ranges if a timeline is strict and doesnt breaks off
  let lastStrictDateRangeDate: Date | null = sortedRanges[0].to;

  for (let i = 1; i < sortedRanges.length; i++) {
    const fromDateAtMidnight = getMidnightDate(new Date(sortedRanges[i].from));
    const lastStrictDateRangeDateAtMidnight = getMidnightDate(new Date(lastStrictDateRangeDate));
    const monthAfterLastStrictDateRangeDateAtMidnight = new Date(
      getMidnightDate(new Date(lastStrictDateRangeDate)).setMonth(lastStrictDateRangeDate.getMonth() + 1),
    );

    if (
      fromDateAtMidnight.getTime() === lastStrictDateRangeDateAtMidnight.getTime() ||
      fromDateAtMidnight.getTime() === monthAfterLastStrictDateRangeDateAtMidnight.getTime()
    ) {
      lastStrictDateRangeDate = sortedRanges[i].to;
    } else {
      lastStrictDateRangeDate = null;
      break;
    }
  }

  if (!lastStrictDateRangeDate) {
    return false;
  }

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const timeDifferenceMS = today.getTime() - new Date(lastStrictDateRangeDate.setHours(0, 0, 0, 0)).getTime();

  // We compare the difference between the last date of the last range and today
  // The difference should be less than 30 days
  // If more, then we consider that TO date pf the last address is not current month
  return Math.floor(timeDifferenceMS / (1000 * 60 * 60 * 24)) <= 30;
};

export const checkDateOverlap = (dateRanges: DateRange[]) => {
  const parsedDates = sortDateRanges(dateRanges);

  return parsedDates.some((date1, i) =>
    parsedDates
      .slice(i + 1)
      .some(
        (date2) =>
          (date1.from > date2.from && date1.from < date2.to) ||
          (date1.to > date2.from && date1.to < date2.to) ||
          (date2.from > date1.from && date2.from < date1.to) ||
          (date2.to > date1.from && date2.to < date1.to),
      ),
  );
};

export const formatDefaultInputDate = (date: string) => {
  try {
    const parsedDate = parse(date, MONTH_FORMAT_WITH_DASH, new Date());

    return format(parsedDate, MONTH_FORMAT_WITH_SLASHES);
  } catch (err) {
    return '';
  }
};

export const getFromAndToDatesContent = (person: SignificantControlPersonAddress) => {
  const { from, to, tillNow } = person || {};

  if (from && to) {
    const isToDateCurrentMonth = isSameMonth(new Date(to), new Date());

    return `${formatDefaultInputDate(from)} - ${isToDateCurrentMonth ? 'Present' : formatDefaultInputDate(to)}`;
  }

  if (from && tillNow) {
    return `${formatDefaultInputDate(from)} - Present`;
  }

  if (from && !to && !tillNow) {
    return `${formatDefaultInputDate(from)} - ...`;
  }

  return '';
};

export const validateIfDateWithinRange = (date: Date, minDate: Date, maxDate: Date): string | null => {
  if (date) {
    if (isBefore(new Date(date), minDate)) {
      return INCORRECT_DATE_MESSAGE;
    }

    if (isAfter(new Date(date), maxDate)) {
      return INCORRECT_DATE_MESSAGE;
    }
  }

  return null;
};
