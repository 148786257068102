import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

const NoInvestmentsAdded = () => {
  return (
    <TableRow>
      <TableData className='pl-2'>No information is available as no Investments have been added</TableData>
    </TableRow>
  );
};

export default NoInvestmentsAdded;
