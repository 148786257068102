import React, { FC, ReactNode } from 'react';

import { ModalWindow } from 'shared-components';

import { TypographyVariants } from '../../constants/shared/typography';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  title: string;
  description?: string | ReactNode;
  children: ReactNode;
  preventOutsideClick?: boolean;
};

const UploadFilesModal: FC<Props> = ({ isOpen, onClose, title, description, children, preventOutsideClick }) => {
  return (
    <ModalWindow
      title={title}
      description={description}
      isOpen={isOpen}
      onClose={onClose}
      className='lg:w-3/5'
      wrapperClassName='min-w-[70%] 2xl:min-w-[1048px] pt-14'
      overlayClassName='bg-black opacity-80'
      preventOutsideClick={preventOutsideClick}
      titleVariant={TypographyVariants.MEDIUM}
    >
      {children}
    </ModalWindow>
  );
};

export default UploadFilesModal;
