import React, { ChangeEvent, PropsWithChildren, ReactElement, ReactNode, useEffect } from 'react';

import cn from 'classnames';
import {
  FieldErrors,
  UseFormRegister,
  Path,
  UseFormWatch,
  PathValue,
  UseFormSetValue,
  UseFormUnregister,
  FieldValues,
} from 'react-hook-form';

import { BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES } from 'constants/company-iac';
import { CompanyIacRadioInputSetField, CompanyIacTextAreaNotesField } from 'interfaces';
import { RadioInputSet, TextArea } from 'shared-components';

interface Props<T extends FieldValues> {
  isDisabled?: boolean;
  register: UseFormRegister<T>;
  unregister: UseFormUnregister<T>;
  setValue: UseFormSetValue<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  radioInputProps: CompanyIacRadioInputSetField;
  textAreaProps?: CompanyIacTextAreaNotesField;
  customComponent?: ReactNode;
  onChangeNotes?: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
  onChangeRadioInput?: (event: ChangeEvent<HTMLInputElement>) => Promise<void> | void;
}

const CompanyIacRadioInputWithNotes = <T extends FieldValues>({
  isDisabled,
  register,
  unregister,
  onChangeRadioInput,
  onChangeNotes,
  setValue,
  errors,
  watch,
  radioInputProps: { validation: radioInputValidation, ...radioInputProps },
  textAreaProps: { validation: textAreaValidation, ...textAreaProps } = {},
  customComponent,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const watchTextAreaValue = watch(textAreaProps.name as Path<T>);
  const watchRadioInputValue = watch(radioInputProps.name as Path<T>);
  const radioInputError = errors[radioInputProps.name as Path<T>]?.message;
  const textAreaError = errors[textAreaProps.name as Path<T>]?.message;

  const validateInputWithNotes = (value: PathValue<T, Path<T>>) => {
    if (!value && radioInputValidation?.required) {
      return radioInputValidation.required as string;
    }

    if (typeof radioInputValidation?.validate === 'function') {
      return radioInputValidation?.validate(value) as string;
    }

    return true;
  };

  useEffect(() => {
    if (watchTextAreaValue === undefined) {
      return;
    }

    if (
      watchRadioInputValue &&
      watchRadioInputValue !== BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.YES &&
      Object.keys(textAreaProps).length > 0
    ) {
      unregister(textAreaProps.name as Path<T>);
      setValue(textAreaProps.name as Path<T>, null as PathValue<T, Path<T>>);
    }
  }, [setValue, unregister, watchRadioInputValue, watchTextAreaValue, textAreaProps.name]);

  return (
    <div className='mb-6'>
      <RadioInputSet
        disabled={isDisabled}
        currentValue={watch(radioInputProps?.name as Path<T>)}
        error={radioInputError as string}
        {...register(radioInputProps.name as Path<T>, {
          validate: validateInputWithNotes,
        })}
        onChange={onChangeRadioInput}
        {...radioInputProps}
      />
      {watchRadioInputValue && watchRadioInputValue === BUSINESS_REVIEW_RADIO_BUTTON_OPTIONS_VALUES.YES && (
        <>
          {Object.keys(textAreaProps).length > 0 && (
            <TextArea
              disabled={isDisabled}
              error={textAreaError as string}
              {...textAreaProps}
              {...register(textAreaProps.name as Path<T>, {
                ...textAreaValidation,
                onChange: onChangeNotes,
              })}
              className={cn('mb-5', { 'disabled-light-grey': isDisabled })}
            />
          )}
          {customComponent}
        </>
      )}
    </div>
  );
};

export default CompanyIacRadioInputWithNotes;
