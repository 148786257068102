import React, { useEffect } from 'react';

import { DASHBOARD_PIE_CHART_COLORS } from 'constants/dashboard';
import { getAdminCompaniesMetrics } from 'modules/companies/action';
import { selectCompaniesMetrics } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ChartInfoItemWithColoredRect } from 'shared-components';
import { PieChart, PieChartActiveShapeV2, ChartMetricsLayout } from 'shared-components/chart';
import { convertObjectToChartData } from 'utils';

const AdminCompaniesSnippetContent = () => {
  const dispatch = useAppDispatch();

  const { isLoading, amountAll, amountInvolvedInShareExchange, amountNotInvolvedInShareExchange } =
    useAppSelector(selectCompaniesMetrics);

  useEffect(() => {
    if (!amountAll) dispatch(getAdminCompaniesMetrics());
  }, [amountAll, dispatch]);

  return (
    <div className='w-full flex flex-col items-center'>
      <PieChart
        isLoading={isLoading}
        colors={DASHBOARD_PIE_CHART_COLORS}
        data={convertObjectToChartData({ amountInvolvedInShareExchange, amountNotInvolvedInShareExchange })}
        activeShape={<PieChartActiveShapeV2 title='No. Companies' customValue={amountAll} />}
      />
      <ChartMetricsLayout isLoading={isLoading} className='mt-6'>
        <ChartInfoItemWithColoredRect
          title='In Share-Exchange'
          value={amountInvolvedInShareExchange}
          rectClassName='bg-lightGreen-700'
        />
        <ChartInfoItemWithColoredRect title='Not in Share-Exchange' value={amountNotInvolvedInShareExchange} />
      </ChartMetricsLayout>
    </div>
  );
};

export default AdminCompaniesSnippetContent;
