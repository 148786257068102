import React, { FC, ReactNode } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import { SearchInput } from 'interfaces';
import { Button, Input } from 'shared-components/index';

export const DEFAULT_SEARCH_INPUT_PLACEHOLDER = 'Search by Name, Company, etc..';

type Props = {
  handleFilter: (search: string) => void;
  label?: string;
  className?: string;
  placeholder?: string;
  children?: ReactNode;
};

const SearchForm: FC<Props> = ({ handleFilter, label, className, placeholder, children }) => {
  const { register, handleSubmit } = useForm<SearchInput>();

  const onSubmit: SubmitHandler<SearchInput> = ({ search }) => {
    handleFilter(search);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={twMerge('basis-7/12 flex items-end', className)}>
      <Input
        {...register('search')}
        className='mb-[0px] xs:mr-6'
        labelClassName='text-sm ml-0.5'
        label={label}
        placeholder={placeholder || DEFAULT_SEARCH_INPUT_PLACEHOLDER}
      />
      {children}
      <Button type='submit' className='w-full xs:w-auto xs:max-w-fit' variant={ButtonVariants.SECONDARY}>
        Filter
      </Button>
    </form>
  );
};

export default SearchForm;
