import React, { Dispatch, FC, SetStateAction } from 'react';

import { FOUNDERS_CONFIRM_COMPANIES_MODAL_TABLE_COLUMNS } from 'constants/founders';
import { FounderModifiedCompany, FoundersCompanies } from 'interfaces';
import { setDueDiligenceDocumentsFilterValue } from 'modules/due-diligence/action';
import { useAppDispatch } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';
import Typography from 'shared-components/Typography';

import ConfirmFoundersModalTableRow from './ConfirmFoundersModalTableRow';

interface Props {
  foundersCompanies: FoundersCompanies[];
  isLoading: boolean;
  setModifiedCompanies: Dispatch<SetStateAction<Array<FounderModifiedCompany>>>;
}

const ConfirmFoundersModalTable: FC<Props> = ({ foundersCompanies, isLoading, setModifiedCompanies }) => {
  const dispatch = useAppDispatch();

  const handleFilterByDocumentType = (filterValue: string) => {
    dispatch(setDueDiligenceDocumentsFilterValue(filterValue));
  };

  return (
    <SnippetLayout className='mt-6'>
      <Table className='table-simplified-th' columns={FOUNDERS_CONFIRM_COMPANIES_MODAL_TABLE_COLUMNS}>
        {foundersCompanies?.length && !isLoading ? (
          foundersCompanies.map((company) => (
            <ConfirmFoundersModalTableRow
              handleFilter={handleFilterByDocumentType}
              key={company.id}
              companyId={company.id}
              companyName={company.companyName}
              companyNumber={company.companyNumber}
              confirmed={company.confirmed}
              setModifiedCompanies={setModifiedCompanies}
            />
          ))
        ) : isLoading ? (
          <TableSkeleton columnsNumber={FOUNDERS_CONFIRM_COMPANIES_MODAL_TABLE_COLUMNS?.length} rowsNumber={3} />
        ) : (
          <Typography className='mt-7'>No information is available as no Companies have been added</Typography>
        )}
      </Table>
    </SnippetLayout>
  );
};

export default ConfirmFoundersModalTable;
