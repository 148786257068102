import { UserTypes } from '../constants/user';
import { capitalizeFirstLetter } from './global/global';

export const getDeletedShareExchangesMessage = (count: number) =>
  count > 1 ? 'The share exchanges were successfully deleted' : 'The share exchange was successfully deleted';

export const getAlreadyRegisteredEmailMessage = (userType: UserTypes) => {
  if (userType !== UserTypes.FOUNDER && userType !== UserTypes.INVESTOR) return;

  const oppositeRole = userType === UserTypes.INVESTOR ? 'Founder' : 'Investor';

  return `${capitalizeFirstLetter(
    oppositeRole,
  )} account already exists in the system please provide password to register ${capitalizeFirstLetter(
    userType,
  )} account `;
};
