import { AxiosError } from 'axios';
import { pickBy } from 'lodash';

import { DEFAULT_ERROR_MESSAGE } from 'constants/global';
import { errorNotify } from 'helpers';
import { ServerError } from 'interfaces';

export const showServerError = (error: unknown, replaceFromStr?: string) => {
  const err = error as AxiosError<ServerError>;

  const message = err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;

  const replaceFromIndex = replaceFromStr ? message?.indexOf(replaceFromStr) : -1;

  if (replaceFromIndex !== -1) {
    errorNotify(message?.slice(0, replaceFromIndex));
    return;
  }

  errorNotify(message);
};

export const showServerMultipleErrors = (errors: string[], maxCount = 5) => {
  [...Array(maxCount)].forEach((_, index) => {
    errorNotify(errors[index]);
  });
};

export const filterObjectWithFalsyString = (data: Record<string, string>) =>
  pickBy(data, (value) => value && value !== '0');
