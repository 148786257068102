import React, { FC, ReactNode } from 'react';

import { ReactComponent as ConversationCloudIcon } from 'assets/svg/conversation-cloud.svg';
import { ButtonVariants } from 'constants/shared/button';
import { Button, ButtonWithIcon } from 'shared-components';

export interface Props {
  onClickAddComments?: VoidFunction;
  onClickEdit?: VoidFunction;
  onClickSave?: VoidFunction;
  onClickDiscardChanges?: VoidFunction;
  isEditing?: boolean;
  isCommentsExist?: boolean;
  isCommentsOpen?: boolean;
  isHideActionButtons?: boolean;
  isLoading?: boolean;
  isCommentsLoading?: boolean;
  hideComments?: boolean;
  hidden?: boolean;
  hideEditButton?: boolean;
  discardButtonText?: string;
  withoutEditing?: boolean;
  children?: ReactNode;
  submitButtonVariant?: ButtonVariants;
}

const ActionsButtons: FC<Props> = ({
  onClickAddComments,
  onClickEdit,
  isEditing,
  onClickSave,
  onClickDiscardChanges,
  isCommentsExist,
  isCommentsOpen,
  isLoading,
  isCommentsLoading,
  hideComments,
  hidden,
  hideEditButton,
  discardButtonText,
  withoutEditing,
  children,
  submitButtonVariant,
}) => {
  const handleAddComments = () => {
    if (onClickAddComments) onClickAddComments();
  };

  if (hidden) return null;

  return (
    <div className='w-full flex flex-col sm:flex-row gap-4 sm:gap-0 justify-end items-end pt-12 sm:pt-6'>
      {isEditing && !withoutEditing ? (
        <>
          <Button
            disabled={isLoading}
            variant={ButtonVariants.SECONDARY}
            className='w-full sm:w-fit text-sm sm:mr-6'
            onClick={onClickDiscardChanges}
          >
            {discardButtonText || 'Discard Changes'}
          </Button>

          <Button
            isLoading={isLoading}
            variant={submitButtonVariant || ButtonVariants.SECONDARY}
            className='w-full sm:w-[130px] text-sm'
            onClick={onClickSave}
          >
            Save
          </Button>
        </>
      ) : (
        <>
          {!(isCommentsExist || isCommentsOpen) && !hideComments && (
            <ButtonWithIcon
              isLoading={isCommentsLoading}
              buttonContentClassName='flex-row-reverse text-sm'
              iconClassName='ml-0 mr-3 fill-transparent'
              className='w-full sm:w-[210px]'
              buttonVariant={ButtonVariants.SECONDARY}
              onClick={handleAddComments}
              icon={ConversationCloudIcon}
              text='Add Comment'
            />
          )}
          {children}
          {!hideEditButton && (
            <Button
              variant={ButtonVariants.SECONDARY}
              className='w-full sm:w-[210px] ml-6 text-sm'
              onClick={onClickEdit}
            >
              Edit
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ActionsButtons;
