import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

interface Props {
  title: string;
  description?: string;
  onCancel: VoidFunction;
}

const TitleWithFormActions: FC<Props> = ({ title, description, onCancel }) => (
  <div className='flex justify-between flex-col md:flex-row gap-4 md:gap-0'>
    <div>
      <h3 className='font-bold text-xl'>{title}</h3>
      {description && <p className='text-grey-500'>{description}</p>}
    </div>
    <div className='flex items-center'>
      <Button className='w-full md:w-150 mr-4' variant={ButtonVariants.SECONDARY} type='button' onClick={onCancel}>
        Cancel
      </Button>
      <Button type='submit' className='w-full md:w-150' variant={ButtonVariants.PRIMARY}>
        Save
      </Button>
    </div>
  </div>
);

export default TitleWithFormActions;
