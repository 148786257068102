import React from 'react';

import { selectCompanyDealParameters, selectIsCurrentCompanyLoading } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';

import AdminCompanyProfileDealParameters from './AdminCompanyProfileDealParameters';

const AdminCompanyProfileDealInformationSection = () => {
  const dealsParameters = useAppSelector(selectCompanyDealParameters);
  const isLoading = useAppSelector(selectIsCurrentCompanyLoading);

  return (
    <>
      <SnippetLayout>
        <h4 className='text-xl text-blue-800 font-bold'>Deal Information</h4>
        <div className='flex justify-between flex-col'>
          <AdminCompanyProfileDealParameters dealsParameters={dealsParameters} isLoading={isLoading} />
        </div>
      </SnippetLayout>
    </>
  );
};

export default AdminCompanyProfileDealInformationSection;
