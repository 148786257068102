import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as InfoIcon } from 'assets/svg/info.svg';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { ButtonsControl, ModalWindow } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onClickBackButton?: VoidFunction;
  onSubmit: VoidFunction;
  isLoading: boolean;
  title: string | ReactNode;
  description?: string | ReactNode;
  descriptionClassName?: string;
  submitButtonText?: string;
  backButtonText?: string;
  overlayClassName?: string;
  wrapperModalClassName?: string;
  modalClassName?: string;
  secondModal?: boolean;
  submitButtonVariant?: ButtonVariants;
  backButtonVariant?: ButtonVariants;
  contentClassName?: string;
  children?: ReactNode;
  hideIcon?: boolean;
  titleVariant?: TypographyVariants;
};

const AlertModal: FC<Props> = ({
  isOpen,
  onClose,
  onClickBackButton,
  isLoading,
  submitButtonText,
  backButtonText,
  onSubmit,
  title,
  description,
  descriptionClassName,
  overlayClassName,
  secondModal = false,
  wrapperModalClassName,
  modalClassName,
  submitButtonVariant,
  backButtonVariant,
  contentClassName,
  children,
  hideIcon,
  titleVariant,
}) => {
  return (
    <ModalWindow
      className={twMerge('px-4 min-w-[45%] z-40', modalClassName)}
      contentClassName={twMerge('overflow-x-hidden z-40', contentClassName)}
      overlayClassName={cn({ 'bg-black opacity-80 z-35': secondModal }, overlayClassName)}
      wrapperClassName={wrapperModalClassName}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='flex flex-col align-center items-center gap-4 xs:gap-0'>
        {!hideIcon && <InfoIcon className='[&_path]:fill-yellow-700 w-[48px] h-[48px]' />}

        <Typography className='text-center' tag='h2' variant={titleVariant || TypographyVariants.MEDIUM}>
          {typeof title === 'string' ? <b className='text-grey-800'>{title}</b> : title}
        </Typography>

        {typeof description === 'string' ? (
          <Typography className={cn('text-grey-500 text-center', descriptionClassName)}>{description}</Typography>
        ) : (
          description
        )}

        {children}

        <div className='w-full p-1 xs:[&_button]:w-[49%] xs:[&_button]:max-w-[50%]'>
          <ButtonsControl
            isLoading={isLoading}
            className='flex-col-reverse xs:flex-row gap-4 xs:gap-0 xs:mt-10'
            onSubmit={onSubmit}
            submitButtonText={submitButtonText || 'Remove'}
            backButtonText={backButtonText || 'Cancel'}
            onBack={onClickBackButton || onClose}
            submitButtonVariant={submitButtonVariant || ButtonVariants.SECONDARY}
            backButtonVariant={backButtonVariant || ButtonVariants.PRIMARY}
          />
        </div>
      </div>
    </ModalWindow>
  );
};

export default AlertModal;
