import React, { FC, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useForm } from 'react-hook-form';

import { ReactComponent as PencilSvg } from 'assets/svg/pencil.svg';
import {
  COMPANY_DATA_FIELDS,
  COMPANY_DATA_FORM_ADDITIONAL_DEFAULT_VALUES,
  COMPANY_FIELDS_EDIT_BUTTON_TEST_ID,
  COMPANY_NAME_DATA_TEST_ID,
  COMPANY_NUMBER_DATA_TEST_ID,
  CompanyDataFieldNames,
} from 'constants/companies';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import useCompanySelect from 'hooks/useCompanySelect';
import { CompanyData, CompanyDetailsInput } from 'interfaces';
import { selectCompanyDataIsCalled } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import { Button, ButtonsControl, CompanySelectField, FormContent } from 'shared-components';

import { handleOnlyNumbersOnlySelect } from '../utils/shared/select';

interface Props {
  companyData: CompanyData | null;
  onSubmit: (data: CompanyDetailsInput) => void;
  onBlurSubmit?: (data: CompanyDetailsInput) => void;
  excludedFields?: CompanyDataFieldNames[];
  className?: string;
  submitButtonText?: string;
  isResetAfterSubmit?: boolean;
  isActionLoading?: boolean;
  handleResetCompanyData?: VoidFunction;
  skipButtonVariant?: ButtonVariants;
  buttonsClassName?: string;
  hideSkipButton?: boolean;
}

const CompanyDataForm: FC<Props> = ({
  companyData,
  onSubmit,
  className,
  onBlurSubmit,
  submitButtonText,
  isResetAfterSubmit,
  isActionLoading,
  handleResetCompanyData,
  skipButtonVariant,
  buttonsClassName,
  hideSkipButton,
  excludedFields = [],
}) => {
  const isCompanyCalled = useAppSelector(selectCompanyDataIsCalled);

  const [isFormAvailable, setIsFormAvailable] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CompanyDetailsInput>({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      companyName: null,
      companyNumber: null,
    },
  });

  const setAsAvailable = () => setIsFormAvailable(true);

  const [handleChangeCompanyName, handleChangeCompanyNumber, isCompanyNameCreated, isCompanyNumberCreated] =
    useCompanySelect<CompanyDetailsInput>(watch, setValue, reset, true, companyData, setAsAvailable);

  const handleResetData = () => {
    if (handleResetCompanyData) handleResetCompanyData();

    reset();
  };

  const filteredCompanyDataFields = useMemo(() => {
    return COMPANY_DATA_FIELDS.filter(({ name }) => !excludedFields.includes(name as CompanyDataFieldNames));
  }, [excludedFields]);

  useEffect(() => {
    if (isCompanyCalled && isResetAfterSubmit) {
      reset(COMPANY_DATA_FORM_ADDITIONAL_DEFAULT_VALUES);
    }
  }, [isCompanyCalled, isResetAfterSubmit, reset]);

  return (
    <>
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        <div className='border-b-2 border-neutral-200'>
          <div className='flex items-end justify-between flex-col xs:flex-row pb-6 xs:px-1'>
            <div className='flex items-center flex-col xs:flex-row w-full xs:pr-2 xs:space-x-4'>
              <CompanySelectField<CompanyDetailsInput>
                className='w-full xs:basis-[50%] mt-0'
                label='Company No.'
                placeholder='Company Number'
                name={CompanyDataFieldNames.COMPANY_NUMBER}
                control={control}
                onChange={handleChangeCompanyNumber}
                disableRequest={isCompanyNameCreated}
                onKeyDown={handleOnlyNumbersOnlySelect}
                creatable
                isReversed
                isClearable
                dataTestId={COMPANY_NUMBER_DATA_TEST_ID}
              />
              <CompanySelectField<CompanyDetailsInput>
                className='w-full xs:basis-[50%] mt-0'
                label='Legal Company Name'
                name={CompanyDataFieldNames.COMPANY_NAME}
                control={control}
                onBlur={onBlurSubmit ? handleSubmit(onBlurSubmit) : () => ''}
                onChange={handleChangeCompanyName}
                disableRequest={isCompanyNumberCreated}
                creatable
                isClearable
                dataTestId={COMPANY_NAME_DATA_TEST_ID}
              />
            </div>
          </div>
          <Button
            data-testid={COMPANY_FIELDS_EDIT_BUTTON_TEST_ID}
            type='button'
            variant={ButtonVariants.SECONDARY}
            className='w-fit mb-6'
            onClick={setAsAvailable}
          >
            Edit <PencilSvg className='ml-2' />
          </Button>
        </div>

        <div className='mt-6 grid grid-cols-4 gap-x-5'>
          <FormContent<CompanyDetailsInput>
            isDisabled={!isFormAvailable}
            fields={filteredCompanyDataFields}
            register={register}
            errors={errors}
          />
        </div>
      </form>
      <ButtonsControl
        hideBackButton
        className={cn({ 'justify-end': hideSkipButton }, buttonsClassName)}
        submitButtonText={submitButtonText}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isActionLoading}
        skipButton={!hideSkipButton}
        skipButtonLink={ROUTES.welcome}
        skipButtonVariant={skipButtonVariant}
        onSkip={handleResetData}
      />
    </>
  );
};

export default CompanyDataForm;
