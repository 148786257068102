import React, { memo, FC, ReactNode } from 'react';

import cn from 'classnames';

import Typography from 'shared-components/Typography';

type Props = {
  title: string;
  value?: string;
  children?: ReactNode;
};

const UserInfo: FC<Props> = ({ title, value, children }) => {
  if (!value && !children) return null;

  return (
    <div className={cn('mb-2', { 'flex items-center': children })}>
      <Typography tag='span'>{`${title}: `}</Typography>
      {children || (
        <Typography tag='span' className='text-blue-800'>
          {value}
        </Typography>
      )}
    </div>
  );
};

export default memo(UserInfo);
