import React, { FC, useState } from 'react';

import { useAppDispatch } from 'modules/store';
import { AlertModal } from 'shared-components';

import DeleteIacFieldModalTitle from './DeleteIacFieldModalTitle';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  label?: string;
  onSubmit?: VoidFunction;
};

const DeleteIacFIeldModal: FC<Props> = ({ isOpen, onClose, label, onSubmit }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <AlertModal
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      backButtonText={'No'}
      submitButtonText={'Yes'}
      title={<DeleteIacFieldModalTitle label={label} />}
    />
  );
};

export default DeleteIacFIeldModal;
