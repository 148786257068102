import React, { FC, useMemo } from 'react';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import {
  DUE_DILIGENCE_DOCUMENTS_COLUMNS,
  DUE_DILIGENCE_DOCUMENTS_ADDITIONAL_ADMIN_COLUMNS,
} from 'constants/due-diligence';
import { LocalStorageKeys } from 'constants/global';
import { UserTypes } from 'constants/user';
import { DueDiligenceDocument, UpdateSortingFunctionType } from 'interfaces';
import { downloadDocument } from 'modules/documents/action';
import { deleteDueDiligenceDocument } from 'modules/due-diligence/action';
import { selectDueDiligenceId } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import NoDocumentsAdded from 'page-components/documents-page/NoDocumentsAdded';
import { ButtonDropdown, ButtonsActions } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import DueDiligenceDocumentsTableItem from './DueDiligenceDocumentsTableItem';

interface Props {
  documents: DueDiligenceDocument[];
  isLoading: boolean;
  updateSorting: UpdateSortingFunctionType;
  id?: string;
  handleOpenPreviewDocumentsModal: ({ filePath }: { filePath: string }) => void;
}

const DueDiligenceDocumentsTable: FC<Props> = ({
  documents,
  isLoading,
  updateSorting,
  handleOpenPreviewDocumentsModal,
}) => {
  const dispatch = useAppDispatch();

  const dueDiligenceId = useAppSelector(selectDueDiligenceId);

  const userType = useMemo(() => localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes, []);

  const tableColumns = useMemo(() => {
    if (userType === UserTypes.ADMIN) {
      return [...DUE_DILIGENCE_DOCUMENTS_COLUMNS, ...DUE_DILIGENCE_DOCUMENTS_ADDITIONAL_ADMIN_COLUMNS];
    }

    return DUE_DILIGENCE_DOCUMENTS_COLUMNS;
  }, [userType]);

  const getDueDiligenceDocumentActions = (filePath: string, filename: string) => [
    {
      title: 'Download',
      id: 'download',
      action: () => {
        dispatch(downloadDocument({ filePath: filePath?.replace('http', 'https'), filename, originalFilePath: true }));
      },
    },
    {
      title: 'View/Preview',
      id: 'preview',
      action: () => handleOpenPreviewDocumentsModal({ filePath }),
    },
  ];

  const handleDeleteDocument = (documentId: number) => {
    if (dueDiligenceId) dispatch(deleteDueDiligenceDocument({ dueDiligenceId, documentId }));
  };

  const getDueDiligenceDocumentsAdditionalActions = (id: number) => [
    {
      id: 'delete-due-diligence-document',
      icon: DeleteSvg,
      action: () => handleDeleteDocument(id),
    },
  ];

  return (
    <>
      <Table columns={tableColumns} onUpdateSorting={updateSorting}>
        {documents?.length && !isLoading ? (
          documents.map((document) => (
            <DueDiligenceDocumentsTableItem
              key={document.id}
              hasAdditionalActions={userType === UserTypes.ADMIN}
              additionalActions={
                <ButtonsActions
                  anchorPrefix={'due-diligence-document'}
                  actions={getDueDiligenceDocumentsAdditionalActions(document.id)}
                />
              }
              actionsContent={
                <ButtonDropdown
                  className='w-40'
                  actions={getDueDiligenceDocumentActions(document.link, document.filename)}
                  disabledPositionCalculation
                />
              }
              {...document}
            />
          ))
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={tableColumns.length} />
        ) : (
          <NoDocumentsAdded />
        )}
      </Table>
    </>
  );
};

export default DueDiligenceDocumentsTable;
