import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { DealPreviewModalData } from 'interfaces';
import { previewDealClosureOverview } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';
import { ButtonsControl, ModalWindow } from 'shared-components';

import DealClosureOverviewItem from './DealClosureOverviewItem';

interface Props {
  id: number | undefined;
  closingDates: string[];
  availableClosingDates: string[];
  selectedClosingDates: string[];
  setSelectedClosingDates: Dispatch<SetStateAction<string[]>>;
  goToNextStep: (data: DealPreviewModalData) => void;
  isOpen: boolean;
  onClose: VoidFunction;
}

const DealClosureOverviewModal: FC<Props> = ({
  id,
  closingDates,
  availableClosingDates,
  selectedClosingDates,
  setSelectedClosingDates,
  goToNextStep,
  onClose,
  isOpen,
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const openDealPreview = () => {
    setIsLoading(true);
    dispatch(previewDealClosureOverview({ id, selectedClosingDates }))
      .unwrap()
      .then((data) => {
        goToNextStep(data);
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  const sendButtonText = `Preview${selectedClosingDates?.length ? `(${selectedClosingDates?.length})` : ''}`;

  const handleSelectClosingDate = (closingDate: string) => {
    if (!availableClosingDates?.includes(closingDate)) return;

    setSelectedClosingDates((prevClosingDates: string[]) => {
      return prevClosingDates.includes(closingDate)
        ? prevClosingDates.filter((date) => date !== closingDate)
        : [...prevClosingDates, closingDate];
    });
  };

  const closeModal = () => {
    onClose();
    setSelectedClosingDates([]);
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      title='Send Deal Closure Overview Document(s)'
      description='Please select the appropriate closing date(s)'
      className='lg:w-8/12 xl:w-10/12'
      wrapperClassName='lg:p-10'
      onClose={closeModal}
    >
      <div className='mt-8 w-200'>
        <h5 className='text-15 text-grey-500 mb-5'>Closing Dates</h5>
        {closingDates?.map((closingDate) => (
          <DealClosureOverviewItem
            key={closingDate}
            onChange={handleSelectClosingDate}
            checked={selectedClosingDates?.includes(closingDate)}
            disabled={!availableClosingDates.includes(closingDate)}
            closingDate={closingDate}
          />
        ))}
      </div>
      <ButtonsControl
        backButtonText='Cancel'
        onBack={closeModal}
        submitButtonText={sendButtonText}
        onSubmit={openDealPreview}
        disabledSubmitButton={!selectedClosingDates?.length}
        isLoading={isLoading}
      />
    </ModalWindow>
  );
};

export default DealClosureOverviewModal;
