import React, { FC, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { StepperStatuses, stepperStatusesLabels } from 'constants/shared/stepper';
import { TypographyVariants } from 'constants/shared/typography';
import { DealProgressItem } from 'interfaces';
import { ColoredLabel, SnippetLayout } from 'shared-components';
import Stepper from 'shared-components/stepper-v1/Stepper';
import StepperItem from 'shared-components/stepper-v1/StepperItem';
import StepperItemCircle from 'shared-components/stepper-v1/StepperItemCircle';
import StepperItemDetails from 'shared-components/stepper-v1/StepperItemDetails';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';
import Typography from 'shared-components/Typography';
import { getCompanyInvestorsLink, getDueDiligenceLink } from 'utils';
import { getDealsSnippetStatus } from 'utils/deals';

const FOUNDER_SHARE_EXCHANGE_STEPS_TOOLTIP_TITLE: { [key: string]: string } = {
  dueDiligence: 'Due Diligence',
  iac: 'IAC',
  dealParameter: 'Deal',
};

const FounderShareExchangeBlock: FC<DealProgressItem> = ({
  id: companyId,
  dueDiligence,
  iac,
  dealParameter,
  companyName,
}) => {
  const navigate = useNavigate();

  const { steps } = useMemo(() => {
    const combinedSteps = Object.entries({ dueDiligence, iac, dealParameter }) || [];

    const getButtonActionLink = (
      stepIndex: number,
      companyId: number,
      state: string,
      previousStepState: string | null,
    ) => {
      if (stepIndex === 1 && !['Approved', 'Finished'].includes(state ?? '')) {
        return () => navigate(getDueDiligenceLink(companyId));
      }
      if (
        stepIndex === 2 &&
        !['Approved', 'Finished'].includes(state ?? '') &&
        ['Approved', 'Finished'].includes(previousStepState ?? '')
      ) {
        return () => navigate(getDueDiligenceLink(companyId));
      }
      if (stepIndex === 3 && ['Approved', 'Finished'].includes(previousStepState ?? '')) {
        return () => navigate(getCompanyInvestorsLink(companyId));
      }
      return undefined;
    };

    const formattedSteps = combinedSteps.map(([id, data], index) => ({
      step: index + 1,
      id,
      title: FOUNDER_SHARE_EXCHANGE_STEPS_TOOLTIP_TITLE[id],
      description: data?.tooltip,
      state: data?.state ?? 'Pending',
      status: getDealsSnippetStatus(data?.state),
      isActiveButton: data?.button ?? false,
      actionLink: getButtonActionLink(
        index + 1,
        companyId,
        data?.state ?? 'Pending',
        combinedSteps[index - 1]?.[1]?.state ?? 'Pending',
      ),
    }));

    const currentStepIndex = combinedSteps.map(([_, data]) => !!data).lastIndexOf(true);

    return { steps: formattedSteps, currentStepIndex, currentStepData: combinedSteps[currentStepIndex][1] };
  }, [dealParameter, dueDiligence, iac, companyId, navigate]);

  return (
    <SnippetLayout className='mb-6 overflow-auto' childrenClassName='flex items-center justify-between'>
      <Typography variant={TypographyVariants.BODY_SMALL} className='flex flex-col mr-6 basis-4/12'>
        Company Name
        <Typography tag='span' className='mt-1' variant={TypographyVariants.MEDIUM}>
          {companyName}
        </Typography>
      </Typography>

      <div className='w-4/6'>
        <Stepper
          steps={steps}
          renderContent={({ step, status, state, actionLink, ...stepDetails }) => (
            <StepperItem
              key={step}
              isActive={status === StepperStatuses.SUCCESS}
              isError={status === StepperStatuses.CANCEL}
              renderStepContent={() => <StepperItemCircle status={status} />}
              onClick={actionLink}
            >
              <StepperItemDetails step={step} {...stepDetails}>
                <ColoredLabel className='mt-4' variant={stepperStatusesLabels[status].variant}>
                  {state || 'Pending'}
                </ColoredLabel>
              </StepperItemDetails>
            </StepperItem>
          )}
          renderSkeleton={() => <StepperSkeleton count={4} withDetails />}
        />
      </div>
    </SnippetLayout>
  );
};

export default FounderShareExchangeBlock;
