import React, { PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { Control, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

import {
  DUE_DILIGENCE_DOCUMENTS_TYPES,
  getUploadDocumentsFormDocumentTypeFields,
  UPLOAD_DOCUMENTS_FORM_NAME_FIELD,
  UploadDocumentsFieldNames,
} from 'constants/due-diligence';
import { REQUIRED_MESSAGE } from 'constants/global';
import useModal from 'hooks/use-modal/useModal';
import { DueDiligenceUploadDocumentsInput, Option } from 'interfaces';
import { DatePickerInput, FormContent } from 'shared-components';

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  control?: Control<T>;
  disabled?: boolean;
  documentTypesOptions?: Option[];
  values?: DueDiligenceUploadDocumentsInput;
  nameFieldDocumentType?: DUE_DILIGENCE_DOCUMENTS_TYPES;
}

const UploadDueDiligenceDocumentsModalInputs = <T extends FieldValues>({
  register,
  errors,
  control,
  disabled,
  documentTypesOptions,
  values,
  nameFieldDocumentType,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const { isOpen: isCalendarOpen, onOpen: onCalendarOpen, onClose: onCalendarClose } = useModal();

  const isShownDocumentNameField = nameFieldDocumentType && values?.documentType?.value === nameFieldDocumentType;

  return (
    <div className={cn('grid grid-cols-4 gap-x-5 mt-6', { '[&+.upload-files]:min-h-[270px]': isCalendarOpen })}>
      <FormContent
        fields={getUploadDocumentsFormDocumentTypeFields(disabled, documentTypesOptions)}
        register={register}
        errors={errors}
        control={control}
      />
      <DatePickerInput
        name={UploadDocumentsFieldNames.OFFICIAL_DATE}
        control={control as Control}
        label='Document Date'
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        validation={{ required: REQUIRED_MESSAGE }}
        labelClassName='labelClassName'
        className='mb-0'
      />

      {isShownDocumentNameField && (
        <FormContent
          fields={[UPLOAD_DOCUMENTS_FORM_NAME_FIELD]}
          register={register}
          errors={errors}
          control={control}
        />
      )}
    </div>
  );
};

export default UploadDueDiligenceDocumentsModalInputs;
