import React, { useCallback, useEffect, useState } from 'react';

import { ReactComponent as EditSvg } from 'assets/svg/pencil.svg';
import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ConstraintVariants } from 'constants/shared';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useSelectId from 'hooks/useSelectId';
import { NumberOrNull } from 'interfaces/global.interfaces';
import { deleteFundById, getFundsList } from 'modules/funds/action';
import { selectFundsListData } from 'modules/funds/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import CreateFundModal from 'page-components/funds/CreateFundModal';
import FundsListItem from 'page-components/funds/FundsListItem';
import { AlertModal, Constraint, Pagination, SearchForm, TitleWithDescription, ListSkeleton } from 'shared-components';
import Typography from 'shared-components/Typography';

const Funds = () => {
  const dispatch = useAppDispatch();

  const { list, isLoading, ...fundsPaginationData } = useAppSelector(selectFundsListData);

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();

  const [deleteId, handleSetDeleteId, handleClearDeleteId] = useSelectId();
  const [editId, handleSetEditId, handleClearEditId] = useSelectId();
  const [isActionLoading, setIsActionLoading] = useState(false);

  const getFundActions = (id: NumberOrNull) => [
    { id: 'edit', icon: EditSvg, action: () => handleSetEditId(id) },
    {
      id: 'delete',
      icon: DeleteSvg,
      action: () => {
        handleSetDeleteId(id);
      },
    },
  ];

  const loadFundsList = useCallback(
    () => dispatch(getFundsList({ page, per_page: 5, query })),
    [dispatch, page, query],
  );

  const handleDeleteFund = () => {
    if (!deleteId) return;

    setIsActionLoading(true);
    dispatch(deleteFundById(deleteId))
      .unwrap()
      .then(loadFundsList)
      .finally(() => {
        handleClearDeleteId();
        setIsActionLoading(false);
      });
  };

  useEffect(() => {
    loadFundsList();
  }, [loadFundsList]);
  return (
    <DashboardLayout>
      <AlertModal
        secondModal
        isLoading={isActionLoading}
        onSubmit={handleDeleteFund}
        onClose={handleClearDeleteId}
        isOpen={deleteId !== null}
        title={'Are you sure?'}
        backButtonText='No'
        submitButtonText='Yes'
        wrapperModalClassName='lg:px-4 2xl:px-14'
      />
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription className='mb-6' title='Funds' />
        <div className='flex justify-between flex-col sm:flex-row gap-6 mb-12'>
          <SearchForm
            className='w-full sm:w-8/12 flex-col xs:flex-row items-center gap-4 xs:gap-0 basis-full sm:basis-7/12'
            placeholder='Search by fund name...'
            handleFilter={handleFilter}
          />
          <CreateFundModal page={page} setPage={setPage} loadFunds={loadFundsList} />
        </div>
        <div>
          {list?.length && !isLoading ? (
            list.map((fund) => (
              <FundsListItem
                key={fund.id}
                isEdit={editId === fund.id}
                actions={getFundActions(fund.id)}
                handleCloseEditMode={handleClearEditId}
                fund={fund}
              />
            ))
          ) : isLoading ? (
            <ListSkeleton />
          ) : (
            <Typography>No Funds Added</Typography>
          )}
        </div>
        <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...fundsPaginationData} />
      </Constraint>
    </DashboardLayout>
  );
};

export default Funds;
