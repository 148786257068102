import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import {
  selectIsDueDiligenceEditable,
  selectParentDueDiligenceStatusFinishedOrDeclined,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { Button } from 'shared-components';

export interface Props {
  className?: string;
  onClickEdit: VoidFunction;
  onClickSave?: VoidFunction;
  onClickDiscardChanges?: VoidFunction;
  isEditing?: boolean;
  isLoading?: boolean;
}

const SignificantControlCompanyActionsButtons: FC<Props> = ({
  className,
  onClickEdit,
  onClickSave,
  onClickDiscardChanges,
  isEditing,
  isLoading,
}) => {
  const isParentDueDiligenceFinishedOrDeclined = useAppSelector(selectParentDueDiligenceStatusFinishedOrDeclined);
  const isEditable = useAppSelector(selectIsDueDiligenceEditable);

  const hideEditButton = isParentDueDiligenceFinishedOrDeclined || !isEditable;

  return (
    <div className={twMerge('w-full flex justify-end items-end flex-col sm:flex-row gap-4 sm:gap-0 pt-6', className)}>
      {isEditing ? (
        <>
          <Button
            disabled={isLoading}
            variant={ButtonVariants.SECONDARY}
            className='w-full sm:w-fit text-sm sm:mr-6'
            onClick={onClickDiscardChanges}
          >
            Discard Changes
          </Button>

          <Button
            isLoading={isLoading}
            variant={ButtonVariants.SECONDARY}
            className='w-full sm:w-[130px] text-sm'
            onClick={onClickSave}
          >
            Save
          </Button>
        </>
      ) : (
        <>
          {!hideEditButton && (
            <Button className='w-full sm:w-[210px] sm:ml-6 text-sm' onClick={onClickEdit}>
              Edit
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default SignificantControlCompanyActionsButtons;
