import { TableColumns } from 'interfaces';

import { ColoredLabelVariants } from './shared';

export enum BillStatuses {
  PAYMENT_REQUESTED = 'payment_requested',
  PAID = 'paid',
}

export enum PayBillModalTypes {
  PAY_BILL = 'pay_bill',
  PAY_SUCCESS = 'pay_success',
  PAY_FAILED = 'pay_failed',
  PAY_PENDING = 'pay_pending',
}

export enum PayBillModalTypeByStatus {
  success = PayBillModalTypes.PAY_SUCCESS,
  failed = PayBillModalTypes.PAY_FAILED,
}

export const MY_BILLS_PAGE_TITLE = 'My Invoices';

export const MY_BILLS_PAGE_DESCRIPTION =
  "As part of the Share Exchange process, you will be sent an invoice to cover planD's introducer fee (this is outlined in the documents you will be sent during the process). This fee is equivalent to the greater of a) £100 or b) 1.5% of your initial investment value";

export const MY_BILLS_SEARCH_PLACEHOLDER = 'Search by Invoice number, reference, Deal ID';

export const getMyBillsPageTable = (additionalColumn: TableColumns): TableColumns[] => [
  additionalColumn,
  { id: 'action', title: '', width: 'w-36' },
  { id: 'billNo', title: 'Invoice No.' },
  { id: 'billRef', title: 'Invoice Ref.' },
  { id: 'dealId', title: 'Deal ID' },
  { id: 'companyName', title: 'Company Name', width: 'w-1/6' },
  { id: 'total_amount', title: 'Amount' },
  { id: 'created_at', title: 'Date Created' },
  { id: 'status', title: 'Status' },
];

export const BILL_STATUS_COLORED_LABEL_STYLE: {
  [key in BillStatuses]: {
    children: string;
    variant: ColoredLabelVariants;
  };
} = {
  [BillStatuses.PAID]: { children: 'Paid', variant: ColoredLabelVariants.SUCCESS },
  [BillStatuses.PAYMENT_REQUESTED]: { children: 'Payment required', variant: ColoredLabelVariants.ERROR },
};

export const PAY_BILL_MODAL_DESCRIPTION = 'Review the invoice below and confirm they are ones you would like to pay';

export const PAY_BILL_MODAL_TITLE = 'Pay your Bills';

export const PAY_BILL_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: '' },
  { id: 'invoiceNumber', title: 'Invoice No.' },
  { id: 'dealId', title: 'Deal ID' },
  { id: 'companyName', title: 'Company Name' },
  { id: 'amount', title: 'Amount' },
  { id: 'createdAt', title: 'Date Created' },
  { id: 'status', title: 'Status' },
];

export const payBillModalCommonStyles = {
  className: 'min-w-[70%] 2xl:min-w-[60%]',
  wrapperClassName: 'pt-10',
};

export const INVOICES_ADMIN_PAGE_TITLE = 'Manage invoices';

export const INVOICES_ADMIN_PAGE_SEARCH_PLACEHOLDER = 'Search by Deal Id, Invoice No, Company';

export const INVOICES_ADMIN_TABLE_TABS: TableColumns[] = [
  { id: '', title: '', width: 'w-200' },
  { id: 'invoiceNo', title: 'Invoice No' },
  { id: 'invoiceRef', title: 'Invoice Ref' },
  { id: 'deal_id', title: 'Deal Id' },
  { id: 'companyName', title: 'Company', sortable: true },
  { id: 'investor', title: 'Investor', sortable: true },
  { id: 'amountInvested', title: 'Amount' },
  { id: 'created_at', title: 'Date Created', sortable: true },
  { id: 'status', title: 'Status', sortable: true },
];
