import React, { FC, useMemo } from 'react';

import { format } from 'date-fns';

import { SHARE_EXCHANGE_SNIPPET_TITLE } from 'constants/dashboard';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { DEAL_STATUSES } from 'constants/deals';
import { stepperStatusesLabels } from 'constants/shared/stepper';
import { Deal, DealStepItem } from 'interfaces';
import { selectDealStages, selectDealStatus } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { ColoredLabel, SnippetLayout } from 'shared-components';
import Stepper from 'shared-components/stepper-v1/Stepper';
import StepperItem from 'shared-components/stepper-v1/StepperItem';
import StepperItemCircle from 'shared-components/stepper-v1/StepperItemCircle';
import StepperItemDetails from 'shared-components/stepper-v1/StepperItemDetails';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';
import Typography from 'shared-components/Typography';
import { calculatePercentage, formatStepsProgressData, openUrlInSeparateTab } from 'utils';
import { getDealStageStepperStatus } from 'utils/deals';

type Props = Pick<Deal, 'stage' | 'closingDate' | 'documentsUrls'>;

const DealsDetailsStepsSnippet: FC<Props> = ({ stage, closingDate = '', documentsUrls }) => {
  const stages = useAppSelector(selectDealStages);
  const dealStatus = useAppSelector(selectDealStatus);

  const isStopped = dealStatus === DEAL_STATUSES.STOPPED;
  const isDeleted = dealStatus === DEAL_STATUSES.DELETED;

  const isDeletedOrStopped = isStopped || isDeleted;

  const { steps, currentStep } = useMemo(() => {
    const { steps: formattedSteps, currentStep } = formatStepsProgressData(stages, stage || '');

    const mappedSteps = formattedSteps?.map(({ key, text, isCompleted, documentKey }, index) => ({
      step: index + 1,
      stageKey: key,
      title: text,
      isCompleted,
      link: documentKey ? documentsUrls[documentKey] : undefined,
    }));

    return { steps: mappedSteps, currentStep };
  }, [documentsUrls, stage, stages]);

  const progress = calculatePercentage(stages?.length - 1, currentStep || 0);
  return (
    <SnippetLayout className='mt-6 pb-5 basis-full max-w-full'>
      <div className='flex justify-between'>
        <Typography className='text-grey-500'>
          {SHARE_EXCHANGE_SNIPPET_TITLE}: <b>{progress}% complete</b>
        </Typography>
        {closingDate ? (
          <Typography className='text-grey-500'>
            Anticipated closing date:{' '}
            <Typography tag='span' className='text-grey-800'>
              {format(new Date(closingDate), DAY_FORMAT_WITH_SLASHES)}
            </Typography>
          </Typography>
        ) : null}
      </div>
      <div className='mt-6'>
        <Stepper<DealStepItem>
          steps={steps}
          renderContent={({ step, isCompleted, link, ...stepDetails }, index) => {
            const isCurrentStage = index === currentStep;

            const status = getDealStageStepperStatus({
              isCompleted,
              isStopped: isStopped && isCurrentStage,
              isDeleted: isDeleted && isCurrentStage,
            });

            return (
              <StepperItem
                key={step}
                lineClassName='w-150'
                isActive={isCompleted}
                isError={isDeletedOrStopped && isCurrentStage}
                renderStepContent={() => <StepperItemCircle status={status} />}
                onClick={
                  link
                    ? () => {
                        if (link) openUrlInSeparateTab(link);
                      }
                    : undefined
                }
              >
                <StepperItemDetails step={step} {...stepDetails}>
                  <ColoredLabel className='mt-4' {...stepperStatusesLabels[status]} />
                </StepperItemDetails>
              </StepperItem>
            );
          }}
          renderSkeleton={() => <StepperSkeleton count={4} withDetails />}
        />
      </div>
    </SnippetLayout>
  );
};

export default DealsDetailsStepsSnippet;
