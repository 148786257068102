import { FormItem } from 'interfaces';
import { Input, SelectFormInput } from 'shared-components';
import NumberInput from 'shared-components/NumberInput';

import { REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export enum FundFormFieldNames {
  NAME = 'name',
  SHARE_PRICE = 'sharePrice',
  TYPES_OF_SHARES = 'typesOfShares',
  SHARES_ISSUED = 'sharesIssued',
  AUM = 'assetsUnderManagement',
}

export const FUNDS_ITEM_FORM_FIELDS: FormItem[] = [
  {
    name: FundFormFieldNames.NAME,
    label: 'Name',
    placeholder: 'Name',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: { required: REQUIRED_MESSAGE },
    isAbsoluteError: true,
  },
  {
    name: FundFormFieldNames.SHARE_PRICE,
    label: 'Share Price',
    placeholder: 'Share Price',
    type: FieldTypes.NUMBER,
    required: true,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    isAbsoluteError: true,
    fractionLength: 50,
  },
  {
    name: FundFormFieldNames.TYPES_OF_SHARES,
    label: 'Types Of Shares',
    placeholder: 'Types Of Shares',
    type: FieldTypes.SELECT,
    required: true,
    component: SelectFormInput,
    validation: { required: REQUIRED_MESSAGE },
    options: [{ label: 'Ordinary', value: 'Ordinary' }],
    isAbsoluteError: true,
  },
  {
    name: FundFormFieldNames.SHARES_ISSUED,
    label: 'Shares Issued',
    placeholder: 'Shares Issued',
    type: FieldTypes.NUMBER,
    required: true,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    isAbsoluteError: true,
  },
  {
    name: FundFormFieldNames.AUM,
    label: 'Assets under management',
    placeholder: 'Assets under management',
    type: FieldTypes.NUMBER,
    required: true,
    disabled: true,
    component: NumberInput,
    validation: { required: REQUIRED_MESSAGE },
    isAbsoluteError: true,
  },
];
