import React from 'react';

const AdminCompanyProfileRelatedSectionPreloader = () => {
  return (
    <>
      {Array.from(Array(7).keys()).map((key) => (
        <div className='skeleton-loader h-24 w-full mb-7' key={key} />
      ))}
      <div className='skeleton-loader h-46 w-1/2 self-end' />
    </>
  );
};

export default AdminCompanyProfileRelatedSectionPreloader;
