import React, { forwardRef, InputHTMLAttributes } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { TEXT_INPUT_CLASSNAMES } from 'constants/shared';
import { ErrorMessage } from 'shared-components/index';

import PrefixSign from '../PrefixSign';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  shownError?: boolean;
}

const InputWithPrefixSign = forwardRef<HTMLInputElement, Props>(
  ({ className, error, inputClassName, onChange, ...props }, ref) => {
    return (
      <div className={twMerge('mb-6 w-full', className)}>
        <div className='flex'>
          <PrefixSign disabled={props.disabled} error={error} />
          <input
            onChange={onChange}
            {...props}
            className={cn(
              TEXT_INPUT_CLASSNAMES.default.replace('rounded', 'rounded-l-none rounded-r').concat(' outline-none'),
              {
                [`${TEXT_INPUT_CLASSNAMES.error} border-l-0`]: error,
                [TEXT_INPUT_CLASSNAMES.disabled]: props.disabled,
              },
              inputClassName,
            )}
            ref={ref}
          />
        </div>

        <ErrorMessage error={error} />
      </div>
    );
  },
);

export default InputWithPrefixSign;

InputWithPrefixSign.displayName = 'Input';
