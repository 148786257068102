import React, { FC } from 'react';

import { SHARE_EXCHANGE_REQUESTS_TABLE_COLUMNS } from 'constants/share-exchange';
import { ShareExchangeInterfaces, UpdateSortingFunctionType } from 'interfaces';
import Table, { TableSkeleton } from 'shared-components/table';

import ShareExchangeRequestsNoAdded from './ShareExchangeRequestsNoAdded';
import ShareExchangeRequestsTableItem from './ShareExchangeRequestsTableItem';

interface Props {
  data: ShareExchangeInterfaces[];
  loading?: boolean;
  updateSorting: UpdateSortingFunctionType;
}

const ShareExchangeRequestsTable: FC<Props> = ({ data, loading, updateSorting }) => {
  return (
    <>
      <Table columns={SHARE_EXCHANGE_REQUESTS_TABLE_COLUMNS} onUpdateSorting={updateSorting}>
        {loading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={SHARE_EXCHANGE_REQUESTS_TABLE_COLUMNS?.length} />
        ) : data?.length ? (
          data.map((item) => <ShareExchangeRequestsTableItem key={item.id} {...item} />)
        ) : (
          <ShareExchangeRequestsNoAdded />
        )}
      </Table>
    </>
  );
};

export default ShareExchangeRequestsTable;
