import React, { FC, ReactElement } from 'react';

import cn from 'classnames';
import { Control, Controller } from 'react-hook-form';
import StateManagedSelect, { default as SelectComponent } from 'react-select';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SmallInformationIcon } from 'assets/svg/small-info.svg';
import useModal from 'hooks/use-modal/useModal';
import { FormItem, FromItemHigligtedColor } from 'interfaces';
import HighlightedMessage from 'shared-components/higlighted-message/HighlightedMessage';

import { FieldTooltipVariants } from '../constants/shared/input';
import IconWithTooltip from './IconWithTooltip';

import { ErrorMessage, FormLabel } from './index';

export type Props = {
  control: Control;
  isMulti?: boolean;
  className?: string;
  labelClassName?: string;
  isSearchable?: boolean;
  anchorPrefix?: string;
  isAbsoluteError?: boolean;
  isClearable?: boolean;
  onClearCallback?: VoidFunction;
  tooltipVariant?: FieldTooltipVariants;
} & Partial<StateManagedSelect> &
  Omit<FormItem, 'component'>;
const SelectFormInput: FC<Props> = ({
  isMulti,
  className,
  label,
  name,
  options,
  control,
  placeholder,
  disabled,
  validation,
  labelClassName,
  customCompanySelectStyles,
  isSearchable = true,
  tooltipContent,
  anchorPrefix,
  highlighted,
  highlightedDescription,
  isAbsoluteError,
  isClearable,
  onClearCallback,
  tooltipVariant = FieldTooltipVariants.WHOLE_INPUT,
  highlightedColor = FromItemHigligtedColor.Blue,
}) => {
  const { isOpen: isTooltipOpen, onOpen: handleOpenTooltip, onClose: handleCloseTooltip } = useModal(false);

  const anchorId = `${anchorPrefix}-${name}`;

  const renderedTooltip = (): ReactElement | string => {
    if (!tooltipContent) return '';

    if (typeof tooltipContent === 'function') {
      const Component = tooltipContent;
      return <Component />;
    }

    return tooltipContent;
  };

  return (
    <div
      className={twMerge('mb-6 w-full', className)}
      id={tooltipVariant === FieldTooltipVariants.WHOLE_INPUT ? anchorId : undefined}
      onMouseEnter={handleOpenTooltip}
      onMouseLeave={handleCloseTooltip}
    >
      <>
        <div className='flex gap-2'>
          <>
            {typeof label === 'string' ? <FormLabel className={labelClassName}>{label}</FormLabel> : label}
            {tooltipVariant === FieldTooltipVariants.WITH_ICON && tooltipContent && (
              <IconWithTooltip
                wrapperClassName='ml-[5px] cursor-pointer'
                tooltipClassName='font-normal'
                tooltipContent={renderedTooltip()}
                anchorId={anchorId}
                offset={1}
                place='top'
                icon={<SmallInformationIcon />}
              />
            )}
          </>
        </div>
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <>
              <SelectComponent
                isSearchable={isSearchable}
                options={options}
                onChange={(selectedOption, triggeredAction) => {
                  if (triggeredAction.action === 'clear' && onClearCallback) {
                    onClearCallback();
                  }

                  onChange(selectedOption);
                }}
                isMulti={isMulti}
                placeholder={placeholder}
                isDisabled={disabled}
                isClearable={isClearable}
                menuPosition='fixed'
                menuPortalTarget={window.document.body}
                styles={{
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: '#4068BF',
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    whiteSpace: 'nowrap',
                  }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  ...customCompanySelectStyles,
                }}
                className={cn('select-input div-py-0', {
                  'select-input-error': error,
                  [`select-input-highlighted-${highlightedColor}`]: !error && highlighted,
                  '[&>div]:!bg-grey-100 [&>div]:!border-grey-200 [&_div]:!text-grey-300': disabled,
                })}
                {...field}
                value={Object.values(field?.value || {}).includes(null) ? null : field.value}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />

              <ErrorMessage error={error?.message} isAbsoluteError={isAbsoluteError} />

              {!error && highlightedDescription && (
                <HighlightedMessage className='input-error-message' color={highlightedColor}>
                  {highlightedDescription}
                </HighlightedMessage>
              )}
            </>
          )}
        />
      </>
      {tooltipVariant === FieldTooltipVariants.WHOLE_INPUT && tooltipContent && isTooltipOpen && (
        <Tooltip
          anchorId={anchorId}
          offset={-5}
          positionStrategy='fixed'
          className='text-sm text-grey-500'
          isOpen
          clickable
          noArrow
        >
          {renderedTooltip()}
        </Tooltip>
      )}
    </div>
  );
};

export default SelectFormInput;
