import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants, ButtonVariantsStyles } from 'constants/shared/button';
import { getButtonDisabledVariantByVariant } from 'utils/shared/button';

export interface Props extends LinkProps {
  children: ReactNode;
  className?: string;
  variant?: ButtonVariants;
  disabled?: boolean;
}

export const LINK_BUTTON_DATA_TEST_ID = 'LINK_BUTTON_DATA_TEST_ID';

const LinkButton: FC<Props> = ({ className, variant = ButtonVariants.LINK, disabled, hidden, ...props }) => {
  if (hidden) return null;

  return (
    <Link
      data-testid={LINK_BUTTON_DATA_TEST_ID}
      className={twMerge(
        cn(
          'w-fit flex justify-center items-center transition-all duration-300 outline-[3px] outline-green-300 focus:outline focus:-outline-offset-1 py-3.75 px-6 rounded leading-6 cursor-pointer m-0 text-center',
          ButtonVariantsStyles[variant],
          {
            [ButtonVariantsStyles[getButtonDisabledVariantByVariant(variant)]]: disabled,
          },
          className,
        ),
      )}
      {...props}
    />
  );
};

export default LinkButton;
