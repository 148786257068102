import React, { FC } from 'react';

import { QUALIFICATION_QUESTIONS_MODAL_TITLE } from 'constants/my-investments';
import { REVIEW_SHARE_EXCHANGE_QUALIFICATION_QUESTIONS_ANSWERS_MODAL_DESCRIPTION } from 'constants/share-exchange';
import { ShareExchangeSubmittedAnswers } from 'interfaces';
import { ModalWindow } from 'shared-components';

import ShareExchangeQualificationQuestionsReviewModalContent from './ShareExchangeQualificationQuestionsReviewModalContent';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onClickReject: VoidFunction;
  onClickAccept: VoidFunction;
  preventOutsideClick: boolean;
  isLoading: boolean;
  answers?: ShareExchangeSubmittedAnswers[] | null;
};

const ShareExchangeQualificationQuestionsReviewModal: FC<Props> = ({
  isOpen,
  onClose,
  answers,
  onClickAccept,
  onClickReject,
  preventOutsideClick,
  isLoading,
}) => {
  return (
    <ModalWindow
      title={QUALIFICATION_QUESTIONS_MODAL_TITLE}
      description={REVIEW_SHARE_EXCHANGE_QUALIFICATION_QUESTIONS_ANSWERS_MODAL_DESCRIPTION}
      className='lg:w-8/12 xl:w-6/12 lg:h-12/12'
      wrapperClassName='px-10 py-[30px] lg:px-10 lg:py-[30px]'
      isOpen={isOpen}
      onClose={onClose}
      preventOutsideClick={preventOutsideClick}
    >
      {answers && (
        <ShareExchangeQualificationQuestionsReviewModalContent
          onClickAccept={onClickAccept}
          onClickReject={onClickReject}
          answers={answers}
          isLoading={isLoading}
        />
      )}
    </ModalWindow>
  );
};

export default ShareExchangeQualificationQuestionsReviewModal;
