import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { DueDiligenceSections } from 'constants/due-diligence';
import {
  BusinessDetails,
  DueDiligenceData,
  DueDiligenceDocument,
  DueDiligenceDocumentPayload,
  DueDiligenceLegalMatters,
  DueDiligenceLegalMattersPayload,
  GetListPayload,
  MetaPage,
  SignificantControlDeletePerson,
  SignificantControlPersonAddressInput,
  DueDiligencePersonInput,
  SignificantControlPerson,
  DueDiligenceAddCommentPayload,
  Comment,
  TruncatedUser,
  IdWithMetaPayload,
  DueDiligenceBusinessDetails,
  PersonsDeletedAddress,
  DueDiligenceResourcePath,
  UpdateDueDiligenceDocumentTypesResponse,
  DueDiligenceFundraisingDetails,
  DueDiligenceCompanyRunway,
  CreateOrUpdateCompanyRunway,
  CreateOrUpdateFundraisingDetails,
  GetListPayloadWithId,
  DueDiligenceDataWithoutChildren,
  GetDueDiligencePayload,
} from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class DueDiligenceApis {
  constructor(private url: string) {}

  getDueDiligenceData(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DueDiligenceData }>(`${this.url}/${id}/due-diligences`, config);
  }

  getDueDiligenceCheckLockData(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ isLocked: boolean }>(`${this.url}/${id}/check-lock`, config);
  }

  createDueDiligence(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: DueDiligenceData }>(`${this.url}/${id}/due-diligences`, config);
  }

  deleteDueDiligenceDocument({ dueDiligenceId, documentId }: DueDiligenceDocumentPayload, config?: AxiosRequestConfig) {
    return httpApiClient.delete<UpdateDueDiligenceDocumentTypesResponse>(
      `${this.url}/${dueDiligenceId}/documents/${documentId}`,
      config,
    );
  }

  getDueDiligenceDocuments({ id, ...params }: GetListPayload & { id: string }, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DueDiligenceDocument[]; meta: MetaPage }>(
      `${this.url}/${id}/due-diligences/documents?${stringifyFilterParams(params)}`,
      config,
    );
  }

  uploadDueDiligenceDocument(
    { formData, dueDiligenceId }: { formData: FormData; dueDiligenceId: string },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post<UpdateDueDiligenceDocumentTypesResponse>(
      `${this.url}/${dueDiligenceId}/documents`,
      formData,
      config,
    );
  }

  saveDueDiligenceLegalMatters(
    { dueDiligenceId, ...payload }: Partial<DueDiligenceLegalMattersPayload>,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post<{ data: DueDiligenceLegalMatters }>(
      `${this.url}/${dueDiligenceId}/legal-matters`,
      payload,
      config,
    );
  }

  editDueDiligenceBusinessDetails(
    {
      id,
      ...payload
    }: Partial<Omit<BusinessDetails, 'approvalForSecondarySale'>> & { approvalForSecondarySale?: string },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.patch<{ data: DueDiligenceBusinessDetails }>(`${this.url}/${id}`, payload, config);
  }

  createSignificantControlPerson(
    { id, person, resourcePath }: { id: number; person: Partial<DueDiligencePersonInput> } & DueDiligenceResourcePath,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post<{ data: SignificantControlPerson }>(`${this.url}/${id}/${resourcePath}`, person, config);
  }
  createSignificantControlPersonAddress(
    {
      id,
      address,
      resourcePath,
    }: { id: number; address: SignificantControlPersonAddressInput } & DueDiligenceResourcePath,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post(`${this.url}/${resourcePath}/${id}/residential-addresses`, address, config);
  }

  updateSignificantControlPersonAddress(
    {
      id,
      address,
      resourcePath,
    }: { id: number; address: SignificantControlPersonAddressInput } & DueDiligenceResourcePath,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.put(`${this.url}/${resourcePath}/${id}/residential-addresses/${address.id}`, address, config);
  }

  deleteSignificantControlPersonAddress(
    { id, addressId, resourcePath }: PersonsDeletedAddress & DueDiligenceResourcePath,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.delete(`${this.url}/${resourcePath}/${id}/residential-addresses/${addressId}`, config);
  }

  updateSignificantControlPerson(
    {
      dueDiligenceId,
      resourcePath,
      ...payload
    }: { dueDiligenceId: number } & Partial<DueDiligencePersonInput> & DueDiligenceResourcePath,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.put(`${this.url}/${dueDiligenceId}/${resourcePath}/${payload?.id}`, payload, config);
  }

  deleteSignificantControlPerson(
    { id, resourcePath, significantId }: SignificantControlDeletePerson & DueDiligenceResourcePath,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.delete(`${this.url}/${id}/${resourcePath}/${significantId}`, config);
  }

  requestFounderInfo({ id, founderId }: { id: number; founderId: number }, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/${id}/send-missing-info-request-to-existing-founder/${founderId}`, config);
  }

  addDueDiligenceComment(
    { dueDiligenceId, ...payload }: Omit<DueDiligenceAddCommentPayload, 'sectionType'>,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.post<{ data: Comment<DueDiligenceSections> }>(
      `${this.url}/${dueDiligenceId}/comments`,
      payload,
      config,
    );
  }

  confirmDueDiligence(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: DueDiligenceBusinessDetails }>(`${this.url}/${id}/confirm`, {}, config);
  }

  rejectDueDiligence(
    { dueDiligenceId, reason }: { dueDiligenceId: number; reason: string },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.patch<{ data: DueDiligenceBusinessDetails }>(
      `${this.url}/${dueDiligenceId}/reject`,
      { reason },
      config,
    );
  }

  reopenDueDiligence(
    { dueDiligenceId, clearReason }: { dueDiligenceId: number; clearReason: string },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.patch<{ data: DueDiligenceBusinessDetails }>(
      `${this.url}/${dueDiligenceId}/re-open`,
      { clearReason },
      config,
    );
  }

  getDueDiligenceFoundersReadyForInvite({ id, ...params }: IdWithMetaPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: TruncatedUser[] }>(
      `${this.url}/${id}/existing-founders-ready-for-invite?${stringifyFilterParams(params)}`,
      config,
    );
  }

  createOrUpdateCompanyRunway({ id, ...payload }: CreateOrUpdateCompanyRunway) {
    return httpApiClient.post<{ data: DueDiligenceCompanyRunway }>(`${this.url}/${id}/company-runways`, payload);
  }

  createOrUpdateFundraisingDetails({ id, ...payload }: Partial<CreateOrUpdateFundraisingDetails> & { id: number }) {
    return httpApiClient.post<{ data: DueDiligenceFundraisingDetails }>(
      `${this.url}/${id}/fundraising-details`,
      payload,
    );
  }

  getArchivedDueDiligence({ id, ...params }: GetListPayloadWithId, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DueDiligenceDataWithoutChildren[] }>(
      `${this.url}/${id}/due-diligences/archives?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getDueDiligenceByDDId({ companyId, dueDiligenceId }: GetDueDiligencePayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: DueDiligenceData }>(
      `${this.url}/${companyId}/due-diligences/${dueDiligenceId}`,
      config,
    );
  }
}

export const adminCompanyDueDiligenceApis = new DueDiligenceApis('/admin/companies');
export const dueDiligenceCheckLockApis = new DueDiligenceApis('/due-diligences');
export const foundersCompanyDueDiligenceApis = new DueDiligenceApis('/founders/companies');
export const adminDueDiligenceApis = new DueDiligenceApis('/admin/due-diligences');
export const foundersDueDiligenceApis = new DueDiligenceApis('/founders/due-diligences');
export const fmUsersCompaniesDueDiligenceApis = new DueDiligenceApis('/fmusers/companies');
export const ddAdminApis = new DueDiligenceApis('/admin');
export const ddFoundersApis = new DueDiligenceApis('/founders');
