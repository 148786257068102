import React, { PropsWithChildren, ReactElement } from 'react';

import { Control, FieldValues, Path, UseFormRegister } from 'react-hook-form';

import { USER_TYPES_OPTIONS } from 'constants/user';
import { UsersFiltersNameFields } from 'interfaces';
import { Checkbox, FormLabel, SelectFormInput } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  disabled: boolean;
};
const UsersFiltersForm = <T extends FieldValues>({
  register,
  control,
  disabled,
}: PropsWithChildren<Props<T>>): ReactElement => {
  return (
    <form className='flex flex-col sm:flex-row'>
      <div>
        <Typography tag='h5' className='text-grey-800 mb-2 font-bold'>
          Applied filters for user
        </Typography>
        <FormLabel>Select user status</FormLabel>
        <div className='flex mb-4'>
          <Checkbox
            disabled={disabled}
            label='User is registered'
            {...register(UsersFiltersNameFields.REGISTERED as Path<T>)}
            className='mr-6'
          />
          <Checkbox
            disabled={disabled}
            label='User is unregistered'
            {...register(UsersFiltersNameFields.UNREGISTERED as Path<T>)}
          />
        </div>
      </div>
      <SelectFormInput
        disabled={disabled}
        className='sm:w-[450px] mb-0 sm:ml-6'
        label='Select user type'
        isMulti
        control={control as Control}
        name={UsersFiltersNameFields.SELECTED_USER_TYPE}
        options={USER_TYPES_OPTIONS}
      />
    </form>
  );
};

export default UsersFiltersForm;
