import React, { FC } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { Checkbox } from 'shared-components';

interface Props {
  closingDate: string;
  checked: boolean;
  disabled: boolean;
  onChange: (closingDate: string) => void;
}

const DealClosureOverviewItem: FC<Props> = ({ closingDate, disabled, checked, onChange }) => {
  const handleSelect = () => onChange(closingDate);

  return (
    <div key={closingDate} className='mb-4 flex items-center justify-between'>
      <label className={cn('font-medium', { 'text-grey-600': disabled })}>
        {format(parseISO(closingDate), DAY_FORMAT_WITH_SLASHES)}
      </label>
      <Checkbox label='Include' checked={checked} disabled={disabled} onChange={handleSelect} />
    </div>
  );
};

export default DealClosureOverviewItem;
