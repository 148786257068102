import { useState } from 'react';

import useModal from './use-modal/useModal';

const useInvestmentsQualificationQuestionsModals = () => {
  const [processingShareExchangeId, setProcessingShareExchangeId] = useState<string>('');

  const {
    isOpen: isOpenQualificationQuestions,
    onClose: onCloseQualificationQuestions,
    onOpen: onOpenQualificationQuestions,
  } = useModal();

  const {
    onOpen: onOpenSuccessShareExchangeRequestModal,
    isOpen: isOpenSuccessShareExchangeRequestModal,
    onClose: onCloseSuccessShareExchangeRequestModal,
  } = useModal();

  const closeQualificationQuestions = () => {
    setProcessingShareExchangeId('');
    onCloseQualificationQuestions();
  };

  return {
    processingShareExchangeId,
    setProcessingShareExchangeId,
    isOpenQualificationQuestions,
    onOpenQualificationQuestions,
    isOpenSuccessShareExchangeRequestModal,
    onCloseSuccessShareExchangeRequestModal,
    onOpenSuccessShareExchangeRequestModal,
    closeQualificationQuestions,
  };
};

export default useInvestmentsQualificationQuestionsModals;
