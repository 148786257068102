import React, { FC } from 'react';

import { StringOrNull } from 'interfaces';
import Typography from 'shared-components/Typography';

interface Props {
  name: string;
  value?: StringOrNull;
}

const DealParametersItem: FC<Props> = ({ name, value }) => {
  return (
    <div className='flex gap-2 px-5 py-3 w-[500px] sm:w-auto odd:bg-grey-100 even:bg-white'>
      <Typography className='text-grey-800 basis-1/2 lg:basis-1/3'>{name}:</Typography>
      <Typography>{value}</Typography>
    </div>
  );
};

export default DealParametersItem;
