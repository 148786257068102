import { TableColumns } from '../interfaces';

export const FOUNDERS_PAGE_TITLE = 'Founders';

export const FOUNDERS_CONFIRM_COMPANIES_MODAL_TITLE = 'Confirm Related Companies';

export const FOUNDERS_CONFIRM_COMPANIES_MODAL_TABLE_COLUMNS: TableColumns[] = [
  { id: 'document', title: 'Company Name', className: 'pl-5' },
  { id: 'date', title: 'Company Number' },
  { id: 'documentAttached', title: 'Confirmed' },
];

export const FOUNDERS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions', width: 'w-150' },
  { id: 'firstName', title: 'First Name', sortable: true, width: 'w-2/12' },
  { id: 'lastName', title: 'Last Name', sortable: true, width: 'w-2/12' },
  { id: 'email', title: 'Email Address', sortable: true, width: 'w-4/12' },
  { id: 'verified', title: 'Verified', width: 'w-2/12' },
  { id: 'phoneNumber', title: 'Phone Number', width: 'w-2/12' },
  { id: 'companies', title: 'Companies', width: 'w-2/12' },
];

export enum FounderProfileInfo {
  EMAIL_ADDRESS = 'Email Address',
  PHONE_NUMBER = 'Phone No',
  CREATED_AT = 'Created At',
}
