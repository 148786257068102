import { RegisterOptions } from 'react-hook-form';

import { FormFieldBasicParams, FormItem, InvitedUser } from 'interfaces';
import { PrivacyPolicyLabel } from 'page-components';
import { Input, Checkbox } from 'shared-components';
import PhoneField from 'shared-components/PhoneField';

import NumberInput from '../shared-components/NumberInput';
import { getTitleWithReplacedYourWord } from '../utils';
import { EMAIL_PATTERN, PASSWORD_PATTERN, REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export enum SignUpFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  WEBSITE = 'website',
  PHONE = 'phone',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'password_confirmation',
  POSITION = 'position',
  COMPANY_NUMBER = 'companyNumber',
  COMPANY_NAME = 'companyName',
  ADDRESS_1 = 'addressLine1',
  ADDRESS_2 = 'addressLine2',
  COUNTY = 'county',
  CITY = 'city',
  COUNTRY = 'country',
  POSTCODE = 'postcode',
  REPRESENTATIVE = 'isAuthorisedToSign',
  NUMBER_OF_SIGNATURES = 'signatureCountRequired',
}

export const SIGN_UP_FIELDS_TEST_IDS = {
  FIRST_NAME_TEST_ID: 'FIRST_NAME_TEST_ID',
  LAST_NAME_TEST_ID: 'LAST_NAME_TEST_ID',
  EMAIL_TEST_ID: 'EMAIL_TEST_ID',
  WEBSITE_TEST_ID: 'WEBSITE_TEST_ID',
  PHONE_TEST_ID: 'PHONE_TEST_ID',
  PASSWORD_TEST_ID: 'PASSWORD_TEST_ID',
  CONFIRM_PASSWORD_TEST_ID: 'CONFIRM_PASSWORD_TEST_ID',
  POSITION_TEST_ID: 'POSITION_TEST_ID',
  COMPANY_NUMBER_TEST_ID: 'COMPANY_NUMBER_TEST_ID',
  COMPANY_NAME_TEST_ID: 'COMPANY_NAME_TEST_ID',
  ADDRESS_1_TEST_ID: 'ADDRESS_1_TEST_ID',
  ADDRESS_2_TEST_ID: 'ADDRESS_2_TEST_ID',
  COUNTY_TEST_ID: 'COUNTY_TEST_ID',
  CITY_TEST_ID: 'CITY_TEST_ID',
  COUNTRY_TEST_ID: 'COUNTRY_TEST_ID',
  POSTCODE_TEST_ID: 'POSTCODE_TEST_ID',
  POLICY_TEST_ID: 'POLICY_TEST_ID',
};

export const SIGN_UP_BUTTON_TEST_IDS = 'SIGN_UP_BUTTON_TEST_IDS';

export const SKIP_NEXT_BUTTON_TEST_ID = 'SKIP_NEXT_BUTTON_TEST_ID';

export const SKIP_NEXT_LINK_TEST_ID = 'SKIP_NEXT_LINK_TEST_ID';

export const INVITED_LIST_TEST_ID = 'INVITED_LIST_TEST_ID';

export enum SIGN_UP_FLOWS {
  CREATE_SHARE_EXCHANGE = 'cse',
}

export const SIGN_UP_PAGE_TITLE = 'Register';

export const SIGN_UP_PAGE_ADDITIONAL_TEXT = 'Already a member?';

export const SIGN_UP_PAGE_ADDITIONAL_LINK = 'Sign In now';

export const FINISH_REGISTRATION_PAGE_TITLE = 'Finish Registration';

export const getInvitedFormValues = (invitedUserData: InvitedUser | null) => ({
  companyNumber: {
    value: invitedUserData?.entityCompany.companyNumber,
    label: invitedUserData?.entityCompany.companyNumber,
  },
  companyName: {
    value: invitedUserData?.entityCompany.companyNumber,
    label: invitedUserData?.entityCompany.companyName,
  },
  email: invitedUserData?.email,
  addressLine1: invitedUserData?.entityCompany.addressLine1,
  addressLine2: invitedUserData?.entityCompany.addressLine2,
  country: invitedUserData?.entityCompany.country,
  city: invitedUserData?.entityCompany.city,
  county: invitedUserData?.entityCompany.county,
  postcode: invitedUserData?.entityCompany.postcode,
});

export const getFirstNameField = ({ tabIndex, className }: FormFieldBasicParams) => ({
  name: SignUpFieldNames.FIRST_NAME,
  label: 'First Name',
  type: FieldTypes.TEXT,
  required: true,
  component: Input,
  validation: { required: 'Required information missing please input First name to continue' },
  dataTestId: SIGN_UP_FIELDS_TEST_IDS.FIRST_NAME_TEST_ID,
  tabIndex,
  className,
});
export const getLastNameField = ({ tabIndex, className }: FormFieldBasicParams) => ({
  name: SignUpFieldNames.LAST_NAME,
  label: 'Last Name',
  type: FieldTypes.TEXT,
  required: true,
  component: Input,
  validation: { required: 'Required information missing please input Last name to continue' },
  dataTestId: SIGN_UP_FIELDS_TEST_IDS.LAST_NAME_TEST_ID,
  tabIndex,
  className,
});
export const getEmailField = ({
  tabIndex,
  disabled,
  onBlur,
  className,
}: {
  disabled?: boolean;
  onBlur?: VoidFunction;
} & FormFieldBasicParams) => ({
  name: SignUpFieldNames.EMAIL,
  label: 'Email Address',
  type: FieldTypes.TEXT,
  required: true,
  disabled,
  component: Input,
  dataTestId: SIGN_UP_FIELDS_TEST_IDS.EMAIL_TEST_ID,
  validation: {
    required: 'Required information missing please input Email address to continue',
    pattern: { message: 'Invalid email address', value: EMAIL_PATTERN },
  },
  className,
  tabIndex,
  onBlur,
});

export const getConfirmPasswordField = ({
  currentPassword,
  tabIndex,
  className,
}: {
  currentPassword: string;
} & FormFieldBasicParams) => ({
  name: SignUpFieldNames.CONFIRM_PASSWORD,
  label: 'Confirm Password',
  type: FieldTypes.PASSWORD,
  component: Input,
  required: true,
  dataTestId: SIGN_UP_FIELDS_TEST_IDS.CONFIRM_PASSWORD_TEST_ID,
  validation: {
    required: 'Required information missing please input Confirmation Password to continue',
    validate: (confirmPassword: string) => currentPassword === confirmPassword || 'Passwords do not match',
  },
  tabIndex,
  className,
});
export const getPhoneField = (
  { tabIndex, className }: FormFieldBasicParams,
  entityName: string,
  isTypeEntity?: boolean,
) => ({
  name: SignUpFieldNames.PHONE,
  type: FieldTypes.PHONE,
  component: PhoneField,
  label: 'Phone Number',
  dataTestId: SIGN_UP_FIELDS_TEST_IDS.PHONE_TEST_ID,
  description: getTitleWithReplacedYourWord(
    'to keep you updated on your portfolio and the progress of any share exchange opportunities',
    entityName,
    isTypeEntity,
  ),
  required: true,
  validation: {
    required: 'Required information missing please input Phone number to continue',
  },
  tabIndex,
  className,
});
export const getPrivacyPolicyField = ({ className, tabIndex }: FormFieldBasicParams) => ({
  name: 'policy',
  label: PrivacyPolicyLabel,
  type: FieldTypes.CHECKBOX,
  dataTestId: SIGN_UP_FIELDS_TEST_IDS.POLICY_TEST_ID,
  component: Checkbox,
  validation: {
    required: 'You should agree to the Policy and Terms & conditions',
  },
  className,
  tabIndex,
});

export const getCompanyAddressFields = () => [
  {
    name: SignUpFieldNames.ADDRESS_1,
    label: 'Address Line 1',
    type: FieldTypes.TEXT,
    component: Input,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.ADDRESS_1_TEST_ID,
    required: true,
    className: 'col-span-full xs:col-span-2 mb-6',
    validation: { required: 'Required information missing please input Address 1 to continue' },
  },
  {
    name: SignUpFieldNames.ADDRESS_2,
    label: 'Address Line 2',
    type: FieldTypes.TEXT,
    component: Input,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.ADDRESS_2_TEST_ID,
    className: 'col-span-full xs:col-span-2 mb-6',
  },
  {
    name: SignUpFieldNames.COUNTY,
    label: 'County',
    type: FieldTypes.TEXT,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.COUNTY_TEST_ID,
    component: Input,
    required: true,
    className: 'col-start-1 col-span-2 xs:col-end-3 mb-6',
    validation: { required: 'Required information missing please input Country 1 to continue' },
  },
  {
    name: SignUpFieldNames.CITY,
    label: 'City',
    type: FieldTypes.TEXT,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.CITY_TEST_ID,
    component: Input,
    required: true,
    className: 'col-start-2 xs:col-start-3 col-span-2 mb-6',
    validation: { required: 'Required information missing please input City to continue' },
  },
  {
    name: SignUpFieldNames.COUNTRY,
    label: 'Country',
    type: FieldTypes.TEXT,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.COUNTRY_TEST_ID,
    component: Input,
    required: true,
    className: 'col-start-1 col-span-2 xs:col-span-1 mb-6',
    validation: { required: 'Required information missing please input Country to continue' },
  },
  {
    name: SignUpFieldNames.POSTCODE,
    label: 'Postcode',
    type: FieldTypes.TEXT,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.POSTCODE_TEST_ID,
    component: Input,
    required: true,
    className: 'col-start-2 col-span-2 xs:col-span-1 mb-6',
    validation: { required: 'Required information missing please input Postcode to continue' },
  },
  {
    name: SignUpFieldNames.WEBSITE,
    label: 'Website',
    type: FieldTypes.TEXT,
    dataTestId: SIGN_UP_FIELDS_TEST_IDS.WEBSITE_TEST_ID,
    component: Input,
    className: 'col-start-3 col-span-4 xs:col-span-2 gap-5 mb-6',
  },
];

export const getEntityFields = () => [
  ...getCompanyAddressFields(),
  {
    name: SignUpFieldNames.NUMBER_OF_SIGNATURES,
    label: 'Please confirm number of signatures required for the entity',
    required: true,
    disabled: false,
    validation: { required: REQUIRED_MESSAGE },
    type: FieldTypes.NUMBER,
    component: NumberInput,
    fractionLength: 50,
    className: 'col-span-full',
  },
  {
    name: SignUpFieldNames.REPRESENTATIVE,
    type: FieldTypes.CHECKBOX,
    label: 'Authorised to sign on behalf of the company',
    disabled: false,
    component: Checkbox,
    className: 'col-span-full row-start-[14] flex justify-centers items-center mb-4',
  },
];

export const getSignUpFields = (
  currentPassword: string,
  excessFields: Array<SignUpFieldNames | string> | undefined = [],
  disabledEmailInput = false,
  entityName: string,
  onBlurEmail?: VoidFunction,
  isTypeEntity?: boolean,
): FormItem[] =>
  [
    getFirstNameField({ tabIndex: 1, className: 'col-start-1 col-end-2 mb-6' }),
    getLastNameField({ tabIndex: 2, className: 'col-span-3 row-span-1 col-start-2 col-end-3 mb-6' }),
    getEmailField({
      tabIndex: 3,
      className: 'col-start-1 col-end-3 mb-6',
      onBlur: onBlurEmail,
      disabled: disabledEmailInput,
    }),
    getConfirmPasswordField({
      tabIndex: 5,
      className: 'col-start-1 col-end-3 mb-6 row-start-5',
      currentPassword,
    }),
    getPhoneField(
      {
        tabIndex: 6,
        className: 'col-start-1 col-end-3 mb-6',
      },
      entityName,
      isTypeEntity,
    ),
    getPrivacyPolicyField({
      className: 'col-start-1 col-end-3 mb-6',
    }),
  ].filter(({ name }) => !excessFields.includes(name));

export const getSignUpInvestorEntityFields = (
  currentPassword: string,
  excessFields: Array<SignUpFieldNames | string> | undefined = [],
  disabledEmailInput = false,
  entityName: string,
  onBlurEmail?: VoidFunction,
  isTypeEntity?: boolean,
) =>
  [
    {
      name: SignUpFieldNames.POSITION,
      label: 'Position / Role at Entity (e.g. Director)',
      type: FieldTypes.TEXT,
      required: true,
      component: Input,
      validation: { required: REQUIRED_MESSAGE },
      className: 'col-start-1 col-span-4',
      tabIndex: 3,
    },
    getFirstNameField({ className: 'col-start-1 col-span-2', tabIndex: 4 }),
    getLastNameField({ className: 'col-start-2 col-span-2', tabIndex: 5 }),
    getEmailField({
      onBlur: onBlurEmail,
      disabled: disabledEmailInput,
      className: 'col-start-1 col-span-4',
      tabIndex: 6,
    }),
    getConfirmPasswordField({
      currentPassword,
      className: 'col-start-1 col-span-4 row-start-[12]',
      tabIndex: 8,
    }),
    getPhoneField({ className: 'col-start-1 col-span-4 row-start-[13]', tabIndex: 9 }, entityName, isTypeEntity),
    getPrivacyPolicyField({ className: 'col-start-1 col-span-4 mb-6 row-start-[16]', tabIndex: 10 }),
  ].filter(({ name }) => !excessFields.includes(name));

export const SIGN_UP_PASSWORD_VALIDATION: RegisterOptions = {
  required: 'Required information missing please input Password to continue',
  pattern: {
    message: 'Password should match all requirements',
    value: PASSWORD_PATTERN,
  },
};

export const SIGN_UP_PAGE_FORM_INITIAL = {
  [SignUpFieldNames.PHONE]: '+44',
  [SignUpFieldNames.WEBSITE]: 'https://',
};

export const SIGN_UP_INVITED_USER_EXCESS_FIELDS = [SignUpFieldNames.EMAIL];
