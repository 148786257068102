import React, { ButtonHTMLAttributes, FC, ReactNode, Ref } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SpinSvg } from 'assets/svg/spin-loader.svg';
import { ButtonVariants, ButtonVariantsStyles } from 'constants/shared/button';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: ButtonVariants;
  className?: string;
  isLoading?: boolean;
  buttonRef?: Ref<HTMLButtonElement>;
}

const Button: FC<Props> = ({
  buttonRef,
  children,
  className,
  variant = ButtonVariants.PRIMARY,
  isLoading,
  ...props
}) => {
  return (
    <button
      ref={buttonRef}
      className={twMerge(
        cn(
          `w-full flex justify-center items-center py-3.75 px-6 transition-all duration-300 border outline-[3px] outline-green-300 disabled:text-grey-300 disabled:border-grey-100 disabled:cursor-not-allowed
          [&_path]:disabled:fill-grey-300 focus:outline focus:-outline-offset-1`,
          ButtonVariantsStyles[variant],
          { 'pointer-events-none user-select-none': isLoading },
          className,
        ),
      )}
      {...props}
    >
      <>
        {isLoading && <SpinSvg className='mr-1 animated-spin' />}
        {children}
      </>
    </button>
  );
};

export default Button;
