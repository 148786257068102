import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { LinkButton, SnippetLayout } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminInvestorsSnippetContent from './AdminInvestorsSnippetContent';

const AdminInvestorsSnippet = () => {
  return (
    <SnippetLayout
      description={
        <LinkButton to={navigateByRole(true, ROUTES.investors)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      title='Investors'
      className='snippet-title col-span-full md:col-span-3 1xl:col-span-2'
      childrenClassName='mt-10 flex justify-around'
    >
      <AdminInvestorsSnippetContent />
    </SnippetLayout>
  );
};

export default AdminInvestorsSnippet;
