import React, { FC } from 'react';

import { InvestorFundShare } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  fundShare: InvestorFundShare;
};

const InvestorFundsSharesTableRow: FC<Props> = ({ fundShare }) => (
  <TableRow>
    <TableData>{fundShare.fundName}</TableData>
    <TableData>{fundShare.classOfShares}</TableData>
    <TableData>{fundShare.totalShares}</TableData>
  </TableRow>
);

export default InvestorFundsSharesTableRow;
