import { MetaPage, MetaState } from './global.interfaces';

export interface UserNotification {
  id: number;
  status: NotificationStatus;
  message: string;
  companyName: string;
  createdAt: string;
}

export interface NotificationState {
  notificationList: NotificationList;
}

export interface NotificationList extends MetaState {
  list: UserNotification[] | null;
  isLoading: boolean;
}

export interface NotificationsResponse {
  data: UserNotification[];
  meta: MetaPage;
}

export interface NotificationResponse {
  data: UserNotification;
}

export enum NotificationStatus {
  UNREAD = 'unread',
  READ = 'read',
}
