import React, { FC, useCallback, useMemo, useState } from 'react';

import cn from 'classnames';
import { PieChart, Pie, Cell, PieProps, Tooltip } from 'recharts';

import { ChartItem } from 'interfaces';

export const PIE_CHART_SKELETON_TEST_ID = 'pie-chart-skeleton';

export interface Props {
  className?: string;
  colors: string[];
  data: ChartItem[];
  activeShape?: PieProps['activeShape'];
  isLoading?: boolean;
  hideChart?: boolean;
  showTooltip?: boolean;
  blendStroke?: boolean;
  paddingAngle?: number;
}

const PieChartComponent: FC<Props> = ({
  className,
  colors,
  data,
  activeShape,
  isLoading,
  hideChart,
  showTooltip,
  paddingAngle = 5,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (i: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );
  const convertedData = useMemo(() => {
    return data.map(({ name, value }) => ({ name, value: Number(value) }));
  }, [data]);

  if (isLoading || hideChart)
    return (
      <div
        className={cn('skeleton-loader min-w-[210px] min-h-[210px] max-w-[210px] rounded-full mx-auto', className)}
        data-testid={PIE_CHART_SKELETON_TEST_ID}
      />
    );

  return (
    <PieChart width={210} height={210} className={className}>
      <Pie
        blendStroke
        data={convertedData}
        activeIndex={activeIndex}
        activeShape={activeShape}
        cx={100}
        cy={100}
        innerRadius={78}
        outerRadius={100}
        paddingAngle={paddingAngle}
        minAngle={3}
        dataKey='value'
        onClick={onPieEnter}
      >
        {convertedData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>

      {showTooltip && <Tooltip />}
    </PieChart>
  );
};

export default PieChartComponent;
