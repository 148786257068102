import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props = {
  totalAmount: number;
  onClickBack: VoidFunction;
  onClickPay: VoidFunction;
};

const PayBillActionsButtons: FC<Props> = ({ totalAmount, onClickBack, onClickPay }) => {
  return (
    <div>
      <div className='flex w-full mt-10 items-center justify-between'>
        <Button onClick={onClickBack} className='w-fit' variant={ButtonVariants.SECONDARY}>
          Back
        </Button>

        <div className='flex items-center'>
          <Typography className='mr-10 text-grey-500 2xl:w-80'>{`Total Amount: £${totalAmount}`}</Typography>
          <Button onClick={onClickPay} className='w-fit'>
            Confirm and Pay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PayBillActionsButtons;
