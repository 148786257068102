import React from 'react';

const NotFound = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen p-2 text-center'>
      <p className='text-40 sm:text-7xl font-bold text-blue-800'>Error 404</p>
      <p className='text-xl text-grey-500 mt-3'>Woops. Looks like this page does not exist</p>
    </div>
  );
};

export default NotFound;
