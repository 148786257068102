import { QualificationQuestionCompany } from 'interfaces';

export const MY_INVESTMENTS_SNIPPET_TITLE = 'Investments Metrics';

export const MY_INVESTMENTS_SNIPPET_DESCRIPTION = 'Overview of all your investments';

export const MY_INVESTMENT_SHARE_EXCHANGE_TITLE = 'Share Exchange Details';

export const MY_INVESTMENTS_SUCCESS_SHARE_EXCHANGE_REQUEST_MODAL_DESCRIPTION =
  'Thank you for initiating a Share Exchange request. planD will carry out their due diligence checks and will let you know if the Share Exchange can proceed. Please note this may take a short amount of time whilst we liaise with the company. ';

export const QUALIFICATION_QUESTIONS_DEFAULT_STATE = {
  isLoading: false,
  list: [],
  company: {} as QualificationQuestionCompany,
};

export enum SHARE_EXCHANGE_STATUSES {
  INITIATED = 'initiated',
  IN_PROGRESS = 'in_progress',
}

export enum COMPANY_WITH_SHARE_EXCHANGE_STATUSES {
  READY_FOR_SER = 'ready_for_ser',
  HAS_ACTIVE_SER = 'has_active_ser',
  READY_FOR_SE = 'ready_for_se',
  HAS_ACTIVE_SE = 'has_active_se',
  SE_REACHED_LIMIT = 'se_reached_the_limit',
}

export const companyShareExchangeStatusOrder = {
  [COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER]: 1,
  [COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SER]: 2,
  [COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE]: 3,
  [COMPANY_WITH_SHARE_EXCHANGE_STATUSES.HAS_ACTIVE_SE]: 4,
  [COMPANY_WITH_SHARE_EXCHANGE_STATUSES.SE_REACHED_LIMIT]: 5,
};

export const QUALIFICATION_QUESTIONS_ERROR_MESSAGE = 'You need to answer all the questions';

export const CANCEL_QUALIFICATION_QUESTIONS_TITLE = 'Confirm Selection';

export const CANCEL_QUALIFICATION_QUESTIONS_DESCRIPTION =
  'Are you sure you want to cancel your share exchange request?';

export const QUALIFICATION_QUESTIONS_MODAL_TITLE = 'Share Exchange Qualification Questions';

export const START_REQUEST_SHARE_EXCHANGE_MODAL_PROMPT_TITLE = 'Request Share Exchange';

export const AVAILABLE_MY_INVESTMENT_TABLE_ITEM_KEYS = [
  { key: 'classOfShares' },
  { key: 'pricePerShare', prefix: '£' },
  { key: 'investmentDate' },
];
