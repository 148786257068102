import React, { Dispatch, FC, SetStateAction } from 'react';

import { unwrapResult } from '@reduxjs/toolkit';

import {
  PAY_BILL_MODAL_TITLE,
  PAY_BILL_MODAL_DESCRIPTION,
  payBillModalCommonStyles,
  PayBillModalTypes,
} from 'constants/my-bills';
import { Bill } from 'interfaces';
import { navigateToCardlessPayPage } from 'modules/bills/action';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ModalWindow, SnippetLayout } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

import PayBillTable from './pay-bill-table/PayBillTable';
import PayBillActionsButtons from './PayBillActionsButtons';
import PayBillModalsHeader from './PayBillModalsHeader';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  bill: Bill | null;
  setModalType: Dispatch<SetStateAction<PayBillModalTypes | null>>;
};

const PayBillModal: FC<Props> = ({ isOpen, onClose, bill, setModalType }) => {
  const dispatch = useAppDispatch();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const onPayBills = () => {
    if (!bill) {
      return;
    }

    setModalType(PayBillModalTypes.PAY_PENDING);

    dispatch(navigateToCardlessPayPage(bill.id))
      .then(unwrapResult)
      .catch(() => {
        setModalType(PayBillModalTypes.PAY_BILL);
      });
  };

  return (
    <ModalWindow {...payBillModalCommonStyles} isOpen={isOpen} onClose={onClose}>
      <PayBillModalsHeader
        title={getTitleWithReplacedYourWord(PAY_BILL_MODAL_TITLE, entityName, isTypeEntity)}
        description={PAY_BILL_MODAL_DESCRIPTION}
      />

      <SnippetLayout>
        <PayBillTable bill={bill} />
      </SnippetLayout>

      <PayBillActionsButtons onClickPay={onPayBills} onClickBack={onClose} totalAmount={bill?.totalAmount || 0} />
    </ModalWindow>
  );
};

export default PayBillModal;
