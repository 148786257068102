import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  DEALS_METRICS_DEFAULT_PIE_CHART_STATE,
  DEALS_METRICS_DEFAULT_TOTAL_STARTED_METRICS,
  DEALS_METRICS_DEFAULT_TOTAL_FINISHED_METRICS,
  DEAL_STATUSES,
} from 'constants/deals';
import { META_INITIAL_STATE } from 'constants/global';
import {
  DealsState,
  Deal,
  MetaPage,
  Option,
  DealsParametersResponse,
  FullDealParameters,
  DealPredefinedData,
  DealStage,
  DealProgressItem,
} from 'interfaces';

import {
  getDashboardDeals,
  getDealsStaticMetrics,
  getDeals,
  getDealsOptions,
  getDealsStartedMetrics,
  getDealsFinishedMetrics,
  getDealsParameters,
  setDealParameters,
  getCompanyDealPredefinedData,
  stopDealById,
  stopDealsParametersById,
  getDealStages,
  updateDealParametersById,
  getDealsProgressList,
  setDealDetails,
  getLastDraftDealPredefinedData,
  restartDeal,
  updateDealFee,
} from './action';

export function getDealsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDeals.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getDeals.fulfilled, (state, action: PayloadAction<{ data: Deal[]; meta: MetaPage }>) => {
    state.isLoading = false;
    state.deals = action.payload.data;
    state.totalPages = action.payload.meta.last_page;
    state.totalDeals = action.payload.meta.total;
  });

  builder.addCase(getDeals.rejected, (state) => {
    state.isLoading = false;
    state.deals = null;
    state.totalPages = 1;
  });
}

export function getDashboardDealsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDashboardDeals.pending, (state, action) => {
    state.isLoading = true;

    const { initialFetch } = action.meta.arg;

    if (initialFetch) {
      state.totalDeals = 0;
      state.dashboardDeals = null;
    }
  });

  builder.addCase(
    getDashboardDeals.fulfilled,
    (state, action: PayloadAction<{ data: Deal[]; meta: MetaPage; initialFetch?: boolean }>) => {
      state.isLoading = false;
      state.totalDeals = action.payload.meta.total;
      state.dashboardDeals = action.payload.initialFetch
        ? action.payload.data
        : [...(state.dashboardDeals || []), ...action.payload.data];
    },
  );

  builder.addCase(getDashboardDeals.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getDealsOptionsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealsOptions.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getDealsOptions.fulfilled, (state, action: PayloadAction<Option[]>) => {
    state.isLoading = false;
    state.dealsOptions = action.payload;
  });

  builder.addCase(getDealsOptions.rejected, (state) => {
    state.isLoading = false;
    state.dealsOptions = [];
  });
}

export function getDealsStaticMetricsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealsStaticMetrics.pending, (state) => {
    state.dealsMetrics.isLoading = true;
  });

  builder.addCase(getDealsStaticMetrics.fulfilled, (state, action: PayloadAction<any>) => {
    const { totalInStages } = action.payload;

    state.dealsMetrics.isLoading = false;
    state.dealsMetrics.totalInStages = { ...totalInStages };
  });

  builder.addCase(getDealsStaticMetrics.rejected, (state) => {
    state.dealsMetrics.isLoading = false;
    state.dealsMetrics.totalInStages = { ...DEALS_METRICS_DEFAULT_PIE_CHART_STATE.totalInStages };
  });
}

export function getStartedDealsMetricsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealsStartedMetrics.pending, (state) => {
    state.dealsMetrics.totalStarted.isLoading = true;
  });

  builder.addCase(getDealsStartedMetrics.fulfilled, (state, action: PayloadAction<any>) => {
    state.dealsMetrics.totalStarted.isLoading = false;
    state.dealsMetrics.totalStarted = { ...action.payload };
  });

  builder.addCase(getDealsStartedMetrics.rejected, (state) => {
    state.dealsMetrics.totalStarted.isLoading = false;
    state.dealsMetrics.totalStarted = { ...DEALS_METRICS_DEFAULT_TOTAL_STARTED_METRICS };
  });
}

export function getFinishedDealsMetricsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealsFinishedMetrics.pending, (state) => {
    state.dealsMetrics.totalFinished.isLoading = true;
  });

  builder.addCase(getDealsFinishedMetrics.fulfilled, (state, action: PayloadAction<any>) => {
    state.dealsMetrics.totalFinished.isLoading = false;
    state.dealsMetrics.totalFinished = { ...action.payload };
  });

  builder.addCase(getDealsFinishedMetrics.rejected, (state) => {
    state.dealsMetrics.totalFinished.isLoading = false;
    state.dealsMetrics.totalFinished = { ...DEALS_METRICS_DEFAULT_TOTAL_FINISHED_METRICS };
  });
}

export function getDealsParametersReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealsParameters.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getDealsParameters.fulfilled, (state, action: PayloadAction<DealsParametersResponse>) => {
    const { meta, data } = action.payload;

    state.isLoading = false;
    state.dealsParameters = { list: data, totalPages: meta.last_page, total: meta.total };
  });

  builder.addCase(getDealsParameters.rejected, (state) => {
    state.isLoading = false;
    state.dealsParameters = {
      ...META_INITIAL_STATE,
      list: [],
    };
  });
}

export function updateDealParametersByIdReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(updateDealParametersById.fulfilled, (state, action: PayloadAction<FullDealParameters>) => {
    state.dealParameters = { ...state.dealParameters, ...action.payload };
  });
}

export function restartDealByIdReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(restartDeal.fulfilled, (state, action: PayloadAction<Deal>) => {
    state.dealDetails = action.payload;
  });
}

export function setDealParametersReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(setDealParameters, (state, action: PayloadAction<FullDealParameters>) => {
    state.dealParameters = action.payload;
  });
}

export function getCompanyDealPredefinedDataReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getCompanyDealPredefinedData.pending, (state) => {
    state.companyDealPredefinedData = null;
  });
  builder.addCase(getCompanyDealPredefinedData.fulfilled, (state, action: PayloadAction<DealPredefinedData>) => {
    state.companyDealPredefinedData = action.payload;
  });
}

export function stopDealByIdReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(stopDealById.fulfilled, (state, action: PayloadAction<Deal>) => {
    state.dealDetails = action.payload;
  });
}

export function getDealStagesReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealStages.fulfilled, (state, action: PayloadAction<DealStage[]>) => {
    state.dealStages = action.payload;
  });
}

export function stopDealsParametersByIdReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(stopDealsParametersById.fulfilled, (state, action: PayloadAction<Record<string, DEAL_STATUSES>>) => {
    state.deals =
      state?.deals?.map(({ status, ...dealParameter }) => ({
        ...dealParameter,
        status: action.payload[dealParameter.id] || status,
      })) || [];
  });
}

export function getDealsProgressListReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getDealsProgressList.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getDealsProgressList.fulfilled, (state, action: PayloadAction<DealProgressItem[]>) => {
    state.dealsProgress = action.payload;
    state.isLoading = false;
  });

  builder.addCase(getDealsProgressList.rejected, (state) => {
    state.isLoading = false;
  });
}

export function setDealDetailsReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(setDealDetails, (state, action: PayloadAction<Deal>) => {
    state.dealDetails = action.payload;
  });
}

export function getLastDraftDealPredefinedDataReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(getLastDraftDealPredefinedData.pending, (state) => {
    state.companyDealPredefinedData = null;
  });
  builder.addCase(getLastDraftDealPredefinedData.fulfilled, (state, action: PayloadAction<DealPredefinedData>) => {
    state.companyDealPredefinedData = action.payload;
  });
}

export function updateDealFeeReducer(builder: ActionReducerMapBuilder<DealsState>) {
  builder.addCase(updateDealFee.fulfilled, (state, action: PayloadAction<Deal>) => {
    state.dealDetails = action.payload;
  });
}
