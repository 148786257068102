import React from 'react';

import { ReactComponent as SpinLoaderSvg } from 'assets/svg/spin-loader.svg';

const DocumentsViewerLoader = () => {
  return (
    <div className='w-full h-full flex justify-center items-center'>
      <SpinLoaderSvg className='animated-spin w-20 h-20' />
    </div>
  );
};

export default DocumentsViewerLoader;
