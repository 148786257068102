import React, { FC, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { TypographyVariants } from 'constants/shared/typography';
import { ChartItem } from 'interfaces';
import Typography from 'shared-components/Typography';
import { calculatePercentageWithDecimals } from 'utils';

import { ChartInfoItem } from '../index';
import ChartInfoListSkeleton from './ChartInfoListSkeleton';

export interface Props {
  infoList: ChartItem[] | undefined;
  colors: string[];
  restCount?: number;
  className?: string;
}

const ChartInfoList: FC<Props> = ({ infoList = [], colors, restCount, className }) => {
  const sumOfValues = useMemo(() => {
    return infoList.reduce((prev, current) => prev + Number(current.value), 0);
  }, [infoList]);

  return (
    <div className={twMerge('grid grid-cols-2 xs:grid-cols-[1fr_1fr_1fr] sm:grid-cols-4 w-full gap-6', className)}>
      {infoList?.length ? (
        infoList.map(({ name, value }, index) => {
          const percentage = calculatePercentageWithDecimals(sumOfValues, Number(value)) || 0;

          return (
            <ChartInfoItem
              key={name + index}
              name={name}
              value={percentage === 0 ? 'LESS THAN 0.1%' : percentage}
              color={colors[index]}
            />
          );
        })
      ) : (
        <ChartInfoListSkeleton />
      )}
      {!!restCount && <Typography variant={TypographyVariants.BODY_SMALL}>+{restCount} more</Typography>}
    </div>
  );
};

export default ChartInfoList;
