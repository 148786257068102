import React, { FC } from 'react';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { InvitedUserStatus } from 'constants/user';
import useCountdownOnClick from 'hooks/useCountdown';
import { DeletePendingInviteUserFunctionType, InvitedUser } from 'interfaces';
import { resendUserInvite } from 'modules/current-user/action';
import { useAppDispatch } from 'modules/store';
import { Button } from 'shared-components';

interface Props extends InvitedUser {
  handleDeleteInvite: DeletePendingInviteUserFunctionType;
}

const MyInvitesItem: FC<Props> = ({ id, email, status, handleDeleteInvite }) => {
  const dispatch = useAppDispatch();

  const { count, isRunning, startCountdown } = useCountdownOnClick(30);

  const isResent = count > 0 && isRunning;

  const isPendingStatus = status === InvitedUserStatus.PENDING;

  const handleResendInvite = () => {
    if (isResent) return;

    dispatch(resendUserInvite(id)).unwrap().then(startCountdown);
  };

  const onClickDeleteInvite = () => handleDeleteInvite({ id, email });

  return (
    <li className='flex justify-between items-center gap-1 mt-4 text-violet-500'>
      <p className='break-all'>{email}</p>
      <div className='flex'>
        {isPendingStatus ? (
          <Button disabled={isResent} variant={ButtonVariants.LINK} onClick={handleResendInvite}>
            {isResent ? `${count}s.` : 'Resend'}
          </Button>
        ) : (
          <p>{status}</p>
        )}

        {status === InvitedUserStatus.PENDING ? (
          <Button className='w-5 p-0 ml-6' variant={ButtonVariants.TRANSPARENT} onClick={onClickDeleteInvite}>
            <DeleteSvg />
          </Button>
        ) : (
          <div className='w-5 ml-6' />
        )}
      </div>
    </li>
  );
};

export default MyInvitesItem;
