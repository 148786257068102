import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { SignatureData, SignatureState } from 'interfaces/signatures.interfaces';

import { getSignatureFromUrl, updateSignature } from './action';

export function updateSignatureReducer(builder: ActionReducerMapBuilder<SignatureState>) {
  builder.addCase(updateSignature.pending, (state) => {
    state.isLoading = true;
    state.isSignatureSaved = false;
  });

  builder.addCase(updateSignature.fulfilled, (state, action: PayloadAction<SignatureData>) => {
    state.isLoading = false;
    state.signature = action.payload;
    state.isSignatureSaved = true;
  });

  builder.addCase(updateSignature.rejected, (state) => {
    state.isLoading = false;
    state.isSignatureSaved = false;
    state.signature = null;
  });
}
export function getSignatureFromUrlReducer(builder: ActionReducerMapBuilder<SignatureState>) {
  builder.addCase(getSignatureFromUrl.pending, (state) => {
    state.signatureUrl.isLoading = true;
  });

  builder.addCase(getSignatureFromUrl.fulfilled, (state, action: PayloadAction<string>) => {
    state.signatureUrl.isLoading = false;
    state.signatureUrl.data = action.payload;
  });

  builder.addCase(getSignatureFromUrl.rejected, (state) => {
    state.signatureUrl.isLoading = false;
    state.signatureUrl.data = null;
  });
}
