import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { LinkButton, SnippetLayout } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminInvoicesSnippetContent from './AdminInvoicesSnippetContent';

const AdminInvoicesSnippet = () => {
  return (
    <SnippetLayout
      title='Invoices'
      description={
        <LinkButton to={navigateByRole(true, ROUTES.invoices)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      className='snippet-title col-span-full md:col-span-3 1xl:col-span-2'
      childrenClassName='mt-10 flex items-center justify-between'
    >
      <AdminInvoicesSnippetContent />
    </SnippetLayout>
  );
};

export default AdminInvoicesSnippet;
