import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { TypographyVariants } from 'constants/shared/typography';

import Typography from './Typography';

export const LABEL_WITH_VALUE_TEST_ID = 'LABEL_WITH_VALUE_TEST_ID';

interface Props {
  title: string;
  content?: string | ReactNode;
  className?: string;
  hideColon?: boolean;
  withLine?: boolean;
}

const LabelWithValue: FC<Props> = ({ title, content, className, hideColon, withLine }) => {
  if (!content) return null;

  return (
    <div
      className={twMerge(
        cn(
          'pr-4 text-grey-500 w-[49%]',
          { 'pl-2 border-l-2 border-violet-500 flex items-center': withLine },
          className,
        ),
      )}
      data-testid={LABEL_WITH_VALUE_TEST_ID}
    >
      <Typography variant={TypographyVariants.BODY_SMALL}>
        {title}
        {!hideColon && ':'}
        {typeof content === 'string' || typeof content === 'number' ? (
          <Typography tag='span' variant={TypographyVariants.BODY_SMALL} className='text-grey-800 ml-1'>
            {content}
          </Typography>
        ) : (
          content
        )}
      </Typography>
    </div>
  );
};

export default LabelWithValue;
