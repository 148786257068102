import React, { useState } from 'react';

import { ADMIN_COMPANIES_PAGE_TITLE } from 'constants/companies';
import useModal from 'hooks/use-modal/useModal';
import { DashboardLayout, CompaniesTable, CompanyIacChooseFundModal } from 'page-components';
import { TitleWithDescription } from 'shared-components';

const AdminCompanies = () => {
  const { isOpen, onClose, onOpen } = useModal();
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string | null>(null);

  const handleOpenFundsModal = (companyId: number) => {
    onOpen();
    setSelectedCompanyId(companyId);
  };

  const handleCloseFundsModal = () => {
    setSelectedCompanyId(null);
    setSelectedCompanyName(null);
    onClose();
  };

  return (
    <>
      <CompanyIacChooseFundModal
        companyName={selectedCompanyName}
        companyId={selectedCompanyId}
        isOpen={isOpen}
        onClose={handleCloseFundsModal}
      />

      <DashboardLayout>
        <div className='contentful-dashboard-page'>
          <TitleWithDescription className='mb-6' title={ADMIN_COMPANIES_PAGE_TITLE} />

          <CompaniesTable onClickCompanyIac={handleOpenFundsModal} />
        </div>
      </DashboardLayout>
    </>
  );
};

export default AdminCompanies;
