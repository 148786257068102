import React, { FC } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  FINISH_REGISTRATION_SUBMIT_BUTTON,
  getFinishRegistrationFields,
  FinishRegistrationFieldNames,
  FINISH_REGISTRATION_BUTTON_TEST_ID,
} from 'constants/finish-registration';
import { FinisRegistrationInput } from 'interfaces';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';
import { Button, FormContent, PasswordStrengthWidget } from 'shared-components';

interface Props {
  onSubmit: SubmitHandler<FinisRegistrationInput>;
}

const FinishRegistrationForm: FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FinisRegistrationInput>({
    reValidateMode: 'onChange',
  });

  const isActionLoading = useAppSelector(selectIsActionLoading);
  const watchPassword = watch(FinishRegistrationFieldNames.PASSWORD);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='grid grid-cols-2 gap-x-7 relative'>
        <FormContent<FinisRegistrationInput>
          fields={getFinishRegistrationFields(watchPassword)}
          register={register}
          errors={errors}
        />
        <PasswordStrengthWidget
          password={watchPassword}
          className='col-start-1 col-end-3 row-start-5 row-end-6 top-0'
        />
      </div>
      <Button className='my-2.5' isLoading={isActionLoading} data-testid={FINISH_REGISTRATION_BUTTON_TEST_ID}>
        {FINISH_REGISTRATION_SUBMIT_BUTTON}
      </Button>
    </form>
  );
};

export default FinishRegistrationForm;
