import React, { FC } from 'react';

import cn from 'classnames';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

type Props = {
  selectedOption: boolean;
  onClickYes: VoidFunction;
  onClickNo: VoidFunction;
  isEditing: boolean;
};

const NomineeOwnershipQuestion: FC<Props> = ({ selectedOption, onClickYes, onClickNo, isEditing }) => {
  const showNoButton = (!isEditing && !selectedOption) || isEditing;
  const showYesButton = (!isEditing && selectedOption) || isEditing;

  return (
    <div className='flex flex-col sm:flex-row gap-4 sm:gap-0 w-full items-center'>
      <div className='text-xl flex-1 pr-9'>
        <p>
          If there are any nominee shareholders with <span className='text-green-600'>over 25%</span> ownership in the
          Company, please detail below if any of the beneficial owners also hold{' '}
          <span className='text-green-600'>over 25%</span> of the Company.
        </p>
      </div>

      <div className='flex-1 w-full sm:w-auto'>
        <div
          className={cn('flex flex-col-reverse lg:flex-row gap-4 lg:gap-0', {
            'cursor-not-allowed': !isEditing,
          })}
        >
          {showNoButton && (
            <Button
              className={cn('w-full lg:w-150 mr-9', { 'pointer-events-none': !isEditing })}
              onClick={onClickNo}
              variant={ButtonVariants.SECONDARY}
              // variant={!selectedOption ? ButtonVariants.SECONDARY : ButtonVariants.SECONDARY_BORDERED}
            >
              No
            </Button>
          )}

          {showYesButton && (
            <Button
              className={cn('w-full lg:w-250', { 'pointer-events-none': !isEditing })}
              onClick={onClickYes}
              variant={selectedOption ? ButtonVariants.PRIMARY : ButtonVariants.SECONDARY}
            >
              Yes
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NomineeOwnershipQuestion;
