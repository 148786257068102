import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { DEFAULT_METRICS_STATE } from 'constants/shared';
import { InvestorsState } from 'interfaces';

import { INVESTORS_SLICE_NAME } from './action';
import {
  getInvestorsReducer,
  getInvestorsMetricsReducer,
  getInvestorsPieChartMetricsReducer,
  getInvestorReducer,
  getInvestorCompaniesInvestmentsReducer,
  getInvestorShareExchangesReducer,
  getInvestorInvoicesReducer,
  getEntityAuditLogsReducer,
  getEntityAuditLogByIdReducer,
  getInvestorsWithDoneShareExchangesReducer,
  getInvestorFundsSharesReducer,
} from './reducers';

export const initialState: InvestorsState = {
  investorsList: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
  investorsMetrics: {
    ...DEFAULT_METRICS_STATE,
    isLoading: false,
  },
  investorsPieMetrics: {
    isLoading: false,
    chartData: null,
  },
  investor: {
    data: null,
    isLoading: false,
  },
  investorShareExchanges: { isLoading: false, list: null, ...META_INITIAL_STATE },
  activityLogs: {
    isLoading: false,
    list: null,
    ...META_INITIAL_STATE,
  },
  investorCompaniesInvestments: {
    isLoading: false,
    list: null,
    ...META_INITIAL_STATE,
  },
  investorInvoices: {
    isLoading: false,
    list: null,
    ...META_INITIAL_STATE,
  },
  activityLog: {
    isLoading: false,
    log: null,
  },
  investorsFundsShares: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
};

const investorsSlice = createSlice({
  name: INVESTORS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getInvestorsReducer(builder);
    getInvestorsMetricsReducer(builder);
    getInvestorReducer(builder);
    getInvestorsPieChartMetricsReducer(builder);
    getInvestorsWithDoneShareExchangesReducer(builder);

    getInvestorCompaniesInvestmentsReducer(builder);
    getInvestorInvoicesReducer(builder);
    getInvestorShareExchangesReducer(builder);
    getInvestorFundsSharesReducer(builder);

    getEntityAuditLogsReducer(builder);
    getEntityAuditLogByIdReducer(builder);
  },
});

export const investorsReducer = investorsSlice.reducer;
