import { isBefore, isWeekend } from 'date-fns';

import { REQUIRED_MESSAGE } from 'constants/global';

export const validateClosingDate = (
  closingDateValue: string,
  isMainValidationIgnored?: boolean,
  isPastDataValidationIgnored?: boolean,
): string | boolean => {
  if (!closingDateValue) {
    return REQUIRED_MESSAGE;
  }

  const closingDate = new Date(closingDateValue);

  if (isMainValidationIgnored) return true;

  if (isWeekend(closingDate)) {
    return 'Closing date can be business days only';
  }

  const yesterday = new Date().setDate(new Date().getDate() - 1);

  if (!isPastDataValidationIgnored && isBefore(closingDate, yesterday)) {
    return 'Closing date cannot be in the past';
  }

  return true;
};
