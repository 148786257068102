import React, { FC } from 'react';

import {
  CORPORATE_DOCUMENTS_AND_FINANCIALS_TABLE_COLUMNS,
  DUE_DILIGENCE_MAIN_DOCUMENTS_TYPES_KEYS,
  DUE_DILIGENCE_DOCUMENT_TYPES_LABELS,
} from 'constants/due-diligence';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { DueDiligenceSelectedDocumentTypeFunction } from 'interfaces';
import { setDueDiligenceDocumentsFilterValue } from 'modules/due-diligence/action';
import { useAppDispatch } from 'modules/store';
import Table from 'shared-components/table';

import CorporateDocumentsAndFinancialsTableRow from './CorporateDocumentsAndFinancialsTableRow';

type Props = {
  isEditing: boolean;
  shouldBeHighlighted: boolean;
  onOpenUploadDocumentModal: DueDiligenceSelectedDocumentTypeFunction;
  getUnfieldValue?: getUnfieldValueFunc;
};

const CorporateDocumentsAndFinancialsTable: FC<Props> = ({ isEditing, onOpenUploadDocumentModal, getUnfieldValue }) => {
  const dispatch = useAppDispatch();

  const handleFilterByDocumentType = (filterValue: string) => {
    dispatch(setDueDiligenceDocumentsFilterValue(filterValue));
  };

  return (
    <Table
      className='table-simplified-tr table-simplified-th'
      columns={CORPORATE_DOCUMENTS_AND_FINANCIALS_TABLE_COLUMNS}
    >
      {DUE_DILIGENCE_MAIN_DOCUMENTS_TYPES_KEYS.map((documentTypeKey) => (
        <CorporateDocumentsAndFinancialsTableRow
          disabledAction={!isEditing}
          handleFilter={handleFilterByDocumentType}
          key={documentTypeKey}
          documentType={DUE_DILIGENCE_DOCUMENT_TYPES_LABELS[documentTypeKey]}
          documentTypeKey={documentTypeKey}
          getUnfieldValue={getUnfieldValue}
          onOpenUploadDocumentModal={onOpenUploadDocumentModal}
        />
      ))}
    </Table>
  );
};

export default CorporateDocumentsAndFinancialsTable;
