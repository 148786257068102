import { useState } from 'react';

import { UseFormReset } from 'react-hook-form';

import {
  DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE,
  DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE,
} from 'constants/due-diligence';
import { SIGNIFICANT_CONTROL_DIRECTORS_FORM_FIELDS } from 'constants/significant-control-company';
import { errorNotify } from 'helpers';
import {
  DueDiligencePersonInput,
  DueDiligencePersonsSubmitFunctionType,
  PersonsDeletedAddress,
  SignificantControlCompanyDirectorFormState,
} from 'interfaces';
import { hasDirectorWithInvalidSubmittedDateRanges, hasDirectorWithOverlappingDates } from 'utils/due-diligence';

const useSignificantControlPersons = ({
  reset,
  dirtyFields,
}: {
  reset: UseFormReset<SignificantControlCompanyDirectorFormState>;
  dirtyFields: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deletedPersons, setDeletedPersons] = useState<number[]>([]);
  const [deletedAddresses, setDeletedAddresses] = useState<PersonsDeletedAddress[]>([]);

  const handleSetIsLoading = (value: boolean) => setIsLoading(value);

  const handleDiscardChanges = () => {
    reset();
  };

  const onSubmit: DueDiligencePersonsSubmitFunctionType =
    ({ onOpen, handleCancel }) =>
    (values) => {
      const persons = values[SIGNIFICANT_CONTROL_DIRECTORS_FORM_FIELDS.DIRECTORS] as DueDiligencePersonInput[];

      const modifiedPersons = persons?.reduce((acc: DueDiligencePersonInput[], director, index) => {
        if (!dirtyFields?.[SIGNIFICANT_CONTROL_DIRECTORS_FORM_FIELDS.DIRECTORS]?.[index]) {
          return acc;
        }

        return [...acc, director];
      }, []);

      if (!modifiedPersons?.length && !deletedPersons?.length) {
        handleCancel();
      }

      const directorWithInvalidDateRange = hasDirectorWithInvalidSubmittedDateRanges(persons);
      const directorWithOverlappingDates = hasDirectorWithOverlappingDates(persons);

      // If there is a director with overlapping dates, then we show an error and return
      if (directorWithOverlappingDates) {
        errorNotify(`${DUE_DILIGENCE_DIRECTORS_OVERLAPPING_RA_DATES_MESSAGE} ${directorWithOverlappingDates.fullName}`);
        return;
      }

      // If there is a director with invalid date range, then we show an error and return
      if (directorWithInvalidDateRange) {
        errorNotify(`${DUE_DILIGENCE_DIRECTORS_INVALID_RA_DATES_MESSAGE} ${directorWithInvalidDateRange.fullName}`);
        return;
      }

      onOpen({ directors: modifiedPersons });
    };

  return {
    onSubmit,
    handleDiscardChanges,
    isLoading,
    handleSetIsLoading,
    deletedPersons,
    setDeletedPersons,
    deletedAddresses,
    setDeletedAddresses,
  };
};

export default useSignificantControlPersons;
