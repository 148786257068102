import React, { useEffect } from 'react';

import { ConstraintVariants } from 'constants/shared';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import { getMyCompanies } from 'modules/companies/action';
import { selectCompaniesData } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout, CompaniesList, AddCompanyModal } from 'page-components';
import { Constraint, Pagination, SearchForm, TitleWithDescription } from 'shared-components';

const MY_COMPANIES_PER_PAGE = 5;

const MyCompanies = () => {
  const dispatch = useAppDispatch();

  const { list, isLoading, ...companiesPaginationData } = useAppSelector(selectCompaniesData);

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();

  useEffect(() => {
    dispatch(getMyCompanies({ page, per_page: MY_COMPANIES_PER_PAGE, query }));
  }, [dispatch, page, query]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription className='mb-6 flex justify-between items-center' title='Companies' />
        <div className='flex justify-between flex-col sm:flex-row gap-x-2'>
          <SearchForm
            className='mb-4 xs:mb-12 items-center flex-col xs:flex-row gap-4 xs:gap-0'
            handleFilter={handleFilter}
            placeholder='Search by Company Name'
          />
          <AddCompanyModal page={page} perPage={MY_COMPANIES_PER_PAGE} setPage={setPage} />
        </div>
        <CompaniesList companies={list} isLoading={isLoading} />

        <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...companiesPaginationData} />
      </Constraint>
    </DashboardLayout>
  );
};

export default MyCompanies;
