import React, { FC, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { AUTH_INVITE_FORM_SUBMIT_TEST_ID, INVITES_PAGE_FIELDS } from 'constants/invites';
import { ButtonVariants } from 'constants/shared/button';
import { FormItem, InviteInput } from 'interfaces';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { useAppSelector } from 'modules/store';
import { Button, FormContent } from 'shared-components';

interface Props {
  invitedCount: number;
  onInvite: (data: InviteInput) => void;
  formFields?: FormItem[];
}

const AuthInviteForm: FC<Props> = ({ invitedCount, onInvite, formFields = INVITES_PAGE_FIELDS }) => {
  const isActionLoading = useAppSelector(selectIsActionLoading);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InviteInput>({ reValidateMode: 'onSubmit' });

  const onSubmit: SubmitHandler<InviteInput> = (data) => {
    onInvite(data);
  };

  useEffect(() => {
    if (invitedCount > 0) reset();
  }, [invitedCount, reset]);

  return (
    <>
      <form className='grid grid-cols-4 gap-x-8 items-end mb-4' onSubmit={handleSubmit(onSubmit)}>
        <FormContent fields={formFields} register={register} errors={errors} className='col-span-full' />
        <Button
          className='col-span-2 sm:col-span-1'
          variant={ButtonVariants.SECONDARY}
          type='submit'
          isLoading={isActionLoading}
          data-testid={AUTH_INVITE_FORM_SUBMIT_TEST_ID}
        >
          Invite <PlusSvg className='ml-2' />
        </Button>
      </form>
    </>
  );
};

export default AuthInviteForm;
