import { ButtonVariants } from 'constants/shared/button';

export const getButtonDisabledVariantByVariant = (variant: ButtonVariants) => {
  switch (variant) {
    case ButtonVariants.PRIMARY:
      return ButtonVariants.DISABLED_PRIMARY;
    case ButtonVariants.SECONDARY:
      return ButtonVariants.DISABLED_SECONDARY;

    case ButtonVariants.LINK:
      return ButtonVariants.DISABLED_LINK;

    default:
      return variant;
  }
};
