import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { ReactComponent as PencilSvg } from 'assets/svg/pencil.svg';
import { EMAIL_FIELD_VALIDATION, EMAIL_PATTERN } from 'constants/global';
import {
  START_SHARE_EXCHANGE_WITH_COMPANY_FORM_FIELDS,
  StartShareExchangeWithCompanyFieldNames,
} from 'constants/share-exchange';
import { ButtonVariants } from 'constants/shared/button';
import { Option, StartShareExchangeWithCompanyFormValues } from 'interfaces';
import { selectInvestorsData } from 'modules/investors/selectors';
import { useAppSelector } from 'modules/store';
import { Button, CreatableSelectFormInput, FormContent, SnippetLayout } from 'shared-components';

interface Props {
  control: Control<StartShareExchangeWithCompanyFormValues>;
  register: UseFormRegister<StartShareExchangeWithCompanyFormValues>;
  setValue: UseFormSetValue<StartShareExchangeWithCompanyFormValues>;
  errors: FieldErrors<StartShareExchangeWithCompanyFormValues>;
  watch: UseFormWatch<StartShareExchangeWithCompanyFormValues>;
}

const ShareExchangeInvestorFields = ({ control, register, setValue, errors, watch }: Props) => {
  const [isFormEditable, setIsFormEditable] = useState(false);

  const { list: investors } = useAppSelector(selectInvestorsData);

  const watchEmail = watch(StartShareExchangeWithCompanyFieldNames.EMAIL);

  const onCreateOption = (inputValue: string) => {
    return EMAIL_PATTERN.test(inputValue);
  };

  const setAsUnavailable = () => setIsFormEditable(false);

  const emailOptions: Option[] = useMemo(() => {
    return (
      investors?.map(({ email }) => ({
        label: email,
        value: email,
      })) || []
    );
  }, [investors]);

  useEffect(() => {
    if (!watchEmail) return;
    const emailOption = watchEmail as Option;
    const selectedInvestor = investors?.find(({ email }) => email === emailOption.value);

    setValue(`${StartShareExchangeWithCompanyFieldNames.FIRST_NAME}`, selectedInvestor?.firstName ?? '');
    setValue(`${StartShareExchangeWithCompanyFieldNames.LAST_NAME}`, selectedInvestor?.lastName ?? '');
    setValue(`${StartShareExchangeWithCompanyFieldNames.INVESTOR_ID}`, selectedInvestor?.id ?? 0);

    setIsFormEditable(!!(selectedInvestor?.firstName && selectedInvestor?.lastName));
  }, [watchEmail]);

  return (
    <SnippetLayout title='Investor' childrenClassName='grid grid-cols-4 md:grid-cols-[1fr_1fr_1fr] gap-4'>
      <CreatableSelectFormInput
        required
        className='col-span-full md:col-span-1'
        label='Email'
        placeholder='Email'
        control={control as unknown as Control}
        validation={EMAIL_FIELD_VALIDATION}
        name={StartShareExchangeWithCompanyFieldNames.EMAIL}
        options={emailOptions}
        onCreateOption={onCreateOption}
      />
      <FormContent
        fields={START_SHARE_EXCHANGE_WITH_COMPANY_FORM_FIELDS(emailOptions, setValue, investors, isFormEditable)}
        control={control}
        register={register}
        errors={errors}
      />
      <Button
        type='button'
        variant={ButtonVariants.SECONDARY}
        className='col-span-full sm:col-span-1 w-full sm:w-fit mb-6'
        onClick={setAsUnavailable}
      >
        Edit <PencilSvg className='ml-2' />
      </Button>
    </SnippetLayout>
  );
};

export default ShareExchangeInvestorFields;
