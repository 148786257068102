import { useCallback, useEffect, useMemo } from 'react';

import { MetaState } from 'interfaces';
import { getDeals } from 'modules/deals/action';
import { selectDealsPaginationData, selectIsLoadingDeals } from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { checkIsAdmin } from 'utils';

import useFilterParameters from '../use-filter-parameters/useFilterParameters';
import {
  usePaginationWithSearch,
  UsePaginationWithSearchReturnValues,
} from '../use-pagination-with-search/usePaginationWithSearch';
import useTableSorting, { UseTableSortingValuesType } from '../use-table-sorting/useTableSorting';

type UseLoadDealsReturnValuesType = {
  handleLoadDeals: VoidFunction;
  loading: boolean;
  paginationState: {
    updatePage: (page: number) => void;
    currentPage: number;
  } & MetaState;
} & Pick<UsePaginationWithSearchReturnValues, 'handleFilter'> &
  Pick<UseTableSortingValuesType, 'updateSorting'>;

const useLoadDeals = ({
  filterType,
  filterValue,
  onlyWithFilters,
  perPage = 7,
}: {
  filterType?: string;
  filterValue?: string | number | null;
  onlyWithFilters?: boolean;
  perPage?: number;
}): UseLoadDealsReturnValuesType => {
  const dispatch = useAppDispatch();

  const { page, query, setPage, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const dealsPaginationData = useAppSelector(selectDealsPaginationData);
  const loading = useAppSelector(selectIsLoadingDeals);

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const handleLoadDeals = useCallback(() => {
    const currentFilter = filterType && filterValue ? `filter[${filterType}]=${filterValue}` : filter;

    if (filterValue === null && onlyWithFilters) return;

    dispatch(
      getDeals({
        isAdmin,
        page,
        per_page: perPage,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter: currentFilter,
      }),
    );
  }, [
    dispatch,
    filter,
    filterType,
    filterValue,
    isAdmin,
    onlyWithFilters,
    page,
    perPage,
    query,
    sorting.column,
    sorting.order,
  ]);

  useEffect(handleLoadDeals, [handleLoadDeals]);

  return {
    handleLoadDeals,
    loading,
    updateSorting,
    handleFilter,
    paginationState: { updatePage: setPage, currentPage: page, ...dealsPaginationData },
  };
};

export default useLoadDeals;
