import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { selectDealDetails } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { Button, ModalWindow, SnippetLayout } from 'shared-components';

import ShareExchangeDetailsTable from './ShareExchangeDetailsTable';
import ShareExchangeDetailsTotals from './ShareExchangeDetailsTotals';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  isLoading: boolean;
};

const ShareExchangeDetailsModal: FC<Props> = ({ isOpen, onClose, isLoading }) => {
  const { companyName, dealExchanges, closingDate } = useAppSelector(selectDealDetails) || {};

  const listOfClassesOfShares =
    dealExchanges?.reduce(
      (acc, { classOfShares }, index) => (index === 0 ? classOfShares : `${acc}, ${classOfShares}`),
      '',
    ) || '';

  return (
    <ModalWindow
      title={`Share Exchange Details: ${companyName}`}
      description={`Class(es) of shares that are supported for this share exchange: ${listOfClassesOfShares}`}
      isOpen={isOpen}
      onClose={onClose}
      className='min-w-[80%]'
      wrapperClassName='px-8 py-8 lg:px-8 lg:py-8'
      overlayClassName='bg-black opacity-80'
    >
      <SnippetLayout className='pt-2'>
        <ShareExchangeDetailsTable
          isLoading={isLoading}
          shareExchangeDetails={dealExchanges}
          closingDate={closingDate}
        />
      </SnippetLayout>
      <div className='w-full flex-col flex items-end mt-6'>
        <ShareExchangeDetailsTotals isLoading={isLoading} shareExchangeDetails={dealExchanges} />

        <Button className='w-150 mt-6' type='button' variant={ButtonVariants.SECONDARY} onClick={onClose}>
          Close
        </Button>
      </div>
    </ModalWindow>
  );
};

export default ShareExchangeDetailsModal;
