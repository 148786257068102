import React, { FC } from 'react';

import cn from 'classnames';

import { LEGAL_MATTERS_RADIO_CHOICES } from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import { Button, Checkbox, RadioInputWithLabel } from 'shared-components';

type Props = {
  selectedLegalMattersState: boolean | undefined;
  onCheckDocumentsAttached: VoidFunction;
  onChangeLegalMattersState: (state: boolean) => void;
  onClickUploadDocument: VoidFunction;
  isDocumentsAttached: boolean;
  isConfirmationValidationHighlighted: boolean;
  isFounder: boolean;
  isDocumentsAttachedActualValue?: boolean;
  isEditing: boolean;
};

const LegalMattersActions: FC<Props> = ({
  onChangeLegalMattersState,
  selectedLegalMattersState,
  isDocumentsAttached,
  onCheckDocumentsAttached,
  onClickUploadDocument,
  isConfirmationValidationHighlighted,
  isDocumentsAttachedActualValue,
  isFounder,
  isEditing,
}) => {
  const isDisabledForFounder = isFounder && selectedLegalMattersState !== undefined;

  return (
    <div className='mt-9 pl-0.5 w-full flex items-center justify-between flex-wrap sm:flex-nowrap gap-y-4 sm:gap-y-0 max-w-[600px]'>
      {LEGAL_MATTERS_RADIO_CHOICES.map((choice) => (
        <RadioInputWithLabel
          wrapperClassName='basis-1/2 sm:basis-auto flex items-center justify-center sm:justify-start'
          className={cn({ 'radio-input-highlighted': isConfirmationValidationHighlighted })}
          disabled={!isEditing || isDisabledForFounder}
          onChange={() => onChangeLegalMattersState(choice.value)}
          key={choice.name}
          labelClassName='pl-2'
          name={choice.name}
          label={choice.label}
          checked={choice.value === selectedLegalMattersState}
        />
      ))}

      <Checkbox
        className='w-full sm:w-auto flex-wrap sm:flex-nowrap order-last sm:order-none'
        disabled={!isEditing || (isDocumentsAttachedActualValue && isFounder)}
        checked={isDocumentsAttached}
        inputClassName='top-0.5'
        label='Documents attached'
        onChange={onCheckDocumentsAttached}
      />

      <Button
        disabled={!isEditing}
        onClick={onClickUploadDocument}
        className='w-full sm:w-auto flex-wrap sm:flex-nowrap'
        variant={ButtonVariants.LINK}
      >
        Upload Document
      </Button>
    </div>
  );
};

export default LegalMattersActions;
