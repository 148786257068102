import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { FeesState } from 'interfaces';

import { FEES_SLICE_NAME } from './action';
import { getAllFeesReducer } from './reducers';

export const initialState: FeesState = {
  fees: [],
  isLoading: false,
  ...META_INITIAL_STATE,
};

const feesSlice = createSlice({
  name: FEES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getAllFeesReducer(builder);
  },
});

export const feesReducer = feesSlice.reducer;
