import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { DEFAULT_METRICS_STATE } from 'constants/shared';
import { FoundersState } from 'interfaces';

import { FOUNDERS_SLICE_NAME } from './action';
import {
  getFoundersReducer,
  getFoundersMetricsReducer,
  getFoundersCompanyReducer,
  updateFoundersCompaniesReducer,
  getFounderReducer,
} from './reducers';

export const initialState: FoundersState = {
  foundersList: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
  foundersMetrics: {
    ...DEFAULT_METRICS_STATE,
    isLoading: false,
  },
  foundersCompanies: {
    list: null,
    isLoading: false,
  },
  founder: {
    data: null,
    isLoading: false,
  },
};

const foundersSlice = createSlice({
  name: FOUNDERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getFounderReducer(builder);
    getFoundersReducer(builder);
    getFoundersMetricsReducer(builder);
    getFoundersCompanyReducer(builder);
    updateFoundersCompaniesReducer(builder);
  },
});

export const foundersReducer = foundersSlice.reducer;
