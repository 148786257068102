import { CreateFundPayload, FundFormInput } from '../interfaces/funds.interfaces';

export const getPreparedFundFormValuesForPayload = ({
  typesOfShares,
  name,
  sharePrice,
  sharesIssued,
  assetsUnderManagement,
  companyName,
  companyNumber,
  id,
  ...companyData
}: FundFormInput): CreateFundPayload => {
  return {
    companyData: {
      ...companyData,
      companyName: companyName?.label || '',
      companyNumber: companyNumber?.label || '',
    },
    typesOfShares: typesOfShares?.value ? [typesOfShares?.value] : [],
    name,
    sharePrice,
    sharesIssued: sharesIssued as number,
    assetsUnderManagement,
    id,
  };
};
