import React, { useEffect } from 'react';

import { SHARE_EXCHANGE_REQUESTS_PAGE_DESCRIPTION, SHARE_EXCHANGE_REQUESTS_PAGE_TITLE } from 'constants/share-exchange';
import { ConstraintVariants } from 'constants/shared';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { selectIsActionLoading } from 'modules/current-user/selectors';
import { getShareExchangeRequests } from 'modules/share-exchange/action';
import { selectShareExchangeRequests } from 'modules/share-exchange/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout, MyInvitesModalForm, ShareExchangeRequestsTable } from 'page-components';
import { Constraint, Pagination, SearchForm, TitleWithDescription } from 'shared-components';

import { ReactComponent as UserIcon } from '../assets/svg/user.svg';
import { ButtonVariants } from '../constants/shared/button';

const SHARE_EXCHANGE_REQUESTS_PER_PAGE = 7;

const ShareExchangeRequests = () => {
  const dispatch = useAppDispatch();

  const isInvitesActionLoading = useAppSelector(selectIsActionLoading);
  const { list, isLoading, ...paginationData } = useAppSelector(selectShareExchangeRequests);
  const { sorting, updateSorting } = useTableSorting();

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();

  const { filter } = useFilterParameters();

  useEffect(() => {
    dispatch(
      getShareExchangeRequests({
        page,
        per_page: SHARE_EXCHANGE_REQUESTS_PER_PAGE,
        sort: sorting.column,
        order: sorting.order,
        query,
        filter,
      }),
    );
  }, [dispatch, page, query, sorting.column, sorting.order, filter]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription
          descriptionClassName='pt-2'
          title={SHARE_EXCHANGE_REQUESTS_PAGE_TITLE}
          description={SHARE_EXCHANGE_REQUESTS_PAGE_DESCRIPTION}
        />
        <div className='flex items-end flex-col sm:flex-row gap-4 sm:gap-0 mb-12'>
          <SearchForm
            className='flex-col xs:flex-row gap-4 sm:gap-0 sm:basis-7/12 w-full sm:w-auto '
            handleFilter={handleFilter}
            placeholder='Search by Name'
          />

          <MyInvitesModalForm
            loading={isInvitesActionLoading}
            buttonContent={
              <>
                Invite user <UserIcon className='ml-2' />
              </>
            }
            buttonVariant={ButtonVariants.SECONDARY}
            buttonClassName='w-full md:w-fit xs:ml-6'
          />
        </div>
        <ShareExchangeRequestsTable data={list} loading={isLoading} updateSorting={updateSorting} />
        <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...paginationData} />
      </Constraint>
    </DashboardLayout>
  );
};

export default ShareExchangeRequests;
