import React from 'react';

interface Props {
  count?: number;
  withDetails?: boolean;
}

const StepperSkeleton = ({ count = 3, withDetails }: Props) => {
  return (
    <div className='flex'>
      {[...Array(count)].map((_, index) => (
        <div style={{ width: `${100 / count}%` }} key={index} className='mr-6 last:mr-0'>
          <div className='flex items-center'>
            <div className='skeleton-loader min-w-[40px] w-10 h-10 rounded-full' />
            <div className='skeleton-loader w-full h-0.5 ml-4' />
          </div>
          {withDetails && (
            <div className='mt-4 space-y-4'>
              <div className='skeleton-loader w-3/12 h-3' />
              <div className='skeleton-loader w-8/12 h-8' />
              <div className='skeleton-loader w-4/12 h-6' />
              <div className='skeleton-loader w-3/12 h-6 rounded-full' />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepperSkeleton;
