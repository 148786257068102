import React, { FC, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  BENEFICIAL_OWNERS_TABLE_COLUMNS,
  DUE_DILIGENCE_BENEFICIAL_OWNERS_NO_PERSONS_ADDED_DEFAULT_TEXT,
  DUE_DILIGENCE_NOMINEE_SHAREHOLDERS_ADD_PERSON_TEXT,
  DueDiligencePersonsFormNamesEnum,
  DueDiligenceSections,
} from 'constants/due-diligence';
import useModal from 'hooks/use-modal/useModal';
import useDueDiligencePersons from 'hooks/useDueDiligencePersons';
import { DueDiligencePersonsProps } from 'interfaces';
import { dueDiligenceDeletePersons, getDueDiligenceData } from 'modules/due-diligence/action';
import {
  selectBeneficialOwners,
  selectDueDiligenceEditingSections,
  selectDueDiligenceId,
  selectHighlightUnsavedChanges,
} from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import { PersonsBlock } from '../../index';
import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
import DueDiligenceBlockTitle from '../due-diligence-block/DueDiligenceBlockTitle';
import NomineeOwnershipQuestion from './NomineeOwnershipQuestion';

const SHAREHOLDERS_BLOCK_NAME = 'beneficialOwners';

interface Props extends DueDiligencePersonsProps {
  isEditing: boolean;
}

const ShareholdersBlock: FC<Props> = ({ isEditing, ...personsProps }) => {
  const dispatch = useAppDispatch();

  const {
    reset,
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = useFormContext();

  const [shareholdersExist, setShareholdersExist] = useState(false);

  const {
    onSubmit,
    handleCancel,
    handleSetIsLoading,
    isLoading,
    deletedPersons,
    setDeletedAddresses,
    deletedAddresses,
    setDeletedPersons,
    previousState,
  } = useDueDiligencePersons({
    sectionType: DueDiligenceSections.BENEFICIAL_OWNERS,
    reset: reset,
    dirtyFields: dirtyFields,
    name: SHAREHOLDERS_BLOCK_NAME,
  });

  const { id: companyId } = useParams();
  const modalProps = useModal();

  const persons = watch(SHAREHOLDERS_BLOCK_NAME);

  const id = useAppSelector(selectDueDiligenceId);
  const beneficialOwners = useAppSelector(selectBeneficialOwners);
  const highlightedUnsavedChanges = useAppSelector(selectHighlightUnsavedChanges);
  const editingSections = useAppSelector(selectDueDiligenceEditingSections);

  const hasUnsavedChangesBlock =
    editingSections.includes(DueDiligenceSections.BENEFICIAL_OWNERS) && highlightedUnsavedChanges;

  const onClickShareholdersExist = () => {
    setShareholdersExist(true);
  };

  const onClickShareholdersDoNotExist = () => {
    setShareholdersExist(false);
  };

  const deleteBeneficialOwners = async () => {
    if (!id || !companyId || !beneficialOwners?.length) {
      handleCancel();
      return;
    }

    handleSetIsLoading(true);

    try {
      await dispatch(
        dueDiligenceDeletePersons({
          id,
          name: 'beneficialOwners',
          significantIds: beneficialOwners.map(({ id }) => id),
        }),
      ).unwrap();
      await dispatch(getDueDiligenceData({ companyId })).unwrap();

      setShareholdersExist(false);
      handleCancel();
    } catch (err) {
      console.error('Error deleting beneficial owners', err);
    }

    handleSetIsLoading(false);
  };

  const onClickSave = () => {
    if (!shareholdersExist) {
      // We dont need to wait for this function to finish
      deleteBeneficialOwners();
      reset({});
      return;
    }

    if (shareholdersExist && !persons?.length && !beneficialOwners?.length) {
      setShareholdersExist(false);
      handleCancel();
      reset({});
      return;
    }

    handleSubmit(onSubmit({ onOpen: modalProps.onOpen, handleCancel }))();
  };

  const onDiscardChanges = () => {
    setShareholdersExist(previousState.shareholdersExist);

    if (beneficialOwners?.length) {
      reset();
    }

    handleCancel();
  };

  const onSuccessUpdatePersons = () => {
    if (!persons?.length) {
      setShareholdersExist(false);
    }
  };

  useEffect(() => {
    if (beneficialOwners?.length) {
      setShareholdersExist(true);
      return;
    }

    setShareholdersExist(false);
  }, [beneficialOwners]);

  return (
    <DueDiligenceBlock
      blockClassName='min-h-250'
      sectionType={DueDiligenceSections.BENEFICIAL_OWNERS}
      onClickDiscardChanges={onDiscardChanges}
      onClickSave={shareholdersExist ? onClickSave : undefined}
      isLoading={isLoading}
    >
      <>
        <NomineeOwnershipQuestion
          isEditing={isEditing}
          onClickNo={onClickShareholdersDoNotExist}
          onClickYes={onClickShareholdersExist}
          selectedOption={shareholdersExist}
        />
        {shareholdersExist && (
          <DueDiligenceBlockTitle
            className='mt-4'
            title='Nominee Shareholders or/and Beneficial Owners'
            blockNumber='1.3'
            highlightedUnsavedChanges={hasUnsavedChangesBlock}
          />
        )}
        {shareholdersExist && (
          <PersonsBlock
            {...personsProps}
            {...modalProps}
            deletedPersons={deletedPersons}
            setDeletedPersons={setDeletedPersons}
            deletedAddresses={deletedAddresses}
            setDeletedAddresses={setDeletedAddresses}
            personsList={beneficialOwners}
            handleCancel={handleCancel}
            handleSetIsLoading={handleSetIsLoading}
            addPersonsText={DUE_DILIGENCE_NOMINEE_SHAREHOLDERS_ADD_PERSON_TEXT}
            noPersonsAddedText={DUE_DILIGENCE_BENEFICIAL_OWNERS_NO_PERSONS_ADDED_DEFAULT_TEXT}
            columns={BENEFICIAL_OWNERS_TABLE_COLUMNS}
            hideCheckbox
            name={DueDiligencePersonsFormNamesEnum.BENEFICIAL_OWNERS}
            onSuccessUpdatePersons={onSuccessUpdatePersons}
            addAddressText='+ Address'
            isEditing={isEditing}
            successMessage='The nominee shareholders were successfully saved'
          />
        )}
      </>
    </DueDiligenceBlock>
  );
};

export default ShareholdersBlock;
