import React, { FC } from 'react';

import { DUE_DILIGENCE_NO_PERSONS_ADDED_DEFAULT_TEXT } from 'constants/due-diligence';
import { TableData, TableRow } from 'shared-components/table';

interface Props {
  className?: string;
  text?: string;
}

const NoPersonsAdded: FC<Props> = ({ className, text }) => {
  return <div className='pb-5'>{text || DUE_DILIGENCE_NO_PERSONS_ADDED_DEFAULT_TEXT}</div>;
};

export default NoPersonsAdded;
