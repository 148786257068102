import { endOfDay, isBefore } from 'date-fns';
import { UseFormTrigger } from 'react-hook-form';

import { errorNotify } from 'helpers';
import { CreateFeeFormValues, FormItem, TableColumns } from 'interfaces';
import { DatePickerInput, Input } from 'shared-components';

import { NUMBER_DOUBLE_PATTERN, NUMBERS_PATTERN, REQUIRED_MESSAGE } from './global';
import { FieldTypes } from './shared';

export enum BusinessSettingsFeeFieldNames {
  FEE_RATE = 'feeRate',
  MINIMUM_FEE = 'feeMin',
  ACTIVE_FROM = 'activeFrom',
}

export const BUSINESS_SETTINGS_FEE_FORM_DESCRIPTION = 'Manage the fee charged to planD users';

export const BUSINESS_SETTINGS_FEE_TABLE_COLUMNS: TableColumns[] = [
  { id: 'feeAmount', title: 'Fee Amount', className: 'pl-4' },
  { id: 'minimumFee', title: 'Minimum Fee' },
  { id: 'changedOn', title: 'Active From' },
  { id: 'status', title: 'Status' },
];

export const BUSINESS_SETTINGS_FEE_FORM_FIELDS = (
  trigger: UseFormTrigger<CreateFeeFormValues>,
  isDisabled: boolean,
): FormItem[] => [
  {
    name: BusinessSettingsFeeFieldNames.FEE_RATE,
    label: 'Fee Amount(%)',
    placeholder: '%',
    type: FieldTypes.TEXT,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: NUMBER_DOUBLE_PATTERN,
        message: 'Incorrect Fee rate',
      },
    },
    component: Input,
    className: 'w-full xs:w-200',
  },
  {
    name: BusinessSettingsFeeFieldNames.MINIMUM_FEE,
    label: 'Minimum Fee(£)',
    placeholder: '£',
    type: FieldTypes.TEXT,
    validation: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: NUMBERS_PATTERN,
        message: 'Incorrect minimum fee',
      },
    },
    component: Input,
    className: 'w-full xs:w-200',
  },
  ...(!isDisabled
    ? [
        {
          name: BusinessSettingsFeeFieldNames.ACTIVE_FROM,
          label: 'Active From',
          type: FieldTypes.DATE,
          component: DatePickerInput,
          validation: {
            required: REQUIRED_MESSAGE,
            validate: (inputDate: string) => {
              const isDateBefore = isBefore(endOfDay(new Date(inputDate)), endOfDay(new Date()));

              if (isDateBefore) {
                errorNotify("Active from shouldn't be less than current day");
                return 'Invalid date';
              }
              return true;
            },
          },
          className: 'w-200',
          min: new Date(),
        },
      ]
    : []),
];
