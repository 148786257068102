import React from 'react';

import { SIGNATURE_SETTINGS_DESCRIPTION } from 'constants/signature-settings';
import { TitleWithDescription } from 'shared-components';

import SignaturePad from './SignaturePad';

const SignatureSettings = () => {
  return (
    <div>
      <TitleWithDescription
        className='ml-2'
        descriptionClassName='pt-2'
        title='Signature'
        description={SIGNATURE_SETTINGS_DESCRIPTION}
      />
      <SignaturePad />
    </div>
  );
};

export default SignatureSettings;
