import React, { FC, ReactNode } from 'react';

import { ReactComponent as WarningIcon } from 'assets/svg/warning-outlined.svg';
import SnippetNoInfoLayout from 'shared-components/SnippetNoInfoLayout';
import Typography from 'shared-components/Typography';
import { getShareExchangeSnippetNotifyTitle } from 'utils';

import ShareExchangeSkeleton from '../ShareExchangeSkeleton';

interface Props {
  children: ReactNode;
  dealsLength?: number;
  companiesLength?: number;
  skeletonItems: number;
  isLoading?: boolean;
}

const FounderShareExchangesSnippetContentWrapper: FC<Props> = ({
  children,
  dealsLength,
  companiesLength,
  isLoading,
  skeletonItems,
}) => {
  if (isLoading) return <ShareExchangeSkeleton itemsCount={skeletonItems} />;

  if (!dealsLength || !companiesLength)
    return (
      <SnippetNoInfoLayout icon={<WarningIcon />}>
        <Typography className='flex text-grey-800'>{getShareExchangeSnippetNotifyTitle(!!companiesLength)}</Typography>
      </SnippetNoInfoLayout>
    );

  return <>{children}</>;
};

export default FounderShareExchangesSnippetContentWrapper;
