import { TableColumns } from 'interfaces';

export const AUDIT_LOGS_PAGE_TITLE = 'Audit Log';

export const AUDIT_LOGS_PAGE_DESCRIPTION = 'View the audit log of the system.';

export enum AuditLogsOperations {
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  EMAIL_SENT = 'email_sent',
}

export const AUDIT_LOG_TABLE_COLUMNS: TableColumns[] = [
  { id: 'id', title: 'Id', width: 'w-1/12' },
  { id: 'subject_id', title: 'Entity Id', width: 'w-1/12', sortable: true },
  { id: 'operation', title: 'Operation', width: 'w-1/6' },
  { id: 'companyName', title: 'Company Name', width: 'w-1/6' },
  { id: 'entity', title: 'Entity', width: 'w-1/6' },
  { id: 'author', title: 'Author', width: 'w-1/6' },
  { id: 'authorType', title: 'Author Type', width: 'w-1/6' },
  { id: 'created', title: 'Created', width: 'w-1/6' },
  { id: 'button', title: '', width: 'w-1/6' },
];

export const AUDIT_LOGS_OPERATION_LABELS: { [key in AuditLogsOperations]: string } = {
  [AuditLogsOperations.LOGGED_IN]: 'Logged In',
  [AuditLogsOperations.LOGGED_OUT]: 'Logged Out',
  [AuditLogsOperations.CREATED]: 'Created',
  [AuditLogsOperations.UPDATED]: 'Updated',
  [AuditLogsOperations.DELETED]: 'Deleted',
  [AuditLogsOperations.EMAIL_SENT]: 'Email Sent',
};
