import React, { FC } from 'react';

import {
  CORPORATE_DOCUMENTS_AND_FINANCIALS_BLOCK_DESCRIPTION,
  CORPORATE_DOCUMENTS_AND_FINANCIALS_BLOCK_TITLE,
  DueDiligenceSections,
} from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import useDueDiligenceEdit from 'hooks/use-due-diligence-edit/useDueDiligenceEdit';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { DueDiligenceSelectedDocumentTypeFunction } from 'interfaces';
import { Button } from 'shared-components';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
import CorporateAndFinancialsInformationTooltip from './CorporateAndFinancialsInformationTooltip';
import CorporateDocumentsAndFinancialsTable from './CorporateDocumentsAndFinancialsTable';

type Props = {
  onOpenUploadDocumentModal: DueDiligenceSelectedDocumentTypeFunction;
  className?: string;
  shouldBeHighlighted: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
  isEditing: boolean;
};

const CorporateDocumentsAndFinancialsBlock: FC<Props> = ({
  onOpenUploadDocumentModal,
  className,
  shouldBeHighlighted,
  getUnfieldValue,
  isEditing,
}) => {
  const { handleEdit, handleCancel } = useDueDiligenceEdit(DueDiligenceSections.DOCUMENTS);

  const onClickEdit = () => {
    handleEdit();
  };

  return (
    <DueDiligenceBlock
      blockNumber='2'
      blockClassName={className}
      sectionType={DueDiligenceSections.DOCUMENTS}
      onClickEdit={onClickEdit}
      onClickDiscardChanges={handleCancel}
      title={CORPORATE_DOCUMENTS_AND_FINANCIALS_BLOCK_TITLE}
      description={CORPORATE_DOCUMENTS_AND_FINANCIALS_BLOCK_DESCRIPTION}
      titleInformationTooltip={<CorporateAndFinancialsInformationTooltip />}
    >
      <CorporateDocumentsAndFinancialsTable
        isEditing={isEditing}
        shouldBeHighlighted={shouldBeHighlighted}
        onOpenUploadDocumentModal={onOpenUploadDocumentModal}
        getUnfieldValue={getUnfieldValue}
      />

      <div>
        <Button
          onClick={() => onOpenUploadDocumentModal()}
          className='px-6 py-6 w-auto inline-block'
          variant={ButtonVariants.LINK}
          disabled={!isEditing}
        >
          Add New Document
        </Button>
      </div>
    </DueDiligenceBlock>
  );
};

export default CorporateDocumentsAndFinancialsBlock;
