import React, { FormEventHandler, PropsWithChildren, ReactElement, ReactNode } from 'react';

import cn from 'classnames';
import { Control, FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

import {
  DEAL_PARAMETERS_NOMINEE_SHAREHOLDERS_FORM_FIELDS,
  DEAL_PARAMETERS_NOTIFICATIONS_FIELDS,
  DEAL_PARAMETERS_SHARES_FORM_FIELDS,
} from 'constants/deal-parameters';
import { CompanyIacSharePrice, FormItem, NomineeShareholder, StringOrNull } from 'interfaces';
import { FormContent, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

import DealParametersClosingDatesFields from './DealParametersClosingDatesFields';
import DealParametersItem from './DealParametersItem';
import DealParametersSharesFormRow from './DealParametersSharesFormRow';

type Props<T extends FieldValues> = {
  legalEntity?: string;
  companyNumber?: string;
  plainlyDealId?: StringOrNull;
  sharesFields?: CompanyIacSharePrice[] | null;
  nomineeFields?: NomineeShareholder[] | null;
  register: UseFormRegister<T>;
  control: Control<T>;
  errors: FieldErrors<T>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  closingDatesAvailable?: boolean;
  formClassName?: string;
  disabledParametersInputs?: string[];
  commercialParametersFields: FormItem[];
  hideSettingsSection?: boolean;
  isEditable?: boolean;
  isSharesEditable?: boolean;
  sharesNode?: ReactNode;
  nomineeNode?: ReactNode;
  nomineeRemoveRow?: (index: number) => () => void;
};

const DealParametersForm = <T extends FieldValues>({
  onSubmit,
  legalEntity,
  plainlyDealId,
  companyNumber,
  register,
  control,
  errors,
  sharesFields,
  formClassName,
  commercialParametersFields,
  closingDatesAvailable = false,
  hideSettingsSection = false,
  isEditable = true,
  isSharesEditable = true,
  sharesNode,
  nomineeFields,
  nomineeNode,
  nomineeRemoveRow,
}: PropsWithChildren<Props<T>>): ReactElement => {
  return (
    <form onSubmit={onSubmit} className={cn('space-y-6', formClassName)}>
      <SnippetLayout title='Deal Parameters' className='overflow-hidden' childrenClassName='overflow-auto'>
        <DealParametersItem name='Legal entity' value={legalEntity} />
        <DealParametersItem name='Company number' value={companyNumber} />
        {plainlyDealId && <DealParametersItem name='Deal Id' value={plainlyDealId} />}
      </SnippetLayout>

      <SnippetLayout title='Commercial Parameters'>
        <FormContent fields={commercialParametersFields} register={register} control={control} errors={errors} />

        {closingDatesAvailable && <DealParametersClosingDatesFields<T> control={control} isEditable={isEditable} />}
      </SnippetLayout>

      <SnippetLayout title={`${legalEntity}: Shares` || ''}>
        {sharesFields?.length
          ? sharesFields.map((shareField, shareIndex) => (
              <DealParametersSharesFormRow key={shareIndex} rowNumber={shareIndex + 1} className='mb-0'>
                <FormContent
                  register={register}
                  control={control}
                  errors={errors}
                  fields={DEAL_PARAMETERS_SHARES_FORM_FIELDS(shareIndex)}
                  isDisabled={!isEditable || !isSharesEditable}
                />
              </DealParametersSharesFormRow>
            ))
          : null}
        {sharesNode}
      </SnippetLayout>
      <SnippetLayout title='Nominee Shareholders'>
        {nomineeFields?.length ? (
          nomineeFields.map((_, nomineeIndex) => (
            <DealParametersSharesFormRow
              key={nomineeIndex}
              rowNumber={nomineeIndex + 1}
              className='mb-0 items-end grid grid-cols-1 sm:grid-cols-2'
              onDeleteRow={nomineeRemoveRow}
            >
              <FormContent
                register={register}
                control={control}
                errors={errors}
                fields={DEAL_PARAMETERS_NOMINEE_SHAREHOLDERS_FORM_FIELDS(nomineeIndex)}
                isDisabled={!isEditable || !isSharesEditable}
              />
            </DealParametersSharesFormRow>
          ))
        ) : (
          <Typography className='pl-5 mt-4 text-grey-800'>There are no nominees shareholders yet</Typography>
        )}
        {nomineeNode}
      </SnippetLayout>
      {!hideSettingsSection && (
        <SnippetLayout title='Settings'>
          <FormContent
            fields={DEAL_PARAMETERS_NOTIFICATIONS_FIELDS({
              label: (
                <Typography className='text-grey-800' tag='label'>
                  Notification Settings
                  <Typography>
                    Switch Between <b>Manual</b> and <b>Automatic</b> Email Delivery{' '}
                  </Typography>
                </Typography>
              ),
            })}
            register={register}
            errors={errors}
            control={control}
          />
        </SnippetLayout>
      )}
    </form>
  );
};

export default DealParametersForm;
