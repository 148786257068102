import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  CONTACT_DETAILS_FIELDS,
  EMAIL_FIELDS,
  PERSONAL_DETAILS_FIELDS,
  PROFILE_FORM_TITLES,
  PROFILE_TAB_DESCRIPTION,
  PROFILE_TAB_TITLE,
} from 'constants/settings';
import useModal from 'hooks/use-modal/useModal';
import { ProfileSettingsInput } from 'interfaces';
import { updateCurrentUser } from 'modules/current-user/action';
import { selectUser, selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector, useAppDispatch } from 'modules/store';
import { FormContent, TitleWithFormActions } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

import InputsWrapper from './InputsWrapper';
import SettingsEmailConfirmModal from './SettingsEmailConfirmModal';

const ProfileSettings = () => {
  const { onOpen: onOpenEmailConfirmModal, onClose: onCloseEmailConfirmModal, ...emailConfirmModal } = useModal();

  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<ProfileSettingsInput>({
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phone: user?.phone || '',
      amountInvested: '1', // TODO: Remove this
    },
  });

  const onSubmit: SubmitHandler<ProfileSettingsInput> = (data) => {
    if (dirtyFields.email && !isEmailConfirmed) {
      onOpenEmailConfirmModal();
      return;
    }

    dispatch(updateCurrentUser(data))
      .unwrap()
      .then((response) => reset({ email: response.email }))
      .finally(() => setIsEmailConfirmed(false));
  };

  const onConfirm = () => {
    setIsEmailConfirmed(true);
    onCloseEmailConfirmModal();
  };

  const onCancel = () => reset();

  useEffect(() => {
    if (isEmailConfirmed) {
      handleSubmit(onSubmit)();
    }
  }, [isEmailConfirmed]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleWithFormActions
        title={PROFILE_TAB_TITLE}
        description={getTitleWithReplacedYourWord(PROFILE_TAB_DESCRIPTION, entityName, isTypeEntity)}
        onCancel={onCancel}
      />
      <InputsWrapper title={PROFILE_FORM_TITLES.personalDetails}>
        <div className='w-full grid grid-cols-2 gap-4'>
          <FormContent<ProfileSettingsInput> fields={PERSONAL_DETAILS_FIELDS} register={register} errors={errors} />
        </div>
      </InputsWrapper>

      <InputsWrapper title={PROFILE_FORM_TITLES.emailAddress}>
        <div className='grid grid-cols-1'>
          <FormContent<ProfileSettingsInput> fields={EMAIL_FIELDS} register={register} errors={errors} />
        </div>
      </InputsWrapper>

      <InputsWrapper title={PROFILE_FORM_TITLES.contactDetails}>
        <div className='grid grid-cols-2'>
          <FormContent<ProfileSettingsInput> fields={CONTACT_DETAILS_FIELDS} register={register} errors={errors} />
        </div>
      </InputsWrapper>
      <SettingsEmailConfirmModal onConfirm={onConfirm} onClose={onCloseEmailConfirmModal} {...emailConfirmModal} />
    </form>
  );
};

export default ProfileSettings;
