import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.documentsReducer;

export const selectIsLoadingCarouselDocuments = createSelector(
  selectState,
  (state) => state.isLoadingCarouselDocuments,
);

export const selectCarouselDocuments = createSelector(selectState, (state) => state.carouselDocuments);

export const selectTotalDocuments = createSelector(selectState, (state) => state.totalItems);

export const selectIsLoadingTableDocuments = createSelector(selectState, (state) => state.isLoadingTableDocuments);

export const selectTableDocuments = createSelector(selectState, (state) => state.tableDocuments);

export const selectCompaniesFilterOptions = createSelector(selectState, (state) => state.filterCompaniesOptions);

export const selectCompaniesUploadOptions = createSelector(selectState, (state) => state.uploadCompaniesOptions);

export const selectDocumentsPaginationData = createSelector(selectState, ({ totalPages, totalItems }) => ({
  totalPages,
  total: totalItems,
}));

export const selectDocumentTypesOptions = createSelector(selectState, ({ documentTypes }) => {
  if (!documentTypes) return [];

  return documentTypes?.map(({ documentType }) => ({ label: documentType, value: documentType }));
});
