import React, { FC, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { checkIsAdmin, getCompanyProfileLink } from 'utils';

interface Props {
  isFirstElement: boolean;
  investmentName: string | null;
  companyId: string | number;
  companyName: string;
  linkClassName?: string;
  isDaxia?: boolean;
}

const MyInvestmentsNameTableDataContent: FC<Props> = ({
  isFirstElement,
  investmentName,
  companyName,
  companyId,
  linkClassName,
  isDaxia,
}) => {
  const isAdmin = useMemo(() => checkIsAdmin(), []);

  if (isDaxia) return <>{companyName}</>;

  return (
    <>
      {isFirstElement && !investmentName && (
        <Link className={linkClassName} to={getCompanyProfileLink(companyId, isAdmin)}>
          {companyName}
        </Link>
      )}
      {investmentName && <p>{investmentName}</p>}
    </>
  );
};

export default MyInvestmentsNameTableDataContent;
