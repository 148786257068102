import { omit } from 'lodash';

import { FormItem } from 'interfaces';

import { EXCEPT_FLOAT_NUMBERS_PATTERN, LOCALE_STRING_FORMAT } from '../constants/global';

export const removeLabelFromFormFields = (fields: FormItem[], shouldBeIgnored?: boolean): FormItem[] => {
  if (shouldBeIgnored) return fields;

  return fields.map((field) => {
    return omit(field, ['label']);
  });
};

export const convertNumberInLocaleString = (value: string) => {
  return Number(value.replace(',', '')).toLocaleString(LOCALE_STRING_FORMAT, {
    maximumFractionDigits: 4,
  });
};

export const formatNumberInputValue = (value: string | number, fractionLength?: number) => {
  const formattedByPatternValue = String(value)?.replace(EXCEPT_FLOAT_NUMBERS_PATTERN, '');

  // Separation of the main and fractional parts
  const splitValueByDot = formattedByPatternValue?.split('.');

  const fraction = splitValueByDot[1];

  // If the last character is a dot and a fractional part is allowed, format the main part and manually add a dot to the end
  if (formattedByPatternValue?.charAt(formattedByPatternValue?.length - 1) === '.' && fractionLength) {
    return convertNumberInLocaleString(splitValueByDot[0]) + '.';
  }
  // formatting in LocaleString with the addition of a fractional part
  return (
    convertNumberInLocaleString(splitValueByDot[0]) +
    (fraction?.length ? `.${fraction?.slice(0, fractionLength || 4)}` : '')
  );
};

export const removeComaFromLocaleString = (value: string) => value?.replaceAll(',', '');
