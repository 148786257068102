import React from 'react';

import { ReactComponent as SpinLoaderSvg } from 'assets/svg/spin-loader.svg';

export const SPIN_LOADER_TEST_ID = 'SPIN_LOADER_TEST_ID';

const SpinLoader = () => {
  return (
    <div className='h-screen w-screen flex items-center justify-center' data-testid={SPIN_LOADER_TEST_ID}>
      <SpinLoaderSvg className='animated-spin w-20 h-20' />
    </div>
  );
};

export default SpinLoader;
