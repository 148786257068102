import React, { FC } from 'react';

import { ReviewInvestment } from 'interfaces';

type Props = {
  investment: ReviewInvestment | null;
};

const DeleteInvestmentModalContent: FC<Props> = ({ investment }) => {
  const { amountInvested, companyName, investmentDate, classOfShares, pricePerShare, totalShares } = investment || {};

  return (
    <div className='flex justify-between w-full pt-7'>
      {investment ? (
        <>
          <div>{companyName}</div>
          <div>{classOfShares}</div>
          <div>{totalShares}</div>
          <div>£{pricePerShare}</div>
          <div>{amountInvested}</div>
          <div>{investmentDate}</div>
        </>
      ) : (
        <div>No Selected Investment</div>
      )}
    </div>
  );
};

export default DeleteInvestmentModalContent;
