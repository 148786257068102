import React, { FC } from 'react';

import { SHARE_EXCHANGE_REVIEW_SECTION_TITLES, ShareExchangeStatuses } from 'constants/share-exchange';
import { ButtonVariants } from 'constants/shared/button';
import { Button, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props = {
  onClickViewAnswers: VoidFunction;
  onClickReviewAnswers: VoidFunction;
  onClickAccept: VoidFunction;
  onClickDecline: VoidFunction;
  hasAnswers: boolean;
  status: ShareExchangeStatuses;
};

const AdminShareExchangeReviewAnswersSection: FC<Props> = ({
  onClickReviewAnswers,
  onClickViewAnswers,
  onClickDecline,
  onClickAccept,
  hasAnswers,
  status,
}) => (
  <SnippetLayout className='mt-6' title={SHARE_EXCHANGE_REVIEW_SECTION_TITLES.VIEW_ANSWERS}>
    <div className='flex w-full items-center justify-between text-sm flex-col md:flex-row gap-4 md:gap-0'>
      <div>
        {hasAnswers ? (
          <button onClick={onClickViewAnswers} className='text-sm'>
            View Answers
          </button>
        ) : (
          <Typography>Not Submitted</Typography>
        )}
      </div>

      <div>
        {hasAnswers && status === ShareExchangeStatuses.UNDER_REVIEW && (
          <Button className='w-150' onClick={onClickReviewAnswers}>
            Review
          </Button>
        )}

        {!hasAnswers && status === ShareExchangeStatuses.INITIATED && (
          <div className='flex items-center flex-col xs:flex-row gap-4 md:gap-0'>
            <Typography className='md:mr-8 text-grey-800'>No answers have been provided</Typography>

            <Button onClick={onClickAccept} className='w-100 xs:mr-4'>
              Accept
            </Button>

            <Button onClick={onClickDecline} className='w-100' variant={ButtonVariants.SECONDARY}>
              Decline
            </Button>
          </div>
        )}
      </div>
    </div>
  </SnippetLayout>
);

export default AdminShareExchangeReviewAnswersSection;
