import { useMemo } from 'react';

import { DEALS_TABS_IDS } from 'constants/deals';
import { ROUTES } from 'constants/routes';
import { WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES } from 'constants/share-exchange';
import { selectWelcomeInvestorShareExchangeData } from 'modules/share-exchange/selectors';
import { useAppSelector } from 'modules/store';
import { getDealDetailsLink, getLinkWithTabId } from 'utils';

const useWelcomeUser = () => {
  const { isLoading, list } = useAppSelector(selectWelcomeInvestorShareExchangeData);

  const isCompletedAddInvestments =
    list?.[WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES.ADD_INVESTMENTS]?.status === 'completed';

  const { isCompletedStartExchange, startExchangeLink } = useMemo(() => {
    const requestExchangeData = list?.[WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES.INITIATE_EXCHANGE];

    return {
      isCompletedStartExchange: requestExchangeData?.status === 'completed',
      startExchangeLink: requestExchangeData?.view ? ROUTES.shareExchangeProcess : '',
    };
  }, [list]);

  const { isCompletedContinueExchange, continueExchangeLink } = useMemo(() => {
    const continueExchangeData = list?.[WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES.CONTINUE_EXCHANGE];

    if (!continueExchangeData) {
      return { isCompletedContinueExchange: false };
    }

    const isCompletedContinueExchange = continueExchangeData.status === 'completed';

    if (continueExchangeData.view && continueExchangeData.id) {
      return {
        isCompletedContinueExchange,
        continueExchangeLink: getDealDetailsLink(continueExchangeData.id, false),
      };
    }

    if (continueExchangeData.view && !continueExchangeData.id) {
      return {
        isCompletedContinueExchange,
        continueExchangeLink: getLinkWithTabId(ROUTES.deals, DEALS_TABS_IDS.SHARE_EXCHANGES),
      };
    }

    return { isCompletedContinueExchange };
  }, [list]);

  const steps: { step: number; title: string; description?: string; link?: string; isActive?: boolean }[] = useMemo(
    () => [
      {
        step: 1,
        title: 'Add Investments',
        description: 'Getting Started',
        link: ROUTES.investments,
        isActive: isCompletedAddInvestments,
      },
      {
        step: 2,
        title: 'Initiate Exchange',
        description: 'Start Exchange',
        link: startExchangeLink,
        isActive: isCompletedStartExchange,
      },
      {
        step: 3,
        title: 'Continue Exchange',
        description: 'Progress',
        link: continueExchangeLink,
        isActive: isCompletedContinueExchange,
      },
    ],
    [
      continueExchangeLink,
      isCompletedAddInvestments,
      isCompletedContinueExchange,
      isCompletedStartExchange,
      startExchangeLink,
    ],
  );

  return { steps, isLoading };
};

export default useWelcomeUser;
