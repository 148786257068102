import React, { FC } from 'react';

import cn from 'classnames';

import { PAGINATION_LOADING_SKELETON_TEST_ID } from './Pagination';

interface Props {
  className?: string;
}

const PaginationSkeleton: FC<Props> = ({ className }) => {
  const divClassName = cn('skeleton-loader w-[260px] h-6', className);

  return (
    <div className='flex justify-between mt-9'>
      <div className={divClassName} data-testid={PAGINATION_LOADING_SKELETON_TEST_ID} />
      <div className={divClassName} data-testid={PAGINATION_LOADING_SKELETON_TEST_ID} />
    </div>
  );
};

export default PaginationSkeleton;
