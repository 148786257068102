import React from 'react';

import { selectCompaniesData } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import CompaniesShortListTable from 'page-components/companies/CompaniesShortListTable';
import { SnippetLayout } from 'shared-components';

const CompaniesSnippet = () => {
  const { list: companies, isLoading } = useAppSelector(selectCompaniesData);
  return (
    <SnippetLayout title='Companies' className='overflow-x-auto'>
      <CompaniesShortListTable isLoading={isLoading} companies={companies} />
    </SnippetLayout>
  );
};

export default CompaniesSnippet;
