import React, { FC } from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import Typography from 'shared-components/Typography';

type Props = {
  title: string;
  description?: string;
};

const PayBillModalsHeader: FC<Props> = ({ title, description }) => {
  return (
    <div>
      <div className='flex flex-col justify-center h-full'>
        <Typography tag='h3' variant={TypographyVariants.MEDIUM}>
          {title}
        </Typography>
        {description && <Typography className='py-6'>{description}</Typography>}
      </div>
    </div>
  );
};

export default PayBillModalsHeader;
