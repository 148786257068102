import React, { useEffect, useMemo } from 'react';

import cn from 'classnames';

import {
  DASHBOARD_PIE_CHART_COLORS,
  INVESTORS_TOOLTIP_MESSAGE,
  PENDING_INVESTORS_TOOLTIP_MESSAGE,
  REGISTERED_USERS_TOOLTIP_MESSAGE,
} from 'constants/dashboard';
import { ADMIN_DASHBOARD_INVESTORS_PIE_CHART_METRICS_LABELS } from 'constants/investors';
import { getInvestorsPieChartMetrics } from 'modules/investors/action';
import { selectInvestorsPieChartMetrics } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ChartInfoItemWithColoredRect } from 'shared-components';
import { ChartMetricsLayout, PieChart, PieChartActiveShapeV2 } from 'shared-components/chart';
import { convertObjectToChartData, mapObjectsWithLabels } from 'utils';

const AdminInvestorsSnippetContent = () => {
  const dispatch = useAppDispatch();
  const { isLoading, chartData } = useAppSelector(selectInvestorsPieChartMetrics);

  const allUsers = useMemo(
    () => (chartData ? Object.values(chartData).reduce((acc, value) => acc + value, 0) : 0),
    [chartData],
  );

  const chartDataWithLabels = useMemo(
    () =>
      chartData
        ? mapObjectsWithLabels(
            { ...chartData } as Record<string, number>,
            ADMIN_DASHBOARD_INVESTORS_PIE_CHART_METRICS_LABELS,
          )
        : {},
    [chartData],
  );

  useEffect(() => {
    dispatch(getInvestorsPieChartMetrics());
  }, [dispatch]);

  return (
    <div className='flex flex-col items-center'>
      <PieChart
        showTooltip
        isLoading={isLoading}
        colors={DASHBOARD_PIE_CHART_COLORS.slice(-3)}
        data={convertObjectToChartData(chartDataWithLabels)}
        activeShape={<PieChartActiveShapeV2 title='All Users' customValue={allUsers} />}
      />

      <ChartMetricsLayout className={cn('mt-6 space-x-6', { 'w-auto': !isLoading })} isLoading={isLoading} length={3}>
        <ChartInfoItemWithColoredRect
          title='Registered Users'
          value={chartData?.totalRegistered || 0}
          rectClassName='bg-[#00C2B5]'
          showTooltip
          tooltipMessage={REGISTERED_USERS_TOOLTIP_MESSAGE}
          tooltipId='registered-users'
        />
        <ChartInfoItemWithColoredRect
          title='Pending Investors'
          value={chartData?.totalPending || 0}
          rectClassName='bg-[#90D331]'
          showTooltip
          tooltipMessage={PENDING_INVESTORS_TOOLTIP_MESSAGE}
          tooltipId='pending-investors'
        />
        <ChartInfoItemWithColoredRect
          title='Investors'
          value={chartData?.totalCompleted || 0}
          rectClassName='bg-[#E0672A]'
          showTooltip
          tooltipMessage={INVESTORS_TOOLTIP_MESSAGE}
          tooltipId='investors'
        />
      </ChartMetricsLayout>
    </div>
  );
};

export default AdminInvestorsSnippetContent;
