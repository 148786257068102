import React, { FC } from 'react';

import cn from 'classnames';

import { NavigationMenu } from 'page-components';
import Logo from 'shared-components/Logo';

interface Props {
  isOpen: boolean;
  handleOpenSignOutModal: VoidFunction;
}

const Sidebar: FC<Props> = ({ isOpen, handleOpenSignOutModal }) => {
  return (
    <>
      <aside
        className={cn(
          'fixed -left-64 lg:left-0 w-64 min-h-screen py-6 bg-blue-800 text-white z-40 transition-all duration-500',
          {
            'left-[0px]': isOpen,
          },
        )}
      >
        <Logo className='mb-10' />
        <NavigationMenu onSignOut={handleOpenSignOutModal} />
      </aside>
    </>
  );
};

export default Sidebar;
