import React, { FC, ReactNode, SVGProps, useRef } from 'react';

import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CloseSvg } from 'assets/svg/close.svg';
import { TypographyStyles, TypographyVariants } from 'constants/shared/typography';
import useLockBodyScroll from 'hooks/use-lock-body-scroll/useLockBodyScroll';
import useOutsideClick from 'hooks/use-outside-click/useOutsideClick';
import { Overlay, TitleWithDescription } from 'shared-components/index';

export const MODAL_WINDOW_CUSTOM_SVG_TEST_ID = 'modal-window-custom-svg';

export interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  children: ReactNode;
  title?: string;
  description?: string | ReactNode;
  icon?: FC<SVGProps<SVGSVGElement>>;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  overlayClassName?: string;
  wrapperClassName?: string;
  preventOutsideClick?: boolean;
  descriptionClassName?: string;
  tooltip?: boolean;
  tooltipText?: string | ReactNode;
  titleVariant?: TypographyVariants;
  anchorId?: string;
}

const ModalWindow: FC<Props> = ({
  isOpen,
  onClose,
  children,
  title,
  description,
  icon: Icon,
  className,
  contentClassName,
  headerClassName,
  overlayClassName,
  wrapperClassName,
  preventOutsideClick,
  descriptionClassName,
  tooltip,
  tooltipText,
  titleVariant,
  anchorId,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, onClose, isOpen, preventOutsideClick);
  useLockBodyScroll(isOpen);

  return ReactDOM.createPortal(
    <>
      <div
        ref={modalRef}
        className={twMerge(
          'opacity-100 transition-all duration-500 max-w-1060 max-h-[80vh] w-11/12 sm:w-4/5 lg:w-auto fixed top-[10%] left-1/2 lg:left-[calc(50%+130px)] transform -translate-x-1/2 bg-white visible z-30 rounded-2xl overflow-y-auto',
          !isOpen ? 'invisible opacity-0 -translate-y-full' : '',
          className,
        )}
      >
        <div className={twMerge('position relative p-10 pt-20', wrapperClassName)}>
          <CloseSvg className='absolute top-4 right-4 cursor-pointer ' onClick={onClose} />
          {Icon && <Icon className='mb-4 mx-auto' data-testid={MODAL_WINDOW_CUSTOM_SVG_TEST_ID} />}
          {title && (
            <TitleWithDescription
              title={title}
              description={description}
              className={headerClassName}
              titleVariant={titleVariant}
              descriptionClassName={twMerge(
                TypographyStyles[TypographyVariants.BODY_NORMAL],
                'text-grey-500 py-6',
                descriptionClassName,
              )}
              tooltip={tooltip}
              tooltipText={tooltipText}
              anchorId={anchorId}
            />
          )}
          <div className={contentClassName}>{children}</div>
        </div>
      </div>
      <Overlay className={overlayClassName} isShown={isOpen} />
    </>,
    document.getElementById('modal') as HTMLElement,
  );
};

export default ModalWindow;
