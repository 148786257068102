export const DAY_FORMAT_WITH_SLASHES = 'dd/MM/yyyy';
export const DAY_FORMAT_WITH_DASH = 'yyyy-MM-dd';
export const MONTH_FORMAT_WITH_DASH = 'yyyy-MM';
export const MONTH_FORMAT_WITH_SLASHES = 'MM/yyyy';
export const MONTH_DAY_YEAR_FORMAT_WITH_SLASHES = 'MM/dd/yyyy';
export const YEAR_MONTH_FORMAT_WITH_DASH = 'yyyy-MM';
export const YEAR_DAY_MONTH_FORMAT_WITH_DASH = 'yyyy-MM-dd';
export const TIME_FORMAT = 'h:mm a';
export const FULL_TIME_FORMAT = 'hh:mm a';
export const DAY_FORMAT_WITH_HOURS = 'dd/MM/yyyy HH:mm';
