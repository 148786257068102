import React, { FC } from 'react';

import { groupBy, mapValues } from 'lodash';

import { DEAL_DETAILS_PAGE_SHARES_OVERVIEW_TITLE } from 'constants/deals';
import { DealShareExchangeDetails } from 'interfaces';
import { selectDealDetailsClosingDate } from 'modules/deals/selectors';
import { useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';
import { capitalizeFirstLetter } from 'utils';
import { getShareExchangesTotalInfo } from 'utils/deals';

import ShareExchangeInfoItem from '../ShareExchangeInfoItem';
import SharesOverviewTable from './shares-overview-table/SharesOverviewTable';

interface Props {
  shareExchanges?: DealShareExchangeDetails[];
  originalInvestmentPrice: string | null;
}

const SharesOverviewSnippet: FC<Props> = ({ shareExchanges = [], originalInvestmentPrice }) => {
  const dealDetailsClosingDate = useAppSelector(selectDealDetailsClosingDate);

  const groupedShareExchanges = mapValues(groupBy(shareExchanges, 'classOfShares'));

  const { totalShares, totalExchangedShares } = getShareExchangesTotalInfo(shareExchanges);

  return (
    <SnippetLayout
      className='basis-full shrink mt-7 w-full'
      childrenClassName='flex flex-col'
      title={DEAL_DETAILS_PAGE_SHARES_OVERVIEW_TITLE}
    >
      <SharesOverviewTable dealExchanges={shareExchanges} closingDate={dealDetailsClosingDate} />

      <div className='flex flex-col self-end w-fit mt-16'>
        <Typography className='mb-2 pl-2 text-grey-500'>Total Summary</Typography>
        <hr className='border-1 border-grey-300 w-full' />

        <div className='flex flex-col'>
          {Object.keys(groupedShareExchanges).map((key) => {
            const shareExchanges = groupedShareExchanges[key];

            const { totalShares } = getShareExchangesTotalInfo(shareExchanges);

            return (
              <ShareExchangeInfoItem
                key={key}
                className='mb-2'
                label={capitalizeFirstLetter(key)}
                value={totalShares || 0}
              />
            );
          })}
        </div>

        <hr className='border-1 border-grey-300 w-full' />

        <ShareExchangeInfoItem label='Total Shares for Exchange' value={totalShares} />
        <ShareExchangeInfoItem label='Total Number of Daxia shares to be received' value={totalExchangedShares} />
        {originalInvestmentPrice && (
          <ShareExchangeInfoItem label='Total Amount Originally Invested' value={Number(originalInvestmentPrice)} />
        )}
      </div>
    </SnippetLayout>
  );
};

export default SharesOverviewSnippet;
