import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminInvestorsApi, adminInvestorsMetrics, investorApi } from 'apis';
import { showServerError } from 'helpers';
import { GetListPayload } from 'interfaces';

export const INVESTORS_SLICE_NAME = 'investors';

export const getInvestors = createAsyncThunk(`${INVESTORS_SLICE_NAME}/getInvestors`, async (data: GetListPayload) => {
  const response = await adminInvestorsApi.getInvestors(data);
  return response.data;
});

export const getInvestorsMetrics = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAdminInvestorsMetrics`,
  async (rangeFilter: string) => {
    const response = await adminInvestorsMetrics.getMetrics(rangeFilter);
    return response.data;
  },
);

export const getInvestor = createAsyncThunk(`${INVESTORS_SLICE_NAME}/getInvestor`, async (id: string) => {
  const response = await adminInvestorsApi.getInvestor(id);
  return response.data.data;
});

export const getInvestorCompaniesInvestments = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getInvestorCompaniesInvestments`,
  async (payload: GetListPayload & { id: string }) => {
    const response = await adminInvestorsApi.getInvestorCompaniesInvestments(payload);
    return response.data;
  },
);

export const getInvestorFundsShares = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getInvestorFundsShares`,
  async (payload: GetListPayload & { id: string }) => {
    const response = await adminInvestorsApi.getInvestorFundsShares(payload);
    return response.data;
  },
);

export const getInvestorShareExchanges = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getInvestorShareExchanges`,
  async (payload: GetListPayload & { id: string }) => {
    const response = await adminInvestorsApi.getInvestorShareExchanges(payload);
    return response.data;
  },
);

export const getInvestorInvoices = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getInvestorInvoices`,
  async (payload: GetListPayload & { id: string }) => {
    const response = await adminInvestorsApi.getInvestorInvoices(payload);
    return response.data;
  },
);

export const getInvestorsPieChartMetrics = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAdminInvestorsPieChart`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminInvestorsMetrics.getPieChart();

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getInvestorsDoneShareExchanges = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAdminInvestorsDoneSes`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminInvestorsMetrics.getInvestorsDoneShareExchanges();

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getEntityActivityLogs = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAuditActivityLogs`,
  async (params: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await investorApi.getInvestorActivityLogs(params);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getEntityActivityLog = createAsyncThunk(
  `${INVESTORS_SLICE_NAME}/getAuditActivityLog`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await investorApi.getInvestorActivityLog(id);
      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);
