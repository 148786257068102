import React, { FC, useMemo } from 'react';

import { components, ValueContainerProps } from 'react-select';

import { Option } from 'interfaces';

const CompanySelectValueContainerWithTitle: FC<ValueContainerProps> = (props) => {
  const { innerProps = {}, hasValue, getValue } = props || {};

  const customInnerProps = useMemo(() => {
    if (!hasValue) return {};

    return {
      title: getValue()
        .map((option) => (option as Option).label)
        .join(', '),
    };
  }, [hasValue, getValue]);

  return (
    <components.ValueContainer innerProps={{ ...customInnerProps, ...innerProps }} {...props}>
      {props.children}
    </components.ValueContainer>
  );
};

export default CompanySelectValueContainerWithTitle;
