import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  firstNode?: ReactNode;
  children: ReactNode;
  className?: string;
  childrenClassName?: string;
}

const ListItemContainer = ({ firstNode, children, className, childrenClassName }: Props) => {
  return (
    <div className={twMerge('flex basis-8/12 lg:basis-10/12', className)}>
      {firstNode}
      <div className={twMerge('flex flex-wrap basis-8/12 lg:basis-8/12 ml-4', childrenClassName)}>{children}</div>
    </div>
  );
};

export default ListItemContainer;
