import React, { FC } from 'react';

import { DEALS_SHARES_OVERVIEW_TABLE_COLUMNS } from 'constants/deals';
import { DealShareExchangeDetails } from 'interfaces';
import Table, { TableSkeleton } from 'shared-components/table';

import NoSharesOverviewAdded from './NoSharesOverviewAdded';
import SharesOverviewTableItem from './SharesOverviewTableItem';

interface Props {
  dealExchanges: DealShareExchangeDetails[];
  closingDate?: string;
  isLoading?: boolean;
}

const SharesOverviewTable: FC<Props> = ({ dealExchanges, isLoading, closingDate = '' }) => {
  return (
    <Table columns={DEALS_SHARES_OVERVIEW_TABLE_COLUMNS}>
      {dealExchanges?.length && !isLoading ? (
        <>
          {dealExchanges.map((deal) => (
            <SharesOverviewTableItem key={deal.id} {...deal} closingDate={closingDate} />
          ))}
        </>
      ) : isLoading ? (
        <TableSkeleton rowsNumber={3} columnsNumber={DEALS_SHARES_OVERVIEW_TABLE_COLUMNS.length} />
      ) : (
        <NoSharesOverviewAdded />
      )}
    </Table>
  );
};

export default SharesOverviewTable;
