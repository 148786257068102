import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { CompaniesIacResponse, CompaniesIacState } from 'interfaces';

import { getCompaniesIacList } from './action';

export function getCompaniesIacReducer(builder: ActionReducerMapBuilder<CompaniesIacState>) {
  builder.addCase(getCompaniesIacList.pending, (state) => {
    state.isLoading = true;
    state.list = [];
  });

  builder.addCase(getCompaniesIacList.fulfilled, (state, action: PayloadAction<CompaniesIacResponse>) => {
    const {
      data,
      meta: { last_page, total },
    } = action.payload;

    return { ...state, list: data, totalPages: last_page, total, isLoading: false };
  });

  builder.addCase(getCompaniesIacList.rejected, (state) => {
    state.isLoading = false;
  });
}
