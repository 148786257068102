import React from 'react';

import { companyIacStatusesColoredLabel } from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { COMPANY_IAC_STATUSES, CompanyIac } from 'interfaces';
import {
  ColoredLabel,
  LabelWithValue,
  LinkButton,
  ListItemContainer,
  ListItemTitle,
  ListItemWrapper,
} from 'shared-components';
import { getAdminCompanyIacLink, getCompanyIacLinkByIacId } from 'utils';

const LABEL_WITH_VALUE_CLASSNAME = 'w-auto grow-0 shrink-0 basis-1/2';

interface Props {
  data: CompanyIac;
  companyId: string;
}

const ArchivedCompanyIacListItem = ({ data, companyId }: Props) => {
  const { id, companyNumber, incorporationDate, status } = data || {};

  const viewLink =
    status === COMPANY_IAC_STATUSES.ARCHIVED
      ? getCompanyIacLinkByIacId(companyId, String(id), true)
      : getAdminCompanyIacLink(Number(companyId));

  return (
    <ListItemWrapper className='flex-col sm:flex-row gap-4 sm:gap-0'>
      <ListItemContainer
        className='flex-col xs:flex-row'
        childrenClassName='ml-0 xs:ml-4'
        firstNode={<ListItemTitle title='Company number' value={companyNumber} />}
      >
        <LabelWithValue
          title='Date of incorporation'
          content={incorporationDate}
          className={LABEL_WITH_VALUE_CLASSNAME}
        />
        <LabelWithValue
          className={LABEL_WITH_VALUE_CLASSNAME}
          title='Status'
          content={status && <ColoredLabel className='ml-0 xs:ml-1' {...companyIacStatusesColoredLabel[status]} />}
        />
      </ListItemContainer>

      <LinkButton className='w-full sm:w-40' variant={ButtonVariants.SECONDARY} to={viewLink}>
        View
      </LinkButton>
    </ListItemWrapper>
  );
};

export default ArchivedCompanyIacListItem;
