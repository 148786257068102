import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';

import { GetUserPayload, GetUsersPayload, PermissionsResponse, UserData, UsersState } from 'interfaces';

import {
  getUsers,
  getUser,
  getUserPermissions,
  inviteUser,
  resetUserData,
  updateUserPassword,
  sendDirectorEntityInvite,
} from './action';

// TODO - replace any type to normal

export function getUsersReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(getUsers.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<GetUsersPayload>) => {
    state.users = action.payload.data;
    state.totalPages = action.payload.meta.last_page;
    state.total = action.payload.meta.total;
    state.isLoading = false;
  });

  builder.addCase(getUsers.rejected, (state) => {
    state.isLoading = false;
    state.users = [];
    state.totalPages = 1;
    state.total = 0;
  });
}

export function getUserReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(getUser.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getUser.fulfilled, (state, action: PayloadAction<GetUserPayload>) => {
    state.userData = action.payload.data;
    state.isLoading = false;
  });

  builder.addCase(getUser.rejected, (state) => {
    state.isLoading = false;
    state.userData = null;
  });
}

export function getUserPermissionsReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(getUserPermissions.pending, (state) => {
    state.isPermissionsLoading = true;
  });

  builder.addCase(getUserPermissions.fulfilled, (state, action: PayloadAction<PermissionsResponse>) => {
    const { availablePermissions, data } = action.payload;

    state.isPermissionsLoading = false;
    state.permissions = availablePermissions;
    state.enabledPermissions = map(data, 'id');
  });

  builder.addCase(getUserPermissions.rejected, (state) => {
    state.isPermissionsLoading = false;
  });
}

export function inviteUserReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(inviteUser.fulfilled, (state, action: PayloadAction<UserData>) => {
    state.userData = action.payload;
  });
}

export function sendDirectorEntityInviteReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(sendDirectorEntityInvite.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(sendDirectorEntityInvite.fulfilled, (state, action: PayloadAction<UserData>) => {
    state.userData = action.payload;
    state.isLoading = false;
  });

  builder.addCase(sendDirectorEntityInvite.rejected, (state) => {
    state.isLoading = false;
  });
}

export function updateUserPasswordReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(updateUserPassword.pending, (state) => {
    state.isProfileSettingsActionLoading = true;
    state.isProfileSettingsActionCalled = false;
  });

  builder.addCase(updateUserPassword.fulfilled, (state) => {
    state.isProfileSettingsActionLoading = false;
    state.isProfileSettingsActionCalled = true;
  });

  builder.addCase(updateUserPassword.rejected, (state) => {
    state.isProfileSettingsActionCalled = false;
    state.isProfileSettingsActionLoading = false;
  });
}

export function resetUserDataReducer(builder: ActionReducerMapBuilder<UsersState>) {
  builder.addCase(resetUserData, (state) => {
    state.userData = null;
  });
}
