import React from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { ConstraintVariants } from 'constants/shared';
import { TypographyStyles } from 'constants/shared/typography';
import useShortCompanyInfo from 'hooks/useShortCompanyInfo';
import { DashboardLayout } from 'page-components';
import ArchivedCompanyIacList from 'page-components/company-iac/archived-company-iac/ArchivedCompanyIacList';
import { Constraint, DynamicPageTitle } from 'shared-components';

const ARCHIVED_COMPANY_IAC_TITLE = 'Archived Companies IAC';

const ArchivedCompanyIac = () => {
  const { id } = useParams();

  const { companyName, isLoading } = useShortCompanyInfo();

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <DynamicPageTitle
          isLoading={isLoading}
          dynamicTitle={companyName}
          title={ARCHIVED_COMPANY_IAC_TITLE}
          className={cn('mb-16', TypographyStyles.large)}
        />
        <ArchivedCompanyIacList id={id} />
      </Constraint>
    </DashboardLayout>
  );
};

export default ArchivedCompanyIac;
