import React, { useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ReactComponent as CopySvg } from 'assets/svg/copy.svg';
import { DUE_DILIGENCE_GENERATE_INVITE_LINK_FORM_FIELDS } from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import { DueDiligenceGenerateInviteLinkInput } from 'interfaces';
import { generateDueDiligenceInviteFounderLink } from 'modules/current-user/action';
import { useAppDispatch } from 'modules/store';
import { Button, FormContent, Input, ModalWindow } from 'shared-components';

const GenerateInviteLinkBlock = () => {
  const { id: companyId } = useParams();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [link, setLink] = useState('');

  const { isOpen, onOpen, onClose } = useModal();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DueDiligenceGenerateInviteLinkInput>();

  const onSubmit: SubmitHandler<DueDiligenceGenerateInviteLinkInput> = ({ email }) => {
    if (!companyId) return;

    setIsLoading(true);
    dispatch(generateDueDiligenceInviteFounderLink({ companyId: Number(companyId), email }))
      .unwrap()
      .then((inviteLink) => {
        setLink(inviteLink);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCloseModal = () => {
    if (isLoading) return;

    onClose();
    reset();
    setIsCopied(false);
    setLink('');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <ModalWindow
        className='lg:w-8/12 xl:w-6/12'
        title='Generate Link'
        wrapperClassName='pt-14'
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='mt-6'>
          <FormContent
            fields={DUE_DILIGENCE_GENERATE_INVITE_LINK_FORM_FIELDS}
            register={register}
            control={control}
            errors={errors}
            isDisabled={isLoading}
          />
          {link && (
            <div>
              <div className='flex items-center justify-center'>
                <Input className='mb-0' inputClassName='border-none focus-visible:outline-none' value={link} readOnly />
                <button type='button' className='ml-1 text-grey-500' onClick={handleCopyLink} disabled={!link}>
                  {isCopied ? 'Copied' : <CopySvg />}
                </button>
              </div>
            </div>
          )}

          <Button className='mt-6' isLoading={isLoading}>
            Generate
          </Button>
        </form>
      </ModalWindow>

      <Button className='w-full sm:w-[210px]' variant={ButtonVariants.SECONDARY} onClick={onOpen}>
        Generate link
      </Button>
    </>
  );
};

export default GenerateInviteLinkBlock;
