import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as WarningSvg } from 'assets/svg/warning-outlined.svg';
import { ButtonVariants } from 'constants/shared/button';
import { ButtonDropdownActions, Company } from 'interfaces';
import { Button, ButtonDropdown } from 'shared-components';
import { checkIsAdmin, getCompanyProfileLink, getDueDiligenceLink } from 'utils';

const CompaniesItemActions = ({ id, confirmed, canStartDueDiligence, canViewDueDiligence }: Company) => {
  const navigate = useNavigate();
  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const actions: ButtonDropdownActions[] = [
    {
      id: 'view',
      title: confirmed ? 'View Company' : 'Pending Approval',
      action: () => navigate(getCompanyProfileLink(id, isAdmin)),
    },
    { id: 'view_dd', title: 'View Due Diligence', action: () => navigate(getDueDiligenceLink(id, isAdmin)) },
  ];

  if (canStartDueDiligence || canViewDueDiligence)
    return (
      <ButtonDropdown
        shouldUseDisplay
        disabledButton={!confirmed}
        className='w-[180px]'
        actions={actions}
        additionalTopValue={60}
      />
    );

  return (
    <Button
      variant={ButtonVariants.SECONDARY}
      onClick={() => (confirmed ? navigate(getCompanyProfileLink(id)) : undefined)}
      className='w-fit'
    >
      {confirmed ? (
        'View'
      ) : (
        <span className='flex whitespace-nowrap'>
          Pending Approval <WarningSvg className='ml-2' />
        </span>
      )}
    </Button>
  );
};

export default CompaniesItemActions;
