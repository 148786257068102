import React, { FC, useMemo } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as CheckmarkSvg } from 'assets/svg/check-mark.svg';
import { TableRowVariants } from 'constants/shared';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { DueDiligenceDataWithoutChildren, FromItemHigligtedColor } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';
import { getSignificantControlCompanyLink } from 'utils';

interface Props {
  company: DueDiligenceDataWithoutChildren;
  isAdmin: boolean;
  isFmUser: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
  isDDFinishedOrDeclined?: boolean;
}

const SignificantControlCompaniesTableItem: FC<Props> = ({
  company,
  isAdmin,
  isFmUser,
  getUnfieldValue,
  isDDFinishedOrDeclined,
}) => {
  const highlightError = useMemo(() => {
    if (getUnfieldValue && !isDDFinishedOrDeclined) {
      const unfieldValueByCompanyId = getUnfieldValue(`significantControlCompany.${company.companyId}`);

      const unfieldValue = getUnfieldValue('significantControlCompany');

      if (unfieldValue || unfieldValueByCompanyId) {
        return unfieldValueByCompanyId?.asError ? FromItemHigligtedColor.Red : FromItemHigligtedColor.Blue;
      }
    }
    return null;
  }, [company.companyId, getUnfieldValue, isDDFinishedOrDeclined]);

  return (
    <TableRow
      variant={TableRowVariants.SIMPLIFIED}
      className={
        highlightError
          ? cn('!bg-opacity-20', {
              '!bg-lightBlue-600': highlightError === FromItemHigligtedColor.Blue,
              '!bg-red-500': highlightError === FromItemHigligtedColor.Red,
            })
          : ''
      }
    >
      <TableData />

      <TableData className='py-2'>{company.businessDetails?.businessName || ''}</TableData>

      <TableData>
        <Link className='text-violet-600' to={getSignificantControlCompanyLink(company.companyId, isAdmin || isFmUser)}>
          View Company
        </Link>
      </TableData>

      <TableData className='flex items-center justify-end h-42'>
        <CheckmarkSvg className='text-green-600 w-7 h-6 fill-green-600 mx-6' />
      </TableData>
    </TableRow>
  );
};

export default SignificantControlCompaniesTableItem;
