import React, { FC, ReactNode } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { GET_DASHBOARD_MY_INVITES_FORM_FIELDS, DASHBOARD_MY_INVITES_MODAL_TITLE } from 'constants/dashboard';
import { ButtonVariants } from 'constants/shared/button';
import { InviteUserType, UserTypes } from 'constants/user';
import useModal from 'hooks/use-modal/useModal';
import { MyInvitesInput } from 'interfaces';
import { inviteUser } from 'modules/current-user/action';
import { useAppDispatch } from 'modules/store';
import { Button, FormContent, ModalWindow } from 'shared-components';

interface Props {
  loading: boolean;
  buttonContent?: string | ReactNode;
  buttonClassName?: string;
  buttonVariant?: ButtonVariants;
  companyId?: number;
  type?: UserTypes;
  inviteType?: InviteUserType;
}

const MyInvitesModalForm: FC<Props> = ({
  loading,
  buttonContent,
  buttonClassName,
  buttonVariant,
  companyId,
  type,
  inviteType,
}) => {
  const { onOpen, ...modalState } = useModal();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<MyInvitesInput>({
    defaultValues: {
      userType: null,
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  const onSubmit: SubmitHandler<MyInvitesInput> = (data) => {
    dispatch(inviteUser({ ...data, companyId, inviteType, type: type ? type : undefined })).finally(() => {
      reset();
      modalState.onClose();
    });
  };

  return (
    <>
      <Button
        variant={buttonVariant || ButtonVariants.PRIMARY}
        className={twMerge('w-28 float-right', buttonClassName)}
        onClick={onOpen}
      >
        {buttonContent || 'Invite'}
      </Button>
      <ModalWindow
        title={DASHBOARD_MY_INVITES_MODAL_TITLE}
        wrapperClassName='pt-10'
        className='lg:w-8/12 xl:w-5/12'
        {...modalState}
      >
        <form onSubmit={handleSubmit(onSubmit)} className='mt-10'>
          <FormContent
            fields={GET_DASHBOARD_MY_INVITES_FORM_FIELDS(!!companyId)}
            register={register}
            errors={errors}
            control={control}
          />
          <Button isLoading={loading}>Invite</Button>
        </form>
      </ModalWindow>
    </>
  );
};

export default MyInvitesModalForm;
