import React, { FC, Dispatch, SetStateAction } from 'react';

import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import {
  DueDiligencePersonsFormNamesEnum,
  DueDiligenceSections,
  SIGNIFICANT_CONTROL_BLOCK_DESCRIPTION,
  SIGNIFICANT_CONTROL_BLOCK_TITLE,
} from 'constants/due-diligence';
import useModal from 'hooks/use-modal/useModal';
import { UseDueDiligenceBusinessDetailsReturnValues } from 'hooks/useDueDiligenceBusinessDetails';
import useDueDiligencePersons from 'hooks/useDueDiligencePersons';
import { DueDiligencePersonsProps } from 'interfaces';
import { IsSCPSSameAsInSCCChecked, selectSignificantControlPersons } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import SignificantControlChildPersons from 'page-components/due-diligence/significant-control/SignificantControlChildPersons';

import { PersonsBlock } from '../../index';
import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
import SignificantControlCompaniesTable from './SignificantControlCompaniesTable';

const SignificantControlBlock: FC<
  DueDiligencePersonsProps & {
    validateBlock: boolean;
    setValidateBlock: Dispatch<SetStateAction<boolean>>;
    isEditing: boolean;
    isConfirmationValidationHighlighted: boolean;
    isShownChildren?: boolean;
    businessDetailsProps: UseDueDiligenceBusinessDetailsReturnValues;
  }
> = ({
  validateBlock,
  setValidateBlock,
  isEditing,
  isConfirmationValidationHighlighted,
  businessDetailsProps,
  ...personsProps
}) => {
  const significantControlPersons = useAppSelector(selectSignificantControlPersons);
  const isSCPSSameAsInSCCChecked = useAppSelector(IsSCPSSameAsInSCCChecked);
  const showPersons = !isSCPSSameAsInSCCChecked || !!significantControlPersons?.length;

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields },
    getValues,
    watch,
  } = useFormContext();

  const {
    onSubmit,
    handleCancel,
    handleEdit,
    handleDiscardChanges,
    handleSetIsLoading,
    isLoading,
    setDeletedPersons,
    deletedPersons,
    deletedAddresses,
    setDeletedAddresses,
  } = useDueDiligencePersons({
    sectionType: DueDiligenceSections.SIGNIFICANT_CONTROL_PERSONS,
    reset: reset,
    dirtyFields: dirtyFields,
    name: 'directors',
    validateAddresses: validateBlock,
  });

  const modalProps = useModal();

  const watchDirectorsFieldsLength = watch(DueDiligencePersonsFormNamesEnum.DIRECTORS)?.length || 0;

  const onClickSave = () => {
    if (validateBlock) {
      handleSubmit(onSubmit({ onOpen: modalProps.onOpen, handleCancel }))();
      return;
    }

    const values = getValues();
    onSubmit({ onOpen: modalProps.onOpen, handleCancel })(values);
  };

  const onClickDiscardChanges = () => {
    handleDiscardChanges();
    setValidateBlock(false);
  };

  return (
    <DueDiligenceBlock
      blockNumber='1.2'
      descriptionClassName='text-grey-500'
      sectionType={DueDiligenceSections.SIGNIFICANT_CONTROL_PERSONS}
      title={SIGNIFICANT_CONTROL_BLOCK_TITLE}
      description={watchDirectorsFieldsLength > 2 ? SIGNIFICANT_CONTROL_BLOCK_DESCRIPTION : undefined}
      onClickEdit={handleEdit}
      onClickSave={showPersons ? onClickSave : undefined}
      onClickDiscardChanges={onClickDiscardChanges}
      isLoading={isLoading}
    >
      <SignificantControlCompaniesTable
        getUnfieldValue={personsProps.getUnfieldValue}
        isConfirmationValidationHighlighted={isConfirmationValidationHighlighted}
      />
      <SignificantControlChildPersons
        businessDetailsProps={businessDetailsProps}
        isEditing={isEditing}
        getUnfieldValue={personsProps.getUnfieldValue}
      />
      {showPersons && (
        <PersonsBlock
          {...personsProps}
          {...modalProps}
          personsList={significantControlPersons}
          name={DueDiligencePersonsFormNamesEnum.DIRECTORS}
          handleCancel={handleCancel}
          handleSetIsLoading={handleSetIsLoading}
          deletedPersons={deletedPersons}
          setDeletedPersons={setDeletedPersons}
          deletedAddresses={deletedAddresses}
          setDeletedAddresses={setDeletedAddresses}
          bypassValidation={!validateBlock}
          formClassName={cn({ 'disabled-light-grey': isLoading })}
          isEditing={isEditing}
          successMessage='The executive directors and persons were successfully saved'
        />
      )}
    </DueDiligenceBlock>
  );
};

export default SignificantControlBlock;
