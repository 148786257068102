import React from 'react';

const InvitingFundModalFmUsersSkeleton = () => {
  return (
    <div>
      <div className='skeleton-loader h-10 w-full mt-0.5' />
      <div className='skeleton-loader h-10 w-full mt-0.5' />
      <div className='skeleton-loader h-10 w-full mt-0.5' />
    </div>
  );
};

export default InvitingFundModalFmUsersSkeleton;
