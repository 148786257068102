import React, { FC } from 'react';

type Props = {
  fullName?: string;
};

const DeleteUserModalTitle: FC<Props> = ({ fullName }) => {
  return (
    <>
      <b>Are you sure you want to remove:</b> {` ${fullName}`}
    </>
  );
};

export default DeleteUserModalTitle;
