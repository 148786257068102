import React, { ChangeEvent, FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { Button, Input } from 'shared-components';
import Typography from 'shared-components/Typography';
import { checkIsFmUser } from 'utils';

type Props = {
  title: ReactNode | string;
  description?: string;
  handleChangeSearch: (query: ChangeEvent<HTMLInputElement>) => void;
  onOpenUploadModal: VoidFunction;
  className?: string;
  descriptionClassName?: string;
  isAdmin?: boolean;
};

const TitleWithSearch: FC<Props> = ({
  title,
  handleChangeSearch,
  onOpenUploadModal,
  className,
  description,
  descriptionClassName,
  isAdmin,
}) => {
  return (
    <div className={className}>
      <div className='flex items-start md:items-center flex-col md:flex-row'>
        <Typography className='mr-12' tag='h4' variant={TypographyVariants.LARGE}>
          {title}
        </Typography>
        <div
          className={twMerge(
            cn('flex sm:items-end w-full md:w-10/12 flex-col sm:flex-row gap-4 sm:gap-0', {
              'w-full justify-end': !isAdmin,
            }),
          )}
        >
          {isAdmin && (
            <Input
              className='mb-[0px] w-auto flex-1'
              onChange={handleChangeSearch}
              placeholder='Search by Filename, Document Type, Company Name'
            />
          )}
          {!checkIsFmUser() && (
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={onOpenUploadModal}
              className='sm:ml-4 w-full sm:w-[200px]'
            >
              Upload Document +
            </Button>
          )}
        </div>
      </div>
      {description && <Typography className={descriptionClassName}>{description}</Typography>}
    </div>
  );
};

export default TitleWithSearch;
