import React, { FC } from 'react';

import cn from 'classnames';
import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SpinLoader } from 'assets/svg/spin-loader.svg';
import useLockBodyScroll from 'hooks/use-lock-body-scroll/useLockBodyScroll';

import Overlay from './overlay/Overlay';

export const FULL_SCREEN_LOADER_TEST_ID = 'FULL_SCREEN_LOADER_TEST_ID';

interface Props {
  text?: string;
  hideOverlay?: boolean;
  withoutSidebar?: boolean;
  className?: string;
}

const FullScreenLoader: FC<Props> = ({ text, hideOverlay, withoutSidebar, className }) => {
  useLockBodyScroll(true);

  return ReactDOM.createPortal(
    <div
      className={twMerge('fixed top-0 left-0 h-screen w-screen flex justify-center items-center z-30', className)}
      data-testid={FULL_SCREEN_LOADER_TEST_ID}
    >
      <div className={cn('z-50', { 'lg:translate-x-32 -translate-y-24': !withoutSidebar })}>
        <SpinLoader className='animated-spin w-32 h-32 mx-auto' />
        {text && <p className='text-white text-3xl mx-0 mt-8'>{text}</p>}
      </div>
      <Overlay className='opacity-80 bg-grey-700' isShown={!hideOverlay} />
    </div>,
    document.getElementById('loader') as HTMLElement,
  );
};

export default FullScreenLoader;
