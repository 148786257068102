import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.signatureReducer;

export const selectSignatureUrl = createSelector(selectState, ({ signatureUrl }) => signatureUrl);

export const selectSignatureIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
export const selectIsSignatureSaved = createSelector(selectState, ({ isSignatureSaved }) => isSignatureSaved);
