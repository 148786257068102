import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

const NoCompanyInvestorsAdded = () => {
  return (
    <TableRow>
      <TableData colSpan={6} className='pl-5'>
        No information is available as no Company Investors have been added
      </TableData>
    </TableRow>
  );
};

export default NoCompanyInvestorsAdded;
