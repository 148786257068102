import { COMPANY_WITH_SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import {
  ShareExchangeStatuses,
  WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES,
  WELCOME_INVESTOR_SHARE_EXCHANGE_LIST_ACTION,
} from 'constants/share-exchange';
import { Investor, Investment, MetaPage, MetaState, DealPredefinedData, Option } from 'interfaces';

export type ShareExchangeInvestor = Omit<Investor, 'deals'>;

export type ShareExchangeRequestsResponse = { data: ShareExchangeInterfaces[]; meta: MetaPage };

export interface ShareExchangeInterfaces {
  id: number;
  companyName: string;
  createdAt: string;
  status: ShareExchangeStatuses;
  investor: ShareExchangeInvestor;
}

export interface ShareExchangeRequests extends MetaState {
  list: ShareExchangeInterfaces[];
  isLoading: boolean;
}

export interface ShareExchangeAnswers {
  questionId: number;
  answerKey: number;
}

export interface SubmitShareExchangeAnswersPayload {
  shareExchangeRequestId: string;
  answers: ShareExchangeAnswers[];
}

export interface ShareExchangeInvestment {
  totalShares: number | string;
  id: string;
}

export interface ShareExchangeInvestmentsPayload {
  investments: ShareExchangeInvestment[];
}

export interface ShareExchangeSubmittedAnswer {
  title: string;
  marked: boolean;
}

export interface ShareExchangeSubmittedAnswers {
  question: string;
  answers: ShareExchangeSubmittedAnswer[];
}

export interface SingleShareExchange
  extends Pick<ShareExchangeInterfaces, 'id' | 'status' | 'companyName' | 'createdAt'> {
  status: ShareExchangeStatuses;
  companyNumber: string;
  companyId: string;
  investments: Investment[];
  answers: ShareExchangeSubmittedAnswers[] | null;
  reason?: string;
}

export type WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS = WELCOME_INVESTOR_SHARE_EXCHANGE_LIST_ACTION &
  COMPANY_WITH_SHARE_EXCHANGE_STATUSES;

export interface WelcomeInvestorShareExchangeAction {
  view: boolean;
  action: WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS;
  id: number | null;
  status: 'pending' | 'completed';
}

export type WelcomeInvestorShareExchangeList = Record<
  WELCOME_INVESTOR_SHARE_EXCHANGE_ACTIONS_NAMES,
  WelcomeInvestorShareExchangeAction
>;

export interface WelcomeInvestorShareExchangeData {
  isLoading: boolean;
  list: WelcomeInvestorShareExchangeList | null;
}

export interface ShareExchangeReviewState {
  isLoading: boolean;
  shareExchange: SingleShareExchange;
}

export interface ShareExchangeState {
  requests: ShareExchangeRequests;
  review: ShareExchangeReviewState;
  welcomeInvestorShareExchangeData: WelcomeInvestorShareExchangeData;
}

export enum DeclineShareExchangeFieldNames {
  REASON = 'reason',
}

export type StartShareExchangesInvestmentItem = Pick<
  DealPredefinedData,
  'fundSharePrice' | 'noExchangedShares' | 'noOfShares'
> & {
  classOfShares: Option | null;
  companyPrice: string;
  closingDate: Option | null;
};

export interface StartShareExchangesInvestmentsFormValues {
  exchanges: StartShareExchangesInvestmentItem[];
}

export interface StartShareExchangeWithCompanyFormValues extends StartShareExchangesInvestmentsFormValues {
  firstName: string;
  lastName: string;
  email: Option;
  investorId: number;
}

export interface StartShareExchangeWithInvestorFormValues extends StartShareExchangesInvestmentsFormValues {
  companyName: Option | null;
  companyId: number;
}

export type StartShareExchangeWatchValues = Pick<
  StartShareExchangesInvestmentItem,
  'noOfShares' | 'fundSharePrice' | 'companyPrice'
>;

export interface DeclineShareExchangeInputs {
  [DeclineShareExchangeFieldNames.REASON]: string;
}

export type StartShareExchangesInvestmentItemPayload = Omit<
  StartShareExchangesInvestmentItem,
  'classOfShares' | 'closingDate'
> & {
  classOfShares: string;
  closingDate: string;
};

export interface ShareExchangeForInvestorPayload {
  investorId: number | null;
  investor: {
    firstName: string;
    lastName: string;
    email: string;
  };
  companyId: number;
  exchanges: StartShareExchangesInvestmentItemPayload[];
}
