import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { getCompanyById } from 'modules/companies/action';
import { selectCompanyShareExchangeInfo } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import AdminCompanyProfileDealInformationSection from './admin-company-profile-deal-information-section/AdminCompanyProfileDealInformationSection';
import AdminCompanyProfileHeader from './admin-company-profile-header/AdminCompanyProfileHeader';
import AdminCompanyProfileRelatedSection from './admin-company-profile-related-section/AdminCompanyProfileRelatedSection';
import AdminCompanyProfileShareExchangeBreakdownSection from './AdminCompanyProfileShareExchangeBreakdownSection';
import AdminCompanyProfileShareExchangeSection from './AdminCompanyProfileShareExchangeSection';

const AdminCompanyProfileContainer = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, dealParameters, ...shareExchangeInfo } = useAppSelector(selectCompanyShareExchangeInfo);

  useEffect(() => {
    if (id)
      dispatch(getCompanyById({ id, isAdmin: true }))
        .unwrap()
        .catch(() => navigate(ROUTES.admin));
  }, [dispatch, id, navigate]);

  return (
    <div>
      <AdminCompanyProfileHeader />
      <div className='flex flex-col md:flex-row gap-6 mt-6'>
        <div className='w-full md:w-[65%] h-full'>
          <AdminCompanyProfileDealInformationSection />
          <AdminCompanyProfileShareExchangeSection
            {...shareExchangeInfo}
            dealId={dealParameters?.length ? dealParameters[0]?.id : ''}
            companyId={id}
            isLoading={isLoading}
          />
          <AdminCompanyProfileShareExchangeBreakdownSection {...shareExchangeInfo} isLoading={isLoading} />
        </div>
        <AdminCompanyProfileRelatedSection isLoading={isLoading} />
      </div>
    </div>
  );
};

export default AdminCompanyProfileContainer;
