import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { INVESTORS_DONE_SHARE_EXCHANGES_SNIPPET_TITLE } from 'constants/dashboard';
import { INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS } from 'constants/investors';
import { ROUTES } from 'constants/routes';
import { Investor } from 'interfaces';
import { getInvestorsDoneShareExchanges } from 'modules/investors/action';
import { selectInvestorsData } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import InvestorsTableRow from '../../../investors/InvestorsTableRow';
import InvestorDoneShareExchangesSnippetNoInfo from './InvestorDoneShareExchangesSnippetNoInfo';

const InvestorsDoneShareExchangesSnippet = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { list, isLoading } = useAppSelector(selectInvestorsData);
  const [sortedList, setSortedList] = useState<Investor[]>();

  const onRowClick = (id: number) => () => navigate(`${ROUTES.admin}${ROUTES.investor}`.replace(':id', id.toString()));

  useEffect(() => {
    dispatch(getInvestorsDoneShareExchanges());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      setSortedList([...list].sort((a, b) => (b.noDoneSEs ?? 0) - (a.noDoneSEs ?? 0)));
    }
  }, [list]);

  return (
    <SnippetLayout
      title={INVESTORS_DONE_SHARE_EXCHANGES_SNIPPET_TITLE}
      className='self-start col-span-full lg:col-span-3 1xl:col-span-2'
    >
      <Table columns={INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS} className='max-h-44'>
        <>
          {!isLoading && sortedList?.length ? (
            sortedList.map((investor) => (
              <InvestorsTableRow key={investor.id} investor={investor} onRowClick={onRowClick} withDoneShareExchanges />
            ))
          ) : isLoading ? (
            <TableSkeleton columnsNumber={INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS.length} rowsNumber={3} />
          ) : (
            <InvestorDoneShareExchangesSnippetNoInfo />
          )}
        </>
      </Table>
    </SnippetLayout>
  );
};

export default InvestorsDoneShareExchangesSnippet;
