import React, { useMemo } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as OnlineSvg } from 'assets/svg/dot.svg';
import { ColoredLabelVariants } from 'constants/shared';
import { ColoredLabel } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getUserLastActivity } from 'utils';

interface Props {
  online: boolean;
  lastActivity?: string | null;
  className?: string;
}

const UserOnlineStatusLabel = ({ online, lastActivity, className }: Props) => {
  const { label: lastOnlineValue, isFullValue } = useMemo(
    () => getUserLastActivity(lastActivity, online),
    [lastActivity, online],
  );

  if (isFullValue) return <Typography>{lastOnlineValue}</Typography>;

  return (
    <ColoredLabel
      className={twMerge('flex justify-center items-center', className)}
      variant={online ? ColoredLabelVariants.SECONDARY_SUCCESS : ColoredLabelVariants.DISABLED}
    >
      <OnlineSvg className={cn('mr-1', { '[&_circle]:fill-grey-800': !online })} />
      {lastOnlineValue}
    </ColoredLabel>
  );
};

export default UserOnlineStatusLabel;
