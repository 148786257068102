import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { QUALIFICATION_QUESTIONS_DEFAULT_STATE } from 'constants/my-investments';
import { InvestmentsState } from 'interfaces';

import { INVESTMENTS_SLICE_NAME } from './action';
import {
  addInvestmentReducer,
  getCompaniesGraphReducer,
  getIndustriesGraphReducer,
  getInvestmentsReducer,
  getCompaniesWithInvestmentsReducer,
  getLinkedInInvestmentsReducer,
  uploadInvestmentsReducer,
  deleteReviewInvestmentReducer,
  updateReviewInvestmentReducer,
  resetReviewInvestmentsReducer,
  saveReviewedInvestmentsReducer,
  getQualificationQuestionsReducer,
  submitShareExchangeAnswersReducer,
  deleteInvestmentReducer,
  cancelShareExchangeAnswersReducer,
  editInvestmentReducer,
} from './reducers';

const initialState: InvestmentsState = {
  companiesWithInvestments: [],
  allInvestments: [],
  reviewInvestments: [],
  linkedInInvestments: {
    list: [],
    isLoading: false,
  },
  companiesGraph: {
    list: [],
    isLoading: false,
  },
  industriesGraph: {
    list: [],
    isLoading: false,
  },
  qualificationQuestions: { ...QUALIFICATION_QUESTIONS_DEFAULT_STATE },
  isActionLoading: false,
  isLoading: false,
  ...META_INITIAL_STATE,
};

export const investmentsSlice = createSlice({
  name: INVESTMENTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getInvestmentsReducer(builder);
    getCompaniesWithInvestmentsReducer(builder);
    addInvestmentReducer(builder);
    getCompaniesGraphReducer(builder);
    getIndustriesGraphReducer(builder);
    uploadInvestmentsReducer(builder);
    getLinkedInInvestmentsReducer(builder);
    deleteReviewInvestmentReducer(builder);
    updateReviewInvestmentReducer(builder);
    resetReviewInvestmentsReducer(builder);
    saveReviewedInvestmentsReducer(builder);
    getQualificationQuestionsReducer(builder);
    submitShareExchangeAnswersReducer(builder);
    deleteInvestmentReducer(builder);
    cancelShareExchangeAnswersReducer(builder);
    editInvestmentReducer(builder);
  },
});

export const investmentsReducer = investmentsSlice.reducer;
