import React, { FC, ReactNode, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { MONTH_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { ADD_INVESTMENT_FIELDS, GET_ADD_INVESTMENT_CLASS_OF_SHARES_FIELD } from 'constants/investments';
import { ButtonVariants } from 'constants/shared/button';
import { Investment, InvestmentInput, InvestmentPayload, InvestmentsFieldNames, Option } from 'interfaces';
import { getCompanyDataForInvestments } from 'modules/companies/action';
import { addInvestment } from 'modules/investments/action';
import { selectIsActionLoading } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, FormContent, CompanySelectField, CompanySelectValueContainerWithTitle } from 'shared-components';
import { removeLabelFromFormFields } from 'utils/shared';

import AddInvestmentClassOfSharesPreloader from './AddInvestmentClassOfSharesPreloader';

const DEFAULT_ADD_INVESTMENT_FORM_CLASSNAME =
  'grid grid-cols-1 xl:grid-cols-[1fr_1fr_1fr] gap-6 mt-6 whitespace-nowrap';

interface Props {
  investmentsLength?: number;
  className?: string;
  customButtons?: ReactNode;
  defaultValues?: Partial<InvestmentInput>;
  onSubmitForm?: SubmitHandler<InvestmentInput>;
  disabledLoading?: boolean;
  companySelectClassName?: string;
  customCompanySelectStyles?: StylesConfig;
  enabledCompanySelect?: boolean;
  anchorPrefix?: string;
  onSuccessAddInvestmentCallback?: (investment: Investment) => void;
  withoutLabels?: boolean;
  shouldIgnoreCompanyDataRequest?: boolean;
}

const AddInvestmentForm: FC<Props> = ({
  investmentsLength,
  className,
  customButtons,
  defaultValues,
  onSubmitForm,
  disabledLoading,
  companySelectClassName,
  customCompanySelectStyles,
  enabledCompanySelect,
  anchorPrefix,
  onSuccessAddInvestmentCallback,
  withoutLabels,
  shouldIgnoreCompanyDataRequest,
}) => {
  const dispatch = useAppDispatch();
  const isActionLoading = useAppSelector(selectIsActionLoading);

  const [classOfShares, setClassOfShares] = useState<Option[]>([]);
  const [isClassOfSharesLoading, setIsClassOfSharesLoading] = useState(false);

  const {
    register,
    formState: { errors },
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm<InvestmentInput>({
    defaultValues,
  });

  const watchTotalShares = watch(InvestmentsFieldNames.TOTAL_SHARES);
  const watchCompanyNumber = watch(InvestmentsFieldNames.COMPANY_NUMBER) as Option;
  const watchInvestmentPrice = watch(InvestmentsFieldNames.ORIGINAL_INVESTMENT_PRICE);

  const onSubmit: SubmitHandler<InvestmentInput> = (data) => {
    const { investmentDate, classOfShares, ...restValues } = data;
    const formattedInvestmentDate = investmentDate ? format(new Date(investmentDate), MONTH_FORMAT_WITH_SLASHES) : '';

    dispatch(
      addInvestment({
        ...restValues,
        investmentDate: formattedInvestmentDate,
        classOfShares: typeof classOfShares === 'string' ? classOfShares : classOfShares?.value,
      } as InvestmentPayload),
    )
      .unwrap()
      .then((result) => {
        if (onSuccessAddInvestmentCallback) {
          onSuccessAddInvestmentCallback(result);
        }
      });
  };

  useEffect(() => {
    if (investmentsLength)
      reset({
        companyNumber: '',
        totalShares: '',
        pricePerShare: '',
        investmentDate: '',
        classOfShares: '',
      });
  }, [investmentsLength, reset]);

  useEffect(() => {
    if (!watchCompanyNumber?.value || shouldIgnoreCompanyDataRequest) return;

    setIsClassOfSharesLoading(true);
    dispatch(getCompanyDataForInvestments(watchCompanyNumber?.value))
      .unwrap()
      .then((response) => {
        setClassOfShares(
          response?.fullyDilutedShareCapital.map(({ classOfShares }) => ({
            label: classOfShares,
            value: classOfShares,
          })),
        );
      })
      .catch(() => {
        setClassOfShares([]);
      })
      .finally(() => {
        setIsClassOfSharesLoading(false);
        setValue(InvestmentsFieldNames.CLASS_OF_SHARES, '');
      });
  }, [dispatch, setValue, watchCompanyNumber?.value]);

  useEffect(() => {
    const calculatedPricePerShare = String(Number(watchInvestmentPrice) / Number(watchTotalShares));

    setValue(InvestmentsFieldNames.PRICE_PER_SHARE, calculatedPricePerShare);
  }, [setValue, watchInvestmentPrice, watchTotalShares]);

  return (
    <form
      onSubmit={handleSubmit(onSubmitForm || onSubmit)}
      className={twMerge(DEFAULT_ADD_INVESTMENT_FORM_CLASSNAME, className)}
    >
      <CompanySelectField
        className={twMerge('col-start-1 col-end-2 mb-0', companySelectClassName)}
        name={InvestmentsFieldNames.COMPANY_NUMBER}
        placeholder='Company name/Company number'
        label={!withoutLabels ? 'Company Name*' : ''}
        disabled={enabledCompanySelect ? false : withoutLabels}
        control={control}
        selectStyles={customCompanySelectStyles}
        description='Minimum of 5 characters required for search'
        components={{ ValueContainer: CompanySelectValueContainerWithTitle }}
      />

      {!isClassOfSharesLoading ? (
        <FormContent
          anchorPrefix={anchorPrefix}
          control={control}
          fields={removeLabelFromFormFields(
            [GET_ADD_INVESTMENT_CLASS_OF_SHARES_FIELD(!!classOfShares?.length, classOfShares || [])],
            !withoutLabels,
          )}
          errors={errors}
          register={register}
        />
      ) : (
        <AddInvestmentClassOfSharesPreloader withoutLabel={withoutLabels} />
      )}

      <FormContent
        anchorPrefix={anchorPrefix}
        control={control}
        fields={removeLabelFromFormFields(ADD_INVESTMENT_FIELDS, !withoutLabels)}
        errors={errors}
        register={register}
      />

      <div>
        {customButtons || (
          <Button className='w-fit' variant={ButtonVariants.PRIMARY} isLoading={isActionLoading && !disabledLoading}>
            Add Investment <PlusIcon className='ml-2' />
          </Button>
        )}
      </div>
    </form>
  );
};

export default AddInvestmentForm;
