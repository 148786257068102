import { createAsyncThunk } from '@reduxjs/toolkit';

import { notificationsApi } from 'apis';
import { GetListPayload } from 'interfaces';

import { showServerError } from '../../helpers';
import { USERS_SLICE_NAME } from '../users/action';

export const NOTIFICATIONS_SLICE_NAME = 'notifications';

export const getNotifications = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/getNotifications`,
  async (data: GetListPayload) => {
    const response = await notificationsApi.getNotifications(data);
    return response.data;
  },
);

export const updateNotification = createAsyncThunk(
  `${USERS_SLICE_NAME}/updateNotification`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await notificationsApi.updateUserNotification(id);

      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
