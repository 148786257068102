import React from 'react';

import { useNavigate } from 'react-router-dom';

import { StepperStatuses, stepperStatusesLabels } from 'constants/shared/stepper';
import useWelcomeUser from 'hooks/useWelcomeUser';
import { ColoredLabel } from 'shared-components';
import Stepper from 'shared-components/stepper-v1/Stepper';
import StepperItem from 'shared-components/stepper-v1/StepperItem';
import StepperItemCircle from 'shared-components/stepper-v1/StepperItemCircle';
import StepperItemDetails from 'shared-components/stepper-v1/StepperItemDetails';
import StepperSkeleton from 'shared-components/stepper-v1/StepperSkeleton';

interface Props {
  onClose?: VoidFunction;
}

const WelcomeUserContent = ({ onClose }: Props) => {
  const navigate = useNavigate();

  const { steps, isLoading } = useWelcomeUser();

  return (
    <div>
      <Stepper
        steps={steps}
        isLoading={isLoading}
        renderContent={({ isActive, link, ...stepDetails }) => (
          <StepperItem
            key={stepDetails.step}
            onClick={() => {
              if (link) {
                navigate(link);
              }

              onClose?.();
            }}
            isActive={isActive}
            renderStepContent={({ isActive }) => (
              <StepperItemCircle status={isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING} />
            )}
          >
            <StepperItemDetails {...stepDetails}>
              <ColoredLabel
                className='mt-4'
                {...stepperStatusesLabels[isActive ? StepperStatuses.SUCCESS : StepperStatuses.PENDING]}
              />
            </StepperItemDetails>
          </StepperItem>
        )}
        renderSkeleton={() => <StepperSkeleton count={3} withDetails />}
      />
    </div>
  );
};

export default WelcomeUserContent;
