import React, { FC, ReactNode } from 'react';

import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left.svg';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { Button, Constraint } from 'shared-components';

import DashboardLayout from '../dashboard/DashboardLayout';
import ShareExchangeReviewPageSkeleton from './ShareExchangeReviewPageSkeleton';

type Props = {
  onClickBack: VoidFunction;
  isLoading: boolean;
  children: ReactNode;
};

const ShareExchangeReviewLayout: FC<Props> = ({ isLoading, children, onClickBack }) => {
  return (
    <DashboardLayout>
      <Button className='w-fit mt-4 ml-4 sm:ml-10' onClick={onClickBack} variant={ButtonVariants.SECONDARY}>
        <ArrowLeftIcon className='mr-2' /> Back
      </Button>

      <Constraint className='mt-5' variant={ConstraintVariants.FULL_ROUNDED}>
        {isLoading ? <ShareExchangeReviewPageSkeleton /> : children}
      </Constraint>
    </DashboardLayout>
  );
};

export default ShareExchangeReviewLayout;
