import React, { useEffect } from 'react';

import { BUSINESS_SETTINGS_FEE_TABLE_COLUMNS } from 'constants/business-settings';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getAllFees } from 'modules/fees/action';
import { selectFeesListData } from 'modules/fees/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import FeesTableNoAdded from './FeesTableNoAdded';
import FeesTableRow from './FeesTableRow';

export const FEES_PER_PAGE = 8;

const FeesTable = () => {
  const dispatch = useAppDispatch();

  const { fees, isLoading, ...paginationData } = useAppSelector(selectFeesListData);

  const { page, setPage } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  useEffect(() => {
    dispatch(getAllFees({ page, per_page: FEES_PER_PAGE, sort: sorting.column }));
  }, [dispatch, page, sorting.column]);

  return (
    <>
      <Table
        onUpdateSorting={updateSorting}
        className='table-simplified-tr table-simplified-th'
        columns={BUSINESS_SETTINGS_FEE_TABLE_COLUMNS}
      >
        {fees?.length && !isLoading ? (
          fees.map((fee) => <FeesTableRow key={fee.id} {...fee} />)
        ) : isLoading ? (
          <TableSkeleton columnsNumber={BUSINESS_SETTINGS_FEE_TABLE_COLUMNS?.length} rowsNumber={3} />
        ) : (
          <FeesTableNoAdded />
        )}
      </Table>
      <Pagination currentPage={page} updatePage={setPage} loading={isLoading} {...paginationData} />
    </>
  );
};

export default FeesTable;
