import React from 'react';

import { Link } from 'react-router-dom';

import { PRIVACY_POLICY_LABEL, PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LABEL } from 'constants/privacy-policy';
import { ROUTES } from 'constants/routes';

const LINK_CLASSNAME = 'text-[10px] text-white flex items-center';

const SidebarPrivacyPolicy = () => {
  return (
    <div className='flex justify-center mt-10 mb-[-80px]'>
      <a className={LINK_CLASSNAME} href={PRIVACY_POLICY_LINK} target='_blank' rel='noreferrer'>
        {PRIVACY_POLICY_LABEL}
      </a>
      <p className='whitespace-pre text-[10px] text-grey'> - </p>
      <Link className={LINK_CLASSNAME} to={ROUTES.termsAndConditions} target='_blank' rel='noreferrer'>
        {TERMS_AND_CONDITIONS_LABEL}
      </Link>
    </div>
  );
};

export default SidebarPrivacyPolicy;
