import { useState } from 'react';

const useSignificantControlCompanyEdit = () => {
  const [isEditing, setIsEditing] = useState(false);

  const setEditingMode = () => {
    setIsEditing(true);
  };

  const unsetEditingMode = () => {
    setIsEditing(false);
  };

  return { isEditing, setEditingMode, unsetEditingMode };
};

export default useSignificantControlCompanyEdit;
