import React, { useEffect } from 'react';

import { NOTIFICATIONS_TABLE_COLUMNS } from 'constants/notifications';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getNotifications } from 'modules/notifications/action';
import { selectNotificationsData } from 'modules/notifications/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import NotificationsTableRow from './NotificationsTableRow';

const NOTIFICATIONS_PER_PAGE = 7;

const NotificationsTable = () => {
  const dispatch = useAppDispatch();

  const { page, setPage, query } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();
  const { filter } = useFilterParameters();

  const { list: notifications, isLoading, ...investorsPaginationData } = useAppSelector(selectNotificationsData);

  useEffect(() => {
    dispatch(
      getNotifications({
        page,
        per_page: NOTIFICATIONS_PER_PAGE,
        query,
        order: sorting.order,
        sort: sorting.column,
        filter,
      }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column, filter]);
  return (
    <div className='flex flex-col'>
      <Table className='whitespace-normal' onUpdateSorting={updateSorting} columns={NOTIFICATIONS_TABLE_COLUMNS}>
        {isLoading ? (
          <TableSkeleton rowsNumber={NOTIFICATIONS_PER_PAGE} columnsNumber={NOTIFICATIONS_TABLE_COLUMNS.length} />
        ) : (
          notifications &&
          notifications.map((notification) => (
            <NotificationsTableRow key={notification.id} notification={notification} />
          ))
        )}
      </Table>

      <Pagination currentPage={page} updatePage={setPage} {...investorsPaginationData} />
    </div>
  );
};

export default NotificationsTable;
