import { createSlice } from '@reduxjs/toolkit';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { CompanyIac, CompanyIacDeal, CompanyIacState } from 'interfaces';

import { COMPANY_IAC_SLICE_NAME } from './action';
import {
  createCustomFieldReducer,
  removeCompanyIacEditingSectionsReducer,
  setCompanyIacReducer,
  setCompanyIacEditingSectionsReducer,
  setIsCustomFieldsInCreateModeReducer,
  getFundsReducer,
  companyIacSetDealReducer,
  companyIacSetCompanyIacHasDealParameters,
  setPartialIacReducer,
  signCompanyIacReducer,
} from './reducers';

export const initialState: CompanyIacState = {
  companyIac: {} as CompanyIac,
  deal: {} as CompanyIacDeal,
  customFields: {
    [InvestmentProposalBlockNames.OVERVIEW]: [],
    [InvestmentProposalBlockNames.SUMMARY]: [],
    [InvestmentProposalBlockNames.BUSINESS_REVIEW]: [],
  },
  editingSections: [],
  customFieldsInCreateMode: {
    [InvestmentProposalBlockNames.OVERVIEW]: false,
    [InvestmentProposalBlockNames.SUMMARY]: false,
    [InvestmentProposalBlockNames.BUSINESS_REVIEW]: false,
  },
  funds: [],
};

const companyIacSlice = createSlice({
  name: COMPANY_IAC_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCustomFieldReducer(builder);
    setCompanyIacEditingSectionsReducer(builder);
    removeCompanyIacEditingSectionsReducer(builder);
    setIsCustomFieldsInCreateModeReducer(builder);
    setCompanyIacReducer(builder);
    getFundsReducer(builder);
    companyIacSetDealReducer(builder);
    companyIacSetCompanyIacHasDealParameters(builder);
    setPartialIacReducer(builder);
    signCompanyIacReducer(builder);
  },
});

export const companyIacReducer = companyIacSlice.reducer;
