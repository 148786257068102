import React, { FC } from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import { ModalWindow } from 'shared-components';
import Typography from 'shared-components/Typography';

import WelcomeUserContent from '../dashboard-snippets/welcome-user-snippet/WelcomeUserContent';
import WelcomeInvestorModalActions from './WelcomeInvestorModalActions';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  firstName?: string;
};

const WelcomeInvestorModal: FC<Props> = ({ isOpen, onClose, firstName }) => {
  return (
    <ModalWindow className='min-w-[70%] 2xl:min-w-[1048px]' isOpen={isOpen} onClose={onClose}>
      <Typography variant={TypographyVariants.LARGE}>Welcome to planD, {firstName}</Typography>

      <div className='my-6'>
        <WelcomeUserContent />
      </div>

      <WelcomeInvestorModalActions onClose={onClose} />
    </ModalWindow>
  );
};

export default WelcomeInvestorModal;
