import React, { FC, useMemo, useState } from 'react';

import { getMyCompaniesWithInvestmentsTableColumns } from 'constants/investments';
import useDeleteInvestment from 'hooks/useDeleteInvestment';
import {
  CompanyWithInvestments,
  HandleClickRequestShareExchangeType,
  InvestmentStatuses,
  TableSorting,
  UpdateSortingFunctionType,
} from 'interfaces';
import { AlertModal } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';
import {
  filterCompanyWithInvestmentsByInvestmentStatus,
  getFormattedCompanyWithInvestmentsWithDaxia,
} from 'utils/investments';

import NoInvestmentsAdded from '../investments-table/NoInvestmentsAdded';
import MyCompaniesWithInvestmentsTableItem from './MyCompaniesWithInvestmentsTableItem';

type Props = {
  companiesWithInvestments: CompanyWithInvestments[];
  updateSorting: UpdateSortingFunctionType;
  companyNameLength?: number;
  isLoading: boolean;
  handleClickRequestShareExchange: HandleClickRequestShareExchangeType;
  onSuccessEditInvestment: VoidFunction;
  sorting: TableSorting;
};

const MyCompaniesWithInvestmentsTable: FC<Props> = ({
  companiesWithInvestments,
  updateSorting,
  isLoading,
  companyNameLength,
  handleClickRequestShareExchange,
  onSuccessEditInvestment,
  sorting,
}) => {
  const [editId, setEditId] = useState<null | string>(null);

  const filteredCompaniesWithInvestments = useMemo(() => {
    const filteredCompanyWithInvestments = filterCompanyWithInvestmentsByInvestmentStatus(
      companiesWithInvestments,
      InvestmentStatuses.EXCHANGED,
    );

    const companiesWithWithReplacedDaxiaInvestments =
      getFormattedCompanyWithInvestmentsWithDaxia(filteredCompanyWithInvestments);

    return filterCompanyWithInvestmentsByInvestmentStatus(
      companiesWithWithReplacedDaxiaInvestments as CompanyWithInvestments[],
      InvestmentStatuses.EXCHANGED,
    );
  }, [companiesWithInvestments]);

  const {
    isOpen: isDeleteInvestmentModalOpen,
    isLoading: isDeleting,
    changeDeletingInvestment,
    onDeleteInvestment,
    resetDeletingInvestment,
    modalTitle,
  } = useDeleteInvestment();

  return (
    <>
      <AlertModal
        isOpen={isDeleteInvestmentModalOpen}
        isLoading={isDeleting}
        title={modalTitle}
        onSubmit={onDeleteInvestment}
        onClose={resetDeletingInvestment}
        submitButtonText='Yes'
        backButtonText='No'
      />

      <Table columns={getMyCompaniesWithInvestmentsTableColumns()} onUpdateSorting={updateSorting} sorting={sorting}>
        {isLoading && (
          <TableSkeleton rowsNumber={3} columnsNumber={getMyCompaniesWithInvestmentsTableColumns().length} />
        )}

        {!isLoading && !filteredCompaniesWithInvestments.length && <NoInvestmentsAdded />}

        {!isLoading && !!filteredCompaniesWithInvestments.length && (
          <>
            {filteredCompaniesWithInvestments.map((companyWithInvestments) => {
              const { seId } = companyWithInvestments;
              return companyWithInvestments.investments.map((investment, index) => (
                <MyCompaniesWithInvestmentsTableItem
                  key={investment.id}
                  investment={investment}
                  isFirstElement={index === 0}
                  setEditId={setEditId}
                  companyId={companyWithInvestments.id}
                  companyNumber={companyWithInvestments.companyNumber}
                  companyName={
                    companyNameLength ? investment?.companyName?.slice(0, companyNameLength) : investment?.companyName
                  }
                  companyActiveShareExchange={companyWithInvestments.activeShareExchangeRequest}
                  disabledActions={!!companyWithInvestments.activeShareExchangeRequest}
                  setDeletingInvestment={changeDeletingInvestment}
                  isEditing={editId === investment.id}
                  handleClickRequestShareExchange={handleClickRequestShareExchange}
                  onSuccessEditInvestment={onSuccessEditInvestment}
                  declineReason={companyWithInvestments?.declineReason}
                  isActiveExchange={companyWithInvestments?.isActiveExchange}
                  shareExchangeRequestId={companyWithInvestments?.shareExchangeRequestId}
                  serDeclined={companyWithInvestments?.serDeclined}
                  userCompanySEStatus={companyWithInvestments?.userCompanySEStatus}
                  draftDealId={seId}
                />
              ));
            })}
          </>
        )}
      </Table>
    </>
  );
};

export default MyCompaniesWithInvestmentsTable;
