import React, { FC, useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { COMPANY_IAC_DEAL_PARAMETERS_FORM_FIELDS } from 'constants/company-iac';
import { CompanyIacStartDealFormState } from 'interfaces';
import { createCompanyIacDealParameters } from 'modules/company-iac/action';
import {
  selectCompanyIacDealParametersCompanyData,
  selectCompanyIacDealParameters,
} from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, ModalWindow } from 'shared-components';

import DealParametersForm from '../../deals/deal-parameters/DealParametersForm';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  companyId?: string;
  onSuccessCallback?: VoidFunction;
};

const CompanyIacStartDealModal: FC<Props> = ({ isOpen, onClose, companyId, onSuccessCallback }) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { companyNumber, companyName } = useAppSelector(selectCompanyIacDealParametersCompanyData);
  const dealParameters = useAppSelector(selectCompanyIacDealParameters);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CompanyIacStartDealFormState>();

  const onSubmit: SubmitHandler<CompanyIacStartDealFormState> = ({ feeMinimum, feeRate }) => {
    if (!companyId) {
      return;
    }

    setIsLoading(true);

    dispatch(
      createCompanyIacDealParameters({
        id: companyId,
        feeMinimum: parseFloat(feeMinimum),
        feeRate: parseFloat(feeRate),
      }),
    )
      .unwrap()
      .then(() => {
        if (onSuccessCallback) {
          onSuccessCallback();
        }
        onClose();
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!dealParameters) {
      return;
    }

    reset({ ...dealParameters });
  }, [reset, dealParameters]);

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onClose}
      className='lg:w-8/12 max-h-[85vh]'
      wrapperClassName='lg:px-12 lg:py-10'
      contentClassName='flex flex-col'
    >
      <DealParametersForm
        legalEntity={companyName}
        companyNumber={companyNumber}
        register={register}
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        sharesFields={dealParameters?.companySharePrice}
        commercialParametersFields={COMPANY_IAC_DEAL_PARAMETERS_FORM_FIELDS}
        hideSettingsSection
        isSharesEditable={false}
      />

      <Button onClick={handleSubmit(onSubmit)} isLoading={isLoading} className='w-9/12 self-center mt-15'>
        Send to Plainly
      </Button>
    </ModalWindow>
  );
};

export default CompanyIacStartDealModal;
