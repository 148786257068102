import React, { FC } from 'react';

import { DEAL_DETAILS_SHARE_EXCHANGES_TABLE_COLUMNS } from 'constants/deals';
import { DealShareExchangeDetails } from 'interfaces';
import Table, { TableSkeleton } from 'shared-components/table';

import ShareExchangeDetailsNoItems from './ShareExchangeDetailsNoItems';
import ShareExchangeDetailsTableRow from './ShareExchangeDetailsTableRow';

type Props = {
  isLoading: boolean;
  shareExchangeDetails?: DealShareExchangeDetails[];
  closingDate?: string;
};

const ShareExchangeDetailsTable: FC<Props> = ({ isLoading, shareExchangeDetails, closingDate }) => {
  return (
    <Table columns={DEAL_DETAILS_SHARE_EXCHANGES_TABLE_COLUMNS}>
      {isLoading && <TableSkeleton rowsNumber={3} columnsNumber={DEAL_DETAILS_SHARE_EXCHANGES_TABLE_COLUMNS.length} />}

      {!isLoading && !shareExchangeDetails?.length && (
        <ShareExchangeDetailsNoItems columnsLength={DEAL_DETAILS_SHARE_EXCHANGES_TABLE_COLUMNS.length} />
      )}

      {!isLoading && !!shareExchangeDetails?.length && (
        <>
          {shareExchangeDetails.map((shareExchangeDetail, index) => (
            <ShareExchangeDetailsTableRow
              key={shareExchangeDetail.id}
              isLast={index === shareExchangeDetails?.length - 1}
              {...shareExchangeDetail}
              closingDate={shareExchangeDetail?.closingDate || closingDate}
            />
          ))}
        </>
      )}
    </Table>
  );
};

export default ShareExchangeDetailsTable;
