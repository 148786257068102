import { ChangeEvent, useState } from 'react';

import { FieldErrors, useForm, UseFormHandleSubmit, UseFormRegister, UseFormReset } from 'react-hook-form';

import { CompanyIacCustomField, CompanyIacCustomFieldFormValues } from 'interfaces';
import { checkIfValuesTheSame } from 'utils';
import { prepareCustomFieldsForUpload } from 'utils/companies-iac';

export interface UseCompanyIacCustomFieldsReturnValues {
  registerCustomFields: UseFormRegister<CompanyIacCustomFieldFormValues>;
  handleSaveEditionCustomField: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
  handleSubmitCustomFieldsForm: UseFormHandleSubmit<CompanyIacCustomFieldFormValues>;
  onDeleteCustomField: (fieldId: number) => void;
  deletedFields: number[];
  reset: UseFormReset<CompanyIacCustomFieldFormValues>;
  resetDeletedFields: VoidFunction;
  customFieldsErrors: FieldErrors<CompanyIacCustomFieldFormValues>;
}

const useCompanyIacCustomFields = (
  customFields?: CompanyIacCustomField[],
  updateFormFields?: (payload: CompanyIacCustomField[]) => void,
): UseCompanyIacCustomFieldsReturnValues => {
  const {
    register,
    trigger,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CompanyIacCustomFieldFormValues>();
  const [deletedFields, setDeletedFields] = useState<number[]>([]);

  const handleSaveEditionCustomField = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!customFields || !updateFormFields) return;

    const name: string = event.target.name;
    const currentValue = customFields?.find((field) => field.name === name);
    const isValidatedCorrectly = await trigger(name);
    const fieldValue = getValues(name);
    const isValueNotUpdated = checkIfValuesTheSame(fieldValue, currentValue?.value);

    if (!isValidatedCorrectly || isValueNotUpdated || (!fieldValue && !currentValue)) {
      return;
    }

    const customFieldValue = { [name]: fieldValue };

    updateFormFields(prepareCustomFieldsForUpload(customFields, customFieldValue));
  };

  const onDeleteCustomField = (fieldId: number) => {
    if (customFields && updateFormFields) {
      const updatedCustomFields = customFields?.filter((field) => fieldId === field.id);

      updateFormFields(updatedCustomFields);
    }

    setDeletedFields((prevState) => [...prevState, fieldId]);
  };

  const resetDeletedFields = () => setDeletedFields([]);

  return {
    registerCustomFields: register,
    handleSaveEditionCustomField,
    handleSubmitCustomFieldsForm: handleSubmit,
    onDeleteCustomField,
    deletedFields,
    resetDeletedFields,
    reset,
    customFieldsErrors: errors,
  };
};

export default useCompanyIacCustomFields;
