import { useEffect, useState } from 'react';

import { Control, FieldErrors, useForm, UseFormRegister } from 'react-hook-form';

import { DueDiligenceSections } from 'constants/due-diligence';
import { MonthsCashRunwayInput } from 'interfaces';
import { createOrUpdateCompanyRunway } from 'modules/due-diligence/action';
import { selectCompanyRunway } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import useDueDiligenceEdit from './use-due-diligence-edit/useDueDiligenceEdit';

const COMPANY_RUNWAY_DEFAULT_VALUES = {
  lastYearRevenue: '',
  currentYearRevenue: '',
  nextYearRevenue: '',
  otherDetails: '',
  cashRunway: '',
};

export interface UseMonthsCashRunwayReturnValues {
  isEditing: boolean;
  isLoading: boolean;
  handleSaveField: (name: keyof MonthsCashRunwayInput) => void;
  handleDiscardChanges: VoidFunction;
  register: UseFormRegister<MonthsCashRunwayInput>;
  control: Control<MonthsCashRunwayInput>;
  errors: FieldErrors<MonthsCashRunwayInput>;
}

const useDueDiligenceMonthsCashRunway = (): UseMonthsCashRunwayReturnValues => {
  const [isLoading, setIsLoading] = useState(false);
  const { isEditing, handleCancel } = useDueDiligenceEdit(DueDiligenceSections.MONTHS_CASH_RUNWAY_BLOCK);

  const dispatch = useAppDispatch();
  const { id, companyRunway } = useAppSelector(selectCompanyRunway) || {};

  const {
    register,
    control,
    trigger,
    reset,
    getValues,
    formState: { errors },
  } = useForm<MonthsCashRunwayInput>({
    defaultValues: COMPANY_RUNWAY_DEFAULT_VALUES,
    reValidateMode: 'onChange',
  });

  const handleSaveField = async (name: keyof MonthsCashRunwayInput) => {
    const isValidatedCorrectly = await trigger(name as keyof MonthsCashRunwayInput);

    const fieldValue = getValues(name as keyof MonthsCashRunwayInput);

    const isOldValue = fieldValue && companyRunway && fieldValue === (companyRunway as MonthsCashRunwayInput)[name];

    if (!isValidatedCorrectly || !fieldValue || isOldValue || !id) return;

    setIsLoading(true);
    dispatch(
      createOrUpdateCompanyRunway({
        id,
        [name]: fieldValue,
      }),
    )
      .unwrap()
      .then(handleCancel)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDiscardChanges = () => {
    handleCancel();
    reset(companyRunway);
  };

  useEffect(() => {
    if (!Object.keys(companyRunway || {})?.length) {
      reset(COMPANY_RUNWAY_DEFAULT_VALUES);
      return;
    }

    reset(companyRunway);
  }, [id, companyRunway, reset]);

  return {
    isEditing,
    isLoading,
    handleSaveField,
    handleDiscardChanges,
    register,
    control,
    errors,
  };
};

export default useDueDiligenceMonthsCashRunway;
