import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { ChartInfoListSkeleton } from 'shared-components/chart';

interface Props {
  isLoading: boolean;
  children: ReactNode;
  length?: number;
  className?: string;
}

const ChartMetricsLayout: FC<Props> = ({ isLoading, children, length = 2, className }) => {
  return (
    <div className={twMerge('w-full flex justify-center space-x-6', className)}>
      {isLoading ? <ChartInfoListSkeleton length={length} className='w-full' /> : <>{children}</>}
    </div>
  );
};

export default ChartMetricsLayout;
