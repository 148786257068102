import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { TypographyVariants } from 'constants/shared/typography';

import Typography from './Typography';

export const DYNAMIC_PAGE_TITLE_TEST_ID = 'DYNAMIC_PAGE_TITLE_TEST_ID';
export const DYNAMIC_PAGE_TITLE_IS_LOADING_TEST_ID = 'DYNAMIC_PAGE_TITLE_IS_LOADING_TEST_ID';

interface Props {
  dynamicTitle?: string;
  title: string | ReactNode;
  isLoading?: boolean;
  className?: string;
}

const DynamicPageTitle: FC<Props> = ({ dynamicTitle, title, isLoading, className }) => {
  if (isLoading)
    return (
      <div
        className={twMerge('h-10 w-200 xl:w-350 skeleton-loader', className)}
        data-testid={DYNAMIC_PAGE_TITLE_IS_LOADING_TEST_ID}
      />
    );

  if (dynamicTitle)
    return (
      <Typography className={className} variant={TypographyVariants.MEDIUM}>
        <span className='text-violet-600' data-testid={DYNAMIC_PAGE_TITLE_TEST_ID}>
          {dynamicTitle}{' '}
        </span>
        {title}
      </Typography>
    );

  return (
    <Typography className={className} variant={TypographyVariants.MEDIUM}>
      {title}
    </Typography>
  );
};

export default DynamicPageTitle;
