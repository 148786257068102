import React, { FC, memo } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { LOCALE_STRING_FORMAT } from 'constants/global';
import { BILL_STATUS_COLORED_LABEL_STYLE, BillStatuses, PayBillModalTypes } from 'constants/my-bills';
import { errorNotify } from 'helpers';
import { Bill } from 'interfaces';
import { ButtonDropdown, Checkbox, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { openUrlInSeparateTab } from 'utils';

const DEFAULT_AVAILABLE_BILL_KEYS = ['invoiceNumber', 'invoiceReference', 'dealId', 'companyName'];

interface Props {
  checked: boolean;
  handleSelectBill: (bill: Bill) => void;
  showBillModal: (modalType: PayBillModalTypes, bill: Bill) => void;
  bill: Bill;
}

const BillsTableItem: FC<Props> = ({ checked, handleSelectBill, bill }) => {
  const { id, status, dateCreated, totalAmount, ...restBill } = bill;
  const billValues = { id, ...restBill };

  const onClickPayBill = () => {
    if (bill.invoiceUrl) {
      openUrlInSeparateTab(bill.invoiceUrl);
    } else {
      errorNotify('The invoice has been already paid');
    }
  };

  const getBillActions = () => {
    return [
      {
        title: bill.status == 'paid' ? 'View Invoice' : 'View and Pay',
        id: 'viewAndPayBill',
        action: onClickPayBill,
      },
    ];
  };

  return (
    <TableRow>
      <TableData>
        <Checkbox
          disabled={bill.status !== BillStatuses.PAYMENT_REQUESTED}
          checked={checked}
          onChange={() => handleSelectBill(bill)}
        />
      </TableData>
      <TableData className='pl-1'>
        <ButtonDropdown className='w-40' actions={getBillActions()} />
      </TableData>
      {DEFAULT_AVAILABLE_BILL_KEYS.map((i) => {
        return <TableData key={i}>{(billValues as Record<string, string | number>)[i]}</TableData>;
      })}
      <TableData>{totalAmount?.toLocaleString(LOCALE_STRING_FORMAT)}</TableData>
      <TableData>{format(parseISO(dateCreated), DAY_FORMAT_WITH_SLASHES)}</TableData>
      <TableData>
        <ColoredLabel {...BILL_STATUS_COLORED_LABEL_STYLE[status]} />
      </TableData>
    </TableRow>
  );
};

export default memo(BillsTableItem);
