import React, { useState, useEffect, useMemo } from 'react';

import { unwrapResult } from '@reduxjs/toolkit';
import cn from 'classnames';
import qs from 'query-string';
import { UseFormReset } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  DOCUMENTS_PAGE_TITLE,
  UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES_TEXT,
  UPLOAD_DOCUMENTS_DRAG_HEAD_TITLE,
  UPLOAD_DOCUMENTS_MODAL_TITLE,
  UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES,
  UPLOAD_DOCUMENTS_MAX_FILE_SIZE,
  DocumentTypes,
  DOCUMENTS_PAGE_DESCRIPTION,
  DOCUMENTS_FOUNDER_DESCRIPTION,
  UPLOAD_DOCUMENTS_INITIAL_VALUES,
} from 'constants/documents';
import { UserTypes } from 'constants/user';
import useFilterParameters from 'hooks/use-filter-parameters/useFilterParameters';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import useDebouncedInputChange from 'hooks/useDebouncedInputChange';
import { Company, UploadDocumentInput, UploadDocumentPayload } from 'interfaces';
import { getShortCompanyInfo } from 'modules/companies/action';
import { selectUserType } from 'modules/current-user/selectors';
import { getDocuments, uploadDocument } from 'modules/documents/action';
import { selectDocumentsPaginationData } from 'modules/documents/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { TitleWithSearch, UploadDocumentsInputs } from 'page-components';
import { UploadFileModal, UploadFileForm, DynamicPageTitle, Pagination } from 'shared-components';
import { checkIsAdmin, checkIsFmUser, getUserFiltersQuery } from 'utils';

import { TypographyStyles } from '../../constants/shared/typography';
import DocumentsPageFilter from './DocumentsPageFilter';
import LocalDocumentsTable from './LocalDocumentsTable';

const DOCUMENTS_PER_PAGE = 8;

const DocumentsTab = () => {
  const dispatch = useAppDispatch();

  const userType = useAppSelector(selectUserType);
  const documentsPaginationData = useAppSelector(selectDocumentsPaginationData);

  const location = useLocation();

  const { companyName = '' } = (location?.state as Pick<Company, 'companyName'>) || {};

  const { onOpen: onOpenUploadModal, onClose: onCloseUploadModal, ...uploadModalProps } = useModal();
  const { filter, filterValue } = useFilterParameters();

  const { search } = useLocation();
  const { filterDocumentType, filterUserId } = qs.parse(search);

  const { page, setPage } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  const [query, setQuery] = useState('');
  const [isDocumentsUploading, setIsDocumentsUploading] = useState(false);
  const [dynamicPageTitle, setDynamicPageTitle] = useState('');

  const debouncedUpdateInput = useDebouncedInputChange(setQuery);

  const isAdmin = useMemo(() => checkIsAdmin(), []);
  const isFmUser = useMemo(() => checkIsFmUser(), []);

  const pageDescription = useMemo(() => {
    if (userType === UserTypes.INVESTOR) {
      return DOCUMENTS_PAGE_DESCRIPTION;
    }

    if (userType === UserTypes.FOUNDER) {
      return DOCUMENTS_FOUNDER_DESCRIPTION;
    }

    return '';
  }, [userType]);

  const onSubmitUpload = async (
    files: File[],
    data?: UploadDocumentInput,
    resetForm?: UseFormReset<UploadDocumentInput>,
  ) => {
    if (!data) {
      return;
    }

    setIsDocumentsUploading(true);

    try {
      await dispatch(
        uploadDocument({
          documentType: DocumentTypes.OTHER,
          companyId: data?.company?.value || filterValue,
          documentFile: files[0],
          isAdmin,
          notifyClassName: filterValue ? 'top-10' : 'top-24',
        } as UploadDocumentPayload),
      ).unwrap();

      if (resetForm) {
        resetForm();
      }

      onCloseUploadModal();
      setIsDocumentsUploading(false);
      dispatch(getDocuments({ page: 1, per_page: DOCUMENTS_PER_PAGE, query, userType, filter }));
    } catch (err) {
      setIsDocumentsUploading(false);
    }
  };

  useEffect(() => {
    setDynamicPageTitle('');
    if (filterValue && !checkIsFmUser()) {
      dispatch(getShortCompanyInfo(filterValue as string))
        .then(unwrapResult)
        .then((response) => {
          setDynamicPageTitle(response?.companyName || '');
        });
    }
  }, [filterValue, dispatch, isAdmin]);

  useEffect(() => {
    dispatch(
      getDocuments({
        page,
        per_page: DOCUMENTS_PER_PAGE,
        userType,
        order: sorting.order,
        sort: sorting.column,
        ...(query ? { query } : {}),
        filter: `${filter || ''}&${getUserFiltersQuery({
          documentType: filterDocumentType,
          user_id: filterUserId,
        })}`.replace(/&$/, ''),
      }),
    );
  }, [dispatch, filter, page, query, userType, filterUserId, filterDocumentType, sorting.order, sorting.column]);

  return (
    <>
      {!isFmUser && (
        <UploadFileModal {...uploadModalProps} onClose={onCloseUploadModal} title={UPLOAD_DOCUMENTS_MODAL_TITLE}>
          <UploadFileForm<UploadDocumentInput>
            allowedFileHelpText={UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES_TEXT}
            dragAreaTitle={UPLOAD_DOCUMENTS_DRAG_HEAD_TITLE}
            allowedFileTypes={UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES}
            maxFileSize={UPLOAD_DOCUMENTS_MAX_FILE_SIZE}
            onSubmitUpload={onSubmitUpload}
            onClose={onCloseUploadModal}
            inputs={!filterValue ? UploadDocumentsInputs : undefined}
            isLoading={isDocumentsUploading}
            submitButtonText='Upload'
            initialFormValues={UPLOAD_DOCUMENTS_INITIAL_VALUES}
          />
        </UploadFileModal>
      )}
      <TitleWithSearch
        title={
          <DynamicPageTitle
            isLoading={Boolean(filterValue && !dynamicPageTitle && !companyName)}
            dynamicTitle={dynamicPageTitle || companyName}
            title={DOCUMENTS_PAGE_TITLE}
            className={TypographyStyles.semi_large}
          />
        }
        description={pageDescription}
        handleChangeSearch={debouncedUpdateInput}
        onOpenUploadModal={onOpenUploadModal}
        className={cn('mb-6 items-start', {
          'flex flex-col [&>div]:w-full [&>div]:mt-8 [&>div]:mb-5': filterValue,
        })}
        descriptionClassName='mt-4'
        isAdmin={isAdmin}
      />

      <DocumentsPageFilter />

      <LocalDocumentsTable itemsPerPage={DOCUMENTS_PER_PAGE} updateSorting={updateSorting}>
        <Pagination currentPage={page} updatePage={setPage} {...documentsPaginationData} />
      </LocalDocumentsTable>
    </>
  );
};

export default DocumentsTab;
