import React from 'react';

import MyInvitesItemSkeleton from './MyInvitesItemSkeleton';

const MyInvitesSkeleton = () => {
  return (
    <div>
      <div className='skeleton-loader h-5 w-2/6 mb-4' />
      <div className='skeleton-loader h-0.5 mb-3' />
      <MyInvitesItemSkeleton />
      <MyInvitesItemSkeleton />
      <MyInvitesItemSkeleton />
    </div>
  );
};

export default MyInvitesSkeleton;
