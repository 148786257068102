import { createSlice } from '@reduxjs/toolkit';

import { SignatureState } from 'interfaces/signatures.interfaces';

import { SIGNATURES_SLICE_NAME } from './action';
import { getSignatureFromUrlReducer, updateSignatureReducer } from './reducers';

export const initialState: SignatureState = {
  isLoading: false,
  isSignatureSaved: null,
  signature: null,
  signatureUrl: {
    data: null,
    isLoading: false,
  },
};

const signatureSlice = createSlice({
  name: SIGNATURES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    updateSignatureReducer(builder);
    getSignatureFromUrlReducer(builder);
  },
});

export const signatureReducer = signatureSlice.reducer;
