import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { ShortCompanyData } from 'interfaces';
import { getShortCompanyInfo } from 'modules/companies/action';
import { useAppDispatch } from 'modules/store';

const useShortCompanyInfo = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const [companyInfo, setCompanyInfo] = useState<ShortCompanyData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);
    dispatch(getShortCompanyInfo(id))
      .unwrap()
      .then((company) => {
        setCompanyInfo(company);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, id]);

  return { ...companyInfo, id, isLoading };
};

export default useShortCompanyInfo;
