import React, { memo } from 'react';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { IDocument } from '@cyntler/react-doc-viewer/dist/esm/models';

import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import FullScreenModal from 'shared-components/modal-window/FullScreenModal';
import { getAccessToken } from 'utils';

import DocumentsViewerError from './DocumentsViewerError';
import DocumentsViewerLoader from './DocumentsViewerLoader';

interface Props extends Omit<UseModalReturnValues, 'onOpen' | 'modalProps'> {
  documents: IDocument[];
  shownError?: boolean;
}

const DocumentsViewer = memo(
  ({ isOpen, onClose, documents, shownError }: Props) => {
    return (
      <FullScreenModal isOpen={isOpen} onClose={onClose}>
        {shownError ? (
          <DocumentsViewerError />
        ) : (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            requestHeaders={{ Authorization: `Bearer ${getAccessToken()}` }}
            documents={documents}
            language='en'
            config={{
              header: {
                disableHeader: true,
              },
              loadingRenderer: {
                overrideComponent: DocumentsViewerLoader,
              },
            }}
          />
        )}
      </FullScreenModal>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  },
);

export default DocumentsViewer;

DocumentsViewer.displayName = 'DocumentsViewer';
