import React, { FC } from 'react';

type Props = {
  label?: string | boolean;
};

const DeleteIacFieldModalTitle: FC<Props> = ({ label }) => {
  return (
    <>
      <b>Are you sure you want to delete the {label ? 'filed ' + `${label}` : 'row'} and its content?</b>
    </>
  );
};

export default DeleteIacFieldModalTitle;
