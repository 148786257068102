import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminAuditLogsApis } from 'apis/audit-logs';
import { showServerError } from 'helpers';
import { GetListPayload } from 'interfaces';

export const AUDIT_LOGS_SLICE_NAME = 'admin/activity-logs';

export const getAdminActivityLogs = createAsyncThunk(
  `${AUDIT_LOGS_SLICE_NAME}`,
  async (params: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await adminAuditLogsApis.getAuditLogs(params);

      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const getAdminActivityLog = createAsyncThunk(
  `${AUDIT_LOGS_SLICE_NAME}/getAdminActivityLog`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await adminAuditLogsApis.getAuditLogById(id);

      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);
