import React, { useMemo } from 'react';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as BellSvg } from 'assets/svg/bell.svg';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { selectUser } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { checkIsAdmin, navigateByRole } from 'utils';

import LinkButton from '../../shared-components/link-button/LinkButton';

const HeaderNotifications = () => {
  const isAdmin = useMemo(() => checkIsAdmin(), []);
  const notificationsPath = navigateByRole(isAdmin, ROUTES.notifications);

  const profile = useAppSelector(selectUser);
  const notificationsCount = profile?.notificationsTotalUnread ?? 0;

  return (
    <LinkButton
      variant={ButtonVariants.TRANSPARENT}
      className='relative group p-1 transition-all self-end hover:stroke-inherit hover:fill-inherit'
      to={notificationsPath}
    >
      <>
        <BellSvg className='group-hover:stroke-white/50 group-hover:fill-white/50 min-w-20 min-h-20' />
        <div
          className={cn(
            'absolute -top-1 -right-1 flex items-center justify-center text-xs text-white group-hover:bg-red-400 p-0.5 w-fit bg-red-500 rounded-[50%]',
            { 'aspect-square': notificationsCount > 10 },
            { 'aspect-[5/1]': notificationsCount < 10 },
            { hidden: !notificationsCount },
          )}
        >
          {notificationsCount}
        </div>
        {!notificationsCount && (
          <Tooltip anchorId='notifications' positionStrategy='fixed' className='text-sm text-grey-500' noArrow>
            You have no notifications
          </Tooltip>
        )}
      </>
    </LinkButton>
  );
};

export default HeaderNotifications;
