import React from 'react';

const CompanyIacSharePriceNoItems = () => {
  return (
    <div className='mb-2 ml-1 mt-4'>
      <div className='text-xs'>Please select target in Fully Diluted Share Capital to add Company Class of Shares</div>
    </div>
  );
};

export default CompanyIacSharePriceNoItems;
