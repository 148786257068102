import React, { FC, useState } from 'react';

import { REVIEW_INVESTMENTS_MODAL_TITLE } from 'constants/investments';
import useModal from 'hooks/use-modal/useModal';
import { ReviewInvestment } from 'interfaces';
import { saveReviewedInvestments } from 'modules/investments/action';
import { selectReviewInvestments, selectIsActionLoading } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ModalWindow, SnippetLayout } from 'shared-components';

import DeleteInvestmentModal from '../delete-investment-modal/DeleteInvestmentModal';
import ReviewUploadedInvestmentsHeaderDescription from './ReviewUploadedInvestmentsHeaderDescription';
import ReviewUploadedInvestmentsModalButtons from './ReviewUploadedInvestmentsModalButtons';
import ReviewUploadedInvestmentsModalTable from './ReviewUploadedInvestmentsModalTable';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const ReviewUploadedInvestmentsModal: FC<Props> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const investments = useAppSelector(selectReviewInvestments);
  const isActionLoading = useAppSelector(selectIsActionLoading);

  const [deletingInvestment, setDeletingInvestment] = useState<null | ReviewInvestment>(null);

  const {
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
    isOpen: isOpenDeleteModal,
    ...deleteModalState
  } = useModal();

  const closeDeleteModal = () => {
    setDeletingInvestment(null);
    onCloseDeleteModal();
  };

  const onClickDeleteInvestment = (investmentId: string) => {
    setDeletingInvestment(investments.find((investment) => investment.id === investmentId) || null);
    onOpenDeleteModal();
  };

  const onSaveInvestments = () => {
    dispatch(saveReviewedInvestments())
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <>
      <DeleteInvestmentModal
        {...deleteModalState}
        isOpen={isOpenDeleteModal}
        onClose={closeDeleteModal}
        investment={deletingInvestment}
      />

      <ModalWindow
        title={REVIEW_INVESTMENTS_MODAL_TITLE}
        description={<ReviewUploadedInvestmentsHeaderDescription />}
        className='min-w-[70%]'
        wrapperClassName='px-8 py-8 lg:px-8 lg:py-8'
        overlayClassName='bg-black opacity-80'
        isOpen={isOpen}
        onClose={onClose}
        preventOutsideClick={isOpenDeleteModal}
      >
        <SnippetLayout>
          <ReviewUploadedInvestmentsModalTable
            onClickDeleteInvestment={onClickDeleteInvestment}
            investments={investments}
          />
        </SnippetLayout>
        <ReviewUploadedInvestmentsModalButtons
          isDisabledSave={!investments.length || isActionLoading}
          onCancel={onClose}
          onSave={onSaveInvestments}
        />
      </ModalWindow>
    </>
  );
};

export default ReviewUploadedInvestmentsModal;
