import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CHOOSE_FUND_MODAL_FORM_FIELDS, COMPANY_IAC_CHOOSE_FUND_MODAL_TITLE } from 'constants/company-iac';
import { REQUIRED_MESSAGE } from 'constants/global';
import { CompanyIacChooseFundFormState } from 'interfaces';
import { createCompanyIac, getFunds } from 'modules/company-iac/action';
import { useAppDispatch } from 'modules/store';
import { Button, ModalWindow } from 'shared-components';
import AsyncSelectField from 'shared-components/async-select-field/AsyncSelectField';
import { getAdminCompanyIacLink } from 'utils';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  companyName?: string | null;
  companyId?: number | null;
};

const CompanyIacChooseFundModal: FC<Props> = ({ companyName, companyId, isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit } = useForm<CompanyIacChooseFundFormState>();

  const modalTitle = COMPANY_IAC_CHOOSE_FUND_MODAL_TITLE.replace('%COMPANY_NAME%', companyName || '');

  const onSubmit = (data: CompanyIacChooseFundFormState) => {
    if (!companyId) {
      return;
    }

    setIsLoading(true);

    dispatch(createCompanyIac({ companyId, fundId: parseInt(data.fund.value, 10) }))
      .then(() => {
        onClose();
        navigate(getAdminCompanyIacLink(companyId));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!companyId) {
    return null;
  }

  return (
    <ModalWindow title={modalTitle} className='lg:w-8/12 xl:w-6/12' isOpen={isOpen} onClose={onClose}>
      {isOpen && (
        <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
          <AsyncSelectField
            control={control}
            name={CHOOSE_FUND_MODAL_FORM_FIELDS.FUND}
            label='Fund'
            placeholder='Select Fund'
            fetchOptions={getFunds}
            noOptionsMessage='No funds found'
            validation={{ required: REQUIRED_MESSAGE }}
            defaultOptions
          />

          <Button className='mt-20' type='submit' isLoading={isLoading}>
            Continue
          </Button>
        </form>
      )}
    </ModalWindow>
  );
};

export default CompanyIacChooseFundModal;
