import React from 'react';

import { PLAND_SUPPORT_EMAIL } from 'constants/global';

const StartShareExchangeClosingDatesTooltip = () => {
  return (
    <p>
      No Closing Date available. Please contact{' '}
      <a className='underline' href={`mailto:${PLAND_SUPPORT_EMAIL}`}>
        planD
      </a>{' '}
      to continue
    </p>
  );
};

export default StartShareExchangeClosingDatesTooltip;
