import React, { FC } from 'react';

const ShareExchangeQualificationQuestionsSkeleton: FC = () => {
  return (
    <div className='flex flex-col mb-[50px] w-full'>
      <div className='skeleton-loader mb-6 w-full h-100' />
      <div className='skeleton-loader mb-6 w-full h-100' />
      <div className='skeleton-loader mb-6 w-full h-100' />
    </div>
  );
};

export default ShareExchangeQualificationQuestionsSkeleton;
