import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import {
  FieldErrors,
  useForm,
  UseFormRegister,
  UseFormWatch,
  Control,
  UseFormTrigger,
  UseFormSetValue,
  UseFormUnregister,
  Path,
  UseFormGetValues,
  UseFormReset,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { CompanyIac, CompanyIacBusinessReviewFormValues, CompanyIacCustomField, StringOrNull } from 'interfaces';
import { updateCompanyIac } from 'modules/company-iac/action';
import { selectCompanyIacBusinessReview, selectCompanyIacId, selectCustomFields } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getCustomFieldsValues } from 'utils/companies-iac';

import { checkIfValuesTheSame } from '../utils';
import useCompanyIacCustomFields, {
  UseCompanyIacCustomFieldsReturnValues,
} from './use-company-iac-custom-fields/useCompanyIacCustomFields';
import useCompanyIacEdit, { UseCompanyIacEditReturnValues } from './useCompanyIacEdit';

export type UseCompanyIacOverviewReturnValues = {
  isLoading: boolean;
  control: Control<CompanyIacBusinessReviewFormValues>;
  register: UseFormRegister<CompanyIacBusinessReviewFormValues>;
  reset: UseFormReset<CompanyIacBusinessReviewFormValues>;
  unregister: UseFormUnregister<CompanyIacBusinessReviewFormValues>;
  getValues: UseFormGetValues<CompanyIacBusinessReviewFormValues>;
  setValue: UseFormSetValue<CompanyIacBusinessReviewFormValues>;
  errors: FieldErrors<CompanyIacBusinessReviewFormValues>;
  watch: UseFormWatch<CompanyIacBusinessReviewFormValues>;
  trigger: UseFormTrigger<CompanyIacBusinessReviewFormValues>;
  handleEdit?: VoidFunction;
  handleSaveRadioInputValue?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleChangeNotesTextFields?: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
  handleSaveEditionCustomField?: (event: ChangeEvent<HTMLTextAreaElement>) => Promise<void>;
  handleSaveBusinessReviewFields?: (name: Path<CompanyIacBusinessReviewFormValues>) => Promise<void>;
  isHideActionButtons: boolean;
  customFieldsData: Omit<
    UseCompanyIacCustomFieldsReturnValues,
    'handleSaveEditionCustomField' | 'reset' | 'resetDeletedFields'
  >;
} & Pick<UseCompanyIacEditReturnValues, 'isEditing'>;

const useCompanyIacBusinessReview = () => {
  const dispatch = useAppDispatch();
  const debouncedSave = useRef<null | any>(null);
  const debouncedUpdatedStates = useRef<any>({});

  const {
    reset,
    control,
    register,
    setValue,
    getValues,
    unregister,
    watch,
    formState: { errors },
    trigger,
  } = useForm<CompanyIacBusinessReviewFormValues>();

  const { id: companyId } = useParams();

  const { isEditing, isHideActionButtons } = useCompanyIacEdit(InvestmentProposalBlockNames.BUSINESS_REVIEW);

  const iacId = useAppSelector(selectCompanyIacId);
  const companyIacBusinessReviewData = useAppSelector(selectCompanyIacBusinessReview);
  const businessReviewCustomFields = useAppSelector(selectCustomFields(InvestmentProposalBlockNames.BUSINESS_REVIEW));

  const [isLoading, setIsLoading] = useState(false);

  const updateCustomBusinessReviewFields = (payloadData: CompanyIacCustomField[]) => {
    if (!iacId || !companyId) {
      return;
    }

    dispatch(
      updateCompanyIac({
        companyId,
        id: iacId,
        data: { businessReviewCustomInputs: payloadData },
      }),
    )
      .unwrap()
      .finally(() => setIsLoading(false));
  };

  const updateBusinessReviewFields = (payloadData: Partial<CompanyIac>) => {
    if (!iacId || !companyId) {
      return;
    }

    // Added shouldUpdateStore to update store as this part supports atosave
    // If implementation of autosave was done, we should remove check form updateCompanyIac action
    dispatch(updateCompanyIac({ companyId, id: iacId, data: payloadData, shouldUpdateStore: true }))
      .unwrap()
      .finally(() => setIsLoading(false));
  };

  const {
    handleSaveEditionCustomField,
    reset: resetCustomFields,
    ...customFieldsData
  } = useCompanyIacCustomFields(businessReviewCustomFields, updateCustomBusinessReviewFields);

  const shouldSkipUpdateBusinessFormField = async (
    fieldName: Path<CompanyIacBusinessReviewFormValues>,
    fieldValue: StringOrNull,
  ) => {
    const isValidatedCorrectly = await trigger(fieldName);
    const currentValue = companyIacBusinessReviewData?.[fieldName];
    const isValueNotUpdated = checkIfValuesTheSame(fieldValue, currentValue);

    return !isValidatedCorrectly || isValueNotUpdated || (!fieldValue && !currentValue);
  };

  const handleSaveBusinessReviewFields = async (name: Path<CompanyIacBusinessReviewFormValues>) => {
    const fieldValue = getValues(name);
    const skipUpdate = await shouldSkipUpdateBusinessFormField(name, fieldValue);

    if (skipUpdate) {
      return;
    }

    updateBusinessReviewFields({ [name]: fieldValue });
  };
  const handleChangeNotesTextFields = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (debouncedSave.current) {
      debouncedSave.current.cancel();
      debouncedSave.current = null;
    }

    const areaTexName = event.target.name as Path<CompanyIacBusinessReviewFormValues>;
    const fieldValue = getValues(areaTexName);

    const skipUpdate = await shouldSkipUpdateBusinessFormField(areaTexName, fieldValue);

    if (skipUpdate) {
      return;
    }

    debouncedSave.current = debounce(async () => {
      updateBusinessReviewFields(debouncedUpdatedStates.current);
      debouncedUpdatedStates.current = {};
    }, 3000);

    debouncedUpdatedStates.current = { ...debouncedUpdatedStates.current, [areaTexName]: fieldValue };
    debouncedSave.current();
  };

  const handleSaveRadioInputValue = async (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as Path<CompanyIacBusinessReviewFormValues>;
    const newValue = event.target.value;

    setValue(name, newValue);

    if (debouncedSave.current) {
      debouncedSave.current.cancel();
      debouncedSave.current = null;
    }

    const skipUpdate = await shouldSkipUpdateBusinessFormField(name, newValue);
    const notesFieldName = `${name}Notes` as Path<CompanyIacBusinessReviewFormValues>;

    if (skipUpdate) {
      return;
    }
    debouncedSave.current = debounce(() => {
      updateBusinessReviewFields({ ...debouncedUpdatedStates.current });
      debouncedUpdatedStates.current = {};
    }, 3000);

    debouncedUpdatedStates.current = { ...debouncedUpdatedStates.current, [name]: newValue, [notesFieldName]: '' };
    debouncedSave.current();
  };

  useEffect(() => {
    if (!companyIacBusinessReviewData) {
      return;
    }

    reset(companyIacBusinessReviewData);
  }, [reset, companyIacBusinessReviewData]);

  useEffect(() => {
    if (!businessReviewCustomFields) {
      return;
    }

    resetCustomFields(getCustomFieldsValues(businessReviewCustomFields));
  }, [resetCustomFields, businessReviewCustomFields]);

  return {
    isLoading,
    getValues,
    isEditing,
    reset,
    control,
    register,
    unregister,
    setValue,
    errors,
    watch,
    trigger,
    handleSaveRadioInputValue,
    handleSaveEditionCustomField,
    handleSaveBusinessReviewFields,
    handleChangeNotesTextFields,
    isHideActionButtons,
    customFieldsData,
  };
};

export default useCompanyIacBusinessReview;
