import React from 'react';

import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ReactComponent as CloseSvg } from 'assets/svg/close.svg';
import { store } from 'modules/store';
import PagesWrapper from 'pages/PagesWrapper';

import history from './services/history';

// Update routing in future: remove usage for unstable_HistoryRouter. See:
// https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219

function App() {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <HistoryRouter history={history}>
      <Provider store={store}>
        <PagesWrapper />
        <ToastContainer
          closeButton={() => <CloseSvg className='fill-violet-600 w-6 h-6 min-w-[24px]' />}
          theme='colored'
          className='top-16'
          bodyClassName='text-grey-800 leading-6 font-roboto'
          pauseOnHover
          autoClose={10000}
        />
      </Provider>
    </HistoryRouter>
  );
}

export default App;
