import React from 'react';

import { ReactComponent as InformationIcon } from 'assets/svg/info.svg';
import { IconWithTooltip } from 'shared-components';

import CorporateDocumentsAndFinancialsInformationTooltipMessage from './CorporateDocumentsAndFinancialsInformationTooltipMessage';

const CORPORATE_AND_FINANCIAL_INFO_ICON_ID = 'corporate-and-financials-info-icon';

const CorporateAndFinancialsInformationTooltip = () => {
  return (
    <div className='ml-2'>
      <IconWithTooltip
        tooltipClassName='info-tooltip-message'
        tooltipContent={<CorporateDocumentsAndFinancialsInformationTooltipMessage />}
        anchorId={CORPORATE_AND_FINANCIAL_INFO_ICON_ID}
        offset={1}
        place='bottom-start'
        icon={<InformationIcon />}
      />
    </div>
  );
};

export default CorporateAndFinancialsInformationTooltip;
