import React, { FC, useMemo } from 'react';

import { format } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { dueDiligenceCompanyStatusesColoredLabel } from 'constants/due-diligence';
import { TypographyVariants } from 'constants/shared/typography';
import { selectBusinessDetails, selectDueDiligenceCompanyStatus } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { ColoredLabel } from 'shared-components';
import Typography from 'shared-components/Typography';

import SignificantControlCompanyHeaderSection from './SignificantControlCompanyHeaderSection';

type Props = {
  isDueDiligenceLoaded?: boolean;
};

const SignificantControlCompanyHeader: FC<Props> = ({ isDueDiligenceLoaded }) => {
  const { dateOfIncorporation, natureOfBusiness, businessName, registeredNumber, companyType } =
    useAppSelector(selectBusinessDetails) || {};
  const companyDataStatus = useAppSelector(selectDueDiligenceCompanyStatus);

  const formattedDateIncorporated = dateOfIncorporation
    ? format(new Date(dateOfIncorporation), DAY_FORMAT_WITH_SLASHES)
    : '';

  const formattedNatureOfBusiness = useMemo(
    () => natureOfBusiness?.map((natureOfBusiness) => `${natureOfBusiness.code} - ${natureOfBusiness.name}`) || [],
    [natureOfBusiness],
  );

  return (
    <>
      <div className='flex mb-6 flex-col 1xl:flex-row gap-4 1xl:gap-0'>
        <div className='sm:mr-[120px]'>
          <div className='text-grey-500'>Company Profile</div>
          {isDueDiligenceLoaded ? (
            <div className='flex items-center flex-col sm:flex-row'>
              <Typography className='mr-2.5' tag='h4' variant={TypographyVariants.LARGE}>
                {businessName}
              </Typography>
              {companyDataStatus && (
                <ColoredLabel
                  className='self-start sm:self-center items-start'
                  {...dueDiligenceCompanyStatusesColoredLabel[companyDataStatus]}
                />
              )}
            </div>
          ) : (
            <div className='skeleton-loader w-150 h-6' />
          )}
        </div>

        <div className='flex flex-col sm:flex-row gap-4 sm:gap-0'>
          <SignificantControlCompanyHeaderSection
            isDueDiligenceLoaded={isDueDiligenceLoaded}
            type='Company Number'
            value={registeredNumber}
          />

          {companyType && (
            <SignificantControlCompanyHeaderSection
              isDueDiligenceLoaded={isDueDiligenceLoaded}
              type='Company Type'
              value={companyType}
            />
          )}

          <SignificantControlCompanyHeaderSection
            isDueDiligenceLoaded={isDueDiligenceLoaded}
            type='Date Incorporated:'
            value={formattedDateIncorporated}
          />

          <SignificantControlCompanyHeaderSection
            isDueDiligenceLoaded={isDueDiligenceLoaded}
            type='Nature of Business (SIC):'
            value={formattedNatureOfBusiness}
          />
        </div>
      </div>
    </>
  );
};

export default SignificantControlCompanyHeader;
