import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { useBlocker } from './useBlocker';

export function usePromptBeforeRouteChange(when: boolean, onOpen: VoidFunction): (() => void)[] {
  const navigate = useNavigate();
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState<{ location: Location } | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setLastLocation(null);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation: { location: Location }) => {
      const nextLocationUrl = `${nextLocation.location.pathname}${nextLocation.location.search}`;
      const currentLocationUrl = `${location.pathname}${location.search}`;
      if (!confirmedNavigation && nextLocationUrl !== currentLocationUrl) {
        onOpen();
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location.pathname, location.search, onOpen],
  );

  const confirmNavigation = useCallback(() => {
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location?.pathname);

      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  useBlocker(handleBlockedNavigation, when);

  return [confirmNavigation, cancelNavigation];
}
