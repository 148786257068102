import React from 'react';

import { companyIacStatusesColoredLabel } from 'constants/company-iac';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyIac } from 'interfaces';
import { ColoredLabel, LinkButton } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getAdminCompanyIacLink } from 'utils';

interface Props {
  data: CompanyIac;
}

const CompaniesIacShortInfoTableItem = ({ data }: Props) => {
  const { companyId, companyName, companyNumber, fundName, status } = data;

  return (
    <TableRow>
      <TableData>
        <LinkButton className='w-40 ml-1' to={getAdminCompanyIacLink(companyId)} variant={ButtonVariants.SECONDARY}>
          View
        </LinkButton>
      </TableData>
      <TableData>{companyName}</TableData>
      <TableData>{companyNumber}</TableData>
      <TableData>{fundName}</TableData>
      <TableData>
        <ColoredLabel {...companyIacStatusesColoredLabel[status]} />
      </TableData>
    </TableRow>
  );
};

export default CompaniesIacShortInfoTableItem;
