import { FromItemHigligtedColor } from '../interfaces';

export enum FieldTypes {
  TEXT = 'text',
  DATE = 'date',
  MONTH = 'month',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  RADIO = 'radio',
  NUMBER = 'number',
  PHONE = 'PHONE',
}

export enum ConstraintVariants {
  AUTH_MAIN = 'auth_main',
  MAIN_CONTENT = 'main_content',
  FULL = 'full',
  FULL_ROUNDED = 'full_rounded',
  MAIN_EXPANDED = 'main_expanded',
}

export enum TableRowVariants {
  MAIN = 'main',
  SIMPLIFIED = 'simplified',
}

export enum ColoredLabelVariants {
  SUCCESS = 'success',
  SECONDARY_SUCCESS = 'secondary_success',
  ERROR = 'error',
  PENDING = 'pending',
  PROGRESS = 'progress',
  DISABLED = 'DISABLED',
}

export enum CheckboxVariants {
  MAIN = 'main',
  SECONDARY = 'secondary',
  ALL = 'all',
}

export const ConstraintVariantsStyles: { [key in ConstraintVariants]: string } = {
  [ConstraintVariants.AUTH_MAIN]: 'max-w-662 pt-10 xs:pt-32',
  [ConstraintVariants.MAIN_CONTENT]: 'max-w-[1200px] mt-6 xl:mt-24',
  [ConstraintVariants.FULL]: 'max-w-full min-h-max p-4 md:p-10',
  [ConstraintVariants.FULL_ROUNDED]:
    'max-w-full min-h-max p-4 md:p-6 contentful-dashboard-page min-h-[calc(100vh-124px)]',
  [ConstraintVariants.MAIN_EXPANDED]: 'max-w-[1330px] mt-6 xl:mt-24',
};

export const TableRowVariantsStyles: { [key in TableRowVariants]: string } = {
  [TableRowVariants.MAIN]: 'min-h-[56px] border-b border-grey-200',
  [TableRowVariants.SIMPLIFIED]: 'h-[40px] max-h-[40px]',
};

export const ColoredLabelVariantsStyles: { [key in ColoredLabelVariants]: string } = {
  [ColoredLabelVariants.SUCCESS]: 'text-lightGreen-700 bg-lightGreen-200',
  [ColoredLabelVariants.SECONDARY_SUCCESS]: 'text-lightBlue-800 bg-lightGreen-200',
  [ColoredLabelVariants.ERROR]: 'text-red-500 bg-red-500/20',
  [ColoredLabelVariants.PENDING]: 'text-yellow-700 bg-yellow-200',
  [ColoredLabelVariants.PROGRESS]: 'text-lightBlue-800 bg-lightBlue-200',
  [ColoredLabelVariants.DISABLED]: 'text-lightBlue-800 bg-grey-200',
};

export const CheckboxVariantsStyles: { [key in CheckboxVariants]: string } = {
  [CheckboxVariants.MAIN]: 'checked:after:bg-blue-600 checked:border-blue-600',
  [CheckboxVariants.SECONDARY]: 'checked:after:bg-violet-500 checked:border-grey-300',
  [CheckboxVariants.ALL]: 'checkbox-check-mark-all checked:after:bg-white checked:border-blue-600',
};

export enum ORDERS {
  ASC = 'asc',
  DESC = 'desc',
}

export const INPUT_ERROR_DATA_TEST_ID = 'INPUT_ERROR_DATA_TEST_ID';

export const UPLOAD_FILE_WRONG_FILETYPE_ERROR = 'Can not upload this file. Docx, pdf, xls-formats are supported';

export const UPLOAD_FILE_WRONG_FILETYPE_PDF_PNG_ERROR = 'Can not upload this file. Pdf, png-formats are supported';

export const UPLOAD_FILE_MAX_FILE_SIZE_ERROR = 'Please provide file with size less then 15Mb';

export const DEFAULT_METRICS_STATE = {
  totalAll: null,
  totalByFilter: {},
  percentOfProgress: null,
};

export const TABLE_FORM_INPUT_ERROR_CLASSNAME = 'pt-6 pb-1';

export const TEXT_INPUT_CLASSNAMES = {
  default:
    'w-full py-3.75 px-4 text-grey-800 leading-6 border border-grey-200 rounded-lg focus:outline-blue-500 data-hj-allow',
  error: 'border-2 border-red-600 focus:outline-red-600',
  warning: 'border-2 border-yellow-600 focus:outline-yellow-600',
  highlighted: {
    blue: 'border-2 border-blue-500',
    red: 'border-2 border-red-600',
    default: '',
  },
  disabled: 'bg-grey-100 border-grey-200 text-grey-300 placeholder:text-grey-300 disabled:text-grey-400',
};
``;
export const HIGHLIGHTED_MESSAGE_COLORS: Record<FromItemHigligtedColor, string> = {
  red: 'red-600',
  blue: 'blue-500',
};

export const FORM_INPUTS_WITHOUT_REFS = [
  FieldTypes.DATE,
  FieldTypes.SELECT,
  FieldTypes.MONTH,
  FieldTypes.NUMBER,
  FieldTypes.PHONE,
];

export enum DATEPICKER_MAX_DETAIL {
  YEAR = 'year',
  MONTH = 'month',
}
