import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ADMIN_COMPANIES_SHORT_TABLE_COLUMNS } from 'constants/companies';
import { ROUTES } from 'constants/routes';
import { COMPANY_IAC_STATUSES } from 'interfaces';
import type { Company } from 'interfaces';
import { getAdminCompanies } from 'modules/companies/action';
import type { AppDispatch } from 'modules/store';
import { LinkButton } from 'shared-components';
import Table from 'shared-components/table';
import TableContentWrapper from 'shared-components/table/TableContentWrapper';
import TableNoData from 'shared-components/table/TableNoData';
import { navigateByRole, checkIsAdmin } from 'utils';

import AdminCompaniesTableRow from './AdminCompaniesTableRow';

interface Props {
  isLoading: boolean;
  companies: Company[] | null;
}

const CompaniesShortListTable = ({ isLoading, companies }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const COMPANIES_PER_PAGE = 50;
  const numberOfCompanies = companies?.length || 0;
  const showButton = numberOfCompanies > 3;
  const isAdmin = useMemo(() => {
    return checkIsAdmin();
  }, []);

  useEffect(() => {
    dispatch(getAdminCompanies({ page: 1, per_page: COMPANIES_PER_PAGE, isAdmin, order: 'desc' }));
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      const filtered = companies
        .filter((company) => company.iac !== COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS)
        .slice(0, 3);
      setFilteredCompanies(filtered);
    }
  }, [companies]);

  return (
    <>
      <Table columns={ADMIN_COMPANIES_SHORT_TABLE_COLUMNS}>
        <TableContentWrapper
          isLoading={isLoading}
          data={filteredCompanies}
          renderContent={(company) => <AdminCompaniesTableRow key={company.id} company={company} />}
          noDataNode={<TableNoData text='No Companies Found' skipColumns={1} />}
          columns={5}
          rows={3}
        />
      </Table>
      {showButton && (
        <div className='w-full flex justify-end mt-6'>
          <LinkButton to={navigateByRole(true, ROUTES.companies)}>
            View more <ArrowSvg className='ml-2.5' />
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default CompaniesShortListTable;
