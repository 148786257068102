import React, { FC } from 'react';

import useModal from 'hooks/use-modal/useModal';

import ShareExchangeQualificationQuestionsModal from './share-exchange-qualification-questions-modal/ShareExchangeQualificationQuestionsModal';
import ShareExchangeCancelQualificationQuestionsModal from './ShareExchangeCancelQualificationQuestionsModal';
import SuccessShareExchangeRequestModal from './SuccessShareExchangeRequestModal';

type Props = {
  processingShareExchangeId: string;
  isOpenSuccessShareExchangeRequestModal: boolean;
  isOpenQualificationQuestions: boolean;
  closeQualificationQuestions: VoidFunction;
  onSuccessSubmitQualificationQuestions: VoidFunction;
  onSuccessCancelShareExchangeQuestions: VoidFunction;
  onCloseSuccessShareExchangeRequestModal: VoidFunction;
};

const InvestmentsQualificationQuestionsModals: FC<Props> = ({
  processingShareExchangeId,
  isOpenSuccessShareExchangeRequestModal,
  isOpenQualificationQuestions,
  closeQualificationQuestions,
  onSuccessSubmitQualificationQuestions,
  onSuccessCancelShareExchangeQuestions,
  onCloseSuccessShareExchangeRequestModal,
}) => {
  const {
    isOpen: isOpenCancelQualificationQuestions,
    onOpen: onOpenCancelQualificationQuestions,
    onClose: onCloseCancelQualificationQuestions,
  } = useModal();

  return (
    <>
      <SuccessShareExchangeRequestModal
        isOpen={isOpenSuccessShareExchangeRequestModal}
        onClose={onCloseSuccessShareExchangeRequestModal}
      />

      <ShareExchangeQualificationQuestionsModal
        isOpen={isOpenQualificationQuestions}
        onClose={closeQualificationQuestions}
        onSuccessSubmitQualificationQuestions={onSuccessSubmitQualificationQuestions}
        shareExchangeId={processingShareExchangeId}
        preventOutsideClick={isOpenQualificationQuestions}
        cancelShareExchangeQuestions={onOpenCancelQualificationQuestions}
      />

      <ShareExchangeCancelQualificationQuestionsModal
        shareExchangeId={processingShareExchangeId}
        isOpen={isOpenCancelQualificationQuestions}
        onClose={onCloseCancelQualificationQuestions}
        onSuccessCancelShareExchangeQuestions={onSuccessCancelShareExchangeQuestions}
      />
    </>
  );
};

export default InvestmentsQualificationQuestionsModals;
