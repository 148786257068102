import React, { FC, Fragment, ReactNode } from 'react';

import { Investment, InvestmentKeys, InvestmentsFieldNames } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';
import { formatNumberInputValue } from 'utils/shared';

import MyInvestmentsNameTableDataContent from './MyInvestmentsNameTableDataContent';

interface Props extends Investment {
  availableKeys?: InvestmentKeys;
  hideForChildKeys?: InvestmentKeys;
  additionalActions?: ReactNode;
}

const DEFAULT_AVAILABLE_KEYS = ['companyNumber', 'classOfShares', 'pricePerShare'];

const MyInvestmentsTableItem: FC<Props> = ({
  companyName,
  companyId,
  availableKeys = DEFAULT_AVAILABLE_KEYS,
  hideForChildKeys = [InvestmentsFieldNames.COMPANY_NUMBER],
  isChild,
  additionalActions,
  investmentName,
  investmentDate,
  originalInvestmentPrice,
  ...restValues
}) => {
  const investmentValues = {
    companyId,
    ...restValues,
  };

  return (
    <Fragment>
      <TableRow>
        <TableData>
          <MyInvestmentsNameTableDataContent
            isFirstElement={!isChild}
            investmentName={investmentName}
            companyId={companyId}
            companyName={companyName}
          />
        </TableData>
        {availableKeys.map((key) => (
          <TableData key={key}>
            {!(hideForChildKeys.includes(key as keyof Investment) && isChild) &&
              (investmentValues as Record<string, any>)[key]}
          </TableData>
        ))}
        <TableData>£{formatNumberInputValue(originalInvestmentPrice, 4)}</TableData>
        <TableData>{investmentDate}</TableData>

        {additionalActions && <TableData>{additionalActions}</TableData>}
      </TableRow>
    </Fragment>
  );
};

export default React.memo(MyInvestmentsTableItem);
