import React, { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { LinkButton } from 'shared-components';
import { getCompanyProfileLink } from 'utils';

import { ButtonVariants } from '../constants/shared/button';

interface Props {
  children: ReactNode;
  id?: string;
  className?: string;
  isLoading?: boolean;
}

export const WITH_COMPANY_PROFILE_LINK_TEST_ID = 'WITH_COMPANY_PROFILE_LINK_TEST_ID';
export const WITH_COMPANY_PROFILE_LOADER_TEST_ID = 'WITH_COMPANY_PROFILE_LOADER_TEST_ID';

const WithCompanyProfileLinkContainer = ({ children, id, className, isLoading }: Props) => {
  if (isLoading)
    return (
      <div
        data-testid={WITH_COMPANY_PROFILE_LOADER_TEST_ID}
        className={twMerge('skeleton-loader w-96 h-[46px]', className)}
      />
    );

  if (!id) return null;

  return (
    <div className={className} data-testid={WITH_COMPANY_PROFILE_LINK_TEST_ID}>
      {children}
      <LinkButton className='w-full md:w-fit' to={getCompanyProfileLink(id, true)} variant={ButtonVariants.SECONDARY}>
        Company Profile
      </LinkButton>
    </div>
  );
};

export default WithCompanyProfileLinkContainer;
