import { useEffect, useState } from 'react';

import { Control, FieldErrors, useForm, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { DueDiligenceSections, FundraisingDetailsFieldNames } from 'constants/due-diligence';
import { FundraisingDetailsInput } from 'interfaces';
import { createOrUpdateFundraisingDetails } from 'modules/due-diligence/action';
import { selectFundraisingDetails } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import useDueDiligenceEdit from './use-due-diligence-edit/useDueDiligenceEdit';

const FUNDRAISING_DETAILS_DEFAULT_VALUES = {
  isCurrentlyFundraising: undefined,
  otherDetails: '',
  targetAmountForRaise: '',
  totalAmountSoFarReceived: '',
};

export interface UseFundraisingDetailsReturnValues {
  isEditing: boolean;
  isLoading: boolean;
  handleSaveField: (name: keyof FundraisingDetailsInput) => void;
  handleDiscardChanges: VoidFunction;
  register: UseFormRegister<FundraisingDetailsInput>;
  control: Control<FundraisingDetailsInput>;
  errors: FieldErrors<FundraisingDetailsInput>;
  setValue: UseFormSetValue<FundraisingDetailsInput>;
  isCurrentlyFundraisingSelected: boolean | undefined;
}

const useFundraisingDetails = (): UseFundraisingDetailsReturnValues => {
  const [isLoading, setIsLoading] = useState(false);
  const { isEditing, handleCancel } = useDueDiligenceEdit(DueDiligenceSections.FUNDRAISING_DETAILS);

  const dispatch = useAppDispatch();

  const { id, fundraisingDetails } = useAppSelector(selectFundraisingDetails);

  const {
    register,
    control,
    trigger,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FundraisingDetailsInput>({
    defaultValues: FUNDRAISING_DETAILS_DEFAULT_VALUES,
    reValidateMode: 'onChange',
  });

  const isCurrentlyFundraisingSelected = watch(FundraisingDetailsFieldNames.IS_CURRENTLY_FUNDRAISING);

  const handleSaveField = async (name: keyof FundraisingDetailsInput) => {
    const isValidatedCorrectly = await trigger(name as keyof FundraisingDetailsInput);

    const fieldValue = getValues(name as keyof FundraisingDetailsInput);

    if (!isValidatedCorrectly || !id) return;

    setIsLoading(true);
    dispatch(
      createOrUpdateFundraisingDetails({
        id,
        [name]:
          name === FundraisingDetailsFieldNames.TOTAL_AMOUNT_SO_FAR_RECEIVED ||
          name === FundraisingDetailsFieldNames.TARGET_AMOUNT_FOR_RAISE
            ? Number(fieldValue)
            : fieldValue,
      }),
    )
      .unwrap()
      .then(handleCancel)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDiscardChanges = () => {
    handleCancel();
    reset(fundraisingDetails);
  };

  useEffect(() => {
    if (!Object.keys(fundraisingDetails || {})?.length) {
      reset(FUNDRAISING_DETAILS_DEFAULT_VALUES);
      return;
    }

    reset(fundraisingDetails);
  }, [id, fundraisingDetails, reset]);

  return {
    isEditing,
    isLoading,
    handleSaveField,
    handleDiscardChanges,
    register,
    control,
    errors,
    setValue,
    isCurrentlyFundraisingSelected,
  };
};

export default useFundraisingDetails;
