import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminSignaturesApis, signaturesUrlApis } from 'apis/signatures.apis';

import { showServerError, successNotify } from '../../helpers';

export const SIGNATURES_SLICE_NAME = 'signatures';

export const updateSignature = createAsyncThunk(
  `${SIGNATURES_SLICE_NAME}/updateSignature`,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await adminSignaturesApis.updateSignature(payload);
      successNotify('Signature updated successfully');

      return response.data.data;
    } catch (err) {
      showServerError({ response: { data: { message: 'There was an error saving the signature' } } });
      return rejectWithValue(err);
    }
  },
);

export const getSignatureFromUrl = createAsyncThunk(
  `${SIGNATURES_SLICE_NAME}/getSignatureFromUrl`,
  async (payload: string) => {
    const response = await signaturesUrlApis.getSignatureFromUrl(payload);
    const arrayBuffer = response.data;

    const uint8Array = new Uint8Array(arrayBuffer);

    const binaryString = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), '');
    const base64String = window.btoa(binaryString);

    return `data:${response.headers['content-type']};base64,${base64String}`;
  },
);
