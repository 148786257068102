import { createSlice } from '@reduxjs/toolkit';

import { NotificationState } from 'interfaces';

import { META_INITIAL_STATE } from '../../constants/global';
import { NOTIFICATIONS_SLICE_NAME } from './action';
import { getNotificationsReducer, updateNotificationReducer } from './reducers';

export const initialState: NotificationState = {
  notificationList: {
    list: null,
    isLoading: false,
    ...META_INITIAL_STATE,
  },
};

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getNotificationsReducer(builder);
    updateNotificationReducer(builder);
  },
});

export const notificationsReducer = notificationsSlice.reducer;
