import { createSelector } from '@reduxjs/toolkit';

import { formatFloatNumbers } from 'utils';

import { RootState } from '../store';

const selectState = (x: RootState) => x.shareExchangeReducer;

export const selectShareExchangeRequests = createSelector(selectState, ({ requests }) => requests);

export const selectShareExchangeReview = createSelector(selectState, ({ review }) => {
  if (Object.keys(review.shareExchange).length === 0) {
    return review;
  }

  const { isLoading, shareExchange } = review;
  const { investments = [], ...restShareExchange } = shareExchange;
  const investmentsWithFormattedNumbers = investments.map(({ amountInvested, pricePerShare, ...investment }) => ({
    amountInvested: formatFloatNumbers(amountInvested),
    pricePerShare: formatFloatNumbers(pricePerShare),
    ...investment,
  }));

  return { isLoading, shareExchange: { ...restShareExchange, investments: investmentsWithFormattedNumbers } };
});

export const selectWelcomeInvestorShareExchangeData = createSelector(
  selectState,
  ({ welcomeInvestorShareExchangeData }) => welcomeInvestorShareExchangeData,
);
