import React from 'react';

const AdminCompanyProfileDealInformationPreloader = () => {
  return (
    <div>
      {Array.from(Array(3).keys()).map((key) => (
        <div key={key} className='mt-6 flex'>
          <div className='skeleton-loader h-6 w-2/12 mr-2' />
          <div className='skeleton-loader h-6 w-3/12' />
        </div>
      ))}
    </div>
  );
};

export default AdminCompanyProfileDealInformationPreloader;
