import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as EnvelopeSvg } from 'assets/svg/envelope.svg';
import { TypographyStyles } from 'constants/shared/typography';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { UserDropdown } from 'page-components';
import IconButtonWithTooltip from 'shared-components/IconButtonWithTooltip';
import { getCurrentTime, getTitleWithReplacedYourWord } from 'utils';

import HeaderNotifications from './HeaderNotifications';

const ICONS_CLASSNAME = 'cursor-pointer icon-button-with-tooltip-svg-white hover:fill-white p-1 mx-0';

interface Props {
  handleOpenSignOutModal: VoidFunction;
}

const HeaderPanel: FC<Props> = ({ handleOpenSignOutModal }) => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(getCurrentTime()), (60 - new Date().getSeconds()) * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className='flex items-center'>
      <div className='hidden md:flex items-center space-x-6 mr-12'>
        <time className={twMerge(TypographyStyles.body_normal, 'text-grey-800 whitespace-nowrap')}>{currentTime}</time>

        <IconButtonWithTooltip
          onClick={() => ''}
          icon={EnvelopeSvg}
          className={cn('hidden', ICONS_CLASSNAME)}
          tooltipText={getTitleWithReplacedYourWord('Your inbox is currently clear', entityName, isTypeEntity)}
          anchorId='inbox'
          disableDefaultIconStyles
        />

        <HeaderNotifications />
      </div>
      <UserDropdown handleOpenSignOutModal={handleOpenSignOutModal} />
    </div>
  );
};

export default HeaderPanel;
