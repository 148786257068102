import React, { PropsWithChildren, ReactElement, useState } from 'react';

import { AsyncThunk } from '@reduxjs/toolkit';
import cn from 'classnames';
import { debounce } from 'lodash';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import Select from 'react-select/async';

import { useAppDispatch } from 'modules/store';
import { ErrorMessage, FormLabel } from 'shared-components/index';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: string;
  label?: string;
  placeholder: string;
  fetchOptions: AsyncThunk<any, any, any>;
  noOptionsMessage: string;
  validation?: Record<string, any>;
  className?: string;
  disabled?: boolean;
  selectStyles?: StylesConfig;
  defaultOptions?: boolean;
}

const AsyncSelectField = <T extends FieldValues>({
  control,
  className,
  name,
  label,
  placeholder,
  disabled,
  selectStyles,
  fetchOptions,
  noOptionsMessage,
  validation,
  defaultOptions,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onSearch = (searchString: string, callback: CallableFunction) => {
    setIsLoading(true);

    dispatch(fetchOptions(searchString))
      .unwrap()
      .then(({ options }) => {
        callback([...options]);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Controller
      name={name as Path<T>}
      control={control}
      rules={validation}
      render={({ field, fieldState: { error } }) => (
        <div className={className}>
          {label && <FormLabel>{label}</FormLabel>}

          <Select
            {...field}
            isLoading={isLoading}
            isDisabled={disabled}
            noOptionsMessage={() => noOptionsMessage}
            className={cn('select-input', { 'select-input-error': error?.message })}
            loadOptions={debounce(onSearch, 2000)}
            placeholder={placeholder}
            menuPosition='fixed'
            menuPortalTarget={window.document.body}
            styles={
              {
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                ...selectStyles,
              } as StylesConfig
            }
            defaultOptions={defaultOptions}
          />

          <ErrorMessage className='text-xs pl-[5px] pt-[5px] leading-[14px] mb-1' error={error?.message} />
        </div>
      )}
    />
  );
};

export default AsyncSelectField;
