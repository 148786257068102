import React from 'react';

import { ReactComponent as SpinLoader } from 'assets/svg/spin-loader.svg';

import useLockBodyScroll from '../../hooks/use-lock-body-scroll/useLockBodyScroll';

const DashboardUserTypeChangeLoader = () => {
  useLockBodyScroll(true);

  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <SpinLoader className='animated-spin w-32 h-32' />
    </div>
  );
};

export default DashboardUserTypeChangeLoader;
