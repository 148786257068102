import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

interface Props {
  isShownCancel: boolean;
  isLoading?: boolean;
  onCancel: VoidFunction;
  onUpdate: VoidFunction;
}

const SettingsSecurityButtons: FC<Props> = ({ isShownCancel, onCancel, onUpdate, isLoading }) => {
  return (
    <div className='flex items-center flex-col-reverse xs:flex-row justify-end gap-5'>
      {isShownCancel && (
        <Button onClick={onCancel} type='button' className='w-full xs:w-150' variant={ButtonVariants.SECONDARY}>
          Cancel
        </Button>
      )}
      <Button onClick={onUpdate} isLoading={isLoading} type='button' className='w-full xs:w-52'>
        Update Password
      </Button>
    </div>
  );
};

export default SettingsSecurityButtons;
