import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { BUSINESS_SETTINGS_FEE_FORM_DESCRIPTION, BUSINESS_SETTINGS_FEE_FORM_FIELDS } from 'constants/business-settings';
import { ButtonVariants } from 'constants/shared/button';
import { CreateFeeFormValues } from 'interfaces';
import { createFee } from 'modules/fees/action';
import { selectActiveFee } from 'modules/fees/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { FormContent, TitleWithDescription } from 'shared-components';

import ActionsButtons from '../due-diligence/ActionsButtons';
import FeesTable from './fees-table/FeesTable';

const BusinessSettingsTab = () => {
  const dispatch = useAppDispatch();

  const activeFee = useAppSelector(selectActiveFee);

  const [isEditing, setIsEditing] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm<CreateFeeFormValues>({ reValidateMode: 'onSubmit' });

  const handleCreateFee: SubmitHandler<CreateFeeFormValues> = (values) => {
    setIsActionLoading(true);
    dispatch(createFee(values))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        reset();
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  const handleDiscardChanges = () => {
    setIsEditing(false);
    reset();
  };

  useEffect(() => {
    const { feeRate, feeMin } = activeFee || {};

    reset({ feeRate: feeRate || '0.01', feeMin: feeMin || '100' });
  }, [activeFee, reset]);

  return (
    <div>
      <div className='flex sm:items-center justify-between flex-col sm:flex-row pb-6'>
        <TitleWithDescription
          className='ml-2'
          descriptionClassName='pt-2'
          title='PlanD Fee'
          description={BUSINESS_SETTINGS_FEE_FORM_DESCRIPTION}
        />
        <div className='flex'>
          <ActionsButtons
            isLoading={isActionLoading}
            isEditing={isEditing}
            onClickSave={handleSubmit(handleCreateFee)}
            onClickDiscardChanges={handleDiscardChanges}
            onClickEdit={() => setIsEditing(true)}
            discardButtonText='Cancel'
            submitButtonVariant={ButtonVariants.PRIMARY}
            hideComments
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(handleCreateFee)}
        className='border-y border-grey-300 p-6 flex flex-col xs:flex-row gap-4'
      >
        <FormContent
          isDisabled={!isEditing}
          fields={BUSINESS_SETTINGS_FEE_FORM_FIELDS(trigger, !isEditing)}
          register={register}
          control={control}
          errors={errors}
        />
      </form>
      <div className='py-5'>
        <TitleWithDescription className='ml-2 mb-10' title='Fee Change Log' />
        <FeesTable />
      </div>
    </div>
  );
};

export default BusinessSettingsTab;
