import React, { useEffect } from 'react';

import { getCompanyArchivedDueDiligence } from 'modules/companies/action';
import { selectArchivedCompanyDueDiligence } from 'modules/companies/selectors';
import { selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ListSkeleton } from 'shared-components';
import Typography from 'shared-components/Typography';

import ArchivedDueDiligenceListItem from './ArchivedDueDiligenceListItem';

interface Props {
  id?: string;
}

const ArchivedDueDiligenceList = ({ id }: Props) => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(selectUserType);

  const { isLoading, list } = useAppSelector(selectArchivedCompanyDueDiligence);

  useEffect(() => {
    if (!id || !userType) return;

    dispatch(getCompanyArchivedDueDiligence({ id, userType }));
  }, [dispatch, id, userType]);

  if (isLoading) return <ListSkeleton />;

  if (!isLoading && !list?.length) return <Typography>No Archived Due Diligence Found</Typography>;

  return (
    <div className='space-y-6'>
      {list?.length
        ? list.map((dueDiligence) => (
            <ArchivedDueDiligenceListItem key={dueDiligence.businessDetails?.id} data={dueDiligence} />
          ))
        : null}
    </div>
  );
};

export default ArchivedDueDiligenceList;
