import React, { FC, useMemo } from 'react';

import cn from 'classnames';

import { INVESTMENT_PROPOSAL_SUMMARY_FORM_FIELDS, InvestmentProposalBlockNames } from 'constants/company-iac';
import { UseCompanyIacSummaryReturnValues } from 'hooks/useCompanyIacSummary';
import { FormContent } from 'shared-components';

import { checkIsAdmin } from '../../../utils';
import CompanyIacAddCustomFields from '../company-iac-add-custom-fields/CompanyIacAddCustomFields';
import CompanyIacCustomFieldsForm from '../company-iac-custom-fields-form/CompanyIacCustomFieldsForm';
import CompanyIacBlock from '../CompanyIacBlock';

type Props = Omit<UseCompanyIacSummaryReturnValues, 'trigger' | 'reset'> & {
  hideAdminActions: boolean;
};

const CompanyIacSummary: FC<Props> = ({
  isLoading,
  isEditing,
  register,
  control,
  errors,
  handleSaveEditionField,
  isHideActionButtons,
  handleSaveEditionCustomField,
  customFieldsData,
  hideAdminActions,
}) => {
  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { registerCustomFields, onDeleteCustomField, deletedFields } = customFieldsData;

  return (
    <CompanyIacBlock title='Summary' isHideActionButtons={isHideActionButtons} isLoading={isLoading}>
      <form className='flex flex-col w-full [&>div]:w-full'>
        <FormContent
          fields={INVESTMENT_PROPOSAL_SUMMARY_FORM_FIELDS(isAdmin)}
          control={control}
          register={register}
          errors={errors}
          isDisabled={isEditing}
          className={cn('mb-5', { 'disabled-light-grey': isEditing })}
          onBlur={handleSaveEditionField}
        />
      </form>

      <CompanyIacCustomFieldsForm
        disabled={isEditing}
        errors={customFieldsData.customFieldsErrors}
        deletedFields={deletedFields}
        onDeleteCustomField={onDeleteCustomField}
        register={registerCustomFields}
        blockName={InvestmentProposalBlockNames.SUMMARY}
        onBlur={handleSaveEditionCustomField}
      />

      <CompanyIacAddCustomFields
        blockName={InvestmentProposalBlockNames.SUMMARY}
        disabled={isEditing}
        hideAddNewFieldButton={hideAdminActions}
        className={cn({ 'disabled-light-grey': isEditing })}
      />
    </CompanyIacBlock>
  );
};

export default CompanyIacSummary;
