import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import Typography from 'shared-components/Typography';
import { formatNumberInputValue } from 'utils/shared';

interface Props {
  label: string;
  value: string | number;
  className?: string;
}

const ShareExchangeInfoItem: FC<Props> = ({ label, value, className }) => {
  return (
    <div className={twMerge('flex justify-between pt-2 px-2', className)}>
      <Typography className='text-grey-500'>{label}</Typography>
      <Typography className='text-grey-500 ml-6 mr-20 2xl:mr-36'>
        {typeof value === 'number' ? formatNumberInputValue(value) : value}
      </Typography>
    </div>
  );
};

export default ShareExchangeInfoItem;
