import React from 'react';

import { Page, Text, Link, Image, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import rightArrowPng from 'assets/png/company-invite-investors-pdf-template/arrow.png';
import plandLogoPng from 'assets/png/company-invite-investors-pdf-template/pland-logo.png';
import ArialBold from 'fonts/Arial/Arial-BoldMT.ttf';
import ArialItalic from 'fonts/Arial/Arial-ItalicMT.ttf';
import ArialRegular from 'fonts/Arial/ArialMT.ttf';

Font.register({
  family: 'Arial',
  fonts: [{ src: ArialRegular }, { src: ArialBold, fontWeight: 'bold' }, { src: ArialItalic, fontStyle: 'italic' }],
});

const styles = StyleSheet.create({
  page: {
    padding: '10px 0',
    maxWidth: '650px',
    backgroundColor: '#FFF',
    border: 'none',
    fontFamily: 'Arial',
  },
  header: {
    backgroundColor: '#F1F1F3',
    padding: '24px 40px',
  },
  logo: {
    width: '96px',
    height: '35px',
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  contentWrapper: {
    padding: '24px 40px',
    color: '#74726F',
    fontSize: '14px',
  },
  text: {
    marginBottom: '10px',
  },
  textWithYMargin: {
    marginBottom: '28px',
  },
  textLargeMargin: {
    marginBottom: '28px',
    fontSize: '14px',
  },
  boldText: {
    fontWeight: 'heavy',
    color: '#333333',
  },
  link: {
    color: '#337AB7',
    textDecoration: 'none',
  },
  registerButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '30px',
  },
  registerButton: {
    backgroundColor: '#31D3C8',
    padding: '16px 24px',
    borderRadius: '32px',
    color: '#080E36',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
  },
  arrow: {
    width: '16px',
    height: '16px',
    marginLeft: '10px',
  },
  largeLogoContainer: {
    marginTop: '10px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  largeLogo: {
    width: '198px',
    height: '80px',
  },
  alertSection: {
    border: '2px solid #000000',
    padding: '20px 10px',
  },
  extraBoldText: {
    color: '#14120F',
    fontWeight: 'bold',
  },
  secondaryLink: {
    color: '#2942E0',
    textDecoration: 'none',
  },
  largeAndBoldText: {
    color: '#14120F',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '10px',
  },
  italic: {
    fontStyle: 'italic',
  },
});

interface Props {
  companyName?: string;
}

export const CompanyInviteInvestorsPdfTemplate = ({ companyName }: Props) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={{ ...styles.header, margin: '-10px -10px 0' }}>
          <Link src='https://pland.co' style={styles.logoLink}>
            <Image style={styles.logo} src={plandLogoPng} />
          </Link>
        </View>
        <View style={styles.contentWrapper}>
          <View style={styles.alertSection}>
            <Text style={styles.extraBoldText}>
              Don’t invest unless you’re prepared to lose all your money invested. This is a high-risk investment. You
              could lose all the money you invest and are unlikely to be protected if something goes wrong.{' '}
              <Link style={styles.secondaryLink} src='https://pland.co/risksummary/'>
                Take two minutes to learn more
              </Link>
              .
            </Text>
          </View>
          <Text style={styles.largeAndBoldText}>Good morning, {`${companyName} Shareholder`}</Text>
          <Text style={styles.textLargeMargin}>
            planD represent a fund, Daxia Ltd, who would be willing to purchase up to 25% of the shares in
            {` ${companyName}`}, on a first come, first served basis. It is important to note that the deal is on an
            exchange of shares basis - not cash, and you would be getting shares in as consideration. However, we
            understand that should the price per share be less than the price you initially paid, this could be a loss
            crystallisation event.
          </Text>
          <Text style={styles.textLargeMargin}>
            Fees apply: the greater of £100 or 1.5% of the initial investment value - e.g. if you invested £10,000 the
            fee would be £150.
          </Text>
          <Text style={styles.textLargeMargin}>
            You can register on the platform here: Once registered, the process will be as follows:
          </Text>
          <View style={styles.registerButtonWrapper}>
            <Link style={styles.registerButton} src='https://app.pland.co/who-are-you'>
              <Text>Register</Text>
              <Image style={styles.arrow} src={rightArrowPng} />
            </Link>
          </View>
          <Text style={styles.textLargeMargin}>Once registered, the process will be as follows:</Text>
          <Text style={styles.textLargeMargin}>
            1. Add your {companyName} shareholding details to the “Share Exchange” page - the deal has already been
            approved and is otherwise ready to launch
          </Text>
          <Text style={styles.textLargeMargin}>
            2. Review the Information Memorandum and eSign the Introducer Agreement
          </Text>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.contentWrapper}>
          <Text style={styles.textLargeMargin}>
            3. Complete the required Appropriateness Questionnaire (online form - dictated by FCA - with a 24 hours
            cooling off before step 4)
          </Text>
          <Text style={styles.textWithYMargin}>
            4. Complete the Application Form (auto populated - just requires number of shares)
          </Text>
          <Text style={styles.textLargeMargin}>5. eSign the Share Exchange Agreement (SEA)</Text>
          <Text style={styles.textLargeMargin}>6. Pay planD’s introducer fee</Text>
          <Text style={styles.textLargeMargin}>7. eSign Stock Transfer Form (STF)</Text>
          <Text style={styles.textLargeMargin}>
            Upon completion of the deal, you will receive an Investment Confirmation Letter from the Fund Manager, which
            together with the SEA (step 5) shows the price / value for the exit. Please note: The availability of any
            tax relief depends on the individual circumstances of each investor and of the company concerned - you
            should always obtain independent tax advice.
          </Text>
          <Text style={styles.textLargeMargin}>
            If you have any questions, email planD on support@pland.co or book a meeting.
          </Text>
          <Text style={styles.textLargeMargin}>---------</Text>
          <Text style={{ ...styles.textLargeMargin, ...styles.italic }}>
            Video Explainer: More information on the planD process can be found in the explainer video{' '}
            <Link style={styles.secondaryLink} src='https://vimeo.com/711789041'>
              here
            </Link>
            .
          </Text>
          <Text style={{ ...styles.textLargeMargin, ...styles.italic }}>
            Daxia: Daxia is a Fund whose sole purpose is to acquire stakes in predominantly underperforming (S)EIS
            companies in exchange for Shares in itself. This cashless transaction provides the Investor with exposure to
            a diversified portfolio thereby increasing the chances of liquidity events.
          </Text>
          <Text style={{ ...styles.textLargeMargin, ...styles.italic }}>
            FIFO (first in, first out): The ordinary share identification rules do not apply to shares that have
            attracted EIS relief. There is no pooling of these shares and other matching rules do not apply. Instead the
            Income Tax rules in ITA07/S246(2) apply. Disposals are identified first against the earliest acquisition.
          </Text>
          <Text style={styles.textWithYMargin}>
            This is a financial promotion for the purposes of Section 21 of FSMA. It is issued by GetplanD Ltd FRN:
            945131, an Appointed Representative of Enterprise Investment Partners LLP FRN:604439, an AIFM as defined by
            the AIFMD, who have
          </Text>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.contentWrapper}>
          <Text style={styles.textLargeMargin}>
            approved it, on 13/10/23, and who are authorised by the FCA. This is not an offer to buy or sell securities
            and does not constitute a Direct Offer Financial Promotion. Applications for investment may only be made on
            the basis of the relevant [Information Memorandum and application form, copies of which are available on
            request. No reliance is to be placed on the information contained in this document in making such an
            application. Information herein is not intended to, nor should be taken to, constitute advice. This material
            is directed only at persons in the UK.
          </Text>
          <Text style={styles.textLargeMargin}>
            GetplanD Ltd source investments in unquoted securities, which are classified by the FCA as a Non-Readily
            Realisable Security (NRRS). As such, these products may only be marketed to limited categories of investors
            relating to knowledge, experience or financial situation. Investors should not invest unless they are
            prepared to lose all the money they invest. This is a high risk investment. Past performance is not a
            reliable indicator of future results. Investment is of a long-term and illiquid nature. It can be difficult
            to value or sell unquoted investments. Any described tax advantages associated with investing are based on
            current legislation, are subject to change, and depend on the individual circumstances of each investor.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
