import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { MY_INVESTMENTS_PAGE_TABS_IDS } from 'constants/investments';
import { ROUTES } from 'constants/routes';
import { errorNotify } from 'helpers';
import useModal from 'hooks/use-modal/useModal';
import { getShareExchange, cancelShareExchange } from 'modules/share-exchange/action';
import { selectShareExchangeReview } from 'modules/share-exchange/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  ShareExchangeConstraintHeader,
  ShareExchangeReviewCancelModal,
  ShareExchangeReviewQualificationAnswersModal,
  ShareExchangeReviewAnswersSection,
  ShareExchangeReviewTableSection,
  ShareExchangeReviewStatusSection,
  ShareExchangeReviewLayout,
} from 'page-components';

const ShareExchangeReview = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, shareExchange } = useAppSelector(selectShareExchangeReview);

  const { id: shareExchangeId } = useParams();

  const { isOpen: isOpenCancelModal, onOpen: onOpenCancelModal, onClose: onCloseCancelModal } = useModal();
  const {
    isOpen: isOpenSubmittedAnswers,
    onOpen: onOpenSubmittedAnswers,
    onClose: onCloseSubmittedAnswers,
  } = useModal();

  const navigateToInvestments = () => {
    navigate({ pathname: ROUTES.myInvestments, search: `?tab=${MY_INVESTMENTS_PAGE_TABS_IDS.INVESTMENTS}` });
  };

  const onCancelShareExchange = () => {
    if (!shareExchangeId) {
      return;
    }

    dispatch(cancelShareExchange(shareExchangeId))
      .unwrap()
      .then(() => {
        onCloseCancelModal();
      });
  };

  const onClickViewAnswers = () => {
    if (!shareExchange.answers) {
      errorNotify('No submitted answers for share exchange request');
      return;
    }

    onOpenSubmittedAnswers();
  };

  useEffect(() => {
    if (!shareExchangeId) {
      return;
    }

    dispatch(getShareExchange({ id: shareExchangeId }));
  }, [shareExchangeId, dispatch]);

  return (
    <>
      <ShareExchangeReviewCancelModal
        isLoading={isLoading}
        isOpen={isOpenCancelModal}
        onCancelShareExchange={onCancelShareExchange}
        onClose={onCloseCancelModal}
      />

      <ShareExchangeReviewQualificationAnswersModal
        isOpen={isOpenSubmittedAnswers}
        answers={shareExchange?.answers}
        onClose={onCloseSubmittedAnswers}
      />

      <ShareExchangeReviewLayout onClickBack={navigateToInvestments} isLoading={isLoading}>
        <ShareExchangeConstraintHeader shareExchange={shareExchange} onClickCancelShareExchange={onOpenCancelModal} />
        <ShareExchangeReviewStatusSection shareExchange={shareExchange} />
        <ShareExchangeReviewTableSection status={shareExchange?.status} investments={shareExchange?.investments} />
        {shareExchange?.answers && <ShareExchangeReviewAnswersSection onClickViewAnswers={onClickViewAnswers} />}
      </ShareExchangeReviewLayout>
    </>
  );
};

export default ShareExchangeReview;
