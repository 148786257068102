import React, { FC, useEffect } from 'react';

import { QUALIFICATION_QUESTIONS_MODAL_TITLE } from 'constants/my-investments';
import { getQualificationQuestions, submitShareExchangeAnswers } from 'modules/investments/action';
import { selectQualificationQuestions } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ModalWindow } from 'shared-components';

import ShareExchangeQualificationQuestionsForm from './ShareExchangeQualificationQuestionsForm';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  cancelShareExchangeQuestions: VoidFunction;
  shareExchangeId: string;
  onSuccessSubmitQualificationQuestions: VoidFunction;
  preventOutsideClick?: boolean;
}

const ShareExchangeQualificationQuestionsModal: FC<Props> = ({
  onClose,
  isOpen,
  onSuccessSubmitQualificationQuestions,
  shareExchangeId,
  cancelShareExchangeQuestions,
  preventOutsideClick = false,
}) => {
  const dispatch = useAppDispatch();

  const { isLoading, list: questions, company } = useAppSelector(selectQualificationQuestions);
  const companyName = company?.companyName || '';

  useEffect(() => {
    if (!shareExchangeId || !isOpen) {
      return;
    }

    dispatch(getQualificationQuestions(shareExchangeId));
  }, [dispatch, shareExchangeId, isOpen]);

  const onSubmitAnswers = (answers: Record<string, string>) => {
    dispatch(submitShareExchangeAnswers({ answers, shareExchangeRequestId: shareExchangeId }))
      .unwrap()
      .then(onSuccessSubmitQualificationQuestions);
  };

  return (
    <ModalWindow
      title={QUALIFICATION_QUESTIONS_MODAL_TITLE}
      description={`Before we proceed with Due Diligence on ${companyName}, please help us by answering a couple of questions:`}
      className='lg:w-8/12 xl:w-6/12 lg:h-12/12'
      wrapperClassName='px-10 py-[30px] lg:px-10 lg:py-[30px]'
      onClose={onClose}
      isOpen={isOpen}
      preventOutsideClick={preventOutsideClick}
    >
      <ShareExchangeQualificationQuestionsForm
        onSubmitAnswers={onSubmitAnswers}
        onClickCancel={cancelShareExchangeQuestions}
        questions={questions}
        loading={isLoading}
      />
    </ModalWindow>
  );
};

export default ShareExchangeQualificationQuestionsModal;
