import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { ReactComponent as EditSvg } from 'assets/svg/pencil.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/plus-1.svg';
import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { INVESTMENTS_TABLE_COLUMNS } from 'constants/investments';
import { Investment } from 'interfaces';
import { selectIsLoading } from 'modules/investments/selectors';
import { useAppSelector } from 'modules/store';
import { InvestmentsTableItem, NoInvestmentsAdded } from 'page-components';
import { ButtonsActions } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import InvestmentsTableFormRow from './InvestmentsTableFormRow';

interface Props {
  investments: Investment[];
  openedInvestmentFormId?: string;
  setOpenedInvestmentFormId: Dispatch<SetStateAction<string>>;
  setCurrentDeleteInvestment: (investment: Investment | null) => void;
}

const InvestmentsTable: FC<Props> = ({
  investments,
  openedInvestmentFormId,
  setOpenedInvestmentFormId,
  setCurrentDeleteInvestment,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const isLoading = useAppSelector(selectIsLoading);

  const handleCloseInvestedForm = () => {
    setOpenedInvestmentFormId('');
    setIsEdit(false);
  };

  const getInvestmentButtonsActions = (id: string, isLastByCompany?: boolean, disabled?: boolean) => [
    ...(isLastByCompany
      ? [
          {
            id: 'add-class-of-shares',
            icon: PlusSvg,
            action: () => setOpenedInvestmentFormId(id),
            tooltipText: 'Add Investment',
            className: 'svg-path-stroke-0',
          },
        ]
      : []),
    {
      id: 'edit-investment',
      icon: EditSvg,
      action: () => {
        setOpenedInvestmentFormId(id);
        setIsEdit(true);
      },
      disabled,
    },
    {
      id: 'delete-investment',
      icon: DeleteSvg,
      action: () => setCurrentDeleteInvestment(investments.find((investment) => investment.id === id) || null),
      disabled,
    },
  ];

  return (
    <div className='rounded-2xl bg-violet-100 p-6'>
      <Table columns={INVESTMENTS_TABLE_COLUMNS}>
        {investments?.length && !isLoading ? (
          <>
            {investments.map((investment, index) => (
              <InvestmentsTableItem
                key={`${investment.id} + ${index}`}
                actionsContent={
                  <ButtonsActions
                    className='[&_button]:w-auto'
                    anchorPrefix={investment.id}
                    actions={getInvestmentButtonsActions(
                      investment.id,
                      investment.isLastByCompany,
                      investment.isBlocked,
                    )}
                  />
                }
                extraRow={
                  <InvestmentsTableFormRow
                    isShown={openedInvestmentFormId === investment.id}
                    investment={investment}
                    onCancel={handleCloseInvestedForm}
                    isEditMode={isEdit}
                  />
                }
                {...investment}
              />
            ))}
          </>
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={7} />
        ) : (
          <NoInvestmentsAdded />
        )}
      </Table>
    </div>
  );
};

export default InvestmentsTable;
