import React, { useState } from 'react';

import { FORGOT_PASSWORD_PAGE_ADDITIONAL_TEXT, FORGOT_PASSWORD_PAGE_TITLE } from 'constants/forgot-password';
import { ROUTES } from 'constants/routes';
import { selectIsActionLoading } from 'modules/auth/selectors';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import {
  AuthLayout,
  HeaderInfo,
  ForgotPasswordForm,
  SuccessForgotPasswordMessage,
  NeedHelpLink,
} from 'page-components';
import { getTitleWithReplacedYourWord } from 'utils';

import { ButtonVariants } from '../constants/shared/button';
import { LinkButton } from '../shared-components';

const ForgotPassword = () => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);
  const isActionLoading = useAppSelector(selectIsActionLoading);
  const [email, setEmail] = useState<string | null>(null);

  return (
    <AuthLayout childrenClassName='relative h-[calc(100vh-74px)]'>
      <HeaderInfo
        className='whitespace-nowrap'
        title={getTitleWithReplacedYourWord(FORGOT_PASSWORD_PAGE_TITLE, entityName, isTypeEntity)}
      >
        {getTitleWithReplacedYourWord(FORGOT_PASSWORD_PAGE_ADDITIONAL_TEXT, entityName, isTypeEntity)}
      </HeaderInfo>

      <ForgotPasswordForm setEmail={setEmail} />

      {email && !isActionLoading && (
        <SuccessForgotPasswordMessage>
          {`Thank you, a password reset link has been sent to ${email} `}
        </SuccessForgotPasswordMessage>
      )}

      <div className='mt-10 flex'>
        Not a member?{` `}
        <LinkButton className='ml-1' variant={ButtonVariants.UNDERLINED_LINK} to={ROUTES.signUp}>
          Register now
        </LinkButton>
      </div>

      <NeedHelpLink />
    </AuthLayout>
  );
};

export default ForgotPassword;
