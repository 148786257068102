import React, { FC, useEffect } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import {
  selectIsDueDiligenceEditable,
  selectIsDueDiligenceFinishedOrDeclined,
  selectDueDiligenceEditingSections,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import GenerateInviteLinkBlock from 'page-components/due-diligence/request-founder/GenerateInviteLinkBlock';
import { Button } from 'shared-components';

import InvitationProposalModal from '../invitation-proposal-modal/InvitationProposalModal';
import RequestFounderModal from '../request-founder-modal/RequestFounderModal';

type Props = {
  dueDiligenceId?: number;
  className?: string;
  isAdmin: boolean;
  isDueDiligenceLoaded?: boolean;
};

const RequestFounderBlock: FC<Props> = ({ className, dueDiligenceId, isAdmin, isDueDiligenceLoaded }) => {
  const isDueDiligenceFinishedOrDeclined = useAppSelector(selectIsDueDiligenceFinishedOrDeclined);
  const isDueDiligenceEditable = useAppSelector(selectIsDueDiligenceEditable);
  const dueDiligenceEditingSections = useAppSelector(selectDueDiligenceEditingSections);

  const location = useLocation();
  const navigate = useNavigate();

  const { shownInvitationProposal = '' } = (location?.state as { shownInvitationProposal: boolean }) || {};

  const { onOpen, ...modalState } = useModal();
  const { onOpen: handleOpenInvitationProposalModal, ...invitationProposalModal } = useModal();

  useEffect(() => {
    if (dueDiligenceId && isAdmin && shownInvitationProposal && isDueDiligenceLoaded) {
      handleOpenInvitationProposalModal();
      navigate(location.pathname, { replace: true });
    }
  }, [
    dueDiligenceId,
    handleOpenInvitationProposalModal,
    isAdmin,
    location.pathname,
    navigate,
    shownInvitationProposal,
    isDueDiligenceLoaded,
  ]);

  return (
    <>
      <RequestFounderModal {...modalState} />
      <InvitationProposalModal handleOpenInvitationModal={onOpen} {...invitationProposalModal} />

      <div
        className={cn(
          'w-full border border-grey-300 rounded-10 flex py-10 justify-center items-center flex-col md:flex-row gap-4 md:gap-0',
          className,
        )}
      >
        <div className='flex flex-col gap-4 items-center justify-center w-full md:w-[250px] px-4 md:px-0'>
          <div className='text-xl max-w-800 text-center'>
            <div className='font-medium'>Missing information?</div>
            <div className='font-bold'>Request information from Director/Founder?</div>
          </div>
          <div className='flex items-center flex-col md:flex-row gap-4 w-full md:w-auto'>
            <Button
              disabled={
                isDueDiligenceFinishedOrDeclined || !isDueDiligenceEditable || !!dueDiligenceEditingSections.length
              }
              className='w-full sm:w-[210px]'
              onClick={onOpen}
              variant={ButtonVariants.SECONDARY}
            >
              Send Request
            </Button>
            {isAdmin && (
              <>
                <div className='flex flex-col items-center justify-center'>or</div>
                <div className='flex flex-col items-center justify-center w-full md:w-auto'>
                  <GenerateInviteLinkBlock />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestFounderBlock;
