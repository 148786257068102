import React, { FC, useMemo } from 'react';

import {
  BUSINESS_DETAILS_IS_APPLY_COMPANY_FIELD,
  DueDiligencePersonsFormNamesEnum,
  SIGNIFICANT_CONTROL_TABLE_COLUMNS,
} from 'constants/due-diligence';
import { UseDueDiligenceBusinessDetailsReturnValues } from 'hooks/useDueDiligenceBusinessDetails';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import {
  IsSCPSSameAsInSCCChecked,
  selectChildrenDueDiligence,
  selectIsChildrenPersonsExists,
  selectRawBusinessDetails,
  selectSignificantControlPersons,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import SignificantControlTableItem from 'page-components/due-diligence/persons-block/PersonsTableItem';
import { FormContent } from 'shared-components';
import Table from 'shared-components/table';

interface Props {
  businessDetailsProps: UseDueDiligenceBusinessDetailsReturnValues;
  isEditing: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
}

const SignificantControlChildPersons: FC<Props> = ({ businessDetailsProps, isEditing, getUnfieldValue }) => {
  const businessDetails = useAppSelector(selectRawBusinessDetails);
  const significantControlPersons = useAppSelector(selectSignificantControlPersons);
  const isChildrenPersonsExists = useAppSelector(selectIsChildrenPersonsExists);
  const children = useAppSelector(selectChildrenDueDiligence);
  const isSCPSSameAsInSCCChecked = useAppSelector(IsSCPSSameAsInSCCChecked);

  const isShown = isChildrenPersonsExists && !significantControlPersons?.length;
  const childPersons = children && children.length > 0 ? children[0].significantControlPersons : null;

  const hasError = useMemo(() => {
    if (getUnfieldValue) {
      const unfieldValue = getUnfieldValue(DueDiligencePersonsFormNamesEnum.DIRECTORS);
      return unfieldValue && unfieldValue.asError;
    }
    return false;
  }, [getUnfieldValue]);

  return (
    <>
      {isShown && (
        <>
          <FormContent
            fields={[
              BUSINESS_DETAILS_IS_APPLY_COMPANY_FIELD(
                businessDetails?.businessName,
                children[0]?.businessDetails?.businessName,
              ),
            ]}
            register={businessDetailsProps.register}
            errors={businessDetailsProps.errors}
            isDisabled={!isEditing}
            onChange={businessDetailsProps.handleSaveField}
            className={hasError ? 'p-2 bg-opacity-20 bg-red-500 ml-0 pl-10' : ''}
          />
          {businessDetails?.isSCPsSameAsInSCC && (
            <Table columns={SIGNIFICANT_CONTROL_TABLE_COLUMNS} className='table-simplified-tr table-simplified-th'>
              {childPersons &&
                isSCPSSameAsInSCCChecked &&
                childPersons.map((person, index) => (
                  <SignificantControlTableItem key={`${person.fullName} ${index}`} {...person} />
                ))}
            </Table>
          )}
        </>
      )}
    </>
  );
};

export default SignificantControlChildPersons;
