import { createSelector } from '@reduxjs/toolkit';

import { SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { RootState } from 'modules/store';
import { formatFloatNumbers } from 'utils';

const selectState = (x: RootState) => x.dealsReducer;

export const selectIsLoadingDeals = createSelector(selectState, (state) => state.isLoading);

export const selectDeals = createSelector(selectState, (state) => state.deals);

export const selectDealsPaginationData = createSelector(selectState, ({ totalPages, totalDeals }) => ({
  totalPages,
  total: totalDeals,
}));

export const selectDealDetails = createSelector(selectState, (state) => {
  if (!state.dealDetails) {
    return state.dealDetails;
  }

  const { dealExchanges = [], ...restDealDetails } = state.dealDetails || {};
  const dealExchangesWithFormattedNumbers = dealExchanges?.map(
    ({ fundSharePrice, companyPrice, ...restDealExchange }) => ({
      ...restDealExchange,
      fundSharePrice: formatFloatNumbers(fundSharePrice),
      companyPrice: formatFloatNumbers(companyPrice),
    }),
  );

  return {
    ...restDealDetails,
    dealExchanges: dealExchangesWithFormattedNumbers,
  };
});

export const selectDealDetailsClosingDate = createSelector(selectState, (state) => state.dealDetails?.closingDate);

export const selectDashboardDeals = createSelector(selectState, (state) => state.dashboardDeals);

export const selectTotalDeals = createSelector(selectState, (state) => state.totalDeals);

export const selectDealsOptions = createSelector(selectState, (state) => state.dealsOptions);

export const selectIsLoadingMetrics = createSelector(selectState, (state) => state.dealsMetrics.isLoading);

export const selectDealsTotalInStagesMetrics = createSelector(selectState, (state) => state.dealsMetrics.totalInStages);

export const selectDealsTotalStartedMetrics = createSelector(selectState, (state) => state.dealsMetrics.totalStarted);

export const selectDealsTotalFinishedMetrics = createSelector(selectState, (state) => state.dealsMetrics.totalFinished);

export const selectDealsPreviewTemplate = createSelector(selectState, (state) => state.previewDeals);

export const selectDealParameters = createSelector(selectState, ({ dealParameters }) => {
  if (!dealParameters) {
    return dealParameters;
  }

  const { daxiaSharePrice, ...restDealParameters } = dealParameters;

  return { daxiaSharePrice: formatFloatNumbers(daxiaSharePrice), ...restDealParameters };
});

export const selectDealParametersId = createSelector(selectState, ({ dealParameters }) => dealParameters?.id);

export const selectDealsParameters = createSelector(selectState, ({ dealsParameters, isLoading }) => ({
  ...dealsParameters,
  isLoading,
}));

export const selectDealParametersMainInfo = createSelector(selectState, ({ dealParameters }) => ({
  companyId: dealParameters?.companyId,
  companyName: dealParameters?.companyName,
  companyNumber: dealParameters?.companyNumber,
  status: dealParameters?.status,
  id: dealParameters?.id,
}));

export const selectCompanyDealPredefinedData = createSelector(selectState, ({ companyDealPredefinedData }) => {
  if (!companyDealPredefinedData) {
    return companyDealPredefinedData;
  }

  const { fundSharePrice, ...restCompanyDealPredefinedData } = companyDealPredefinedData || {};

  return { fundSharePrice: formatFloatNumbers(fundSharePrice), ...restCompanyDealPredefinedData };
});

export const selectCompanyDealPredefinedClassOfShares = createSelector(selectState, ({ companyDealPredefinedData }) =>
  companyDealPredefinedData?.companySharePrice?.map(({ classOfShares }) => classOfShares),
);

export const selectDealDetailsDocumentsUrl = createSelector(
  selectState,
  ({ dealDetails }) => dealDetails?.documentsUrls,
);

export const selectDealStages = createSelector(selectState, ({ dealStages }) => dealStages);

export const selectDealStagesLength = createSelector(selectState, ({ dealStages }) => dealStages?.length);

export const selectInvestorDealStages = createSelector(
  selectState,
  ({ dealStages }) =>
    dealStages.filter(
      ({ stage }) => stage !== SHARE_EXCHANGE_STAGES.APPROVAL && stage !== SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE,
    ), // We need to filter out approval && ready_to_complete stages from investor deal stages
);

export const selectIsDealStagesExists = createSelector(selectState, ({ dealStages }) => !!dealStages?.length);

export const selectDealParametersClosingDatesInfo = createSelector(selectState, ({ dealParameters }) => ({
  availableClosingDates: dealParameters?.closingDatesAvailableForOverview || [],
  closingDates: dealParameters?.closingDates?.map(({ closingDate }) => closingDate) || [],
}));

export const selectDealsProgressData = createSelector(selectState, ({ dealsProgress }) => dealsProgress);

export const selectDealStatus = createSelector(selectState, ({ dealDetails }) => dealDetails?.status);
