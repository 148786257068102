import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { CreateFeeFormValues, Fee, GetListPayload, MetaPage } from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class FeesApis {
  constructor(private url: string) {}

  getAllFees(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Fee[]; meta: MetaPage }>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  createFee(payload: CreateFeeFormValues, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: Fee }>(`${this.url}`, payload, config);
  }
}

export const adminFeesApi = new FeesApis('/admin/fees');
