import React, { FC, useState, Dispatch, SetStateAction } from 'react';

import cn from 'classnames';

import { TableRowVariants } from 'constants/shared';
import { DueDiligenceSelectedDocumentTypeFunction, FounderModifiedCompany } from 'interfaces';
import { Checkbox } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

interface Props {
  handleFilter: (filterValue: string) => void;
  disabledAction?: boolean;
  isHighlighted?: boolean;
  onOpenUploadDocumentModal?: DueDiligenceSelectedDocumentTypeFunction;
  companyId: number;
  companyName: string;
  companyNumber: string;
  confirmed: boolean;
  setModifiedCompanies: Dispatch<SetStateAction<Array<FounderModifiedCompany>>>;
}

const ConfirmFoundersModalTableRow: FC<Props> = ({
  companyId,
  companyName,
  companyNumber,
  confirmed,
  setModifiedCompanies,
}) => {
  const [isChecked, setIsChecked] = useState(confirmed);

  const handleSelectCompany = () => {
    setIsChecked(!isChecked);

    setModifiedCompanies((prevModifiedCompanies) => {
      const stringCompanyId = companyId.toString();

      if (prevModifiedCompanies.some(({ companyId: modifiedCompanyId }) => modifiedCompanyId === stringCompanyId)) {
        return prevModifiedCompanies.filter(
          ({ companyId: modifiedCompanyId }) => modifiedCompanyId !== stringCompanyId,
        );
      }

      return [...prevModifiedCompanies, { confirmed: !confirmed, companyId: stringCompanyId }];
    });
  };

  return (
    <TableRow
      className={isChecked ? 'bg-violet-200 border-none' : 'odd:bg-grey-100 table-selected-th'}
      variant={TableRowVariants.SIMPLIFIED}
    >
      <TableData className={cn('pl-5', { 'text-violet-600': isChecked })}>{companyName}</TableData>
      <TableData>{companyNumber}</TableData>
      <TableData>
        <div className='w-full h-full'>
          <Checkbox checked={isChecked} onChange={handleSelectCompany} />
        </div>
      </TableData>
    </TableRow>
  );
};

export default ConfirmFoundersModalTableRow;
