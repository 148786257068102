import React, { FC } from 'react';

import cn from 'classnames';
import { format } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { DealShareExchangeDetails } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

interface Props extends Omit<DealShareExchangeDetails, 'closingDate'> {
  closingDate?: string;
  isLast?: boolean;
}

const ShareExchangeDetailsTableRow: FC<Props> = ({
  classOfShares,
  noExchangedShares,
  companyPrice,
  noOfShares,
  fundSharePrice,
  closingDate,
  isLast,
}) => {
  return (
    <TableRow className={cn({ 'border-0 [&_td]:pb-0': isLast })}>
      <TableData>{classOfShares}</TableData>
      <TableData>{noOfShares}</TableData>
      <TableData>{companyPrice}</TableData>
      <TableData>{fundSharePrice}</TableData>
      <TableData>{noExchangedShares}</TableData>
      <TableData>{closingDate ? format(new Date(closingDate), DAY_FORMAT_WITH_SLASHES) : null}</TableData>
    </TableRow>
  );
};

export default ShareExchangeDetailsTableRow;
