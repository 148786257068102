import React from 'react';

import { useParams } from 'react-router-dom';

import { ShareExchangeStatuses } from 'constants/share-exchange';
import { selectIsAdmin } from 'modules/current-user/selectors';
import { selectCanViewInvestmentProposal } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { LinkButton } from 'shared-components';
import WithCompanyProfileLinkContainer from 'shared-components/WithCompanyProfileLinkContainer';
import { getAdminCompanyIacLink } from 'utils';

import { ButtonVariants } from '../../constants/shared/button';

interface Props {
  isLoading?: boolean;
  status?: ShareExchangeStatuses;
}

const DueDiligenceHeaderLinks = ({ isLoading, status }: Props) => {
  const { id } = useParams();

  const canViewInvestmentProposal = useAppSelector(selectCanViewInvestmentProposal);

  const isAdmin = useAppSelector(selectIsAdmin);

  if (!isAdmin) return null;

  return (
    <WithCompanyProfileLinkContainer className='flex flex-col md:flex-row gap-4 mt-6 ' id={id} isLoading={isLoading}>
      <LinkButton
        variant={ButtonVariants.SECONDARY}
        className='w-full md:w-100'
        hidden={
          status === ShareExchangeStatuses.DECLINED ||
          status === ShareExchangeStatuses.ARCHIVED ||
          !canViewInvestmentProposal
        }
        disabled={!canViewInvestmentProposal}
        to={getAdminCompanyIacLink(id)}
      >
        IAC
      </LinkButton>
    </WithCompanyProfileLinkContainer>
  );
};

export default DueDiligenceHeaderLinks;
