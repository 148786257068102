import React from 'react';

const TooltipV1 = ({ payload }: any) => {
  if (!payload?.length) return null;

  return (
    <div className='relative w-[70px] h-6 flex items-center justify-center bg-lightGreen-800 text-white'>
      <p>{payload[0].value}</p>
      <div className='w-0 h-0 absolute -bottom-1.5 border-t-[6px] border-t-lightGreen-800 border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent' />
    </div>
  );
};

export default TooltipV1;
