import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import Typography from 'shared-components/Typography';

export interface Props {
  text: string;
  secondPartText?: string;
  linkText: string;
  href: string;
  isDownload?: boolean;
  className?: string;
}

const DescriptionWithLink: FC<Props> = ({ text, linkText, secondPartText, href, isDownload, className }) => {
  if (!text || !linkText) return null;

  return (
    <Typography className={twMerge('py-6 text-grey-500', className)}>
      {text}{' '}
      <a className='text-blue-600 underline' href={href} download={isDownload}>
        {linkText}
      </a>{' '}
      {secondPartText}
    </Typography>
  );
};

export default DescriptionWithLink;
