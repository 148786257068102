import React, { ReactNode, FC } from 'react';

type Props = {
  children: ReactNode;
};

const SuccessForgotPasswordMessage: FC<Props> = ({ children }) => (
  <div className='px-3 py-2 mt-6 rounded-md bg-lightBlue-600 text-lightBlue-700'>{children}</div>
);

export default SuccessForgotPasswordMessage;
