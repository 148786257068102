import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  INVESTMENTS_DOWNLOAD_TEMPLATE_PATH,
  INVESTMENTS_PAGE_DESCRIPTION,
  INVESTMENTS_PAGE_DESCRIPTION_SECOND_PART,
  INVESTMENTS_PAGE_DOWNLOAD_TEXT,
  INVESTMENTS_PAGE_PREFERRED_WAY_TEXT,
  INVESTMENTS_PAGE_TITLE,
  SUCCESS_ADD_INVESTMENT_MODAL_PROMPT_TITLE,
} from 'constants/investments';
import { COMPANY_WITH_SHARE_EXCHANGE_STATUSES, SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import useDeleteInvestment from 'hooks/useDeleteInvestment';
import useInvestmentsQualificationQuestionsModals from 'hooks/useInvestmentsQualificationQuestionsModals';
import useStartInvestmentShareExchange from 'hooks/useStartInvestmentShareExchange';
import { Investment } from 'interfaces';
import { selectSelfId, selectUserEntityData } from 'modules/current-user/selectors';
import { getCompanyDealPredefinedData } from 'modules/deals/action';
import {
  getInvestments,
  requestShareExchange,
  resetReviewInvestments,
  uploadInvestments,
} from 'modules/investments/action';
import { selectAllInvestments } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  AddInvestmentForm,
  DashboardLayout,
  InvestmentsButtonsControl,
  InvestmentsQualificationQuestionsModals,
  InvestmentsTable,
  ReviewUploadedInvestmentsModal,
  StartInvestmentShareExchangeModal,
  UploadInvestmentsModal,
} from 'page-components';
import { AlertModal, Constraint, DescriptionWithLink, TitleWithDescription } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getLinkWithOriginPrefix, getTitleWithReplacedYourWord } from 'utils';

import { TypographyVariants } from '../constants/shared/typography';

const INVESTMENTS_PER_PAGE = 1000;

const Investments = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [processingInvestment, setProcessingInvestment] = useState<Investment | null>(null);
  const [isLoadingShareExchangeRequest, setIsLoadingShareExchangeRequest] = useState(false);

  const { onOpen: onOpenUploadModal, onClose: onCloseUploadModal, ...uploadModalState } = useModal();
  const { onOpen: onOpenReviewModal, onClose: onCloseReviewModal, ...reviewModalState } = useModal();

  const {
    onOpen: onOpenSuccessAddInvestmentPromptModal,
    onClose: onCloseSuccessAddInvestmentPromptModal,
    isOpen: isOpenSuccessAddInvestmentPromptModal,
  } = useModal();

  const {
    processingShareExchangeId,
    setProcessingShareExchangeId,
    isOpenQualificationQuestions,
    onOpenQualificationQuestions,
    isOpenSuccessShareExchangeRequestModal,
    onCloseSuccessShareExchangeRequestModal,
    onOpenSuccessShareExchangeRequestModal,
    closeQualificationQuestions,
  } = useInvestmentsQualificationQuestionsModals();

  const { isOpenStartShareExchange, onOpenStartShareExchange, onCloseStartShareExchange, onSuccessStartShareExchange } =
    useStartInvestmentShareExchange();

  const {
    isOpen: isDeleteInvestmentModalOpen,
    isLoading: isDeleting,
    changeDeletingInvestment,
    onDeleteInvestment,
    resetDeletingInvestment,
    modalTitle,
  } = useDeleteInvestment();

  const allInvestments = useAppSelector(selectAllInvestments);
  const selfId = useAppSelector(selectSelfId);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const [openedInvestmentFormId, setOpenedInvestmentFormId] = useState('');

  const investmentsTemplateDownloadPath = getLinkWithOriginPrefix(INVESTMENTS_DOWNLOAD_TEMPLATE_PATH);

  const loadInvestments = useCallback(() => {
    dispatch(getInvestments({ per_page: INVESTMENTS_PER_PAGE }));
  }, [dispatch]);

  const closeReviewModal = () => {
    dispatch(resetReviewInvestments());
    onCloseReviewModal();
  };

  const onBack = () => navigate(-1);

  const onSubmitUploadInvestments = async ([investmentsFile]: File[]): Promise<void> => {
    window.scrollTo(0, 0);

    await dispatch(uploadInvestments(investmentsFile))
      .unwrap()
      .then(() => {
        onOpenReviewModal();
      });
  };

  const onSuccessSubmitQualificationQuestions = () => {
    loadInvestments();
    closeQualificationQuestions();
    onOpenSuccessShareExchangeRequestModal();
    setProcessingInvestment(null);
  };

  const onSuccessCancelShareExchangeQuestions = () => {
    closeQualificationQuestions();
    setProcessingInvestment(null);
  };

  const onConfirmStartRequestPrompt = () => {
    if (!processingInvestment) {
      return;
    }

    setIsLoadingShareExchangeRequest(true);

    dispatch(
      requestShareExchange({
        id: Number(processingInvestment.companyId),
        isResubmit: false,
        selfId,
      }),
    )
      .unwrap()
      .then(({ data: response }) => {
        onCloseSuccessAddInvestmentPromptModal();

        if (response?.status === SHARE_EXCHANGE_STATUSES.INITIATED && response?.id) {
          onOpenQualificationQuestions();
          setProcessingShareExchangeId(response.id);

          return;
        }

        onOpenSuccessShareExchangeRequestModal();
      })
      .finally(() => {
        setIsLoadingShareExchangeRequest(false);
      });
  };

  const onSuccessAddInvestment = (investment: Investment) => {
    if (investment?.userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SER) {
      setProcessingInvestment(investment);
      onOpenSuccessAddInvestmentPromptModal();
    }

    if (investment?.userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE) {
      dispatch(getCompanyDealPredefinedData({ companyId: Number(investment.companyId) }));
      onOpenStartShareExchange();
    }
  };

  useEffect(() => {
    loadInvestments();
  }, [loadInvestments]);

  return (
    <>
      <StartInvestmentShareExchangeModal
        isOpen={isOpenStartShareExchange}
        onClose={onCloseStartShareExchange}
        onSuccessStartShareExchange={onSuccessStartShareExchange}
      />

      <AlertModal
        isOpen={isOpenSuccessAddInvestmentPromptModal}
        onClose={onCloseSuccessAddInvestmentPromptModal}
        onSubmit={onConfirmStartRequestPrompt}
        title={SUCCESS_ADD_INVESTMENT_MODAL_PROMPT_TITLE}
        isLoading={isLoadingShareExchangeRequest}
        submitButtonVariant={ButtonVariants.PRIMARY}
        backButtonVariant={ButtonVariants.SECONDARY}
        submitButtonText='Approve'
      />

      <InvestmentsQualificationQuestionsModals
        processingShareExchangeId={processingShareExchangeId}
        isOpenQualificationQuestions={isOpenQualificationQuestions}
        isOpenSuccessShareExchangeRequestModal={isOpenSuccessShareExchangeRequestModal}
        closeQualificationQuestions={closeQualificationQuestions}
        onCloseSuccessShareExchangeRequestModal={onCloseSuccessShareExchangeRequestModal}
        onSuccessSubmitQualificationQuestions={onSuccessSubmitQualificationQuestions}
        onSuccessCancelShareExchangeQuestions={onSuccessCancelShareExchangeQuestions}
      />

      <UploadInvestmentsModal
        {...uploadModalState}
        investmentsTemplateDownloadPath={investmentsTemplateDownloadPath}
        onClose={onCloseUploadModal}
        onSubmitUploadInvestment={onSubmitUploadInvestments}
      />

      <ReviewUploadedInvestmentsModal {...reviewModalState} onClose={closeReviewModal} />

      <AlertModal
        isOpen={isDeleteInvestmentModalOpen}
        isLoading={isDeleting}
        title={modalTitle}
        onSubmit={onDeleteInvestment}
        onClose={resetDeletingInvestment}
        submitButtonText='Yes'
        backButtonText='No'
      />

      <DashboardLayout>
        <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
          <TitleWithDescription
            title={getTitleWithReplacedYourWord(INVESTMENTS_PAGE_TITLE, entityName, isTypeEntity)}
            titleVariant={TypographyVariants.MEDIUM}
          />
          <DescriptionWithLink
            className='pb-0 pt-2'
            text={getTitleWithReplacedYourWord(INVESTMENTS_PAGE_DESCRIPTION, entityName, isTypeEntity)}
            secondPartText={INVESTMENTS_PAGE_DESCRIPTION_SECOND_PART}
            linkText={INVESTMENTS_PAGE_DOWNLOAD_TEXT}
            href={investmentsTemplateDownloadPath}
            isDownload
          />
          <Typography className='text-grey-500'>{INVESTMENTS_PAGE_PREFERRED_WAY_TEXT}</Typography>

          <AddInvestmentForm
            investmentsLength={allInvestments?.length}
            disabledLoading={Boolean(openedInvestmentFormId)}
            onSuccessAddInvestmentCallback={onSuccessAddInvestment}
            className='relative'
          />

          <div className='mb-10 mt-6'>
            <InvestmentsTable
              setCurrentDeleteInvestment={changeDeletingInvestment}
              investments={allInvestments}
              openedInvestmentFormId={openedInvestmentFormId}
              setOpenedInvestmentFormId={setOpenedInvestmentFormId}
            />
          </div>

          <InvestmentsButtonsControl openUploadModal={onOpenUploadModal} onBack={onBack} />
        </Constraint>
      </DashboardLayout>
    </>
  );
};

export default Investments;
