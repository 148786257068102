import React from 'react';

import { ReactComponent as DocumentSvg } from 'assets/svg/documents.svg';
import { TypographyVariants } from 'constants/shared/typography';
import Typography from 'shared-components/Typography';

const DocumentsViewerError = () => {
  return (
    <div className='flex items-center flex-col pt-20 text-dark'>
      <DocumentSvg className='w-[96px] h-[96px] mb-6' />
      <Typography className='mb-4' tag='h4' variant={TypographyVariants.LARGE}>
        Can&lsquo;t view document
      </Typography>
      <p>Sorry, this document can&lsquo;t be viewed. Please, download it</p>
    </div>
  );
};

export default DocumentsViewerError;
