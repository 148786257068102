import { set, format, parse, startOfMonth, setMonth } from 'date-fns';

import { MONTH_FORMAT_WITH_DASH } from 'constants/dateFormats';
import { DateRange } from 'interfaces/global.interfaces';

export const parseDateStringToUnix = (date: string, format: string): number =>
  parse(date, format, new Date()).getTime();

export const calculateMillisecondsOfSortedRange = (sortedRanges: Array<{ from: Date; to: Date }>) =>
  sortedRanges.reduce((acc, currentRange, i, arr) => {
    if (i === 0 || currentRange.from > arr[i - 1].to) {
      // We need to take last day of the month because we consider that if month selected then
      // it's full month. For example: we select May 2020, so we expect that selected month ends 31 May 2020
      // By default in input when you select any month, it will be 1st day of the month
      const nextCurrentRangeToMonth = setMonth(getMidnightDate(currentRange.to), currentRange.to.getMonth() + 1);
      const currentRangeToMonthLastDay = new Date(new Date(nextCurrentRangeToMonth).getTime() - 1);

      return acc + (currentRangeToMonthLastDay.getTime() - currentRange.from.getTime());
    } else if (currentRange.to > arr[i - 1].to) {
      return acc + (currentRange.to.getTime() - arr[i - 1].to.getTime());
    }

    return acc;
  }, 0);

export const getYearsFromMilliseconds = (milliseconds: number) =>
  Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 365));

export const getTotalYears = (dateRanges: DateRange[]) => {
  const sortedRanges: Array<{ from: Date; to: Date }> = dateRanges
    .filter((range) => range.from && (range.to || range.tillNow))
    .map((range) => ({
      from: new Date(range.from),
      to: range.to ? new Date(range.to) : startOfMonth(new Date()), // If to date then we can consider that tillNow is set to true
    }))
    .sort((a, b) => a.from.getTime() - b.from.getTime());

  const totalMilliseconds = calculateMillisecondsOfSortedRange(sortedRanges);

  return getYearsFromMilliseconds(totalMilliseconds);
};

export const getMinMaxDates = (ranges: DateRange[]): { minDate?: Date; maxDate?: Date } => {
  return ranges.reduce((acc, range) => {
    const toDate = range.tillNow || !range.to ? startOfMonth(new Date()) : new Date(range.to);
    const fromDate = new Date(range.from);

    return {
      minDate: !acc?.minDate || fromDate < acc?.minDate ? fromDate : acc.minDate,
      maxDate: !acc?.maxDate || toDate > acc?.maxDate ? toDate : acc.maxDate,
    };
  }, {} as { minDate?: Date; maxDate?: Date });
};

export const convertDateToInputFormat = (date: Date): string => date.toISOString().split('T')[0];

export const convertDateToInputMonthFormat = (date: Date): string => format(date, MONTH_FORMAT_WITH_DASH);

export const getMidnightDate = (date: Date): Date => set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
