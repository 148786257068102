import React, { FC } from 'react';

import { format, parse } from 'date-fns';
import { SubmitHandler } from 'react-hook-form';

import { MONTH_FORMAT_WITH_DASH, MONTH_FORMAT_WITH_SLASHES, YEAR_MONTH_FORMAT_WITH_DASH } from 'constants/dateFormats';
import { TableRowVariants } from 'constants/shared';
import { parseDateStringToUnix } from 'helpers';
import { EditInvestmentPayload, Investment, InvestmentInput, InvestmentPayload } from 'interfaces';
import { addInvestment, editInvestment } from 'modules/investments/action';
import { selectIsActionLoading } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AddInvestmentForm } from 'page-components';
import { TableData, TableRow } from 'shared-components/table';

import AddInvestmentFormButtons from '../AddInvestmentFormButtons';

interface Props {
  isShown: boolean;
  investment: Investment;
  onCancel: VoidFunction;
  onSubmit?: VoidFunction;
  isEditMode?: boolean;
  colSpan?: number;
  onSuccessEdit?: VoidFunction;
}

const InvestmentsTableFormRow: FC<Props> = ({
  isShown,
  onCancel,
  investment,
  isEditMode,
  colSpan = 7,
  onSuccessEdit,
}) => {
  const dispatch = useAppDispatch();
  const isActionLoading = useAppSelector(selectIsActionLoading);

  const {
    companyName,
    companyNumber,
    id,
    classOfShares,
    totalShares,
    pricePerShare,
    investmentDate,
    originalInvestmentPrice,
  } = investment;

  const editInvestmentDefaultValues: Omit<InvestmentInput, 'companyNumber' | 'amountInvested'> = {
    classOfShares: classOfShares || '',
    totalShares: String(totalShares),
    pricePerShare,
    investmentDate: investmentDate
      ? format(parse(investmentDate, MONTH_FORMAT_WITH_SLASHES, new Date()), MONTH_FORMAT_WITH_DASH)
      : '',
    originalInvestmentPrice,
  };

  const investmentDefaultValues: Partial<InvestmentInput> = {
    companyNumber: {
      label: companyName,
      value: companyNumber,
    },
    ...(isEditMode && editInvestmentDefaultValues),
  };

  const onSubmit: SubmitHandler<InvestmentInput> = ({ classOfShares, ...data }) => {
    const investmentDate = format(
      parseDateStringToUnix(data?.investmentDate, YEAR_MONTH_FORMAT_WITH_DASH),
      MONTH_FORMAT_WITH_SLASHES,
    );

    const payload = {
      ...data,
      investmentDate,
      classOfShares: typeof classOfShares === 'string' ? classOfShares : classOfShares?.value,
    };

    if (isEditMode) {
      dispatch(editInvestment({ ...payload, id } as EditInvestmentPayload)).then(() => {
        onCancel();
        if (onSuccessEdit) {
          onSuccessEdit();
        }
      });
    } else {
      dispatch(addInvestment(payload as InvestmentPayload)).then(onCancel);
    }
  };

  if (!isShown) return null;

  return (
    <TableRow variant={TableRowVariants.SIMPLIFIED}>
      <TableData colSpan={colSpan}>
        <AddInvestmentForm
          anchorPrefix='add-investment'
          className='mt-0 flex py-6 space-x-2'
          onSubmitForm={onSubmit}
          defaultValues={investmentDefaultValues}
          customButtons={<AddInvestmentFormButtons isLoading={isActionLoading} onCancel={onCancel} />}
          withoutLabels
        />
      </TableData>
    </TableRow>
  );
};

export default InvestmentsTableFormRow;
