import React from 'react';

const AddInvestmentFormSkeleton = () => {
  return (
    <div className='grid grid-cols-1 xl:grid-cols-[1fr_1fr_1fr] gap-6 whitespace-nowrap mt-0 py-4'>
      {Array.from(Array(6).keys()).map((key) => (
        <div key={key} className='skeleton-loader h-[56px] w-full' />
      ))}
      <div className='flex'>
        <div className='skeleton-loader h-[56px] w-1/2' />
        <div className='skeleton-loader h-[56px] w-1/2 ml-6' />
      </div>
    </div>
  );
};

export default AddInvestmentFormSkeleton;
