import React from 'react';

import Typography from 'shared-components/Typography';

const CompanyProfileFinancialItem = () => {
  return (
    <div className='basis-1/2'>
      <div className='flex items-center'>
        <div className='mr-2 w-2.5 h-2.5 rounded-sm bg-red-500' />
        <h5 className='text-grey-500'>Year Ended</h5>
      </div>
      <div className='mt-2 text-blue-800 font-medium text-2xl flex'>
        <Typography>Nov 2021</Typography>
        <p className='text-red-500 text-xs ml-1.5'>
          -£100.84k (-10%)
          <br /> <span className='text-grey-500'>vs previous year</span>
        </p>
      </div>
    </div>
  );
};

export default CompanyProfileFinancialItem;
