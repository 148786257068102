import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { UsersState } from 'interfaces';

import { USERS_SLICE_NAME } from './action';
import {
  getUsersReducer,
  getUserReducer,
  getUserPermissionsReducer,
  inviteUserReducer,
  resetUserDataReducer,
  updateUserPasswordReducer,
  sendDirectorEntityInviteReducer,
} from './reducers';

const initialState: UsersState = {
  isLoading: false,
  isPermissionsLoading: false,
  isProfileSettingsActionLoading: false,
  isProfileSettingsActionCalled: false,
  users: null,
  userData: null,
  permissions: {},
  enabledPermissions: [],
  ...META_INITIAL_STATE,
};

export const usersSlice = createSlice({
  name: USERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getUsersReducer(builder);
    getUserReducer(builder);
    getUserPermissionsReducer(builder);
    inviteUserReducer(builder);
    resetUserDataReducer(builder);
    sendDirectorEntityInviteReducer(builder);
    updateUserPasswordReducer(builder);
  },
});

export const usersReducer = usersSlice.reducer;
