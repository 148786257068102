import React, { Dispatch, PropsWithChildren, ReactElement, SetStateAction, useMemo } from 'react';

import cn from 'classnames';
import { Path, FieldValues, useFormContext } from 'react-hook-form';

import {
  DUE_DILIGENCE_SIGNIFICANT_CONTROL_ADD_PERSON_TEXT,
  DueDiligencePersonsFormNamesEnum,
  SIGNIFICANT_CONTROL_TABLE_COLUMNS,
} from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import {
  DueDiligencePersonInput,
  PersonsDeletedAddress,
  SignificantControlPerson,
  SignificantControlPersonAddressInput,
  TableColumns,
} from 'interfaces';
import { Button } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import NoPersonsAdded from './NoPersonsAdded';
import PersonsFormRow from './PersonsFormRow';
import SignificantControlTableItem from './PersonsTableItem';

interface Props {
  columns?: TableColumns[];
  fields: DueDiligencePersonInput[];
  personsData: SignificantControlPerson[] | null;
  name: string;
  isEditing: boolean;
  hasNominee?: boolean;
  noPersonsAddedText?: string;
  addAddressText?: string;
  hideCheckbox?: boolean;
  setDeletedAddresses: Dispatch<SetStateAction<PersonsDeletedAddress[]>>;
  handleDeleteSignificantPerson: (index: number, id: number, isNew?: boolean) => void;
  bypassValidation?: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
  haveAccessToDelete?: boolean;
  onClickAddPersons: () => void;
  addPersonsText?: string;
  footerClassName?: string;
}

const PersonsTable = <T extends FieldValues>({
  columns,
  name,
  isEditing,
  personsData,
  hasNominee = false,
  noPersonsAddedText,
  hideCheckbox,
  addAddressText,
  fields,
  setDeletedAddresses,
  handleDeleteSignificantPerson,
  bypassValidation,
  haveAccessToDelete,
  getUnfieldValue,
  onClickAddPersons,
  addPersonsText,
  footerClassName,
}: PropsWithChildren<Props>): ReactElement => {
  const { getValues, watch } = useFormContext<T>();

  const hasError = useMemo(() => {
    if (getUnfieldValue) {
      const unfieldValue = getUnfieldValue(DueDiligencePersonsFormNamesEnum.DIRECTORS);
      return unfieldValue && unfieldValue.asError;
    }
    return false;
  }, [getUnfieldValue]);

  const hasValues = !!getValues()?.[name]?.length;

  return (
    <div>
      {hasValues && (
        <Table
          columns={columns || SIGNIFICANT_CONTROL_TABLE_COLUMNS}
          className='table-simplified-tr table-simplified-th'
        >
          {isEditing
            ? fields.map((field, index) => (
                <PersonsFormRow<T>
                  key={field.id}
                  isNew={field.isNew}
                  personId={(watch(`${name}.${index}.id` as Path<T>) as number) || 0}
                  addresses={
                    (watch(
                      `${name}.${index}.residentialAddresses` as Path<T>,
                    ) as SignificantControlPersonAddressInput[]) || []
                  }
                  index={index}
                  handleDeleteSignificantPerson={handleDeleteSignificantPerson}
                  setDeletedAddresses={setDeletedAddresses}
                  name={name}
                  hideCheckbox={hideCheckbox}
                  addAddressText={addAddressText}
                  bypassValidation={bypassValidation}
                  haveAccessToDelete={haveAccessToDelete}
                  getUnfieldValue={getUnfieldValue}
                />
              ))
            : null}

          {personsData === null ? (
            <TableSkeleton columnsNumber={SIGNIFICANT_CONTROL_TABLE_COLUMNS.length} rowsNumber={2} />
          ) : personsData?.length ? (
            !isEditing &&
            personsData.map((person, index) => (
              <SignificantControlTableItem hasNominee={hasNominee} key={`${person.fullName} ${index}`} {...person} />
            ))
          ) : null}
        </Table>
      )}
      <div
        className={cn(
          'px-10 py-6',
          { 'bg-grey-100 mt-6': !hasValues, 'border-2 border-red-500': hasError },
          footerClassName,
        )}
      >
        {!hasValues && <NoPersonsAdded text={noPersonsAddedText} />}
        {isEditing && (
          <div>
            <Button type='button' onClick={onClickAddPersons} variant={ButtonVariants.LINK} className='w-auto'>
              {addPersonsText || DUE_DILIGENCE_SIGNIFICANT_CONTROL_ADD_PERSON_TEXT}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonsTable;
