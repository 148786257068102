import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { TodosResponse, TodosState } from 'interfaces';

import { getTodos } from './action';

export function getTodosReducer(builder: ActionReducerMapBuilder<TodosState>) {
  builder.addCase(getTodos.pending, (state) => {
    state.todosList.isLoading = true;
  });

  builder.addCase(getTodos.fulfilled, (state, action: PayloadAction<TodosResponse>) => {
    state.todosList.isLoading = false;
    state.todosList.list = action.payload.data;
  });

  builder.addCase(getTodos.rejected, (state) => {
    state.todosList.isLoading = false;
  });
}
