import React from 'react';

import { AUDIT_LOGS_PAGE_DESCRIPTION, AUDIT_LOGS_PAGE_TITLE } from 'constants/audit-log';
import { ConstraintVariants } from 'constants/shared';
import { DashboardLayout } from 'page-components';
import EntityAuditLogsTable from 'page-components/due-diligence/entity-audit-logs-table/EntityAuditLogsTable';
import { Constraint, TitleWithDescription } from 'shared-components';

const EntityAuditLogs = () => {
  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription
          descriptionClassName='pt-2'
          title={AUDIT_LOGS_PAGE_TITLE}
          description={AUDIT_LOGS_PAGE_DESCRIPTION}
        />
        <EntityAuditLogsTable />
      </Constraint>
    </DashboardLayout>
  );
};

export default EntityAuditLogs;
