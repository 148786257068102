import React, { FC } from 'react';

import { ReactComponent as CheckmarkSvg } from 'assets/svg/check-mark.svg';
import { TableRowVariants } from 'constants/shared';
import { getFromAndToDatesContent } from 'helpers';
import { DueDiligencePersonInput, SignificantControlPersonAddress } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  director: DueDiligencePersonInput;
  hasNominee?: boolean;
};

const SubmitResidentialAddressesTableRow: FC<Props> = ({ director, hasNominee }) => {
  const { fullName, dateOfBirth, checked, residentialAddresses, nominee } = director;

  const residentialAddress = residentialAddresses?.length ? residentialAddresses[0].address : '';

  return (
    <>
      <TableRow variant={TableRowVariants.SIMPLIFIED}>
        <TableData className='pl-1'>{fullName}</TableData>
        <TableData>{dateOfBirth}</TableData>
        {hasNominee && <TableData className='whitespace-normal'>{nominee}</TableData>}
        <TableData className='whitespace-normal'>{residentialAddress}</TableData>
        <TableData>{getFromAndToDatesContent(residentialAddresses[0] as SignificantControlPersonAddress)}</TableData>
        <TableData>
          {' '}
          {checked ? <CheckmarkSvg className='text-green-600 w-7 h-6 fill-green-600 mx-6' /> : null}
        </TableData>
      </TableRow>

      {residentialAddresses?.slice(1).map((address) => (
        <TableRow key={address.id} variant={TableRowVariants.SIMPLIFIED}>
          <TableData />
          <TableData />
          {hasNominee && <TableData />}
          <TableData className='whitespace-normal'>{address.address}</TableData>
          <TableData>{getFromAndToDatesContent(address as SignificantControlPersonAddress)}</TableData>
          <TableData />
        </TableRow>
      ))}
    </>
  );
};

export default SubmitResidentialAddressesTableRow;
