import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { CompaniesIacState } from 'interfaces';

import { COMPANIES_IAC_SLICE_NAME } from './action';
import { getCompaniesIacReducer } from './reducers';

export const initialState: CompaniesIacState = {
  list: [],
  isLoading: false,
  ...META_INITIAL_STATE,
};

const companiesIacSlice = createSlice({
  name: COMPANIES_IAC_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCompaniesIacReducer(builder);
  },
});

export const companiesIacReducer = companiesIacSlice.reducer;
