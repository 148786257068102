import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { MetaPage, GetListPayload, UserData } from 'interfaces';
import { Fund, GetFmUsersPayload } from 'interfaces/funds.interfaces';
import { stringifyFilterParams } from 'utils';

export class FundsApis {
  constructor(private url: string) {}

  getFunds(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Fund[]; meta: MetaPage }>(
      `${this.url}/funds?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getFundManagers(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Fund[]; meta: MetaPage }>(`${this.url}/fund-managers`, config);
  }

  getFundManagerUsersForInvite({ id, ...payload }: GetFmUsersPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: UserData[]; meta: MetaPage }>(
      `${this.url}/fund-managers/${id}/fmusers?${stringifyFilterParams(payload)}`,
      config,
    );
  }

  companyIacInviteExistingFmUser(
    {
      id,
      fmUserId,
    }: {
      id: number;
      fmUserId: number;
    },
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.patch<{ data: UserData[]; meta: MetaPage }>(
      `${this.url}/investment-proposals/${id}/invite-existing-fmusers/${fmUserId}`,
      config,
    );
  }

  deleteFund(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/funds/${id}`, config);
  }

  createFund(fund: Partial<Fund>, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/funds`, fund, config);
  }

  editFund({ id, ...fund }: Partial<Fund>, config?: AxiosRequestConfig) {
    return httpApiClient.put(`${this.url}/funds/${id}`, fund, config);
  }
}

export const adminFundsApis = new FundsApis('/admin');
export const userInvitesFundsApis = new FundsApis('/user-invites');
