import React from 'react';

import { Sector } from 'recharts';

import { getAbbreviateNumber } from 'utils';

const PieChartActiveShapeV2 = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  value,
  title,
  customValue,
}: any) => (
  <g>
    <g>
      <text
        x={cx}
        y={cy - 25}
        textAnchor='middle'
        dominantBaseline='central'
        alignmentBaseline='middle'
        fontSize='15'
        fill='#74726F'
      >
        {title}
      </text>
      <text
        x={cx}
        y={cy + 10}
        textAnchor='middle'
        dominantBaseline='middle'
        fill='#19294C'
        fontSize='40'
        fontWeight='500'
      >
        {getAbbreviateNumber(customValue || value)}
      </text>
    </g>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 1}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  </g>
);

export default PieChartActiveShapeV2;
