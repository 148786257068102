import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { dealParametersStatusesLabel } from 'constants/deals';
import { ButtonVariants } from 'constants/shared/button';
import { DealParameters } from 'interfaces';
import { Button, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';
import { getDealParametersLink } from 'utils';

const DealParametersTableRow: FC<DealParameters> = ({
  id,
  companyId,
  companyName,
  companyNumber,
  plainlyDealId,
  createdAt,
  status,
}) => {
  const navigate = useNavigate();

  // TODO - temporary solution
  const handleNavigateToDealParameters = () =>
    navigate(getDealParametersLink(id, true), {
      state: {
        companyId,
      },
    });

  return (
    <TableRow>
      <TableData>
        <Button className='w-40' variant={ButtonVariants.SECONDARY} onClick={handleNavigateToDealParameters}>
          View
        </Button>
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{companyName}</TableData>
      <TableData>{companyNumber}</TableData>
      <TableData>{plainlyDealId}</TableData>
      <TableData>{createdAt}</TableData>
      <TableData>
        <ColoredLabel {...dealParametersStatusesLabel[status]} />
      </TableData>
    </TableRow>
  );
};

export default DealParametersTableRow;
