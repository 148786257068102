import { useCallback, useEffect } from 'react';

import { Investment, MetaPayload, MetaState, UpdateSortingFunctionType } from 'interfaces';
import { getInvestments } from 'modules/investments/action';
import {
  selectAllInvestments,
  selectAllInvestmentsPaginationData,
  selectIsLoading,
} from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

import {
  usePaginationWithSearch,
  UsePaginationWithSearchReturnValues,
} from './use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from './use-table-sorting/useTableSorting';

const INVESTMENTS_PER_PAGE = 5;

type UseInvestmentsReturnValues = Omit<UsePaginationWithSearchReturnValues, 'query'> & {
  paginationData: MetaState;
  investments: Investment[];
  isLoading: boolean;
  updateSorting: UpdateSortingFunctionType;
  onLoadInvestments: VoidFunction;
};

const useInvestments = ({
  loadInvestments,
  per_page = INVESTMENTS_PER_PAGE,
}: {
  loadInvestments?: (data: MetaPayload) => void;
  per_page?: number;
}): UseInvestmentsReturnValues => {
  const dispatch = useAppDispatch();

  const paginationData = useAppSelector(selectAllInvestmentsPaginationData);
  const isInvestmentsLoading = useAppSelector(selectIsLoading);
  const investments = useAppSelector(selectAllInvestments);

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  const onLoadInvestments = useCallback(() => {
    const metaData = { page, per_page, sort: sorting.column, order: sorting.order, query };

    if (loadInvestments) loadInvestments(metaData);
    else dispatch(getInvestments(metaData));
  }, [dispatch, loadInvestments, page, per_page, query, sorting.column, sorting.order]);

  useEffect(() => {
    onLoadInvestments();
  }, [dispatch, onLoadInvestments]);

  return {
    paginationData,
    page,
    investments,
    isLoading: isInvestmentsLoading,
    updateSorting,
    setPage,
    handleFilter,
    onLoadInvestments,
  };
};

export default useInvestments;
