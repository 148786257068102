import React from 'react';

import cn from 'classnames';

import { ConstraintVariants } from 'constants/shared';
import { TypographyStyles } from 'constants/shared/typography';
import useShortCompanyInfo from 'hooks/useShortCompanyInfo';
import { DashboardLayout } from 'page-components';
import ArchivedDueDiligenceList from 'page-components/due-diligence/archived-due-diligence-list/ArchivedDueDiligenceList';
import { Constraint, DynamicPageTitle } from 'shared-components';

const ARCHIVED_COMPANY_DUE_DILIGENCE_TITLE = 'Archived Due Diligence';

const ArchivedCompanyDueDiligence = () => {
  const { id, companyName, isLoading } = useShortCompanyInfo();

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <DynamicPageTitle
          isLoading={isLoading}
          dynamicTitle={companyName}
          title={ARCHIVED_COMPANY_DUE_DILIGENCE_TITLE}
          className={cn('mb-16', TypographyStyles.large)}
        />
        <ArchivedDueDiligenceList id={id} />
      </Constraint>
    </DashboardLayout>
  );
};

export default ArchivedCompanyDueDiligence;
