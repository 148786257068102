import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { FundsState } from 'interfaces/funds.interfaces';

import { FUNDS_SLICE_NAME } from './action';
import { getFundsListReducer } from './reducers';

export const initialState: FundsState = {
  list: [],
  isLoading: false,
  ...META_INITIAL_STATE,
};

const fundsSlice = createSlice({
  name: FUNDS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getFundsListReducer(builder);
  },
});

export const fundsReducer = fundsSlice.reducer;
