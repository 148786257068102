import { createAsyncThunk } from '@reduxjs/toolkit';

import { fmUserIacApis } from 'apis';
import { showServerError } from 'helpers';
import { GetListPayload } from 'interfaces';

import { COMPANIES_SLICE_NAME } from '../companies/action';

export const COMPANIES_IAC_SLICE_NAME = 'companies-iac';

export const getCompaniesIacList = createAsyncThunk(
  `${COMPANIES_SLICE_NAME}/getCompaniesIac`,
  async (params: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await fmUserIacApis.getCompaniesIacList(params);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);
