export enum FeeStatuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
}

export const feeStatusesTextLabel: {
  [key in FeeStatuses]: string;
} = {
  [FeeStatuses.ACTIVE]: 'Active',
  [FeeStatuses.INACTIVE]: 'Inactive',
  [FeeStatuses.EXPIRED]: 'Expired',
};
