import React from 'react';

const ShareExchangeReviewPageSkeleton = () => {
  return (
    <>
      <div className='skeleton-loader w-full h-[80px] mb-9' />

      <div className='skeleton-loader w-full h-[100px] mb-9' />

      <div className='skeleton-loader w-full h-[200px] mb-9' />

      <div className='skeleton-loader w-full h-[100px] mb-9' />
    </>
  );
};

export default ShareExchangeReviewPageSkeleton;
