import React from 'react';

import { format, parseISO } from 'date-fns';

import { COMPANY_IAC_PAGE_SUBTITLE, companyIacStatusesColoredLabel } from 'constants/company-iac';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import {
  selectCompanyIacCompanyName,
  selectCompanyIacFundManager,
  selectCompanyIacFundName,
  selectCompanyIacStatus,
  selectLastCreatedAt,
} from 'modules/company-iac/selectors';
import { useAppSelector } from 'modules/store';
import { ColoredLabel, DetailsPageTitle, LabelWithValue } from 'shared-components';
import Typography from 'shared-components/Typography';

import CompanyIacHeaderLinks from './CompanyIacHeaderLinks';

const CompanyIacHeader = () => {
  const companyName = useAppSelector(selectCompanyIacCompanyName);
  const fundName = useAppSelector(selectCompanyIacFundName);
  const { name: fundManagerName } = useAppSelector(selectCompanyIacFundManager);
  const status = useAppSelector(selectCompanyIacStatus);
  const lastCreatedAt = useAppSelector(selectLastCreatedAt);

  const formattedLastCreatedAt = lastCreatedAt ? format(parseISO(lastCreatedAt), DAY_FORMAT_WITH_SLASHES) : null;

  return (
    <div className='pb-6 mb-6 border-b border-grey-300'>
      <div className='flex items-start md:items-center justify-between flex-col md:flex-row'>
        <DetailsPageTitle title={companyName} subtitle={COMPANY_IAC_PAGE_SUBTITLE}>
          {status && <ColoredLabel {...companyIacStatusesColoredLabel[status]} />}
        </DetailsPageTitle>

        <div className='flex flex-col xs:flex-row gap-2'>
          <div className='flex items-center xs:justify-end [&_button]:mr-8'>
            <LabelWithValue
              className='w-full whitespace-nowrap xs:mx-4'
              title='Last updated'
              content={formattedLastCreatedAt}
            />
          </div>
          <div className='text-blue-800 flex'>
            <div>
              <Typography>Fund</Typography>
              <Typography className='text-grey-500'>{fundName}</Typography>
            </div>

            {fundManagerName && (
              <div className='ml-8'>
                <Typography>Fund Manager</Typography>
                <Typography className='text-grey-500'>{fundManagerName}</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <CompanyIacHeaderLinks status={status} />
    </div>
  );
};

export default CompanyIacHeader;
