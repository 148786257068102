import React from 'react';

import {
  COMPANY_PROFILE_HOLDERS_TITLE,
  COMPANY_PROFILE_HOLDERS_COLUMNS,
  COMPANY_PROFILE_NO_HOLDERS,
} from 'constants/company-profile';
import { selectActiveCompanyHolders, selectIsCurrentCompanyLoading } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import CompanyProfileHoldersItem from './CompanyProfileHoldersItem';

const CompanyProfileHoldersSnippet = () => {
  const activeHolders = useAppSelector(selectActiveCompanyHolders);
  const isLoading = useAppSelector(selectIsCurrentCompanyLoading);

  return (
    <SnippetLayout className='mt-6' title={COMPANY_PROFILE_HOLDERS_TITLE}>
      {!isLoading && !activeHolders?.length ? (
        <div>{COMPANY_PROFILE_NO_HOLDERS}</div>
      ) : (
        <Table className='table-simplified-tr table-simplified-th' columns={COMPANY_PROFILE_HOLDERS_COLUMNS}>
          {activeHolders?.length ? (
            activeHolders.map((item) => <CompanyProfileHoldersItem key={item.name} {...item} />)
          ) : (
            <TableSkeleton rowsNumber={4} columnsNumber={3} />
          )}
        </Table>
      )}
    </SnippetLayout>
  );
};

export default CompanyProfileHoldersSnippet;
