import React from 'react';

const MyInvitesItemSkeleton = () => {
  return (
    <div className='flex justify-between  mb-3'>
      <div className='skeleton-loader h-5 w-3/6' />
      <div className='skeleton-loader h-5 w-1/6' />
    </div>
  );
};

export default MyInvitesItemSkeleton;
