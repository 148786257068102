import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminFundsApis } from 'apis';
import { showServerError } from 'helpers';
import { GetListPayload } from 'interfaces';
import { Fund } from 'interfaces/funds.interfaces';

export const FUNDS_SLICE_NAME = 'funds';

export const getFundsList = createAsyncThunk(
  `${FUNDS_SLICE_NAME}/getFundsList`,
  async (params: GetListPayload, { rejectWithValue }) => {
    try {
      const response = await adminFundsApis.getFunds(params);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const deleteFundById = createAsyncThunk(
  `${FUNDS_SLICE_NAME}/deleteFundById`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await adminFundsApis.deleteFund(id);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const createFund = createAsyncThunk(
  `${FUNDS_SLICE_NAME}/createFund`,
  async (payload: Partial<Fund>, { rejectWithValue }) => {
    try {
      const response = await adminFundsApis.createFund(payload);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const editFundById = createAsyncThunk(
  `${FUNDS_SLICE_NAME}/editFundById`,
  async (payload: Partial<Fund>, { rejectWithValue }) => {
    try {
      const response = await adminFundsApis.editFund(payload);
      return response.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);
