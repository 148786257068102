import React, { FC } from 'react';

import { ShareExchangeSubmittedAnswers } from 'interfaces';
import { RadioInputWithLabel } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props = {
  answers: ShareExchangeSubmittedAnswers[];
};

const ShareExchangeQualificationQuestionsList: FC<Props> = ({ answers }) => {
  return (
    <div>
      {answers.map(({ question, answers }) => (
        <div key={question} className='flex justify-between flex-col lg:flex-row mb-12'>
          <Typography>{question}</Typography>

          <div className='pr-12'>
            <div className='flex flex-col'>
              {answers.map((answer) => (
                <RadioInputWithLabel key={answer.title} checked={answer.marked} disabled label={answer.title} />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShareExchangeQualificationQuestionsList;
