import React, { FC } from 'react';

import cn from 'classnames';

import Typography from 'shared-components/Typography';

import CheckmarkWithTitle from '../checkmark-with-title/CheckmarkWithTitle';

const PASSWORD_STRENGTH_POINTS = [
  { id: 'length', title: 'At least 8 characters or more!', pattern: /^.{8,}$/ },
  { id: 'cases', title: 'A mixture of both uppercase and lowercase letters', pattern: /(?=.*[a-z])(?=.*[A-Z])/ },
  { id: 'numsAndLetters', title: 'A mixture of letters and numbers', pattern: /(?=.*\d)(?=.*[a-zA-Z])/ },
  {
    id: 'symbols',
    title: 'Inclusion of at least one special character, e.g., ! % # $ ]',
    pattern: /(?=.*[^a-zA-Z\d\s])/,
  },
];

export interface Props {
  password: string | undefined;
  className?: string;
}

const PasswordStrengthWidget: FC<Props> = ({ password = '', className }) => (
  <div className={cn('pt-4 pb-8', className)}>
    <Typography tag='h6' className='mb-2.5'>
      Creating a strong password
    </Typography>

    <div>
      {PASSWORD_STRENGTH_POINTS.map(({ id, title, pattern }) => (
        <CheckmarkWithTitle key={id} title={title} filled={new RegExp(pattern).test(password)} />
      ))}
    </div>
  </div>
);

export default React.memo(PasswordStrengthWidget);
