import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

export const CHART_INFO_LIST_SKELETON_ITEM_TEST_ID = 'chart-info-list-skeleton';

interface Props {
  length?: number;
  className?: string;
}

const ChartInfoListSkeleton: FC<Props> = ({ length = 8, className }) => {
  return (
    <>
      {Array.from(Array(length).keys()).map((i) => (
        <div
          key={i}
          className={twMerge('skeleton-loader h-10 w-full mx-1', className)}
          data-testid={CHART_INFO_LIST_SKELETON_ITEM_TEST_ID}
        />
      ))}
    </>
  );
};

export default ChartInfoListSkeleton;
