import React, { FC, useEffect, useMemo, useState } from 'react';

import { SubmitHandler, useFieldArray, UseFieldArrayAppend, useForm, UseFormSetValue } from 'react-hook-form';

import {
  START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS,
  START_SHARE_EXCHANGE_WITH_INVESTOR_FORM_FIELDS,
} from 'constants/share-exchange';
import { TypographyVariants } from 'constants/shared/typography';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import {
  Investor,
  Option,
  StartShareExchangesInvestmentsFormValues,
  StartShareExchangeWatchValues,
  StartShareExchangeWithInvestorFormValues,
} from 'interfaces';
import { getAdminCompanies, getAdminCompanyExchangeData } from 'modules/companies/action';
import { selectCompaniesData, selectCompanyDealData } from 'modules/companies/selectors';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal, FormContent, SnippetLayout } from 'shared-components';
import { checkIsAdmin } from 'utils';

import { createShareExchangeForInvestor } from '../../../modules/share-exchange/action';
import { selectWelcomeInvestorShareExchangeData } from '../../../modules/share-exchange/selectors';
import ShareExchangeDetailsCommonFields from './ShareExchangeDetailsCommonFields';

interface Props extends Omit<UseModalReturnValues, 'onOpen'> {
  title?: string;
  investor?: Investor;
}

const StartShareExchangesModalWithCompany: FC<Props> = ({ title, investor, onClose, ...modalProps }) => {
  const dispatch = useAppDispatch();

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);
  const { list: companies, isLoading } = useAppSelector(selectCompaniesData);

  const [isCompanyHidden, setIsCompanyHidden] = useState(true);

  const { data: companyDealPredefinedData, isLoading: isDealDataLoading } = useAppSelector(selectCompanyDealData);
  const { isLoading: isStartShareExchangeLoading } = useAppSelector(selectWelcomeInvestorShareExchangeData);

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<StartShareExchangeWithInvestorFormValues>({
    defaultValues: {
      exchanges: [],
    },
  });

  const {
    fields: dealsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'exchanges',
  });

  const watchExchanges = watch('exchanges');
  const watchCompanyName = watch('companyName');

  const companyOptions: Option[] = useMemo(() => {
    return (
      companies?.map(({ companyName, id }) => ({
        label: companyName,
        value: `${id}`,
      })) || []
    );
  }, [companies]);

  const getFields = (
    watchValues: StartShareExchangeWatchValues,
    handleSetCompanyPrice: VoidFunction,
    classOfSharesOptions: Option[],
    closingDatesOptions: Option[],
    dealIndex: number,
  ) =>
    START_INVESTMENT_SHARE_EXCHANGE_FORM_FIELDS(
      dealIndex,
      setValue as unknown as UseFormSetValue<StartShareExchangesInvestmentsFormValues>,
      watchValues,
      classOfSharesOptions,
      closingDatesOptions,
      handleSetCompanyPrice,
      isTypeEntity,
      entityName,
    );

  const onSubmit: SubmitHandler<StartShareExchangeWithInvestorFormValues> = ({ exchanges, companyId }) => {
    if (!investor || !companyDealPredefinedData) return;

    const formattedExchanges = exchanges.map(({ classOfShares, closingDate, ...rest }) => ({
      classOfShares: classOfShares?.value ?? '',
      closingDate: closingDate?.value ?? '',
      ...rest,
    }));

    const investorFields = {
      investorId: investor.id,
      investor: {
        firstName: investor.firstName,
        lastName: investor.lastName,
        email: investor.email,
      },
    };

    dispatch(
      createShareExchangeForInvestor({
        exchanges: formattedExchanges,
        companyId,
        ...investorFields,
      }),
    )
      .unwrap()
      .then(() => {
        onClose();
        reset({});
      });
  };

  const onCloseModal = () => {
    setIsCompanyHidden(true);
    reset({});
    onClose();
  };

  useEffect(() => {
    if (!companyDealPredefinedData?.companyName) return;

    const { companySharePrice, ...dealPredefinedData } = companyDealPredefinedData;

    const [{ companyPrice }] = companySharePrice || [];

    reset({
      exchanges: [
        {
          ...dealPredefinedData,
          companyPrice,
        },
      ],
    });
  }, [companyDealPredefinedData, reset]);

  useEffect(() => {
    dispatch(getAdminCompanies({ page: 1, per_page: 20, isAdmin, filter: 'filter[isReadyToSE]=1' }));
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (watchCompanyName && isCompanyHidden) {
      const companyId = +watchCompanyName.value;
      dispatch(getAdminCompanyExchangeData(companyId))
        .unwrap()
        .then(() => {
          setIsCompanyHidden(false);
          setValue('companyId', companyId);
        });
    }
  }, [dispatch, watchCompanyName]);

  return (
    <AlertModal
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading || isDealDataLoading || isStartShareExchangeLoading}
      title={title}
      submitButtonText='Submit Request'
      modalClassName='min-w-[70%] 2xl:min-w-[1048px]'
      titleVariant={TypographyVariants.LARGE}
      hideIcon
      onClose={onCloseModal}
      {...modalProps}
    >
      <form className='w-full mt-8'>
        {isCompanyHidden ? (
          <SnippetLayout title='Company' childrenClassName='flex flex-col gap-4'>
            {isDealDataLoading ? (
              <div className='flex flex-col gap-2'>
                <div className='skeleton-loader h-8 w-full' />
                <div className='skeleton-loader h-16 w-full' />
              </div>
            ) : (
              <FormContent
                fields={START_SHARE_EXCHANGE_WITH_INVESTOR_FORM_FIELDS(companyOptions)}
                control={control}
                register={register}
                errors={errors}
              />
            )}
          </SnippetLayout>
        ) : (
          <ShareExchangeDetailsCommonFields
            getFields={getFields}
            dealsFields={dealsFields}
            shareExchanges={watchExchanges}
            companyDealPredefinedData={companyDealPredefinedData}
            register={register}
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
            append={append as unknown as UseFieldArrayAppend<StartShareExchangesInvestmentsFormValues, 'exchanges'>}
            remove={remove}
          />
        )}
      </form>
    </AlertModal>
  );
};

export default StartShareExchangesModalWithCompany;
