import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  title: string;
  className?: string;
};

const InputsWrapper: FC<Props> = ({ title, children, className }) => {
  return (
    <>
      <hr className='my-6 border-grey-200' />

      <div className={twMerge('flex gap-2 flex-col xs:flex-row', className)}>
        <div className='pt-2 text-grey-500 w-1/4'>{title}</div>
        <div className='w-full xs:w-[630px]'>{children}</div>
      </div>
    </>
  );
};

export default InputsWrapper;
