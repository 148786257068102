import React, { FC, useState, useEffect } from 'react';

import { FOUNDERS_CONFIRM_COMPANIES_MODAL_TITLE } from 'constants/founders';
import { ButtonVariants } from 'constants/shared/button';
import { FounderModifiedCompany } from 'interfaces';
import { getFounderCompanies, updateFoundersCompanies } from 'modules/founders/action';
import { selectFoundersCompanies, selectFoundersCompaniesLoading } from 'modules/founders/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, ModalWindow } from 'shared-components';

import ConfirmFoundersModalTable from './ConfirmFoundersModalTable';

type Props = {
  onClose: VoidFunction;
  isOpen: boolean;
  founderId: string;
  onSuccessUpdateConfirmedCompanies: VoidFunction;
};

const BUTTON_CLASSNAME = 'w-full sm:w-fit h-10 h-full sm:ml-6';

const ConfirmFoundersModal: FC<Props> = ({ onClose, isOpen, founderId, onSuccessUpdateConfirmedCompanies }) => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectFoundersCompaniesLoading);
  const foundersCompanies = useAppSelector(selectFoundersCompanies);

  const [modifiedCompanies, setModifiedCompanies] = useState<Array<FounderModifiedCompany>>([]);

  const handleCLose = () => {
    setModifiedCompanies([]);
    onClose();
  };

  const handleSaveChanges = () => {
    if (!modifiedCompanies.length) {
      onClose();
      return;
    }

    dispatch(
      updateFoundersCompanies({
        founderId,
        modifiedCompanies,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccessUpdateConfirmedCompanies();
        handleCLose();
      });
  };

  useEffect(() => {
    if (!isOpen || !founderId) {
      return;
    }

    dispatch(getFounderCompanies(founderId));
  }, [dispatch, isOpen, founderId]);

  if (!founderId || !isOpen) {
    return null;
  }

  return (
    <ModalWindow
      title={FOUNDERS_CONFIRM_COMPANIES_MODAL_TITLE}
      wrapperClassName='!px-8 pt-14'
      className='lg:w-8/12 xl:w-6/12'
      onClose={handleCLose}
      isOpen={isOpen}
    >
      <ConfirmFoundersModalTable
        foundersCompanies={foundersCompanies || []}
        isLoading={isLoading}
        setModifiedCompanies={setModifiedCompanies}
      />

      <div className='flex sm:justify-end flex-col-reverse sm:flex-row gap-4 sm:gap-0 mt-6 lg:mb-[-20px]'>
        <Button className={BUTTON_CLASSNAME} variant={ButtonVariants.SECONDARY} onClick={onClose}>
          Cancel
        </Button>
        <Button className={BUTTON_CLASSNAME} variant={ButtonVariants.PRIMARY} onClick={handleSaveChanges}>
          Save Companies
        </Button>
      </div>
    </ModalWindow>
  );
};

export default ConfirmFoundersModal;
