import React, { FC } from 'react';

import { REVIEW_INVESTMENTS_TABLE_COLUMNS } from 'constants/investments';
import { ReviewInvestment } from 'interfaces';
import Table from 'shared-components/table';
import Typography from 'shared-components/Typography';

import ReviewUploadedInvestmentsModalTableItem from './ReviewUploadedInvestmentsModalTableItem';

type Props = {
  investments: ReviewInvestment[];
  onClickDeleteInvestment: (investmentId: string) => void;
};

const ReviewUploadedInvestmentsModalTable: FC<Props> = ({ investments, onClickDeleteInvestment }) => {
  return (
    <div className='flex flex-col'>
      {investments.length ? (
        <Table columns={REVIEW_INVESTMENTS_TABLE_COLUMNS}>
          {investments.map((investment) => (
            <ReviewUploadedInvestmentsModalTableItem
              key={investment.id}
              onDelete={onClickDeleteInvestment}
              investment={investment}
            />
          ))}
        </Table>
      ) : (
        <Typography>No Uploaded Investments To Review</Typography>
      )}
    </div>
  );
};

export default ReviewUploadedInvestmentsModalTable;
