import React from 'react';

const ListSkeleton = () => {
  return (
    <div>
      <div className='skeleton-loader w-full h-32 mb-6' />
      <div className='skeleton-loader w-full h-32 mb-6' />
      <div className='skeleton-loader w-full h-32 mb-6' />
    </div>
  );
};

export default ListSkeleton;
