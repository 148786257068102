import React, { FC } from 'react';

import { FormLabel } from 'shared-components';

interface Props {
  withoutLabel?: boolean;
}

const AddInvestmentClassOfSharesPreloader: FC<Props> = ({ withoutLabel }) => {
  return (
    <div className='w-full'>
      {!withoutLabel && <FormLabel>Class Of Shares</FormLabel>}
      <div className='skeleton-loader h-[56px] w-full mb-[0] rounded-lg' />
    </div>
  );
};

export default AddInvestmentClassOfSharesPreloader;
