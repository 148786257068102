import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AuditLog, AuditLogsResponse, AuditLogsState } from 'interfaces/audit-logs.interfaces';

import { getAdminActivityLog, getAdminActivityLogs } from './action';

export function getAdminAuditLogsReducer(builder: ActionReducerMapBuilder<AuditLogsState>) {
  builder.addCase(getAdminActivityLogs.pending, (state) => {
    state.data = [];
    state.isLoading = true;
  });

  builder.addCase(getAdminActivityLogs.fulfilled, (state, action: PayloadAction<AuditLogsResponse>) => {
    state.data = action.payload.data;
    state.isLoading = false;
    state.totalPages = action.payload.meta.last_page;
    state.total = action.payload.meta.total;
  });

  builder.addCase(getAdminActivityLogs.rejected, (state) => {
    state.data = [];
    state.isLoading = false;
  });
}

export function getAdminAuditLogByIdReducer(builder: ActionReducerMapBuilder<AuditLogsState>) {
  builder.addCase(getAdminActivityLog.pending, (state) => {
    state.auditLogInfo.isLoading = true;
  });

  builder.addCase(getAdminActivityLog.fulfilled, (state, action: PayloadAction<AuditLog>) => {
    state.auditLogInfo = {
      isLoading: false,
      info: action.payload,
    };
  });

  builder.addCase(getAdminActivityLog.rejected, (state) => {
    state.auditLogInfo.isLoading = false;
  });
}
