import React, { FC } from 'react';

import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { COMPANY_INVESTORS_CHOOSE_TEXT } from 'constants/company-investors';
import { ButtonVariants } from 'constants/shared/button';
import { Button, ButtonWithTooltip } from 'shared-components';

interface Props {
  handleConfirmButton: VoidFunction;
  selectedInvestorsLength: number;
  handleClickBatchUpload: VoidFunction;
  isLoading?: boolean;
}

const CompanyInvestorsFooter: FC<Props> = ({
  handleConfirmButton,
  selectedInvestorsLength,
  handleClickBatchUpload,
  isLoading,
}) => {
  return (
    <div className='flex mt-20 justify-end flex-col xs:flex-row gap-4 xs:gap-0 relative'>
      <Button
        className='w-full xs:w-fit xs:mr-6'
        variant={ButtonVariants.SECONDARY}
        onClick={handleClickBatchUpload}
        disabled={isLoading}
      >
        Batch Upload <PlusSvg className='ml-2' />
      </Button>
      <div className='flex justify-between space-x-4'>
        <ButtonWithTooltip
          variant={ButtonVariants.PRIMARY}
          onClick={handleConfirmButton}
          disabled={!selectedInvestorsLength}
          isLoading={isLoading}
          disabledTooltip={Boolean(selectedInvestorsLength)}
          anchorId='send-investor-invite'
          tooltipContent={COMPANY_INVESTORS_CHOOSE_TEXT}
          wrapperClassName='w-full xs:w-auto'
          place='top-start'
          offset={20}
        >
          Send Invite To Deal ({selectedInvestorsLength})
        </ButtonWithTooltip>
      </div>
    </div>
  );
};

export default CompanyInvestorsFooter;
