import { ReactComponent as CompanySvg } from 'assets/svg/company.svg';
import { ReactComponent as UserSvg } from 'assets/svg/user.svg';
import { UserTypes } from 'constants/user';

export const WHO_ARE_YOU_PAGE_TITLE = 'Who are you?';
export const WHO_ARE_YOU_TEST_ID = 'WHO_ARE_YOU_TEST_ID';
export const WHO_ARE_YOU_SIGN_IN_PAGE_TITLE = 'Sign in';

export const getUserTypes = (isMultiSignIn?: boolean) => {
  const actionType = isMultiSignIn ? 'sign in' : 'register';

  return [
    {
      title: 'Investor',
      description: `Choose if you wish to ${actionType} as an Investor on planD`,
      userType: UserTypes.INVESTOR,
      icon: UserSvg,
    },
    {
      title: 'Founder/Director',
      description: `Choose if you wish to ${actionType} as a Founder/Director on behalf of a company on planD`,
      userType: UserTypes.FOUNDER,
      icon: CompanySvg,
    },
  ];
};
