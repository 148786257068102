import React, { FC } from 'react';

import { PAY_BILL_TABLE_COLUMNS } from 'constants/my-bills';
import { Bill } from 'interfaces';
import Table from 'shared-components/table';

import PayBillTableRow from './PayBillTableRow';

type Props = {
  bill: Bill | null;
};

const PayBillsTable: FC<Props> = ({ bill }) => {
  return (
    <div className='overflow-auto'>
      <Table columns={PAY_BILL_TABLE_COLUMNS}>{bill && <PayBillTableRow key={bill.id} bill={bill} />}</Table>
    </div>
  );
};

export default PayBillsTable;
