import { endOfDay, isBefore } from 'date-fns';

import { DEAL_DETAILS_INCORRECT_URL_PATTERN, DEAL_STATUSES, SHARE_EXCHANGE_STAGES } from 'constants/deals';
import { Option } from 'interfaces';

import { LocalStorageKeys } from '../constants/global';
import { StepperStatuses } from '../constants/shared/stepper';
import { checkIsJSONString } from './global/global';

export const checkIsDealNotCompleted = (stage?: SHARE_EXCHANGE_STAGES, status?: DEAL_STATUSES) => {
  if (!stage || !status) return false;

  return stage !== SHARE_EXCHANGE_STAGES.COMPLETED && status !== DEAL_STATUSES.DONE;
};

export const getShareExchangesTotalInfo = (shareExchanges: { noOfShares: string; noExchangedShares: string }[]) => {
  return shareExchanges.reduce(
    (acc, { noOfShares, noExchangedShares }) => ({
      totalShares: acc.totalShares + Number(noOfShares),
      totalExchangedShares: acc.totalExchangedShares + Number(noExchangedShares),
    }),
    {
      totalShares: 0,
      totalExchangedShares: 0,
    },
  );
};

export const checkIsCompletedStepAndReadyToCompleteStage = (
  step: SHARE_EXCHANGE_STAGES | string,
  stage: SHARE_EXCHANGE_STAGES,
) => {
  return step === SHARE_EXCHANGE_STAGES.COMPLETED && stage === SHARE_EXCHANGE_STAGES.READY_TO_COMPLETE;
};

export const checkIsCurrentDocumentCorrect = (currentDoc?: string | null) =>
  currentDoc && !currentDoc?.endsWith(DEAL_DETAILS_INCORRECT_URL_PATTERN);

export const checkIsClosingDatesExistsAndNotExpired = (closingDates: Option[]) => {
  if (!closingDates?.length) return false;

  const isAllClosingDatesInPast = closingDates?.every(({ value }) =>
    isBefore(endOfDay(new Date(value)), endOfDay(new Date())),
  );

  return !isAllClosingDatesInPast;
};

export const getStorageDealsStagesLabels = (id?: number | string) => {
  const dealsStages = localStorage.getItem(LocalStorageKeys.DEALS_STAGES_LABELS) || '{}';

  const parsedDeals = JSON.parse(dealsStages);

  const storageDealStageDetails = id ? ((parsedDeals || {}) as Record<string, string>)[id] : '';

  const { stageLabel = '', nextStage = '' } = checkIsJSONString(storageDealStageDetails)
    ? JSON.parse(storageDealStageDetails)
    : { stageLabel: storageDealStageDetails, nextStage: '' };

  return [parsedDeals || undefined, { stageLabel, nextStage }];
};

export const checkIsDealDeleted = (status: DEAL_STATUSES) => status === DEAL_STATUSES.DELETED;

export const getDealStageStepperStatus = ({
  isCompleted,
  isStopped,
  isDeleted,
}: {
  isCompleted?: boolean;
  isStopped?: boolean;
  isDeleted?: boolean;
}) => {
  if (isStopped) return StepperStatuses.CANCEL;

  if (isDeleted) return StepperStatuses.DELETED;

  if (isCompleted) return StepperStatuses.SUCCESS;

  return StepperStatuses.PENDING;
};

export const getDealsSnippetStatus = (state?: string) => {
  switch (state) {
    case 'Approved':
    case 'Finished':
      return StepperStatuses.SUCCESS;
    case 'Declined':
      return StepperStatuses.CANCEL;
    default:
      return StepperStatuses.PENDING;
  }
};
