export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
  UNDERLINED_LINK = 'underlined-link',
  DISABLED_PRIMARY = 'disabled-primary',
  DISABLED_SECONDARY = 'disabled-secondary',
  DISABLED_LINK = 'disabled-link',
  TRANSPARENT = 'transparent',
}

export const BACK_BUTTON_TEST_ID = 'BACK_BUTTON_TEST_IS';
export const NEXT_BUTTON_TEST_ID = 'NEXT_BUTTON_TEST_IS';
export const SKIP_BUTTON_TEST_ID = 'SKIP_BUTTON_TEST_IS';

export const LINK_SHARED_STYLES =
  'p-0 border-transparent rounded outline-blue-200 text-blue-600 hover:bg-transparent focus:bg-transparent focus:text-blue-600 focus:outline focus:-outline-offset-1 [&_path]:fill-blue-600 hover:text-blue-700 [&_path]:hover:fill-blue-700 disabled:border-none';

export const ButtonVariantsStyles: { [key in ButtonVariants]: string } = {
  [ButtonVariants.PRIMARY]:
    'bg-green-500 border outline-[3px] border-green-500 rounded-32 text-blue-800 font-bold hover:bg-green-600 hover:border-green-600 [&_path]:fill-blue-800 disabled:bg-grey-100',
  [ButtonVariants.SECONDARY]:
    'bg-transparent border outline-[3px] border-green-600 text-green-700 font-bold rounded-32 [&_path]:fill-green-700 hover:bg-green-100 hover:text-green-800 [&_path]:hover:fill-green-800 [&_path]:focus:fill-green-800 focus:bg-green-200 focus:text-green-800 focus:border-green-200 disabled:bg-grey-100 disabled:text-grey-300 disabled:border-grey-100 disabled:bg-transparent disabled:text-grey-300 disabled:border-grey-100',
  [ButtonVariants.LINK]: `${LINK_SHARED_STYLES} font-bold`,
  [ButtonVariants.UNDERLINED_LINK]: `${LINK_SHARED_STYLES} underline`,
  [ButtonVariants.DISABLED_PRIMARY]:
    'bg-grey-100 text-grey-300 border-grey-100 cursor-not-allowed pointer-events-none [&_path]:fill-grey-300 focus:outline-0 hover:bg-grey-100 hover:text-grey-300 hover:border-grey-100 focus:bg-grey-100',
  [ButtonVariants.DISABLED_SECONDARY]:
    'bg-transparent text-grey-300 border-grey-100 cursor-not-allowed pointer-events-none [&_path]:fill-grey-300 focus:outline-0 focus:-outline-offset-1 bg-transparent text-grey-300 border-grey-100',
  [ButtonVariants.DISABLED_LINK]: 'bg-transparent text-grey-300 [&_path]:fill-grey-300 focus:outline-0 border-none',
  [ButtonVariants.TRANSPARENT]:
    'bg-transparent border-none disabled:border-none focus:outline-0 focus:outline-none focus-visible:outline-0 p-2',
};
