import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { DOCUMENTS_PAGE_TABS_IDS } from 'constants/documents';
import { BILL_STATUS_COLORED_LABEL_STYLE } from 'constants/my-bills';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { Bill } from 'interfaces';
import { Button, ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

import { formatNumberInputValue } from '../../../../../utils/shared';

type Props = {
  bill: Bill;
};

const PayBillTableRow: FC<Props> = ({ bill }) => {
  const navigate = useNavigate();

  const goToBillPage = () => {
    navigate({ pathname: ROUTES.DOCUMENTS, search: `?tab=${DOCUMENTS_PAGE_TABS_IDS.INVOICES}` });
  };

  return (
    <TableRow className='last:border-0 [&>td]:last:pb-0'>
      <TableData className='pl-2'>
        <Button onClick={goToBillPage} variant={ButtonVariants.SECONDARY} className='w-[90%] max-w-[130px]'>
          View Bill
        </Button>
      </TableData>
      <TableData>{bill.invoiceReference}</TableData>
      <TableData>{bill.dealId}</TableData>
      <TableData>{bill.companyName}</TableData>
      <TableData>£{formatNumberInputValue(bill.totalAmount)}</TableData>
      <TableData>{format(parseISO(bill.dateCreated), DAY_FORMAT_WITH_SLASHES)}</TableData>
      <TableData>
        <ColoredLabel {...BILL_STATUS_COLORED_LABEL_STYLE[bill.status]} />
      </TableData>
    </TableRow>
  );
};

export default PayBillTableRow;
