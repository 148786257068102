import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { USERS_PAGE_DESCRIPTION, USERS_PAGE_TITLE } from 'constants/users';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { Option, UsersFiltersNameFields, UsersFiltersParams } from 'interfaces';
import { selectIsActionLoading } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { selectIsLoading, selectUsers, selectUsersPaginationData } from 'modules/users/selectors';
import { DashboardLayout, MyInvitesModalForm, UsersFiltersForm, UsersTable } from 'page-components';
import { Constraint, Pagination, SearchForm, TitleWithDescription } from 'shared-components';

const USERS_PER_PAGE = 7;

const Users = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const isInvitesActionLoading = useAppSelector(selectIsActionLoading);
  const users = useAppSelector(selectUsers);
  const usersPaginationData = useAppSelector(selectUsersPaginationData);

  const [filteredParams, setFilteredParams] = useState('');

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  const { register, control, watch, setValue } = useForm<UsersFiltersParams>({
    reValidateMode: 'onChange',
    defaultValues: { [UsersFiltersNameFields.SELECTED_USER_TYPE]: [] },
  });

  const watchIsRegistered: boolean = watch(UsersFiltersNameFields.REGISTERED);
  const watchIsUnregistered: boolean = watch(UsersFiltersNameFields.UNREGISTERED);
  const watchSelectedUserTypes: Option[] = watch(UsersFiltersNameFields.SELECTED_USER_TYPE);

  const loadUsers = useCallback(() => {
    dispatch(
      getUsers({
        page,
        per_page: USERS_PER_PAGE,
        sort: sorting.column,
        order: sorting.order,
        query,
        filter: filteredParams,
      }),
    );
  }, [filteredParams, dispatch, page, sorting.column, sorting.order, query]);

  useEffect(() => {
    if (watchIsRegistered) {
      setValue(UsersFiltersNameFields.UNREGISTERED, false);
    }
  }, [setValue, watchIsRegistered]);

  useEffect(() => {
    if (watchIsUnregistered) {
      setValue(UsersFiltersNameFields.REGISTERED, false);
    }
  }, [setValue, watchIsUnregistered]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    if (!watchIsRegistered && !watchIsUnregistered && watchSelectedUserTypes.length === 0) {
      setFilteredParams('');
      setPage(1);
    }

    const userTypes =
      watchSelectedUserTypes.length !== 0
        ? `${watchSelectedUserTypes.map((userType) => `filter[userble_type][]=${userType.value}`).join('&')}`
        : '';

    const registeredUsersValue = watchIsRegistered
      ? 'filter[registered]=1'
      : watchIsUnregistered
      ? 'filter[registered]=0'
      : '';

    const newFilteredParams = `${userTypes ? `${userTypes}&` : ''}${registeredUsersValue}`;

    setPage(filteredParams !== newFilteredParams ? 1 : page);
    setFilteredParams(newFilteredParams);
  }, [filteredParams, page, setPage, watchIsRegistered, watchIsUnregistered, watchSelectedUserTypes]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription
          descriptionClassName='pt-2'
          title={USERS_PAGE_TITLE}
          description={USERS_PAGE_DESCRIPTION}
        />
        <div className='flex items-start sm:items-end flex-col sm:flex-row gap-4 sm:gap-0 mb-6'>
          <SearchForm
            handleFilter={handleFilter}
            placeholder='Search by Name'
            className='flex-col sm:flex-row gap-4 sm:gap-0 w-full sm:w-auto'
          />

          <div className='flex justify-start basis-full sm:basis-5/12 w-full sm:w-auto'>
            <MyInvitesModalForm
              loading={isInvitesActionLoading}
              buttonContent={
                <>
                  Invite user <UserIcon className='ml-2' />
                </>
              }
              buttonVariant={ButtonVariants.SECONDARY}
              buttonClassName='w-full sm:w-fit sm:ml-6'
            />
          </div>
        </div>
        <div className='mb-12'>
          <UsersFiltersForm disabled={isLoading} register={register} control={control} />
        </div>
        <UsersTable
          loading={isLoading}
          updateSorting={updateSorting}
          callback={loadUsers}
          rowsNumber={USERS_PER_PAGE}
          users={users}
        />

        <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...usersPaginationData} />
      </Constraint>
    </DashboardLayout>
  );
};

export default Users;
