import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { TypographyVariants } from 'constants/shared/typography';
import { UserProfileInfo } from 'constants/user';
import useModal from 'hooks/use-modal/useModal';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getUser } from 'modules/users/action';
import { selectIsLoading, selectUserData } from 'modules/users/selectors';
import { DashboardLayout, DeleteUserModal, UserInfo, UserOnlineStatusLabel, UserPageSkeleton } from 'page-components';
import { Button } from 'shared-components';
import Typography from 'shared-components/Typography';

const User = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { onOpen, ...modalState } = useModal();

  const isLoading = useAppSelector(selectIsLoading);
  const user = useAppSelector(selectUserData);

  const navigateToUsers = () => {
    navigate(`${ROUTES.admin}${ROUTES.users}`);
  };

  useEffect(() => {
    if (id) {
      dispatch(getUser(id));
    }
  }, [id, dispatch]);

  return (
    <DashboardLayout>
      {isLoading || !user ? (
        <UserPageSkeleton />
      ) : (
        <div className='contentful-dashboard-page'>
          <div className='mb-10 flex justify-between items-start flex-col md:flex-row gap-4 md:gap-0'>
            <div>
              <Typography tag='h2' variant={TypographyVariants.LARGE}>
                User Profile: {`${user.firstName} ${user.lastName}`}
              </Typography>

              <div className='mt-6'>
                <UserInfo title={UserProfileInfo.EMAIL_ADDRESS} value={user.email} />
                <UserInfo title={UserProfileInfo.ORGANIZATION} value={user.companies[0]} />
                <UserInfo title={UserProfileInfo.PHONE_NUMBER} value={user.phone || ''} />
                <UserInfo title={UserProfileInfo.LAST_ONLINE}>
                  <UserOnlineStatusLabel className='ml-1' online={user?.online} lastActivity={user?.lastActivity} />
                </UserInfo>
              </div>
            </div>

            <div className='flex flex-col xs:flex-row gap-4 xs:gap-0 w-full md:w-auto'>
              <Button className='w-full md:w-150 xs:mr-5' variant={ButtonVariants.SECONDARY} disabled>
                Action
              </Button>
              <Button onClick={onOpen} variant={ButtonVariants.SECONDARY} className='w-full md:w-150'>
                Delete User
              </Button>
            </div>
          </div>

          <DeleteUserModal
            id={id}
            fullName={`${user.firstName} ${user.lastName}`}
            callback={navigateToUsers}
            {...modalState}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default User;
