import React, { FC, useEffect, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as SortingArrow } from 'assets/svg/arrow-drop-down.svg';
import { ReactComponent as SmallInformationIcon } from 'assets/svg/small-info.svg';
import { ORDERS } from 'constants/shared';
import { TableColumns, SortingOrder, TableSorting } from 'interfaces';
import IconWithTooltip from 'shared-components/IconWithTooltip';

type Props = {
  columns: TableColumns[];
  onUpdateSorting?: (column: string, order: SortingOrder) => void;
  sorting?: TableSorting;
};

export const COLUMN_TITLE_TEST_ID = 'column-title-test-id';
export const TABLE_HEAD_TEST_ID = 'table-head-test-id';
export const SORTING_ARROW_TEST_ID = 'sorting-arrow-test-id';

const TableHead: FC<Props> = ({ columns, onUpdateSorting, sorting = {} }) => {
  const [selectedSortingColumn, setSelectedSortingColumn] = useState('');
  const [order, setOrder] = useState<SortingOrder>(ORDERS.ASC);

  const onClickSorting = (id: string) => {
    if (!onUpdateSorting) {
      return;
    }

    if (id !== selectedSortingColumn) {
      setSelectedSortingColumn(id);
      onUpdateSorting(id, order as SortingOrder);
    } else {
      const newOrder = order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC;

      setOrder(newOrder);
      onUpdateSorting(id, newOrder);
    }
  };
  useEffect(() => {
    const { order, column } = sorting || {};

    if (order && column) {
      setSelectedSortingColumn(column);
      setOrder(order);
    }
  }, [sorting.order, sorting.column, sorting]);
  return (
    <thead className='after:h-2 after:content-[" "] after:color-white after:block'>
      <tr>
        {columns.map(({ id, title, width, sortable, tooltipText, anchorId, wrapperClassName, className }) => (
          <th
            data-testid={TABLE_HEAD_TEST_ID}
            key={`${id}-${title}`}
            className={cn('table-cell pt-4 pb-2 pr-2 border-b border-grey-200', className, width)}
          >
            <div className='flex items-center relative'>
              <span data-testid={COLUMN_TITLE_TEST_ID} className='text-grey-500 text-sm text-left font-normal'>
                {title}
              </span>

              {sortable && (
                <SortingArrow
                  data-testid={SORTING_ARROW_TEST_ID}
                  onClick={() => onClickSorting(id)}
                  className={cn(
                    ' ml-1.5 cursor-pointer transition-sorting ease-linear delay-300 [&_path]:fill-grey-500',
                    {
                      '-scale-y-100': id === selectedSortingColumn && order === ORDERS.ASC,
                    },
                  )}
                />
              )}

              {tooltipText && anchorId && (
                <IconWithTooltip
                  wrapperClassName={cn('ml-[5px]', wrapperClassName)}
                  tooltipClassName='font-normal'
                  tooltipContent={tooltipText}
                  anchorId={anchorId}
                  offset={1}
                  place='bottom-start'
                  icon={<SmallInformationIcon />}
                />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
