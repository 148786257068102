import { TableColumns } from '../interfaces';

export const NOTIFICATIONS_PAGE_TITLE = 'Notifications';

export const NOTIFICATIONS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'companyName', title: 'Company Name' },
  { id: 'createdAt', title: 'Date Created' },
  { id: 'actions', title: '' },
];

export const NOTIFICATIONS_PAGE_PLACEHOLDER = 'Search by Company Name';
