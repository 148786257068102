import React, { FC, useMemo } from 'react';

import {
  COMPANIES_ADD_MODAL_DESCRIPTION,
  COMPANIES_ADD_MODAL_SUBMIT_TEXT,
  COMPANIES_ADD_MODAL_TITLE,
  CompanyDataFieldNames,
} from 'constants/companies';
import useModal, { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { CompanyDetailsInput, GetListPayload } from 'interfaces';
import { addCompany, getAdminCompanies, getMyCompanies } from 'modules/companies/action';
import { selectCompanyData, selectCompanyDataIsLoading } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button, CompanyDataForm, ModalWindow } from 'shared-components';
import { checkIsAdmin } from 'utils';

interface Props extends Pick<GetListPayload, 'page'>, Partial<UseModalReturnValues> {
  setPage?: (page: number) => void;
  perPage: number;
}

const AddCompanyModal: FC<Props> = ({ page, perPage, setPage, ...outsiderModalState }) => {
  const dispatch = useAppDispatch();
  const companyData = useAppSelector(selectCompanyData);
  const isActionLoading = useAppSelector(selectCompanyDataIsLoading);

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { onOpen, onClose, ...modalState } = useModal();

  const onSubmit = (data: CompanyDetailsInput) => {
    dispatch(addCompany({ ...data, isAdmin }))
      .unwrap()
      .then(() => {
        if (page === 1) {
          const getCompaniesPayload = { page, per_page: perPage };

          if (isAdmin) {
            dispatch(getAdminCompanies({ ...getCompaniesPayload, isAdmin }));
            return;
          }
          dispatch(getMyCompanies(getCompaniesPayload));
        } else if (setPage) setPage(1);
      })
      .finally(onClose);
  };
  return (
    <div>
      {outsiderModalState?.isOpen === undefined && (
        <Button className='w-full xs:w-fit' onClick={onOpen}>
          Add Company
        </Button>
      )}

      <ModalWindow
        title={COMPANIES_ADD_MODAL_TITLE}
        description={COMPANIES_ADD_MODAL_DESCRIPTION}
        className='min-w-[60%] xl:min-w-[52%]'
        contentClassName='overflow-x-hidden'
        headerClassName='modal-big-title'
        onClose={outsiderModalState?.onClose || onClose}
        preventOutsideClick
        {...modalState}
        {...outsiderModalState}
      >
        <CompanyDataForm
          submitButtonText={COMPANIES_ADD_MODAL_SUBMIT_TEXT}
          excludedFields={[CompanyDataFieldNames.AMOUNT_INVESTED]}
          companyData={companyData}
          onSubmit={onSubmit}
          isActionLoading={isActionLoading}
          isResetAfterSubmit
          hideSkipButton
        />
      </ModalWindow>
    </div>
  );
};

export default AddCompanyModal;
