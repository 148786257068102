import React from 'react';

import { TableRow, TableData } from 'shared-components/table';

const NoDealsAdded = () => {
  return (
    <TableRow>
      <TableData>No information is available as no Share Exchanges have been added</TableData>
    </TableRow>
  );
};

export default NoDealsAdded;
