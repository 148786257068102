import React from 'react';

import { useNavigate } from 'react-router-dom';

import {
  RESET_PASSWORD_SUCCESS_PAGE_ADDITIONAL_TEXT,
  RESET_PASSWORD_SUCCESS_PAGE_TITLE,
  RESET_PASSWORD_SUCCESS_SUBMIT_BUTTON,
} from 'constants/reset-password-success';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { selectResetPasswordUserType } from 'modules/auth/selectors';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo, NeedHelpLink } from 'page-components';
import { Button } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  const userType = useAppSelector(selectResetPasswordUserType);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  return (
    <AuthLayout childrenClassName='relative h-[calc(100vh-74px)] max-w-[630px]'>
      <HeaderInfo className='items-center' title={RESET_PASSWORD_SUCCESS_PAGE_TITLE}>
        <div className='text-center'>
          {getTitleWithReplacedYourWord(RESET_PASSWORD_SUCCESS_PAGE_ADDITIONAL_TEXT, entityName, isTypeEntity)}
        </div>
      </HeaderInfo>

      <Button
        onClick={() =>
          navigate(
            userType === UserTypes.ADMIN || userType === UserTypes.FM_USER ? `/admin${ROUTES.signIn}` : ROUTES.signIn,
          )
        }
        className='mt-28'
      >
        {RESET_PASSWORD_SUCCESS_SUBMIT_BUTTON}
      </Button>

      <NeedHelpLink />
    </AuthLayout>
  );
};

export default ResetPasswordSuccess;
