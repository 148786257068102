import React, { FC, ReactNode } from 'react';

import { Investment, InvestmentKeys, TableColumns, UpdateSortingFunctionType } from 'interfaces';
import { MyInvestmentsTableItem, NoInvestmentsAdded } from 'page-components';
import Table, { TableSkeleton } from 'shared-components/table';

interface Props {
  updateSorting: UpdateSortingFunctionType;
  isLoading: boolean;
  limit?: number;
  investments: Investment[];
  columns: TableColumns[];
  availableKeys?: InvestmentKeys;
  actionsContent?: (props?: any) => ReactNode;
  allowDisablingRows?: boolean;
  isAvailableAdditionalActions?: boolean;
  companyNameLength?: number;
}

const MyInvestmentsTable: FC<Props> = ({
  updateSorting,
  isLoading,
  limit,
  investments,
  availableKeys,
  columns,
  companyNameLength,
}) => {
  return (
    <Table columns={columns} onUpdateSorting={updateSorting}>
      {investments?.length && !isLoading ? (
        <>
          {investments.slice(0, limit || investments?.length).map((investment) => {
            const { companyName } = investment;
            const formattedCompanyName = companyNameLength ? companyName.slice(0, companyNameLength) : companyName;

            return (
              <MyInvestmentsTableItem
                {...investment}
                key={investment.id}
                availableKeys={availableKeys}
                companyName={formattedCompanyName}
                pricePerShare={`£ ${investment?.pricePerShare}`}
              />
            );
          })}
        </>
      ) : isLoading ? (
        <TableSkeleton rowsNumber={3} columnsNumber={columns?.length} />
      ) : (
        <NoInvestmentsAdded />
      )}
    </Table>
  );
};

export default MyInvestmentsTable;
