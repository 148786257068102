import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { ColoredLabelVariants, ColoredLabelVariantsStyles } from 'constants/shared';

export interface Props {
  children?: ReactNode;
  className?: string;
  variant?: ColoredLabelVariants;
}

const ColoredLabel: FC<Props> = ({ children, className, variant = ColoredLabelVariants.SUCCESS }) => {
  if (!children) return null;

  return (
    <span
      className={twMerge(
        'h-6 w-fit py-1 px-2 inline-block rounded-10 whitespace-nowrap text-sm leading-4 select-none',
        ColoredLabelVariantsStyles[variant],
        className,
      )}
    >
      {children}
    </span>
  );
};

export default ColoredLabel;
