import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { COMPANIES_IAC_SNIPPET_COLUMNS } from 'constants/companies-iac';
import { ROUTES } from 'constants/routes';
import { CompanyIac } from 'interfaces';
import { LinkButton } from 'shared-components';
import Table from 'shared-components/table';
import TableContentWrapper from 'shared-components/table/TableContentWrapper';
import TableNoData from 'shared-components/table/TableNoData';
import { navigateByRole } from 'utils';

import CompaniesIacShortInfoTableItem from './CompaniesIacShortInfoTableItem';

interface Props {
  companiesIac?: CompanyIac[] | null;
  isLoading?: boolean;
}

const CompaniesIacShortInfoTable = ({ companiesIac, isLoading }: Props) => {
  const numberOfProposals = companiesIac?.length || 0;
  const showButton = numberOfProposals > 3;
  const selectedCompaniesIac = numberOfProposals > 3 ? companiesIac?.slice(0, 3) : companiesIac;
  return (
    <>
      <Table columns={COMPANIES_IAC_SNIPPET_COLUMNS}>
        <TableContentWrapper
          isLoading={isLoading}
          data={selectedCompaniesIac}
          renderContent={(data) => <CompaniesIacShortInfoTableItem key={data.id} data={data} />}
          noDataNode={<TableNoData text='No Companies Iac Found' skipColumns={1} />}
          columns={COMPANIES_IAC_SNIPPET_COLUMNS?.length}
          rows={3}
        />
      </Table>
      {showButton && (
        <div className='w-full flex justify-end mt-6'>
          <LinkButton to={navigateByRole(true, `${ROUTES.companies}?iacStatus=reviewing_by_fmusers`)}>
            View more <ArrowSvg className='ml-2.5' />
          </LinkButton>
        </div>
      )}
    </>
  );
};

export default CompaniesIacShortInfoTable;
