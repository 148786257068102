import { useState } from 'react';

function useCountdownOnClick(initialValue: number) {
  const [count, setCount] = useState<number>(initialValue);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [isRunning, setIsRunning] = useState(false);

  const startCountdown = () => {
    if (timerId) return;

    setCount(initialValue);
    setIsRunning(true);
    const id = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(id);
          setTimerId(null);
          setIsRunning(false);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    setTimerId(id);
  };

  const stopCountdown = () => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
      setIsRunning(false);
    }
  };

  return { count, isRunning, startCountdown, stopCountdown };
}

export default useCountdownOnClick;
