import React, { ReactNode } from 'react';

import { Tooltip } from 'react-tooltip';

import useModal from 'hooks/use-modal/useModal';

import { Props as TableDataProps } from './TableData';

import { TableData } from './index';

interface Props extends TableDataProps {
  anchorId: string;
  tooltipContent: string | ReactNode;
  hidden?: boolean;
}

const TableDataWithTooltip = ({ anchorId, children, tooltipContent, hidden, ...props }: Props) => {
  const { isOpen: isTooltipOpen, onOpen: handleOpenTooltip, onClose: handleCloseTooltip } = useModal();
  return (
    <TableData id={anchorId} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip} {...props}>
      {children}

      <Tooltip
        anchorId={anchorId}
        isOpen={isTooltipOpen && !hidden}
        positionStrategy='fixed'
        className='text-sm text-grey-500 z-50'
        clickable
        noArrow
      >
        {tooltipContent}
      </Tooltip>
    </TableData>
  );
};

export default TableDataWithTooltip;
