import React, { FC, useEffect, useState } from 'react';

import { START_REQUEST_SHARE_EXCHANGE_MODAL_PROMPT_TITLE } from 'constants/my-investments';
import { ButtonVariants } from 'constants/shared/button';
import { getShortCompanyInfo } from 'modules/companies/action';
import { useAppDispatch } from 'modules/store';
import { AlertModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  companyId?: number;
  onRequestShareExchange: (companyId: number) => void;
};

const StartRequestShareExchangeModalPrompt: FC<Props> = ({ onRequestShareExchange, isOpen, onClose, companyId }) => {
  const dispatch = useAppDispatch();

  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    setIsLoading(true);

    dispatch(getShortCompanyInfo(companyId.toString()))
      .unwrap()
      .then((response) => {
        setCompanyName(response.companyName);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, companyId]);

  const onSubmit = () => {
    if (!companyId) {
      return;
    }

    onRequestShareExchange(companyId);
  };

  return (
    <AlertModal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      title={START_REQUEST_SHARE_EXCHANGE_MODAL_PROMPT_TITLE}
      backButtonText='Cancel'
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
      submitButtonText='Request'
    >
      <div className='mt-4 w-full flex justify-center'>
        {!isLoading && companyName ? (
          <div>
            <span>{`Are you sure you want to start request share exchange for `}</span>
            <span className='font-bold underline'>{companyName}</span>
          </div>
        ) : (
          <div className='skeleton-loader w-full h-8' />
        )}
      </div>
    </AlertModal>
  );
};

export default StartRequestShareExchangeModalPrompt;
