import React, { FC, memo, ReactNode } from 'react';

import { ReactComponent as MenuSvg } from 'assets/svg/menu.svg';
import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

interface Props {
  handleOpenSidebar: VoidFunction;
  children: ReactNode;
}

const Header: FC<Props> = ({ handleOpenSidebar, children }) => {
  return (
    <header className='flex justify-between lg:justify-end items-center py-4 px-10 bg-white'>
      <Button onClick={handleOpenSidebar} variant={ButtonVariants.TRANSPARENT} className='basis-6 lg:hidden p-0'>
        <MenuSvg />
      </Button>
      {children}
    </header>
  );
};

export default memo(Header);
