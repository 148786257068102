import React from 'react';

import { useParams } from 'react-router-dom';

import { ButtonVariants } from 'constants/shared/button';
import { COMPANY_IAC_STATUSES } from 'interfaces';
import { selectIsAdmin } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { LinkButton } from 'shared-components';
import WithCompanyProfileLinkContainer from 'shared-components/WithCompanyProfileLinkContainer';
import { getDueDiligenceLink } from 'utils';

interface Props {
  status?: COMPANY_IAC_STATUSES;
}

const CompanyIacHeaderLinks = ({ status }: Props) => {
  const { id } = useParams();

  const isAdmin = useAppSelector(selectIsAdmin);

  if (!isAdmin) return null;

  return (
    <WithCompanyProfileLinkContainer
      className='flex flex-col sm:flex-row gap-4 sm:gap-6 mt-6'
      id={id}
      isLoading={!status}
    >
      <LinkButton
        hidden={status === COMPANY_IAC_STATUSES.ARCHIVED}
        className='w-full md:w-fit'
        to={id ? getDueDiligenceLink(id, true) : ''}
        variant={ButtonVariants.SECONDARY}
      >
        Due Diligence
      </LinkButton>
    </WithCompanyProfileLinkContainer>
  );
};

export default CompanyIacHeaderLinks;
