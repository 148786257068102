import React, { FC } from 'react';

import cn from 'classnames';

import { ReactComponent as CheckMarkSvg } from 'assets/svg/check-mark-v3.svg';
import { ButtonVariants } from 'constants/shared/button';
import { SIGNIFICANT_CONTROL_DOCUMENT_TYPES } from 'constants/significant-control-company';
import { CHECKMARK_PATH_STYLES } from 'constants/styles';
import {
  selectDueDiligenceDocumentTypes,
  selectIsDueDiligenceEditable,
  selectParentDueDiligenceStatusFinishedOrDeclined,
} from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { Button } from 'shared-components';

type Props = {
  label: string;
  documentType: SIGNIFICANT_CONTROL_DOCUMENT_TYPES;
  className?: string;
  onClickUploadDocuments: VoidFunction;
  onOpenViewDocumentsModal: VoidFunction;
  required?: boolean;
};

const SignificantControlCompanyDocumentType: FC<Props> = ({
  className,
  label,
  documentType,
  onClickUploadDocuments,
  onOpenViewDocumentsModal,
  required,
}) => {
  const documentTypes = useAppSelector(selectDueDiligenceDocumentTypes);
  const isParentDueDiligenceFinishedOrDeclined = useAppSelector(selectParentDueDiligenceStatusFinishedOrDeclined);
  const isEditable = useAppSelector(selectIsDueDiligenceEditable);

  const { isDocumentAttached, documentsCount } =
    documentTypes.find((documentTypeInfo) => documentTypeInfo.documentType === documentType) || {};

  return (
    <div className={cn('flex items-center justify-between', className)}>
      <div className='flex items-center'>
        {isDocumentAttached ? (
          <CheckMarkSvg className={cn('h-5 w-5 mr-2', CHECKMARK_PATH_STYLES)} />
        ) : (
          <div
            className={cn('mr-2 h-5 w-5 rounded-full border-3 border-grey-300', {
              'border-red-500': required && !isDocumentAttached && !isParentDueDiligenceFinishedOrDeclined,
            })}
          />
        )}

        <div className='flex'>
          <div className='text-violet-800 mr-1'>{label}</div>
          {isDocumentAttached && documentsCount && <div className='text-violet-600 mr-2'>{`(${documentsCount})`}</div>}
        </div>
      </div>

      <div>
        {isDocumentAttached && (
          <Button
            onClick={onOpenViewDocumentsModal}
            variant={ButtonVariants.SECONDARY}
            className='w-200 justify-center'
          >
            View Documents
          </Button>
        )}

        <Button
          disabled={isParentDueDiligenceFinishedOrDeclined || !isEditable}
          onClick={onClickUploadDocuments}
          variant={ButtonVariants.LINK}
          className='w-200 h-[46px] justify-center'
        >
          {isDocumentAttached ? 'Upload Another Document' : 'Upload Document'}
        </Button>
      </div>
    </div>
  );
};

export default SignificantControlCompanyDocumentType;
