import React, { FC } from 'react';

import { SHARE_EXCHANGE_REVIEW_SECTION_TITLES, ShareExchangeStatuses } from 'constants/share-exchange';
import { Investment } from 'interfaces';
import { SnippetLayout } from 'shared-components';

import ShareExchangeReviewInvestmentsTable from '../share-exchange-review-investments-table/ShareExchangeReviewInvestmentsTable';

type Props = {
  status: ShareExchangeStatuses;
  investments: Investment[];
};

const ShareExchangeReviewTableSection: FC<Props> = ({ status, investments }) => {
  return (
    <SnippetLayout title={SHARE_EXCHANGE_REVIEW_SECTION_TITLES.IN_REQUEST}>
      <ShareExchangeReviewInvestmentsTable status={status} investments={investments} />
    </SnippetLayout>
  );
};

export default ShareExchangeReviewTableSection;
