import React, { FC } from 'react';

import { DELETE_COMPANY_MODAL_TITLE, DELETE_COMPANY_WITH_LIVE_DEAL_MODAL_TITLE } from 'constants/companies';
import { useAppDispatch } from 'modules/store';
import { AlertModal } from 'shared-components';

import { deleteCompany } from '../../modules/companies/action';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  companyId: number | null;
  hasLiveDealParameter?: boolean;
};

const AdminDeleteCompanyModal: FC<Props> = ({ isOpen, onClose, companyId, hasLiveDealParameter }) => {
  const dispatch = useAppDispatch();

  const onSubmitDelete = () => {
    if (!companyId) return;

    dispatch(deleteCompany(companyId))
      .unwrap()
      .finally(() => onClose());
  };

  return (
    <AlertModal
      secondModal
      isLoading={false}
      onSubmit={onSubmitDelete}
      onClose={onClose}
      isOpen={isOpen}
      title={hasLiveDealParameter ? DELETE_COMPANY_WITH_LIVE_DEAL_MODAL_TITLE : DELETE_COMPANY_MODAL_TITLE}
      backButtonText='No'
      submitButtonText='Yes'
      wrapperModalClassName='lg:px-4 2xl:px-14'
    />
  );
};

export default AdminDeleteCompanyModal;
