import { createSlice } from '@reduxjs/toolkit';

import { AuthState } from 'interfaces';

import { AUTH_SLICE_NAME } from './action';
import {
  signUpReducer,
  searchCompaniesReducer,
  inviteInvestorReducer,
  addCompanyReducer,
  inviteFounderReducer,
  asyncSignInReducer,
  resetPasswordReducer,
  forgotPasswordReducer,
  connectLinkedInReducer,
  submitSignUpDataReducer,
  getAsyncInvitedUserReducer,
  resetAuthDataReducer,
  finishRegistrationReducer,
  invitedUserSignUpReducer,
  resetAuthInvitesReducer,
  confirmEmailReducer,
} from './reducers';

export const initialState: AuthState = {
  companies: null,
  invitedUser: null,
  investorsList: [],
  foundersList: [],
  isActionLoading: false,
  isInvitationLoading: false,
  isEmailChanged: null,
  signUpData: null,
  error: null,
  resetPasswordUserType: null,
};

const authSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    signUpReducer(builder);
    searchCompaniesReducer(builder);
    inviteInvestorReducer(builder);
    inviteFounderReducer(builder);
    addCompanyReducer(builder);
    asyncSignInReducer(builder);
    resetPasswordReducer(builder);
    forgotPasswordReducer(builder);
    getAsyncInvitedUserReducer(builder);
    connectLinkedInReducer(builder);
    submitSignUpDataReducer(builder);
    resetAuthDataReducer(builder);
    finishRegistrationReducer(builder);
    invitedUserSignUpReducer(builder);
    confirmEmailReducer(builder);
    resetAuthInvitesReducer(builder);
  },
});

export const authReducer = authSlice.reducer;
