import { IDocument } from '@cyntler/react-doc-viewer/dist/esm/models';

import { adminDocumentsApis, documentsApis, fmUserDocumentsApis } from 'apis';
import { UserTypes } from 'constants/user';
import { GetFileDocumentsPayload } from 'interfaces';
import { getWindowEnvBaseUrl } from 'utils';

export const getDocumentsRequestByUserType = (payload: GetFileDocumentsPayload, userType?: UserTypes) => {
  switch (userType) {
    case UserTypes.ADMIN:
      return adminDocumentsApis.getDocuments(payload);
    case UserTypes.FM_USER:
      return fmUserDocumentsApis.getDocuments(payload);

    default:
      return documentsApis.getDocuments(payload);
  }
};

export const getUploadFileErrorMessage = (size: number | string) => `Please provide file with size less then ${size}Mb`;

export const getFormattedDocumentDownloadUrl = (url: string) => {
  const replaceValue = 'https';

  if (url.includes(replaceValue)) return url;

  return url?.replace('http', replaceValue);
};

export const getPreviewDocumentsData = (filePath: string): [IDocument[], boolean] => {
  if (!filePath) return [[], false];

  const previewDocuments = [
    {
      uri: getFormattedDocumentDownloadUrl(filePath),
      fileType: filePath?.split('.').pop(),
    },
  ];

  const isDocumentInvalid = !filePath?.includes('.pdf') || filePath.includes('localhost');

  return [previewDocuments, isDocumentInvalid];
};

export const getPreviewDocumentUrlByFileName = (fileName: string) => {
  return `${getWindowEnvBaseUrl()}/api/v1/documents/download/${fileName}`;
};
