import React, { useState, useEffect } from 'react';

import { Sector } from 'recharts';

import { splitActiveShapeContentWithLines } from 'utils/charts';

const TEXT_LINE_HEIGHT = 13;

const PieChartActiveShapeV1 = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  percent,
}: any) => {
  const [lines, setLines] = useState<string[]>([]);

  const calculatedPercent = Number((percent * 100).toFixed(1));

  useEffect(() => {
    const splitActiveShapeNameWithLines = splitActiveShapeContentWithLines(payload.name);

    setLines(splitActiveShapeNameWithLines);
  }, [payload.name]);

  return (
    <g>
      <g>
        <circle cx='39%' cy='54%' r='8' fill={fill} />
      </g>
      <g>
        {lines.map((line, index) => {
          const lineOffset = (lines.length - 1 - index) * TEXT_LINE_HEIGHT;

          return (
            <text
              key={index}
              x={cx}
              y={cy - 10 - lineOffset}
              textAnchor='middle'
              dominantBaseline='central'
              alignmentBaseline='middle'
              fontSize='12'
              fill='#74726F'
            >
              {line}
            </text>
          );
        })}

        <text
          x={cx + 17}
          y={cy + 10}
          textAnchor='middle'
          dominantBaseline='middle'
          fill='#14120F'
          fontSize='16'
          fontWeight='400'
        >
          {calculatedPercent < 0.1 ? '< 0.1%' : `${calculatedPercent}%`}
        </text>
      </g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 1}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default PieChartActiveShapeV1;
