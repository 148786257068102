import React, { FC } from 'react';

import { groupBy, mapValues } from 'lodash';

import { StartShareExchangesInvestmentItem } from 'interfaces';
import ShareExchangeInfoItem from 'page-components/deals/ShareExchangeInfoItem';
import Typography from 'shared-components/Typography';
import { capitalizeFirstLetter } from 'utils';
import { getShareExchangesTotalInfo } from 'utils/deals';

interface Props {
  shareExchanges: StartShareExchangesInvestmentItem[];
}

const StartInvestmentTotalShareExchangeList: FC<Props> = ({ shareExchanges }) => {
  const groupedShareExchanges = mapValues(groupBy(shareExchanges, 'closingDate.value'));

  return (
    <div className='w-full lg:w-2/3 self-end'>
      {Object.keys(groupedShareExchanges).map((key) => {
        const shareExchanges = groupedShareExchanges[key];

        const { totalShares, totalExchangedShares } = getShareExchangesTotalInfo(shareExchanges);

        if (key === 'undefined') return null;

        return (
          <div
            key={key}
            className='flex flex-col sm:flex-row gap-2 justify-between lg:justify-end [&:not(:first-child)]:mt-16'
          >
            <Typography className='text-grey-800 mt-2 flex flex-col whitespace-nowrap'>
              For closing date <b>{key}</b>
            </Typography>
            <div>
              {shareExchanges?.map((shareExchange, index) => {
                const classOfShare = shareExchange?.classOfShares?.label;

                if (!classOfShare) return null;

                return (
                  <ShareExchangeInfoItem
                    key={index}
                    className='mb-2'
                    label={capitalizeFirstLetter(classOfShare)}
                    value={shareExchange?.noOfShares || 0}
                  />
                );
              })}
              <hr className='border-1 border-grey-200' />

              <ShareExchangeInfoItem label='Total Shares for Exchange' value={totalShares} />
              <ShareExchangeInfoItem label='Total Number of Daxia Share to be Received' value={totalExchangedShares} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StartInvestmentTotalShareExchangeList;
