import { differenceInHours, format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { TIME_FORMAT } from 'constants/dateFormats';
import { LINKED_IN_PATTERN } from 'constants/global';
import { Permissions, UserData, UserIntegration, UserIntegrationsData } from 'interfaces';
import { formatCamelCaseToTitleCase } from 'utils';

export const getUserPermissionsDefaultValue = (
  permissions: Permissions,
  permissionsArr: number[],
): Record<string, string> => {
  return Object.values(permissions)
    .flat(1)
    .reduce((prev, { id }) => {
      return { ...prev, [String(id)]: permissionsArr.includes(id) ? '1' : '0' };
    }, {});
};

export const convertIntegrationsDataToList = (data?: UserIntegrationsData): UserIntegration[] => {
  if (!data) return [];

  return Object.entries(data)
    .map(([integrationType, values]) => {
      return Object.entries(values).map(([link, status]) => ({ link, status, type: integrationType }));
    })
    .flat(1) as UserIntegration[];
};

export const getIntegrationTitleByLink = (link: string): string => {
  const integrationTitle = link.match(LINKED_IN_PATTERN);

  if (integrationTitle) return formatCamelCaseToTitleCase(integrationTitle[3]).replace('/', '');
  else return '';
};

export const getUserLastActivity = (
  lastActivity?: string | null,
  online?: boolean,
): { label: string; isFullValue?: boolean } => {
  const isDifferenceHoursEqualZero =
    differenceInHours(parseISO(new Date().toISOString()), parseISO(lastActivity || '')) === 0;

  if (online) {
    return { label: 'Online' };
  } else if (lastActivity && isDifferenceHoursEqualZero) {
    return { label: format(utcToZonedTime(lastActivity, 'GMT'), TIME_FORMAT), isFullValue: true };
  }
  return { label: 'Offline' };
};

export const formatUsersToOptions = (users: UserData[]) =>
  users?.map(({ firstName, lastName, id }) => ({ label: `${firstName} ${lastName}`, value: id }));
