import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { ConstraintVariants } from '../constants/shared';
import { TypographyVariants } from '../constants/shared/typography';
import { asyncConfirmEmail } from '../modules/auth/action';
import { selectIsActionLoading, selectIsEmailChanged } from '../modules/auth/selectors';
import { useAppDispatch, useAppSelector } from '../modules/store';
import { DashboardLayout } from '../page-components';
import { Constraint } from '../shared-components';
import Typography from '../shared-components/Typography';

const ConfirmEmailChange = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsActionLoading);
  const isEmailChanged = useAppSelector(selectIsEmailChanged);

  const showMessageByCondition = (condition: boolean | null) =>
    condition ? 'Your email is verified' : 'Your email is not verified';

  useEffect(() => {
    if (id) {
      dispatch(asyncConfirmEmail(id));
    }
  }, [dispatch, id]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL}>
        {isLoading ? (
          <div className='skeleton-loader h-6 mx-auto w-1/2' />
        ) : (
          <Typography className='text-center' tag='h5' variant={TypographyVariants.MEDIUM}>
            {!isLoading && isEmailChanged === null
              ? 'Email verification is in the process'
              : showMessageByCondition(isEmailChanged)}
          </Typography>
        )}
      </Constraint>
    </DashboardLayout>
  );
};

export default ConfirmEmailChange;
