import React, { FC } from 'react';

import { ShareExchangeStatuses, shareExchangeStatusesColoredLabel } from 'constants/share-exchange';
import { Investment } from 'interfaces';
import { ColoredLabel } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

type Props = {
  investment: Investment;
  status: ShareExchangeStatuses;
};

const ShareExchangeReviewInvestmentsTableItem: FC<Props> = ({ investment, status }) => {
  return (
    <TableRow>
      <TableData>{investment.classOfShares}</TableData>
      <TableData>{investment.totalShares}</TableData>
      <TableData>{investment.pricePerShare}</TableData>
      <TableData>{investment.investmentDate}</TableData>
      <TableData>
        <ColoredLabel {...shareExchangeStatusesColoredLabel[status]} />
      </TableData>
    </TableRow>
  );
};

export default ShareExchangeReviewInvestmentsTableItem;
