import React from 'react';

import { MetricCard } from 'shared-components';

const BusinessMetrics = () => {
  return (
    <div>
      <div className='flex mb-5 overflow-hidden overflow-x-auto pb-11 border-b border-b-grey-500'>
        <MetricCard />
        <MetricCard />
        <MetricCard />
        <MetricCard />
        <MetricCard />
        <MetricCard />
      </div>
    </div>
  );
};

export default BusinessMetrics;
