import { TabItem, TableColumns } from 'interfaces';
import DocumentsTab from 'page-components/documents-page/DocumentsTab';
import MyBillsTab from 'page-components/documents-page/MyBillsTab';

export enum UploadDocumentsFieldNames {
  COMPANY = 'company',
}

export enum DocumentTypes {
  OTHER = 'other',
}

export enum DocumentNames {
  stock_transfer_form = 'stock_transfer_form',
  shareholder_agreement = 'shareholder_agreement',
  questionnaire = 'questionnaire',
  invoice = 'invoice',
  investment_confirmation_letter = 'investment_confirmation_letter',
  introducer_agreement = 'introducer_agreement',
  application_form = 'application_form',
}

export const DocumentFormattedNames: { [key in DocumentNames]: string } = {
  [DocumentNames.stock_transfer_form]: 'Stock Transfer Form',
  [DocumentNames.shareholder_agreement]: 'Share Exchange Agreement',
  [DocumentNames.questionnaire]: 'Investor Appropriateness Questionnaire',
  [DocumentNames.invoice]: 'Invoice',
  [DocumentNames.investment_confirmation_letter]: 'Investment Confirmation Letter',
  [DocumentNames.introducer_agreement]: 'Introducer Agreement',
  [DocumentNames.application_form]: 'Application Form',
};

export const FILE_EXTENSION_PATTERN = /\.[^/.]+$/;

export const DOCUMENTS_PAGE_TITLE = 'Documents';

export const MY_DOCUMENTS_PAGE_TITLE = 'My Documents';

export const DOCUMENTS_PAGE_DESCRIPTION =
  'If documents are uploaded or signed as part of a share exchange, they will be stored here.';

export const DOCUMENTS_FOUNDER_DESCRIPTION =
  'As part of the ShEx process you may be required to upload certain documents for due diligence purposes.';

export enum DOCUMENTS_PAGE_TABS_IDS {
  DOCUMENTS = 'documents',
  INVOICES = 'invoices',
}

export const DOCUMENTS_PAGE_BASIC_TABS: TabItem[] = [
  { id: DOCUMENTS_PAGE_TABS_IDS.DOCUMENTS, name: 'Documents', component: DocumentsTab },
];

export const DOCUMENTS_PAGE_EXTENDED_TABS: TabItem[] = [
  ...DOCUMENTS_PAGE_BASIC_TABS,
  ...[{ id: DOCUMENTS_PAGE_TABS_IDS.INVOICES, name: 'Invoices', component: MyBillsTab }],
];

export const DOCUMENTS_TABLE_COMPANY_NAME_COLUMN: TableColumns = {
  id: 'company_id',
  title: 'Company Name',
  sortable: true,
};

export const SHARED_DOCUMENTS_TABLE_COLUMNS = [
  { id: 'actions', title: 'Actions' },
  { id: 'investor', title: 'Investor' },
  { id: 'documentType', title: 'Document Type', sortable: true },
  { id: 'location', title: 'Source', sortable: true },
];

export const DOCUMENTS_TABLE_COLUMNS: TableColumns[] = [
  ...SHARED_DOCUMENTS_TABLE_COLUMNS,
  DOCUMENTS_TABLE_COMPANY_NAME_COLUMN,
  { id: 'created_at', title: 'Date Added', sortable: true },
];

export const INVESTOR_DOCUMENTS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions' },
  { id: 'name', title: 'Document Name' },
  { id: 'companyName', title: 'Company Name' },
  { id: 'created_at', title: 'Date Added', sortable: true },
];

export const DOCUMENTS_CAROUSEL_DROPDOWN_ITEMS = [{ id: 'recentlyAdded', title: 'Recently Added' }];

export const UPLOAD_DOCUMENTS_MODAL_TITLE = 'Upload Documents';

export const UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES_TEXT = 'Accepted, PDF,DOCX,XLS';

export const UPLOAD_DOCUMENTS_DRAG_HEAD_TITLE = 'Upload Documents';

export const UPLOAD_DOCUMENTS_ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
];

export const UPLOAD_DOCUMENTS_MAX_FILE_SIZE = 15000000;

export const UPLOAD_DOCUMENTS_INITIAL_VALUES = {
  [UploadDocumentsFieldNames.COMPANY]: null,
};
