import React, { FC, ReactNode } from 'react';

import { DUE_DILIGENCE_DOCUMENT_TYPES_LABELS, DUE_DILIGENCE_DOCUMENTS_TYPES } from 'constants/due-diligence';
import {
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES,
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS,
} from 'constants/significant-control-company';
import { DueDiligenceDocument } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';
import { formatFileSize } from 'utils';

interface Props extends Omit<DueDiligenceDocument, 'documentType'> {
  actionsContent: ReactNode;
  additionalActions: ReactNode;
  hasAdditionalActions: boolean;
  documentType: DUE_DILIGENCE_DOCUMENTS_TYPES | SIGNIFICANT_CONTROL_DOCUMENT_TYPES;
}

const DueDiligenceDocumentsTableItem: FC<Props> = ({
  actionsContent,
  filename,
  documentType,
  location,
  source,
  fileSize,
  officialDate,
  additionalActions,
  hasAdditionalActions,
}) => {
  const formattedFileSize = formatFileSize(Number(fileSize), 1);

  return (
    <TableRow>
      <TableData>{actionsContent}</TableData>
      <TableData className='underline'>{filename}</TableData>
      <TableData>
        {DUE_DILIGENCE_DOCUMENT_TYPES_LABELS[documentType as DUE_DILIGENCE_DOCUMENTS_TYPES] ||
          SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS[documentType as SIGNIFICANT_CONTROL_DOCUMENT_TYPES] ||
          documentType}
      </TableData>
      <TableData>{location}</TableData>
      <TableData>{source}</TableData>
      <TableData>{formattedFileSize}</TableData>
      <TableData>{officialDate}</TableData>
      {hasAdditionalActions && <TableData>{additionalActions}</TableData>}
    </TableRow>
  );
};

export default DueDiligenceDocumentsTableItem;
