export const uploadImageToCanvasWithAspectRatio = (canvas: HTMLCanvasElement, image: HTMLImageElement) => {
  const canvasContext = canvas.getContext('2d');

  const imageAspectRatio = image.width / image.height;
  const canvasAspectRatio = canvas.width / canvas.height;
  let drawWidth, drawHeight;

  if (imageAspectRatio > canvasAspectRatio) {
    drawWidth = canvas.width;
    drawHeight = canvas.width / imageAspectRatio;
  } else {
    drawHeight = canvas.height;
    drawWidth = canvas.height * imageAspectRatio;
  }

  const xOffset = (canvas.width - drawWidth) / 2;
  const yOffset = (canvas.height - drawHeight) / 2;

  canvasContext?.drawImage(image, xOffset, yOffset, drawWidth, drawHeight);
};
