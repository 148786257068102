import React from 'react';

import { INVESTORS_PAGE_TITLE } from 'constants/investors';
import { DashboardLayout, InvestorsTable } from 'page-components';
import { TitleWithDescription } from 'shared-components';

const Investors = () => {
  return (
    <DashboardLayout>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-6' title={INVESTORS_PAGE_TITLE} />
        <InvestorsTable />
      </div>
    </DashboardLayout>
  );
};

export default Investors;
