import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { GetListPayload, TodosResponse } from 'interfaces';
import { stringifyFilterParams } from 'utils';

export class TodosApis {
  constructor(private url: string) {}

  getTodos(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<TodosResponse>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }
}

export const adminTodosApi = new TodosApis('/admin/todos');
