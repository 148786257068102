import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { FileDocumentsState, FileDocument, MetaPage, Option, DocumentType } from 'interfaces';

import {
  getDocuments,
  getCarouselDocuments,
  getTableDocuments,
  getCompaniesFilerOptions,
  getCompaniesDocumentsUploadOptions,
  getDocumentTypes,
  getFundDocuments,
} from './action';

export function getDocumentsReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getDocuments.pending, (state) => {
    state.isLoadingCarouselDocuments = true;
    state.isLoadingTableDocuments = true;
    state.totalPages = 0;
    state.totalItems = 0;
    state.carouselDocuments = null;
    state.tableDocuments = null;
  });

  builder.addCase(getDocuments.fulfilled, (state, action: PayloadAction<{ data: FileDocument[]; meta: MetaPage }>) => {
    state.isLoadingCarouselDocuments = false;
    state.isLoadingTableDocuments = false;
    state.carouselDocuments = action.payload.data;
    state.tableDocuments = action.payload.data;
    state.totalPages = action.payload.meta.last_page;
    state.totalItems = action.payload.meta.total;
  });

  builder.addCase(getDocuments.rejected, (state) => {
    state.isLoadingCarouselDocuments = false;
    state.isLoadingTableDocuments = false;
    state.tableDocuments = null;
    state.carouselDocuments = null;
    state.totalPages = 1;
  });
}

export function getFundDocumentsReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getFundDocuments.pending, (state) => {
    state.isLoadingCarouselDocuments = true;
    state.isLoadingTableDocuments = true;
    state.carouselDocuments = null;
    state.tableDocuments = null;
  });

  builder.addCase(getFundDocuments.fulfilled, (state, action: PayloadAction<{ data: FileDocument[] }>) => {
    state.isLoadingCarouselDocuments = false;
    state.isLoadingTableDocuments = false;
    state.carouselDocuments = action.payload.data;
    state.tableDocuments = action.payload.data;
  });

  builder.addCase(getFundDocuments.rejected, (state) => {
    state.isLoadingCarouselDocuments = false;
    state.isLoadingTableDocuments = false;
    state.tableDocuments = null;
    state.carouselDocuments = null;
  });
}

export function getCarouselDocumentsReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getCarouselDocuments.pending, (state) => {
    state.isLoadingCarouselDocuments = true;
  });

  builder.addCase(getCarouselDocuments.fulfilled, (state, action: PayloadAction<{ data: FileDocument[] }>) => {
    state.isLoadingCarouselDocuments = false;
    state.carouselDocuments = state.carouselDocuments
      ? [...state.carouselDocuments, ...action.payload.data]
      : action.payload.data;
  });

  builder.addCase(getCarouselDocuments.rejected, (state) => {
    state.isLoadingCarouselDocuments = false;
    state.carouselDocuments = null;
    state.totalPages = 1;
  });
}

export function getTableDocumentsReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getTableDocuments.pending, (state) => {
    state.isLoadingTableDocuments = true;
  });

  builder.addCase(getTableDocuments.fulfilled, (state, action: PayloadAction<{ data: FileDocument[] }>) => {
    state.tableDocuments = action.payload.data;
    state.isLoadingTableDocuments = false;
  });

  builder.addCase(getTableDocuments.rejected, (state) => {
    state.isLoadingTableDocuments = false;
    state.tableDocuments = null;
    state.totalPages = 1;
  });
}

export function getFilterCompaniesOptionsReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getCompaniesFilerOptions.pending, (state) => {
    state.filterCompaniesOptions = [];
  });

  builder.addCase(getCompaniesFilerOptions.fulfilled, (state, action: PayloadAction<Option[]>) => {
    state.filterCompaniesOptions = action.payload;
  });

  builder.addCase(getCompaniesFilerOptions.rejected, (state) => {
    state.filterCompaniesOptions = [];
  });
}

export function getCompaniesDocumentsUploadOptionsReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getCompaniesDocumentsUploadOptions.pending, (state) => {
    state.uploadCompaniesOptions = [];
  });

  builder.addCase(getCompaniesDocumentsUploadOptions.fulfilled, (state, action: PayloadAction<Option[]>) => {
    state.uploadCompaniesOptions = action.payload;
  });

  builder.addCase(getCompaniesDocumentsUploadOptions.rejected, (state) => {
    state.uploadCompaniesOptions = [];
  });
}

export function getDocumentTypesReducer(builder: ActionReducerMapBuilder<FileDocumentsState>) {
  builder.addCase(getDocumentTypes.fulfilled, (state, action: PayloadAction<DocumentType[]>) => {
    state.documentTypes = action.payload;
  });
}
