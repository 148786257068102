import React from 'react';

import { ShareExchangeStatuses, shareExchangeStatusesColoredLabel } from 'constants/share-exchange';
import { ButtonVariants } from 'constants/shared/button';
import { DueDiligenceDataWithoutChildren } from 'interfaces';
import {
  ColoredLabel,
  LabelWithValue,
  LinkButton,
  ListItemContainer,
  ListItemTitle,
  ListItemWrapper,
} from 'shared-components';
import { getDueDiligenceLink, getDueDiligenceLinkByDDId } from 'utils';

const LABEL_WITH_VALUE = 'w-auto grow-0 shrink-0 basis-1/2';

interface Props {
  data: DueDiligenceDataWithoutChildren;
}

const ArchivedDueDiligenceListItem = ({ data }: Props) => {
  const { id, businessName, registeredNumber, dateOfIncorporation, status } = data?.businessDetails || {};

  const viewLink =
    status !== ShareExchangeStatuses.IN_PROGRESS
      ? getDueDiligenceLinkByDDId(String(data?.companyId), String(id), true)
      : getDueDiligenceLink(Number(data?.companyId), true);

  return (
    <ListItemWrapper className='flex-col sm:flex-row gap-4 sm:gap-0'>
      <ListItemContainer
        className='flex-col xs:flex-row'
        childrenClassName='ml-0 xs:ml-4'
        firstNode={<ListItemTitle title='Business Name' value={businessName} />}
      >
        <LabelWithValue title='Registered number' content={registeredNumber} className={LABEL_WITH_VALUE} />
        <LabelWithValue title='Date of incorporation' content={dateOfIncorporation} className={LABEL_WITH_VALUE} />
        <LabelWithValue
          className={LABEL_WITH_VALUE}
          title='Status'
          content={status && <ColoredLabel className='ml-0 xs:ml-1' {...shareExchangeStatusesColoredLabel[status]} />}
        />
      </ListItemContainer>

      <LinkButton className='w-full sm:w-40' variant={ButtonVariants.SECONDARY} to={viewLink}>
        View
      </LinkButton>
    </ListItemWrapper>
  );
};

export default ArchivedDueDiligenceListItem;
