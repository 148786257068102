import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

interface Props {
  addNewRow: VoidFunction;
}

const StartInvestmentShareExchangeAddNewRow: FC<Props> = ({ addNewRow }) => {
  return (
    <div className='border-b-0 pt-2'>
      <Button type='button' className='w-fit' variant={ButtonVariants.SECONDARY} onClick={addNewRow}>
        + Add Row
      </Button>
    </div>
  );
};

export default StartInvestmentShareExchangeAddNewRow;
