import React, { forwardRef, InputHTMLAttributes } from 'react';

import cn from 'classnames';

import { INPUT_DEFAULT_STYLES } from '../checkbox/Checkbox';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
}

const RadioV1 = forwardRef<HTMLInputElement, Props>(({ label, className, ...props }, ref) => {
  return (
    <div className={className}>
      <div className='flex w-full gap-2.5 cursor-pointer'>
        <input
          id={props.name}
          type='radio'
          ref={ref}
          {...props}
          className={cn(
            INPUT_DEFAULT_STYLES,
            'checked:after:bg-red-500 checked:border-red-500 checked:after:bg-checkMark',
          )}
        />
        {label && (
          <label htmlFor={props.name} className='inline-block w-full text-grey-500 leading-5 cursor-pointer'>
            {label}
          </label>
        )}
      </div>
    </div>
  );
});

export default RadioV1;

RadioV1.displayName = 'RadioV1';
