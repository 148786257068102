import React, { FC, memo } from 'react';

import cn from 'classnames';
import { format, parseISO } from 'date-fns';

import { ReactComponent as CheckmarkSvg } from 'assets/svg/check-mark.svg';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { TableRowVariants } from 'constants/shared';
import { getTotalYears, getFromAndToDatesContent } from 'helpers';
import { SignificantControlPerson } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

type Props = SignificantControlPerson & {
  hasNominee?: boolean;
};

const PersonsTableItem: FC<Props> = ({ fullName, dateOfBirth, nominee, checked, residentialAddresses, hasNominee }) => {
  const residentialAddress = residentialAddresses?.length ? residentialAddresses[0].address : '';
  const dateRanges = residentialAddresses?.length
    ? residentialAddresses.map(({ from, to, tillNow }) => ({ from, to, tillNow }))
    : [];

  const intervalBetweenDates = getTotalYears(dateRanges);

  const fromAndToDatesContent = residentialAddresses[0] ? getFromAndToDatesContent(residentialAddresses[0]) : '';

  // Formats a value only if it is not the default
  const formattedDateOfBirth =
    !dateOfBirth || dateOfBirth?.includes('/')
      ? dateOfBirth
      : format(parseISO(String(dateOfBirth)), DAY_FORMAT_WITH_SLASHES);

  return (
    <>
      <TableRow variant={TableRowVariants.SIMPLIFIED}>
        <TableData />
        <TableData>{fullName}</TableData>
        <TableData>{formattedDateOfBirth}</TableData>
        {hasNominee && <TableData className='whitespace-normal'>{nominee}</TableData>}
        <TableData className='whitespace-normal py-1'>{residentialAddress}</TableData>
        <TableData>
          {fromAndToDatesContent}{' '}
          {fromAndToDatesContent && intervalBetweenDates >= 0 && (
            <span className={cn('text-green-600', { 'text-red-500': intervalBetweenDates <= 2 })}>
              ({intervalBetweenDates} Years)
            </span>
          )}
        </TableData>
        <TableData className='flex items-center justify-end h-42'>
          {checked ? <CheckmarkSvg className='text-green-600 w-7 h-6 fill-green-600 mx-6' /> : null}
        </TableData>
      </TableRow>

      {residentialAddresses?.slice(1).map((address) => (
        <TableRow key={address.id} variant={TableRowVariants.SIMPLIFIED}>
          <TableData />
          <TableData />
          <TableData />
          {hasNominee && <TableData />}
          <TableData className='whitespace-normal'>{address.address}</TableData>
          <TableData>{getFromAndToDatesContent(address)}</TableData>
          <TableData />
        </TableRow>
      ))}
    </>
  );
};

export default memo(PersonsTableItem);
