import React, { FC } from 'react';

import { shareExchangeStatusesColoredLabel } from 'constants/share-exchange';
import { TypographyStyles } from 'constants/shared/typography';
import { SingleShareExchange } from 'interfaces';
import { ColoredLabel, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

type Props = {
  shareExchange: SingleShareExchange;
};

const ShareExchangeReviewStatusSection: FC<Props> = ({ shareExchange }) => {
  return (
    <SnippetLayout className='my-6'>
      <Typography className={TypographyStyles.medium}>
        Share Exchange: Requested <span className='text-violet-600'>({shareExchange.investments?.length || '0'})</span>
      </Typography>

      <div className='mt-2'>
        <Typography tag='span'>{'Status: '}</Typography>
        <ColoredLabel {...shareExchangeStatusesColoredLabel[shareExchange.status]} />
      </div>

      {shareExchange.reason && (
        <div className='text-xl'>
          <span className='font-bold'>{'Reason: '}</span>
          <span>{shareExchange.reason}</span>
        </div>
      )}
    </SnippetLayout>
  );
};

export default ShareExchangeReviewStatusSection;
