import React, { useEffect, useMemo, useState } from 'react';

import qs from 'query-string';
import { useNavigate, useParams } from 'react-router-dom';

import { COMPANY_IAC_WHOLE_FORM_ERROR_NOTIFICATION } from 'constants/company-iac';
import { ROUTES } from 'constants/routes';
import { ConstraintVariants } from 'constants/shared';
import { UserTypes } from 'constants/user';
import { errorNotify } from 'helpers';
import useModal from 'hooks/use-modal/useModal';
import useCompanyIacBusinessReview from 'hooks/useCompanyIacBusinessReview';
import useCompanyIacOverview from 'hooks/useCompanyIacOverview';
import useCompanyIacSummary from 'hooks/useCompanyIacSummary';
import { COMPANY_IAC_STATUSES, CompanyIac as CompanyIacInterface } from 'interfaces';
import { confirmCompanyIac, getCompanyIac, setCompanyIacData, getCompanyIacDeal } from 'modules/company-iac/action';
import { selectCompanyIacId, selectCompanyIacStatus } from 'modules/company-iac/selectors';
import { selectUserType } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout } from 'page-components';
import {
  CompanyIacHeader,
  CompanyIacOverview,
  CompanyIacSummary,
  CompanyIacBusinessReview,
  CompanyIacConfirm,
  CompanyIacTotalSharesInfo,
  InvitingFundModal,
  CompanyIacDeclineModal,
  CompanyIacStopModal,
  CompanyIacStartDealModal,
} from 'page-components/company-iac';
import { Constraint, LinkButton } from 'shared-components';
import { checkIsAdmin, getArchivedCompanyIacLink, navigateByRole } from 'utils';

import { ButtonVariants } from '../constants/shared/button';
import CompanyIacFmUserConfirmModal from '../page-components/company-iac/company-iac-fm-user-confirm-modal/CompanyIacFmUserConfirmModal';

const CompanyIac = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: companyId, iacId } = useParams();

  const companyIacId = useAppSelector(selectCompanyIacId);
  const companyIacStatus = useAppSelector(selectCompanyIacStatus);
  const userType = useAppSelector(selectUserType);

  const [isLoading, setIsLoading] = useState(false);

  const { reset: summaryFormReset, trigger: triggerSummary, ...summaryData } = useCompanyIacSummary();
  const {
    reset: businessReviewFormReset,
    trigger: triggerBusinessReview,
    ...businessReviewData
  } = useCompanyIacBusinessReview();

  const {
    reset: overviewFormReset,
    trigger: triggerOverview,
    totalShares,
    checkedTotalShares,
    ...companyOverviewData
  } = useCompanyIacOverview();

  const { onOpen: onOpenInvitingModal, ...invitingFundModalProps } = useModal();
  const { onOpen: onOpenDeclineModal, ...declineModalProps } = useModal();
  const { onOpen: onOpenStopModal, ...stopModalProps } = useModal();
  const { onOpen: onOpenFmUserConfirmModal, ...fmUserConfirmModal } = useModal();
  const { onOpen: onOpenStartDealModal, ...startDealModalProps } = useModal();

  const isAdmin = useMemo(() => checkIsAdmin(), []);
  const isFmUser = userType === UserTypes.FM_USER;

  const hideAdminActions = !isAdmin || companyIacStatus === COMPANY_IAC_STATUSES.STOPPED;

  const onSubmitCompanyIac = async () => {
    if (!isAdmin) {
      onOpenFmUserConfirmModal();
      return;
    }

    companyOverviewData.setIsAllowAdditionalValidation(true);

    const overviewValidated = await triggerOverview();
    const summaryValidated = await triggerSummary();
    const businessReviewValidated = await triggerBusinessReview();

    const formValid = overviewValidated && businessReviewValidated && summaryValidated;

    if (!formValid || !totalShares) {
      errorNotify(COMPANY_IAC_WHOLE_FORM_ERROR_NOTIFICATION);
    }

    if (formValid && companyIacStatus === COMPANY_IAC_STATUSES.CONFIRMED_BY_ADMIN) {
      onOpenInvitingModal();
      return;
    }

    if (isAdmin && formValid && totalShares) {
      if (companyIacStatus !== COMPANY_IAC_STATUSES.REVIEWING_BY_FMUSERS) {
        setIsLoading(true);

        dispatch(confirmCompanyIac({ iacId: companyIacId, isAdmin }))
          .unwrap()
          .then(() => onOpenInvitingModal())
          .finally(() => setIsLoading(false));
      } else {
        onOpenInvitingModal();
      }
    }
  };

  const onDeclineCompanyIac = () => {
    if (isAdmin) {
      return;
    }

    onOpenDeclineModal();
  };

  const onStopCompanyIac = () => {
    if (!isAdmin) {
      return;
    }

    onOpenStopModal();
  };

  const onStartDeal = () => {
    if (!isAdmin || !companyId) {
      return;
    }

    setIsLoading(true);

    dispatch(getCompanyIacDeal(companyId))
      .unwrap()
      .then(() => onOpenStartDealModal())
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const { openSendToReview } = qs.parse(location.search);

    if (!companyId) {
      navigate(navigateByRole(true, ROUTES.companies));
      return;
    }
    dispatch(getCompanyIac({ companyId, iacId, isAdmin }))
      .unwrap()
      .catch(() => {
        navigate(navigateByRole(true, ROUTES.companies));
      });

    if (openSendToReview) {
      onOpenInvitingModal();
    }
  }, [dispatch, companyId, iacId, isAdmin, navigate, onOpenInvitingModal]);

  useEffect(() => {
    dispatch(setCompanyIacData({} as CompanyIacInterface));
    overviewFormReset({});
    summaryFormReset({});
    businessReviewFormReset({});
  }, [dispatch, overviewFormReset, businessReviewFormReset, summaryFormReset]);

  return (
    <>
      {isAdmin && (
        <InvitingFundModal {...invitingFundModalProps} companyIacId={companyOverviewData?.id} companyId={companyId} />
      )}

      {isAdmin && <CompanyIacStartDealModal companyId={companyId} {...startDealModalProps} />}

      {!isAdmin && <CompanyIacFmUserConfirmModal {...fmUserConfirmModal} />}

      <CompanyIacDeclineModal {...declineModalProps} />

      <CompanyIacStopModal {...stopModalProps} />

      <DashboardLayout>
        <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
          <CompanyIacHeader />

          <LinkButton
            variant={ButtonVariants.SECONDARY}
            className='self-end w-full sm:w-auto mb-4'
            to={getArchivedCompanyIacLink(companyId ? +companyId : 0, isFmUser || isAdmin)}
          >
            Archived IAC
          </LinkButton>

          <div className='space-y-6'>
            <CompanyIacOverview {...companyOverviewData} hideAdminActions={hideAdminActions} totalShares={totalShares}>
              <CompanyIacTotalSharesInfo totalShares={totalShares} checkedTotalShares={checkedTotalShares} />
            </CompanyIacOverview>
            <CompanyIacSummary {...summaryData} hideAdminActions={hideAdminActions} />
            <CompanyIacBusinessReview {...businessReviewData} hideAdminActions={hideAdminActions} />

            <CompanyIacConfirm
              isLoading={isLoading}
              onStopCompanyIac={onStopCompanyIac}
              onDeclineCompanyIac={onDeclineCompanyIac}
              onSubmitCompanyIac={onSubmitCompanyIac}
              onStartDeal={onStartDeal}
            />
          </div>
        </Constraint>
      </DashboardLayout>
    </>
  );
};

export default CompanyIac;
