import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { FundFormFieldNames } from 'constants/funds';
import { Fund, FundFormInput } from 'interfaces/funds.interfaces';

const useFundForm = ({ fund }: { fund?: Fund }) => {
  const fundFormMethods = useForm<FundFormInput>();

  const { watch, setValue, reset } = fundFormMethods;

  const watchSharePrice = watch(FundFormFieldNames.SHARE_PRICE);
  const watchSharesIssued = watch(FundFormFieldNames.SHARES_ISSUED);

  useEffect(() => {
    if (!fund) return;

    const { company, ...fundValues } = fund ?? {};

    const { companyName, companyNumber, ...companyValues } = company ?? {};

    reset({
      ...companyValues,
      ...fundValues,
      companyName: companyName ? { label: companyName, value: companyNumber } : null,
      companyNumber: companyNumber ? { label: companyNumber, value: companyName } : null,
      [FundFormFieldNames.TYPES_OF_SHARES]: fund?.typesOfShares
        ? { label: fund.typesOfShares[0], value: fund.typesOfShares[0] }
        : null,
    });
  }, [fund, reset]);

  useEffect(() => {
    if (!watchSharePrice && !watchSharesIssued) return;

    const calculatedAum = Number(watchSharePrice) * Number(watchSharesIssued);
    setValue(FundFormFieldNames.AUM, calculatedAum.toString());
  }, [setValue, watchSharePrice, watchSharesIssued]);

  return fundFormMethods;
};

export default useFundForm;
