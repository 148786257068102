import React from 'react';

import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { getTitleWithReplacedYourWord } from 'utils';

const ShareExchangeModalTooltipMessage = () => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  return (
    <div>
      <p>1. Please choose which class(es) of shares you would like to exchange</p>
      <p>2. Please input the number of shares you would like to exchange</p>
      <p>
        {getTitleWithReplacedYourWord(
          '3. Please select from the available dates when you would like your share exchange to close.',
          entityName,
          isTypeEntity,
        )}
      </p>
    </div>
  );
};

export default ShareExchangeModalTooltipMessage;
