import { useCallback, useEffect } from 'react';

import { DUE_DILIGENCE_UNSAVED_DATA_WARNING_MESSAGE } from 'constants/due-diligence';
import { setDueDiligenceHighlightUnsavedChanges } from 'modules/due-diligence/action';
import { selectDueDiligenceEditingSections } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import history from 'services/history';

// This hook is for preventing page leave on DD pages with unsaved changes
const useDueDiligencePreventPageLeave = () => {
  const dispatch = useAppDispatch();

  const dueDiligenceEditingSections = useAppSelector(selectDueDiligenceEditingSections);

  const handleBeforeUnload = useCallback(
    (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      ev.returnValue = '';

      dispatch(setDueDiligenceHighlightUnsavedChanges(true));
    },
    [dispatch],
  );

  useEffect(() => {
    const unblock = history.block(({ retry }) => {
      if (!dueDiligenceEditingSections || !dueDiligenceEditingSections.length) {
        return;
      }

      dispatch(setDueDiligenceHighlightUnsavedChanges(true));

      if (window.confirm(DUE_DILIGENCE_UNSAVED_DATA_WARNING_MESSAGE)) {
        unblock();
        retry();
      }
    });

    if (!dueDiligenceEditingSections || !dueDiligenceEditingSections.length) {
      unblock();
    }

    return unblock;
  }, [dueDiligenceEditingSections, dispatch]);

  useEffect(() => {
    if (dueDiligenceEditingSections && dueDiligenceEditingSections.length) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dueDiligenceEditingSections, handleBeforeUnload]);

  useEffect(() => {
    dispatch(setDueDiligenceHighlightUnsavedChanges(false));
  }, [dispatch]);
};

export default useDueDiligencePreventPageLeave;
