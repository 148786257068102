import React from 'react';

import { PLAND_SUPPORT_EMAIL } from 'constants/global';

const NeedHelpLink = () => (
  <a
    className='absolute mt-[20px] pb-[20px] left-1/2 -translate-x-2/4 text-grey-500 cursor-pointer'
    href={`mailto:${PLAND_SUPPORT_EMAIL}`}
  >
    Need Help?
  </a>
);

export default React.memo(NeedHelpLink);
