import { adminCompaniesApis, fmUserApis, founderCompaniesApis, investorsCompaniesApis } from 'apis';
import { UserTypes } from 'constants/user';
import { CompanyNatureOfBusiness, GetListPayload } from 'interfaces';

export const getNatureOfBusinessList = (list: CompanyNatureOfBusiness[]): string =>
  list?.reduce((prev, { name, code }) => prev + `${prev && ', '}${code} - ${name}`, '') || '';

export const getCompaniesOptionsRequestByUserType = (userType: UserTypes, params: GetListPayload) => {
  switch (userType) {
    case UserTypes.INVESTOR:
      return investorsCompaniesApis.getMyCompanies(params);
    case UserTypes.FOUNDER:
      return founderCompaniesApis.getMyCompanies(params);
    case UserTypes.FM_USER:
      return fmUserApis.getMyCompanies(params);
    default:
      return adminCompaniesApis.getMyCompanies(params);
  }
};
