import React, { FC } from 'react';

import { format, parseISO } from 'date-fns';

import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { feeStatusesTextLabel } from 'constants/fees';
import { TableRowVariants } from 'constants/shared';
import { Fee } from 'interfaces';
import { TableData, TableRow } from 'shared-components/table';

const FeesTableRow: FC<Fee> = ({ feeRate, feeMin, activeFrom, status }) => {
  return (
    <TableRow variant={TableRowVariants.SIMPLIFIED}>
      <TableData className='pl-5'>{feeRate}%</TableData>
      <TableData>£{feeMin}</TableData>
      <TableData>{format(parseISO(activeFrom), DAY_FORMAT_WITH_SLASHES)}</TableData>
      <TableData>{feeStatusesTextLabel[status]}</TableData>
    </TableRow>
  );
};

export default FeesTableRow;
