import React, { FC } from 'react';

interface Props {
  reason: string;
}

const MyInvestmentsDeclineReasonTooltipContent: FC<Props> = ({ reason }) => {
  return (
    <div>
      Previous request for share exchange has been declined - {reason}.<br /> Review info and re-submit
    </div>
  );
};

export default MyInvestmentsDeclineReasonTooltipContent;
