import React, { ReactNode } from 'react';

interface Props {
  conditional: boolean;
  children: ReactNode;
  elseContent?: ReactNode;
}

const ConditionalRender = ({ conditional, children, elseContent }: Props) => {
  return <>{conditional ? children : elseContent || null}</>;
};

export default ConditionalRender;
