import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import { GetListPayload } from 'interfaces';
import { AuditLog, AuditLogsResponse } from 'interfaces/audit-logs.interfaces';
import { stringifyFilterParams } from 'utils/global/global';

export class AuditLogsApis {
  constructor(private url: string) {}

  getAuditLogs(params: GetListPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<AuditLogsResponse>(`${this.url}?${stringifyFilterParams(params)}`, config);
  }

  getAuditLogById(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: AuditLog }>(`${this.url}/${id}`, config);
  }
}

export const adminAuditLogsApis = new AuditLogsApis('/admin/activity-logs');
