import React, { FC, useState } from 'react';

import { useForm } from 'react-hook-form';

import { DECLINE_SHARE_EXCHANGE_MODAL_TITLE, getDeclineShareExchangeModalFields } from 'constants/share-exchange';
import { DeclineShareExchangeInputs } from 'interfaces';
import { AlertModal, FormContent } from 'shared-components';

import DeclineShareExchangeQualificationQuestionModalDescription from './DeclineShareExchangeQualificationQuestionModalDescription';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onSubmitDeclineQualificationAnswers: (reason: string) => void;
  companyName?: string;
  companyNumber?: string;
};

const DeclineShareExchangeQualificationQuestionsModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmitDeclineQualificationAnswers,
  isLoading,
  companyName,
  companyNumber,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<DeclineShareExchangeInputs>();

  const onSubmit = (data: DeclineShareExchangeInputs) => {
    onSubmitDeclineQualificationAnswers(data.reason);
  };

  const onClickSubmitDeclineQualificationAnswers = () => {
    if (!isFormVisible) {
      setIsFormVisible(true);
      return;
    }

    handleSubmit(onSubmit)();
  };

  const onClickCancel = () => {
    setIsFormVisible(false);
    onClose();
  };

  return (
    <AlertModal
      secondModal
      isOpen={isOpen}
      onClose={onClickCancel}
      onSubmit={onClickSubmitDeclineQualificationAnswers}
      isLoading={isLoading}
      submitButtonText='Yes'
      backButtonText='No'
      title={DECLINE_SHARE_EXCHANGE_MODAL_TITLE}
      description={
        <DeclineShareExchangeQualificationQuestionModalDescription
          companyName={companyName}
          companyNumber={companyNumber}
        />
      }
    >
      {isFormVisible && (
        <form className='mt-12 w-full'>
          <FormContent fields={getDeclineShareExchangeModalFields()} register={register} errors={errors} />
        </form>
      )}
    </AlertModal>
  );
};

export default DeclineShareExchangeQualificationQuestionsModal;
