import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { ShareExchangeSubmittedAnswers } from 'interfaces';
import { Button } from 'shared-components';

import ShareExchangeQualificationQuestionsList from '../ShareExchangeQualificationQuestionsList';

type Props = {
  answers: ShareExchangeSubmittedAnswers[];
  onClickReject: VoidFunction;
  onClickAccept: VoidFunction;
  isLoading: boolean;
};

const ShareExchangeQualificationQuestionsReviewModalContent: FC<Props> = ({
  answers,
  onClickReject,
  onClickAccept,
  isLoading,
}) => {
  return (
    <div>
      <ShareExchangeQualificationQuestionsList answers={answers} />

      <div className='w-full justify-between flex flex-col-reverse sm:flex-row gap-4'>
        <Button
          isLoading={isLoading}
          variant={ButtonVariants.SECONDARY}
          onClick={onClickReject}
          className='w-full sm:w-150'
        >
          Reject
        </Button>
        <Button isLoading={isLoading} onClick={onClickAccept} className='w-full sm:w-300'>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default ShareExchangeQualificationQuestionsReviewModalContent;
