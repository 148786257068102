import React, { FC } from 'react';

import { InviteUserType, UserTypes } from 'constants/user';
import { selectIsActionLoading, selectUserType } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { MyInvitesModalForm } from 'page-components/index';

interface Props {
  companyId?: string | number;
}

const CompanyInviteFounder: FC<Props> = ({ companyId }) => {
  const isInvitesActionLoading = useAppSelector(selectIsActionLoading);
  const userType = useAppSelector(selectUserType);

  if (userType !== UserTypes.INVESTOR) return null;

  return (
    <div className='rounded-md border border-grey-300 my-6 p-5'>
      <div className='flex justify-between items-center'>
        <div className='mr-4'>
          <p className='text-xl font-bold'>Invite Founders</p>
        </div>
        <div>
          <MyInvitesModalForm
            companyId={Number(companyId)}
            buttonClassName='min-w-120 max-w-[140px] w-full h-42 mt-0'
            loading={isInvitesActionLoading}
            type={UserTypes.FOUNDER}
            inviteType={InviteUserType.DUE_DILIGENCE}
          />
        </div>
      </div>
    </div>
  );
};
export default CompanyInviteFounder;
