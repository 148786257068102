import React, { useEffect, useMemo } from 'react';

import { CompanyWithInvestmentsAndDeals } from 'interfaces';
import { getDeals } from 'modules/deals/action';
import { selectDeals } from 'modules/deals/selectors';
import { getCompaniesWithInvestments } from 'modules/investments/action';
import { selectCompaniesWithInvestments, selectIsLoading } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';
import { getInvestmentsForBanner } from 'utils/investments';

import InvestmentsStepsGroup from './InvestmentsStepsGroup';
import InvestmentStepsSnippetItem from './InvestmentsStepsSnippetItem';

const REQUEST_PARAMS = {
  page: 1,
  per_page: 10,
  filter: `filter[status][]=active,inExchange`,
};

const InvestmentsStepsSnippet = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);
  const companiesWithInvestment = useAppSelector(selectCompaniesWithInvestments);
  const deals = useAppSelector(selectDeals);

  const companiesInvestmentsToShow = useMemo(() => {
    return getInvestmentsForBanner(companiesWithInvestment, deals ?? []);
  }, [companiesWithInvestment, deals]);

  useEffect(() => {
    dispatch(getCompaniesWithInvestments(REQUEST_PARAMS));
    dispatch(getDeals({ page: 1, per_page: 10 }));
  }, [dispatch]);

  return (
    <SnippetLayout className='row-start-1 row-end-2 col-start-1 col-end-3'>
      <InvestmentsStepsGroup companyWithInvestment={companiesInvestmentsToShow} isLoading={isLoading}>
        {companiesInvestmentsToShow?.length ? (
          <div className='max-h-[800px] overflow-y-auto'>
            {companiesInvestmentsToShow.map((companyWithInvestment: CompanyWithInvestmentsAndDeals, index) => (
              <InvestmentStepsSnippetItem
                key={companyWithInvestment.id}
                companyWithInvestment={companyWithInvestment}
                showCompany={companiesInvestmentsToShow.length > 1}
                isLast={index === companiesInvestmentsToShow.length - 1}
              />
            ))}
          </div>
        ) : null}
      </InvestmentsStepsGroup>
    </SnippetLayout>
  );
};

export default InvestmentsStepsSnippet;
