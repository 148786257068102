import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { TableColumns, TableSorting, UpdateSortingFunctionType } from 'interfaces';

import TableHead from './TableHead';

export type Props = {
  children: ReactNode;
  columns: TableColumns[];
  className?: string;
  wrapperClassName?: string;
  onUpdateSorting?: UpdateSortingFunctionType;
  sorting?: TableSorting;
  disabledXOverflow?: boolean;
};

const Table: FC<Props> = ({
  children,
  columns,
  onUpdateSorting,
  className,
  wrapperClassName = '',
  disabledXOverflow = false,
  sorting,
}) => {
  return (
    <div className={!disabledXOverflow ? cn('overflow-x-auto hide-scrollbar', wrapperClassName) : wrapperClassName}>
      <table className={twMerge('whitespace-nowrap w-full', className)}>
        <TableHead onUpdateSorting={onUpdateSorting} sorting={sorting} columns={columns} />

        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
