import React from 'react';

import { Company, DealProgressItem } from 'interfaces';
import { SnippetLayout } from 'shared-components';

import FounderShareExchangeBlock from './FounderShareExchangeBlock';
import FounderShareExchangesSnippetContentWrapper from './FounderShareExchangesSnippetContentWrapper';

interface Props {
  companies?: Company[] | null;
  isCompaniesLoading: boolean;
  dealsProgress: DealProgressItem[];
  isLoadingDeals: boolean;
}

const FounderShareExchangesSnippet = ({ companies, isCompaniesLoading, dealsProgress, isLoadingDeals }: Props) => {
  return (
    <SnippetLayout title='Deals' className='overflow-hidden' childrenClassName='overflow-hidden'>
      <FounderShareExchangesSnippetContentWrapper
        isLoading={isLoadingDeals || (!companies?.length && isCompaniesLoading)}
        dealsLength={dealsProgress?.length}
        companiesLength={companies?.length}
        skeletonItems={3}
      >
        {dealsProgress?.length ? (
          <div className='max-h-[500px] overflow-y-auto'>
            {dealsProgress.map((deal) => (
              <FounderShareExchangeBlock key={deal.id} {...deal} />
            ))}
          </div>
        ) : null}
      </FounderShareExchangesSnippetContentWrapper>
    </SnippetLayout>
  );
};

export default FounderShareExchangesSnippet;
