import React, { Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { ADMIN_DEALS_TABLE_COLUMNS, DEALS_TABLE_COLUMNS } from 'constants/deals';
import { START_SHARE_EXCHANGE_TITLE } from 'constants/share-exchange';
import { CheckboxVariants } from 'constants/shared';
import useDeleteDeal from 'hooks/deals/useDeleteDeal';
import useModal from 'hooks/use-modal/useModal';
import useStartInvestmentShareExchange from 'hooks/useStartInvestmentShareExchange';
import {
  ButtonDropdownActions,
  Deal,
  DealHandleClickRequestShareExchangeType,
  UpdateSortingFunctionType,
} from 'interfaces';
import { getAdminCompanyExchangeData } from 'modules/companies/action';
import { getCompanyDealPredefinedData } from 'modules/deals/action';
import { selectDealParametersMainInfo, selectDeals, selectIsLoadingDeals } from 'modules/deals/selectors';
import { selectInvestorsData } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { StartInvestmentShareExchangeModal } from 'page-components/index';
import { AlertModal, Button, ButtonDropdown, Checkbox, SearchForm } from 'shared-components';
import AddInvestmentButton from 'shared-components/AddInvestmentButton';
import Table, { TableSkeleton } from 'shared-components/table';
import { checkIsAdmin } from 'utils';
import { checkIsDealDeleted } from 'utils/deals';

import ConfirmStartNewShareExchangeModal from '../deals-details/ConfirmStartNewShareExchangeModal';
import StartShareExchangesModalWithInvestor from '../share-exchanges/StartShareExchangesModalWithInvestor';
import AdminDealsTableRow from './AdminDealsTableRow';
import DealsTableRow from './DealsTableRow';
import NoDealsAdded from './NoDealsAdded';

const DEALS_PER_PAGE = 7;

interface Props {
  headerActions?: ButtonDropdownActions[];
  placeholder?: string;
  selectedDeals?: Deal[];
  setSelectedDeals?: Dispatch<SetStateAction<Deal[]>>;
  isLoading?: boolean;
  children?: ReactNode;
  updateSorting: UpdateSortingFunctionType;
  handleFilter: (search: string) => void;
  handleLoadDeals?: VoidFunction;
}

const DealsTable: FC<Props> = ({
  headerActions,
  placeholder,
  selectedDeals = [],
  setSelectedDeals,
  isLoading,
  updateSorting,
  handleFilter,
  children,
  handleLoadDeals,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { companyId } = useAppSelector(selectDealParametersMainInfo);

  const deals = useAppSelector(selectDeals);
  const loading = useAppSelector(selectIsLoadingDeals);
  const { isLoading: isInvestorsLoading } = useAppSelector(selectInvestorsData);

  const isAdmin = useMemo(() => checkIsAdmin(), []);

  const { onOpen: handleOpenConfirmStartExchangeModal, ...confirmStartNewExchangeModalProps } = useModal();

  const { isOpenStartShareExchange, onOpenStartShareExchange, onCloseStartShareExchange, onSuccessStartShareExchange } =
    useStartInvestmentShareExchange();
  const {
    isOpen: isOpenShareExchangeModal,
    onClose: onCloseShareExchangeModal,
    onOpen: onOpenShareExchangeModal,
    ...shareExchangeModalProps
  } = useModal();

  const [handleDeleteDeal, { onOpen: handleOpenDeleteDealModal, ...deleteDealModalProps }, isDeleteDealLoading] =
    useDeleteDeal({
      onSuccess: () => {
        handleOpenConfirmStartExchangeModal(deleteDealModalProps?.modalProps);
        handleLoadDeals?.();
      },
    });

  const handleSelectDeal = (currentDeal: Deal) => {
    if (!setSelectedDeals) return;

    setSelectedDeals((prev) =>
      prev.some(({ id }) => id === currentDeal.id)
        ? prev.filter(({ id }) => id !== currentDeal.id)
        : [...prev, currentDeal],
    );
  };
  const handleAllSelectedDeals = useCallback(() => {
    if (!deals?.length || !setSelectedDeals) return;

    const filteredNotDeletedDeals = deals.filter(({ status }) => !checkIsDealDeleted(status));

    const newSelectedDeals = selectedDeals?.length < filteredNotDeletedDeals?.length ? filteredNotDeletedDeals : [];

    setSelectedDeals(newSelectedDeals);
  }, [deals, selectedDeals?.length, setSelectedDeals]);

  const checkboxTableColumn = useMemo(
    () => ({
      id: 'checkbox',
      title: (
        <Checkbox
          variant={CheckboxVariants.ALL}
          onChange={handleAllSelectedDeals}
          checked={selectedDeals?.length === deals?.length}
        />
      ),
    }),
    [deals?.length, handleAllSelectedDeals, selectedDeals?.length],
  );

  const tableColumns = useMemo(
    () =>
      isAdmin
        ? [{ id: 'view', title: '', width: 'w-44' }, checkboxTableColumn, ...ADMIN_DEALS_TABLE_COLUMNS]
        : DEALS_TABLE_COLUMNS,
    [checkboxTableColumn, isAdmin],
  );

  const handleClickRequestShareExchange: DealHandleClickRequestShareExchangeType = useCallback(
    ({ companyId, draftDealId }) => {
      dispatch(getCompanyDealPredefinedData({ companyId, draftDealId }));

      onOpenStartShareExchange();
      return;
    },
    [dispatch, onOpenStartShareExchange],
  );

  const handleOpenShareExchangeModal = () => {
    if (!companyId) return;
    onOpenShareExchangeModal();
    dispatch(getAdminCompanyExchangeData(companyId))
      .unwrap()
      .then(() => {
        onOpenShareExchangeModal();
      });
  };

  return (
    <>
      <StartInvestmentShareExchangeModal
        isOpen={isOpenStartShareExchange}
        onClose={onCloseStartShareExchange}
        onSuccessStartShareExchange={onSuccessStartShareExchange}
      />
      <ConfirmStartNewShareExchangeModal
        companyId={confirmStartNewExchangeModalProps?.modalProps?.companyId}
        {...confirmStartNewExchangeModalProps}
      />

      <AlertModal
        onSubmit={() => handleDeleteDeal(deleteDealModalProps?.modalProps?.id)}
        isLoading={isDeleteDealLoading}
        title='Are you sure you want to delete this Share Exchange?'
        submitButtonText='Confirm'
        modalClassName='lg:w-8/12 xl:w-6/12'
        contentClassName='lg:px-28 lg:py-12'
        wrapperModalClassName='lg:py-0 lg:px-0'
        {...deleteDealModalProps}
      />

      <StartShareExchangesModalWithInvestor
        companyId={companyId}
        isOpen={isOpenShareExchangeModal}
        onClose={onCloseShareExchangeModal}
        title={START_SHARE_EXCHANGE_TITLE}
        {...shareExchangeModalProps}
      />

      <div className='flex flex-col'>
        <div className='flex justify-between flex-col md:flex-row gap-4 mb-12'>
          <div className='flex w-full justify-between items-center flex-col sm:flex-row gap-4 sm:gap-0'>
            <SearchForm
              className='w-full flex-col xs:flex-row items-center basis-full 2lg:basis-7/12 gap-4 xs:gap-0'
              handleFilter={handleFilter}
              placeholder={placeholder || 'Search by Company Name, Date Created, Status, Stage'}
            />
            {!isAdmin && <AddInvestmentButton className='w-full sm:w-auto' />}
          </div>
          <div className='flex gap-4 flex-col sm:flex-row'>
            {isAdmin && (
              <Button
                onClick={handleOpenShareExchangeModal}
                isLoading={isInvestorsLoading}
                className='w-full md:w-auto whitespace-nowrap'
              >
                Start Share Exchange
              </Button>
            )}
            {headerActions && (
              <ButtonDropdown
                className='w-full md:w-40'
                actions={headerActions}
                isButtonLoading={isLoading}
                disabledButton={!selectedDeals?.length}
              />
            )}
          </div>
        </div>
        <Table onUpdateSorting={updateSorting} columns={tableColumns}>
          {deals?.length && !loading ? (
            deals?.map((deal) =>
              isAdmin ? (
                <AdminDealsTableRow
                  key={deal.id}
                  deal={deal}
                  handleSelectDeal={handleSelectDeal}
                  selected={selectedDeals.some((selectedDeal) => deal.id === selectedDeal.id)}
                />
              ) : (
                <DealsTableRow
                  key={deal.id}
                  deal={deal}
                  navigate={navigate}
                  handleClickRequestShareExchange={handleClickRequestShareExchange}
                  handleOpenDeleteDealModal={handleOpenDeleteDealModal}
                />
              ),
            )
          ) : loading ? (
            <TableSkeleton rowsNumber={DEALS_PER_PAGE} columnsNumber={tableColumns.length} />
          ) : (
            <NoDealsAdded />
          )}
        </Table>
        {children}
      </div>
    </>
  );
};

export default DealsTable;
