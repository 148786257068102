import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left.svg';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { getDueDiligenceData, setDueDiligence } from 'modules/due-diligence/action';
import { selectDueDiligenceRegisteredOffice } from 'modules/due-diligence/selectors';
import { initialState } from 'modules/due-diligence/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  DashboardLayout,
  SignificantControlCompanyHeader,
  SignificantControlCompanyGroupStructure,
  SignificantControlCompanyDocuments,
  SignificantControlCompanyDirectors,
} from 'page-components';
import { Button, Constraint, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

const SignificantControlCompany = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isDueDiligenceLoaded, setIsDueDiligenceLoaded] = useState(false);

  const companyRegisteredOffice = useAppSelector(selectDueDiligenceRegisteredOffice);

  const handleGoBack = () => navigate(-1);

  useEffect(() => {
    dispatch(setDueDiligence(initialState));

    if (id) {
      dispatch(
        getDueDiligenceData({
          companyId: id,
        }),
      )
        .unwrap()
        .then(() => setIsDueDiligenceLoaded(true));
    }
  }, [dispatch, id]);

  return (
    <DashboardLayout>
      <Button className='w-fit ml-10 mt-6' onClick={handleGoBack} variant={ButtonVariants.SECONDARY}>
        <ArrowLeftIcon className='mr-2' /> Back
      </Button>

      <Constraint className='!mt-6' variant={ConstraintVariants.FULL_ROUNDED}>
        <SignificantControlCompanyHeader isDueDiligenceLoaded={isDueDiligenceLoaded} />

        <SnippetLayout className='mt-4' title='Registered Office'>
          {companyRegisteredOffice ? (
            <Typography className='text-violet-600'>{companyRegisteredOffice}</Typography>
          ) : (
            <div className='skeleton-loader w-300 h-6' />
          )}
        </SnippetLayout>

        <SignificantControlCompanyGroupStructure />

        <SignificantControlCompanyDirectors id={id} />

        <SnippetLayout className='mt-6' childrenClassName='overflow-auto' title='Documents'>
          <SignificantControlCompanyDocuments />
        </SnippetLayout>
      </Constraint>
    </DashboardLayout>
  );
};

export default SignificantControlCompany;
