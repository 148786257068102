import React, { FC } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { CompanyShareExchangeSharedProps } from 'interfaces';
import { Button, LabelWithValue, SnippetLayout } from 'shared-components';
import ProgressBar from 'shared-components/ProgressBar';
import Typography from 'shared-components/Typography';
import { getLinkWithTabId, navigateByRole } from 'utils';

const SHARED_COMPANY_PROFILE_DETAILS_CLASSNAME = 'flex flex-col w-fit [&>span]:text-blue-800 [&>span]:font-bold';

interface Props extends CompanyShareExchangeSharedProps {
  dealId: string | number;
  companyId?: string;
}

const AdminCompanyProfileShareExchangeSection: FC<Props> = ({
  amountShareExchangesCompleted,
  amountShareExchangesInProgress,
  amountShareExchangesStopped,
  noOfSharesExchanged,
  noOfShares,
  noOfSharesRemaining,
  isLoading,
  dealId,
  companyId,
}) => {
  const navigate = useNavigate();

  if (isLoading) return <div className='skeleton-loader h-60 my-6 rounded-lg' />;

  const shareExchangeProgress =
    noOfShares && noOfSharesExchanged ? Number(((noOfSharesExchanged / noOfShares) * 100).toFixed(1)) : 0;

  const handleNavigateShareExchange = () =>
    navigate(
      navigateByRole(true, getLinkWithTabId(ROUTES.dealParameters.replace(':id', String(dealId)), 'share-exchanges')),
      { state: { companyId } },
    );

  return (
    <SnippetLayout className='mt-6'>
      <h4 className='text-lg text-blue-800'>Share Exchange Information</h4>
      <div className='mt-6 px-6 py-4 bg-grey-100 rounded-2xl'>
        <div className='flex justify-between mb-5'>
          <LabelWithValue
            className='flex flex-col w-fit'
            title='Total Shared Exchanged'
            content={
              <span className='text-violet-800 font-bold'>
                {noOfSharesExchanged} Shares <span className='font-normal'>({shareExchangeProgress}%)</span>
              </span>
            }
          />
          <LabelWithValue
            className={cn(SHARED_COMPANY_PROFILE_DETAILS_CLASSNAME, 'text-end')}
            title='Deal Exchange Allowance'
            content={Number.isInteger(noOfSharesRemaining) ? String(noOfSharesRemaining) : null}
          />
        </div>
        <ProgressBar progress={Number(shareExchangeProgress)} className='bg-white' isLoading={isLoading} />
        <Typography className='mt-4'>
          <span className='font-semibold text-blue'>{noOfSharesExchanged}</span> shares out of{' '}
          <b className='font-semibold text-blue-800'>{noOfShares}</b> exchanged
        </Typography>
        <div className='flex justify-between items-center mt-6'>
          <div className='flex'>
            <LabelWithValue
              className={SHARED_COMPANY_PROFILE_DETAILS_CLASSNAME}
              title='No. Exchange in Progress'
              content={amountShareExchangesInProgress}
            />
            <LabelWithValue
              className={SHARED_COMPANY_PROFILE_DETAILS_CLASSNAME}
              title='No. Exchange in Stopped'
              content={amountShareExchangesStopped}
            />
            <LabelWithValue
              className={SHARED_COMPANY_PROFILE_DETAILS_CLASSNAME}
              title='No. Exchange Complete'
              content={amountShareExchangesCompleted}
            />
          </div>
          <Button
            variant={ButtonVariants.LINK}
            className='w-200 whitespace-nowrap'
            disabled={!dealId}
            onClick={handleNavigateShareExchange}
          >
            View Share Exchanges
          </Button>
        </div>
      </div>
    </SnippetLayout>
  );
};

export default AdminCompanyProfileShareExchangeSection;
