import React, { ReactNode } from 'react';

import ReactDOM from 'react-dom';

import useLockBodyScroll from 'hooks/use-lock-body-scroll/useLockBodyScroll';

import { Overlay } from '../index';
interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose: VoidFunction;
}

const FullScreenModal = ({ children, isOpen, onClose }: Props) => {
  useLockBodyScroll(isOpen);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className='fixed left-0 top-0 flex justify-center w-full h-full z-40'>
      <div className='w-10/12 mx-4 z-[60] bg-white'> {children}</div>
      <Overlay isShown={isOpen} onClick={onClose} />
    </div>,
    document.getElementById('modal') as HTMLElement,
  );
};

export default FullScreenModal;
