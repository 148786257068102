import { useCallback, useState, useMemo } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import history from 'services/history';

export type UsePaginationWithSearchReturnValues = {
  page: number;
  query: string;
  setPage: (page: number) => void;
  handleFilter: (search: string) => void;
};

export const usePaginationWithSearch = (): UsePaginationWithSearchReturnValues => {
  const { search } = useLocation();
  const [query, setQuery] = useState('');

  const { page, searchParams } = useMemo(() => {
    const { page, ...searchParams } = qs.parse(search);

    return { page, searchParams };
  }, [search]);

  const setPage = useCallback(
    (page: number) => {
      history.replace({ pathname: window.location.pathname, search: qs.stringify({ ...searchParams, page }) });
    },
    [searchParams],
  );

  const handleFilter = useCallback(
    (search: string): void => {
      setQuery(search);
      setPage(1);
    },
    [setPage],
  );

  return {
    page: Number(page) || 1,
    query,
    setPage,
    handleFilter,
  };
};
