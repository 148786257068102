import React, { useEffect, useState } from 'react';

import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  DEAL_PARAMETERS_AUTO_NOTIFICATION_OPTIONS,
  DEAL_PARAMETERS_COMMERCIAL_PARAMETERS_FORM_FIELDS,
  DEAL_PARAMETERS_FINISH_MODAL_MESSAGE,
  DEAL_PARAMETERS_REACTIVATE_DEAL_MODAL_TITLE,
} from 'constants/deal-parameters';
import { DealParametersStatuses } from 'constants/deals';
import { ButtonVariants } from 'constants/shared/button';
import useModal from 'hooks/use-modal/useModal';
import { DealParametersFormValues } from 'interfaces';
import { finishDealById, reactivateDealById, updateDealParametersById } from 'modules/deals/action';
import { selectDealParameters } from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AlertModal, Button, LinkButton } from 'shared-components';
import { getCompanyProfileLink } from 'utils';

import DealParametersForm from './DealParametersForm';
import DealParametersSkeleton from './DealParametersSkeleton';

interface Props {
  companyId?: string;
}

const DealParametersTab = ({ companyId }: Props) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const dealParametersData = useAppSelector(selectDealParameters);

  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isAdditionalActionLoading, setIsAdditionalActionLoading] = useState(false);

  const { onOpen: onOpenConfirmFinishModal, ...confirmFinishModalState } = useModal();
  const { onOpen: onOpenReactivateDealModal, ...reactivateDealModalState } = useModal();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DealParametersFormValues>();

  const { fields: sharesFields, append } = useFieldArray({
    control,
    name: 'companySharePrice',
  });

  const {
    fields: nomineeFields,
    append: appendNominee,
    remove,
  } = useFieldArray({
    control,
    name: 'nominees',
  });

  const nomineeRemoveRow = (index: number) => () => remove(index);

  const { legalEntity, companyNumber, plainlyDealId } = dealParametersData || {};

  const isEditable = dealParametersData?.status === DealParametersStatuses.LIVE;

  const handleFinishDeal = () => {
    if (!id) return;

    setIsAdditionalActionLoading(true);
    dispatch(finishDealById(id))
      .unwrap()
      .finally(() => {
        setIsAdditionalActionLoading(false);
        confirmFinishModalState.onClose();
      });
  };

  const handleReactivateDeal = () => {
    if (dealParametersData?.status !== DealParametersStatuses.COMPLETED || !id) {
      return;
    }

    setIsAdditionalActionLoading(true);
    dispatch(reactivateDealById(id))
      .unwrap()
      .finally(() => {
        setIsAdditionalActionLoading(false);
        reactivateDealModalState.onClose();
      });
  };

  const onSubmit: SubmitHandler<DealParametersFormValues> = ({
    closingDates,
    autoNotificationThatSEExpiresSoon,
    companySharePrice,
    nominees,
    daxiaSharePrice,
    feeRate,
    feeMinimum,
  }) => {
    if (id) {
      setIsActionLoading(true);
      dispatch(
        updateDealParametersById({
          closingDates,
          companySharePrice,
          nominees,
          daxiaSharePrice,
          feeRate,
          feeMinimum,
          id,
          autoNotificationThatSEExpiresSoon: autoNotificationThatSEExpiresSoon?.value === 'automatic',
        }),
      ).finally(() => setIsActionLoading(false));
    }
  };

  useEffect(() => {
    if (dealParametersData) {
      const autoNotificationThatSEExpiresSoon = dealParametersData?.autoNotificationThatSEExpiresSoon
        ? DEAL_PARAMETERS_AUTO_NOTIFICATION_OPTIONS[0]
        : DEAL_PARAMETERS_AUTO_NOTIFICATION_OPTIONS[1];

      reset({
        ...dealParametersData,
        companySharePrice: dealParametersData?.companySharePrice || [],
        nominees: dealParametersData?.nominees || [],
        closingDates: dealParametersData?.closingDates || [],
        autoNotificationThatSEExpiresSoon,
      });
    }
  }, [dealParametersData, reset]);

  if (!dealParametersData || dealParametersData?.id !== Number(id)) return <DealParametersSkeleton />;

  return (
    <div>
      <AlertModal
        secondModal
        isLoading={isAdditionalActionLoading}
        onSubmit={handleFinishDeal}
        title={DEAL_PARAMETERS_FINISH_MODAL_MESSAGE}
        backButtonText='No'
        submitButtonText='Yes'
        {...confirmFinishModalState}
      />

      <AlertModal
        secondModal
        isLoading={isAdditionalActionLoading}
        onSubmit={handleReactivateDeal}
        title={DEAL_PARAMETERS_REACTIVATE_DEAL_MODAL_TITLE}
        backButtonText='No'
        submitButtonText='Yes'
        submitButtonVariant={ButtonVariants.PRIMARY}
        backButtonVariant={ButtonVariants.SECONDARY}
        {...reactivateDealModalState}
      />

      <div className='flex justify-end flex-col sm:flex-row gap-4 sm:gap-0 mb-6'>
        {dealParametersData?.status === DealParametersStatuses.COMPLETED ? (
          <Button
            onClick={onOpenReactivateDealModal}
            isLoading={isAdditionalActionLoading}
            disabled={isActionLoading}
            className='w-full sm:w-[200px]'
          >
            Re-activate Deal
          </Button>
        ) : (
          <Button
            onClick={onOpenConfirmFinishModal}
            variant={ButtonVariants.SECONDARY}
            isLoading={isAdditionalActionLoading}
            disabled={isActionLoading}
            className='w-full sm:w-fit'
          >
            Finish Deal
          </Button>
        )}

        <Button
          onClick={handleSubmit(onSubmit)}
          variant={ButtonVariants.SECONDARY}
          className='w-full sm:w-fit sm:ml-6'
          isLoading={isActionLoading}
          disabled={isAdditionalActionLoading}
        >
          Change
        </Button>
      </div>

      <div>
        <DealParametersForm
          errors={errors}
          register={register}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          companyNumber={companyNumber}
          legalEntity={legalEntity}
          sharesFields={sharesFields}
          nomineeFields={nomineeFields}
          plainlyDealId={plainlyDealId}
          commercialParametersFields={DEAL_PARAMETERS_COMMERCIAL_PARAMETERS_FORM_FIELDS}
          formClassName='max-w-800 mx-auto 2xl:ml-48'
          closingDatesAvailable
          isEditable={isEditable}
          nomineeRemoveRow={nomineeRemoveRow}
          sharesNode={
            <Button
              type='button'
              className='ml-5 mt-5 w-fit'
              variant={ButtonVariants.SECONDARY}
              onClick={() => append({ sharePrice: '', classOfShares: '', denomination: '' })}
            >
              + Add New Share
            </Button>
          }
          nomineeNode={
            <Button
              type='button'
              className='ml-5 mt-5 w-fit'
              variant={ButtonVariants.SECONDARY}
              onClick={() => appendNominee({ name: '', primaryContact: '', contactEmail: '', address: '' })}
            >
              + Add New Nominee
            </Button>
          }
        />
        {companyId && (
          <div className='flex justify-end mt-6'>
            <LinkButton
              className='w-fit'
              variant={ButtonVariants.SECONDARY}
              to={getCompanyProfileLink(companyId, true)}
            >
              Company Profile
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default DealParametersTab;
