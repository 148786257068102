import React, { FC } from 'react';

import { TypographyVariants } from 'constants/shared/typography';
import { ChartItem } from 'interfaces';
import Typography from 'shared-components/Typography';

export const CHART_INFO_ITEM_TEST_ID = 'chart-info-item';

export interface Props extends Omit<ChartItem, 'value'> {
  color: string;
  value: number | string;
}

const ChartInfoItem: FC<Props> = ({ name, value, color }) => {
  if (!color) return null;
  return (
    <div className='flex flex-col justify-start' data-testid={CHART_INFO_ITEM_TEST_ID}>
      <Typography variant={TypographyVariants.BODY_SMALL}>{name}</Typography>
      <div className='flex items-end grow mt-3'>
        <div className='flex items-start'>
          <div style={{ backgroundColor: color }} className='min-w-[16px] w-4 h-4 mr-1 mt-1 rounded-full' />
          <Typography className='text-grey-800'> {typeof value === 'number' ? `+${value}%` : value}</Typography>
        </div>
      </div>
    </div>
  );
};

export default ChartInfoItem;
