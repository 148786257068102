import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useMemo } from 'react';

import cn from 'classnames';
import {
  FieldError,
  RegisterOptions,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  Path,
  PathValue,
  UseFormTrigger,
  FieldValues,
} from 'react-hook-form';

import {
  ADD_SIGNIFICATION_CONTROL_FROM_AND_TO_FIELDS,
  SignificationControlPersonFieldNames,
} from 'constants/due-diligence';
import { REQUIRED_MESSAGE } from 'constants/global';
import { TABLE_FORM_INPUT_ERROR_CLASSNAME } from 'constants/shared';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { FormItem, FromItemHigligtedColor, SignificantControlPersonAddressInput } from 'interfaces';
import { Checkbox, FormContent, Input } from 'shared-components';
import { TableData } from 'shared-components/table';

interface Props<T extends FieldValues> {
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  trigger: UseFormTrigger<T>;
  addresses: SignificantControlPersonAddressInput[];
  errors: FieldErrors<T>;
  index: number;
  addressIndex?: number;
  additionalColumn?: ReactNode;
  name: string;
  tableDataClassName?: string;
  bypassValidation?: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
  personId: number;
}

const SignificantControlAddressFormContent = <T extends FieldValues>({
  setValue,
  register,
  trigger,
  errors,
  index,
  additionalColumn,
  addressIndex = 0,
  addresses = [] as SignificantControlPersonAddressInput[],
  name,
  tableDataClassName = '',
  bypassValidation,
  getUnfieldValue,
  personId,
}: PropsWithChildren<Props<T>>): ReactElement => {
  const baseAddressName = `${name}.${index}.residentialAddresses.${addressIndex}`;
  const registerAddressName = `${baseAddressName}.${SignificationControlPersonFieldNames.RESIDENTIAL_ADDRESS}`;
  const tillNowCheckbox = `${baseAddressName}.${SignificationControlPersonFieldNames.TILL_NOW}`;

  const tillNowChecked = addresses[addressIndex]?.tillNow || false;

  const localErrors = (errors as any)?.[name]?.[index]?.residentialAddresses?.[addressIndex] || {};
  const toAddressError = (localErrors as Record<string, FieldError>)[SignificationControlPersonFieldNames.TO]?.message;
  const fromAddressError = (localErrors as Record<string, FieldError>)[SignificationControlPersonFieldNames.FROM];

  const residentialAddressError = (localErrors as Record<string, FieldError>)[
    SignificationControlPersonFieldNames.RESIDENTIAL_ADDRESS
  ]?.message;

  const validateAddressInput = (value: string) => {
    if (bypassValidation) {
      return true;
    }

    if (!value) {
      return REQUIRED_MESSAGE;
    }

    if (value?.length > 250) {
      return 'Must not be greater than 250 characters.';
    }

    return true;
  };

  const onChangeTillNowCheckbox = () => {
    if (!addresses) {
      return;
    }

    if (tillNowChecked) {
      setValue(tillNowCheckbox as Path<T>, false as PathValue<T, Path<T>>, { shouldDirty: true });
      return;
    }

    const selectedTillNowAddressIndex = addresses.findIndex((address) => address.tillNow);

    if (selectedTillNowAddressIndex !== -1) {
      setValue(
        `${name}.${index}.residentialAddresses.${selectedTillNowAddressIndex}.tillNow` as Path<T>,
        false as PathValue<T, Path<T>>,
      );
    }

    setValue(tillNowCheckbox as Path<T>, true as PathValue<T, Path<T>>, { shouldDirty: true });
    setValue(`${baseAddressName}.${SignificationControlPersonFieldNames.TO}` as Path<T>, '' as PathValue<T, Path<T>>);
  };

  useEffect(() => {
    // Since we use tillNowChecked as validation parameter
    // its not an option to use trigger inside onChangeTillNowCheckbox function
    // In this case we have useEffect and if tillNowChecked changes we trigger validation
    // When we add new address tillNowChecked is false, so no need to trigger validation
    if (tillNowChecked) {
      trigger(`${baseAddressName}.${SignificationControlPersonFieldNames.TO}` as Path<T>);
    }
  }, [tillNowChecked, trigger, baseAddressName]);

  const formFields = useMemo(() => {
    let fields = ADD_SIGNIFICATION_CONTROL_FROM_AND_TO_FIELDS(
      `residentialAddresses.${addressIndex}.`,
      addresses,
      tillNowChecked,
      bypassValidation,
    );

    if (getUnfieldValue) {
      fields = fields.map((field): FormItem => {
        let unfieldValue = getUnfieldValue(
          `${name}.${personId}.residentialAddresses.${addresses[addressIndex].id}.${field.pureName}`,
        );
        if (!unfieldValue) {
          unfieldValue = getUnfieldValue(`${name}.${personId}.residentialAddresses`);
        }
        return {
          ...field,
          highlighted: !!unfieldValue,
          highlightedDescription: unfieldValue?.description,
          highlightedColor: unfieldValue?.asError ? FromItemHigligtedColor.Red : undefined,
        };
      });
    }

    return fields;
  }, [name, addresses, addressIndex, tillNowChecked, bypassValidation, getUnfieldValue]);

  const addressHighlighted = useMemo(() => {
    let result = null;
    if (getUnfieldValue && !residentialAddressError) {
      let unfieldValue = getUnfieldValue(
        `${name}.${personId}.residentialAddresses.${addresses[addressIndex].id}.${SignificationControlPersonFieldNames.RESIDENTIAL_ADDRESS}`,
      );
      if (!unfieldValue) {
        unfieldValue = getUnfieldValue(`${name}.${personId}.residentialAddresses`);
      }
      if (unfieldValue) {
        result = unfieldValue;
      }
    }
    return result;
  }, [name, addresses, addressIndex, getUnfieldValue, residentialAddressError]);

  return (
    <>
      <TableData
        className={
          residentialAddressError ? cn(TABLE_FORM_INPUT_ERROR_CLASSNAME, tableDataClassName) : tableDataClassName
        }
      >
        <Input
          {...register(
            registerAddressName as Path<T>,
            {
              validate: validateAddressInput,
            } as RegisterOptions<T>,
          )}
          error={residentialAddressError}
          placeholder='Residential address'
          className='mb-0'
          highlighted={!!addressHighlighted}
          highlightedDescription={addressHighlighted?.description}
          highlightedColor={addressHighlighted?.asError ? FromItemHigligtedColor.Red : undefined}
        />
      </TableData>

      <TableData
        className={
          fromAddressError || toAddressError
            ? cn(TABLE_FORM_INPUT_ERROR_CLASSNAME, tableDataClassName)
            : cn(tableDataClassName)
        }
      >
        <div className='flex items-baseline'>
          <FormContent fields={formFields} register={register} errors={localErrors} group={`${name}.${index}`} />
        </div>

        <Checkbox
          onChange={onChangeTillNowCheckbox}
          checked={tillNowChecked}
          value='tillNow'
          className='pt-2'
          inputClassName='top-0.5'
          label='Current Address'
        />
      </TableData>
      {additionalColumn}
    </>
  );
};

export default SignificantControlAddressFormContent;
