import React, { FC } from 'react';

import {
  MY_INVESTMENT_SHARE_EXCHANGE_TITLE,
  MY_INVESTMENTS_SUCCESS_SHARE_EXCHANGE_REQUEST_MODAL_DESCRIPTION,
} from 'constants/my-investments';
import { ButtonVariants } from 'constants/shared/button';
import { UseModalReturnValues } from 'hooks/use-modal/useModal';
import { Button, ModalWindow } from 'shared-components';

type Props = Omit<UseModalReturnValues, 'onOpen' | 'modalProps'>;

const SuccessShareExchangeRequestModal: FC<Props> = (modalProps) => {
  return (
    <ModalWindow
      title={MY_INVESTMENT_SHARE_EXCHANGE_TITLE}
      description={MY_INVESTMENTS_SUCCESS_SHARE_EXCHANGE_REQUEST_MODAL_DESCRIPTION}
      className='lg:w-8/12 xl:w-6/12 lg:h-12/12'
      {...modalProps}
    >
      <Button variant={ButtonVariants.SECONDARY} onClick={modalProps.onClose} className='mt-10'>
        Close
      </Button>
    </ModalWindow>
  );
};

export default SuccessShareExchangeRequestModal;
