import React, { FC } from 'react';

import { CANCEL_SHARE_EXCHANGE_MODAL_TITLE, CANCEL_SHARE_EXCHANGE_MODAL_DESCRIPTION } from 'constants/share-exchange';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { AlertModal } from 'shared-components';
import { getTitleWithReplacedYourWord } from 'utils';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onCancelShareExchange: VoidFunction;
};

const ShareExchangeCancelQualificationQuestionsModal: FC<Props> = ({
  isOpen,
  onClose,
  isLoading,
  onCancelShareExchange,
}) => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onCancelShareExchange}
      isLoading={isLoading}
      submitButtonText='Yes'
      backButtonText='No'
      title={CANCEL_SHARE_EXCHANGE_MODAL_TITLE}
      description={getTitleWithReplacedYourWord(CANCEL_SHARE_EXCHANGE_MODAL_DESCRIPTION, entityName, isTypeEntity)}
    />
  );
};

export default ShareExchangeCancelQualificationQuestionsModal;
