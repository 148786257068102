import React, { ChangeEvent, FC, useEffect } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as ChevronLeftIcon } from 'assets/svg/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/svg/chevron-right.svg';
import { INTEGER_PATTERN } from 'constants/global';
import { TEXT_INPUT_CLASSNAMES } from 'constants/shared';
import { TypographyVariants } from 'constants/shared/typography';
import Typography from 'shared-components/Typography';

import PaginationSkeleton from './PaginationSkeleton';

export const PAGINATION_LOADING_SKELETON_TEST_ID = 'pagination-loading-skeleton';
export const PAGINATION_INFO_TEST_ID = 'pagination-pages-info-test-id';

export type Props = {
  currentPage: number;
  updatePage: (page: number) => void;
  totalPages?: number;
  total?: number;
  className?: string;
  loading?: boolean;
};

type PageInput = {
  page: string;
};

const Pagination: FC<Props> = ({
  loading = false,
  className,
  currentPage,
  totalPages = 1,
  total: totalRecords,
  updatePage,
}) => {
  const { handleSubmit, setValue, watch } = useForm<PageInput>();
  const watchPageValue = watch('page');

  const onSubmit: SubmitHandler<PageInput> = ({ page }) => {
    const pageNumber = Number(page);

    if (pageNumber === 0 || pageNumber === currentPage) return;
    updatePage(Number(page));
  };

  const onChangePageNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const pageNumber = e.target.value.replace(INTEGER_PATTERN, '');

    if (Number(pageNumber) > totalPages) {
      setValue('page', String(currentPage));
      return;
    }

    setValue('page', pageNumber);
  };

  const onClickIncreasePage = () => {
    if (currentPage >= totalPages) {
      return;
    }

    updatePage(currentPage + 1);
  };

  const onClickDecreasePage = () => {
    if (currentPage === 1) {
      return;
    }

    updatePage(currentPage - 1);
  };

  useEffect(() => {
    setValue('page', String(currentPage));
  }, [currentPage, setValue]);

  if (loading) {
    return <PaginationSkeleton className={className} />;
  }

  return (
    <div
      className={twMerge(
        cn(
          'flex justify-between mt-9',
          {
            'justify-end': !totalRecords,
          },
          className,
        ),
      )}
    >
      {totalRecords ? (
        <Typography variant={TypographyVariants.BODY_SMALL}>
          Total records: <b className='text-grey-800 font-bold'> {totalRecords}</b>
        </Typography>
      ) : null}

      {totalPages > 1 && (
        <div className='flex items-center'>
          <div className='w-fit flex justify-around text-blue-800'>
            {currentPage > 1 && (
              <>
                <button className='flex items-center' onClick={() => updatePage(1)}>
                  <ChevronLeftIcon />
                  <ChevronLeftIcon />
                </button>
                <button className='mx-4' onClick={onClickDecreasePage}>
                  <ChevronLeftIcon />
                </button>
              </>
            )}
          </div>
          <Typography className='mr-2' variant={TypographyVariants.BODY_SMALL} tag='span'>
            Page
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              size={Math.min(Math.max(String(watchPageValue).length, 1), 20)}
              className={cn(TEXT_INPUT_CLASSNAMES.default, 'py-0.5 px-1 text-sm font-bold text-center leading-4')}
              onChange={onChangePageNumber}
              value={watchPageValue}
            />
          </form>
          <Typography className='ml-2' variant={TypographyVariants.BODY_SMALL} tag='span'>
            of <span className='text-grey-800 font-bold'>{totalPages}</span>
          </Typography>
          <div className='w-fit flex justify-around text-blue-800'>
            {currentPage !== totalPages && (
              <>
                <button className='mx-4' onClick={onClickIncreasePage}>
                  <ChevronRightIcon />
                </button>
                <button className='flex items-center' onClick={() => updatePage(totalPages)}>
                  <ChevronRightIcon />
                  <ChevronRightIcon />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
