import React, { FC, useState } from 'react';

import { LocalStorageKeys } from 'constants/global';
import { ButtonVariants } from 'constants/shared/button';
import { selectSelfId } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { Button, Checkbox } from 'shared-components';
import { addUserIdToWelcomeModalUsersIds, removeIdFromWelcomeModalUsersIds } from 'utils/investments';

type Props = {
  onClose: VoidFunction;
};

const WelcomeInvestorModalActions: FC<Props> = ({ onClose }) => {
  const [isChecked, setIsChecked] = useState(false);

  const selfId = useAppSelector(selectSelfId);

  const onChangeDontShowMessageAgain = () => {
    setIsChecked((prevState) => {
      if (!prevState) {
        addUserIdToWelcomeModalUsersIds(selfId);
      } else {
        removeIdFromWelcomeModalUsersIds(selfId);
      }

      return !prevState;
    });
  };

  const handleSkip = () => {
    removeIdFromWelcomeModalUsersIds(selfId);
    localStorage.setItem(LocalStorageKeys.HIDE_INVESTOR_WELCOME_MODAL, 'true');
    onClose();
  };

  return (
    <div className='flex items-center justify-between flex-col sm:flex-row gap-4 sm:gap-0'>
      <Checkbox
        checked={isChecked}
        onChange={onChangeDontShowMessageAgain}
        label='Don’t show this message again'
        className='self-start'
      />

      <Button className='w-full sm:w-auto' onClick={handleSkip} variant={ButtonVariants.SECONDARY}>
        Skip for now
      </Button>
    </div>
  );
};

export default WelcomeInvestorModalActions;
