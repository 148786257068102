import { AxiosRequestConfig } from 'axios';
import qs from 'query-string';

import { httpApiClient } from 'configs/http-client';
import { MetaPayload, NotificationResponse, NotificationsResponse } from 'interfaces';

export class NotificationsApi {
  constructor(private url: string) {}

  getNotifications({ page = 1, per_page = 5, query, ...rest }: MetaPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<NotificationsResponse>(
      `${this.url}?${qs.stringify({ page, per_page, q: query, ...rest })}`,
      config,
    );
  }
  updateUserNotification(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.patch<NotificationResponse>(`${this.url}/${id}`, config);
  }
}

export const notificationsApi = new NotificationsApi('/users/notifications');
