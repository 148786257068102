import React, { Dispatch, FC, SetStateAction } from 'react';

import { PayBillModalTypes } from 'constants/my-bills';
import { Bill } from 'interfaces';

import PayBillModal from './PayBillModal';
import PayBillProcessingModal from './PayBillProcessingModal';
import PayBillSuccessModal from './PayBillSuccessModal';
import PayBillUnsuccessModal from './PayBillUnsuccessModal';

type Props = {
  modalType: PayBillModalTypes | null;
  onClose: VoidFunction;
  bill: Bill | null;
  setModalType: Dispatch<SetStateAction<PayBillModalTypes | null>>;
  isOpen: boolean;
};

const PayBillModalsContainer: FC<Props> = ({ modalType, ...props }) => {
  if (modalType === PayBillModalTypes.PAY_BILL) {
    return <PayBillModal {...props} />;
  }

  if (modalType === PayBillModalTypes.PAY_SUCCESS) {
    return <PayBillSuccessModal {...props} />;
  }

  if (modalType === PayBillModalTypes.PAY_FAILED) {
    return <PayBillUnsuccessModal {...props} />;
  }

  if (modalType === PayBillModalTypes.PAY_PENDING) {
    return <PayBillProcessingModal {...props} />;
  }

  return null;
};

export default PayBillModalsContainer;
