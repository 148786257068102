import React from 'react';

import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { getTitleWithReplacedYourWord } from 'utils';

const DealDetailsReadyToCompleteTooltipContent = () => {
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  return (
    <p>
      Thank you for completing the planD process, there is nothing further for you to action.
      <br />
      {getTitleWithReplacedYourWord(
        'Upon deal closure, we will finalise the transaction and will be able to issue your investment confirmation letter',
        entityName,
        isTypeEntity,
      )}
    </p>
  );
};

export default DealDetailsReadyToCompleteTooltipContent;
