import React, { FC, memo, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { ReactComponent as SignOutSvg } from 'assets/svg/arrow-left.svg';
import { LocalStorageKeys } from 'constants/global';
import {
  ROUTES,
  SIDEBAR_ADDITIONAL_LINKS,
  SIDEBAR_ADMIN_LINKS,
  SIDEBAR_BUSINESS_SETTINGS_LINK,
  SIDEBAR_FAQ_LINK,
  SIDEBAR_FM_USERS_LINKS,
  SIDEBAR_SETTING_LINK,
  SIDEBAR_USER_LINKS,
} from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { UserTypes } from 'constants/user';
import { LinkItem } from 'interfaces';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { NavigationItem } from 'page-components';
import { Button, LinkWithIcon } from 'shared-components';
import { checkIsAdminOrFmUser, navigateByRole } from 'utils';

import SidebarPrivacyPolicy from './SidebarPrivacyPolicy';

const NAVIGATION_BUTTON_CLASS_NAME =
  'py-4 px-6 justify-start text-white stroke-violet-600 hover:bg-violet-800 hover:[&>a]:stroke-violet-600';

interface Props {
  onSignOut: VoidFunction;
}

const renderNavigationItems = (links: LinkItem[], pathname: string) =>
  links?.map((link) => {
    const path = link?.target ? link.path : navigateByRole(checkIsAdminOrFmUser(), link.path);
    return (
      <NavigationItem key={link.title} isSelected={path === pathname}>
        <LinkWithIcon {...link} path={path} />
      </NavigationItem>
    );
  });

const NavigationMenu: FC<Props> = ({ onSignOut }) => {
  const { pathname } = useLocation();

  const { isTypeEntity } = useAppSelector(selectUserEntityData);

  const userType = localStorage.getItem(LocalStorageKeys.USER_TYPE) as UserTypes;

  const navigationLinks = useMemo(() => {
    switch (userType) {
      case UserTypes.ADMIN:
        return SIDEBAR_ADMIN_LINKS(isTypeEntity);
      case UserTypes.FM_USER:
        return SIDEBAR_FM_USERS_LINKS;
      default:
        return SIDEBAR_USER_LINKS(userType, isTypeEntity);
    }
  }, [userType, isTypeEntity]);

  return (
    <nav className='h-full flex flex-col justify-between h-[calc(100vh-104px)] overflow-y-auto	'>
      <div>{renderNavigationItems(navigationLinks, pathname)}</div>

      <div className='my-12'>{renderNavigationItems(SIDEBAR_ADDITIONAL_LINKS, pathname)}</div>

      <div className='mb-2 sm:mb-24'>
        {userType === UserTypes.ADMIN ? (
          <NavigationItem isSelected={ROUTES.businessSettings === pathname}>
            <LinkWithIcon {...SIDEBAR_BUSINESS_SETTINGS_LINK} />
          </NavigationItem>
        ) : (
          ''
        )}
        {userType === UserTypes.FOUNDER || userType === UserTypes.INVESTOR ? (
          <>
            {renderNavigationItems([SIDEBAR_FAQ_LINK], pathname)}
            <NavigationItem isSelected={ROUTES.settings === pathname}>
              <LinkWithIcon {...SIDEBAR_SETTING_LINK} isTypeEntity={isTypeEntity} />
            </NavigationItem>
          </>
        ) : (
          ''
        )}
        <Button onClick={onSignOut} variant={ButtonVariants.TRANSPARENT} className={NAVIGATION_BUTTON_CLASS_NAME}>
          <SignOutSvg className='mr-4 [&>path]:fill-violet-600' />
          Sign Out
        </Button>
        {!checkIsAdminOrFmUser() && <SidebarPrivacyPolicy />}
      </div>
    </nav>
  );
};

export default memo(NavigationMenu);
