import { useState } from 'react';

import { GRAPHS_RANGES_DROPDOWN } from 'constants/dashboard';
import { SelectOption } from 'interfaces';

const useGraphRanges = () => {
  const [selectedRange, setSelectedRange] = useState(GRAPHS_RANGES_DROPDOWN[0]);

  const onChangeRange = (range: SelectOption | null) => {
    if (!range) {
      return;
    }

    setSelectedRange(range);
  };

  return { selectedRange, onChangeRange };
};

export default useGraphRanges;
