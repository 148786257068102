import { useMemo } from 'react';

import { InvestmentProposalBlockNames } from 'constants/company-iac';
import { COMPANY_IAC_STATUSES } from 'interfaces';
import { removeCompanyIacEditingSections, setCompanyIacEditingSections } from 'modules/company-iac/action';
import { selectCompanyIacEditingSections, selectCompanyIacStatus } from 'modules/company-iac/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { checkIsFmUser } from 'utils';

export interface UseCompanyIacEditReturnValues {
  handleEdit: VoidFunction;
  handleCancel: VoidFunction;
  isEditing: boolean;
  isHideActionButtons: boolean;
}

const useCompanyIacEdit = (sectionKey: InvestmentProposalBlockNames): UseCompanyIacEditReturnValues => {
  const dispatch = useAppDispatch();
  const companyIacStatus = useAppSelector(selectCompanyIacStatus);

  const editingSections = useAppSelector(selectCompanyIacEditingSections);

  const isFMUser = useMemo(() => checkIsFmUser(), []);

  const checkStateForEdit =
    isFMUser ||
    companyIacStatus === COMPANY_IAC_STATUSES.STOPPED ||
    companyIacStatus === COMPANY_IAC_STATUSES.APPROVED_BY_FMUSERS ||
    companyIacStatus === COMPANY_IAC_STATUSES.ARCHIVED;

  const isHideActionButtons = sectionKey === InvestmentProposalBlockNames.OVERVIEW ? checkStateForEdit : true;

  const isEditing = useMemo(() => {
    if (sectionKey !== InvestmentProposalBlockNames.OVERVIEW) {
      return checkStateForEdit;
    }

    return editingSections.includes(sectionKey);
  }, [checkStateForEdit, editingSections, sectionKey]);

  const handleEdit = () => {
    dispatch(setCompanyIacEditingSections(sectionKey));
  };

  const handleCancel = () => {
    dispatch(removeCompanyIacEditingSections(sectionKey));
  };

  return {
    handleEdit,
    handleCancel,
    isEditing,
    isHideActionButtons,
  };
};

export default useCompanyIacEdit;
