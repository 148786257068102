import React, { FC, ReactElement, ReactNode } from 'react';

import Collapsible from 'react-collapsible';

interface Props {
  children: ReactNode;
  trigger: ReactElement;
}

const UserPermissionsCategory: FC<Props> = ({ children, trigger }) => {
  return (
    <Collapsible trigger={trigger} className='min-w-400'>
      <ul>{children}</ul>
    </Collapsible>
  );
};

export default UserPermissionsCategory;
