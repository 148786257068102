import React from 'react';

import { TableData, TableRow } from 'shared-components/table';

const NoDealsAdded = () => {
  return (
    <TableRow>
      <TableData colSpan={5}>No information is available as no Share Exchanges have been added</TableData>
    </TableRow>
  );
};

export default NoDealsAdded;
