import React from 'react';

const TooltipV2 = ({ payload }: any) => {
  if (!payload?.length) return null;

  const {
    payload: { name = '' },
    value = '',
  } = payload[0];

  return (
    <div className='relative w-[130px] h-10 flex flex-col items-center justify-center bg-lightGreen-800 text-white text-xs'>
      <p>{name}</p>
      <p>{value}</p>
      <div className='w-0 h-0 absolute -bottom-1.5 border-t-[6px] border-t-lightGreen-800 border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent' />
    </div>
  );
};

export default TooltipV2;
