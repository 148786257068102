import { createSelector } from '@reduxjs/toolkit';

import { FeeStatuses } from 'constants/fees';
import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.feesReducer;

export const selectFeesListData = createSelector(selectState, ({ fees, total, totalPages, isLoading }) => ({
  fees,
  total,
  totalPages,
  isLoading,
}));

export const selectActiveFee = createSelector(selectState, ({ fees }) =>
  fees.find((fee) => fee?.status === FeeStatuses.ACTIVE),
);
