import React, { PropsWithChildren, ReactElement } from 'react';

import cn from 'classnames';
import { get } from 'lodash';
import { Control, FieldError, FieldErrors, FieldValues, Path, UseFormRegister } from 'react-hook-form';

import { FORM_INPUTS_WITHOUT_REFS, TABLE_FORM_INPUT_ERROR_CLASSNAME } from 'constants/shared';
import { FormItem } from 'interfaces';

import TableData from './table/TableData';

export interface Props<T extends FieldValues> {
  fields: FormItem[];
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  isDisabled?: boolean;
  control?: Control<T>;
  anchorPrefix?: string;
  isTableView?: boolean;
  group?: string | number;
  disabledFilledField?: boolean;
  className?: string;
  tableDataClassName?: string;
  onBlur?: (name: keyof T) => void;
  onChange?: (name: keyof T) => void;
}

const FormContent = <T extends FieldValues>({
  fields,
  register,
  errors,
  isDisabled,
  control,
  anchorPrefix,
  isTableView,
  group,
  disabledFilledField,
  className,
  onBlur,
  onChange,
  tableDataClassName = '',
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  if (!fields?.length) return null;

  return (
    <>
      {fields?.map(
        ({ component: Component, pureName, validation, colSpan, className: componentClassName, ...props }) => {
          const error = get(errors as Record<string, FieldError>, pureName || props.name)?.message;

          const name = group || group === 0 ? `${group}.${props.name}` : props.name;

          const isDisabledByFilledCondition = disabledFilledField && control?._formValues[name];

          const { ref, ...registerProps } = register(name as Path<T>, {
            ...validation,
            onChange: props.onChange ? props.onChange : () => onChange && onChange(name as keyof T),
            onBlur: props.onBlur ? props.onBlur : () => onBlur && onBlur(name as keyof T),
          });

          const componentProps = {
            disabled: isDisabled || isDisabledByFilledCondition,
            error,
            control,
            anchorPrefix,
            className: cn(componentClassName, className),
            ref: props.type && FORM_INPUTS_WITHOUT_REFS.includes(props.type) ? undefined : ref,
            ...props,
            ...registerProps,
          };

          if (isTableView)
            return (
              <TableData
                colSpan={colSpan}
                key={props.name}
                className={
                  error || props.highlighted
                    ? cn(TABLE_FORM_INPUT_ERROR_CLASSNAME, tableDataClassName)
                    : tableDataClassName
                }
              >
                <Component {...componentProps} />
              </TableData>
            );

          return <Component key={props.name} {...componentProps} />;
        },
      )}
    </>
  );
};

export default FormContent;
