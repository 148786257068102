import { AxiosRequestConfig } from 'axios';

import { SignatureResponse } from 'interfaces/signatures.interfaces';

import { httpApiClient } from '../configs/http-client';
class SignaturesApi {
  constructor(private url: string) {}

  updateSignature(data: FormData, config?: AxiosRequestConfig) {
    return httpApiClient.post<SignatureResponse>(this.url, data, config);
  }

  getSignatureFromUrl(url: string, config?: AxiosRequestConfig) {
    return httpApiClient.get(`${this.url}${url}`, { responseType: 'arraybuffer', ...config });
  }
}
export const adminSignaturesApis = new SignaturesApi('/signatures');
export const signaturesUrlApis = new SignaturesApi('');
