import React, { FC } from 'react';

import { ButtonVariants } from 'constants/shared/button';
import { Button } from 'shared-components';

type Props = {
  isLoading: boolean;
  onCancel: VoidFunction;
};

const AddInvestmentFormButtons: FC<Props> = ({ onCancel, isLoading }) => {
  return (
    <div className='flex justify-between space-x-6'>
      <Button variant={ButtonVariants.PRIMARY} isLoading={isLoading}>
        Save Changes
      </Button>
      <Button variant={ButtonVariants.SECONDARY} onClick={onCancel} type='button'>
        Cancel
      </Button>
    </div>
  );
};

export default AddInvestmentFormButtons;
