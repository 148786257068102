import React from 'react';

import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  RESET_PASSWORD_PAGE_ADDITIONAL_TEXT,
  RESET_PASSWORD_PAGE_TITLE,
  SET_PASSWORD_AUTH_LAYOUT_CHILDREN_CLASSNAMES,
} from 'constants/reset-password';
import { SetPasswordInput } from 'interfaces';
import { asyncResetPassword } from 'modules/auth/action';
import { selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuthLayout, HeaderInfo, NeedHelpLink, SetPasswordForm } from 'page-components';
import { getTitleWithReplacedYourWord } from 'utils';

const SetPassword = () => {
  const dispatch = useAppDispatch();
  const { token, email } = useParams();

  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);

  const onSubmit: SubmitHandler<SetPasswordInput> = (data) => {
    if (!token || !email) {
      return;
    }
    dispatch(asyncResetPassword({ ...data, email, token }));
  };

  return (
    <AuthLayout childrenClassName={SET_PASSWORD_AUTH_LAYOUT_CHILDREN_CLASSNAMES}>
      <HeaderInfo title={getTitleWithReplacedYourWord(RESET_PASSWORD_PAGE_TITLE, entityName, isTypeEntity)}>
        {getTitleWithReplacedYourWord(RESET_PASSWORD_PAGE_ADDITIONAL_TEXT, entityName, isTypeEntity)}
      </HeaderInfo>

      <SetPasswordForm onSubmit={onSubmit} />

      <NeedHelpLink />
    </AuthLayout>
  );
};

export default SetPassword;
