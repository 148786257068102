import React, { useEffect, useMemo } from 'react';

import { endOfDay, isBefore } from 'date-fns';
import {
  FieldArrayWithId,
  Path,
  UseFormWatch,
  FieldArrayPath,
  UseFormSetValue,
  PathValue,
  UseFieldArrayRemove,
  UseFormRegister,
  Control,
  FieldErrors,
  UseFieldArrayAppend,
} from 'react-hook-form';

import { StartShareExchangeFieldNames } from 'constants/share-exchange';
import {
  DealPredefinedData,
  FormItem,
  Option,
  StartShareExchangesInvestmentItem,
  StartShareExchangesInvestmentsFormValues,
  StartShareExchangeWatchValues,
} from 'interfaces';
import { FormContent, SnippetLayout } from 'shared-components';
import { calculateNoOfExchangesShares } from 'utils/investments';

import StartInvestmentShareExchangeAddNewRow from '../../my-investments/start-investment-share-exchange-modal/StartInvestmentShareExchangeAddNewRow';
import StartInvestmentShareExchangeModalTableItem from '../../my-investments/start-investment-share-exchange-modal/StartInvestmentShareExchangeModalTableItem';
import StartInvestmentTotalShareExchangeList from '../../my-investments/start-investment-share-exchange-modal/StartInvestmentTotalShareExchangeList';

interface Props<
  TFieldValues extends { exchanges: StartShareExchangesInvestmentItem[] },
  TFieldArrayName extends FieldArrayPath<StartShareExchangesInvestmentItem>,
> {
  getFields: (
    watchValues: StartShareExchangeWatchValues,
    handleSetCompanyPrice: VoidFunction,
    classOfSharesOptions: Option[],
    closingDatesOptions: Option[],
    dealIndex: number,
  ) => FormItem[];
  dealsFields: FieldArrayWithId<TFieldValues, TFieldArrayName, 'id'>[];
  shareExchanges: StartShareExchangesInvestmentItem[];
  companyDealPredefinedData: DealPredefinedData | null;
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  errors: FieldErrors<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  append: UseFieldArrayAppend<StartShareExchangesInvestmentsFormValues>;
  remove: UseFieldArrayRemove;
  isFormDisabled?: boolean;
}

const ShareExchangeDetailsCommonFields = <
  TFieldValues extends { exchanges: StartShareExchangesInvestmentItem[] },
  TFieldArrayName extends FieldArrayPath<StartShareExchangesInvestmentItem>,
>({
  getFields,
  dealsFields,
  shareExchanges,
  companyDealPredefinedData,
  register,
  control,
  watch,
  errors,
  setValue,
  append,
  remove,
  isFormDisabled,
}: Props<TFieldValues, TFieldArrayName>) => {
  const classOfSharesOptions: Option[] = useMemo(() => {
    return (
      companyDealPredefinedData?.companySharePrice?.map(({ classOfShares }) => ({
        label: classOfShares,
        value: classOfShares,
      })) || []
    );
  }, [companyDealPredefinedData?.companySharePrice]);

  const closingDatesOptions: Option[] = useMemo(() => {
    return (
      companyDealPredefinedData?.closingDatesDropDown?.map(({ closingDate }) => ({
        label: closingDate,
        value: closingDate,
        isDisabled: isBefore(endOfDay(new Date(closingDate)), endOfDay(new Date())),
      })) || []
    );
  }, [companyDealPredefinedData?.closingDatesDropDown]);

  const appendNewInvestmentsRow = () => {
    const [{ companyPrice }] = companyDealPredefinedData?.companySharePrice || [];

    const { value: classOfShareValue } = classOfSharesOptions[0];
    const { value: closingDateValue } = closingDatesOptions[0];

    append({
      classOfShares: classOfSharesOptions?.length === 1 ? { label: classOfShareValue, value: classOfShareValue } : null,
      companyPrice: companyPrice || '0',
      fundSharePrice: companyDealPredefinedData?.fundSharePrice || '',
      noExchangedShares: '',
      noOfShares: '',
      closingDate: closingDatesOptions?.length === 1 ? { label: closingDateValue, value: closingDateValue } : null,
    });
  };

  useEffect(() => {
    const firstExchangePrefix = `exchanges.0`;

    if (classOfSharesOptions?.length === 1) {
      const { value } = classOfSharesOptions[0];
      const optionToSet: Option = { label: value, value };

      setValue(
        `${firstExchangePrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}` as Path<TFieldValues>,
        optionToSet as PathValue<TFieldValues, Path<TFieldValues>>,
      );
    }

    if (closingDatesOptions?.length === 1) {
      const { value } = closingDatesOptions[0];
      const optionToSet: Option = { label: value, value };

      setValue(
        `${firstExchangePrefix}.${StartShareExchangeFieldNames.CLOSING_DATE}` as Path<TFieldValues>,
        optionToSet as PathValue<TFieldValues, Path<TFieldValues>>,
      );
    }
  }, [setValue, shareExchanges]);

  return (
    <>
      <SnippetLayout
        title={companyDealPredefinedData?.companyName}
        description='Please confirm the class of shares and number of shares you’d like to exchange'
        className='mb-10 mt-6 [&>div_p]:pb-2'
        childrenClassName='mt-0'
      >
        <div className='flex flex-col gap-2'>
          {dealsFields?.map((deal, dealIndex) => {
            const dealsPrefix: `exchanges.${number}` = `exchanges.${dealIndex}`;

            const noOfShares = watch(
              `${dealsPrefix}.${StartShareExchangeFieldNames.NO_OF_SHARES}` as Path<TFieldValues>,
            );
            const fundSharePrice = watch(
              `${dealsPrefix}.${StartShareExchangeFieldNames.DAXIA_PRICE}` as Path<TFieldValues>,
            );

            const watchValues = {
              noOfShares,
              fundSharePrice,
              companyPrice:
                watch(`${dealsPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}` as Path<TFieldValues>) || '0',
            };

            const watchClassOfShares = watch(
              `${dealsPrefix}.${StartShareExchangeFieldNames.CLASS_OF_SHARES}` as Path<TFieldValues>,
            ) as Option;

            const handleSetCompanyPrice = () => {
              const { companyPrice } =
                companyDealPredefinedData?.companySharePrice.find(
                  ({ classOfShares }) => classOfShares === watchClassOfShares?.value,
                ) || {};

              if (companyPrice) {
                setValue(
                  `${dealsPrefix}.${StartShareExchangeFieldNames.COMPANY_PRICE}` as Path<TFieldValues>,
                  companyPrice as PathValue<TFieldValues, Path<TFieldValues>>,
                );
                setValue(
                  `${dealsPrefix}.${StartShareExchangeFieldNames.NO_OF_EXCHANGED_SHARES}` as Path<TFieldValues>,
                  calculateNoOfExchangesShares({
                    companyPrice,
                    noOfShares: `${noOfShares}`,
                    fundSharePrice: `${fundSharePrice}`,
                  }) as PathValue<TFieldValues, Path<TFieldValues>>,
                );
              }
            };

            return (
              <StartInvestmentShareExchangeModalTableItem
                key={deal.id}
                isOnlyOne={dealsFields?.length === 1}
                isLast={dealIndex === dealsFields?.length - 1}
                handleRemoveRow={() => remove(dealIndex)}
                watchClassOfSharesValue={watchClassOfShares?.value}
                handleSetCompanyPrice={handleSetCompanyPrice}
              >
                <FormContent
                  isDisabled={isFormDisabled}
                  fields={getFields(
                    watchValues as StartShareExchangeWatchValues,
                    handleSetCompanyPrice,
                    classOfSharesOptions,
                    closingDatesOptions,
                    dealIndex,
                  )}
                  register={register}
                  control={control}
                  errors={errors}
                  anchorPrefix='start-investment-share-exchange-modal'
                />
              </StartInvestmentShareExchangeModalTableItem>
            );
          })}
          {(classOfSharesOptions.length > 1 || closingDatesOptions.length > 1) && (
            <StartInvestmentShareExchangeAddNewRow addNewRow={appendNewInvestmentsRow} />
          )}
        </div>
      </SnippetLayout>

      <StartInvestmentTotalShareExchangeList shareExchanges={shareExchanges} />
    </>
  );
};

export default ShareExchangeDetailsCommonFields;
