import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import { dealParametersStatusesLabel } from 'constants/deals';
import { DealParameters } from 'interfaces';
import { ColoredLabel, LabelWithValue } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getDealParametersLink } from 'utils';

import AdminCompanyProfileDealInformationPreloader from './AdminCompanyProfileDealInformationPreloader';

interface Props {
  dealsParameters?: DealParameters[];
  isLoading?: boolean;
}

const AdminCompanyProfileDealParameters: FC<Props> = ({ dealsParameters, isLoading }) => {
  if (isLoading) return <AdminCompanyProfileDealInformationPreloader />;

  if (!dealsParameters?.length) return <Typography className='mt-6'>No Deals Parameters</Typography>;

  return (
    <>
      {dealsParameters.map(({ plainlyDealId, status, id }) => (
        <div className='flex mt-7 flex-wrap items-center' key={plainlyDealId}>
          <LabelWithValue
            className='[&>span]:text-lightBlue-600 w-fit flex mr-4 whitespace-nowrap'
            title='Deal ID'
            content={
              <Link className='ml-1 flex text-violet-600' to={getDealParametersLink(id, true)}>
                {plainlyDealId}
              </Link>
            }
          />
          <LabelWithValue
            className='w-fit flex'
            title='Deal Status'
            content={<ColoredLabel className='ml-2' {...dealParametersStatusesLabel[status]} />}
          />
        </div>
      ))}
    </>
  );
};

export default AdminCompanyProfileDealParameters;
