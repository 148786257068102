import React, { FC } from 'react';

import { twMerge } from 'tailwind-merge';

export const OVERLAY_TEST_ID = 'overlay';

export interface Props {
  isShown: boolean;
  onClick?: VoidFunction;
  className?: string;
}
const Overlay: FC<Props> = ({ isShown, onClick, className = '' }) => {
  // TEMPORARY SOLUTION - add transition in the future
  if (!isShown) return null;

  return (
    <div
      onClick={onClick}
      className={twMerge('w-screen h-screen bg-blue-800 opacity-50 fixed left-0 top-0 z-10', className)}
      data-testid={OVERLAY_TEST_ID}
    />
  );
};

export default Overlay;
