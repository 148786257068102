import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

export interface Props {
  children: ReactNode | string;
  className?: string;
  htmlFor?: string;
  disabled?: boolean;
}

export const FORM_LABEL_TEST_ID = 'FORM_LABEL_TEST_ID';

const FormLabel: FC<Props> = ({ children, className, htmlFor, disabled }) => {
  if (!children) return null;

  return (
    <label
      className={twMerge(cn('block text-blue-800 mb-2 leading-6', { 'text-grey-400': disabled }, className))}
      htmlFor={htmlFor}
      data-testid={FORM_LABEL_TEST_ID}
    >
      {children}
    </label>
  );
};

export default FormLabel;
