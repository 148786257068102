import React, { FC } from 'react';

import cn from 'classnames';
import { Control, FieldErrors, useFieldArray, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ButtonVariants } from 'constants/shared/button';
import { ButtonAction, CompanyIacOverviewFormState, CompanyIacTableFieldsLabel, FormItem } from 'interfaces';
import { Button, FormContent, FormLabel } from 'shared-components';
import Typography from 'shared-components/Typography';
import { formatFormItemsNamesForAppend } from 'utils';

import CompanyIacTableFieldsRow from './CompanyIacTableFieldsRow';

interface Props {
  register: UseFormRegister<CompanyIacOverviewFormState>;
  control: Control<CompanyIacOverviewFormState>;
  errors: FieldErrors<CompanyIacOverviewFormState>;
  name: string;
  label?: string;
  fields: (
    index: number,
    setValue: UseFormSetValue<CompanyIacOverviewFormState>,
    checkDisabledField?: (index: number, fieldName: string) => boolean,
  ) => FormItem[];
  fieldsLabels?: CompanyIacTableFieldsLabel[];
  addNewButtonText?: string;
  disabled?: boolean;
  setValue: UseFormSetValue<CompanyIacOverviewFormState>;
  hideAddNewButton?: boolean;
  checkDisabledField?: (index: number, fieldName: string) => boolean;
  className?: string;
}

const CompanyIacTableFields: FC<Props> = ({
  control,
  register,
  errors,
  name,
  label,
  fields,
  fieldsLabels,
  disabled,
  addNewButtonText = '+Add New Row',
  hideAddNewButton,
  setValue,
  checkDisabledField,
}) => {
  const {
    fields: tableFields,
    remove: removeShareField,
    append,
  } = useFieldArray({
    control,
    name: name as any,
  });

  const handleAddNewShare = () => append(formatFormItemsNamesForAppend(fields(0, setValue)));

  const getSharesActions = (index: number): ButtonAction[] => [
    { id: 'delete_share_field', icon: DeleteSvg, action: () => removeShareField(index) },
  ];

  const checkDisabled = (index: number, fieldName: string) => {
    if (disabled) {
      return true;
    }

    if (checkDisabledField) {
      return checkDisabledField(index, fieldName);
    }

    return false;
  };

  return (
    <div className='mb-6 w-[600px] md:w-auto'>
      {label && <FormLabel>{label}</FormLabel>}

      <div className='flex mt-6 px-2'>
        {fieldsLabels &&
          fieldsLabels.map(({ className, label }, index) => (
            <Typography key={index} className={className}>
              {label}
            </Typography>
          ))}
      </div>

      {tableFields?.length ? (
        tableFields.map((shareField, shareIndex) => (
          <CompanyIacTableFieldsRow
            callback={() => removeShareField(shareIndex)}
            key={shareField?.id}
            index={shareIndex}
            actions={getSharesActions(shareIndex)}
            addNewShare={handleAddNewShare}
            label={label || undefined}
            isAvailableToAddNew={Boolean(tableFields.length - 1 === shareIndex) && !hideAddNewButton}
            disabled={disabled}
          >
            <FormContent
              fields={fields(shareIndex, setValue, checkDisabled)}
              control={control}
              register={register}
              errors={errors}
              isDisabled={disabled}
            />
          </CompanyIacTableFieldsRow>
        ))
      ) : (
        <>
          {hideAddNewButton && <Typography className='mt-6 ml-2 underline'>No Records Found</Typography>}

          {!hideAddNewButton && (
            <div className={cn('flex bg-grey-100 px-2 py-6', { 'bg-grey-100': disabled })}>
              <Button
                disabled={disabled}
                className='max-w-fit justify-start'
                variant={ButtonVariants.LINK}
                onClick={handleAddNewShare}
              >
                {addNewButtonText}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CompanyIacTableFields;
