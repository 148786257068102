import { useState } from 'react';

import { DealFee, WithId } from 'interfaces';
import { updateDealFee } from 'modules/deals/action';
import { useAppDispatch } from 'modules/store';

type UseUpdateDealFeeReturnValues = [(payload: WithId<DealFee>) => void, boolean];

const useUpdateDealFee = (): UseUpdateDealFeeReturnValues => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateDealFee = (payload: WithId<DealFee>) => {
    setIsLoading(true);

    dispatch(updateDealFee(payload))
      .unwrap()
      .finally(() => setIsLoading(false));
  };
  return [handleUpdateDealFee, isLoading];
};

export default useUpdateDealFee;
