import { EntityInviteDirectorFieldNames, FormItem } from 'interfaces';
import { Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';
import { FieldTypes } from './shared';

export const ENTITY_INVITE_DIRECTOR_MODAL_TITLE = 'Invite Director';

export const ENTITY_INVITE_DIRECTOR_MODAL_DESCRIPTION =
  'Please provide email, first name and last name of the Director';

export const COMPANY_NUMBER_FIELD_ALLOWED_KEYS = [
  'Backspace',
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Delete',
  'Enter',
  'Escape',
  'Home',
  'End',
  'Shift',
  'Control',
  'Alt',
];

export const COMPANY_NUMBER_FIELD_CTRL_COMBINATIONS = ['a', 'v', 'c', 'x'];

export const ENTITY_INVITE_DIRECTOR_FORM_FIELDS: FormItem[] = [
  {
    name: EntityInviteDirectorFieldNames.FIRST_NAME,
    label: 'First Name',
    placeholder: 'First Name',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input First Name to continue',
    },
  },
  {
    name: EntityInviteDirectorFieldNames.LAST_NAME,
    label: 'Last Name',
    placeholder: 'Last Name',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Last Name to continue',
    },
  },
  {
    name: EntityInviteDirectorFieldNames.EMAIL,
    label: 'Email Address',
    placeholder: 'Email Address',
    type: FieldTypes.TEXT,
    component: Input,
    required: true,
    validation: {
      required: 'Required information missing please input Email to continue',
      pattern: { message: 'Invalid Email Address', value: EMAIL_PATTERN },
    },
    className: 'mb-2',
  },
];
