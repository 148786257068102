import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { QUALIFICATION_QUESTIONS_DEFAULT_STATE } from 'constants/my-investments';
import {
  ChartItem,
  Investment,
  InvestmentsState,
  MetaPage,
  QualificationQuestionResponce,
  ReviewInvestment,
  QualificationQuestionCompany,
  CompanyWithInvestments,
} from 'interfaces';

import {
  addInvestment,
  getCompaniesGraph,
  getIndustriesGraph,
  getInvestments,
  getCompaniesWithInvestments,
  getLinkedInInvestments,
  uploadInvestments,
  deleteReviewInvestment,
  updateReviewInvestment,
  resetReviewInvestments,
  saveReviewedInvestments,
  getQualificationQuestions,
  submitShareExchangeAnswers,
  deleteInvestment,
  cancelShareExchange,
  editInvestment,
} from './action';

export function getInvestmentsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(getInvestments.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getInvestments.fulfilled, (state, action: PayloadAction<{ data: Investment[]; meta: MetaPage }>) => {
    const { data, meta } = action.payload;
    state.allInvestments = data;
    state.totalPages = meta.last_page;
    state.isLoading = false;
  });

  builder.addCase(getInvestments.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCompaniesWithInvestmentsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(getCompaniesWithInvestments.pending, (state) => {
    state.isLoading = true;
    state.companiesWithInvestments = [];
  });

  builder.addCase(getCompaniesWithInvestments.fulfilled, (state, action: PayloadAction<CompanyWithInvestments[]>) => {
    state.companiesWithInvestments = action.payload;
    state.isLoading = false;
  });

  builder.addCase(getCompaniesWithInvestments.rejected, (state) => {
    state.isLoading = false;
  });
}

export function addInvestmentReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(addInvestment.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(addInvestment.fulfilled, (state, action: PayloadAction<Investment>) => {
    state.allInvestments.unshift(action.payload);
    state.isActionLoading = false;
  });

  builder.addCase(addInvestment.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function editInvestmentReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(editInvestment.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(editInvestment.fulfilled, (state, action: PayloadAction<Investment>) => {
    const allInvestmentsIndex = state.allInvestments.findIndex(({ id }) => id === action.payload.id);
    state.allInvestments[allInvestmentsIndex] = action.payload;

    state.isActionLoading = false;
  });

  builder.addCase(editInvestment.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function deleteInvestmentReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(deleteInvestment.fulfilled, (state, action: PayloadAction<string>) => {
    state.allInvestments = state.allInvestments.filter(({ id }) => id !== action.payload);
  });
}

export function getCompaniesGraphReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(getCompaniesGraph.pending, (state) => {
    state.companiesGraph.isLoading = true;
    state.companiesGraph.list = [];
  });

  builder.addCase(getCompaniesGraph.fulfilled, (state, action: PayloadAction<ChartItem[]>) => {
    state.companiesGraph.list = action.payload;
    state.companiesGraph.isLoading = false;
  });
  builder.addCase(getCompaniesGraph.rejected, (state) => {
    state.companiesGraph.isLoading = false;
  });
}

export function getIndustriesGraphReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(getIndustriesGraph.pending, (state) => {
    state.industriesGraph.isLoading = true;
    state.industriesGraph.list = [];
  });

  builder.addCase(getIndustriesGraph.fulfilled, (state, action: PayloadAction<ChartItem[]>) => {
    state.industriesGraph.list = action.payload;
    state.industriesGraph.isLoading = false;
  });
  builder.addCase(getIndustriesGraph.rejected, (state) => {
    state.industriesGraph.isLoading = false;
  });
}

export function uploadInvestmentsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(uploadInvestments.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(uploadInvestments.fulfilled, (state, action: PayloadAction<ReviewInvestment[]>) => {
    const data = action.payload;

    state.isLoading = false;
    state.reviewInvestments = [...data];
  });

  builder.addCase(uploadInvestments.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getLinkedInInvestmentsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(getLinkedInInvestments.pending, (state) => {
    state.linkedInInvestments.isLoading = true;
  });

  builder.addCase(
    getLinkedInInvestments.fulfilled,
    (state, action: PayloadAction<{ data: Investment[]; meta: MetaPage }>) => {
      const { data, meta } = action.payload;
      state.linkedInInvestments = {
        isLoading: false,
        list: data,
      };
      state.totalPages = meta.last_page;
      state.total = meta.total;
    },
  );

  builder.addCase(getLinkedInInvestments.rejected, (state) => {
    state.linkedInInvestments.isLoading = true;
  });
}

export function deleteReviewInvestmentReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(deleteReviewInvestment, (state, action) => {
    const { investment } = action.payload;

    state.reviewInvestments = state.reviewInvestments.filter((currInvestment) => currInvestment.id !== investment.id);
  });
}

export function updateReviewInvestmentReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(updateReviewInvestment, (state, action) => {
    const { investment } = action.payload;
    state.reviewInvestments = state.reviewInvestments.map((currInvestment) =>
      currInvestment.id === investment.id ? { ...investment } : currInvestment,
    );
  });
}

export function resetReviewInvestmentsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(resetReviewInvestments, (state) => {
    state.reviewInvestments = [];
  });
}

export function saveReviewedInvestmentsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(saveReviewedInvestments.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(saveReviewedInvestments.fulfilled, (state, action: PayloadAction<{ data: Investment[] }>) => {
    state.isActionLoading = false;
    state.allInvestments = [...action.payload.data, ...state.allInvestments];
    state.reviewInvestments = [];
  });

  builder.addCase(saveReviewedInvestments.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function getQualificationQuestionsReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(getQualificationQuestions.pending, (state) => {
    state.qualificationQuestions = { isLoading: true, list: [], company: {} as QualificationQuestionCompany };
  });

  builder.addCase(
    getQualificationQuestions.fulfilled,
    (state, action: PayloadAction<QualificationQuestionResponce>) => {
      state.qualificationQuestions = {
        isLoading: false,
        list: action.payload.data,
        company: action.payload.company,
      };
    },
  );

  builder.addCase(getQualificationQuestions.rejected, (state) => {
    state.qualificationQuestions = { ...QUALIFICATION_QUESTIONS_DEFAULT_STATE };
  });
}

export function cancelShareExchangeAnswersReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(cancelShareExchange.pending, (state) => {
    state.qualificationQuestions.isLoading = true;
  });

  builder.addCase(cancelShareExchange.fulfilled, (state) => {
    state.qualificationQuestions = { ...QUALIFICATION_QUESTIONS_DEFAULT_STATE };
  });

  builder.addCase(cancelShareExchange.rejected, (state) => {
    state.qualificationQuestions.isLoading = false;
  });
}

export function submitShareExchangeAnswersReducer(builder: ActionReducerMapBuilder<InvestmentsState>) {
  builder.addCase(submitShareExchangeAnswers.pending, (state) => {
    state.qualificationQuestions.isLoading = true;
  });

  builder.addCase(submitShareExchangeAnswers.fulfilled, (state) => {
    state.qualificationQuestions = { ...QUALIFICATION_QUESTIONS_DEFAULT_STATE };
  });

  builder.addCase(submitShareExchangeAnswers.rejected, (state) => {
    state.qualificationQuestions.isLoading = false;
  });
}
