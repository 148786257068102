import React, { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { SearchForm } from 'shared-components';
import AddInvestmentButton from 'shared-components/AddInvestmentButton';

interface Props {
  customHeader?: ReactNode;
  additionalButtons?: ReactNode;
  children: ReactNode;
  handleFilter?: (search: string) => void;
  className?: string;
  childrenClassName?: string;
}
const InvestmentsLayout: FC<Props> = ({
  customHeader,
  additionalButtons,
  children,
  className,
  childrenClassName,
  handleFilter = () => '',
}) => {
  return (
    <div className='flex flex-col'>
      <div className={twMerge('flex items-end justify-between mb-12', className)}>
        {customHeader || <SearchForm handleFilter={handleFilter} />}
        <div className={twMerge('flex justify-end basis-full xs:basis-7/12 w-full xs:w-auto', childrenClassName)}>
          <AddInvestmentButton className='flex items-center mx-0 2lg:mx-4 w-full xs:w-auto' />
          {additionalButtons}
        </div>
      </div>
      {children}
    </div>
  );
};

export default InvestmentsLayout;
