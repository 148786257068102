import React, { useEffect } from 'react';

import { DEALS_PARAMETERS_TABLE_COLUMNS } from 'constants/deals';
import { ConstraintVariants } from 'constants/shared';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import { getDealsParameters } from 'modules/deals/action';
import { selectDealsParameters } from 'modules/deals/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DashboardLayout, DealParametersTableRow, NoDealsAdded } from 'page-components';
import { Constraint, Pagination, SearchForm, TitleWithDescription } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

const DEALS_PER_PAGE = 8;

const AllDealParameters = () => {
  const dispatch = useAppDispatch();
  const { isLoading, list: dealsParameters, ...dealsParametersPaginationData } = useAppSelector(selectDealsParameters);

  const { page, setPage, query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  useEffect(() => {
    dispatch(
      getDealsParameters({
        page,
        per_page: DEALS_PER_PAGE,
        query,
        order: sorting.order ? sorting.order : 'desc',
        sort: sorting.column ? sorting.column : 'last_action',
      }),
    );
  }, [dispatch, page, query, sorting.order, sorting.column]);

  return (
    <DashboardLayout>
      <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
        <TitleWithDescription title='Deals' className='mb-6' />

        <SearchForm
          className='items-center mb-12 xl:w-7/12 flex-col xs:flex-row gap-4 xs:gap-0'
          handleFilter={handleFilter}
          placeholder='Search by Company Name'
        />

        <Table onUpdateSorting={updateSorting} columns={DEALS_PARAMETERS_TABLE_COLUMNS}>
          {isLoading ? (
            <TableSkeleton rowsNumber={DEALS_PER_PAGE} columnsNumber={DEALS_PARAMETERS_TABLE_COLUMNS.length} />
          ) : dealsParameters?.length && !isLoading ? (
            dealsParameters?.map((deal) => <DealParametersTableRow key={deal.id} {...deal} />)
          ) : (
            <NoDealsAdded />
          )}
        </Table>

        <Pagination loading={isLoading} currentPage={page} updatePage={setPage} {...dealsParametersPaginationData} />
      </Constraint>
    </DashboardLayout>
  );
};

export default AllDealParameters;
