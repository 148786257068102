import React, { FC } from 'react';

import {
  SIGNIFICANT_CONTROL_SUBMIT_MODAL_DESCRIPTION,
  SIGNIFICANT_CONTROL_SUBMIT_MODAL_TITLE,
} from 'constants/due-diligence';
import { ButtonVariants } from 'constants/shared/button';
import { DueDiligencePersonInput, SignificantControlPerson, TableColumns } from 'interfaces';
import { AlertModal } from 'shared-components';

import SubmitResidentialAddressesTable from './SubmitResidentialAddressesTable';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onClickSubmitUpdatedDirectors: VoidFunction;
  columns: TableColumns[];
  directors?: DueDiligencePersonInput[];
  deletedDirectors?: SignificantControlPerson[];
  hasNominee?: boolean;
};

const SubmitResidentialAddressesModal: FC<Props> = ({
  isOpen,
  onClose,
  columns,
  onClickSubmitUpdatedDirectors,
  directors,
  deletedDirectors,
  hasNominee,
}) => {
  if (!directors) return null;

  return (
    <AlertModal
      title={SIGNIFICANT_CONTROL_SUBMIT_MODAL_TITLE}
      description={SIGNIFICANT_CONTROL_SUBMIT_MODAL_DESCRIPTION}
      wrapperModalClassName='py-4 px-6 lg:px-6 lg:py-4'
      modalClassName='min-w-[70%]'
      isLoading={false}
      onSubmit={onClickSubmitUpdatedDirectors}
      isOpen={isOpen}
      onClose={onClose}
      submitButtonText='Confirm'
      submitButtonVariant={ButtonVariants.PRIMARY}
      backButtonVariant={ButtonVariants.SECONDARY}
    >
      {!!directors?.length && (
        <div className='w-full'>
          <div className='text-green-600 font-bold'>Modified Persons</div>
          <SubmitResidentialAddressesTable columns={columns} directors={directors} hasNominee={hasNominee} />
        </div>
      )}

      {!!deletedDirectors?.length && (
        <div className='w-full mt-4'>
          <div className='text-red-500 font-bold'>Deleted Persons</div>
          <SubmitResidentialAddressesTable directors={deletedDirectors} columns={columns} />
        </div>
      )}
    </AlertModal>
  );
};

export default SubmitResidentialAddressesModal;
