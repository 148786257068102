import React, { FC, useMemo } from 'react';

import cn from 'classnames';
import { Control, FieldErrors, UseFormRegister, UseFormTrigger } from 'react-hook-form';

import {
  BUSINESS_DETAILS_BLOCK_TITLE,
  BUSINESS_DETAILS_FORM_FIELDS,
  DueDiligenceSections,
} from 'constants/due-diligence';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import { BusinessDetails, CompanyNatureOfBusiness, FormItem, FromItemHigligtedColor } from 'interfaces';
import { selectIsBusinessDetailsDisabled } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { FormContent } from 'shared-components';
import { checkIsFounder } from 'utils';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
import NatureOfBusiness from './NatureOfBusiness';

interface Props {
  isLoading: boolean;
  isEditing: boolean;
  handleEdit: VoidFunction;
  handleSaveField: any;
  handleDiscardChanges: VoidFunction;
  register: UseFormRegister<BusinessDetails>;
  control: Control<BusinessDetails>;
  errors: FieldErrors<BusinessDetails>;
  trigger: UseFormTrigger<BusinessDetails>;
  natureOfBusiness: CompanyNatureOfBusiness[] | undefined;
  getUnfieldValue: getUnfieldValueFunc;
}

const BusinessDetailsBlock: FC<Props> = ({
  isEditing,
  natureOfBusiness,
  errors,
  handleDiscardChanges,
  handleEdit,
  handleSaveField,
  control,
  register,
  isLoading,
  getUnfieldValue,
}) => {
  const isFounder = useMemo(() => checkIsFounder(), []);

  const isBusinessDetailsDisabled = useAppSelector(selectIsBusinessDetailsDisabled);

  const isHiddenEditButton = isFounder && Boolean(isBusinessDetailsDisabled);

  const highlightFields = (fields: FormItem[]): FormItem[] => {
    return fields.map((field): FormItem => {
      const unfieldValue = getUnfieldValue(field.name);
      return {
        ...field,
        highlighted: !!unfieldValue,
        highlightedDescription: unfieldValue?.description,
        highlightedColor: unfieldValue?.asError ? FromItemHigligtedColor.Red : undefined,
      };
    });
  };

  return (
    <DueDiligenceBlock
      blockNumber='1.1'
      title={BUSINESS_DETAILS_BLOCK_TITLE}
      onClickEdit={handleEdit}
      onClickDiscardChanges={handleDiscardChanges}
      sectionType={DueDiligenceSections.BUSINESS_DETAILS}
      isLoading={isLoading}
      footerContent={<NatureOfBusiness data={natureOfBusiness || []} />}
    >
      <form className='grid grid-cols-[1fr_1fr_1fr] xl:grid-cols-dueDiligence gap-x-6 mt-6'>
        <FormContent
          fields={highlightFields(BUSINESS_DETAILS_FORM_FIELDS)}
          register={register}
          errors={errors}
          control={control}
          disabledFilledField={isFounder}
          isDisabled={!isEditing || isHiddenEditButton}
          className={cn({ 'disabled-light-grey': !isEditing || isHiddenEditButton })}
          onBlur={handleSaveField}
        />
      </form>
    </DueDiligenceBlock>
  );
};

export default BusinessDetailsBlock;
