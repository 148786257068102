import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client';
import {
  DownloadDocumentPayload,
  FileDocument,
  GetFileDocumentsPayload,
  MetaPage,
  UploadDocumentPayload,
} from 'interfaces';
import { stringifyFilterParams, getFormDataFromObject } from 'utils';

export class DocumentsApis {
  constructor(private url: string) {}

  getDocuments(params: GetFileDocumentsPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: FileDocument[]; meta: MetaPage }>(
      `${this.url}?${stringifyFilterParams(params)}`,
      config,
    );
  }

  getFundDocuments(params?: GetFileDocumentsPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: FileDocument[]; meta: MetaPage }>(
      `${this.url}?${stringifyFilterParams(params ?? {})}`,
      config,
    );
  }

  uploadDocument(data: UploadDocumentPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/upload`, getFormDataFromObject(data), config);
  }

  downloadDocument(
    { filePath, originalFilePath }: Omit<DownloadDocumentPayload, 'filename'>,
    config?: AxiosRequestConfig,
  ) {
    return httpApiClient.get(originalFilePath ? filePath : `${this.url}/download/${filePath}`, {
      ...config,
      responseType: 'blob',
    });
  }

  getDocumentTypes(config?: AxiosRequestConfig) {
    return httpApiClient.get(`${this.url}/types`, config);
  }
}

export const documentsApis = new DocumentsApis('/documents');
export const adminDocumentsApis = new DocumentsApis('/admin/documents');
export const fmUserDocumentsApis = new DocumentsApis('/fmusers/documents');
export const investorDocumentsApis = new DocumentsApis('/investors/funds/documents');
