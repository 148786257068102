import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import { COMPANIES_INVITE_MODAL_DESCRIPTION, COMPANIES_INVITE_MODAL_TITLE } from 'constants/companies';
import { COMPANY_INVESTORS_DESCRIPTION, COMPANY_INVESTORS_DOWNLOAD_TEMPLATE_PATH } from 'constants/company-investors';
import { ConstraintVariants } from 'constants/shared';
import { TypographyStyles, TypographyVariants } from 'constants/shared/typography';
import { Company, InvitePreviewModalParams, InvitePreviewTemplate } from 'interfaces';
import { getCompanyName, getInvitedCompanyInvestors } from 'modules/companies/action';
import { selectInvitedCompanyInvestors } from 'modules/companies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CompanyInvestorsContent, DashboardLayout } from 'page-components';
import CompanyInviteInvestorsPreviewEmail from 'page-components/company-investors/CompanyInviteInvestorsPreviewEmail';
import { Constraint, DescriptionWithLink, DynamicPageTitle, ModalWindow } from 'shared-components';
import { getLinkWithOriginPrefix, getTitleWithReplacedYourWord } from 'utils';

import { UserTypes } from '../constants/user';
import { useInviteInvestorsPreviewEmail } from '../hooks/investors/useInviteInvestorsPreviewEmail';
import { selectUserEntityData, selectUserType } from '../modules/current-user/selectors';

const CompanyInvestors = () => {
  const { id: companyId } = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const dispatch = useAppDispatch();

  const invitedCompanyInvestors = useAppSelector(selectInvitedCompanyInvestors);
  const { isTypeEntity, entityName } = useAppSelector(selectUserEntityData);
  const userType = useAppSelector(selectUserType);

  const [invitePreviewModalState, handleOpenInvitePreviewModal, isPreviewEmailLoading, handleSetIsPreviewEmailLoading] =
    useInviteInvestorsPreviewEmail();

  const { companyName: locationCompanyName = '' } = (location?.state as Pick<Company, 'companyName'>) || {};

  const currentCompanyName = companyName || locationCompanyName;

  useEffect(() => {
    if (companyId && !locationCompanyName && userType === UserTypes.FOUNDER) {
      dispatch(getCompanyName(companyId))
        .unwrap()
        .then(({ companyName }) => setCompanyName(companyName));
    }
  }, [companyId, dispatch, locationCompanyName, userType]);

  useEffect(() => {
    if (companyId) {
      setIsLoading(true);
      dispatch(getInvitedCompanyInvestors({ id: companyId, userType }))
        .unwrap()
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [companyId, dispatch]);

  return (
    <>
      <ModalWindow
        title={COMPANIES_INVITE_MODAL_TITLE}
        description={COMPANIES_INVITE_MODAL_DESCRIPTION}
        titleVariant={TypographyVariants.MEDIUM}
        headerClassName='text-blue-800'
        className='min-w-[70%] 2xl:min-w-[1048px]'
        contentClassName='overflow-x-hidden'
        {...invitePreviewModalState}
      >
        <CompanyInviteInvestorsPreviewEmail
          isLoading={isLoading || isPreviewEmailLoading}
          handleSetIsLoading={handleSetIsPreviewEmailLoading}
          onClose={invitePreviewModalState.onClose}
          {...(invitePreviewModalState.modalProps as InvitePreviewModalParams & { templates: InvitePreviewTemplate })}
        />
      </ModalWindow>

      <DashboardLayout>
        <Constraint variant={ConstraintVariants.FULL_ROUNDED}>
          <DynamicPageTitle
            className={cn(TypographyStyles.large)}
            title='Investors'
            dynamicTitle={currentCompanyName}
            isLoading={!currentCompanyName}
          />
          <DescriptionWithLink
            text={getTitleWithReplacedYourWord(COMPANY_INVESTORS_DESCRIPTION, entityName, isTypeEntity)}
            linkText='Download Template'
            href={getLinkWithOriginPrefix(COMPANY_INVESTORS_DOWNLOAD_TEMPLATE_PATH)}
            isDownload
          />

          <CompanyInvestorsContent
            investors={invitedCompanyInvestors}
            isLoading={isLoading}
            handleSetIsLoading={handleSetIsPreviewEmailLoading}
            companyId={companyId}
            companyName={currentCompanyName}
            handleOpenInvitePreviewModal={handleOpenInvitePreviewModal}
          />
        </Constraint>
      </DashboardLayout>
    </>
  );
};

export default CompanyInvestors;
