export const getParsedLocalStorageArray = (localStorageKey: string) => {
  const arr = localStorage.getItem(localStorageKey) || '';
  return arr ? JSON.parse(arr) : [];
};

export const addValueToLocalStorageArray = (localStorageKey: string, value?: number | string) => {
  if (!value) return;

  const parsedValues = getParsedLocalStorageArray(localStorageKey);

  localStorage.setItem(localStorageKey, JSON.stringify([...parsedValues, value]));
};
