import React from 'react';

import { SETTINGS_PAGE_TITLE } from 'constants/settings';
import { InvestorSubTypes } from 'constants/user';
import { TabItem } from 'interfaces';
import { selectUser } from 'modules/current-user/selectors';
import { useAppSelector } from 'modules/store';
import { DashboardLayout, EntitySettings, ProfileSettings, SettingsSecurity } from 'page-components';
import { Tabs, TitleWithDescription } from 'shared-components';

const SETTINGS_TABS = (type?: InvestorSubTypes): TabItem[] => [
  { id: 'profile', name: 'Profile', component: ProfileSettings },
  { id: 'security', name: 'Security', component: SettingsSecurity },
  ...(type === InvestorSubTypes.ENTITY ? [{ id: 'entity', name: 'Entity', component: EntitySettings }] : []),
  // Hidden according to the requirements of task №1173
  // { id: 'integrations', name: 'Integrations', component: SettingsIntegrations },
  // { id: 'accSettings', name: 'Account Settings', component: AccountSettings },
  // { id: 'notifications', name: 'Notifications' },
];

const Settings = () => {
  const user = useAppSelector(selectUser);

  return (
    <DashboardLayout>
      <div className='contentful-dashboard-page'>
        <TitleWithDescription className='mb-4' title={SETTINGS_PAGE_TITLE} />

        <Tabs tabs={SETTINGS_TABS(user?.type)} />
      </div>
    </DashboardLayout>
  );
};

export default Settings;
