import React, { FC } from 'react';

import {
  UPLOAD_COMPANY_INVESTORS_MODAL_DESCRIPTION,
  UPLOAD_COMPANY_INVESTORS_MODAL_TITLE,
} from 'constants/company-investors';
import {
  UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPE,
  UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPES_TEXT,
  UPLOAD_INVESTMENTS_MAX_FILE_SIZE,
} from 'constants/investments';
import { PromiseBooleanOrVoid } from 'interfaces';
import { DescriptionWithLink, UploadFileForm, UploadFileModal } from 'shared-components';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmitUploadInvestors: ([investmentsFile]: File[]) => PromiseBooleanOrVoid;
  investorsTemplateDownloadPath: string;
};

const UploadCompanyInvestorsModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmitUploadInvestors,
  investorsTemplateDownloadPath,
}) => {
  return (
    <UploadFileModal
      isOpen={isOpen}
      onClose={onClose}
      title={UPLOAD_COMPANY_INVESTORS_MODAL_TITLE}
      description={
        <DescriptionWithLink
          href={investorsTemplateDownloadPath}
          text={UPLOAD_COMPANY_INVESTORS_MODAL_DESCRIPTION}
          linkText='here'
          isDownload
        />
      }
    >
      <UploadFileForm
        onClose={onClose}
        onSubmitUpload={onSubmitUploadInvestors}
        allowedFileTypes={[UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPE]}
        maxFileSize={UPLOAD_INVESTMENTS_MAX_FILE_SIZE}
        allowedFileHelpText={UPLOAD_INVESTMENTS_ALLOWED_FILE_TYPES_TEXT}
      />
    </UploadFileModal>
  );
};

export default UploadCompanyInvestorsModal;
