import React from 'react';

import { PLAND_SUPPORT_EMAIL } from 'constants/global';
import { getUploadFileErrorMessage } from 'utils/documents';

const UploadDueDiligenceDocumentsFileSizeError = () => {
  return (
    <div>
      {getUploadFileErrorMessage(7)}. If you still need upload this file, please contact{' '}
      <a className='text-blue' href={`mailto:${PLAND_SUPPORT_EMAIL}`}>
        {PLAND_SUPPORT_EMAIL}
      </a>
    </div>
  );
};

export default UploadDueDiligenceDocumentsFileSizeError;
