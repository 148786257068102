import React from 'react';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ROUTES } from 'constants/routes';
import { SnippetLayout, LinkButton } from 'shared-components';
import { navigateByRole } from 'utils';

import AdminCompaniesSnippetContent from './AdminCompaniesSnippetContent';

const AdminCompaniesSnippet = () => {
  return (
    <SnippetLayout
      title='Companies'
      description={
        <LinkButton to={navigateByRole(true, ROUTES.companies)}>
          View more <ArrowSvg className='ml-2.5' />
        </LinkButton>
      }
      className='snippet-title col-span-full md:col-span-3 1xl:col-span-2'
      childrenClassName='mt-10 flex items-center justify-between'
    >
      <AdminCompaniesSnippetContent />
    </SnippetLayout>
  );
};

export default AdminCompaniesSnippet;
