import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import { getInvestorInvoices } from 'modules/investors/action';
import { selectInvestorInvoices } from 'modules/investors/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Pagination } from 'shared-components';
import Table, { TableSkeleton } from 'shared-components/table';

import { INVOICES_ADMIN_TABLE_TABS } from '../../constants/my-bills';
import InvoicesTableItem from '../invoices/invoices-table/InvoicesTableItem';
import NoInvoicesAdded from '../invoices/invoices-table/NoInvoicesAdded';

const InvestorInvoices = () => {
  const { id } = useParams();
  const { page, setPage } = usePaginationWithSearch();

  const dispatch = useAppDispatch();

  const { isLoading, list, ...paginationData } = useAppSelector(selectInvestorInvoices);

  useEffect(() => {
    if (id) {
      dispatch(getInvestorInvoices({ id, page, per_page: 5 }));
    }
  }, [dispatch, id, page]);

  return (
    <>
      <Table columns={INVOICES_ADMIN_TABLE_TABS}>
        {list?.length && !isLoading ? (
          <>
            {list.map((invoice) => (
              <InvoicesTableItem key={invoice.id} {...invoice} />
            ))}
          </>
        ) : isLoading ? (
          <TableSkeleton rowsNumber={3} columnsNumber={10} />
        ) : (
          <NoInvoicesAdded />
        )}
      </Table>
      <Pagination currentPage={page} updatePage={setPage} {...paginationData} />
    </>
  );
};

export default InvestorInvoices;
