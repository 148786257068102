export interface EntityInviteDirectorInput {
  firstName: string;
  lastName: string;
  email: string;
}

export enum EntityInviteDirectorFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
}

export interface EntityInviteDirectorPayload extends EntityInviteDirectorInput {
  type: string;
  inviteType: string;
  companyId: number;
}
