import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';

import { adminInvoicesApis, adminInvoicesMetricsApis, invoicesApis } from 'apis';
import { showServerError } from 'helpers';
import { GetListPayload } from 'interfaces';
import history from 'services/history';

export const BILLS_SLICE_NAME = 'bills';

export const getBills = createAsyncThunk(
  `${BILLS_SLICE_NAME}/getBills`,
  async (data: GetListPayload & { isAdmin?: boolean }) => {
    const { isAdmin, ...params } = data;

    const response = await (isAdmin ? adminInvoicesApis.getBills(params) : invoicesApis.getBills(params));

    return response?.data;
  },
);

export const navigateToCardlessPayPage = createAsyncThunk(
  `${BILLS_SLICE_NAME}/navigateToCardlessPayPage`,
  async (invoiceId: number, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { billingUrl },
        },
      } = await invoicesApis.getCardlessLink(invoiceId);

      history.push(billingUrl);

      return billingUrl;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const downloadInvoice = createAsyncThunk(
  `${BILLS_SLICE_NAME}/downloadBills`,
  async ({ id, invoiceReference }: { id: number; invoiceReference: string }, { rejectWithValue }) => {
    try {
      const response = await adminInvoicesApis.downloadBill(id);
      saveAs(response.data, `${invoiceReference}.pdf`);

      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getAdminInvoicesMetrics = createAsyncThunk(
  `${BILLS_SLICE_NAME}/getAdminInvoicesMetrics`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminInvoicesMetricsApis.getAdminInvoicesMetrics();

      return response?.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
