import React, { FC } from 'react';

import { SHARE_EXCHANGE_REVIEW_INVESTMENTS_COLUMNS, ShareExchangeStatuses } from 'constants/share-exchange';
import { Investment } from 'interfaces';
import Table from 'shared-components/table';

import ShareExchangeReviewInvestmentsTableItem from './ShareExchangeReviewInvestmentsTableItem';
import ShareExchangeReviewTableNoInvestments from './ShareExchangeReviewTableNoInvestments';

type Props = {
  status: ShareExchangeStatuses;
  investments: Investment[];
};

const ShareExchangeReviewInvestmentsTable: FC<Props> = ({ status, investments }) => {
  return (
    <Table columns={SHARE_EXCHANGE_REVIEW_INVESTMENTS_COLUMNS}>
      {investments?.length ? (
        investments.map((investment) => (
          <ShareExchangeReviewInvestmentsTableItem key={investment.id} status={status} investment={investment} />
        ))
      ) : (
        <ShareExchangeReviewTableNoInvestments />
      )}
    </Table>
  );
};

export default ShareExchangeReviewInvestmentsTable;
