import React, { ReactNode } from 'react';

import TableNoData from './TableNoData';
import TableSkeleton from './TableSkeleton';

interface Props<T> {
  isLoading?: boolean;
  noDataCondition?: boolean;
  data: T[] | undefined | null;
  renderContent: (data: T) => ReactNode;
  noDataNode?: ReactNode;
  columns?: number;
  rows?: number;
}

const TableContentWrapper = <T,>({
  isLoading,
  noDataCondition,
  data,
  renderContent,
  noDataNode,
  columns = 3,
  rows = 3,
}: Props<T>) => {
  if (isLoading) return <TableSkeleton columnsNumber={columns} rowsNumber={rows} />;

  if (noDataCondition || !data?.length) return <>{noDataNode || <TableNoData />}</>;

  return <>{data?.map((item) => renderContent(item))}</>;
};

export default TableContentWrapper;
