import { UserTypes } from '../constants/user';
import { IsRegisteredData } from './useIsAlreadyRegistered';

export const useUserRegistrationFieldFlags = (
  isAlreadyRegisteredData: IsRegisteredData | null,
  userType: UserTypes,
) => {
  const isCorrectFounderFields = userType === UserTypes.FOUNDER && !isAlreadyRegisteredData?.isFounder;
  const isIncorrectFounderFields = userType === UserTypes.FOUNDER && isAlreadyRegisteredData?.isFounder;

  const isCorrectInvestorFields = userType === UserTypes.INVESTOR && !isAlreadyRegisteredData?.isInvestor;
  const isIncorrectInvestorFields = userType === UserTypes.INVESTOR && isAlreadyRegisteredData?.isInvestor;

  return { isCorrectFounderFields, isCorrectInvestorFields, isIncorrectFounderFields, isIncorrectInvestorFields };
};
