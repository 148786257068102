import React, { useEffect } from 'react';

import useGraphRanges from 'hooks/use-grpah-ranges/useGraphRanges';
import { getFoundersMetrics } from 'modules/founders/action';
import { selectFoundersMetrics } from 'modules/founders/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { MetricsGraphLayout } from 'shared-components';
import { convertObjectToAreaData } from 'utils';

const AdminFoundersSnippetContent = () => {
  const dispatch = useAppDispatch();
  const { isLoading, totalAll, totalByFilter } = useAppSelector(selectFoundersMetrics);
  const { selectedRange, onChangeRange } = useGraphRanges();

  useEffect(() => {
    dispatch(getFoundersMetrics(selectedRange.value));
  }, [dispatch, selectedRange.value]);

  return (
    <MetricsGraphLayout
      selectedRange={selectedRange}
      isLoading={isLoading}
      chartData={convertObjectToAreaData(totalByFilter)}
      onChangeRange={onChangeRange}
      amount={totalAll}
    />
  );
};

export default AdminFoundersSnippetContent;
