import React, { FC, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useForm } from 'react-hook-form';

import {
  DueDiligenceSections,
  MANAGEMENT_OWNERSHIP_COMPANY_OWNED_BLOCK_TITLE,
  MANAGEMENT_OWNERSHIP_FORM_FIELDS,
} from 'constants/due-diligence';
import useDueDiligenceEdit from 'hooks/use-due-diligence-edit/useDueDiligenceEdit';
import { ManagementOwnershipInput } from 'interfaces';
import { editDueDiligenceBusinessDetails } from 'modules/due-diligence/action';
import { selectFoundersOwnershipPercentage } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { FormContent } from 'shared-components';

import DueDiligenceBlock from '../due-diligence-block/DueDiligenceBlock';
const MANAGEMENT_OWNERSHIP_DEFAULT_VALUES = {
  foundersOwnershipPercentage: null,
};

interface Props {
  isEditing: boolean;
}

const ManagementOwnership: FC<Props> = ({ isEditing }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleEdit, handleCancel } = useDueDiligenceEdit(DueDiligenceSections.MANAGEMENT_OWNERSHIP);

  const dispatch = useAppDispatch();
  const { id, foundersOwnershipPercentage } = useAppSelector(selectFoundersOwnershipPercentage);

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ManagementOwnershipInput>({
    defaultValues: MANAGEMENT_OWNERSHIP_DEFAULT_VALUES,
  });

  const foundersOwnershipPercentageOption = useMemo(
    () => ({ value: foundersOwnershipPercentage, label: foundersOwnershipPercentage }),
    [foundersOwnershipPercentage],
  );

  const handleDiscardChanges = () => {
    handleCancel();
    reset({ foundersOwnershipPercentage: foundersOwnershipPercentageOption });
  };

  const handleSaveField = () => {
    setIsLoading(true);

    const fieldValue = getValues('foundersOwnershipPercentage')?.value;

    if (fieldValue === foundersOwnershipPercentage) return;

    dispatch(editDueDiligenceBusinessDetails({ id, foundersOwnershipPercentage: fieldValue }))
      .unwrap()
      .then(handleCancel)
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!foundersOwnershipPercentageOption?.value) return;

    reset({ foundersOwnershipPercentage: foundersOwnershipPercentageOption });
  }, [foundersOwnershipPercentageOption, reset]);

  return (
    <DueDiligenceBlock
      blockNumber='5'
      descriptionClassName='text-grey-500'
      sectionType={DueDiligenceSections.MANAGEMENT_OWNERSHIP}
      title={MANAGEMENT_OWNERSHIP_COMPANY_OWNED_BLOCK_TITLE}
      onClickEdit={handleEdit}
      onClickDiscardChanges={handleDiscardChanges}
      isLoading={isLoading}
    >
      <form>
        <FormContent
          fields={MANAGEMENT_OWNERSHIP_FORM_FIELDS}
          control={control}
          register={register}
          errors={errors}
          onBlur={handleSaveField}
          isDisabled={!isEditing || isLoading}
          className={cn({ 'disabled-light-grey': !isEditing || isLoading })}
        />
      </form>
    </DueDiligenceBlock>
  );
};

export default ManagementOwnership;
