import { useMemo, useState, useCallback } from 'react';

import { DueDiligenceSections } from 'constants/due-diligence';
import { removeDueDiligenceEditingSections, setDueDiligenceEditingSections } from 'modules/due-diligence/action';
import { selectDueDiligenceEditingSections } from 'modules/due-diligence/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';

type PreviousState = Record<string, any>;

const useDueDiligenceEdit = (sectionKey: DueDiligenceSections) => {
  const dispatch = useAppDispatch();

  const editingSections = useAppSelector(selectDueDiligenceEditingSections);

  const [previousState, setPreviousState] = useState<PreviousState>({});

  const isEditing = useMemo(() => {
    return editingSections.includes(sectionKey);
  }, [editingSections, sectionKey]);

  const handleEdit = useCallback(
    (previousState: PreviousState = {}) => {
      setPreviousState({ ...previousState });
      dispatch(setDueDiligenceEditingSections(sectionKey));
    },
    [dispatch, sectionKey],
  );

  const handleCancel = useCallback(() => {
    setPreviousState({});
    dispatch(removeDueDiligenceEditingSections(sectionKey));
  }, [dispatch, sectionKey]);

  return {
    handleEdit,
    handleCancel,
    previousState,
    isEditing,
  };
};

export default useDueDiligenceEdit;
