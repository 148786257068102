import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftIcon } from 'assets/svg/arrow-left.svg';
import { ConstraintVariants } from 'constants/shared';
import { ButtonVariants } from 'constants/shared/button';
import { DashboardLayout } from 'page-components';
import { AdminCompanyProfileContainer } from 'page-components/admin-company-profile';
import { Button, Constraint } from 'shared-components';

import { useCurrentPath } from '../hooks/useCurrentPath';
import { selectCurrentCompanyData } from '../modules/companies/selectors';
import { useAppSelector } from '../modules/store';

const AdminCompanyProfile = () => {
  const navigate = useNavigate();

  const { data } = useAppSelector(selectCurrentCompanyData);

  const handleGoBack = () => navigate(-1);

  useCurrentPath(data?.companyName);

  return (
    <DashboardLayout>
      <Button className='w-fit ml-10 mt-6' onClick={handleGoBack} variant={ButtonVariants.SECONDARY}>
        <ArrowLeftIcon className='mr-2' /> Back
      </Button>

      <Constraint className='!mt-6' variant={ConstraintVariants.FULL_ROUNDED}>
        <AdminCompanyProfileContainer />
      </Constraint>
    </DashboardLayout>
  );
};

export default AdminCompanyProfile;
