import React from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';

import { ReactComponent as SmallInformationIcon } from 'assets/svg/small-info.svg';
import { DEAL_PARAMETERS_FEE_FORM_FIELDS } from 'constants/deal-parameters';
import useUpdateDealFee from 'hooks/deals/useUpdageDealFee';
import { DealFee, NumberOrNull } from 'interfaces';
import { Button, FormContent, SnippetLayout } from 'shared-components';
import IconWithTooltip from 'shared-components/IconWithTooltip';

interface Props {
  id: number;
  fee: DealFee;
  currentStepIndex: NumberOrNull;
}

export const ShareExchangeFeeSnippet = ({ id, fee, currentStepIndex }: Props) => {
  const [handleUpdateDealFee, isLoading] = useUpdateDealFee();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<DealFee>({ defaultValues: fee });

  const isApprovalStageReached = Boolean(currentStepIndex && currentStepIndex >= 3);

  const onSubmit: SubmitHandler<DealFee> = ({ feeRate, feeMinimum }) => {
    handleUpdateDealFee({ id, feeRate: Number(feeRate), feeMinimum: Number(feeMinimum) });
  };

  return (
    <SnippetLayout
      title='Share Exchange Fee'
      className='mt-7 w-full [&>div]:justify-start [&>div]:items-center'
      customHeader={
        isApprovalStageReached ? (
          <IconWithTooltip
            wrapperClassName='ml-1.5 cursor-pointer'
            tooltipClassName='font-normal'
            tooltipContent='Fees cannot be changed after the approval step'
            anchorId='share-exchange-fee-tooltip'
            offset={1}
            place='top'
            icon={<SmallInformationIcon />}
          />
        ) : null
      }
    >
      <form
        className='relative grid grid-cols-1 md:grid-cols-[3fr_3fr_1fr] items-end gap-6 mb-4'
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormContent
          fields={DEAL_PARAMETERS_FEE_FORM_FIELDS}
          isDisabled={isApprovalStageReached}
          control={control}
          register={register}
          errors={errors}
        />
        <Button disabled={isApprovalStageReached} isLoading={isLoading} className='w-full'>
          Save
        </Button>
      </form>
    </SnippetLayout>
  );
};
