import React, { useCallback, useEffect, useState } from 'react';

import qs from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { DEAL_STATUSES } from 'constants/deals';
import { INVESTMENTS_TABLE_FILTER_FORM_FIELDS } from 'constants/investments';
import { COMPANY_WITH_SHARE_EXCHANGE_STATUSES, SHARE_EXCHANGE_STATUSES } from 'constants/my-investments';
import useModal from 'hooks/use-modal/useModal';
import { usePaginationWithSearch } from 'hooks/use-pagination-with-search/usePaginationWithSearch';
import useTableSorting from 'hooks/use-table-sorting/useTableSorting';
import useInvestmentsQualificationQuestionsModals from 'hooks/useInvestmentsQualificationQuestionsModals';
import useStartInvestmentShareExchange from 'hooks/useStartInvestmentShareExchange';
import { HandleClickRequestShareExchangeType, InvestmentsFieldNames } from 'interfaces';
import { selectSelfId } from 'modules/current-user/selectors';
import { getCompanyDealPredefinedData } from 'modules/deals/action';
import { getCompaniesWithInvestments, requestShareExchange } from 'modules/investments/action';
import { selectCompaniesWithInvestments } from 'modules/investments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  StartInvestmentShareExchangeModal,
  InvestmentsQualificationQuestionsModals,
  StartRequestShareExchangeModalPrompt,
} from 'page-components';
import history from 'services/history';
import { FormContent, SearchForm } from 'shared-components';
import { formatToMonthFormatWithSlashes } from 'utils/dates';

import InvestmentsLayout from '../InvestmentsLayout';
import MyCompaniesWithFinalisedInvestmentsTable from './my-companies-with-finalised-investments/MyCompaniesWithFinalisedInvestmentsTable';
import MyCompaniesWithInvestmentsTable from './MyCompaniesWithInvestmentsTable';

const INVESTMENTS_PER_PAGE = 1000;

const MyInvestmentsContainer = () => {
  const {
    processingShareExchangeId,
    setProcessingShareExchangeId,
    isOpenQualificationQuestions,
    onOpenQualificationQuestions,
    isOpenSuccessShareExchangeRequestModal,
    onCloseSuccessShareExchangeRequestModal,
    onOpenSuccessShareExchangeRequestModal,
    closeQualificationQuestions,
  } = useInvestmentsQualificationQuestionsModals();

  const { isOpenStartShareExchange, onOpenStartShareExchange, onCloseStartShareExchange, onSuccessStartShareExchange } =
    useStartInvestmentShareExchange();

  const {
    isOpen: isOpenStartRequestPrompt,
    onClose: onCloseStartRequestPrompt,
    onOpen: onOpenStartRequestPrompt,
    modalProps: { companyId: startRequestShareExchangeCompanyId },
  } = useModal();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const { query, handleFilter } = usePaginationWithSearch();
  const { sorting, updateSorting } = useTableSorting();

  const {
    register,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      investmentDate: '',
    },
  });

  const companiesWithInvestments = useAppSelector(selectCompaniesWithInvestments);
  const selfId = useAppSelector(selectSelfId);

  const [isLoading, setIsLoading] = useState(true);

  const loadCompaniesWithInvestments = (search?: string, ignoreQuery?: boolean) => {
    setIsLoading(true);

    const investmentDate = getValues(InvestmentsFieldNames.INVESTMENT_DATE);

    const formattedInvestmentDate = formatToMonthFormatWithSlashes(investmentDate);

    const metaData = {
      page: 1,
      per_page: INVESTMENTS_PER_PAGE,
      sort: sorting.column,
      order: sorting.order,
      query: search || ignoreQuery ? search : query,
      filter: formattedInvestmentDate ? `filter[investmentDate]=${formattedInvestmentDate}` : '',
    };

    dispatch(getCompaniesWithInvestments(metaData)).finally(() => setIsLoading(false));
  };

  const onSuccessSubmitQualificationQuestions = () => {
    loadCompaniesWithInvestments();
    closeQualificationQuestions();
    onOpenSuccessShareExchangeRequestModal();
  };

  const onSuccessCancelShareExchangeQuestions = () => {
    loadCompaniesWithInvestments();
    closeQualificationQuestions();
  };

  const handleRequestShareExchange = useCallback(
    (companyId: number, serDeclined?: boolean, shareExchangeRequestId?: number) => {
      setIsLoading(true);

      dispatch(
        requestShareExchange({
          id: serDeclined && shareExchangeRequestId ? shareExchangeRequestId : companyId,
          isResubmit: !!serDeclined,
          selfId,
        }),
      )
        .unwrap()
        .then(({ data: response }) => {
          loadCompaniesWithInvestments();

          if (response?.status === SHARE_EXCHANGE_STATUSES.INITIATED && response?.id) {
            onOpenQualificationQuestions();
            setProcessingShareExchangeId(response.id);

            return;
          }

          onOpenSuccessShareExchangeRequestModal();
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
    [
      dispatch,
      onOpenQualificationQuestions,
      onOpenSuccessShareExchangeRequestModal,
      selfId,
      setProcessingShareExchangeId,
    ],
  );

  const handleClickRequestShareExchange: HandleClickRequestShareExchangeType = useCallback(
    ({ userCompanySEStatus, companyId, shareExchangeRequestId, serDeclined, draftDealId, investmentIds }) => {
      if (userCompanySEStatus === COMPANY_WITH_SHARE_EXCHANGE_STATUSES.READY_FOR_SE) {
        dispatch(getCompanyDealPredefinedData({ companyId, draftDealId, investmentIds }));

        onOpenStartShareExchange();
        return;
      }

      handleRequestShareExchange(companyId, serDeclined, shareExchangeRequestId);
    },
    [dispatch, handleRequestShareExchange, onOpenStartShareExchange],
  );

  const onSubmitRequestShareExchangePrompt = (companyId: number) => {
    if (!companyId) {
      return;
    }

    handleRequestShareExchange(companyId);
    onCloseStartRequestPrompt();
  };

  useEffect(() => {
    loadCompaniesWithInvestments();
  }, [sorting.column, sorting.order]);

  useEffect(() => {
    // Its used for redirecting from sign-in and sign-up pages, when invitation link has CSE flow
    const { companyId, startShareExchange, tab } = qs.parse(location.search);

    if (!companyId && startShareExchange) {
      onOpenStartShareExchange();
      history.replace({ pathname: window.location.pathname, search: qs.stringify({ tab }) });
      return;
    }

    if (companyId && startShareExchange && !!companiesWithInvestments?.length) {
      const companyInvestment = companiesWithInvestments.find((c) => {
        return Number(c.id) === Number(companyId);
      });
      let draftDealId;
      if (companyInvestment?.seStatus === DEAL_STATUSES.PENDING) {
        draftDealId = companyInvestment.seId;
      }
      dispatch(getCompanyDealPredefinedData({ companyId: Number(companyId), draftDealId }));

      onOpenStartShareExchange();
      history.replace({ pathname: window.location.pathname, search: qs.stringify({ tab }) });
    }
  }, [dispatch, location.search, onOpenStartShareExchange, companiesWithInvestments]);

  useEffect(() => {
    // It's used for redirecting from investor deal details page, when deal was stopped
    const { companyId } = location?.state || {};

    if (!companyId) return;

    dispatch(getCompanyDealPredefinedData({ companyId }))
      .unwrap()
      .then(() => {
        onOpenStartShareExchange();
        history.replace(history.location, {});
      });
  }, [dispatch, location?.state, onOpenStartShareExchange]);

  useEffect(() => {
    // Its used for redirecting from investor dashboard when request is available
    const { companyId, startShareExchangeRequest, tab } = qs.parse(location.search);

    if (companyId && startShareExchangeRequest) {
      onOpenStartRequestPrompt({ companyId });
      history.replace({ pathname: window.location.pathname, search: qs.stringify({ tab }) });
    }
  }, [dispatch, location.search, onOpenStartRequestPrompt]);

  return (
    <>
      <StartRequestShareExchangeModalPrompt
        isOpen={isOpenStartRequestPrompt}
        onClose={onCloseStartRequestPrompt}
        companyId={startRequestShareExchangeCompanyId}
        onRequestShareExchange={onSubmitRequestShareExchangePrompt}
      />

      <StartInvestmentShareExchangeModal
        isOpen={isOpenStartShareExchange}
        onClose={onCloseStartShareExchange}
        onSuccessStartShareExchange={onSuccessStartShareExchange}
      />

      <InvestmentsQualificationQuestionsModals
        processingShareExchangeId={processingShareExchangeId}
        isOpenQualificationQuestions={isOpenQualificationQuestions}
        isOpenSuccessShareExchangeRequestModal={isOpenSuccessShareExchangeRequestModal}
        closeQualificationQuestions={closeQualificationQuestions}
        onCloseSuccessShareExchangeRequestModal={onCloseSuccessShareExchangeRequestModal}
        onSuccessSubmitQualificationQuestions={onSuccessSubmitQualificationQuestions}
        onSuccessCancelShareExchangeQuestions={onSuccessCancelShareExchangeQuestions}
      />

      <InvestmentsLayout
        className='flex-col 2lg:flex-row gap-4 2lg:gap-0 items-start 2lg:items-end'
        customHeader={
          <>
            <SearchForm
              handleFilter={(search) => {
                handleFilter(search);

                loadCompaniesWithInvestments(search, true);
              }}
              className='flex items-end flex-col xs:flex-row gap-4 xs:gap-0 w-full 2lg:w-auto basis-full 2lg:basis-7/12'
            >
              <FormContent
                register={register}
                control={control}
                errors={errors}
                fields={INVESTMENTS_TABLE_FILTER_FORM_FIELDS}
              />
            </SearchForm>
          </>
        }
      >
        <MyCompaniesWithInvestmentsTable
          companiesWithInvestments={companiesWithInvestments}
          updateSorting={updateSorting}
          sorting={sorting}
          isLoading={isLoading}
          handleClickRequestShareExchange={handleClickRequestShareExchange}
          onSuccessEditInvestment={loadCompaniesWithInvestments}
        />
        <div className='mt-10'>
          <MyCompaniesWithFinalisedInvestmentsTable
            companiesWithInvestments={companiesWithInvestments}
            updateSorting={updateSorting}
            sorting={sorting}
            isLoading={isLoading}
          />
        </div>
      </InvestmentsLayout>
    </>
  );
};

export default MyInvestmentsContainer;
