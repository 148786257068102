import { createAsyncThunk } from '@reduxjs/toolkit';

import { adminTodosApi } from 'apis';
import { GetListPayload } from 'interfaces';

export const TODOS_SLICE_NAME = 'todos';

export const getTodos = createAsyncThunk(`${TODOS_SLICE_NAME}/getTodos`, async (data: GetListPayload) => {
  const response = await adminTodosApi.getTodos(data);
  return response.data;
});
