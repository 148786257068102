import { AxiosRequestConfig } from 'axios';
import qs from 'query-string';

import { httpApiClient } from 'configs/http-client';
import {
  MetaPayload,
  InviteUserPayload,
  Permission,
  PermissionsResponse,
  UserData,
  SettingsSecurityInput,
  GetUsersPayload,
  GetUserPayload,
  FinishRegistrationPayload,
  SignInResponse,
  EntityInviteDirectorPayload,
} from 'interfaces';

export class UsersApis {
  constructor(private url: string) {}

  // TODO: add response

  getUsers({ page, per_page = 10, sort, order, query, filter }: MetaPayload, config?: AxiosRequestConfig) {
    return httpApiClient.get<GetUsersPayload>(
      `${this.url}?${qs.stringify({ page, per_page, sort, order, q: query })}${filter ? `&${filter}` : ''}`,
      config,
    );
  }

  getUser(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<GetUserPayload>(`${this.url}/${id}`, config);
  }

  deleteUser(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.delete<unknown>(`${this.url}/${id}`, config);
  }

  getUserPermissions(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.get<PermissionsResponse>(`${this.url}/${id}`, config);
  }

  inviteUser(data: InviteUserPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: UserData }>(this.url, data, config);
  }

  updateUserPermissions(id: number, permissions: string[], config?: AxiosRequestConfig) {
    return httpApiClient.patch<{ data: Permission[] }>(`${this.url}/${id}`, { permissions }, config);
  }

  updateUserPassword(data: SettingsSecurityInput, config?: AxiosRequestConfig) {
    return httpApiClient.patch<unknown>(`${this.url}/update-password`, data, config);
  }

  sendFinishRegistrationInvite(userId: number, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/send-invite/${userId}`, config);
  }

  sendDirectorEntityInvite(data: EntityInviteDirectorPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: UserData }>(`${this.url}/send`, data, config);
  }

  approveFinishRegistration(data: FinishRegistrationPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<Omit<SignInResponse, 'permissions'>>(`${this.url}/approve`, data, config);
  }

  findFounderIdByEmail(email: string, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ founderId: number }>(`${this.url}`, { email }, config);
  }
}

export const usersApi = new UsersApis('/admin/users');
export const permissionsApi = new UsersApis('/admin/permissions');
export const investorsApi = new UsersApis('/admin/investors');
export const profileApi = new UsersApis('/profile');
export const usersFinishRegistrationApi = new UsersApis('/users/finish-registration');
export const inviteUserApi = new UsersApis('/user-invites');
export const findUserApi = new UsersApis('/users/find-founder-id-by-email');
