import React, { Dispatch, PropsWithChildren, ReactElement, SetStateAction, useMemo } from 'react';

import { useFieldArray, FieldArray, FieldValues, useFormContext } from 'react-hook-form';

import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import {
  ADD_BENEFICIAL_OWNERS_PERSONS_FIELDS,
  ADD_SIGNIFICATION_CONTROL_PERSON_FIELDS,
  PERSON_EMPTY_ADDRESS_VALUE,
  DueDiligencePersonsFormNamesEnum,
} from 'constants/due-diligence';
import { getUnfieldValueFunc } from 'hooks/useDueDiligenceValidation';
import {
  ButtonAction,
  FormItem,
  FromItemHigligtedColor,
  PersonsDeletedAddress,
  SignificantControlPersonAddressInput,
} from 'interfaces';
import { ButtonsActions, FormContent } from 'shared-components';
import { TableData, TableRow } from 'shared-components/table';

import PersonsAdditionalData from './PersonsAdditionalData';
import PersonsAddressFormContent from './PersonsAddressFormContent';

interface Props {
  addresses: SignificantControlPersonAddressInput[];
  handleDeleteSignificantPerson: (index: number, id: number, isNew?: boolean) => void;
  setDeletedAddresses: Dispatch<SetStateAction<PersonsDeletedAddress[]>>;
  hidden?: boolean;
  index: number;
  personId: number;
  name: string;
  isNew?: boolean;
  hideCheckbox?: boolean;
  addAddressText?: string;
  bypassValidation?: boolean;
  getUnfieldValue?: getUnfieldValueFunc;
  haveAccessToDelete?: boolean;
}

const TABLE_DATA_CLASSNAME = 'align-top py-3';

// We need to use 'key' prop for the first row where we display information about director 1st address because
// FormContent uses 'name' of field as key and in our case name depends on the index of address and director but
// Index is always constant and equals 0. But in case if we delete first address we need to update date information but
// Since the key that uses FormContent wasnt changed we are not able to see updated data. Thats why we need to use
// 'key' prop for the first row based on field id of address. Bug link: https://evolve-consultants.atlassian.net/browse/PLAND-1505
// Mapping of address fields works fine because we've already used 'key' prop as address field 'id', but
// If we change key definition of key to addressIndex we will have same issue but also for address list
const PersonsFormRow = <T extends FieldValues>({
  index,
  hidden,
  personId,
  addresses,
  handleDeleteSignificantPerson,
  setDeletedAddresses,
  name,
  isNew,
  hideCheckbox,
  addAddressText,
  bypassValidation,
  haveAccessToDelete,
  getUnfieldValue,
}: PropsWithChildren<Props>): ReactElement | null => {
  const {
    control,
    register,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext<T>();

  const {
    fields: addressFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${name}.${index}.residentialAddresses` as any,
  });

  const isShownAddressButton = addresses.some(({ from, to, tillNow }) => !!from || !!to || tillNow);

  const actions: ButtonAction[] = useMemo(() => {
    const allActions = [];

    if (haveAccessToDelete)
      allActions.push({
        id: 'delete-persons-block-item',
        icon: DeleteSvg,
        action: () => handleDeleteSignificantPerson(index, personId, isNew),
      });

    return allActions;
  }, [handleDeleteSignificantPerson, haveAccessToDelete, index, isNew, personId]);

  const formFields = useMemo(() => {
    let fields = [];
    if (name === DueDiligencePersonsFormNamesEnum.BENEFICIAL_OWNERS) {
      fields = ADD_BENEFICIAL_OWNERS_PERSONS_FIELDS(bypassValidation);
    } else {
      fields = ADD_SIGNIFICATION_CONTROL_PERSON_FIELDS(bypassValidation);
    }

    if (getUnfieldValue) {
      fields = fields.map((field): FormItem => {
        const unfieldValue = getUnfieldValue(`${name}.${personId}.${field.name}`);
        return {
          ...field,
          highlighted: !!unfieldValue,
          highlightedDescription: unfieldValue?.description,
          highlightedColor: unfieldValue?.asError ? FromItemHigligtedColor.Red : undefined,
        };
      });
    }

    return fields;
  }, [name, bypassValidation, getUnfieldValue]);

  const getAddressActions = (addressId: number | undefined, index: number): ButtonAction[] => {
    if (addresses.length <= 1) return [];

    return [
      {
        id: 'delete-persons-block-address',
        icon: DeleteSvg,
        action: () => {
          remove(index);

          if (addressId) {
            setDeletedAddresses((prev) => [...prev, { id: personId, addressId, index }]);
          }
        },
      },
    ];
  };

  const handleAddNewAddress = () => {
    append(PERSON_EMPTY_ADDRESS_VALUE as FieldArray<T>);
  };

  if (hidden) return null;

  return (
    <>
      <TableRow>
        <TableData>
          <ButtonsActions className='items-center' anchorPrefix='director-main-address-actions' actions={actions} />
        </TableData>

        <FormContent
          tableDataClassName={TABLE_DATA_CLASSNAME}
          fields={formFields}
          register={register}
          errors={(errors as any)?.[name]?.[index]}
          group={`${name}.${index}`}
          isTableView
        />

        <PersonsAddressFormContent
          key={addressFields[0].id} // See the description above PersonsFormRow component definition
          tableDataClassName={TABLE_DATA_CLASSNAME}
          setValue={setValue}
          register={register}
          trigger={trigger}
          errors={errors}
          index={index}
          addresses={addresses}
          addressIndex={0}
          name={name}
          bypassValidation={bypassValidation}
          getUnfieldValue={getUnfieldValue}
          personId={personId}
        />

        <PersonsAdditionalData
          name={name}
          register={register}
          directorIndex={index}
          isShownAddressButton={isShownAddressButton}
          actions={getAddressActions(addresses?.[0]?.id, 0)}
          handleAddNewAddress={handleAddNewAddress}
          isShownCheckbox={!hideCheckbox}
          addAddressText={addAddressText}
        />
      </TableRow>

      {addressFields.slice(1).map((addressField, addressIndex) => (
        <TableRow key={addressField.id}>
          <TableData colSpan={formFields.length + 1} />
          <PersonsAddressFormContent
            setValue={setValue}
            tableDataClassName={TABLE_DATA_CLASSNAME}
            register={register}
            trigger={trigger}
            errors={errors}
            index={index}
            addressIndex={addressIndex + 1}
            name={name}
            addresses={addresses}
            bypassValidation={bypassValidation}
            additionalColumn={
              <PersonsAdditionalData
                name={name}
                isShownCheckbox={false}
                actions={getAddressActions(addresses[addressIndex + 1]?.id, addressIndex + 1)}
              />
            }
            getUnfieldValue={getUnfieldValue}
            personId={personId}
          />
        </TableRow>
      ))}
    </>
  );
};

export default PersonsFormRow;
