import { createSlice } from '@reduxjs/toolkit';

import { META_INITIAL_STATE } from 'constants/global';
import { ShareExchangeState, SingleShareExchange } from 'interfaces';

import { SHARE_EXCHANGE_SLICE_NAME } from './action';
import {
  getShareExchangeRequestsReducer,
  getShareExchangeReducer,
  cancelShareExchangeReducer,
  acceptShareExchangeReducer,
  declineShareExchangeReducer,
  getWelcomeInvestorShareExchangeDataReducer,
  createShareExchangeForInvestorReducer,
} from './reducers';

export const initialState: ShareExchangeState = {
  requests: {
    list: [],
    isLoading: false,
    ...META_INITIAL_STATE,
  },
  review: {
    isLoading: false,
    shareExchange: {} as SingleShareExchange,
  },
  welcomeInvestorShareExchangeData: {
    isLoading: false,
    list: null,
  },
};

const shareExchangeSlice = createSlice({
  name: SHARE_EXCHANGE_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getShareExchangeRequestsReducer(builder);
    getShareExchangeReducer(builder);
    cancelShareExchangeReducer(builder);
    acceptShareExchangeReducer(builder);
    declineShareExchangeReducer(builder);
    createShareExchangeForInvestorReducer(builder);
    getWelcomeInvestorShareExchangeDataReducer(builder);
  },
});

export const shareExchangeReducer = shareExchangeSlice.reducer;
