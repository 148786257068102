import { ChartItem, GraphItem, NumberOrNull } from 'interfaces';

export const convertObjectToChartData = (object: Record<string, string | NumberOrNull>): ChartItem[] => {
  return Object.entries(object).map((e) => ({ value: String(e[1]), name: e[0] }));
};

export const convertObjectToAreaData = (object: Record<string, number>): GraphItem[] => {
  return Object.entries(object).map((e) => ({ uv: e[1], name: e[0] }));
};

export const convertObjectToMultiAreaData = (
  firstGraphData: Record<string, number>,
  secondGraphData: Record<string, number>,
): GraphItem[] => {
  const allKeys = new Set([...Object.keys(firstGraphData), ...Object.keys(secondGraphData)]);

  return Array.from(allKeys).map((key) => ({
    name: key,
    uv: firstGraphData[key],
    secondChart: secondGraphData[key],
  }));
};
