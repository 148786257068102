import React, { useMemo } from 'react';

import { format } from 'date-fns';

import { COMPANY_PROFILE_PAGE_TITLE } from 'constants/company-profile';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/dateFormats';
import { selectCurrentCompanyData } from 'modules/companies/selectors';
import { useAppSelector } from 'modules/store';
import { LabelWithValue } from 'shared-components';
import Typography from 'shared-components/Typography';
import { getNatureOfBusinessList } from 'utils';

import { TypographyVariants } from '../../../constants/shared/typography';
import CompanyDetailsSkeleton from './CompanyDetailsSkeleton';

const CompanyProfileDetails = () => {
  const { data, isLoading } = useAppSelector(selectCurrentCompanyData);

  const natureOfBusinessList = useMemo(() => {
    return getNatureOfBusinessList(data?.natureOfBusiness || []);
  }, [data?.natureOfBusiness]);

  const formattedDateIncorporated = data?.dateIncorporated
    ? format(new Date(data.dateIncorporated), DAY_FORMAT_WITH_SLASHES)
    : '';

  if (isLoading || !data) return <CompanyDetailsSkeleton />;

  return (
    <div>
      <h2 className='text-grey-500'>{COMPANY_PROFILE_PAGE_TITLE}</h2>
      <div className='flex items-center pb-6 border-b border-grey-200'>
        <Typography className='text-grey-800' tag='h3' variant={TypographyVariants.SEMI_LARGE}>
          {data.companyName}
        </Typography>
      </div>
      <div className='flex mt-6 flex justify-between flex-wrap gap-y-6'>
        <LabelWithValue title='Industry' content={data.industry} />
        <LabelWithValue title='Date Incorporated' content={formattedDateIncorporated} />
        <LabelWithValue title='Nature of business(SIC)' content={natureOfBusinessList} />
      </div>
    </div>
  );
};

export default CompanyProfileDetails;
