import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import {
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS,
  SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_COLUMNS,
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES_OPTIONS,
  SIGNIFICANT_CONTROL_DOCUMENT_TYPES,
  SIGNIFICANT_CONTROL_PASSPORT_FILE_TYPES,
  SIGNIFICANT_CONTROL_PASSPORT_FILE_HELP_TEXT,
  SIGNIFICANT_CONTROL_LIST_DOCUMENT_REQUIRED_TYPES,
} from 'constants/significant-control-company';
import useModal from 'hooks/use-modal/useModal';
import { selectDueDiligenceCompanyId, selectDueDiligenceId } from 'modules/due-diligence/selectors';
import { useAppSelector } from 'modules/store';
import { SnippetLayout } from 'shared-components';

import DueDiligenceDocumentsModal from '../../due-diligence/DueDiligenceDocumentsModal';
import UploadDueDiligenceDocumentsModal from '../../due-diligence/upload-due-diligence-documents-modal/UploadDueDiligenceDocumentsModal';
import SignificantControlCompanyDocumentType from './SignificantControlCompanyDocumentType';

const SIGNIFICANT_CONTROL_DOCUMENT_TYPE_CLASSNAME = 'min-h-[92px] mb-4';

const [SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_FIRST_COLUMN, SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_SECOND_COLUMN] =
  SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_COLUMNS;

const SignificantControlCompanyDocuments = () => {
  const { id } = useParams();

  const [documentsModalSelectedType, setDocumentsModalSelectedType] = useState('');

  const significantControlCompanyId = useAppSelector(selectDueDiligenceId);
  const companyId = useAppSelector(selectDueDiligenceCompanyId);

  const isSignificantControlCompanyLoaded = companyId === Number(id);

  const { onOpen: onOpenDocumentsList, onClose: onCloseDocumentsList, ...documentsListModalProps } = useModal();
  const {
    onOpen: onOpenUploadDocuments,
    onClose: onCloseUploadDocuments,
    isOpen: isOpenUploadDocuments,
    modalProps: uploadDocumentsModalProps,
  } = useModal();

  const onClickUploadDocuments = (selectedDocumentType: string) => () => {
    if (
      selectedDocumentType === SIGNIFICANT_CONTROL_DOCUMENT_TYPES.PASSPORT ||
      selectedDocumentType === SIGNIFICANT_CONTROL_DOCUMENT_TYPES.OTHER
    ) {
      onOpenUploadDocuments({
        selectedDocumentType,
        allowedFileTypes: SIGNIFICANT_CONTROL_PASSPORT_FILE_TYPES,
        allowedFileHelpText: SIGNIFICANT_CONTROL_PASSPORT_FILE_HELP_TEXT,
      });

      return;
    }

    onOpenUploadDocuments({ selectedDocumentType });
  };

  const onOpenViewDocumentsModal = (documentType: string) => () => {
    onOpenDocumentsList();
    setDocumentsModalSelectedType(documentType);
  };

  const onCloseViewDocuments = () => {
    setDocumentsModalSelectedType('');
    onCloseDocumentsList();
  };

  return (
    <>
      <DueDiligenceDocumentsModal
        id={id}
        filterValue={documentsModalSelectedType}
        title={
          SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS[documentsModalSelectedType as SIGNIFICANT_CONTROL_DOCUMENT_TYPES] ||
          ''
        }
        onClose={onCloseViewDocuments}
        isOpen={documentsListModalProps.isOpen}
      />

      <UploadDueDiligenceDocumentsModal
        dueDiligenceId={significantControlCompanyId?.toString()}
        documentLabels={SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS}
        documentTypesOptions={SIGNIFICANT_CONTROL_DOCUMENT_TYPES_OPTIONS}
        onClose={onCloseUploadDocuments}
        isOpen={isOpenUploadDocuments}
        modalProps={uploadDocumentsModalProps}
      />

      <div>
        <div className='flex'>
          <SnippetLayout className='flex-1 mr-3'>
            {SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_FIRST_COLUMN.map((documentType) => (
              <SignificantControlCompanyDocumentType
                key={documentType}
                documentType={documentType}
                className={SIGNIFICANT_CONTROL_DOCUMENT_TYPE_CLASSNAME}
                label={SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS[documentType]}
                onClickUploadDocuments={onClickUploadDocuments(documentType)}
                onOpenViewDocumentsModal={onOpenViewDocumentsModal(documentType)}
              />
            ))}
          </SnippetLayout>
          <SnippetLayout className='flex-1 ml-3'>
            {SIGNIFICANT_CONTROL_LIST_DOCUMENT_TYPES_SECOND_COLUMN.map((documentType) => (
              <SignificantControlCompanyDocumentType
                key={documentType}
                documentType={documentType}
                className={SIGNIFICANT_CONTROL_DOCUMENT_TYPE_CLASSNAME}
                label={SIGNIFICANT_CONTROL_DOCUMENT_TYPES_LABELS[documentType]}
                onClickUploadDocuments={onClickUploadDocuments(documentType)}
                onOpenViewDocumentsModal={onOpenViewDocumentsModal(documentType)}
                required={
                  SIGNIFICANT_CONTROL_LIST_DOCUMENT_REQUIRED_TYPES.includes(documentType) &&
                  isSignificantControlCompanyLoaded
                }
              />
            ))}
          </SnippetLayout>
        </div>
      </div>
    </>
  );
};

export default SignificantControlCompanyDocuments;
