import React from 'react';

import InvestorCompaniesInvestments from './InvestorCompaniesInvestments';
import InvestorNumberOfShares from './InvestorNumberOfShares';

const InvestorInvestments = () => {
  return (
    <div className='flex flex-col gap-10'>
      <InvestorCompaniesInvestments />
      <InvestorNumberOfShares />
    </div>
  );
};

export default InvestorInvestments;
