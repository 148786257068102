import { createSlice } from '@reduxjs/toolkit';

import { FileDocumentsState } from 'interfaces';

import { DOCUMENTS_SLICE_NAME } from './action';
import {
  getDocumentsReducer,
  getCarouselDocumentsReducer,
  getTableDocumentsReducer,
  getFilterCompaniesOptionsReducer,
  getCompaniesDocumentsUploadOptionsReducer,
  getDocumentTypesReducer,
  getFundDocumentsReducer,
} from './reducers';

export const initialState: FileDocumentsState = {
  isLoadingCarouselDocuments: false,
  isLoadingTableDocuments: false,
  totalPages: 0,
  totalItems: 0,
  carouselDocuments: null,
  tableDocuments: null,
  filterCompaniesOptions: [],
  uploadCompaniesOptions: [],
  documentTypes: null,
};

const documentsSlice = createSlice({
  name: DOCUMENTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getDocumentsReducer(builder);
    getFundDocumentsReducer(builder);
    getCarouselDocumentsReducer(builder);
    getTableDocumentsReducer(builder);
    getFilterCompaniesOptionsReducer(builder);
    getCompaniesDocumentsUploadOptionsReducer(builder);
    getDocumentTypesReducer(builder);
  },
});

export const documentsReducer = documentsSlice.reducer;
