import React from 'react';

import { TODOS_SNIPPET_COLUMNS } from 'constants/dashboard';
import SnippetNoInfoLayout from 'shared-components/SnippetNoInfoLayout';
import { TableData, TableRow } from 'shared-components/table';
import Typography from 'shared-components/Typography';

const AdminTodosSnippetNoInfo = () => {
  return (
    <TableRow>
      <TableData className='pr-0' colSpan={TODOS_SNIPPET_COLUMNS.length}>
        <SnippetNoInfoLayout>
          <Typography className='flex text-grey-800'>No Todos found</Typography>
        </SnippetNoInfoLayout>
      </TableData>
    </TableRow>
  );
};

export default AdminTodosSnippetNoInfo;
