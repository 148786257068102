import React, { FC, useMemo } from 'react';

import { AUDIT_LOGS_OPERATION_LABELS } from 'constants/audit-log';
import { TypographyVariants } from 'constants/shared/typography';
import { AuditLog } from 'interfaces/audit-logs.interfaces';
import { ModalWindow, SnippetLayout } from 'shared-components';
import Typography from 'shared-components/Typography';

import AuditLogsBasicInfoItem from './AuditLogsBasicInfoItem';
import AuditLogsModalPreloader from './AuditLogsModalPreloader';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  id: number | null;
  info: AuditLog | null;
  isLoading?: boolean;
}

const AuditLogsModal: FC<Props> = ({ id, isOpen, onClose, info, isLoading }) => {
  const requestInformationContent = useMemo(
    () => <pre>{JSON.stringify(info?.properties, null, 2)}</pre>,
    [info?.properties],
  );

  return (
    <>
      <ModalWindow
        className='min-w-[70%] 2xl:min-w-[1048px]'
        wrapperClassName=' pt-14'
        onClose={onClose}
        isOpen={isOpen}
      >
        {!info || isLoading ? (
          <AuditLogsModalPreloader />
        ) : (
          <>
            <Typography tag='h5' variant={TypographyVariants.MEDIUM}>
              Showing: <span className='inline font-normal text-violet-600'> {id} Audit Log</span>
            </Typography>
            <div className='flex flex-wrap xl:flex-nowrap mt-6'>
              <SnippetLayout
                title='Basic Info'
                className='w-full xl:w-4/12 min-w-[300px] xl:mr-6'
                childrenClassName='flex flex-wrap gap-y-6'
              >
                <AuditLogsBasicInfoItem title='Author' value={info?.author} />
                <AuditLogsBasicInfoItem title='Entity' value={info?.entity} />
                <AuditLogsBasicInfoItem title='Operation' value={AUDIT_LOGS_OPERATION_LABELS[info?.operation]} />
                <AuditLogsBasicInfoItem title='Log Name' value={info?.logName} />
              </SnippetLayout>
              <SnippetLayout
                title='Request Information'
                className='w-full mt-6 xl:mt-0'
                childrenClassName='h-[350px] overflow-y-scroll text-grey-800'
              >
                {requestInformationContent}
              </SnippetLayout>
            </div>
          </>
        )}
      </ModalWindow>
    </>
  );
};

export default AuditLogsModal;
