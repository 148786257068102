import React, { FC, ReactNode } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import {
  COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME,
  COMPANY_IAC_PURCHASE_AT_COMPLETION,
  COMPANY_IAC_PURCHASE_COMPLETION_LABEL,
  COMPANY_IAC_SHARES_FIELDS,
  COMPANY_IAC_SHARES_FIELDS_LABEL,
  COMPANY_IAC_SHARES_FIELDS_LABELS,
  INVESTMENT_PROPOSAL_OVERVIEW_FORM_FIELDS,
  INVESTMENT_PROPOSAL_OVERVIEW_FORM_FIELDS_SECOND_PART,
  INVESTMENT_PROPOSAL_OVERVIEW_OTHER_NOTABLE_FIELD,
  INVESTMENT_PROPOSAL_OVERVIEW_PROPOSED_PURCHASE_PRICE_FIELD,
  InvestmentProposalBlockNames,
} from 'constants/company-iac';
import { UseCompanyIacOverviewReturnValues } from 'hooks/useCompanyIacOverview';
import { selectCompanyIAcNatureOfBusiness } from 'modules/company-iac/selectors';
import { useAppSelector } from 'modules/store';
import { FormContent, FormLabel } from 'shared-components';

import NatureOfBusiness from '../../due-diligence/business-details/NatureOfBusiness';
import CompanyIacAddCustomFields from '../company-iac-add-custom-fields/CompanyIacAddCustomFields';
import CompanyIacClosingDatesFields from '../company-iac-closing-dates/CompanyIacClosingDatesFields';
import CompanyIacCustomFieldsForm from '../company-iac-custom-fields-form/CompanyIacCustomFieldsForm';
import CompanyIacSharePriceFields from '../company-iac-share-price-fields/CompanyIacSharePriceFields';
import CompanyIacTableFields from '../company-iac-table-fields/CompanyIacTableFields';
import CompanyIacBlock from '../CompanyIacBlock';

interface Props extends Omit<UseCompanyIacOverviewReturnValues, 'reset' | 'trigger'> {
  children: ReactNode;
  totalShares: number;
  hideAdminActions: boolean;
}

const CompanyIacOverview: FC<Props> = ({
  isEditing,
  handleEdit,
  handleDiscardChanges,
  handleFormSubmit,
  sharedFormProps,
  setValue,
  customFieldsData,
  isLoading,
  children,
  totalShares,
  hideAdminActions,
  companySharePriceFields,
  isHideActionButtons,
  isAllowAdditionalValidation,
}) => {
  const { registerCustomFields, onDeleteCustomField, deletedFields } = customFieldsData;

  const natureOfBusiness = useAppSelector(selectCompanyIAcNatureOfBusiness);

  return (
    <CompanyIacBlock
      title='Overview'
      hideComments
      isHideActionButtons={isHideActionButtons}
      isEditing={isEditing}
      onClickEdit={handleEdit}
      onClickDiscardChanges={handleDiscardChanges}
      onClickSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <form className={cn('w-full', { 'disabled-autofill': !isEditing })}>
        <div className='flex flex-col 2xl:flex-row 2xl:space-x-6'>
          <div className='basis-1/2'>
            <FormContent
              isDisabled={!isEditing}
              fields={INVESTMENT_PROPOSAL_OVERVIEW_FORM_FIELDS}
              {...sharedFormProps}
            />
            <NatureOfBusiness
              className='w-full pt-0 mb-6'
              title='Nature of Business (SIC)*'
              isLoading={!natureOfBusiness?.length}
              data={natureOfBusiness}
            />
            <div
              className={twMerge(
                cn(
                  COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME,
                  {
                    'border border-red-500': !totalShares && isAllowAdditionalValidation,
                  },
                  'overflow-auto',
                ),
              )}
            >
              <CompanyIacTableFields
                name='fullyDilutedShareCapital'
                label={COMPANY_IAC_SHARES_FIELDS_LABEL}
                fields={COMPANY_IAC_SHARES_FIELDS}
                disabled={!isEditing}
                setValue={setValue}
                hideAddNewButton={hideAdminActions}
                fieldsLabels={COMPANY_IAC_SHARES_FIELDS_LABELS}
                className={cn('w-[220px] mb-0')}
                {...sharedFormProps}
              />
              {children}
            </div>

            <div className={cn('flex flex-col mt-6 mb-5', COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME)}>
              <FormLabel>{COMPANY_IAC_PURCHASE_COMPLETION_LABEL}</FormLabel>
              <div className='grid grid-cols-2 mt-12 gap-x-6 gap-y-12'>
                <FormContent
                  fields={COMPANY_IAC_PURCHASE_AT_COMPLETION(setValue, totalShares)}
                  isDisabled={!isEditing}
                  {...sharedFormProps}
                />
              </div>
            </div>

            <FormContent
              isDisabled={!isEditing}
              fields={[INVESTMENT_PROPOSAL_OVERVIEW_PROPOSED_PURCHASE_PRICE_FIELD]}
              {...sharedFormProps}
            />
          </div>
          <div className='basis-1/2 xs:min-w-[310px]'>
            <FormContent
              isDisabled={!isEditing}
              fields={INVESTMENT_PROPOSAL_OVERVIEW_FORM_FIELDS_SECOND_PART}
              className={cn('[&>.select-input>div]:py-1')}
              {...sharedFormProps}
            />
            <CompanyIacClosingDatesFields
              disabled={!isEditing}
              isAllowAdditionalValidation={isAllowAdditionalValidation}
              setValue={setValue}
              {...sharedFormProps}
            />

            <div
              className={twMerge(
                cn(COMPANY_IAC_BORDERED_WRAPPER_CLASSNAME, 'my-6 overflow-auto', {
                  'border border-red-500': !companySharePriceFields?.length && isAllowAdditionalValidation,
                }),
              )}
            >
              <CompanyIacSharePriceFields
                register={sharedFormProps.register}
                errors={sharedFormProps.errors}
                control={sharedFormProps.control}
                fields={companySharePriceFields}
                disabled={!isEditing}
              />
            </div>
          </div>
        </div>
        <FormContent
          isDisabled={!isEditing}
          fields={[INVESTMENT_PROPOSAL_OVERVIEW_OTHER_NOTABLE_FIELD]}
          className={cn('mb-5', { 'disabled-light-grey': !isEditing })}
          {...sharedFormProps}
        />
      </form>

      <CompanyIacCustomFieldsForm
        disabled={!isEditing}
        deletedFields={deletedFields}
        onDeleteCustomField={onDeleteCustomField}
        register={registerCustomFields}
        blockName={InvestmentProposalBlockNames.OVERVIEW}
        errors={customFieldsData.customFieldsErrors}
      />

      <CompanyIacAddCustomFields
        blockName={InvestmentProposalBlockNames.OVERVIEW}
        disabled={!isEditing}
        hideAddNewFieldButton={hideAdminActions}
        className={cn({ 'disabled-light-grey': !isEditing })}
      />
    </CompanyIacBlock>
  );
};

export default CompanyIacOverview;
