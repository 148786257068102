import React, { FC, ReactNode } from 'react';

import { DUE_DILIGENCE_CHECK_LOCK_MESSAGE, DUE_DILIGENCE_CHECK_LOCK_STATUSES } from 'constants/due-diligence';

export const DETAILS_PAGE_TITLE_IS_LOADING_TEST_ID = 'DETAILS_PAGE_TITLE_IS_LOADING_TEST_ID';
export const DETAILS_PAGE_TITLE_TEST_ID = 'DETAILS_PAGE_TITLE_TEST_ID';
export const DETAILS_PAGE_TITLE_LOCK_TEST_ID = 'DETAILS_PAGE_TITLE_LOCK_TEST_ID';

interface Props {
  title: string | undefined;
  subtitle: string;
  children?: ReactNode;
  subTitleNode?: ReactNode;
  isLocked?: boolean;
  checkLockStatus?: string;
}

const DetailsPageTitle: FC<Props> = ({ title, subtitle, children, isLocked, checkLockStatus, subTitleNode }) => {
  if (!title || !subtitle)
    return (
      <div data-testid={DETAILS_PAGE_TITLE_IS_LOADING_TEST_ID}>
        <div className='skeleton-loader h-12 w-80' />
        <div className='skeleton-loader h-14 w-96 mt-4' />
      </div>
    );

  return (
    <div data-testid={DETAILS_PAGE_TITLE_TEST_ID}>
      <div className='flex'>
        <h2 className='text-grey-500 mr-4'>{subtitle}</h2>
        {children}
      </div>
      <h3 className='text-32 text-blue-800 font-bold'>{title}</h3>
      {subTitleNode}
      {isLocked && checkLockStatus === DUE_DILIGENCE_CHECK_LOCK_STATUSES.PENDING && (
        <div className='skeleton-loader h-12 w-96 mt-2' data-testid={DETAILS_PAGE_TITLE_IS_LOADING_TEST_ID} />
      )}

      {isLocked && checkLockStatus === DUE_DILIGENCE_CHECK_LOCK_STATUSES.LOADED && (
        <div
          className='flex items-center mt-2 text-[14px] text-red-600 font-bold'
          data-testid={DETAILS_PAGE_TITLE_LOCK_TEST_ID}
        >
          {DUE_DILIGENCE_CHECK_LOCK_MESSAGE}
        </div>
      )}
    </div>
  );
};

export default DetailsPageTitle;
